import React, { useCallback } from 'react';
import MenuTitle from '../../shared/titles/MenuTitle';
import { initT } from '../../helpers/i18nHelpers';
import MenuContent from '../../shared/organizers/MenuContent';
import List from '../../shared/lists/List';
import { draggedElements } from '../../constants/draggedElements';
import LockedEntityLoader from '../../shared/loaders/LockedEntityLoader';
import SidebarButton from '../../shared/buttons/SidebarButton';
import AddIcon from '../../icons/addIcon';
import QueueVariantIcon from './QueueVariantIcon';

const t = initT('pages.learning');

const Left = (props) => {
  const { queue, workspace, visibleQueues, creationMode } = props;

  const getListElement = useCallback(
    (_queue) => (
      <LockedEntityLoader key={_queue.id} visible={_queue.locked}>
        <SidebarButton
          selected={queue && _queue.id === queue.id}
          to={`/learning/${_queue.id}`}
          bullet={<QueueVariantIcon variant={_queue.variant} className="w-4 h-4 mr-2" />}
        >
          {_queue.name}
        </SidebarButton>
      </LockedEntityLoader>
    ),
    [queue],
  );

  return (
    <MenuContent
      top={
        <>
          <MenuTitle marginClass="mx-25px py-25px" to="/learning/workspaces" tooltip={t('changeWorkspace')}>
            {workspace.name}
          </MenuTitle>
          <div className="pt-25px px-25px pb-4 flex justify-center">
            <SidebarButton
              variant="add"
              to={creationMode ? false : '/learning/new'}
              selected={creationMode}
              bullet={<AddIcon className="w-4 h-4 mr-2" />}
              centered
            >
              {t('createQueue')}
            </SidebarButton>
          </div>
        </>
      }
      main={
        <div className="w-full px-25px pb-25px">
          <List collection={visibleQueues} draggable={{ draggedElementType: draggedElements.learningQueue }}>
            {getListElement}
          </List>
        </div>
      }
    />
  );
};

export default Left;
