import React from 'react';
import Selectable from './Selectable';
import Trigger from './standardParts/Trigger';
import Menu from './standardParts/Menu';
import Option from './standardParts/Option';
import find from 'lodash/find';
import { initT } from '../../../../helpers/i18nHelpers';

const t = initT('selector');

const getDefaultTriggerChildren = (options, value, placeholder) => {
  const label = find(options, { value }) ? find(options, { value }).label : '';
  if (!label && placeholder) return <span className="opacity-40">{placeholder}</span>;
  return label;
};

const Selector = React.forwardRef((props, ref) => {
  const {
    id,
    triggerProps = {},
    menuProps = {},
    optionProps = {},
    // should be an array of { label: ..., value: ... } objects
    options = [],
    onSelect,
    onBlur,
    checkIfSelected,
    value = '',
    placeholder = t('placeholder'),
    children = getDefaultTriggerChildren(options, value, placeholder),
    optionComponent = Option,
    trigger = <Trigger />,
    menu = <Menu />,
    disabled = false,
    widthClass = 'w-full',
    fixedMenuWidth = true,
  } = props;

  const _mapOptions = (option) =>
    React.createElement(optionComponent, {
      key: option.label,
      ...option,
      onSelect,
      checkIfSelected,
      selectedValue: value,
      ...optionProps,
    });

  return (
    <Selectable
      id={id}
      ref={ref}
      disabled={disabled}
      widthClass={widthClass}
      trigger={React.cloneElement(trigger, { ...props, ...triggerProps, children })}
      menu={React.cloneElement(menu, {
        ...menuProps,
        options: options.map(_mapOptions),
        selectedValue: value,
      })}
      onBlur={onBlur}
      fixedMenuWidth={fixedMenuWidth}
    />
  );
});

export default Selector;
