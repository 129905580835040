import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../../../helpers/stateManagementHelpers';
import {
  audioConfigActionLock,
  audioConfigActionSetAudioBuffer,
  audioConfigActionUnlock,
} from '../../../../stateManagement/actions/audioConfigActions';
import { combineMasks, invertMask, sanitizeMask } from './utils';
import { generateTagId } from '../../../../helpers/identifierHelpers';
import { getThemeTypeDefinition } from '../../../../helpers/themeHelpers';
import { useWavesurfer } from './useWavesurfer';

const AudioControllerBase = (props) => {
  const {
    file,
    src,
    mask,
    waveHeight,
    theme,
    onMaskUpdated = () => {},
    onFinish = () => {},
    onAudioProcess = () => {},
    disabled,
    audioConfig: { lockedForId, audioBuffers },
    audioConfigActionLock,
    audioConfigActionUnlock,
    audioConfigActionSetAudioBuffer,
    children,
  } = props;
  const waveFormId = useMemo(generateTagId, []);
  const timerId = useMemo(() => waveFormId + '-timer', []);
  const durationId = useMemo(() => waveFormId + '-duration', []);
  const [trimMode, setTrimMode] = useState(false);

  useEffect(() => {
    if (disabled) setTrimMode(false);
  }, [disabled]);

  const wavesurfer = useWavesurfer({
    timerId,
    durationId,
    src,
    file,
    mask,
    theme,
    waveHeight,
    waveFormId,
    disabled,
    audioBuffers,
    audioConfigActionUnlock,
    audioConfigActionSetAudioBuffer,
    onAudioProcess,
    onFinish,
  });

  const toggleTrimMode = useCallback(() => {
    if (trimMode) {
      const newMask = invertMask(
        Object.values(wavesurfer.regions.list).map((region) => [region.start, region.end]),
        wavesurfer.getDuration(),
      );

      onMaskUpdated(sanitizeMask(combineMasks(mask, newMask)));
      wavesurfer.clearRegions();
      wavesurfer.disableDragSelection();
    } else {
      const color = getThemeTypeDefinition(theme, 'deleteSurface').color;
      wavesurfer.enableDragSelection({
        color,
      });
    }
    setTrimMode(!trimMode);
  }, [theme, wavesurfer, trimMode, setTrimMode]);

  const isPlaying = useMemo(() => lockedForId === waveFormId, [lockedForId]);
  const audioLocked = useMemo(() => !isPlaying && lockedForId !== null, [isPlaying, lockedForId]);

  const playPause = useCallback(() => {
    if (audioLocked) return;
    if (isPlaying) audioConfigActionUnlock(waveFormId);
    else audioConfigActionLock(waveFormId);
  }, [audioLocked, isPlaying]);

  useEffect(() => {
    if (isPlaying && wavesurfer && !wavesurfer.isPlaying()) wavesurfer.play();
    else if (!isPlaying && wavesurfer && wavesurfer.isPlaying()) wavesurfer.pause();
  }, [isPlaying]);

  // destructor
  useEffect(
    () => () => {
      audioConfigActionUnlock(waveFormId);
    },
    [],
  );

  return children({
    wavesurfer,
    isPlaying,
    playPause,
    audioLocked,
    timerId,
    waveHeight,
    waveFormId,
    durationId,
    trimMode,
    toggleTrimMode,
  });
};

export default connect(prepareState(['audioConfig', 'theme']), {
  audioConfigActionLock,
  audioConfigActionUnlock,
  audioConfigActionSetAudioBuffer,
})(AudioControllerBase);
