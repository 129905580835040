import React from 'react';
import ThemeCrumb from '../../../shared/ThemeCrumb';
import AuxiliaryText from '../../../shared/AuxiliaryText';
import { enumT } from '../../../helpers/i18nHelpers';
import ButtonsGroup from '../../../shared/buttons/ButtonsGroup';
import IconButton from '../../../shared/buttons/IconButton';
import ConfirmationButton from '../../../shared/buttons/ConfirmationButton';
import ArrowRightIcon from '../../../icons/arrowRightIcon';
import Clickable from '../../../shared/Clickable';
import ArrowDownIcon from '../../../icons/arrowDownIcon';

const HabitHeader = (props) => {
  const {
    habit,
    incognitoMode,
    switchPrivate,
    switchHidden,
    deleteElement,
    toggleContainerActivity,
    isContainerActive,
    activateEditionMode,
    style = {},
  } = props;

  let result = (
    <div className={`flex items-center ${isContainerActive ? 'border-b rounded-t-xl' : ''}`} style={style}>
      <Clickable
        className="flex w-full items-center truncate"
        onMouseDown={() => {
          toggleContainerActivity();
        }}
      >
        <ThemeCrumb type={`${habit.variant}HabitDenotation`}>
          <div className="p-5">
            {isContainerActive ? <ArrowDownIcon className="w-3 h-3" /> : <ArrowRightIcon className="w-3 h-3" />}
          </div>
        </ThemeCrumb>
        <div className="h-full w-full text-left truncate">
          {habit.name}
          <AuxiliaryText>{enumT(`inputTypes.${habit.inputType}`)}</AuxiliaryText>
        </div>
      </Clickable>
      <div className="p-2 sm:flex flex-row-reverse">
        <ButtonsGroup
          className="w-full flex justify-end pl-1"
          buttons={[
            <IconButton key="edit" variant="edit" onMouseDown={activateEditionMode} />,
            <ConfirmationButton
              key="delete"
              variant="delete"
              i18nKey="habits.delete"
              resourceName={habit.name}
              onConfirmed={() => deleteElement(habit)}
            />,
          ]}
        />
        <ButtonsGroup
          className="w-full flex justify-end"
          buttons={[
            <IconButton
              key="switchHidden"
              variant={habit.hidden ? 'unmarkHidden' : 'markHidden'}
              onMouseDown={switchHidden}
            />,
            incognitoMode ? null : (
              <IconButton
                key="switchPrivate"
                onMouseDown={switchPrivate}
                variant={habit.private ? 'unmarkPrivate' : 'markPrivate'}
              />
            ),
          ]}
        />
      </div>
    </div>
  );

  if (isContainerActive)
    result = (
      <ThemeCrumb
        type="container"
        mixinType={`${habit.variant}HabitDenotation`}
        mixedStyleKeysMultipliers={{ borderColor: 0.2, backgroundColor: 0.05 }}
      >
        {result}
      </ThemeCrumb>
    );

  return result;
};

export default HabitHeader;
