import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { snackbarMessagesActionHandleBackendErrors } from '../../actions/snackbarMessagesActions';
import { learningWorkspaceFromDto, learningWorkspaceToDto } from '../../../helpers/model/learningWorkspacesHelpers';
import {
  learningWorkspacesActionSetElement,
  learningWorkspacesActionUnlockElement,
} from '../../actions/learningWorkspacesActions';

export default function* markPrivateElementLearningWorkspacesSaga(action) {
  const initialWorkspace = action.payload;
  const { ok, data } = yield call(api.learningWorkspaces.markPrivate, learningWorkspaceToDto(initialWorkspace));
  if (ok) {
    yield put(learningWorkspacesActionSetElement(learningWorkspaceFromDto(data)));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(learningWorkspacesActionUnlockElement(initialWorkspace));
  }
}
