export const configActionSet = (config) => ({
  type: 'CONFIG_ACTION_SET',
  payload: config,
});

export const configActionUpdate = (
  apiKey,
  payload,
  successCallback = () => {},
  failureCallback = () => {},
  preventErrorMsg = false,
) => ({
  type: 'CONFIG_ACTION_UPDATE',
  payload: {
    apiKey,
    payload,
    successCallback,
    failureCallback,
    preventErrorMsg,
  },
});
