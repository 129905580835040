import reduce from 'lodash/reduce';

export const propsWhitelists = {
  block: ['type', 'alignmentType'],
  bullet: ['type', 'alignmentType', 'nestingLevel'],
  orderedBullet: ['type', 'alignmentType', 'nestingLevel', 'ordinal'],
  checklistBullet: ['type', 'alignmentType', 'nestingLevel', 'checked'],
  link: ['type', 'url'],
};

export const allPossibleProps = Array.from(
  reduce(
    Object.keys(propsWhitelists),
    (result, key) => {
      propsWhitelists[key].forEach((propKey) => result.add(propKey));
      return result;
    },
    new Set(),
  ),
);
