import React from 'react';
import ThemeCrumb from '../../../ThemeCrumb';

export const getDatepickerCalendarContainer = (themeType) => (props) => {
  const { className, children, showPopperArrow, arrowProps = {} } = props;
  return (
    <ThemeCrumb type={themeType}>
      <div className={className}>
        {showPopperArrow && <div className="react-datepicker__triangle" {...arrowProps} />}
        {children}
      </div>
    </ThemeCrumb>
  );
};

export default getDatepickerCalendarContainer;
