import { useEffect } from 'react';
import isHotkey from 'is-hotkey';
import {
  learningQueueIterableVariants,
  learningQueuePausableVariants,
  learningQueueVariants,
} from '../../../constants/enums/learningQueueVariants';

export const useKeydown = (props) => {
  let { queue, currentChunk } = props;
  queue ||= {};
  currentChunk ||= {};
  const { next, back, paused, pause, resume, reset, empty, ended } = props;

  useEffect(() => {
    if (!queue.id || empty) return;

    const nextCb = (e) => {
      if (learningQueueIterableVariants.includes(queue.variant)) {
        if (isHotkey('space', e)) {
          if (ended) reset();
        }
      }

      if (queue.variant === learningQueueVariants.typing) {
        if (isHotkey('space', e)) {
          if (!ended && paused) resume();
        }
        // typing variant is very specific and the following conditions are not appropriate for it
        return;
      }

      if (learningQueueIterableVariants.includes(queue.variant)) {
        if (isHotkey('left', e)) back();
        if (isHotkey('right', e)) next();
      }
      if (learningQueuePausableVariants.includes(queue.variant)) {
        if (isHotkey('space', e)) {
          if (!ended && paused) resume();
          else if (!ended && !paused) pause();
        }
      }
    };
    window.addEventListener('keydown', nextCb);
    return () => window.removeEventListener('keydown', nextCb);
  }, [queue.id, queue.variant, currentChunk.nodeId, next, back, pause, paused, resume, empty, ended]);
};
