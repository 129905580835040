import React from 'react';
import ImageEditor from '../shared/ImageEditor';
import ImagePresenter from './ImagePresenter';
import Clickable from '../../../Clickable';
import { runIfDraggingNotEnabled } from '../../../../helpers/draggingHelpers';
import ThemeCrumb from '../../../ThemeCrumb';
import { imageSizes } from '../../../../constants/enums/imageSizes';

const NodeImage = (props) => {
  const { editionAllowed, editionMode, dragged, enableEditionMode, disableEditionMode, node, update, onModal } = props;

  if (!editionAllowed) return <ImagePresenter content={node.content} />;

  if (!editionMode || dragged)
    return (
      <div>
        <Clickable
          className="w-full"
          onMouseDown={() => runIfDraggingNotEnabled(enableEditionMode)}
          disabled={dragged}
          opaqueWhenDisabled={false}
          hoverOpacity
        >
          <ThemeCrumb type="input">
            <div className="rounded-b border p-4">
              <ImagePresenter content={node.content} />
            </div>
          </ThemeCrumb>
        </Clickable>
      </div>
    );

  const { url = '', description = '', size = imageSizes.large } = node.content || {};

  const onSubmit = ({ url, description, size }) => {
    disableEditionMode();
    node.content = { url, description, size };
    update(node);
  };

  return <ImageEditor url={url} description={description} size={size} handleSubmit={onSubmit} onModal={onModal} />;
};

export default NodeImage;
