export const recurringTasksActionFetch = () => ({
  type: 'RECURRING_TASKS_ACTION_FETCH',
});

export const recurringTasksActionSet = (recurringTasks) => ({
  type: 'RECURRING_TASKS_ACTION_SET',
  payload: recurringTasks,
});

export const recurringTasksActionCreateElement = (recurringTask) => ({
  type: 'RECURRING_TASKS_ACTION_CREATE_ELEMENT',
  payload: recurringTask,
});

export const recurringTasksActionUnlock = () => ({
  type: 'RECURRING_TASKS_ACTION_UNLOCK',
});

export const recurringTasksActionUpdateElement = (recurringTask) => ({
  type: 'RECURRING_TASKS_ACTION_UPDATE_ELEMENT',
  payload: recurringTask,
});

export const recurringTasksActionMarkPrivateElement = (recurringTask) => ({
  type: 'RECURRING_TASKS_ACTION_MARK_PRIVATE_ELEMENT',
  payload: recurringTask.assignValues({ private: true }),
});

export const recurringTasksActionUnmarkPrivateElement = (recurringTask) => ({
  type: 'RECURRING_TASKS_ACTION_UNMARK_PRIVATE_ELEMENT',
  payload: recurringTask.assignValues({ private: false }),
});

export const recurringTasksActionLockElement = (recurringTask) => ({
  type: 'RECURRING_TASKS_ACTION_LOCK_ELEMENT',
  payload: recurringTask,
});

export const recurringTasksActionUnlockElement = (recurringTask) => ({
  type: 'RECURRING_TASKS_ACTION_UNLOCK_ELEMENT',
  payload: recurringTask,
});

export const recurringTasksActionDeleteElement = (recurringTask) => ({
  type: 'RECURRING_TASKS_ACTION_DELETE_ELEMENT',
  payload: recurringTask,
});

export const recurringTasksActionSetCreatedElement = (recurringTask) => ({
  type: 'RECURRING_TASKS_ACTION_SET_CREATED_ELEMENT',
  payload: recurringTask,
});

export const recurringTasksActionSetElement = (recurringTask) => ({
  type: 'RECURRING_TASKS_ACTION_SET_ELEMENT',
  payload: recurringTask,
});

export const recurringTasksActionUnset = (oneOrManyElements) => ({
  type: 'RECURRING_TASKS_ACTION_UNSET',
  payload: oneOrManyElements,
});
