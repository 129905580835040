import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';

export const prepareCollection = (dto, dtoToModelFunc) => dto.map((element) => dtoToModelFunc(element));

export const produceModelFromDto =
  (modelClass, mappings, additionalValuesDefaults = {}) =>
  (dto, additionalValues = {}) => {
    const result = new modelClass();
    populateModelFields(result, dto, mappings);
    Object.keys(additionalValuesDefaults).forEach((valueKey) => {
      const passedValue = additionalValues[valueKey];
      if (passedValue === undefined) result[valueKey] = additionalValuesDefaults[valueKey];
      else result[valueKey] = passedValue;
    });
    return result;
  };

export const produceModelToDto =
  (mappings, additionalValuesDefaults = {}) =>
  (modelInstance, additionalValues = {}) => {
    const result = {};
    populateDto(result, modelInstance, mappings);
    Object.keys(additionalValuesDefaults).forEach((valueKey) => {
      const passedValue = additionalValues[valueKey];
      if (passedValue === undefined) result[valueKey] = additionalValuesDefaults[valueKey];
      else result[valueKey] = passedValue;
    });
    return result;
  };

export const populateModelFields = (model, dto, mappings) => {
  mappings.forEach(({ 0: dtoKey, 1: modelKey, 2: type, 3: additionalData }) => {
    const dtoValue = dto[dtoKey];
    if (dtoValue !== undefined) model[modelKey] = handleDtoValue(dtoValue, type, additionalData);
  });
};

const populateDto = (dto, model, mappings) => {
  mappings.forEach(({ 0: dtoKey, 1: modelKey, 2: type, 3: additionalData }) => {
    const modelValue = model[modelKey];
    if (modelValue !== undefined) dto[dtoKey] = handleModelValue(modelValue, type, additionalData);
  });
};

const handleDtoValue = (value, type, additionalData) => {
  if (!type) return value;
  switch (type) {
    case 'enum':
      return camelCase(value);
    case 'association':
      return value.map((e) => additionalData.fromDto(e));
    default:
      return value;
  }
};

const handleModelValue = (value, type, additionalData) => {
  if (!type) return value;
  switch (type) {
    case 'enum':
      return snakeCase(value);
    case 'association':
      return value.map((e) => additionalData.toDto(e));
    default:
      return value;
  }
};
