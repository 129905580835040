import React from 'react';
import HabitHidingConditionForm from './HabitHidingConditionForm';
import HabitHidingCondition from '../../../../models/habitHidingCondition';

const HabitHidingConditionModalContent = (props) => {
  const { addElement } = props;
  const newHabitHidingCondition = new HabitHidingCondition();
  return <HabitHidingConditionForm {...newHabitHidingCondition} addElement={addElement} />;
};

export default HabitHidingConditionModalContent;
