import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { notificationT } from '../../../helpers/i18nHelpers';
import { noteFromDto, noteToDto } from '../../../helpers/model/noteHelpers';
import { notesActionSetCreatedElement, notesActionUnlock } from '../../actions/notesActions';
import { push } from 'connected-react-router';
import { elementToFocusOnActionSet } from '../../actions/elementToFocusOnActions';
import { elementToFocusOnTypes } from '../../../constants/elementToFocusOnTypes';

export default function* duplicateElementNotesSaga(action) {
  const { element: initialNote } = action.payload;
  const { ok, data } = yield call(api.notes.duplicate, noteToDto(initialNote));
  if (ok) {
    yield put(snackbarMessagesActionAdd(notificationT('notes.create.success')));
    const note = noteFromDto(data);
    yield put(notesActionSetCreatedElement(note));
    yield put(push(`/notes/${note.id}`));
    yield put(elementToFocusOnActionSet(elementToFocusOnTypes.noteTitle, note.id));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(notesActionUnlock());
  }
}
