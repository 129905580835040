import React from 'react';
import ThemeCrumb from '../../../ThemeCrumb';

const ErrorWrapper = ({ displayError, fixedErrorHeight, error, children }) => {
  if (!displayError && !fixedErrorHeight) return children;

  const fixedHeightClasses = fixedErrorHeight ? 'h-5 overflow-auto' : '';

  return (
    <div className="w-full">
      {children}
      <ThemeCrumb type="error">
        <div className={`${fixedHeightClasses} mt-1`}>{error}</div>
      </ThemeCrumb>
    </div>
  );
};

export default ErrorWrapper;
