import React from 'react';
import ThemeCrumb from './ThemeCrumb';

const AuxiliaryText = (props) => {
  const { children, widthClass = 'w-full', textSizeClass = 'text-xs', className = '' } = props;

  return (
    <ThemeCrumb type="auxiliary">
      <div className={`${textSizeClass} ${widthClass} ${className}`}>{children}</div>
    </ThemeCrumb>
  );
};

export default AuxiliaryText;
