import React from 'react';

export const UnlockIcon = ({ className = '', style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className={`fill-current ${className}`}
    style={style}
  >
    <path
      d="M12 10v-4c0-3.313-2.687-6-6-6s-6 2.687-6 6v3h2v-3c0-2.206 1.794-4 4-4s4 1.794 4
                 4v4h-4v14h18v-14h-12zm10 12h-14v-10h14v10z"
    />
  </svg>
);
export default UnlockIcon;
