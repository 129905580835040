import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { notificationT } from '../../../helpers/i18nHelpers';
import { eventFromDto, eventToDto } from '../../../helpers/model/eventHelpers';
import { eventsActionSetCreatedElement, eventsActionUnlock } from '../../actions/eventsActions';
import { elementToFocusOnActionSet } from '../../actions/elementToFocusOnActions';
import { elementToFocusOnTypes } from '../../../constants/elementToFocusOnTypes';

export default function* createElementEventsSaga(action) {
  const { element } = action.payload;
  const { ok, data } = yield call(api.events.create, eventToDto(element));
  if (ok) {
    const newEvent = eventFromDto(data);
    yield put(elementToFocusOnActionSet(elementToFocusOnTypes.eventCreationForm, newEvent.date));
    yield put(snackbarMessagesActionAdd(notificationT('events.create.success')));
    yield put(eventsActionSetCreatedElement(newEvent));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(eventsActionUnlock());
  }
}
