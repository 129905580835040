import React, { useCallback } from 'react';
import QueueForm from './queueForm';
import { useHistory } from 'react-router-dom';
import FullPageNoData from '../../shared/placeholders/FullPageNoData';
import MainTopSpace from '../../shared/organizers/MainTopSpace';
import useNarrowMainWidth from '../../hooks/useNarrowMainWidth';
import Container from '../../shared/organizers/containers/Container';
import QueuePresenter from './QueuePresenter';
import QueueRun from './queueRun';

const Main = (props) => {
  const { queue, creationMode, runMode, setRunMode, formVisible, setFormVisible, queueRunToolkit } = props;

  const history = useHistory();
  const width = useNarrowMainWidth();

  const hideForm = useCallback(() => {
    if (creationMode) history.replace('/learning');
    else setFormVisible(false);
  }, [setFormVisible]);

  if (!queue) return <FullPageNoData />;

  if (runMode) return <QueueRun queue={queue} queueRunToolkit={queueRunToolkit} />;

  return (
    <>
      <MainTopSpace />
      <div className="w-full flex justify-center pb-25px">
        {formVisible ? (
          <Container width={width}>
            <QueueForm {...queue} hideForm={hideForm} />
          </Container>
        ) : (
          <div style={{ width }}>
            <QueuePresenter queue={queue} setRunMode={setRunMode} />
          </div>
        )}
      </div>
    </>
  );
};

export default Main;
