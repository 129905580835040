import React, { useEffect, useState } from 'react';
import useNarrowMainWidth from '../../../../hooks/useNarrowMainWidth';
import ActivableContainer from '../../../../shared/organizers/containers/ActivableContainer';
import ActiveElement from '../../../../models/auxiliary/activeElement';
import { activeElementTypes } from '../../../../constants/activeElementTypes';
import { customColors } from '../../../../constants/customColors';
import RecurringEventForm from './RecurringEventForm';
import RecurringEventHeader from './RecurringEventHeader';
import OnClickOutsideWrapper from '../../../../shared/onClickOutside/OnClickOutsideWrapper';

const RecurringEventTile = (props) => {
  const { recurringEvent, draggableListRef } = props;

  const [editionMode, setEditionMode] = useState(false);
  useEffect(() => {
    if (!editionMode) draggableListRef.current.reinitialize();
  }, [editionMode]);

  const width = useNarrowMainWidth();
  const activeWidth = useNarrowMainWidth(false);

  return (
    <ActivableContainer
      activeElement={new ActiveElement(activeElementTypes.recurringEvent, recurringEvent.id)}
      themeType="container"
      themeCrumbProps={{
        mixinColor: editionMode ? false : customColors[recurringEvent.color].primary,
        mixedStyleKeysMultipliers: {
          borderColor: 0.2,
        },
      }}
      loading={recurringEvent.locked}
      width={width}
      activeWidth={activeWidth}
      className={'transition-all'}
      renderCommonPart={(containerProps) => (
        <RecurringEventHeader
          {...containerProps}
          recurringEvent={recurringEvent}
          activateEditionMode={() => {
            setEditionMode(true);
            containerProps.activateContainer();
            draggableListRef.current.disableDragging();
          }}
        />
      )}
      renderActive={(containerProps) =>
        editionMode ? (
          <RecurringEventForm
            {...recurringEvent}
            onBlur={() => {
              setEditionMode(false);
              containerProps.deactivateContainer();
              draggableListRef.current.enableDragging();
            }}
          />
        ) : (
          <OnClickOutsideWrapper onClickOutside={containerProps.toggleContainerActivity}>
            <div>
              {containerProps.commonPart}
              <RecurringEventForm editionMode={false} {...recurringEvent} />
            </div>
          </OnClickOutsideWrapper>
        )
      }
    />
  );
};

export default RecurringEventTile;
