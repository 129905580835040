import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { nodesActionSetCreatedElement, nodesActionUnlock } from '../../actions/nodesActions';
import { nodeFromDto, nodeToDto } from '../../../helpers/model/nodeHelpers';
import { elementToFocusOnActionSet } from '../../actions/elementToFocusOnActions';
import { elementToFocusOnTypes } from '../../../constants/elementToFocusOnTypes';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { notificationT } from '../../../helpers/i18nHelpers';

export default function* createElementNodesSaga(action) {
  const { element: initialNode, successorId } = action.payload;
  const { ok, data } = yield call(api.nodes.create, { ...nodeToDto(initialNode), successor_id: successorId });
  if (ok) {
    yield put(snackbarMessagesActionAdd(notificationT('nodes.create.success')));
    const node = nodeFromDto(data);
    yield put(nodesActionSetCreatedElement(node));
    yield put(elementToFocusOnActionSet(elementToFocusOnTypes.node, node.id));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(nodesActionUnlock());
  }
}
