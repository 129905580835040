import frenchkiss from 'frenchkiss';

frenchkiss.extend('en', {
  navbar: {
    links: {
      notes: 'Notes',
      learning: 'Learning',
      language: 'Language',
      journal: 'Journal',
      habits: 'Habits',
      tasks: 'Tasks',
      other: 'Other',
      profile: 'Profile settings',
      signOut: 'Sign out',
      lock: 'Lock',
      disableIncognitoMode: 'Show private items',
      enableIncognitoMode: 'Hide private items',
    },
  },
  selector: {
    placeholder: 'Select...',
    noOptions: 'No options',
  },
  button: {
    add: 'Add',
    save: 'Save',
    edit: 'Edit',
    delete: 'Delete',
    markPrivate: 'Mark as private',
    unmarkPrivate: 'Mark as not private',
    markHidden: 'Mark as hidden',
    unmarkHidden: 'Mark as visible',
    markTodo: 'Mark as TODO',
    unmarkTodo: 'Mark as not TODO',
    check: 'Check off',
    markUpdated: 'Mark as updated',
    reset: 'Reset',
    switchOnMenu: 'Menu',
    switchOffMenu: 'Menu',
    chart: 'Chart',
    bold: 'Bold',
    italic: 'Italic',
    underline: 'Underline',
    strikethrough: 'Strikethrough',
    code: 'Code',
    leftText: 'Align to left',
    centerText: 'Align to center',
    rightText: 'Align to right',
    justifyText: 'Justify',
    link: 'Link',
    undo: 'Undo',
    redo: 'Redo',
    bullets: 'Bulleted list',
    orderedBullets: 'Ordered list',
    checklistBullets: 'Checklist',
    decreaseIndent: 'Decrease indentation',
    increaseIndent: 'Increase indentation',
    heading: 'Heading',
    text: 'Text paragraph',
    image: 'Image',
    attachment: 'Attachment',
    label: 'Labels',
    duplicate: 'Duplicate',
    showDetails: 'Show details',
    hideDetails: 'Hide details',
    color: 'Change color',
    ok: 'OK',
    confirm: 'Confirm',
    submit: 'Submit',
    cancel: 'Cancel',
    changeWorkspace: 'Change workspace',
    microphone: 'Record',
    play: 'Play',
    pause: 'Pause',
    stop: 'Stop',
    pdf: 'PDF',
    category: 'Change category',
    cut: 'Cut',
    exit: 'Exit',
  },
  textEditor: {
    linkUrl: 'URL',
    linkTextDefault: 'New link',
    linkUrlPlaceholder: 'https://google.com',
  },
  noDataPlaceholder: 'NO DATA',
});
