import Collection from '../../models/auxiliary/collection';

const initialState = () => ({});

export default function (state = initialState(), action) {
  const { date, habitId, newElements } = action.payload || {};

  switch (action.type) {
    case 'HABIT_CHARTS_DATA_ACTION_FETCH': {
      const dateDictionary = state[date] || {};
      const newDateDictionary = { ...dateDictionary, [habitId]: new Collection() };
      return { ...state, [date]: newDateDictionary };
    }
    case 'HABIT_CHARTS_DATA_ACTION_SET': {
      const dateDictionary = state[date];
      if (!dateDictionary) return state;
      const newCollection = dateDictionary[habitId]
        .toFetched(false)
        .toUnlocked(false)
        .withReplacedElements(newElements);
      return { ...state, [date]: { ...dateDictionary, [habitId]: newCollection } };
    }
    case 'HABIT_CHARTS_DATA_ACTION_RESET':
    case 'GLOBAL_ACTION_RESET':
      return initialState();
    default:
      return state;
  }
}
