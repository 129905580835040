import { produceModelFromDto, produceModelToDto } from './modelHelpers';
import HabitHidingCondition from '../../models/habitHidingCondition';

const mappings = [
  ['id', 'id'],
  ['times_required', 'timesRequired'],
  ['variant', 'variant', 'enum'],
  ['custom_period_days_counter', 'customPeriodDaysCounter'],
  ['weekday_type', 'weekdayType'],
];

export const habitHidingConditionFromDto = produceModelFromDto(HabitHidingCondition, mappings);

export const habitHidingConditionToDto = produceModelToDto(mappings);
