import React from 'react';
import Separator from '../Separator';

const FormFooter = (props) => {
  const { children, marginClass = '' } = props;
  return (
    <div className={`w-full ${marginClass}`}>
      <footer className={`w-full flex justify-end px-4`}>{children}</footer>
      <Separator className="pt-4" />
    </div>
  );
};

export default FormFooter;
