import Collection from '../../models/auxiliary/collection';

const initialState = () => new Collection();

export default function (state = initialState(), action) {
  const { identifier, url, name } = action.payload || {};

  switch (action.type) {
    case 'FILES_ACTION_UPLOAD_ELEMENT':
      return state.withReplacedElements({ id: identifier, loading: true, failed: false });
    case 'FILES_ACTION_SET_ELEMENT':
      return state.withReplacedElements({ id: identifier, loading: false, url, name });
    case 'FILES_ACTION_MARK_FAILED_ELEMENT':
      return state.withReplacedElements({ id: identifier, loading: false, failed: true });
    case 'GLOBAL_ACTION_RESET':
      return initialState();
    default:
      return state;
  }
}
