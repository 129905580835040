import { blockVariants } from '../../shared/features/nodesGroupPanel/nodeText/textEditor/constants/blockVariants';
import { nestedElementVariants } from '../../shared/features/nodesGroupPanel/nodeText/textEditor/constants/nestedElementVariants';
import {
  leafVariants,
  leafVariantsValues,
} from '../../shared/features/nodesGroupPanel/nodeText/textEditor/constants/leafVariants';
import reduce from 'lodash/reduce';
import {
  alignmentTypes,
  defaultAlignmentType,
} from '../../shared/features/nodesGroupPanel/nodeText/textEditor/constants/alignmentTypes';

const leafMarkdown = {
  [leafVariants.bold]: '*',
  [leafVariants.italic]: '_',
  [leafVariants.underline]: '^',
  [leafVariants.strikethrough]: '~',
  [leafVariants.code]: '`',
};

const alignmentMarkdown = {
  [alignmentTypes.left]: '{',
  [alignmentTypes.center]: ':',
  [alignmentTypes.right]: '}',
  [alignmentTypes.justify]: ';',
};

const getBlockVariantMarkdown = (highestElement) => {
  const { type, nestingLevel, checked } = highestElement;

  if (type === blockVariants.bullet) return '-'.repeat(nestingLevel + 1);
  else if (type === blockVariants.orderedBullet) return '='.repeat(nestingLevel + 1);
  else if (type === blockVariants.checklistBullet) return (checked ? ',' : '.').repeat(nestingLevel + 1);
  return null;
};

// Pairs (toReplace|replacement)
// Warning: order of the elements is relevant
const escapesDefinitions = [
  // text styling
  ['*', '\\*'],
  ['_', '\\_'],
  ['^', '\\^'],
  ['~', '\\~'],
  ['`', '\\`'],
  // link
  ['<', '\\<'],
  ['|', '\\|'],
  ['>', '\\>'],
  // soft enter
  ['\n+', '\n\\+'],
  ['\n\n', '\n+\n'], // slightly hacky solution consisting of 3 replacements
  ['\n+\n\n', '\n+\n+\n'],
  ['\n+\n\n', '\n+\n+\n'],
  [/\n$/g, '\n+'],
  // alignment
  ['\n{', '\n\\{'],
  ['\n:', '\n\\:'],
  ['\n}', '\n\\}'],
  ['\n;', '\n\\;'],
  // block type
  ['\n-', '\n\\-'],
  ['\n=', '\n\\='],
  ['\n.', '\n\\.'],
  ['\n,', '\n\\,'],
];

const firstTextEscapesDefinitions = [
  [/^\+/g, '\\+'],
  [/^\{/g, '\\{'],
  [/^:/g, '\\:'],
  [/^\}/g, '\\}'],
  [/^;/g, '\\;'],
  [/^-/g, '\\-'],
  [/^=/g, '\\='],
  [/^\./g, '\\.'],
  [/^,/g, '\\,'],
];

const escapeLinkUrl = (linkUrl) => linkUrl.replaceAll('|', '\\|');

export const slateToPlain = (slateText) => {
  if (!slateText) return '';

  const leafMarkerActive = {
    [leafVariants.bold]: false,
    [leafVariants.italic]: false,
    [leafVariants.underline]: false,
    [leafVariants.strikethrough]: false,
    [leafVariants.code]: false,
  };

  let firstTextHandled = true;

  const escapeText = (text) => {
    let result = text;
    result = result.replaceAll('\\', '\\\\');

    if (firstTextHandled) {
      firstTextHandled = false;
      firstTextEscapesDefinitions.forEach(([toReplace, replacement]) => {
        result = result.replaceAll(toReplace, replacement);
      });
    }
    escapesDefinitions.forEach(([toReplace, replacement]) => {
      result = result.replaceAll(toReplace, replacement);
    });
    return result;
  };

  const handleLeaf = (leaf) => {
    if (!leaf.text) return '';

    let leafPlain = '';
    leafVariantsValues.forEach((variant) => {
      // start markdown
      if (leaf[variant] && !leafMarkerActive[variant]) {
        leafMarkerActive[variant] = true;
        leafPlain += leafMarkdown[variant];

        // end markdown
      } else if (!leaf[variant] && leafMarkerActive[variant]) {
        leafPlain += leafMarkdown[variant];
        leafMarkerActive[variant] = false;
      }
    });
    return leafPlain + escapeText(leaf.text);
  };

  // it is not necessary, although it prevents leaf markdowns from dangling
  // in the beginning of a new line
  const resetLeafMarkdowns = () =>
    reduce(
      leafVariantsValues,
      (resetPlain, variant) => {
        if (leafMarkerActive[variant]) {
          leafMarkerActive[variant] = false;
          return resetPlain + leafMarkdown[variant];
        }
        return resetPlain;
      },
      '',
    );

  const handleLink = (link) => {
    if (!link.children) return '';

    let linkPlain = '';
    link.children.forEach((leaf) => {
      linkPlain += handleLeaf(leaf);
    });
    if (!linkPlain) return '';

    return '<' + escapeLinkUrl(link.url || '') + '|' + linkPlain + '>';
  };

  let alignmentActive = defaultAlignmentType;
  let result = '';
  slateText.forEach((highestElement, index) => {
    const { alignmentType = defaultAlignmentType, children } = highestElement;
    if (index > 0) firstTextHandled = false;

    let highestElementMarkdowns = '';
    if (alignmentType !== alignmentActive) {
      alignmentActive = alignmentType;
      highestElementMarkdowns += alignmentMarkdown[alignmentType];
    }
    const blockVariantMarkdown = getBlockVariantMarkdown(highestElement);
    if (blockVariantMarkdown) highestElementMarkdowns += blockVariantMarkdown;

    if (index > 0) {
      if (highestElementMarkdowns) highestElementMarkdowns = '\n' + highestElementMarkdowns + ' ';
      else highestElementMarkdowns = '\n\n';
    } else if (highestElementMarkdowns) highestElementMarkdowns = highestElementMarkdowns + ' ';

    result += highestElementMarkdowns;

    children.forEach((element) => {
      if (!element.type) result += handleLeaf(element);
      else if (element.type === nestedElementVariants.link) result += handleLink(element);
    });

    result += resetLeafMarkdowns();
  });
  return result;
};
