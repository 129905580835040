import frenchkiss from 'frenchkiss';

frenchkiss.extend('en', {
  features: {
    audioController: {
      enableTrim: 'Trim',
      confirmTrim: 'Apply trimming',
      trimPrompt: 'Drag regions you want to trim, then confirm',
    },
  },
});
