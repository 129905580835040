import React, { useMemo } from 'react';
import SelectorField from '../../form/fieldTypes/SelectorField';
import LabelOption from './LabelOption';
import LabelMenu from './LabelMenu';
import LabelTrigger from './LabelTrigger';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';

const LabelSelectorField = (props) => {
  const { labels, object, incognitoMode } = props;

  const options = useMemo(
    () =>
      labels.visible(incognitoMode).map((label) => ({
        label: label.name,
        value: label.id,
        labelModel: label,
      })),
    [labels],
  );
  const labelsNames = useMemo(() => labels.elements.map((label) => label.name), [labels]);

  return (
    <SelectorField
      {...props}
      options={options}
      optionComponent={LabelOption}
      menu={<LabelMenu object={object} labelsNames={labelsNames} />}
      trigger={<LabelTrigger />}
      fixedMenuWidth={false}
    />
  );
};

export default connect(prepareState(['incognitoMode']))(LabelSelectorField);
