import FetchableAndLockable from './parents/fetchableAndLockable';

export default class DayEvaluationsChartData extends FetchableAndLockable {
  constructor() {
    super();
    this.variant = null;
    this.data = [];
  }

  visible() {
    return true;
  }
}
