import Collection from './collection';
import remove from 'lodash/remove';
import flatten from 'lodash/flatten';
import intersection from 'lodash/intersection';

export default class MultiFetchableCollection extends Collection {
  constructor(additionalData = {}) {
    super(additionalData);
    this.fetchedForIdentifiers = [];
    this.fetched = (fetchingIdentifiers) => {
      const ids = flatten([fetchingIdentifiers]);
      return ids.length === intersection(ids, this.fetchedForIdentifiers).length;
    };
  }

  toFetched(fetchingIdentifiers, createClone = true) {
    let result = this;
    if (createClone) result = this.shallowClone();
    flatten([fetchingIdentifiers]).forEach((fetchingIdentifier) =>
      result.fetchedForIdentifiers.push(fetchingIdentifier),
    );
    return result;
  }

  toNotFetched(fetchingIdentifiers, createClone = true) {
    let result = this;
    if (createClone) result = this.shallowClone();
    remove(result.fetchedForIdentifiers, (i) => flatten([fetchingIdentifiers]).includes(i));
    return result;
  }
}
