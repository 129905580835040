import { all, takeLatest, takeEvery, takeLeading } from 'redux-saga/effects';
import sessionSignInSaga from './session/sessionSignInSaga';
import sessionLookupSaga from './session/sessionLookupSaga';
import sessionSignOutSaga from './session/sessionSignOutSaga';
import fetchCategoriesSaga from './categories/fetchCategoriesSaga';
import updateElementCategoriesSaga from './categories/updateElementCategoriesSaga';
import createElementCategoriesSaga from './categories/createElementCategoriesSaga';
import deleteElementCategoriesSaga from './categories/deleteElementCategoriesSaga';
import unsetCategoriesSaga from './categories/unsetCategoriesSaga';
import fetchTasksSaga from './tasks/fetchTasksSaga';
import createElementTasksSaga from './tasks/createElementTasksSaga';
import checkElementTasksSaga from './tasks/checkElementTasksSaga';
import deleteElementTasksSaga from './tasks/deleteElementTasksSaga';
import snackbarMessagesActivateSaga from './snackbarMessages/snackbarMessagesActivateSaga';
import snackbarMessagesAddSaga from './snackbarMessages/snackbarMessagesAddSaga';
import snackbarMessagesRemoveActiveSaga from './snackbarMessages/snackbarMessagesRemoveActiveSaga';
import updateElementTasksSaga from './tasks/updateElementTasksSaga';
import markTodoElementTasksSaga from './tasks/markTodoElementTasksSaga';
import unmarkTodoElementTasksSaga from './tasks/unmarkTodoElementTasksSaga';
import incognitoModeDisableSaga from './incognitoMode/incognitoModeDisableSaga';
import incognitoModeEnableSaga from './incognitoMode/incognitoModeEnableSaga';
import markPrivateElementTasksSaga from './tasks/markPrivateElementTasksSaga';
import unmarkPrivateElementTasksSaga from './tasks/unmarkPrivateElementTasksSaga';
import markPrivateElementCategoriesSaga from './categories/markPrivateElementCategoriesSaga';
import unmarkPrivateElementCategoriesSaga from './categories/unmarkPrivateElementCategoriesSaga';
import draggedElementDropSaga from './draggedElement/draggedElementDropSaga';
import fetchCheckedTasksSaga from './checkedTasks/fetchCheckedTasksSaga';
import fetchElementDayEvaluationsSaga from './dayEvaluations/fetchElementDayEvaluationsSaga';
import updateElementDayEvaluationsSaga from './dayEvaluations/updateElementDayEvaluationsSaga';
import fetchHabitsSectionsSaga from './habitsSections/fetchHabitsSectionsSaga';
import createElementHabitsSectionsSaga from './habitsSections/createElementHabitsSectionsSaga';
import updateElementHabitsSectionsSaga from './habitsSections/updateElementHabitsSectionsSaga';
import markPrivateElementHabitsSectionsSaga from './habitsSections/markPrivateElementHabitsSectionsSaga';
import unmarkPrivateElementHabitsSectionsSaga from './habitsSections/unmarkPrivateElementHabitsSectionsSaga';
import deleteElementHabitsSectionsSaga from './habitsSections/deleteElementHabitsSectionsSaga';
import fetchHabitsSaga from './habits/fetchHabitsSaga';
import createElementHabitsSaga from './habits/createElementHabitsSaga';
import updateElementHabitsSaga from './habits/updateElementHabitsSaga';
import markPrivateElementHabitsSaga from './habits/markPrivateElementHabitsSaga';
import unmarkPrivateElementHabitsSaga from './habits/unmarkPrivateElementHabitsSaga';
import deleteElementHabitsSaga from './habits/deleteElementHabitsSaga';
import fetchHabitAnswersSaga from './habitAnswers/fetchHabitAnswersSaga';
import updateElementHabitAnswersSaga from './habitAnswers/updateElementHabitAnswersSaga';
import resetElementHabitAnswersSaga from './habitAnswers/resetElementHabitAnswersSaga';
import markUpdatedElementHabitAnswersSaga from './habitAnswers/markUpdatedElementHabitAnswersSaga';
import markHiddenElementHabitsSaga from './habits/markHiddenElementHabitsSaga';
import unmarkHiddenElementHabitsSaga from './habits/unmarkHiddenElementHabitsSaga';
import markHiddenElementHabitsSectionsSaga from './habitsSections/markHiddenElementHabitsSectionsSaga';
import unmarkHiddenElementHabitsSectionsSaga from './habitsSections/unmarkHiddenElementHabitsSectionsSaga';
import fetchHabitChartsDataSaga from './habitChartsData/fetchHabitChartsDataSaga';
import deleteElementDayEvaluationsSaga from './dayEvaluations/deleteElementDayEvaluationsSaga';
import fetchDayEvaluationsChartsDataSaga from './dayEvaluationsChartsData/fetchDayEvaluationsChartsDataSaga';
import fetchNodesSaga from './nodes/fetchNodesSaga';
import updateElementNodesSaga from './nodes/updateElementNodesSaga';
import createElementNodesSaga from './nodes/createElementNodesSaga';
import deleteElementNodesSaga from './nodes/deleteElementNodesSaga';
import markPrivateElementNodesSaga from './nodes/markPrivateElementNodesSaga';
import unmarkPrivateElementNodesSaga from './nodes/unmarkPrivateElementNodesSaga';
import duplicateElementNodesSaga from './nodes/duplicateElementNodesSaga';
import fetchNotesWorkspacesSaga from './notes_workspaces/fetchNotesWorkspacesSaga';
import createElementNotesWorkspacesSaga from './notes_workspaces/createElementNotesWorkspacesSaga';
import markPrivateElementNotesWorkspacesSaga from './notes_workspaces/markPrivateElementNotesWorkspacesSaga';
import unmarkPrivateElementNotesWorkspacesSaga from './notes_workspaces/unmarkPrivateElementNotesWorkspacesSaga';
import deleteElementNotesWorkspacesSaga from './notes_workspaces/deleteElementNotesWorkspacesSaga';
import updateElementNotesWorkspacesSaga from './notes_workspaces/updateElementNotesWorkspacesSaga';
import updateConfigSaga from './config/updateConfigSaga';
import fetchNotesSaga from './notes/fetchNotesSaga';
import updateElementNotesSaga from './notes/updateElementNotesSaga';
import createElementNotesSaga from './notes/createElementNotesSaga';
import markPrivateElementNotesSaga from './notes/markPrivateElementNotesSaga';
import unmarkPrivateElementNotesSaga from './notes/unmarkPrivateElementNotesSaga';
import deleteElementNotesSaga from './notes/deleteElementNotesSaga';
import duplicateElementNotesSaga from './notes/duplicateElementNotesSaga';
import fetchRecurringTasksSaga from './recurringTasks/fetchRecurringTasksSaga';
import createElementRecurringTasksSaga from './recurringTasks/createElementRecurringTasksSaga';
import updateElementRecurringTasksSaga from './recurringTasks/updateElementRecurringTasksSaga';
import markPrivateElementRecurringTasksSaga from './recurringTasks/markPrivateElementRecurringTasksSaga';
import unmarkPrivateElementRecurringTasksSaga from './recurringTasks/unmarkPrivateElementRecurringTasksSaga';
import deleteElementRecurringTasksSaga from './recurringTasks/deleteElementRecurringTasksSaga';
import generatedLookupTasksSaga from './tasks/generatedLookupTasksSaga';
import fetchEventsSaga from './events/fetchEventsSaga';
import createElementEventsSaga from './events/createElementEventsSaga';
import updateElementEventsSaga from './events/updateElementEventsSaga';
import markPrivateElementEventsSaga from './events/markPrivateElementEventsSaga';
import unmarkPrivateElementEventsSaga from './events/unmarkPrivateElementEventsSaga';
import deleteElementEventsSaga from './events/deleteElementEventsSaga';
import fetchRecurringEventsSaga from './recurringEvents/fetchRecurringEventsSaga';
import createElementRecurringEventsSaga from './recurringEvents/createElementRecurringEventsSaga';
import updateElementRecurringEventsSaga from './recurringEvents/updateElementRecurringEventsSaga';
import markPrivateElementRecurringEventsSaga from './recurringEvents/markPrivateElementRecurringEventsSaga';
import unmarkPrivateElementRecurringEventsSaga from './recurringEvents/unmarkPrivateElementRecurringEventsSaga';
import deleteElementRecurringEventsSaga from './recurringEvents/deleteElementRecurringEventsSaga';
import uploadElementFilesSaga from './files/uploadElementFilesSaga';
import updateAudioUrlElementNodesSaga from './nodes/updateAudioUrlElementNodesSaga';
import fetchLabelsSaga from './labels/fetchLabelsSaga';
import unmarkPrivateElementLabelsSaga from './labels/unmarkPrivateElementLabelsSaga';
import markPrivateElementLabelsSaga from './labels/markPrivateElementLabelsSaga';
import toggleAssignmentLabelsSaga from './labels/toggleAssignmentLabelsSaga';
import fetchLearningWorkspacesSaga from './learningWorkspaces/fetchLearningWorkspacesSaga';
import createElementLearningWorkspacesSaga from './learningWorkspaces/createElementLearningWorkspacesSaga';
import updateElementLearningWorkspacesSaga from './learningWorkspaces/updateElementLearningWorkspacesSaga';
import markPrivateElementLearningWorkspacesSaga from './learningWorkspaces/markPrivateElementLearningWorkspacesSaga';
import unmarkPrivateElementLearningWorkspacesSaga from './learningWorkspaces/unmarkPrivateElementLearningWorkspacesSaga';
import deleteElementLearningWorkspacesSaga from './learningWorkspaces/deleteElementLearningWorkspacesSaga';
import fetchLanguageWorkspacesSaga from './languageWorkspaces/fetchLanguageWorkspacesSaga';
import createElementLanguageWorkspacesSaga from './languageWorkspaces/createElementLanguageWorkspacesSaga';
import updateElementLanguageWorkspacesSaga from './languageWorkspaces/updateElementLanguageWorkspacesSaga';
import markPrivateElementLanguageWorkspacesSaga from './languageWorkspaces/markPrivateElementLanguageWorkspacesSaga';
import unmarkPrivateElementLanguageWorkspacesSaga from './languageWorkspaces/unmarkPrivateElementLanguageWorkspacesSaga';
import deleteElementLanguageWorkspacesSaga from './languageWorkspaces/deleteElementLanguageWorkspacesSaga';
import fetchLearningQueuesSaga from './learningQueues/fetchLearningQueuesSaga';
import updateElementLearningQueuesSaga from './learningQueues/updateElementLearningQueuesSaga';
import createElementLearningQueuesSaga from './learningQueues/createElementLearningQueuesSaga';
import markPrivateElementLearningQueuesSaga from './learningQueues/markPrivateElementLearningQueuesSaga';
import unmarkPrivateElementLearningQueuesSaga from './learningQueues/unmarkPrivateElementLearningQueuesSaga';
import deleteElementLearningQueuesSaga from './learningQueues/deleteElementLearningQueuesSaga';
import unsetEventsSaga from './events/unsetEventsSaga';
import unsetHabitsSectionsSaga from './habitsSections/unsetHabitsSectionsSaga';
import unsetLearningWorkspacesSaga from './learningWorkspaces/unsetLearningWorkspacesSaga';
import unsetHabitsSaga from './habits/unsetHabitsSaga';
import unsetNotesWorkspacesSaga from './notes_workspaces/unsetNotesWorkspacesSaga';
import unsetTasksSaga from './tasks/unsetTasksSaga';
import unsetRecurringTasksSaga from './recurringTasks/unsetRecurringTasksSaga';
import unsetRecurringEventsSaga from './recurringEvents/unsetRecurringEventsSaga';
import unsetNotesSaga from './notes/unsetNotesSaga';
import fetchWastesSaga from './wastes/fetchWastesSaga';
import restoreElementWastesSaga from './wastes/restoreElementWastesSaga';
import deleteElementWastesSaga from './wastes/deleteElementWastesSaga copy';

export default function* () {
  yield all([
    takeLatest('SESSION_ACTION_LOOKUP', sessionLookupSaga),
    takeLatest('SESSION_ACTION_SIGN_IN', sessionSignInSaga),
    takeLatest('SESSION_ACTION_SIGN_OUT', sessionSignOutSaga),
    takeEvery('CONFIG_ACTION_UPDATE', updateConfigSaga),
    takeLatest('INCOGNITO_MODE_ACTION_ENABLE', incognitoModeEnableSaga),
    takeLatest('INCOGNITO_MODE_ACTION_DISABLE', incognitoModeDisableSaga),
    takeEvery('SNACKBAR_MESSAGES_ACTION_ADD', snackbarMessagesAddSaga),
    takeEvery('SNACKBAR_MESSAGES_ACTION_ACTIVATE', snackbarMessagesActivateSaga),
    takeEvery('SNACKBAR_MESSAGES_ACTION_REMOVE_ACTIVE', snackbarMessagesRemoveActiveSaga),
    takeEvery('FILES_ACTION_UPLOAD_ELEMENT', uploadElementFilesSaga),
    takeEvery('DRAGGED_ELEMENT_ACTION_DROP', draggedElementDropSaga),
    takeLatest('CATEGORIES_ACTION_FETCH', fetchCategoriesSaga),
    takeLeading('CATEGORIES_ACTION_CREATE_ELEMENT', createElementCategoriesSaga),
    takeEvery('CATEGORIES_ACTION_UPDATE_ELEMENT', updateElementCategoriesSaga),
    takeEvery('CATEGORIES_ACTION_MARK_PRIVATE_ELEMENT', markPrivateElementCategoriesSaga),
    takeEvery('CATEGORIES_ACTION_UNMARK_PRIVATE_ELEMENT', unmarkPrivateElementCategoriesSaga),
    takeEvery('CATEGORIES_ACTION_DELETE_ELEMENT', deleteElementCategoriesSaga),
    takeEvery('CATEGORIES_ACTION_UNSET', unsetCategoriesSaga),
    takeLatest('TASKS_ACTION_FETCH', fetchTasksSaga),
    takeEvery('TASKS_ACTION_GENERATED_LOOKUP', generatedLookupTasksSaga),
    takeLeading('TASKS_ACTION_CREATE_ELEMENT', createElementTasksSaga),
    takeEvery('TASKS_ACTION_UPDATE_ELEMENT', updateElementTasksSaga),
    takeEvery('TASKS_ACTION_CHECK_ELEMENT', checkElementTasksSaga),
    takeEvery('TASKS_ACTION_MARK_TODO_ELEMENT', markTodoElementTasksSaga),
    takeEvery('TASKS_ACTION_UNMARK_TODO_ELEMENT', unmarkTodoElementTasksSaga),
    takeEvery('TASKS_ACTION_MARK_PRIVATE_ELEMENT', markPrivateElementTasksSaga),
    takeEvery('TASKS_ACTION_UNMARK_PRIVATE_ELEMENT', unmarkPrivateElementTasksSaga),
    takeEvery('TASKS_ACTION_DELETE_ELEMENT', deleteElementTasksSaga),
    takeEvery('TASKS_ACTION_UNSET', unsetTasksSaga),
    takeEvery('CHECKED_TASKS_ACTION_FETCH', fetchCheckedTasksSaga),
    takeLatest('RECURRING_TASKS_ACTION_FETCH', fetchRecurringTasksSaga),
    takeLeading('RECURRING_TASKS_ACTION_CREATE_ELEMENT', createElementRecurringTasksSaga),
    takeEvery('RECURRING_TASKS_ACTION_UPDATE_ELEMENT', updateElementRecurringTasksSaga),
    takeEvery('RECURRING_TASKS_ACTION_MARK_PRIVATE_ELEMENT', markPrivateElementRecurringTasksSaga),
    takeEvery('RECURRING_TASKS_ACTION_UNMARK_PRIVATE_ELEMENT', unmarkPrivateElementRecurringTasksSaga),
    takeEvery('RECURRING_TASKS_ACTION_DELETE_ELEMENT', deleteElementRecurringTasksSaga),
    takeEvery('RECURRING_TASKS_ACTION_UNSET', unsetRecurringTasksSaga),
    takeEvery('DAY_EVALUATIONS_ACTION_FETCH_ELEMENT', fetchElementDayEvaluationsSaga),
    takeEvery('DAY_EVALUATIONS_ACTION_UPDATE_ELEMENT', updateElementDayEvaluationsSaga),
    takeEvery('DAY_EVALUATIONS_ACTION_DELETE_ELEMENT', deleteElementDayEvaluationsSaga),
    takeEvery('DAY_EVALUATIONS_CHARTS_DATA_ACTION_FETCH', fetchDayEvaluationsChartsDataSaga),
    takeLatest('HABITS_SECTIONS_ACTION_FETCH', fetchHabitsSectionsSaga),
    takeLeading('HABITS_SECTIONS_ACTION_CREATE_ELEMENT', createElementHabitsSectionsSaga),
    takeEvery('HABITS_SECTIONS_ACTION_UPDATE_ELEMENT', updateElementHabitsSectionsSaga),
    takeEvery('HABITS_SECTIONS_ACTION_MARK_PRIVATE_ELEMENT', markPrivateElementHabitsSectionsSaga),
    takeEvery('HABITS_SECTIONS_ACTION_UNMARK_PRIVATE_ELEMENT', unmarkPrivateElementHabitsSectionsSaga),
    takeEvery('HABITS_SECTIONS_ACTION_MARK_HIDDEN_ELEMENT', markHiddenElementHabitsSectionsSaga),
    takeEvery('HABITS_SECTIONS_ACTION_UNMARK_HIDDEN_ELEMENT', unmarkHiddenElementHabitsSectionsSaga),
    takeEvery('HABITS_SECTIONS_ACTION_DELETE_ELEMENT', deleteElementHabitsSectionsSaga),
    takeEvery('HABITS_SECTIONS_ACTION_UNSET', unsetHabitsSectionsSaga),
    takeLatest('HABITS_ACTION_FETCH', fetchHabitsSaga),
    takeLeading('HABITS_ACTION_CREATE_ELEMENT', createElementHabitsSaga),
    takeEvery('HABITS_ACTION_UPDATE_ELEMENT', updateElementHabitsSaga),
    takeEvery('HABITS_ACTION_MARK_PRIVATE_ELEMENT', markPrivateElementHabitsSaga),
    takeEvery('HABITS_ACTION_UNMARK_PRIVATE_ELEMENT', unmarkPrivateElementHabitsSaga),
    takeEvery('HABITS_ACTION_MARK_HIDDEN_ELEMENT', markHiddenElementHabitsSaga),
    takeEvery('HABITS_ACTION_UNMARK_HIDDEN_ELEMENT', unmarkHiddenElementHabitsSaga),
    takeEvery('HABITS_ACTION_DELETE_ELEMENT', deleteElementHabitsSaga),
    takeEvery('HABITS_ACTION_UNSET', unsetHabitsSaga),
    takeEvery('HABIT_ANSWERS_ACTION_FETCH', fetchHabitAnswersSaga),
    takeEvery('HABIT_ANSWERS_ACTION_UPDATE_ELEMENT', updateElementHabitAnswersSaga),
    takeEvery('HABIT_ANSWERS_ACTION_MARK_UPDATED_ELEMENT', markUpdatedElementHabitAnswersSaga),
    takeEvery('HABIT_ANSWERS_ACTION_RESET_ELEMENT', resetElementHabitAnswersSaga),
    takeEvery('HABIT_CHARTS_DATA_ACTION_FETCH', fetchHabitChartsDataSaga),
    takeEvery('NODES_ACTION_FETCH', fetchNodesSaga),
    takeEvery('NODES_ACTION_UPDATE_ELEMENT', updateElementNodesSaga),
    takeEvery('NODES_ACTION_UPDATE_AUDIO_URL_ELEMENT', updateAudioUrlElementNodesSaga),
    takeLeading('NODES_ACTION_CREATE_ELEMENT', createElementNodesSaga),
    takeLeading('NODES_ACTION_DUPLICATE_ELEMENT', duplicateElementNodesSaga),
    takeLeading('NODES_ACTION_MARK_PRIVATE_ELEMENT', markPrivateElementNodesSaga),
    takeLeading('NODES_ACTION_UNMARK_PRIVATE_ELEMENT', unmarkPrivateElementNodesSaga),
    takeEvery('NODES_ACTION_DELETE_ELEMENT', deleteElementNodesSaga),
    takeLatest('NOTES_WORKSPACES_ACTION_FETCH', fetchNotesWorkspacesSaga),
    takeLeading('NOTES_WORKSPACES_ACTION_CREATE_ELEMENT', createElementNotesWorkspacesSaga),
    takeEvery('NOTES_WORKSPACES_ACTION_UPDATE_ELEMENT', updateElementNotesWorkspacesSaga),
    takeEvery('NOTES_WORKSPACES_ACTION_MARK_PRIVATE_ELEMENT', markPrivateElementNotesWorkspacesSaga),
    takeEvery('NOTES_WORKSPACES_ACTION_UNMARK_PRIVATE_ELEMENT', unmarkPrivateElementNotesWorkspacesSaga),
    takeEvery('NOTES_WORKSPACES_ACTION_DELETE_ELEMENT', deleteElementNotesWorkspacesSaga),
    takeEvery('NOTES_WORKSPACES_ACTION_UNSET', unsetNotesWorkspacesSaga),
    takeEvery('NOTES_ACTION_FETCH', fetchNotesSaga),
    takeEvery('NOTES_ACTION_UPDATE_ELEMENT', updateElementNotesSaga),
    takeLeading('NOTES_ACTION_CREATE_ELEMENT', createElementNotesSaga),
    takeLeading('NOTES_ACTION_DUPLICATE_ELEMENT', duplicateElementNotesSaga),
    takeLeading('NOTES_ACTION_MARK_PRIVATE_ELEMENT', markPrivateElementNotesSaga),
    takeLeading('NOTES_ACTION_UNMARK_PRIVATE_ELEMENT', unmarkPrivateElementNotesSaga),
    takeEvery('NOTES_ACTION_DELETE_ELEMENT', deleteElementNotesSaga),
    takeEvery('NOTES_ACTION_UNSET', unsetNotesSaga),
    takeLatest('EVENTS_ACTION_FETCH', fetchEventsSaga),
    takeLeading('EVENTS_ACTION_CREATE_ELEMENT', createElementEventsSaga),
    takeEvery('EVENTS_ACTION_UPDATE_ELEMENT', updateElementEventsSaga),
    takeEvery('EVENTS_ACTION_MARK_PRIVATE_ELEMENT', markPrivateElementEventsSaga),
    takeEvery('EVENTS_ACTION_UNMARK_PRIVATE_ELEMENT', unmarkPrivateElementEventsSaga),
    takeEvery('EVENTS_ACTION_DELETE_ELEMENT', deleteElementEventsSaga),
    takeEvery('EVENTS_ACTION_UNSET', unsetEventsSaga),
    takeLatest('RECURRING_EVENTS_ACTION_FETCH', fetchRecurringEventsSaga),
    takeLeading('RECURRING_EVENTS_ACTION_CREATE_ELEMENT', createElementRecurringEventsSaga),
    takeEvery('RECURRING_EVENTS_ACTION_UPDATE_ELEMENT', updateElementRecurringEventsSaga),
    takeEvery('RECURRING_EVENTS_ACTION_MARK_PRIVATE_ELEMENT', markPrivateElementRecurringEventsSaga),
    takeEvery('RECURRING_EVENTS_ACTION_UNMARK_PRIVATE_ELEMENT', unmarkPrivateElementRecurringEventsSaga),
    takeEvery('RECURRING_EVENTS_ACTION_DELETE_ELEMENT', deleteElementRecurringEventsSaga),
    takeEvery('RECURRING_EVENTS_ACTION_UNSET', unsetRecurringEventsSaga),
    takeLeading('LABELS_ACTION_FETCH', fetchLabelsSaga),
    takeEvery('LABELS_ACTION_MARK_PRIVATE_ELEMENT', markPrivateElementLabelsSaga),
    takeEvery('LABELS_ACTION_UNMARK_PRIVATE_ELEMENT', unmarkPrivateElementLabelsSaga),
    takeEvery('LABELS_ACTION_TOGGLE_ASSIGNMENT', toggleAssignmentLabelsSaga),
    takeLatest('LEARNING_WORKSPACES_ACTION_FETCH', fetchLearningWorkspacesSaga),
    takeLeading('LEARNING_WORKSPACES_ACTION_CREATE_ELEMENT', createElementLearningWorkspacesSaga),
    takeEvery('LEARNING_WORKSPACES_ACTION_UPDATE_ELEMENT', updateElementLearningWorkspacesSaga),
    takeEvery('LEARNING_WORKSPACES_ACTION_MARK_PRIVATE_ELEMENT', markPrivateElementLearningWorkspacesSaga),
    takeEvery('LEARNING_WORKSPACES_ACTION_UNMARK_PRIVATE_ELEMENT', unmarkPrivateElementLearningWorkspacesSaga),
    takeEvery('LEARNING_WORKSPACES_ACTION_DELETE_ELEMENT', deleteElementLearningWorkspacesSaga),
    takeEvery('LEARNING_WORKSPACES_ACTION_UNSET', unsetLearningWorkspacesSaga),
    takeLatest('LANGUAGE_WORKSPACES_ACTION_FETCH', fetchLanguageWorkspacesSaga),
    takeLeading('LANGUAGE_WORKSPACES_ACTION_CREATE_ELEMENT', createElementLanguageWorkspacesSaga),
    takeEvery('LANGUAGE_WORKSPACES_ACTION_UPDATE_ELEMENT', updateElementLanguageWorkspacesSaga),
    takeEvery('LANGUAGE_WORKSPACES_ACTION_MARK_PRIVATE_ELEMENT', markPrivateElementLanguageWorkspacesSaga),
    takeEvery('LANGUAGE_WORKSPACES_ACTION_UNMARK_PRIVATE_ELEMENT', unmarkPrivateElementLanguageWorkspacesSaga),
    takeEvery('LANGUAGE_WORKSPACES_ACTION_DELETE_ELEMENT', deleteElementLanguageWorkspacesSaga),
    takeEvery('LEARNING_QUEUES_ACTION_FETCH', fetchLearningQueuesSaga),
    takeEvery('LEARNING_QUEUES_ACTION_UPDATE_ELEMENT', updateElementLearningQueuesSaga),
    takeLeading('LEARNING_QUEUES_ACTION_CREATE_ELEMENT', createElementLearningQueuesSaga),
    takeLeading('LEARNING_QUEUES_ACTION_MARK_PRIVATE_ELEMENT', markPrivateElementLearningQueuesSaga),
    takeLeading('LEARNING_QUEUES_ACTION_UNMARK_PRIVATE_ELEMENT', unmarkPrivateElementLearningQueuesSaga),
    takeEvery('LEARNING_QUEUES_ACTION_DELETE_ELEMENT', deleteElementLearningQueuesSaga),
    takeLatest('WASTES_ACTION_FETCH', fetchWastesSaga),
    takeLatest('WASTES_ACTION_RESTORE_ELEMENT', restoreElementWastesSaga),
    takeLatest('WASTES_ACTION_DELETE_ELEMENT', deleteElementWastesSaga),
  ]);
}
