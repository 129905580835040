import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../../../helpers/stateManagementHelpers';
import ActivableContainer from '../../../../shared/organizers/containers/ActivableContainer';
import ActiveElement from '../../../../models/auxiliary/activeElement';
import { activeElementTypes } from '../../../../constants/activeElementTypes';
import useNarrowMainWidth from '../../../../hooks/useNarrowMainWidth';
import RecurringTaskHeader from './RecurringTaskHeader';
import { customColors } from '../../../../constants/customColors';
import RecurringTaskForm from './RecurringTaskForm';
import OnClickOutsideWrapper from '../../../../shared/onClickOutside/OnClickOutsideWrapper';

const RecurringTaskTile = (props) => {
  const { categories, recurringTask, draggableListRef } = props;

  const [editionMode, setEditionMode] = useState(false);
  useEffect(() => {
    if (!editionMode) draggableListRef.current.reinitialize();
  }, [editionMode]);

  const width = useNarrowMainWidth();
  const activeWidth = useNarrowMainWidth(false);

  const category = categories.find({ id: recurringTask.categoryId });

  return (
    <ActivableContainer
      activeElement={new ActiveElement(activeElementTypes.recurringTask, recurringTask.id)}
      themeType="container"
      themeCrumbProps={{
        mixinColor: editionMode ? false : customColors[category.color].primary,
        mixedStyleKeysMultipliers: {
          borderColor: 0.2,
        },
      }}
      loading={recurringTask.locked}
      width={width}
      activeWidth={activeWidth}
      className={'transition-all'}
      renderCommonPart={(containerProps) => (
        <RecurringTaskHeader
          {...containerProps}
          recurringTask={recurringTask}
          category={category}
          activateEditionMode={() => {
            setEditionMode(true);
            containerProps.activateContainer();
            draggableListRef.current.disableDragging();
          }}
        />
      )}
      renderActive={(containerProps) =>
        editionMode ? (
          <RecurringTaskForm
            {...recurringTask}
            onBlur={() => {
              setEditionMode(false);
              containerProps.deactivateContainer();
              draggableListRef.current.enableDragging();
            }}
          />
        ) : (
          <OnClickOutsideWrapper onClickOutside={containerProps.toggleContainerActivity}>
            <div>
              {containerProps.commonPart}
              <RecurringTaskForm editionMode={false} {...recurringTask} />
            </div>
          </OnClickOutsideWrapper>
        )
      }
    />
  );
};

export default connect(prepareState(['categories']))(RecurringTaskTile);
