import frenchkiss from 'frenchkiss';

frenchkiss.extend('en', {
  features: {
    eventPanel: {
      name: 'Name',
      date: 'Date',
      noDetails: 'NO DETAILS',
      details: 'Additional details',
    },
  },
});
