import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { activeElementsActionDeactivateAllOfType } from '../../stateManagement/actions/activeElementsActions';
import useNarrowMainWidth from '../../hooks/useNarrowMainWidth';
import EventCreator from '../../shared/features/eventCreator';
import List from '../../shared/lists/List';
import { draggedElements } from '../../constants/draggedElements';
import EventPanel from '../../shared/features/eventPanel';

const EventsList = (props) => {
  const { events, date, disabled, creationActive } = props;
  const draggableListRef = useRef(null);

  const width = useNarrowMainWidth();
  const activeWidth = useNarrowMainWidth(false);

  return (
    <>
      {creationActive ? (
        <div className="w-full flex justify-center mb-2">
          <div style={{ width }}>
            <EventCreator date={date} cancellable />
          </div>
        </div>
      ) : (
        <></>
      )}
      <List
        collection={events}
        draggable={
          disabled
            ? null
            : {
                ref: draggableListRef,
                draggedElementType: draggedElements.event,
              }
        }
        noDataCondition={events.length === 0 && !creationActive}
      >
        {(event) => (
          <div key={event.id} className="w-full flex justify-center mt-2 first:mt-0">
            <EventPanel
              event={event}
              draggableListRef={draggableListRef}
              width={width}
              activeWidth={activeWidth}
              disabled={disabled}
            />
          </div>
        )}
      </List>
    </>
  );
};

export default connect(null, { activeElementsActionDeactivateAllOfType })(EventsList);
