import { produceModelFromDto, produceModelToDto } from './modelHelpers';
import Node from '../../models/node';

const mappings = [
  ['id', 'id'],
  ['nodes_group_id', 'nodesGroupId'],
  ['variant', 'variant'],
  ['content', 'content'],
  ['additional_content_above', 'additionalContentAbove'],
  ['additional_content_below', 'additionalContentBelow'],
  ['audio_url', 'audioUrl'],
  ['audio_mask', 'audioMask'],
  ['labels_ids', 'labelsIds'],
  ['default_order', 'defaultOrder'],
  ['private', 'private'],
];

export const nodeFromDto = produceModelFromDto(Node, mappings);

export const nodeToDto = produceModelToDto(mappings);
