import React from 'react';
import Collection from '../../../../models/auxiliary/collection';
import { connect } from 'react-redux';
import { prepareState } from '../../../../helpers/stateManagementHelpers';
import HabitChart from '../../../charts/habitChart';
import find from 'lodash/find';
import ModalHeader from '../../ModalHeader';
import { activeElementTypes } from '../../../../constants/activeElementTypes';
import ModalBody from '../../ModalBody';

const HabitChartModalContent = (props) => {
  const { date, habitId, chartRequestId, habits, habitChartsData } = props;

  const habit = habits.find({ id: habitId });
  const chartRequest = find(habit.habitChartRequests, { id: parseInt(chartRequestId, 10) });

  const chartDataForDate = habitChartsData[date] || {};
  const chartDataForHabit = chartDataForDate[habitId] || new Collection();
  const chartData = chartDataForHabit.find({
    habitChartRequestId: chartRequestId,
  });

  const minPixelsPerLabel = 50;
  let labelsMaxNumber = window.innerWidth / minPixelsPerLabel;
  if (labelsMaxNumber < 5) labelsMaxNumber = 5;

  return (
    <div className="w-almost-screen">
      <ModalHeader type={activeElementTypes.habitChartModal} identifier={`${date}-${chartRequestId}`}>
        {habit.name}
      </ModalHeader>
      <ModalBody>
        <HabitChart
          habit={habit}
          habitChartRequest={chartRequest}
          chartData={chartData}
          heightClass="h-half-screen h-max-half-screen-width"
          labelsMaxNumber={labelsMaxNumber}
        />
      </ModalBody>
    </div>
  );
};

export default connect(prepareState(['habits', 'habitChartsData']))(HabitChartModalContent);
