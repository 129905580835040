import React from 'react';
import ThemeCrumb from './ThemeCrumb';

const Separator = (props) => {
  const { className } = props;
  return (
    <ThemeCrumb type="separator">
      <div className={`w-full border-b ${className}`} />
    </ThemeCrumb>
  );
};

export default Separator;
