import React from 'react';
import { compact } from 'lodash';

const ButtonsGroup = (props) => {
  const { buttons = [], className = '', buttonWrappersClassNames = buttons.map(() => '') } = props;

  return (
    <div className={`flex ${className}`}>
      {compact(
        buttons.map((button, index) => {
          if (!button) return null;
          return (
            <div key={button.key} className={`ml-1 first:ml-0 ${buttonWrappersClassNames[index]}`}>
              {button}
            </div>
          );
        }),
      )}
    </div>
  );
};

export default ButtonsGroup;
