import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../../../helpers/stateManagementHelpers';
import { sortBy } from 'lodash';
import List from '../../../../shared/lists/List';
import { draggedElements } from '../../../../constants/draggedElements';
import RecurringEventTile from './RecurringEventTile';

const RecurringEventsList = (props) => {
  const { additionMode, recurringEvents, incognitoMode } = props;

  const draggableListRef = useRef(null);

  const visibleRecurringEvents = sortBy(recurringEvents.visible(incognitoMode), 'defaultOrder');
  const listEmpty = visibleRecurringEvents.length === 0;

  return (
    <List
      collection={visibleRecurringEvents}
      draggable={{
        ref: draggableListRef,
        draggedElementType: draggedElements.recurringEvent,
      }}
      noDataCondition={additionMode ? false : listEmpty}
      className={additionMode && !listEmpty ? 'mt-4' : ''}
    >
      {(recurringEvent) => (
        <div key={recurringEvent.id} className="mb-4 last:mb-0 flex w-full justify-center">
          <RecurringEventTile recurringEvent={recurringEvent} draggableListRef={draggableListRef} />
        </div>
      )}
    </List>
  );
};

export default connect(prepareState(['recurringEvents', 'incognitoMode']))(RecurringEventsList);
