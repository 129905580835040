import { useCallback, useRef } from 'react';
import { DateTime } from 'luxon';

export const useOnInputChange = (props) => {
  const {
    words,
    currentWord,
    currentWordIndex,
    setErroneousCharsCount,
    setInputValue,
    setCurrentWordIndex,
    setHightlightedCharsInCurrentWordCount,
    setHighlightedCharsInPreviousWordsCount,
    onFinished,
    setStartedAt,
  } = props;

  const startedAtAlreadySet = useRef(false);

  return useCallback(
    ({ event }) => {
      if (!startedAtAlreadySet.current) {
        setStartedAt(DateTime.now());
        startedAtAlreadySet.current = true;
      }

      const newValue = event.target.value;
      setInputValue(newValue);

      const lastWord = words.length === currentWordIndex + 1;

      if (newValue.match(new RegExp(`${currentWord}\\s`)) || (lastWord && newValue === currentWord)) {
        setErroneousCharsCount(0);
        setHightlightedCharsInCurrentWordCount(0);
        setHighlightedCharsInPreviousWordsCount((prevValue) => prevValue + currentWord.length);
        setInputValue('');
        setCurrentWordIndex(currentWordIndex + 1);

        if (words.length - 1 === currentWordIndex) {
          onFinished();
        }

        return;
      }

      if (newValue.length === 0) setErroneousCharsCount(0);

      let matchCount = 0;
      let errorDetected = false;

      for (let i = 0; i < newValue.length && i < currentWord.length; i++) {
        if (newValue[i] === currentWord[i]) {
          matchCount++;
        } else {
          errorDetected = true;
          setErroneousCharsCount(newValue.length - matchCount);
          break;
        }
      }

      if (matchCount < newValue.length) {
        errorDetected = true;
        setErroneousCharsCount(newValue.length - matchCount);
      }

      if (!errorDetected) setErroneousCharsCount(0);
      setHightlightedCharsInCurrentWordCount(matchCount);
    },
    [onFinished, words, currentWord, currentWordIndex],
  );
};
