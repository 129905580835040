import React, { useEffect } from 'react';
import { customPropsToValues, produceValuesFilter } from '../../helpers/formHelpers';
import HabitAnswer from '../../models/habitAnswer';
import { connect } from 'react-redux';
import {
  habitAnswersActionMarkUpdatedElement,
  habitAnswersActionResetElement,
  habitAnswersActionUpdateElement,
} from '../../stateManagement/actions/habitAnswersActions';
import { withFormik } from 'formik';
import LabelField from '../../shared/form/LabelField';
import CheckboxField from '../../shared/form/fieldTypes/CheckBoxField';
import CounterField from '../../shared/form/fieldTypes/CounterField';
import SmartField from '../../shared/form/SmartField';
import TextAreaField from '../../shared/form/fieldTypes/TextAreaField';
import NumberField from '../../shared/form/fieldTypes/NumberField';
import SmartForm from '../../shared/form/SmartForm';
import HabitSummaries from './HabitSummaries';
import AuxiliaryText from '../../shared/AuxiliaryText';
import { frontEndErrorT, initT } from '../../helpers/i18nHelpers';
import ChartPanel from './ChartPanel';
import chunk from 'lodash/chunk';
import ChartPanelsContainer from './ChartPanelsContainer';
import { habitChartsDataActionFetch } from '../../stateManagement/actions/habitChartsDataActions';
import * as yup from 'yup';
import isNil from 'lodash/isNil';
import Container from '../../shared/organizers/containers/Container';
import LabelledButton from '../../shared/buttons/LabelledButton';

const t = initT('pages.calendar.habitAnswers');

const relevantAttributes = ['id', 'answer', 'date'];
const auxiliaryAttributes = [
  'locked',
  'wasUpdated',
  'habit',
  'section',
  'habitSummaries',
  'calendarLocked',
  'habitAnswersActionMarkUpdatedElement',
  'habitAnswersActionResetElement',
  'habitChartsDataActionFetch',
];
const filterValues = produceValuesFilter(relevantAttributes);

const validationSchema = () =>
  yup.object().shape({
    answer: yup.mixed().test('empty', frontEndErrorT('habitAnswer.answer.empty'), (value) => !isNil(value)),
  });

const answerFields = (fieldParams, onBlur, conditionalOnBlur) => ({
  checkbox: () => (
    <SmartField {...fieldParams} wrapperClass="w-full text-left" centered onBlur={onBlur} component={CheckboxField} />
  ),
  counter: () => (
    <LabelField {...fieldParams} className="w-full max-w-64" onBlur={onBlur} labelClass="" component={CounterField} />
  ),
  realNumber: () => (
    <LabelField
      {...fieldParams}
      className="w-full max-w-64"
      labelClass=""
      component={NumberField}
      onBlur={conditionalOnBlur}
    />
  ),
  text: () => (
    <LabelField
      {...fieldParams}
      className="w-full"
      labelClass=""
      component={TextAreaField}
      onBlur={conditionalOnBlur}
    />
  ),
});

const HabitAnswerInnerForm = (props) => {
  const {
    handleSubmit,
    initialValues,
    values,
    values: {
      answer,
      date,
      auxiliary: {
        locked,
        wasUpdated,
        habit,
        habitSummaries,
        calendarLocked,
        habitAnswersActionMarkUpdatedElement: markUpdated,
        habitAnswersActionResetElement: reset,
        habitChartsDataActionFetch: fetchChartsData,
      },
    },
  } = props;

  const fieldParams = {
    name: 'answer',
    label: habit.name,
    presentationMode: calendarLocked,
  };
  const fieldsDictionary = answerFields(fieldParams, handleSubmit, () => {
    if (answer !== initialValues.answer) handleSubmit();
  });
  const habitAnswer = new HabitAnswer().assignValues(filterValues(values));
  const updatedStatusRelevant = habit.considerOnlyUpdatedValues && !calendarLocked;
  const chartRequests = habit.habitChartRequests;
  const chartsCount = chartRequests.length;
  const useChartsSets = updatedStatusRelevant ? chartsCount > 1 : chartsCount > 2;
  const chartRequestsSets = useChartsSets ? chunk(chartRequests, 3) : [];

  useEffect(() => {
    if (chartsCount > 0) fetchChartsData(date, habit.id);
  }, []);

  const manageUpdateButton = wasUpdated ? (
    <LabelledButton variant="reset" onMouseDown={() => reset(habitAnswer)} />
  ) : (
    <LabelledButton variant="markUpdated" onMouseDown={() => markUpdated(habitAnswer)} />
  );
  const mixinType = `${habit.variant}HabitDenotation`;

  return (
    <Container
      key={answer.id}
      loading={locked}
      themeCrumbProps={{
        mixinType,
        mixedStyleKeysMultipliers: {
          borderColor: 0.3,
          backgroundColor: 0.01,
        },
      }}
      roundedClass="first:rounded-t-xl last:rounded-b-xl mt-1"
      borderClass="border last:border-b "
    >
      <div className="w-full pt-4 px-4 lg:grid lg:grid-cols-3 gap-25px">
        <div className="lg:order-2">
          <SmartForm>
            <div className="w-full flex justify-center">
              <div className="flex flex-wrap justify-center w-full max-w-128">
                {fieldsDictionary[habit.inputType]()}
                {updatedStatusRelevant && !wasUpdated ? (
                  <div className="w-full mt-4">
                    <AuxiliaryText>{t('notYetUpdated')}</AuxiliaryText>
                  </div>
                ) : (
                  <></>
                )}
                {updatedStatusRelevant ? (
                  <div
                    className={`w-full flex justify-center lg:hidden
                                                        ${wasUpdated ? 'mt-4' : 'mt-2'}`}
                  >
                    {manageUpdateButton}
                  </div>
                ) : (
                  <></>
                )}
                <HabitSummaries habit={habit} habitSummaries={habitSummaries} />
              </div>
            </div>
          </SmartForm>
        </div>
        <div className="lg:order-1">
          {(updatedStatusRelevant ? chartsCount === 1 : [1, 2].includes(chartsCount)) ? (
            <ChartPanel date={date} habit={habit} chartRequest={chartRequests[0]} />
          ) : (
            <div />
          )}
        </div>
        <div className="order-3">
          {updatedStatusRelevant ? (
            <div className="w-full hidden lg:flex justify-end">{manageUpdateButton}</div>
          ) : chartsCount === 2 ? (
            <ChartPanel date={date} habit={habit} chartRequest={chartRequests[1]} />
          ) : (
            <></>
          )}
        </div>
      </div>
      {chartRequestsSets.map((set, index) => (
        <ChartPanelsContainer key={index} date={date} habit={habit} chartRequestsSet={set} />
      ))}
      <div className="pb-4" />
    </Container>
  );
};

const HabitAnswerForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: (props) => customPropsToValues(props, filterValues, auxiliaryAttributes),
  validationSchema,
  validateOnChange: false,
  validateOnBlur: false,
  handleSubmit: (values, formikBag) => {
    const habitAnswer = new HabitAnswer().assignValues(filterValues(values));
    formikBag.props.habitAnswersActionUpdateElement(habitAnswer);
  },
})(HabitAnswerInnerForm);

export default connect(null, {
  habitAnswersActionUpdateElement,
  habitAnswersActionMarkUpdatedElement,
  habitAnswersActionResetElement,
  habitChartsDataActionFetch,
})(HabitAnswerForm);
