import React from 'react';
import HabitSummaryRequest from '../../../../models/habitSummaryRequest';
import HabitSummaryRequestForm from './HabitSummaryRequestForm';

const HabitSummaryRequestModalContent = (props) => {
  const { addElement } = props;
  const newHabitSummaryRequest = new HabitSummaryRequest();
  return <HabitSummaryRequestForm {...newHabitSummaryRequest} addElement={addElement} />;
};

export default HabitSummaryRequestModalContent;
