import { create } from 'apisauce';

const apiSauce = create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
  headers: {
    'Browser-Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
});

export const api = {
  users: {
    sessions: {
      create: {
        google: (credential) => apiSauce.post('/users/sessions/google', { credential }),
      },
      delete: () => apiSauce.delete('/users/sessions'),
      get: () => apiSauce.get('/users/sessions'),
    },
    incognitoMode: {
      enable: () => apiSauce.patch('/users/incognito_mode/enable'),
      disable: () => apiSauce.patch('/users/incognito_mode/disable'),
    },
    configuration: {
      selectNotesWorkspace: ({ id, identifierType = 'NotesWorkspace' }) =>
        apiSauce.patch(`/users/configuration/select_notes_workspace/${id}`, { identifier_type: identifierType }),
      selectLearningWorkspace: ({ id, identifierType = 'LearningWorkspace' }) =>
        apiSauce.patch(`/users/configuration/select_learning_workspace/${id}`, { identifier_type: identifierType }),
      selectLanguageWorkspace: ({ id, identifierType = 'LanguageWorkspace' }) =>
        apiSauce.patch(`/users/configuration/select_language_workspace/${id}`, { identifier_type: identifierType }),
    },
  },
  presignedUrl: {
    get: (data) => apiSauce.get('/presigned_url', data),
  },
  categories: {
    index: () => apiSauce.get('/categories'),
    create: (category) => apiSauce.post('/categories', category),
    update: (category) => apiSauce.patch(`/categories/${category.id}`, category),
    move: (data) => apiSauce.patch(`/categories/${data.id}/move`, data),
    markPrivate: (category) => apiSauce.patch('/private/mark', { ...category, type: 'Category' }),
    unmarkPrivate: (category) => apiSauce.patch('/private/unmark', { ...category, type: 'Category' }),
    delete: (id) => apiSauce.delete(`/categories/${id}`),
  },
  tasks: {
    index: () => apiSauce.get('/tasks'),
    generatedLookup: (data) => apiSauce.get('/tasks/generated_lookup', data),
    create: (task) => apiSauce.post('/tasks', task),
    update: (task) => apiSauce.patch(`/tasks/${task.id}`, task),
    check: (task) => apiSauce.patch(`/tasks/${task.id}/check`, task),
    move: (data) => apiSauce.patch(`/tasks/${data.id}/move`, data),
    moveTodo: (data) => apiSauce.patch(`/tasks/${data.id}/move_todo`, data),
    markTodo: (task) => apiSauce.patch(`/tasks/${task.id}/mark_todo`, task),
    unmarkTodo: (task) => apiSauce.patch(`/tasks/${task.id}/unmark_todo`, task),
    markPrivate: (task) => apiSauce.patch('/private/mark', { ...task, type: 'Task' }),
    unmarkPrivate: (task) => apiSauce.patch('/private/unmark', { ...task, type: 'Task' }),
    delete: (id) => apiSauce.delete(`/tasks/${id}`),
  },
  checkedTasks: {
    index: (data) => apiSauce.get('/checked_tasks', data),
    find: (needle) => apiSauce.get('/checked_tasks/find', { needle }),
  },
  recurringTasks: {
    index: () => apiSauce.get('/recurring_tasks'),
    create: (recurringTask) => apiSauce.post('/recurring_tasks', recurringTask),
    update: (recurringTask) => apiSauce.patch(`/recurring_tasks/${recurringTask.id}`, recurringTask),
    move: (data) => apiSauce.patch(`/recurring_tasks/${data.id}/move`, data),
    markPrivate: (recurringTask) => apiSauce.patch('/private/mark', { ...recurringTask, type: 'RecurringTask' }),
    unmarkPrivate: (recurringTask) => apiSauce.patch('/private/unmark', { ...recurringTask, type: 'RecurringTask' }),
    delete: (id) => apiSauce.delete(`/recurring_tasks/${id}`),
  },
  dayEvaluations: {
    index: (data) => apiSauce.get('/day_evaluations', data),
    update: (dayEvaluation) => apiSauce.patch(`/day_evaluations/${dayEvaluation.date}`, dayEvaluation),
    delete: (dayEvaluation) => apiSauce.delete(`/day_evaluations/${dayEvaluation.date}`),
  },
  dayEvaluationsChartsData: {
    index: (data) => apiSauce.get('/day_evaluations_charts_data', data),
  },
  habitsSections: {
    index: () => apiSauce.get('/habits_sections'),
    create: (habitsSection) => apiSauce.post('/habits_sections', habitsSection),
    update: (habitsSection) => apiSauce.patch(`/habits_sections/${habitsSection.id}`, habitsSection),
    move: (data) => apiSauce.patch(`/habits_sections/${data.id}/move`, data),
    markPrivate: (habitsSection) => apiSauce.patch('/private/mark', { ...habitsSection, type: 'HabitsSection' }),
    unmarkPrivate: (habitsSection) => apiSauce.patch('/private/unmark', { ...habitsSection, type: 'HabitsSection' }),
    markHidden: (habitsSection) => apiSauce.patch(`/habits_sections/${habitsSection.id}/mark_hidden`, habitsSection),
    unmarkHidden: (habitsSection) =>
      apiSauce.patch(`/habits_sections/${habitsSection.id}/unmark_hidden`, habitsSection),
    delete: (id) => apiSauce.delete(`/habits_sections/${id}`),
  },
  habits: {
    index: () => apiSauce.get('/habits'),
    create: (habit) => apiSauce.post('/habits', habit),
    update: (habit) => apiSauce.patch(`/habits/${habit.id}`, habit),
    move: (data) => apiSauce.patch(`/habits/${data.id}/move`, data),
    markPrivate: (habit) => apiSauce.patch('/private/mark', { ...habit, type: 'Habit' }),
    unmarkPrivate: (habit) => apiSauce.patch('/private/unmark', { ...habit, type: 'Habit' }),
    markHidden: (habit) => apiSauce.patch(`/habits/${habit.id}/mark_hidden`, habit),
    unmarkHidden: (habit) => apiSauce.patch(`/habits/${habit.id}/unmark_hidden`, habit),
    delete: (id) => apiSauce.delete(`/habits/${id}`),
  },
  habitAnswers: {
    index: (data) => apiSauce.get('/habit_answers', data),
    update: (habitAnswer) => apiSauce.patch(`/habit_answers/${habitAnswer.id}`, habitAnswer),
    markUpdated: (habitAnswer) => apiSauce.patch(`/habit_answers/${habitAnswer.id}/mark_updated`, habitAnswer),
    reset: (habitAnswer) => apiSauce.patch(`/habit_answers/${habitAnswer.id}/reset`, habitAnswer),
  },
  habitChartsData: {
    index: (data) => apiSauce.get('/habit_charts_data', data),
  },
  nodesGroups: {
    show: (nodesGroup) => apiSauce.get(`/nodes_groups/${nodesGroup.id}`),
  },
  nodes: {
    index: (nodesGroupId) => apiSauce.get('/nodes', { nodes_group_id: nodesGroupId }),
    create: (node) => apiSauce.post('/nodes', node),
    update: (node) => apiSauce.patch(`/nodes/${node.id}`, node),
    duplicate: (data) => apiSauce.post(`/nodes/${data.id}/duplicate`, data),
    move: (data) => apiSauce.patch(`/nodes/${data.id}/move`, data),
    markPrivate: (node) => apiSauce.patch('/private/mark', { ...node, type: 'Node' }),
    unmarkPrivate: (node) => apiSauce.patch('/private/unmark', { ...node, type: 'Node' }),
    delete: (id) => apiSauce.delete(`/nodes/${id}`),
  },
  notesWorkspaces: {
    index: () => apiSauce.get('/notes_workspaces'),
    create: (notes_workspace) => apiSauce.post('/notes_workspaces', notes_workspace),
    update: (notes_workspace) => apiSauce.patch(`/notes_workspaces/${notes_workspace.id}`, notes_workspace),
    move: (data) => apiSauce.patch(`/notes_workspaces/${data.id}/move`, data),
    markPrivate: (notes_workspace) => apiSauce.patch('/private/mark', { ...notes_workspace, type: 'NotesWorkspace' }),
    unmarkPrivate: (notes_workspace) =>
      apiSauce.patch('/private/unmark', { ...notes_workspace, type: 'NotesWorkspace' }),
    delete: (id) => apiSauce.delete(`/notes_workspaces/${id}`),
  },
  notes: {
    index: (notesWorkspaceId) => apiSauce.get('/notes', { notes_workspace_id: notesWorkspaceId }),
    create: (note) => apiSauce.post('/notes', note),
    update: (note) => apiSauce.patch(`/notes/${note.id}`, note),
    duplicate: (data) => apiSauce.post(`/notes/${data.id}/duplicate`, data),
    move: (data) => apiSauce.patch(`/notes/${data.id}/move`, data),
    markPrivate: (note) => apiSauce.patch('/private/mark', { ...note, type: 'Note' }),
    unmarkPrivate: (note) => apiSauce.patch('/private/unmark', { ...note, type: 'Note' }),
    delete: (id) => apiSauce.delete(`/notes/${id}`),
    getWorkspaceId: (noteId) => apiSauce.get(`/notes/${noteId}/workspace_id`),
  },
  events: {
    index: (dates) => apiSauce.get('/events', { dates }),
    create: (event) => apiSauce.post('/events', event),
    update: (event) => apiSauce.patch(`/events/${event.id}`, event),
    move: (data) => apiSauce.patch(`/events/${data.id}/move`, data),
    markPrivate: (event) => apiSauce.patch('/private/mark', { ...event, type: 'Event' }),
    unmarkPrivate: (event) => apiSauce.patch('/private/unmark', { ...event, type: 'Event' }),
    delete: (id) => apiSauce.delete(`/events/${id}`),
    find: (needle) => apiSauce.get('/events/find', { needle }),
  },
  recurringEvents: {
    index: () => apiSauce.get('/recurring_events'),
    create: (recurringEvent) => apiSauce.post('/recurring_events', recurringEvent),
    update: (recurringEvent) => apiSauce.patch(`/recurring_events/${recurringEvent.id}`, recurringEvent),
    move: (data) => apiSauce.patch(`/recurring_events/${data.id}/move`, data),
    markPrivate: (recurringEvent) => apiSauce.patch('/private/mark', { ...recurringEvent, type: 'RecurringEvent' }),
    unmarkPrivate: (recurringEvent) => apiSauce.patch('/private/unmark', { ...recurringEvent, type: 'RecurringEvent' }),
    delete: (id) => apiSauce.delete(`/recurring_events/${id}`),
  },
  labels: {
    index: () => apiSauce.get('/labels'),
    toggleAssignment: (labelName, objectType, objectId) =>
      apiSauce.patch('/labels/toggle_assignment', { label: labelName, type: objectType, id: objectId }),
    markPrivate: (label) => apiSauce.patch('/private/mark', { ...label, type: 'Label' }),
    unmarkPrivate: (label) => apiSauce.patch('/private/unmark', { ...label, type: 'Label' }),
  },
  learningWorkspaces: {
    index: () => apiSauce.get('/learning_workspaces'),
    create: (learning_workspace) => apiSauce.post('/learning_workspaces', learning_workspace),
    update: (learning_workspace) => apiSauce.patch(`/learning_workspaces/${learning_workspace.id}`, learning_workspace),
    move: (data) => apiSauce.patch(`/learning_workspaces/${data.id}/move`, data),
    markPrivate: (learning_workspace) =>
      apiSauce.patch('/private/mark', { ...learning_workspace, type: 'LearningWorkspace' }),
    unmarkPrivate: (learning_workspace) =>
      apiSauce.patch('/private/unmark', { ...learning_workspace, type: 'LearningWorkspace' }),
    delete: (id) => apiSauce.delete(`/learning_workspaces/${id}`),
  },
  learningQueues: {
    index: (learningWorkspaceId) => apiSauce.get('/learning_queues', { learning_workspace_id: learningWorkspaceId }),
    create: (queue) => apiSauce.post('/learning_queues', queue),
    update: (queue) => apiSauce.patch(`/learning_queues/${queue.id}`, queue),
    move: (data) => apiSauce.patch(`/learning_queues/${data.id}/move`, data),
    markPrivate: (queue) => apiSauce.patch('/private/mark', { ...queue, type: 'LearningQueue' }),
    unmarkPrivate: (queue) => apiSauce.patch('/private/unmark', { ...queue, type: 'LearningQueue' }),
    delete: (id) => apiSauce.delete(`/learning_queues/${id}`),
  },
  learning: {
    plan: (queue) => apiSauce.get('/learning/plan', { learning_queue_id: queue.id }),
    learn: (queue, nodeId, learningProgress, wpm = null) =>
      apiSauce.patch('/learning/learn', {
        learning_queue_id: queue.id,
        node_id: nodeId,
        learning_progress: learningProgress,
        wpm,
      }),
    reset: (queue) => apiSauce.patch('/learning/reset', { learning_queue_id: queue.id }),
  },
  languageWorkspaces: {
    index: () => apiSauce.get('/language_workspaces'),
    create: (language_workspace) => apiSauce.post('/language_workspaces', language_workspace),
    update: (language_workspace) => apiSauce.patch(`/language_workspaces/${language_workspace.id}`, language_workspace),
    move: (data) => apiSauce.patch(`/language_workspaces/${data.id}/move`, data),
    markPrivate: (language_workspace) =>
      apiSauce.patch('/private/mark', { ...language_workspace, type: 'LanguageWorkspace' }),
    unmarkPrivate: (language_workspace) =>
      apiSauce.patch('/private/unmark', { ...language_workspace, type: 'LanguageWorkspace' }),
    delete: (id) => apiSauce.delete(`/language_workspaces/${id}`),
  },
  wastes: {
    index: (modelName) => apiSauce.get('/wastes', { model_name: modelName }),
    restore: (waste) => apiSauce.patch(`/wastes/${waste.id}/restore`, { model_name: waste.modelName }),
    delete: (waste) => apiSauce.delete(`/wastes/${waste.id}`, { model_name: waste.modelName }),
  },
};
