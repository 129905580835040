import { select, put } from 'redux-saga/effects';
import { flatten } from 'lodash';
import { tasksActionUnset } from '../../actions/tasksActions';
import { recurringTasksActionUnset } from '../../actions/recurringTasksActions';

export default function* unsetCategoriesSaga(action) {
  const ids = flatten([action.payload]).map((category) => category.id);
  const [relatedTasks, relatedRecurringTasks] = yield select((state) => [
    state.tasks.filter((task) => ids.includes(task.categoryId)),
    state.recurringTasks.filter((recurringTask) => ids.includes(recurringTask.categoryId)),
  ]);

  if (relatedTasks.length > 0) yield put(tasksActionUnset(relatedTasks));
  if (relatedRecurringTasks.length > 0) yield put(recurringTasksActionUnset(relatedRecurringTasks));
}
