import React from 'react';
import ThemeCrumb from '../../ThemeCrumb';
import ErrorWrapper from './auxiliary/ErrorWrapper';
import { generateTagId } from '../../../helpers/identifierHelpers';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { connect } from 'react-redux';
import { getThemeTypeDefinition } from '../../../helpers/themeHelpers';
import { getThemeType } from '../../../helpers/formHelpers';
import { get } from 'lodash';

const defaultOnChange = async (props) => {
  const {
    radioValue,
    onBlur = () => {},
    field: { name },
    form: { setFieldValue, setFieldTouched },
  } = props;
  await setFieldValue(name, radioValue);
  await setFieldTouched(name);
  await onBlur();
};

const RadioField = (props) => {
  const {
    id,
    label = '',
    radioValue,
    innerRef,
    themeType = 'input',
    displayError,
    fixedErrorHeight,
    handleErrorThemeType,
    handleDisabledThemeType,
    handlePresentationModeThemeType,
    theme,
    disabled = false,
    presentationMode = false,
    onChange = (onChangeProps, _defaultOnChange) => _defaultOnChange(onChangeProps),
    // eslint-disable-next-line no-unused-vars
    onBlur = () => {},
    field: { name, value },
    form: { errors, touched },
    AlternativeDisplayComponent,
  } = props;

  const error = get(touched, name) ? get(errors, name) : null;
  const actualThemeType = getThemeType({
    themeType,
    error,
    handleErrorThemeType,
    presentationMode,
    handlePresentationModeThemeType,
    disabled,
    handleDisabledThemeType,
  });
  const innerId = generateTagId();
  const checked = radioValue === value;
  const checkedRadioStyle = {
    backgroundColor: checked ? getThemeTypeDefinition(theme, 'check').color : '#0000',
  };
  const changesDisabled = disabled || presentationMode;

  return (
    <>
      <input
        id={innerId}
        hidden
        type="radio"
        name={name}
        value={radioValue}
        checked={checked}
        disabled={changesDisabled}
        onChange={() => onChange(props, defaultOnChange)}
      />
      {AlternativeDisplayComponent ? (
        <AlternativeDisplayComponent
          {...props}
          error={error}
          actualThemeType={actualThemeType}
          innerId={innerId}
          checked={checked}
          changesDisabled={changesDisabled}
        />
      ) : (
        <ErrorWrapper displayError={displayError} fixedErrorHeight={fixedErrorHeight} error={error}>
          <label
            id={id}
            ref={innerRef}
            htmlFor={innerId}
            className={`flex items-center
                                       ${changesDisabled ? '' : 'cursor-pointer'}`}
          >
            <ThemeCrumb type={actualThemeType}>
              <div
                className="rounded-full p-1 border w-4 h-4 flex items-center
                                        justify-center"
              >
                <div className="w-1.5 min-w-1.5 h-1.5 rounded-full" style={checkedRadioStyle} />
              </div>
            </ThemeCrumb>
            <div className={`ml-2 text-left ${checked ? '' : 'opacity-60'}`}>{label}</div>
          </label>
        </ErrorWrapper>
      )}
    </>
  );
};

export default connect(prepareState('theme'))(RadioField);
