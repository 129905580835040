import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { wastesActionUnlockElement, wastesActionUnset } from '../../actions/wastesActions';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { notificationT } from '../../../helpers/i18nHelpers';

export default function* deleteElementWastesSaga(action) {
  const { element: initialWaste } = action.payload;
  const { ok, data } = yield call(api.wastes.delete, initialWaste);
  if (ok) {
    yield put(snackbarMessagesActionAdd(notificationT('wastes.delete.success')));
    yield put(wastesActionUnset(initialWaste));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(wastesActionUnlockElement(initialWaste));
  }
}
