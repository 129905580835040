import React, { useState } from 'react';
import { fixedMenuSides, fixedMenuWidthPx } from '../../../constants/fixedMenu';
import FixedMenuToggle from './FixedMenuToggle';
import ThemeCrumb from '../../ThemeCrumb';
import { get100vhInPx, getNavbarHeight } from '../../../helpers/elementsSizeHelpers';
import useResizeEffect from '../../../hooks/useResizeEffect';

const FixedMenu = (props) => {
  const { children, side, toggle = () => {}, disabled, expanded } = props;

  const [navbarHeight, setNavbarHeight] = useState(getNavbarHeight());
  const [paperPaddingBottom, setPaperPaddingBottom] = useState(get100vhInPx() - window.innerHeight);
  useResizeEffect(
    () => {
      setNavbarHeight(getNavbarHeight());
      setPaperPaddingBottom(get100vhInPx() - window.innerHeight);
    },
    [],
    true,
  );

  const zIndexClass = expanded ? 'z-11' : 'z-9';
  const containerClass =
    side === fixedMenuSides.left ? 'left-0 border-r rounded-r-md' : 'right-0 border-l rounded-l-md';
  const foldedTranslation = `translate(${side === fixedMenuSides.left ? '-' : ''}${fixedMenuWidthPx}px)`;

  const height = `calc(100vh - ${navbarHeight}px)`;

  return (
    <>
      {expanded ? (
        <ThemeCrumb type="modalOverlay">
          <div
            className="fixed-menu-overlay fixed w-screen left-0 z-10"
            style={{
              height,
              maxHeight: height,
              top: `${navbarHeight}px`,
            }}
            onMouseDown={() => setTimeout(toggle)}
          />
        </ThemeCrumb>
      ) : (
        <></>
      )}
      <ThemeCrumb type="fixedMenu">
        <div
          className={`fixed ${zIndexClass} flex items-center items-center max-w-almost-screen
                        transition-transform duration-500 ${containerClass}`}
          style={{
            transform: expanded ? '' : foldedTranslation,
            height,
            maxHeight: height,
            top: `${navbarHeight}px`,
            width: `${fixedMenuWidthPx}px`,
          }}
        >
          <div
            className="w-full h-full overflow-y-auto overscroll-contain"
            style={{ paddingBottom: paperPaddingBottom }}
          >
            {children}
          </div>
          <FixedMenuToggle expanded={expanded} side={side} disabled={disabled} toggle={toggle} />
        </div>
      </ThemeCrumb>
    </>
  );
};

export default FixedMenu;
