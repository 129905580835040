import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { create } from 'apisauce';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { filesActionSetElement, filesActionMarkFailedElement } from '../../actions/filesActions';
import { maxFileSizeInMb } from '../../../constants/files';
import snackbarMessages from '../../../constants/snackbarMessages';
import { frontEndErrorT } from '../../../helpers/i18nHelpers';

const getUrl = (data) =>
  unescape(new DOMParser().parseFromString(data, 'text/xml').getElementsByTagName('Location')[0].textContent);

function* handleError(identifier, data) {
  yield put(snackbarMessagesActionHandleBackendErrors(data));
  yield put(filesActionMarkFailedElement(identifier));
}

export default function* uploadElementFilesSaga(action) {
  const { identifier, file } = action.payload;
  if (file.size > maxFileSizeInMb * 1024 * 1024) {
    yield put(
      snackbarMessagesActionAdd(
        frontEndErrorT('shared.file.tooBig', { maxFileSizeInMb }),
        snackbarMessages.types.error,
      ),
    );
    yield put(filesActionMarkFailedElement(identifier));
    return;
  }

  const presignedUrlResponse = yield call(api.presignedUrl.get, { filename: file.name });
  if (!presignedUrlResponse.ok) {
    yield handleError(identifier, presignedUrlResponse.data);
    return;
  }

  const { url, fields } = presignedUrlResponse.data;
  const formData = new FormData();
  Object.keys(fields).forEach((key) => formData.append(key, fields[key]));
  formData.append('file', file);
  const upload = () =>
    create({
      baseURL: url,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).post('/', formData);

  const { ok, data } = yield call(upload);
  if (ok) {
    yield put(filesActionSetElement(identifier, getUrl(data), file.name));
  } else {
    yield handleError(identifier, data);
  }
}
