import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { getAllNotesWorkspaces } from '../../../helpers/notesHelpers';
import { notesActionUpdateElement } from '../../../stateManagement/actions/notesActions';
import WorkspaceSelector from '../../../shared/features/WorkspaceSelector';

const NotesWorkspaceSelector = (props) => {
  const { note, notesWorkspaces, incognitoMode, notesActionUpdateElement: update } = props;

  const workspaces = useMemo(
    () => getAllNotesWorkspaces(notesWorkspaces, incognitoMode),
    [notesWorkspaces, incognitoMode],
  );

  const onChange = useCallback(
    (newValue) => {
      const updatedNote = note.shallowClone().assignValues({
        ...note,
        notesWorkspaceId: newValue,
      });
      update(updatedNote);
    },
    [note, update],
  );

  return <WorkspaceSelector value={note.notesWorkspaceId} workspaces={workspaces} onChange={onChange} />;
};

export default connect(prepareState(['notesWorkspaces', 'incognitoMode']), {
  notesActionUpdateElement,
})(NotesWorkspaceSelector);
