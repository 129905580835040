import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useResizeEffect from '../hooks/useResizeEffect';
import { generateTagId } from '../helpers/identifierHelpers';
import { useCheckIfMounted } from '../hooks/useCheckIfMounted';

const LastStickyElement = (props) => {
  const {
    zIndex = 1,
    children,
    // position of element is going to be recalculated whenever elements to observe
    // have their class changed
    idsOfElementsToObserve = ['standard-content-template-top', 'wodzitsu'],
  } = props;

  const id = useMemo(generateTagId, []);
  const [top, setTop] = useState(0);
  const checkIfMounted = useCheckIfMounted();

  const calcTop = useCallback(
    () =>
      setTimeout(() => {
        if (!checkIfMounted()) return;

        let newTop = 0;
        for (let element of document.getElementsByClassName('sticky')) {
          if (element.id === id) continue;
          const topCandidate = element.getBoundingClientRect().top + element.offsetHeight;
          if (topCandidate > newTop) newTop = topCandidate;
        }
        setTop(newTop);
      }),
    [],
  );

  useResizeEffect(calcTop, [], true);

  useEffect(() => {
    const observer = new MutationObserver(([{ attributeName }]) => {
      if (attributeName === 'class') calcTop();
    });
    idsOfElementsToObserve.forEach((id) => {
      const element = document.getElementById(id);
      if (element) observer.observe(element, { attributes: true });
    });
    return () => observer.disconnect();
  }, []);

  return (
    <div id={id} className="sticky" style={{ top, zIndex }}>
      {children}
    </div>
  );
};

export default LastStickyElement;
