import React from 'react';

export const OrderedBulletsIcon = ({ className = '', style = {} }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
    style={style}
  >
    <text
      style={{ fontFamily: 'Arial', fontSize: '10.6px', whiteSpace: 'pre' }}
      x="-0.431"
      y="9.746"
      transform="matrix(1.522002, 0, 0, 1.168363, 0.129577, -0.506643)"
    >
      1
    </text>
    <text
      style={{ fontFamily: 'Arial', fontSize: '0.7px', whiteSpace: 'pre' }}
      transform="matrix(23.067133, 0, 0, 18.122074, -234.055679, -180.018784)"
    >
      <tspan x="10.129" y="11.132">
        2
      </tspan>
      <tspan x="10.129" dy="1em">
        {' '}
      </tspan>
    </text>
    <rect x="12.5" y="2.5" width="10.966" height="0.85" style={{ stroke: 'rgb(0, 0, 0)' }} />
    <rect x="12.5" y="7" width="10.966" height="0.85" style={{ stroke: 'rgb(0, 0, 0)' }} />
    <rect x="12.5" y="11.5" width="10.966" height="0.85" style={{ stroke: 'rgb(0, 0, 0)' }} />
    <rect x="12.5" y="16" width="10.966" height="0.85" style={{ stroke: 'rgb(0, 0, 0)' }} />
    <rect x="12.5" y="20.5" width="10.966" height="0.85" style={{ stroke: 'rgb(0, 0, 0)' }} />
  </svg>
);
export default OrderedBulletsIcon;
