import findIndex from 'lodash/findIndex';

const initialState = () => ({ awaitingMessages: [], activeMessage: null });

export default function (state = initialState(), action) {
  switch (action.type) {
    case 'SNACKBAR_MESSAGES_ACTION_ADD':
      return {
        ...state,
        awaitingMessages: [
          // one can uncomment to restore the previous approach with awaitingMessages queue
          // ...state.awaitingMessages,
          action.payload,
        ],
      };
    case 'SNACKBAR_MESSAGES_ACTION_ACTIVATE': {
      const { awaitingMessages } = state;
      const uuid = action.payload;
      const index = findIndex(state.awaitingMessages, { uuid });
      if (index === -1) return state;
      const activeMessage = awaitingMessages[index];
      const newAwaitingMessages = [...awaitingMessages.slice(0, index), ...awaitingMessages.slice(index + 1)];
      return {
        ...state,
        awaitingMessages: newAwaitingMessages,
        activeMessage,
      };
    }
    case 'SNACKBAR_MESSAGES_ACTION_REMOVE_ACTIVE': {
      if ((state.activeMessage || {}).uuid === action.payload) return { ...state, activeMessage: null };
      return state;
    }
    default:
      return state;
  }
}
