import React, { useRef } from 'react';
import LabelledButton from '../../../buttons/LabelledButton';
import { initT } from '../../../../helpers/i18nHelpers';
import { acceptAudio } from '../../../../constants/fileExtensions';

const t = initT('modals.audioSelectionModal');

const FileChooser = (props) => {
  const { onFileChosen } = props;

  const ref = useRef(null);

  const chooseFile = () => {
    ref.current.click();
  };
  const _onFileChosen = (e) => {
    const { files } = e.target;
    if (files.length === 0) return;

    onFileChosen(e.target.files[0]);
  };

  return (
    <>
      <input ref={ref} className="hidden" type="file" accept={acceptAudio} onChange={_onFileChosen} />
      <LabelledButton variant="attachment" onMouseDown={chooseFile}>
        {t('chooseFile')}
      </LabelledButton>
    </>
  );
};

export default FileChooser;
