import React from 'react';

export const applyTemplate =
  (content, template, childName, additionalTemplateProps = {}) =>
  (props) =>
    React.createElement(
      template,
      { ...props, ...additionalTemplateProps, childName },
      React.createElement(content, props),
    );
