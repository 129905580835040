const getCaretGlobalPosition = () => {
  const selection = document.getSelection();
  if (!selection) return;

  // sometimes selection.getRangeAt(0) throws an unexpected error
  try {
    if (!selection.getRangeAt(0)) return;
  } catch {
    return;
  }

  return selection.getRangeAt(0).getBoundingClientRect();
};

export const scrollToCaretIfTooLow = () => {
  const caretPosition = getCaretGlobalPosition();
  if (!caretPosition) return;

  const diff = window.innerHeight - caretPosition.bottom;
  if (diff < 200) {
    setTimeout(() => {
      window.scrollBy({
        top: 200 - diff,
        left: 0,
        behavior: 'smooth',
      });
    });
  }
};
