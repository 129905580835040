import React, { useEffect, useRef } from 'react';
import NodePanel from '../../../../shared/features/nodesGroupPanel/nodePanel';
import { nodeVariants } from '../../../../constants/nodeVariants';
import { compact } from 'lodash';
import { useCheckIfMounted } from '../../../../hooks/useCheckIfMounted';
import every from 'lodash/every';
import TopInfoBox from '../../TopInfoBox';

const PdfContent = (props) => {
  const {
    data,
    onReady,
    // (210 (default width in px) - 2 * 10 (margin)) / 0.25 (scale)
    width = 760,
  } = props;

  const checkIfMounted = useCheckIfMounted();
  const ref = useRef(null);

  useEffect(() => {
    const check = () => {
      if (!checkIfMounted()) return;

      const images = Array.from(ref.current.getElementsByTagName('img'));
      if (every(images.map((img) => img.complete))) {
        onReady(ref.current);
      } else {
        setTimeout(check, 10);
      }
    };

    setTimeout(check);
  }, []);

  return (
    <div className="fixed invisible">
      <div ref={ref} style={{ width: `${width}px`, fontFamily: 'PTSans', wordSpacing: '2px' }}>
        {compact(
          data.map(({ node, workspace, notesPath, heading }) => {
            if (![nodeVariants.text, nodeVariants.image].includes(node.variant)) return;

            return (
              <div key={node.id} className="pb-25px">
                <div className="py-1">
                  <TopInfoBox
                    workspace={workspace}
                    notesPath={notesPath}
                    heading={heading}
                    headingTitlePaddingClass="pb-2"
                  />
                </div>
                <NodePanel node={node} interactive={false} />
              </div>
            );
          }),
        )}
      </div>
    </div>
  );
};

export default PdfContent;
