import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { prepareCollection } from '../../../helpers/model/modelHelpers';
import { notesWorkspacesActionSet } from '../../actions/notesWorkspacesActions';
import { notesWorkspaceFromDto } from '../../../helpers/model/notesWorkspacesHelpers';

export default function* fetchNotesWorkspacesSaga(action) {
  const { ok, data } = yield call(api.notesWorkspaces.index, action.payload);
  if (ok) {
    yield put(notesWorkspacesActionSet(prepareCollection(data, notesWorkspaceFromDto)));
  }
}
