import { blockVariants, blockVariantsValues, defaultBlockVariant } from '../constants/blockVariants';
import { Editor, Transforms } from 'slate';
import { minNestingLevel } from '../constants/nestingLevels';
import { sanitizeProps } from './propsHelpers';
import { ReactEditor } from 'slate-react';

export const getValidBlockVariant = (blockVariant) =>
  blockVariantsValues.includes(blockVariant) ? blockVariant : defaultBlockVariant;

export const blockVariantToIconButtonVariant = (blockVariant) => {
  blockVariant = getValidBlockVariant(blockVariant);
  switch (blockVariant) {
    case blockVariants.bullet:
      return 'bullets';
    case blockVariants.orderedBullet:
      return 'orderedBullets';
    case blockVariants.checklistBullet:
      return 'checklistBullets';
  }
};

export const getActiveBlockVariants = (editor) => {
  const result = new Set();

  for (let [node] of Editor.nodes(editor, {
    at: editor.selection,
    mode: 'highest',
    match: (n) => Editor.isBlock(editor, n),
  })) {
    result.add(getValidBlockVariant(node.type));
  }

  return result;
};

export const produceToggleBlockVariant = (editor, blockVariant) => (event) => {
  if (event) event.preventDefault();
  blockVariant = getValidBlockVariant(blockVariant);
  const activeBlockVariants = getActiveBlockVariants(editor);
  const props =
    activeBlockVariants.size === 1 && activeBlockVariants.has(blockVariant)
      ? sanitizeProps({ type: defaultBlockVariant }, true)
      : sanitizeProps({ type: blockVariant, nestingLevel: minNestingLevel }, true);
  Transforms.setNodes(editor, props, { mode: 'highest', match: (n) => Editor.isBlock(editor, n) });
  ReactEditor.focus(editor);
};
