import ButtonsGroup from '../../../shared/buttons/ButtonsGroup';
import IconButton from '../../../shared/buttons/IconButton';
import H1 from '../../../shared/titles/H1';
import CenteredContent from '../../../shared/organizers/CenteredContent';
import React from 'react';
import ConfirmationButton from '../../../shared/buttons/ConfirmationButton';

export const getSectionContent = (props) => {
  const {
    section,
    incognitoMode,
    switchHiddenVariant,
    switchHidden,
    switchPrivateVariant,
    switchPrivate,
    enableEditionMode,
    deleteElement,
  } = props;

  return (
    <CenteredContent
      left={
        <ButtonsGroup
          className="flex px-2 sm:px-25px"
          buttons={[
            <IconButton key="switchHidden" variant={switchHiddenVariant} onMouseDown={switchHidden} />,
            incognitoMode ? null : (
              <IconButton key="switchPrivate" variant={switchPrivateVariant} onMouseDown={switchPrivate} />
            ),
          ]}
        />
      }
      main={<H1 onDoubleClick={enableEditionMode}>{section.name}</H1>}
      right={
        <ButtonsGroup
          className="flex px-2 sm:px-25px"
          buttons={[
            <ConfirmationButton
              key="delete"
              variant="delete"
              i18nKey="habitsSections.delete"
              onConfirmed={deleteElement}
              resourceName={section.name}
              textConfirmation
            />,
          ]}
        />
      }
      heightPx={40}
    />
  );
};
