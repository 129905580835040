import { put, select } from 'redux-saga/effects';
import { flatten } from 'lodash';
import { learningQueuesActionUnset } from '../../actions/learningQueuesActions';

export default function* unsetLearningWorkspacesSaga(action) {
  const workspaces = flatten([action.payload]);
  const ids = workspaces.map((workspace) => workspace.id);

  const relatedQueues = yield select((state) =>
    state.learningQueues.filter((queue) => ids.includes(queue.learningWorkspaceId)),
  );

  if (relatedQueues.length > 0) yield put(learningQueuesActionUnset(relatedQueues));
}
