import React from 'react';
import SubSectionTitle from '../../../titles/SubSectionTitle';
import ThemeCrumb from '../../../ThemeCrumb';
import Clickable from '../../../Clickable';
import { runIfDraggingNotEnabled } from '../../../../helpers/draggingHelpers';
import { HeadingEditor } from './HeadingEditor';

const NodeHeading = (props) => {
  const { editionAllowed, editionMode, dragged, enableEditionMode, disableEditionMode, node, update, onModal } = props;

  if (!editionAllowed) {
    return (
      <div className={'node-heading'}>
        <SubSectionTitle marginClass="">{node.content}</SubSectionTitle>
      </div>
    );
  }

  if (!editionMode || dragged) {
    return (
      <div>
        <Clickable
          className="w-full"
          onMouseDown={() => runIfDraggingNotEnabled(enableEditionMode)}
          disabled={dragged}
          opaqueWhenDisabled={false}
          hoverOpacity
        >
          <ThemeCrumb type="headingNodeEditor">
            <div className="w-full rounded-b-md border p-4 min-h-54px">
              <SubSectionTitle marginClass="">{node.content}</SubSectionTitle>
            </div>
          </ThemeCrumb>
        </Clickable>
      </div>
    );
  }

  const onSubmit = ({ content }) => {
    disableEditionMode();
    node.content = content;
    update(node);
  };

  return <HeadingEditor content={node.content || ''} handleSubmit={onSubmit} onModal={onModal} />;
};

export default NodeHeading;
