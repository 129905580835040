import React from 'react';
import { initT } from '../../../../helpers/i18nHelpers';
import FormikFieldSub from '../../../form/FormikFieldSub';
import CheckboxField from '../../../form/fieldTypes/CheckBoxField';

const t = initT('modals.audioSelectionModal');

const NoAudioPanel = (props) => {
  const { selectedCandidateUuid, setSelectedCandidateUuid } = props;

  const selected = !selectedCandidateUuid;

  return (
    <div className={`w-full ${selected ? '' : 'opacity-50'}`}>
      <FormikFieldSub
        value={selected}
        disabled={selected}
        component={CheckboxField}
        onChange={() => setSelectedCandidateUuid(null)}
        label={<div className="w-full">{t('noAudio')}</div>}
      />
    </div>
  );
};

export default NoAudioPanel;
