import React from 'react';
import { alignmentTypeToFlexJustifyClass, alignmentTypeToTextClass } from '../helpers/alignmentHelpers';
import { alignmentTypes } from '../constants/alignmentTypes';
import { ReactEditor, useSlateStatic } from 'slate-react';
import { Transforms } from 'slate';
import Clickable from '../../../../../Clickable';

const ChecklistBullet = React.forwardRef((props, ref) => {
  const {
    children,
    alignmentType,
    nestingLevel,
    checked,
    element,
    attributes,
    readOnly,
    checklistItemClickable,
    onChecklistItemClicked,
  } = props;
  const editor = useSlateStatic();

  let leftMarginClass;
  switch (nestingLevel) {
    case 0:
      leftMarginClass = '';
      break;
    case 1:
      leftMarginClass = alignmentType === alignmentTypes.center ? 'pl-16' : 'pl-8';
      break;
    case 2:
      leftMarginClass = alignmentType === alignmentTypes.center ? 'pl-32' : 'pl-16';
      break;
  }
  const containerClass = `${leftMarginClass} w-full pt-2 first:pt-4
                            ${checked ? 'opacity-50' : ''}`;

  const toggle = checklistItemClickable
    ? (e) => {
        if (e.target.closest('.readonly-link')) return;
        e.stopPropagation();
        e.preventDefault();
        const path = ReactEditor.findPath(editor, element);
        Transforms.setNodes(editor, { checked: !checked }, { at: path });
        setTimeout(onChecklistItemClicked);
      }
    : () => {};

  const wrap = (_children) => {
    const wrapperClass = `w-full flex ${alignmentTypeToFlexJustifyClass(alignmentType)}`;

    if (!readOnly || !checklistItemClickable) return <div className={wrapperClass}>{_children}</div>;
    return (
      <Clickable onMouseDown={toggle} className={wrapperClass}>
        {_children}
      </Clickable>
    );
  };

  return (
    <div className={`checklist-bullet ${containerClass}`} ref={ref} {...attributes}>
      {wrap(
        <>
          <div
            contentEditable={false}
            className={`mr-2 text-lg flex ${checklistItemClickable ? 'cursor-pointer ' : ''}
                            checklist-bullet-symbol`}
            onMouseDown={toggle}
          >
            {checked ? '☑' : '☐'}
          </div>
          <div
            className={`${alignmentTypeToTextClass(alignmentType)}
                                    ${checked ? 'line-through' : ''}`}
            style={{ marginTop: '0.3rem' }}
          >
            {children}
          </div>
        </>,
      )}
    </div>
  );
});

export default ChecklistBullet;
