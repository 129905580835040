import { select, put } from 'redux-saga/effects';
import { flatten } from 'lodash';
import { habitsActionUnset } from '../../actions/habitActions';

export default function* unsetHabitsSectionsSaga(action) {
  const ids = flatten([action.payload]).map((section) => section.id);
  const relatedHabits = yield select((state) => state.habits.filter((habit) => ids.includes(habit.habitsSectionId)));

  if (relatedHabits.length > 0) yield put(habitsActionUnset(relatedHabits));
}
