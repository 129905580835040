import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { FastField, withFormik } from 'formik';
import { customPropsToValues, produceValuesFilter, withCustomReinitialize } from '../../../helpers/formHelpers';
import Task from '../../../models/task';
import * as yup from 'yup';
import { tasksActionCreateElement } from '../../../stateManagement/actions/tasksActions';
import { elementToFocusOnActionReset } from '../../../stateManagement/actions/elementToFocusOnActions';
import { elementToFocusOnTypes } from '../../../constants/elementToFocusOnTypes';
import { frontEndErrorT, initT } from '../../../helpers/i18nHelpers';
import SmartForm from '../../form/SmartForm';
import SmartField from '../../form/SmartField';
import TextField from '../../form/fieldTypes/TextField';
import IconButton from '../../buttons/IconButton';
import CustomColorCrumb from '../../CustomColorCrumb';
import ButtonsGroup from '../../buttons/ButtonsGroup';
import isEmpty from 'lodash/isEmpty';
import { sortBy } from 'lodash';
import SelectorField from '../../form/fieldTypes/SelectorField';
import Menu from '../../form/components/selector/standardParts/Menu';
import find from 'lodash/find';
import { activeElementsActionDeactivateAllOfType } from '../../../stateManagement/actions/activeElementsActions';
import { activeElementTypes } from '../../../constants/activeElementTypes';

const t = initT('features.taskCreator');

const relevantAttributes = ['description', 'todo', 'private', 'categoryId'];
const auxiliaryAttributes = [
  'locked',
  'incognitoMode',
  'elementToFocusOn',
  'elementToFocusOnActionReset',
  'setTodo',
  'setPrivate',
  'alwaysTodo',
  'fixedCategory',
  'visibleCategories',
  'setCategory',
];
const filterValues = produceValuesFilter(relevantAttributes);

const validationSchema = () =>
  yup.object().shape({
    description: yup.string().required(frontEndErrorT('task.description.empty')),
  });

const CategoryTrigger = ({ toggleMenu, color }) => (
  <CustomColorCrumb color={color} styleKeys={['color']}>
    <IconButton variant="category" onMouseDown={toggleMenu} />
  </CustomColorCrumb>
);

const TaskCreatorInnerForm = (props) => {
  const {
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    cancellable,
    hideCreator,
    values: {
      todo,
      private: _private,
      categoryId,
      auxiliary: {
        locked,
        incognitoMode,
        elementToFocusOn,
        elementToFocusOnActionReset,
        setTodo,
        setPrivate,
        alwaysTodo,
        fixedCategory,
        visibleCategories,
        setCategory,
      },
    },
  } = props;

  const descriptionInputRef = useRef(null);

  useEffect(() => {
    const shouldBeFocused = elementToFocusOn && elementToFocusOn.equals(elementToFocusOnTypes.taskCreationForm, null);
    if (locked === false && shouldBeFocused) {
      descriptionInputRef.current.focus();
      elementToFocusOnActionReset();
    }
  }, [locked]);

  const switchTodo = () => {
    setFieldValue('todo', !todo);
    setFieldTouched('todo');
    setTodo(!todo);
    descriptionInputRef.current.focus();
  };
  const switchTodoVariant = todo ? 'unmarkTodo' : 'markTodo';
  const switchPrivate = () => {
    setFieldValue('private', !_private);
    setFieldTouched('private');
    setPrivate(!_private);
    descriptionInputRef.current.focus();
  };
  const switchPrivateVariant = _private ? 'unmarkPrivate' : 'markPrivate';

  const selectedCategory = useMemo(() => find(visibleCategories, { id: categoryId }), [categoryId]);
  const categoriesOptions = useMemo(
    () =>
      visibleCategories.map((category) => ({
        label: category.name,
        value: category.id,
      })),
    [selectedCategory],
  );
  const onCategoryChange = useCallback((onChangeProps, _defaultOnChange) => {
    setCategory(find(visibleCategories, { id: onChangeProps.newValue }));
    _defaultOnChange(onChangeProps);
    descriptionInputRef.current.focus();
  }, []);

  return (
    <CustomColorCrumb
      styleKeys={['borderColor']}
      color={selectedCategory.color}
      mixinThemeType="container"
      mixedStyleKeysMultipliers={{ borderColor: 0.8 }}
    >
      <SmartForm className="flex items-end border-b border-dotted">
        <div className={`flex w-full pb-1 pl-1 ${fixedCategory ? 'sm:pl-12' : ''}`}>
          {!fixedCategory && (
            <div>
              <FastField
                name="categoryId"
                component={SelectorField}
                options={categoriesOptions}
                menu={<Menu fullyBordered />}
                trigger={<CategoryTrigger color={selectedCategory.color} />}
                onChange={onCategoryChange}
                fixedMenuWidth={200}
                selectedOptionOnTop
              />
            </div>
          )}
          <SmartField
            name="description"
            ref={descriptionInputRef}
            placeholder={t('descriptionPrompt')}
            component={TextField}
            themeType="transparentInput"
            textAlignClass="text-left"
            displayError={false}
            handleErrorThemeType={false}
          />
          <ButtonsGroup
            className="flex justify-end items-center px-1"
            buttons={[
              !alwaysTodo && <IconButton key="switchTodo" variant={switchTodoVariant} onMouseDown={switchTodo} />,
              !incognitoMode && (
                <IconButton key="switchPrivate" variant={switchPrivateVariant} onMouseDown={switchPrivate} />
              ),
              cancellable && <IconButton key="cancel" variant="cancel" onMouseDown={hideCreator} />,
              <IconButton
                key="submit"
                variant="submit"
                disabled={locked || !isEmpty(errors) || isEmpty(touched)}
                onMouseDown={handleSubmit}
                paddingClass="p-2"
              />,
            ]}
          />
        </div>
      </SmartForm>
    </CustomColorCrumb>
  );
};

const TaskCreatorForm = withCustomReinitialize(['locked', 'incognitoMode', 'visibleCategories', 'fixedCategory'])(
  withFormik({
    validationSchema,
    mapPropsToValues: (props) => customPropsToValues(props, filterValues, auxiliaryAttributes),
    handleSubmit: (values, formikBag) => {
      const task = new Task().assignValues(filterValues(values));
      formikBag.props.tasksActionCreateElement(task);
      // the form is reinitialized because 'locked' prop is changed externally
    },
  })(TaskCreatorInnerForm),
);

const TaskCreator = (props) => {
  const {
    tasks,
    categories,
    incognitoMode,
    alwaysTodo,
    fixedCategory,
    activeElementsActionDeactivateAllOfType: deactivate,
  } = props;

  const visibleCategories = useMemo(
    () => sortBy(categories.visible(incognitoMode), 'tasksOrder'),
    [categories, incognitoMode],
  );

  const [todo, setTodo] = useState(!!alwaysTodo);
  const [_private, setPrivate] = useState(false);
  const [auxiliaryCategory, setAuxiliaryCategory] = useState(visibleCategories[0]);

  const newTask = useMemo(() => {
    const task = new Task();

    if (fixedCategory) task.categoryId = fixedCategory.id;
    else task.categoryId = auxiliaryCategory.id;

    task.todo = todo;
    task.private = incognitoMode ? false : _private;
    return task;
  }, [fixedCategory, auxiliaryCategory, incognitoMode]);

  const hideCreator = useCallback(() => {
    deactivate(activeElementTypes.calendarTaskCreator);
  }, [deactivate]);

  return (
    <>
      <TaskCreatorForm
        {...props}
        {...newTask}
        locked={tasks.locked}
        setTodo={setTodo}
        setPrivate={setPrivate}
        setCategory={setAuxiliaryCategory}
        todo={todo}
        private={_private}
        visibleCategories={visibleCategories}
        hideCreator={hideCreator}
      />
    </>
  );
};

export default connect(prepareState(['incognitoMode', 'elementToFocusOn', 'tasks', 'categories']), {
  tasksActionCreateElement,
  elementToFocusOnActionReset,
  activeElementsActionDeactivateAllOfType,
})(TaskCreator);
