import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { notificationT } from '../../../helpers/i18nHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { notesActionUnlockElement, notesActionUnset } from '../../actions/notesActions';
import { push } from 'connected-react-router';

export default function* deleteElementNotesSaga(action) {
  const { element: initialNote } = action.payload;
  const { ok, data } = yield call(api.notes.delete, initialNote.id);
  if (ok) {
    yield put(notesActionUnset(initialNote));
    yield put(snackbarMessagesActionAdd(notificationT('notes.delete.success')));
    yield put(push('/notes'));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(notesActionUnlockElement(initialNote));
  }
}
