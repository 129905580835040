import React, { useRef } from 'react';
import { draggedElements } from '../../../../constants/draggedElements';
import EventPanel from '../../../features/eventPanel';
import List from '../../../lists/List';
import EventCreator from '../../../features/eventCreator';
import { initT } from '../../../../helpers/i18nHelpers';
import SubSectionTitle from '../../../titles/SubSectionTitle';
import { activeElementTypes } from '../../../../constants/activeElementTypes';

const t = initT('modals.calendarDayModal');

const Events = (props) => {
  const { date, events } = props;

  const draggableListRef = useRef(null);

  return (
    <>
      <SubSectionTitle>{t('events')}</SubSectionTitle>
      <div className="w-full mb-2">
        <EventCreator date={date} initialFocus />
      </div>
      <List
        collection={events}
        draggable={{
          ref: draggableListRef,
          draggedElementType: draggedElements.event,
        }}
        noDataCondition={events.length === 0}
      >
        {(event) => (
          <div key={event.id} className="w-full flex justify-center mt-2 first:mt-0">
            <EventPanel
              event={event}
              draggableListRef={draggableListRef}
              onModal={activeElementTypes.calendarDayModal}
            />
          </div>
        )}
      </List>
    </>
  );
};

export default Events;
