import { reverse } from 'lodash';

const removeWhitespaces = (text) => text.replace(/\s/g, '');

const findSliceIndex = (text, relevantCharsCount, considerPrecedingWhitespaces) => {
  let highlightedCharsCount = 0;
  let index = 0;
  while (highlightedCharsCount < relevantCharsCount) {
    const char = text[index];
    if (char.match(/[^\s]/)) {
      highlightedCharsCount++;
    }
    index++;
  }

  if (considerPrecedingWhitespaces) {
    const precedingWhitespacesMatch = text.slice(index).match(/^\s*/);
    if (precedingWhitespacesMatch) {
      index += precedingWhitespacesMatch[0].length;
    }
  }

  return index;
};

const createDeepCopy = (value) => {
  const newValue = [];

  value.forEach((element) => {
    const newElement = { ...element };
    if (Array.isArray(element.children)) {
      newElement.children = createDeepCopy(element.children);
    }
    newValue.push(newElement);
  });
  return newValue;
};

const getParents = (array) => {
  const result = [];
  const queue = [...array];

  while (queue.length > 0) {
    const element = queue.shift();

    if (Array.isArray(element.children)) {
      queue.unshift(...reverse([...element.children]));
      result.push(element);
    }
  }
  return result;
};

const iterateOverLeavesWithText = (array, rootParent, action) => {
  const queue = array.map((element, elementIndex) => ({ element, elementIndex, parent: rootParent }));

  while (queue.length > 0) {
    const {
      element,
      element: { children },
      parent,
      elementIndex,
    } = queue.shift();

    if (Array.isArray(children)) {
      reverse([...children]).forEach((child, index) => {
        queue.unshift({ element: child, parent: element, elementIndex: children.length - index - 1 });
      });
      continue;
    }

    if (element.text !== undefined) {
      if (action({ element, parent, elementIndex }) === false) break;
    }
  }
};

// Z TYM JEST PROBLEM!!!
const splitPrecedingWhitespaces = (value) => {
  getParents(value).forEach((parent) => {
    let splitCount = 0;
    [...parent.children].forEach((element, index) => {
      const { text } = element;
      if (text === undefined) return;

      const precedingWhitespacesMatch = text.match(/^\s*/);
      if (!precedingWhitespacesMatch || precedingWhitespacesMatch[0] === '') return;

      const beforeLength = precedingWhitespacesMatch[0].length;
      const before = text.slice(0, beforeLength);
      const after = text.slice(beforeLength);

      element.text = before;
      parent.children.splice(index + splitCount + 1, 0, { ...element, text: after });

      splitCount++;
    });
  });
};

const applyHighlight = (value, highlightedTextProps) => {
  const {
    charsCount: toBeHighlightedCharsCount,
    showCaret: showCaretProp,
    caretAtTheBeginningOfNextWord,
    erroneousCharsCount,
  } = highlightedTextProps;

  const showCaret = showCaretProp && !erroneousCharsCount;

  let handledCharsCount = 0;

  let caretBeforeNext = toBeHighlightedCharsCount === 0 && showCaret ? caretAtTheBeginningOfNextWord : false;

  iterateOverLeavesWithText(value, null, ({ element, parent, elementIndex }) => {
    if (handledCharsCount > toBeHighlightedCharsCount && !caretBeforeNext) return false;

    const aText = removeWhitespaces(element.text);
    const currentLastIndex = handledCharsCount + aText.length;

    if (aText.length === 0) return;

    if (caretBeforeNext) {
      element.highlightedCaretBefore = true;
      caretBeforeNext = false;

      return false;
    } else if (currentLastIndex > toBeHighlightedCharsCount) {
      const relevantCharsCount = toBeHighlightedCharsCount - handledCharsCount;
      const sliceIndex = findSliceIndex(element.text, relevantCharsCount, showCaret && caretAtTheBeginningOfNextWord);

      const before = element.text.slice(0, sliceIndex);
      const after = element.text.slice(sliceIndex);

      element.text = before;
      parent.children.splice(elementIndex + 1, 0, {
        ...element,
        text: after,
      });

      if (showCaret) element.highlightedCaretAfter = true;
    } else if (currentLastIndex === toBeHighlightedCharsCount && showCaret) {
      if (caretAtTheBeginningOfNextWord) caretBeforeNext = true;
      else element.highlightedCaretAfter = true;
    }
    element.highlighted = true;

    handledCharsCount += aText.length;
  });
};

const applyErrors = (value, highlightedTextProps) => {
  const { erroneousCharsCount, caretAtTheBeginningOfNextWord } = highlightedTextProps;

  const relevantElementsData = [];
  let notHighlightedElementAlreadyFound = false;
  let blockIndex = 0;
  let currentBlock = null;

  for (; blockIndex < value.length; blockIndex++) {
    currentBlock = value[blockIndex];

    let firstRelevantInBlock = true;

    iterateOverLeavesWithText(currentBlock.children, currentBlock, (elementData) => {
      const {
        element: { text, highlighted },
      } = elementData;

      const relevant = removeWhitespaces(text).length > 0;

      if (relevant && !highlighted) {
        notHighlightedElementAlreadyFound = true;

        // We don't want to mark errors in the following block - this handles a case of the end of a paragraph.
        if (firstRelevantInBlock && blockIndex !== 0 && !caretAtTheBeginningOfNextWord) {
          blockIndex -= 1;
          currentBlock = value[blockIndex];
          return false;
        }
      }
      if (notHighlightedElementAlreadyFound) relevantElementsData.push(elementData);

      if (relevant) firstRelevantInBlock = false;
    });
    if (notHighlightedElementAlreadyFound) break;
  }

  let remainingErroneousCharsCount = erroneousCharsCount;
  let currentElementData = relevantElementsData.shift();

  while (currentElementData && remainingErroneousCharsCount > 0) {
    const {
      element,
      parent,
      elementIndex,
      element: { text },
    } = currentElementData;

    if (remainingErroneousCharsCount >= text.length) {
      remainingErroneousCharsCount -= text.length;
    } else {
      const before = text.slice(0, remainingErroneousCharsCount);
      const after = text.slice(remainingErroneousCharsCount);
      remainingErroneousCharsCount = 0;

      element.text = before;
      parent.children.splice(elementIndex + 1, 0, {
        ...element,
        text: after,
      });
    }

    if (remainingErroneousCharsCount === 0) {
      element.lastHighlightedError = true;
    }
    element.highlightedError = true;

    currentElementData = relevantElementsData.shift();
  }

  if (currentBlock && remainingErroneousCharsCount > 0) {
    currentBlock.children.push({
      text: [...Array(remainingErroneousCharsCount)].map(() => ' ').join(''),
      highlightedError: true,
      lastHighlightedError: true,
    });
  }
};

export const prepareValueToHandleHighlight = (value, highlightedTextProps) => {
  const newValue = createDeepCopy(value);
  splitPrecedingWhitespaces(newValue);

  applyHighlight(newValue, highlightedTextProps);
  splitPrecedingWhitespaces(newValue);

  applyErrors(newValue, highlightedTextProps);

  return newValue;
};
