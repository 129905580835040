import { produceModelFromDto, produceModelToDto } from './modelHelpers';
import LanguageWorkspace from '../../models/languageWorkspace';

const mappings = [
  ['id', 'id'],
  ['name', 'name'],
  ['default_order', 'defaultOrder'],
  ['private', 'private'],
];

export const languageWorkspaceFromDto = produceModelFromDto(LanguageWorkspace, mappings);

export const languageWorkspaceToDto = produceModelToDto(mappings);
