import frenchkiss from 'frenchkiss';

frenchkiss.extend('en', {
  pages: {
    habits: {
      good: {
        title: 'Good habits',
      },
      bad: {
        title: 'Bad habits',
      },
      section: {
        title: 'Sections',
        add: 'New section',
        newSectionName: 'New section',
      },
      habit: {
        add: 'New habit',
        fields: {
          name: 'Name',
          inputType: 'Input type',
          habitsSectionId: 'Section',
          variant: 'Variant',
          considerOnlyUpdatedValues: 'Consider only updated values',
          hidden: 'Hidden',
          private: 'Private',
        },
        hidingConditions: {
          title: 'Hiding conditions',
          sinceWeekday: 'If happened {times} time(s) since last {weekday}',
          sinceCustomPeriod: 'If happened {times} time(s) during last {daysCounter} day(s)',
        },
        summaryRequests: {
          title: 'Visible summaries',
          variants: {
            sinceWeekday: 'since last {weekday}',
            sinceCustomPeriod: 'during last {daysCounter} day(s)',
            monthly: 'this month',
            annually: 'this year',
          },
        },
        chartRequests: {
          title: 'Visible charts',
          requestSummaryMiddlePart: 'of values aggregated',
        },
      },
    },
  },
});
