import { alignmentTypes } from '../../shared/features/nodesGroupPanel/nodeText/textEditor/constants/alignmentTypes';
import { isNestableBlockVariant } from '../../shared/features/nodesGroupPanel/nodeText/textEditor/helpers/blockNestingHelpers';
import {
  blockVariants,
  defaultBlockVariant,
} from '../../shared/features/nodesGroupPanel/nodeText/textEditor/constants/blockVariants';
import first from 'lodash/first';
import {
  maxNestingLevel,
  minNestingLevel,
} from '../../shared/features/nodesGroupPanel/nodeText/textEditor/constants/nestingLevels';
import { leafVariants } from '../../shared/features/nodesGroupPanel/nodeText/textEditor/constants/leafVariants';
import max from 'lodash/max';

const generalLoopRegex = /[\\*_^~`<|>\n]/g;

const alignmentMetadata = {
  '{': alignmentTypes.left,
  ':': alignmentTypes.center,
  '}': alignmentTypes.right,
  ';': alignmentTypes.justify,
};

const blockVariantMetadata = {
  '-': blockVariants.bullet,
  '=': blockVariants.orderedBullet,
  '.': blockVariants.checklistBullet,
  ',': blockVariants.checklistBullet,
};

const leafVariantMetadata = {
  '*': leafVariants.bold,
  _: leafVariants.italic,
  '^': leafVariants.underline,
  '~': leafVariants.strikethrough,
  '`': leafVariants.code,
};

class PlainToSlateService {
  call(plainText) {
    this.plainText = plainText;
    this.maxIndex = plainText.length;

    this.result = [];
    this.skipNext = false;
    this.currentIndex = 0;
    this.currentAlignment = alignmentTypes.left;
    this.currentBlock = this.getNewBlock();
    this.currentLeafMetadata = [];
    this.currentLeaf = this.getNewLeaf();
    this.currentLeafPartStartIndex = 0;

    this.currentLinkUrlStartIndex = 0;
    this.currentLinkElement = null;
    this.gatheringLinkUrl = false;
    this.gatheringLinkContent = false;

    this.currentIndex = -1;
    this.handleNewline();

    for (let match of this.plainText.matchAll(generalLoopRegex)) {
      if (this.skipNext) {
        this.skipNext = false;
        continue;
      }

      const { 0: matchValue, index } = match;
      this.currentIndex = index;

      if (this.gatheringLinkUrl) {
        if (matchValue === '|' && (this.getPreviousChar() !== '\\' || this.getPreviousChar(false) === '\\')) {
          this.handleLinkContentStart();
        }
        continue;
      }

      if (matchValue === '\\') {
        this.handleBackslash();
      } else if (/[*_^~`]/.test(matchValue)) {
        this.handleLeafVariantMarkdown(matchValue);
      } else if (matchValue === '\n') {
        this.handleNewline();
      } else if (matchValue === '<') {
        this.handleLinkUrlStart();
      } else if (this.gatheringLinkContent && matchValue === '>') {
        this.handleLinkContentEnd(this.currentIndex - 1, this.currentIndex + 1);
      }
    }
    this.currentIndex = this.plainText.length;
    this.endBlock();

    return this.result;
  }

  handleBackslash() {
    const nextChar = this.getNextChar();
    const previousChar = this.getPreviousChar();

    if (/[*_^~`<|>]/.test(nextChar)) {
      this.skipNext = true;
      this.endLeafPart(this.currentIndex - 1);
      this.currentLeafPartStartIndex = this.currentIndex + 1;
    } else if (nextChar === '\\') {
      this.skipNext = true;
      this.endLeafPart();
      this.currentLeafPartStartIndex = this.currentIndex + 2;
    } else if (previousChar === '\n' && /[{:};\-=.,+]/.test(nextChar)) {
      this.endLeafPart(this.currentIndex - 1);
      this.currentLeafPartStartIndex = this.currentIndex + 1;
    } else if (this.currentIndex === 0 && /[{:};\-=.,+]/.test(nextChar)) {
      this.currentLeafPartStartIndex = this.currentIndex + 1;
    }
  }

  handleLeafVariantMarkdown(markdown) {
    this.endLeafPart(this.currentIndex - 1);
    if (this.gatheringLinkContent) this.currentLinkElement.children.push(this.currentLeaf);
    else this.currentBlock.children.push(this.currentLeaf);

    const metadata = leafVariantMetadata[markdown];
    this.currentLeafMetadata.includes(metadata)
      ? (this.currentLeafMetadata = this.currentLeafMetadata.filter((v) => v !== metadata))
      : this.currentLeafMetadata.push(metadata);
    this.currentLeaf = this.getNewLeaf();
    this.currentLeafPartStartIndex = this.currentIndex + 1;
  }

  handleLinkUrlStart() {
    this.endLeafPart(this.currentIndex - 1);
    this.currentBlock.children.push(this.currentLeaf);
    this.currentLeaf = this.getNewLeaf();

    this.currentLinkUrlStartIndex = this.currentIndex + 1;
    this.gatheringLinkUrl = true;
    this.currentLinkElement = {
      type: 'link',
      children: [],
    };
  }

  handleLinkContentStart() {
    this.gatheringLinkUrl = false;
    this.gatheringLinkContent = true;
    this.currentLeafPartStartIndex = this.currentIndex + 1;

    this.currentLinkElement.url = this.plainText
      .substring(this.currentLinkUrlStartIndex, this.currentIndex)
      .replaceAll('\\|', '|');
  }

  handleLinkContentEnd(lastIndex = this.currentIndex, newLeafStartIndex = lastIndex + 1) {
    this.endLeafPart(lastIndex);
    this.currentLinkElement.children.push(this.currentLeaf);
    this.currentLeaf = this.getNewLeaf();
    this.currentLeafPartStartIndex = newLeafStartIndex;
    this.currentBlock.children.push(this.currentLinkElement);
    this.gatheringLinkContent = false;
  }

  handleNewline() {
    const nextChar = this.getNextChar();
    if (nextChar === '+') {
      this.endLeafPart();
      this.currentLeafPartStartIndex = this.currentIndex + 2;
    } else if (nextChar === '\n' && this.currentIndex !== -1) {
      this.skipNext = true;
      this.endBlock(this.currentIndex - 1, this.currentIndex + 2);
      this.currentBlock = this.getNewBlock(this.currentIndex + 2);
      this.currentLeaf = this.getNewLeaf();
    } else if (/[-=.,{:};]/.test(nextChar)) {
      this.endBlock(this.currentIndex - 1, this.currentIndex + 1);
      this.currentBlock = this.getNewBlock(this.currentIndex + 1);
      this.currentLeaf = this.getNewLeaf();
    }
  }

  getNewBlock(startIndex = this.currentIndex) {
    return {
      ...this.getBlockMetadata(startIndex),
      children: [],
    };
  }

  endBlock(lastIndex = this.currentIndex, newLeafStartIndex = lastIndex + 1) {
    if (lastIndex < 0) return;

    if (this.gatheringLinkContent) {
      this.handleLinkContentEnd(lastIndex, newLeafStartIndex);
    } else {
      this.endLeafPart(lastIndex);
      this.currentBlock.children.push(this.currentLeaf);
      this.currentLeafPartStartIndex = newLeafStartIndex;
    }
    this.result.push(this.currentBlock);
  }

  getNewLeaf() {
    const result = {
      text: '',
    };
    this.currentLeafMetadata.forEach((m) => (result[m] = true));
    return result;
  }

  endLeafPart(lastIndex = this.currentIndex) {
    this.currentLeaf.text += this.plainText.substring(
      this.currentLeafPartStartIndex,
      max([this.currentLeafPartStartIndex, lastIndex + 1]),
    );
  }

  getBlockMetadata(startIndex = this.currentIndex) {
    let relevantChars = [this.plainText[startIndex]];
    let nextCharIndex = startIndex + 1;
    const blockMetadata = {};

    const _getNextChar = () => {
      if (nextCharIndex > this.maxIndex) return null;
      return this.plainText[nextCharIndex];
    };

    const assignBlockVariantMetadata = (blockVariantChar) => {
      blockMetadata.type = blockVariantMetadata[blockVariantChar];
      if (isNestableBlockVariant(blockMetadata.type)) {
        let nestingLevel = minNestingLevel;
        while (nestingLevel < maxNestingLevel) {
          const nextChar = _getNextChar();
          if (nextChar === blockVariantChar) {
            nestingLevel += 1;
            relevantChars.push(nextChar);
            nextCharIndex += 1;
          } else break;
        }
        blockMetadata.nestingLevel = nestingLevel;
      }

      if (blockVariantChar === ',') blockMetadata.checked = true;
    };

    if (first(relevantChars) in blockVariantMetadata) {
      assignBlockVariantMetadata(first(relevantChars));
      let nextChar = _getNextChar();

      if (nextChar in alignmentMetadata) {
        relevantChars.push(nextChar);
        this.currentAlignment = alignmentMetadata[nextChar];
        nextCharIndex += 1;
        nextChar = _getNextChar();
      }

      if (nextChar === ' ') relevantChars.push(nextChar);
    } else if (first(relevantChars) in alignmentMetadata) {
      this.currentAlignment = alignmentMetadata[first(relevantChars)];
      let nextChar = _getNextChar();

      if (nextChar in blockVariantMetadata) {
        relevantChars.push(nextChar);
        nextCharIndex += 1;
        assignBlockVariantMetadata(nextChar);
        nextChar = _getNextChar();
      }

      if (nextChar === ' ') relevantChars.push(nextChar);
    } else {
      relevantChars = [];
    }

    blockMetadata.alignmentType = this.currentAlignment;
    if (!blockMetadata.type) blockMetadata.type = defaultBlockVariant;
    this.currentLeafPartStartIndex = startIndex + relevantChars.length;

    return blockMetadata;
  }

  initNextChars(startIndex = this.currentIndex) {
    this.nextCharsIterator = this.nextChars(startIndex);
  }

  getNextChar(init = true, startIndex = this.currentIndex) {
    if (init) this.initNextChars(startIndex);
    return this.nextCharsIterator.next().value;
  }

  *nextChars(startIndex = this.currentIndex) {
    let iterationIndex = startIndex + 1;
    while (iterationIndex < this.maxIndex) {
      yield this.plainText[iterationIndex];
      iterationIndex += 1;
    }
  }

  initPreviousChars(startIndex = this.currentIndex) {
    this.previousCharsIterator = this.previousChars(startIndex);
  }

  getPreviousChar(init = true, startIndex = this.currentIndex) {
    if (init) this.initPreviousChars(startIndex);
    return this.previousCharsIterator.next().value;
  }

  *previousChars(startIndex = this.currentIndex) {
    let iterationIndex = startIndex - 1;
    while (iterationIndex >= 0) {
      yield this.plainText[iterationIndex];
      iterationIndex -= 1;
    }
  }
}

export const plainToSlate = (plainText) => new PlainToSlateService().call(plainText);
