export const produceGetWidthFunction = (elementId) => () => {
  const element = document.getElementById(elementId);
  if (!element) return 0;
  return element.clientWidth;
};

export const produceGetHeightFunction = (elementId) => () => {
  const element = document.getElementById(elementId);
  if (!element) return 0;
  return element.clientHeight;
};

export const getWidth = (elementId) => produceGetWidthFunction(elementId)();

export const getHeight = (elementId) => produceGetHeightFunction(elementId)();

export const getNavbarHeight = produceGetHeightFunction('navbar');

export const getStandardTemplateTopHeight = produceGetHeightFunction('standard-content-template-top');

export const getStandardTemplateBottomHeight = produceGetHeightFunction('standard-content-template-bottom');

export const getStandardTemplateMainWidth = produceGetWidthFunction('standard-content-template-main');

export const get100vhInPx = () => document.getElementById('artificial-element-with-100vh-height').clientHeight;
