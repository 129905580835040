import Lockable from './parents/lockable';
import { getUuid } from '../helpers/identifierHelpers';
import { habitHidingConditionVariants } from '../constants/enums/habitHidingConditionVariants';
import { weekdayTypes } from '../constants/enums/weekdayTypes';

export default class HabitHidingCondition extends Lockable {
  constructor() {
    super();
    this.id = null;
    this.nestedAttributeUniqueIdentifier = getUuid();
    this.timesRequired = 1;
    this.variant = habitHidingConditionVariants.sinceWeekday;
    this.weekdayType = weekdayTypes.monday;
    this.customPeriodDaysCounter = 1;
  }

  visible() {
    return true;
  }
}
