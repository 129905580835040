import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { notificationT } from '../../../helpers/i18nHelpers';
import { configActionUpdate } from '../../actions/configActions';
import { learningQueueFromDto, learningQueueToDto } from '../../../helpers/model/learningQueueHelpers';
import { learningQueuesActionSetElement, learningQueuesActionUnlockElement } from '../../actions/learningQueuesActions';

export default function* updateElementLearningQueuesSaga(action) {
  const { element: initialQueue } = action.payload;
  const { ok, data } = yield call(api.learningQueues.update, learningQueueToDto(initialQueue));
  if (ok) {
    const newQueue = learningQueueFromDto(data);
    yield put(snackbarMessagesActionAdd(notificationT('learningQueues.update.success')));
    yield put(learningQueuesActionSetElement(newQueue));
    yield put(configActionUpdate('selectLearningWorkspace', { id: newQueue.learningWorkspaceId }));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(learningQueuesActionUnlockElement(initialQueue));
  }
}
