export const languageWorkspacesActionFetch = () => ({
  type: 'LANGUAGE_WORKSPACES_ACTION_FETCH',
});

export const languageWorkspacesActionSet = (languageWorkspaces) => ({
  type: 'LANGUAGE_WORKSPACES_ACTION_SET',
  payload: languageWorkspaces,
});

export const languageWorkspacesActionCreateElement = (languageWorkspace) => ({
  type: 'LANGUAGE_WORKSPACES_ACTION_CREATE_ELEMENT',
  payload: languageWorkspace,
});

export const languageWorkspacesActionUnlock = () => ({
  type: 'LANGUAGE_WORKSPACES_ACTION_UNLOCK',
});

export const languageWorkspacesActionUpdateElement = (languageWorkspace) => ({
  type: 'LANGUAGE_WORKSPACES_ACTION_UPDATE_ELEMENT',
  payload: languageWorkspace,
});

export const languageWorkspacesActionMarkPrivateElement = (languageWorkspace) => ({
  type: 'LANGUAGE_WORKSPACES_ACTION_MARK_PRIVATE_ELEMENT',
  payload: languageWorkspace.assignValues({ private: true }),
});

export const languageWorkspacesActionUnmarkPrivateElement = (languageWorkspace) => ({
  type: 'LANGUAGE_WORKSPACES_ACTION_UNMARK_PRIVATE_ELEMENT',
  payload: languageWorkspace.assignValues({ private: false }),
});

export const languageWorkspacesActionLockElement = (languageWorkspace) => ({
  type: 'LANGUAGE_WORKSPACES_ACTION_LOCK_ELEMENT',
  payload: languageWorkspace,
});

export const languageWorkspacesActionUnlockElement = (languageWorkspace) => ({
  type: 'LANGUAGE_WORKSPACES_ACTION_UNLOCK_ELEMENT',
  payload: languageWorkspace,
});

export const languageWorkspacesActionDeleteElement = (languageWorkspace) => ({
  type: 'LANGUAGE_WORKSPACES_ACTION_DELETE_ELEMENT',
  payload: languageWorkspace,
});

export const languageWorkspacesActionSetCreatedElement = (languageWorkspace) => ({
  type: 'LANGUAGE_WORKSPACES_ACTION_SET_CREATED_ELEMENT',
  payload: languageWorkspace,
});

export const languageWorkspacesActionSetElement = (languageWorkspace) => ({
  type: 'LANGUAGE_WORKSPACES_ACTION_SET_ELEMENT',
  payload: languageWorkspace,
});

export const languageWorkspacesActionUnset = (oneOrManyElements) => ({
  type: 'LANGUAGE_WORKSPACES_ACTION_UNSET',
  payload: oneOrManyElements,
});
