import React, { useMemo } from 'react';
import { learningQueueVariants } from '../../constants/enums/learningQueueVariants';
import AudioIcon from '../../icons/audioIcon';
import PdfIcon from '../../icons/pdfIcon';
import OverviewIcon from '../../icons/overviewIcon';
import TypingIcon from '../../icons/typingIcon';

const getComponent = (variant) => {
  const { audio, typing, pdf } = learningQueueVariants;

  if (variant === audio) return AudioIcon;
  if (variant === typing) return TypingIcon;
  if (variant === pdf) return PdfIcon;
  return OverviewIcon;
};

const QueueVariantIcon = (props) => {
  const { variant = learningQueueVariants.overview, ...otherProps } = props;

  return useMemo(() => React.createElement(getComponent(variant), otherProps), [variant]);
};

export default QueueVariantIcon;
