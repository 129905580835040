import React, { useEffect } from 'react';
import ThemeCrumb from '../../ThemeCrumb';

const notHideableTopWindowHeightThresholdPx = 500;

let lastScrollTop = 0;
let ongoingTouchmove = false;

const Top = (props) => {
  const { children, navbarHeightPx, windowHeightPx, maxWidthClass = '', hideable = false } = props;

  useEffect(() => {
    const top = document.getElementById('standard-content-template-top');
    if (!hideable) {
      top.classList.add('sticky');
      return;
    }
    if (windowHeightPx < notHideableTopWindowHeightThresholdPx) top.classList.remove('sticky');
    else top.classList.add('sticky');
  }, [windowHeightPx < notHideableTopWindowHeightThresholdPx, hideable]);

  useEffect(() => {
    lastScrollTop = 0;
    if (!hideable) return;

    const listener = () => {
      const top = document.getElementById('standard-content-template-top');
      if (windowHeightPx < notHideableTopWindowHeightThresholdPx) {
        top.classList.remove('sticky');
        return;
      }

      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop <= lastScrollTop && scrollTop > top.clientHeight) {
        top.classList.remove('sticky');
      } else if (!ongoingTouchmove) {
        top.classList.add('sticky');
      }
      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    };
    const onTouchmove = () => (ongoingTouchmove = true);
    const onTouchend = () => (ongoingTouchmove = false);
    window.addEventListener('scroll', listener);
    window.addEventListener('touchmove', onTouchmove);
    window.addEventListener('touchend', onTouchend);
    window.addEventListener('touchcancel', onTouchend);
    return () => {
      window.removeEventListener('scroll', listener);
      window.removeEventListener('touchmove', onTouchmove);
      window.removeEventListener('touchend', onTouchend);
      window.removeEventListener('touchcancel', onTouchend);
    };
  }, [windowHeightPx, hideable]);

  return (
    <ThemeCrumb type="main">
      <div
        id="standard-content-template-top"
        className="flex w-full justify-center pt-25px transition-all"
        style={{ zIndex: 5, top: navbarHeightPx }}
      >
        <ThemeCrumb type="standardTemplateTitle">
          <div
            className={`w-full flex justify-center items-center ${maxWidthClass}
                                 border rounded-xl`}
          >
            {children}
          </div>
        </ThemeCrumb>
      </div>
    </ThemeCrumb>
  );
};

export default Top;
