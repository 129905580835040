import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { notificationT } from '../../../helpers/i18nHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { recurringEventsActionUnset, recurringEventsActionUnlockElement } from '../../actions/recurringEventsActions';

export default function* deleteElementRecurringEventsSaga(action) {
  const initialRecurringEvent = action.payload;
  const { ok, data } = yield call(api.recurringEvents.delete, initialRecurringEvent.id);
  if (ok) {
    yield put(snackbarMessagesActionAdd(notificationT('recurringEvents.delete.success')));
    yield put(recurringEventsActionUnset(initialRecurringEvent));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(recurringEventsActionUnlockElement(initialRecurringEvent));
  }
}
