import React from 'react';
import H2 from './H2';
import ThemeCrumb from '../ThemeCrumb';

const SectionTitle = (props) => {
  const { children, additionalContent = false, marginClass = 'mb-4' } = props;

  const title = (
    <ThemeCrumb type="sectionTitle">
      <span>
        <H2 className="truncate">{children}</H2>
      </span>
    </ThemeCrumb>
  );

  return (
    <div className={`w-full flex justify-center ${marginClass}`}>
      <span className="invisible">{additionalContent}</span>
      <span className="w-full">{title}</span>
      {additionalContent}
    </div>
  );
};

export default SectionTitle;
