const initialState = () => ({
  lockedForId: null,
  audioBuffers: {},
});

export default function (state = initialState(), action) {
  switch (action.type) {
    case 'AUDIO_CONFIG_ACTION_LOCK':
      return { ...state, lockedForId: action.payload.id };
    case 'AUDIO_CONFIG_ACTION_UNLOCK': {
      if (state.lockedForId === action.payload.id) return { ...state, lockedForId: null };
      return state;
    }
    case 'AUDIO_CONFIG_ACTION_SET_AUDIO_BUFFER':
      return {
        ...state,
        audioBuffers: {
          ...state.audioBuffers,
          [action.payload.src]: action.payload.audioBuffer,
        },
      };
    case 'GLOBAL_ACTION_RESET':
      return initialState();
    default:
      return state;
  }
}
