import { isString } from 'lodash';

export const slateToPureText = (slateText) => {
  let result = '';

  (slateText || []).forEach((highestElement, index) => {
    if (index > 0) result += '\n';

    const queue = [...highestElement.children];

    while (queue.length > 0) {
      const element = queue.shift();
      if (element.children) queue.unshift(...element.children);
      if (isString(element.text)) result += element.text;
    }
  });

  return result;
};
