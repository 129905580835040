import React from 'react';
import { withCustomReinitialize } from '../../../helpers/formHelpers';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { frontEndErrorT, initT } from '../../../helpers/i18nHelpers';
import { connect } from 'react-redux';
import { labelsActionToggleAssignment } from '../../../stateManagement/actions/labelsActions';
import SmartForm from '../../form/SmartForm';
import TextField from '../../form/fieldTypes/TextField';
import SmartField from '../../form/SmartField';
import IconButton from '../../buttons/IconButton';
import isEmpty from 'lodash/isEmpty';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import ThemeCrumb from '../../ThemeCrumb';

const t = initT('features.labelsSelector');

export const validationSchema = () =>
  yup.object().shape({
    name: yup.string().required(frontEndErrorT('label.name.empty')),
  });

const LabelInputInnerForm = (props) => {
  const {
    handleSubmit,
    errors,
    touched,
    values,
    values: {
      auxiliary: { locked },
    },
  } = props;

  const additionDisabled =
    locked || !isEmpty(errors) || isEmpty(touched) || values.auxiliary.labelsNames.includes(values.name);

  return (
    <ThemeCrumb type="container">
      <SmartForm className="flex items-end border-b border-dotted rounded-t-md">
        <div className="flex w-full p-1">
          <SmartField
            name="name"
            placeholder={t('descriptionPrompt')}
            component={TextField}
            themeType="transparentInput"
            textAlignClass="text-left"
            displayError={false}
            handleErrorThemeType={false}
            autoFocus
            onChange={(onChangeProps, defaultOnChange) => {
              values.auxiliary.onChange(onChangeProps.event.target.value);
              defaultOnChange(onChangeProps);
            }}
          />
          <IconButton
            key="add"
            variant="add"
            disabled={additionDisabled}
            onMouseDown={handleSubmit}
            paddingClass="p-1"
          />
        </div>
      </SmartForm>
    </ThemeCrumb>
  );
};

const LabelInput = withCustomReinitialize(['object', 'locked', 'labelsNames'])(
  withFormik({
    validationSchema,
    mapPropsToValues: (props) => {
      const name = '';
      setTimeout(() => props.onChange(name));
      return {
        name,
        auxiliary: {
          object: props.object,
          onChange: props.onChange,
          labelsNames: props.labelsNames,
          locked: false,
        },
      };
    },
    handleSubmit: (values, formikBag) => {
      if (values.auxiliary.labelsNames.includes(values.name)) return;

      formikBag.props.labelsActionToggleAssignment(values.auxiliary.object, values.name);
    },
  })(LabelInputInnerForm),
);

export default connect(prepareState(['labels']), {
  labelsActionToggleAssignment,
})(LabelInput);
