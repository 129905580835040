import React from 'react';
import { alignmentTypeToTextClass } from '../helpers/alignmentHelpers';

const Block = React.forwardRef((props, ref) => {
  const { children, alignmentType, attributes } = props;
  return (
    <div className={`pt-4 ${alignmentTypeToTextClass(alignmentType)}`} ref={ref} {...attributes}>
      {children}
    </div>
  );
});

export default Block;
