import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { nodesActionFetch } from '../../../stateManagement/actions/nodesActions';
import LockedEntityLoader from '../../loaders/LockedEntityLoader';
import NodesGroupPanel from '../nodesGroupPanel';
import NoData from '../../placeholders/NoData';
import { initT } from '../../../helpers/i18nHelpers';
import CustomColorCrumb from '../../CustomColorCrumb';

const t = initT('features.eventPanel');

const Details = (props) => {
  const {
    event: { color, nodesGroupId },
    nodes,
    nodesActionFetch,
    onModal,
  } = props;

  useEffect(() => {
    if (!nodes.fetched(nodesGroupId)) nodesActionFetch(nodesGroupId);
  }, [nodes.fetched(nodesGroupId)]);

  return (
    <CustomColorCrumb
      styleKeys={['borderColor', 'backgroundColor']}
      color={color}
      mixinThemeType="container"
      mixedStyleKeysMultipliers={{ borderColor: 0.95, backgroundColor: 0.98 }}
    >
      <div className="p-25px border-b border-l border-r rounded-b">
        <LockedEntityLoader visible={!nodes.fetched(nodesGroupId)}>
          <NodesGroupPanel
            nodesGroupId={nodesGroupId}
            noDataPlaceholder={<NoData placeholder={t('noDetails')} />}
            onModal={onModal}
          />
        </LockedEntityLoader>
      </div>
    </CustomColorCrumb>
  );
};

export default connect(prepareState('nodes'), {
  nodesActionFetch,
})(Details);
