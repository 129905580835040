import React, { useEffect } from 'react';
import ThemeCrumb from '../../../ThemeCrumb';
import H3 from '../../../titles/H3';
import AuxiliaryText from '../../../AuxiliaryText';
import { initT } from '../../../../helpers/i18nHelpers';
import NodePanel from '../../../features/nodesGroupPanel/nodePanel';

const t = initT('modals.audioSelectionModal');

const RecordingPlaceholder = ({ stopRecording, node }) => {
  useEffect(() => {
    setTimeout(() => {
      window.addEventListener(
        'click',
        (event) => {
          stopRecording();
          event.preventDefault();
        },
        { once: true },
      );
    }, 400);
  }, [stopRecording]);

  return (
    <div className="w-full">
      <div className="w-full flex items-center justify-center pulse">
        <ThemeCrumb type="recording">
          <div className="w-3 h-3 recording-circle mr-1" />
        </ThemeCrumb>
        <H3 widthClass="w-auto">{t('recording')}</H3>
      </div>
      <AuxiliaryText className="text-center">{t('clickToStop')}</AuxiliaryText>
      <div className="p-25px">
        <NodePanel node={node} editionAllowed={false} interactive={false} />
      </div>
    </div>
  );
};

export default RecordingPlaceholder;
