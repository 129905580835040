import React, { useMemo } from 'react';
import MainTopSpace from '../../../../shared/organizers/MainTopSpace';
import LabelledButton from '../../../../shared/buttons/LabelledButton';
import { initT } from '../../../../helpers/i18nHelpers';
import Container from '../../../../shared/organizers/containers/Container';
import useNarrowMainWidth from '../../../../hooks/useNarrowMainWidth';
import RecurringEvent from '../../../../models/recurringEvent';
import RecurringEventForm from './RecurringEventForm';

const t = initT('pages.other.recurringEvents');

const RecurringEventAdditionPanel = (props) => {
  const { additionMode, setAdditionMode } = props;

  const newRecurringEvent = useMemo(() => new RecurringEvent(), []);

  const activeWidth = useNarrowMainWidth(false);

  return (
    <>
      <MainTopSpace>
        <LabelledButton
          variant="add"
          wrapperClass={additionMode ? 'invisible' : ''}
          onMouseDown={() => setAdditionMode(true)}
        >
          {t('add')}
        </LabelledButton>
      </MainTopSpace>
      {additionMode ? (
        <div className="w-full flex justify-center px-25px">
          <div style={{ width: activeWidth }}>
            <Container>
              <RecurringEventForm
                {...newRecurringEvent}
                editionMode
                onBlur={() => {
                  setAdditionMode(false);
                }}
              />
            </Container>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default RecurringEventAdditionPanel;
