import frenchkiss from 'frenchkiss';

frenchkiss.extend('en', {
  pages: {
    monthlyCalendar: {
      previousMonth: 'Previous month',
      nextMonth: 'Next month',
      finderTooltip: 'Search for events or checked off tasks',
    },
  },
});
