import React, { useState } from 'react';
import CenteredLoader from '../../loaders/CenteredLoader';
import FixedMenuController from '../../organizers/fixedMenu/FixedMenuController';
import useResizeEffect from '../../../hooks/useResizeEffect';
import { getNavbarHeight, getStandardTemplateBottomHeight } from '../../../helpers/elementsSizeHelpers';
import ThemeCrumb from '../../ThemeCrumb';
import FullWindowHeightWrapper from '../../heightWrappers/FullWindowHeightWrapper';
import Loader from '../../loaders/Loader';
import Bottom from './Bottom';
import Top from './Top';

const getBigScreenContent = (props) => {
  const { top, left, main, right, bottom, narrowMain, windowWidthPx, windowHeightPx, navbarHeightPx, bottomHeightPx } =
    props;

  const ultraWidthPx = 1980;

  const sideWidthPx = 300;
  const sideOffset = windowWidthPx > ultraWidthPx ? '((100% - 1980px) / 2)' : '0px';

  const mainMarginLeft = left || narrowMain ? `calc(${sideWidthPx}px + 75px)` : '25px';
  const mainMarginRight = right || narrowMain ? `calc(${sideWidthPx}px + 75px)` : '25px';
  const marginBottomPx = bottom ? bottomHeightPx || 0 : 0;
  const mainMargins = `0 ${mainMarginRight} ${marginBottomPx}px ${mainMarginLeft}`;

  const bottomOffsetLeft = `(${sideOffset} + ${left || narrowMain ? `${sideWidthPx}px + 50px` : '0px'} + 25px)`;
  const bottomOffsetRight = `(${sideOffset} + ${right || narrowMain ? `${sideWidthPx}px + 50px` : '0px'} + 25px)`;

  return (
    <>
      <div className="w-full flex justify-center">
        <div className="w-full max-w-ultra">
          <div className="w-full" style={{ padding: mainMargins }}>
            {top ? (
              <Top navbarHeightPx={navbarHeightPx} windowHeightPx={windowHeightPx}>
                {top}
              </Top>
            ) : (
              <></>
            )}
            <div id="standard-content-template-main" className="w-full">
              {main}
            </div>
          </div>
        </div>
      </div>
      {left && (
        <div
          className="fixed z-10"
          style={{
            top: `${navbarHeightPx}px`,
            left: `calc(${sideOffset})`,
            width: `${sideWidthPx}px`,
          }}
        >
          <ThemeCrumb type="standardTemplateSide">
            <div className="ultra:border-l border-r">
              <FullWindowHeightWrapper minHeight="10rem" marginBottomPx={0}>
                {left}
              </FullWindowHeightWrapper>
            </div>
          </ThemeCrumb>
        </div>
      )}
      {right && (
        <div
          className="fixed z-10"
          style={{
            top: `${navbarHeightPx}px`,
            right: `calc(${sideOffset})`,
            width: `${sideWidthPx}px`,
          }}
        >
          <ThemeCrumb type="standardTemplateSide">
            <div className="border-l ultra:border-r">
              <FullWindowHeightWrapper minHeight="10rem" marginBottomPx={0}>
                {right}
              </FullWindowHeightWrapper>
            </div>
          </ThemeCrumb>
        </div>
      )}
      {bottom ? (
        <Bottom
          leftOffset={bottomOffsetLeft}
          rightOffset={bottomOffsetRight}
          windowHeightPx={windowHeightPx}
          bottomHeightPx={bottomHeightPx}
        >
          {bottom}
        </Bottom>
      ) : (
        <></>
      )}
    </>
  );
};

const getSmallScreenContent = (props) => {
  const {
    top,
    left,
    right,
    main,
    bottom,
    windowWidthPx,
    windowHeightPx,
    navbarHeightPx,
    bottomHeightPx,
    smallScreensFixedMenu,
  } = props;

  const marginBottomPx = bottom ? bottomHeightPx || 0 : 0;
  const maxBottomWidthWithMarginsPx = 1152 + 50;
  const bottomOffset = windowWidthPx > maxBottomWidthWithMarginsPx ? 'calc((100% - 72rem) / 2)' : '25px';

  return (
    <div>
      {smallScreensFixedMenu.left || smallScreensFixedMenu.right ? (
        <FixedMenuController
          left={{ top, left, right, main, bottom }[smallScreensFixedMenu.left]}
          right={{ top, left, right, main, bottom }[smallScreensFixedMenu.right]}
        />
      ) : (
        <></>
      )}
      <div className="px-25px" style={{ paddingBottom: marginBottomPx }}>
        {top ? (
          <Top navbarHeightPx={navbarHeightPx} windowHeightPx={windowHeightPx} maxWidthClass="max-w-6xl" hideable>
            {top}
          </Top>
        ) : (
          <></>
        )}
        {main ? (
          <div id="standard-content-template-main" className="pb-25px last:pb-0 flex justify-center">
            <div className="w-full max-w-6xl">{main}</div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {bottom ? (
        <Bottom
          leftOffset={bottomOffset}
          rightOffset={bottomOffset}
          windowHeightPx={windowHeightPx}
          bottomHeightPx={bottomHeightPx}
          hideable
        >
          {bottom}
        </Bottom>
      ) : (
        <></>
      )}
    </div>
  );
};

const getContent = ({ renderBig, ...props }) => (
  <>
    {renderBig && getBigScreenContent(props)}
    {!renderBig && getSmallScreenContent(props)}
  </>
);

const StandardContentTemplate = (props) => {
  const {
    renderBigThresholdPx = 1366,
    topReady = true,
    top,
    leftReady = true,
    left,
    mainReady = true,
    main,
    rightReady = true,
    right,
    bottomReady = true,
    bottom,
    narrowMain = true,
    smallScreensFixedMenu = {
      left: left ? 'left' : false,
      right: right ? 'right' : false,
    },
  } = props;

  const [windowWidthPx, setWindowWidthPx] = useState();
  const [windowHeightPx, setWindowHeightPx] = useState();
  const [navbarHeightPx, setNavbarHeightPx] = useState();
  const [bottomHeightPx, setBottomHeightPx] = useState();

  useResizeEffect(
    () => {
      setWindowWidthPx(window.innerWidth);
      setWindowHeightPx(window.innerHeight);
      setNavbarHeightPx(getNavbarHeight());
    },
    [],
    true,
  );

  useResizeEffect(
    () => {
      setTimeout(() => setBottomHeightPx(getStandardTemplateBottomHeight()));
    },
    [bottomReady],
    true,
  );

  if (windowWidthPx === undefined) return <></>;

  return getContent({
    renderBig: windowWidthPx >= renderBigThresholdPx,
    top: topReady ? top : <Loader sizeClass="w-8 h-8" />,
    left: leftReady ? left : <CenteredLoader />,
    main: mainReady ? (
      main
    ) : (
      <div className="pt-25px">
        <Loader />
      </div>
    ),
    right: rightReady ? right : <CenteredLoader />,
    bottom: bottomReady ? bottom : <Loader sizeClass="w-8 h-8" />,
    narrowMain,
    windowWidthPx,
    windowHeightPx,
    navbarHeightPx,
    bottomHeightPx,
    smallScreensFixedMenu,
  });
};

export default StandardContentTemplate;
