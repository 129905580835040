import camelCase from 'lodash/camelCase';

import { getUuid } from './identifierHelpers';
import snackbarMessages from '../constants/snackbarMessages';
import { notificationT, notificationTKey } from './i18nHelpers';

export const getSnackbarBackendErrorMessage = (errors, i18nKey) => {
  let message = notificationT('unexpectedError');
  try {
    errors.every((error) => {
      const attributeName = camelCase(error.attribute);
      const attributeType = camelCase(error.type);
      const tKey = `${i18nKey}.${attributeName}.${attributeType}`;
      const messageCandidate = notificationT(tKey);
      if (messageCandidate !== notificationTKey(tKey)) {
        message = messageCandidate;
        return false;
      }
    });
    // eslint-disable-next-line no-empty
  } catch (_) {}
  return { message, type: snackbarMessages.types.error, uuid: getUuid() };
};
