import Lockable from './parents/lockable';
import { getUuid } from '../helpers/identifierHelpers';
import { habitSummaryRequestVariants } from '../constants/enums/habitSummaryRequestVariants';
import { weekdayTypes } from '../constants/enums/weekdayTypes';
import { aggregationMethods } from '../constants/enums/aggregationMethods';

export default class HabitSummaryRequest extends Lockable {
  constructor() {
    super();
    this.id = null;
    this.nestedAttributeUniqueIdentifier = getUuid();
    this.aggregationMethod = aggregationMethods.valuesSum;
    this.variant = habitSummaryRequestVariants.sinceWeekday;
    this.customPeriodDaysCounter = 1;
    this.weekdayType = weekdayTypes.monday;
  }

  visible() {
    return true;
  }
}
