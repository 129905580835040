import React, { useCallback, useEffect, useState } from 'react';
import { activeElementTypes } from '../../../constants/activeElementTypes';
import ActivableContainer from '../../organizers/containers/ActivableContainer';
import Header from './Header';
import ActiveElement from '../../../models/auxiliary/activeElement';
import Details from './Details';
import Container from '../../organizers/containers/Container';
import TaskForm from './TaskForm';
import { connect } from 'react-redux';
import { nodesActionFetch } from '../../../stateManagement/actions/nodesActions';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import LockedEntityLoader from '../../loaders/LockedEntityLoader';
import OnClickOutsideWrapper from '../../onClickOutside/OnClickOutsideWrapper';

const TaskPanel = (props) => {
  const {
    task,
    task: { nodesGroupId },
    activeElementType = activeElementTypes.task,
    draggableListRef,
    nodes,
    nodesActionFetch,
    width,
    activeWidth = width,
  } = props;

  const [editionMode, setEditionMode] = useState(false);
  const nodesFetched = nodes.fetched(nodesGroupId);

  useEffect(() => {
    if (!nodesFetched && editionMode) nodesActionFetch(nodesGroupId);
  }, [nodesFetched, editionMode]);

  const activateEditionMode = useCallback(() => {
    setEditionMode(true);
    if (draggableListRef.current) draggableListRef.current.disableDragging();
  }, [draggableListRef && draggableListRef.current]);
  const deactivateEditionMode = useCallback(() => {
    setEditionMode(false);
    if (draggableListRef.current) draggableListRef.current.enableDragging();
  }, [draggableListRef && draggableListRef.current]);

  if (editionMode && nodesFetched)
    return (
      <div className="transition-all" style={{ width }}>
        <Container>
          <LockedEntityLoader visible={!nodesFetched}>
            <TaskForm {...task} deactivateEditionMode={deactivateEditionMode} />
          </LockedEntityLoader>
        </Container>
      </div>
    );

  return (
    <ActivableContainer
      activeElement={new ActiveElement(activeElementType, task.id)}
      themeType={false}
      borderClass={false}
      loading={task.locked}
      className="transition-all"
      width={width}
      activeWidth={activeWidth}
      widthClass={width ? undefined : 'w-full'}
      activeWidthClass={activeWidth ? undefined : 'w-full'}
      renderCommonPart={(containerProps) => (
        <Header {...props} {...containerProps} activateEditionMode={activateEditionMode} />
      )}
      renderActive={({ commonPart, deactivateContainer }) => (
        <OnClickOutsideWrapper onClickOutside={deactivateContainer}>
          {commonPart}
          {<Details {...props} />}
        </OnClickOutsideWrapper>
      )}
    />
  );
};

export default connect(prepareState(['nodes']), {
  nodesActionFetch,
})(TaskPanel);
