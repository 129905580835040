import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MenuTitle from '../../../shared/titles/MenuTitle';
import { initT } from '../../../helpers/i18nHelpers';
import MenuContent from '../../../shared/organizers/MenuContent';
import LabelledButton from '../../../shared/buttons/LabelledButton';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { notesActionCreateElement, notesActionDuplicateElement } from '../../../stateManagement/actions/notesActions';
import { useCheckIfMounted } from '../../../hooks/useCheckIfMounted';
import Note from '../../../models/note';
import NotesList from './NotesList';
import { getUuid } from '../../../helpers/identifierHelpers';
import { draggedElementActionDrop } from '../../../stateManagement/actions/draggedElementActions';
import { getCookieExpandedNotesIds, getNotesTreeHash, setCookieExpandedNotesIds } from '../../../helpers/notesHelpers';
import { snackbarMessagesActionAdd } from '../../../stateManagement/actions/snackbarMessagesActions';
import snackbarMessages from '../../../constants/snackbarMessages';
import { useParams } from 'react-router-dom';

const t = initT('pages.notes');

const Left = (props) => {
  const {
    workspace,
    notes,
    incognitoMode,
    notesActionCreateElement,
    draggedElementActionDrop,
    snackbarMessagesActionAdd,
    notesActionDuplicateElement,
  } = props;

  const [notesListKey, setNotesListKey] = useState(getUuid());
  const refreshNotesList = () => setNotesListKey(getUuid());
  useEffect(() => {
    refreshNotesList();
  }, [notes]);
  const notesTreeHash = useMemo(
    () => getNotesTreeHash(notes, workspace.id, incognitoMode),
    [notes, incognitoMode, workspace.id],
  );
  const workspaceNotesIds = useMemo(
    () =>
      Object.keys(notesTreeHash)
        .map((id) => parseInt(id))
        .filter((id) => !isNaN(id)),
    [notesTreeHash],
  );

  const [creationEnabled, setCreationEnabled] = useState(true);
  const [dragged, setDragged] = useState(false);

  const [expandedNotes, _setExpandedNotes] = useState(
    getCookieExpandedNotesIds().filter((id) => workspaceNotesIds.includes(id)),
  );
  const setExpandedNotes = useCallback(
    (ids) => {
      const sanitizedIds = ids.filter((id) => workspaceNotesIds.includes(id));
      _setExpandedNotes(sanitizedIds);
      setCookieExpandedNotesIds([
        ...getCookieExpandedNotesIds().filter((id) => !workspaceNotesIds.includes(id)),
        ...sanitizedIds,
      ]);
    },
    [notesTreeHash, workspaceNotesIds],
  );

  const checkIfMounted = useCheckIfMounted();
  const createNewNote = useCallback(
    (parentId = null) => {
      setCreationEnabled(false);
      const newNote = new Note();
      newNote.name = t('newNoteName');
      newNote.notesWorkspaceId = workspace.id;
      newNote.parentId = parentId;
      notesActionCreateElement(newNote);
      setTimeout(() => {
        if (checkIfMounted()) setCreationEnabled(true);
      }, 1000);
    },
    [creationEnabled],
  );
  useEffect(() => {
    const newExpandedNotes = [];
    expandedNotes.forEach((noteId) => {
      const treeNode = notesTreeHash[noteId];
      if (!treeNode) return;
      if (treeNode.childrenIds.length === 0) return;
      newExpandedNotes.push(noteId);
    });
    setExpandedNotes(newExpandedNotes);
  }, [notes]);
  const { noteId } = useParams();
  useEffect(() => {
    const noteIdInt = parseInt(noteId, 10);
    const noteTreeHash = notesTreeHash[noteIdInt];
    if (!noteTreeHash) return;

    const idsToExpand = [];
    let currentParentId = noteTreeHash.parentId;
    while (currentParentId) {
      idsToExpand.push(currentParentId);
      currentParentId = notesTreeHash[currentParentId].parentId;
    }
    setExpandedNotes([...expandedNotes.filter((id) => !idsToExpand.includes(id)), ...idsToExpand]);
  }, [noteId]);

  return (
    <MenuContent
      top={
        <>
          <MenuTitle marginClass="mx-25px py-25px" to="/notes/workspaces" tooltip={t('changeWorkspace')}>
            {workspace.name}
          </MenuTitle>
          <div className="pt-25px px-25px pb-4 flex justify-center">
            <LabelledButton
              variant="add"
              onMouseDown={() => createNewNote()}
              disabled={notes.locked || !creationEnabled}
            >
              {t('create')}
            </LabelledButton>
          </div>
        </>
      }
      main={
        <div className="w-full px-25px pb-25px">
          <NotesList
            key={notesListKey}
            notesTreeHash={notesTreeHash}
            expandedNotes={expandedNotes}
            setExpandedNotes={setExpandedNotes}
            dragged={dragged}
            setDragged={setDragged}
            draggedElementActionDrop={draggedElementActionDrop}
            refresh={refreshNotesList}
            showError={(message) => {
              snackbarMessagesActionAdd(message, snackbarMessages.types.error);
            }}
            locked={notes.locked}
            createNewNote={createNewNote}
            duplicateNote={notesActionDuplicateElement}
          />
        </div>
      }
      bottom={
        <div className="w-full flex justify-center p-25px">
          <LabelledButton variant="pdf" to="/notes/consolidated-pdf" preventContextMenu>
            {t('pdfFromMultipleNotes')}
          </LabelledButton>
        </div>
      }
    />
  );
};

export default connect(prepareState(['notes', 'incognitoMode']), {
  notesActionCreateElement,
  draggedElementActionDrop,
  snackbarMessagesActionAdd,
  notesActionDuplicateElement,
})(Left);
