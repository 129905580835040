import { sortBy } from 'lodash';
import reverse from 'lodash/reverse';
import Cookies from 'js-cookie';

const EXPANDED_NOTES_IDS_COOKIE_IDENTIFIER = 'expanded_notes_ids';

export const getAllNotesWorkspaces = (notesWorkspaces, incognitoMode) =>
  sortBy(notesWorkspaces.visible(incognitoMode), 'defaultOrder');

export const getNotes = (notes, notesWorkspaceId, parentId, incognitoMode) =>
  sortBy(notes.visible(incognitoMode, { notesWorkspaceId, parentId }), 'defaultOrder');

export const getNotesTreeHash = (notes, notesWorkspaceId, incognitoMode) => {
  const result = {};
  const queue = [{ parentId: null, note: null, nestingLevel: -1 }];

  let index = 0;
  while (index < queue.length) {
    const { parentId, note, nestingLevel } = queue[index];
    const noteId = note === null ? null : note.id;
    const childrenIds = [];
    sortBy(notes.visible(incognitoMode, { notesWorkspaceId, parentId: noteId }), 'defaultOrder').forEach((child) => {
      queue.push({ parentId: noteId, note: child, nestingLevel: nestingLevel + 1 });
      childrenIds.push(child.id);
    });
    const nestedLevels = childrenIds.length > 0 ? 1 : 0;

    result[noteId] = {
      note,
      parentId,
      childrenIds,
      nestedLevels,
      nestingLevel,
    };

    if (note !== null && nestedLevels > 0) {
      let currentParentHash = result[parentId];
      let currentNestedLevels = nestedLevels;

      while (currentParentHash.nestedLevels === currentNestedLevels) {
        currentParentHash.nestedLevels += 1;
        if (currentParentHash.note === null) break;
        currentNestedLevels += 1;
        currentParentHash = result[currentParentHash.parentId];
      }
    }

    index += 1;
  }
  return result;
};

export const getSortedNotesIds = (notesTreeHash) => {
  if (!notesTreeHash[null]) return [];

  const result = [];
  const queue = [null];
  while (queue.length > 0) {
    const currentId = queue[0];
    queue.shift();
    reverse(notesTreeHash[currentId].childrenIds).forEach((id) => queue.unshift(id));
    if (currentId !== null) result.push(currentId);
  }
  return result;
};

export const getCookieExpandedNotesIds = () => {
  const cookieValue = Cookies.get(EXPANDED_NOTES_IDS_COOKIE_IDENTIFIER);
  return cookieValue ? JSON.parse(cookieValue) : [];
};

export const setCookieExpandedNotesIds = (ids) =>
  Cookies.set(EXPANDED_NOTES_IDS_COOKIE_IDENTIFIER, JSON.stringify(ids));

export const removeCookieExpandedNotesIds = () => Cookies.remove(EXPANDED_NOTES_IDS_COOKIE_IDENTIFIER);
