import { produceModelFromDto, produceModelToDto } from './modelHelpers';
import HabitChartRequest from '../../models/habitChartRequest';

const mappings = [
  ['id', 'id'],
  ['aggregation_method', 'aggregationMethod', 'enum'],
  ['variant', 'variant', 'enum'],
];

export const habitChartRequestFromDto = produceModelFromDto(HabitChartRequest, mappings);

export const habitChartRequestToDto = produceModelToDto(mappings);
