import Fetchable from './parents/fetchable';

export default class Session extends Fetchable {
  constructor() {
    super();
    this.signedIn = false;
    this.email = null;
    this.fullName = null;
    this.picture = null;
  }
}
