export const labelsActionFetch = () => ({
  type: 'LABELS_ACTION_FETCH',
});

export const labelsActionSet = (labels) => ({
  type: 'LABELS_ACTION_SET',
  payload: labels,
});

export const labelsActionUnlock = () => ({
  type: 'LABELS_ACTION_UNLOCK',
});

export const labelsActionToggleAssignment = (object, labelName) => ({
  type: 'LABELS_ACTION_TOGGLE_ASSIGNMENT',
  payload: { object, labelName },
});

export const labelsActionMarkPrivateElement = (label) => ({
  type: 'LABELS_ACTION_MARK_PRIVATE_ELEMENT',
  payload: label.assignValues({ private: true }),
});

export const labelsActionUnmarkPrivateElement = (label) => ({
  type: 'LABELS_ACTION_UNMARK_PRIVATE_ELEMENT',
  payload: label.assignValues({ private: false }),
});

export const labelsActionLockElement = (label) => ({
  type: 'LABELS_ACTION_LOCK_ELEMENT',
  payload: label,
});

export const labelsActionUnlockElement = (label) => ({
  type: 'LABELS_ACTION_UNLOCK_ELEMENT',
  payload: label,
});

export const labelsActionSetElement = (label) => ({
  type: 'LABELS_ACTION_SET_ELEMENT',
  payload: label,
});
