import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { prepareCollection } from '../../../helpers/model/modelHelpers';
import { wastesActionSet } from '../../actions/wastesActions';
import { wasteFromDto } from '../../../helpers/model/wasteHelpers';

export default function* fetchWastesSaga(action) {
  const { modelName } = action.payload;
  const { ok, data } = yield call(api.wastes.index, modelName);

  if (ok) {
    yield put(wastesActionSet(modelName, prepareCollection(data, wasteFromDto)));
  }
}
