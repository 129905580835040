import { useMemo } from 'react';
import { compact } from 'lodash';
import sortBy from 'lodash/sortBy';
import { nodeVariants } from '../../../constants/nodeVariants';
import { plainToSlate } from '../../../helpers/textConversion/plainToSlate';

const verifiedImage = (imageData) => {
  if (!imageData || !imageData.url) return null;
  return imageData;
};

export const useChunkData = (props) => {
  const { incognitoMode, notesWorkspaces, notes, nodes, currentChunk } = props;

  const workspace = useMemo(() => {
    if (!currentChunk) return undefined;

    const predicate = { id: currentChunk.notesWorkspaceId };
    return notesWorkspaces.find(predicate, true, incognitoMode);
  }, [currentChunk, incognitoMode, notesWorkspaces]);

  const notesPath = useMemo(() => {
    if (!currentChunk) return undefined;

    return compact(currentChunk.notesPath || []).map((noteId) => notes.find({ id: noteId }, true, incognitoMode));
  }, [currentChunk, incognitoMode, notes]);

  const [heading, node] = useMemo(() => {
    if (!currentChunk) return [undefined, undefined];

    const visibleNodes = sortBy(
      nodes.visible(incognitoMode, { nodesGroupId: currentChunk.nodesGroupId }),
      'defaultOrder',
    );
    let _heading = undefined;
    let _node = undefined;
    visibleNodes.forEach((node) => {
      if (node.variant === nodeVariants.heading && !_node) _heading = node;
      else if (node.id === currentChunk.nodeId) _node = node;
    });
    return [_heading, _node];
  }, [currentChunk, incognitoMode, nodes]);

  const [imageAbove, textValue, imageBelow] = useMemo(() => {
    let _imageAbove = undefined,
      _textValue = undefined,
      _imageBelow = undefined;

    if (node && node.variant === nodeVariants.text) {
      _imageAbove = node.additionalContentAbove;
      _textValue = node.content;
      _imageBelow = node.additionalContentBelow;
    } else if (node && node.variant === nodeVariants.image) {
      _imageAbove = node.content;
      const { description } = node.content || {};
      _textValue = description && plainToSlate(description);
    }

    _imageAbove = verifiedImage(_imageAbove);
    _imageBelow = verifiedImage(_imageBelow);
    return [_imageAbove, _textValue, _imageBelow];
  }, [node]);

  return {
    workspace,
    notesPath,
    heading,
    node,
    imageAbove,
    textValue,
    imageBelow,
  };
};
