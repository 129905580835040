const themes = {
  default: {
    // (from the darkest to the brightest)
    // === screen ===
    // #2D2D2D -> section title, tooltip
    // #83817F -> NOT USED
    // #C5C1C0 -> more visible borders, loaders, inactive things, whole page message
    // #E7E6E5 -> less visible borders, code denotation
    // #EFEFF0 -> navbar, side and bottom menus, modal footer, disabled inputs, selector option,
    //            additionalNodeContent
    // #F3F2F2 -> NOT USED (previously used in so-called 'papers')
    // #F8F8F8 -> for the background, modal background, link popover background
    // #FDFDFD -> for containers, buttons and nodes
    // === steel ===
    // #0A1612 -> NOT USED
    // === denim ===
    // #1A2930 -> used instead of black to denote activity as well as for modal related things
    // #C2C6C7 -> button faded, active input borders
    // #CFD2D3 -> selected toolbar icon
    // === marigold ===
    // #E9CA61 -> selected container border
    // #F7CE3E -> active/special things, tooltips
    // #FCF9EC -> selected container background
    // === green ===
    // #33983E -> positive things
    // #9ECCA3 -> positive snackbar message
    // === red ===
    // #CD2626 -> negative things
    // #E49999 -> negative snackbar message
    // === blue ===
    // #0073CF -> for links

    main: {
      color: '#000000',
      backgroundColor: '#F8F8F8',
    },
    loader: {
      color: '#C5C1C0',
    },
    snackbarMessageSuccess: {
      color: '#000000',
      backgroundColor: '#9ECCA3',
    },
    snackbarMessageError: {
      color: '#000000',
      backgroundColor: '#E49999',
    },
    modalOverlay: {
      backgroundColor: '#000000AA',
    },
    modal: {
      borderColor: '#EFEFF0',
    },
    modalHeader: {
      color: '#000000',
      backgroundColor: '#EFEFF0',
      borderColor: '#C5C1C0',
    },
    modalBody: {
      color: '#000000',
      backgroundColor: '#FDFDFD',
    },
    modalBodyDenotation: {
      backgroundColor: '#F7CE3E',
    },
    modalFooter: {
      backgroundColor: '#FDFDFD',
      borderColor: '#E7E6E5',
    },
    fixedMenu: {
      backgroundColor: '#EFEFF0',
      borderColor: '#C5C1C0',
    },
    fixedMenuToggle: {
      backgroundColor: '#EFEFF0',
      borderColor: '#C5C1C0',
      color: '#1A2930',
    },
    standardTemplateTitle: {
      backgroundColor: '#EFEFF0',
      borderColor: '#C5C1C0',
    },
    standardTemplateSide: {
      backgroundColor: '#EFEFF0',
      borderColor: '#C5C1C0',
    },
    standardTemplateBottom: {
      backgroundColor: '#EFEFF0',
      borderColor: '#C5C1C0',
    },
    menuTitle: {
      borderColor: '#C5C1C0',
    },
    sectionTitle: {
      color: '#2D2D2D',
    },
    bigMessage: {
      color: '#C5C1C0',
    },
    separator: {
      borderColor: '#E7E6E5',
    },
    easilyDenotableContainer: {
      color: '#000000',
      backgroundColor: '#FDFDFD',
      borderColor: '#E7E6E5',
    },
    container: {
      color: '#000000',
      backgroundColor: '#FDFDFD',
      borderColor: '#C5C1C0',
    },
    selectedContainer: {
      color: '#000000',
      backgroundColor: '#FCF9EC',
      borderColor: '#E9CA61',
    },
    goodHabitDenotation: {
      color: '#33983E',
    },
    badHabitDenotation: {
      color: '#CD2626',
    },
    actionButton: {
      color: '#1A2930',
      backgroundColor: '#FDFDFD',
      borderColor: '#E7E6E5',
    },
    input: {
      color: '#1A2930',
      backgroundColor: '#FFFFFF',
      borderColor: '#C2C6C7',
    },
    inputError: {
      color: '#CD2626',
      backgroundColor: '#FFFFFF',
      borderColor: '#CD2626',
    },
    inputDisabled: {
      color: '#000000',
      backgroundColor: '#EFEFF0',
      borderColor: '#C5C1C0',
    },
    inputPresentationMode: {
      color: '#000000',
      backgroundColor: '#FFFFFF',
      borderColor: '#C5C1C0',
    },
    transparentInput: {
      color: '#1A2930',
      backgroundColor: '#00000000',
      borderColor: '#00000000',
    },
    transparentInputError: {
      color: '#CD2626',
      backgroundColor: '#00000000',
      borderColor: '#00000000',
    },
    transparentInputDisabled: {
      color: '#C5C1C0',
      backgroundColor: '#00000000',
      borderColor: '#00000000',
    },
    transparentInputPresentationMode: {
      color: '#000000',
      backgroundColor: '#00000000',
      borderColor: '#00000000',
    },
    collectionFieldSeparator: {
      borderColor: '#E7E6E5',
    },
    node: {
      backgroundColor: '#FDFDFD',
      borderColor: '#E7E6E5',
    },
    additionalNodeContent: {
      backgroundColor: '#EFEFF0',
      borderColor: '#EFEFF0',
    },
    nodeToolbar: {
      backgroundColor: '#EFEFF0',
      borderColor: '#C2C6C7',
    },
    nodeToolbarIconSelected: {
      color: '#1A2930',
      backgroundColor: '#CFD2D3',
    },
    textEditorLinkPopover: {
      backgroundColor: '#F8F8F8',
      borderColor: '#C5C1C0',
    },
    headingNodeEditor: {
      borderColor: '#C2C6C7',
    },
    selectorMenu: {
      backgroundColor: '#FDFDFD',
      borderColor: '#C2C6C7',
    },
    selectorOption: {
      color: '#1A2930',
    },
    datePickerCalendar: {
      color: '#1A2930',
      backgroundColor: '#FFFFFF',
      borderColor: '#C5C1C0',
    },
    navbar: {
      color: '#000000',
      backgroundColor: '#EFEFF0',
      borderColor: '#C5C1C0',
    },
    navbarLink: {
      color: '#1A2930',
    },
    navbarLinkSelected: {
      color: '#1A2930',
      borderColor: '#F7CE3E',
    },
    navbarAdditionalMenu: {
      color: '#1A2930',
      backgroundColor: '#EFEFF0',
    },
    chartTitleLabel: {
      color: '#000000',
    },
    chartLabels: {
      color: '#00000077',
    },
    chartGrid: {
      color: '#E7E6E5',
    },
    chartData: {
      borderColor: '#F7CE3E',
      backgroundColor: '#F7CE3E44',
      pointBackgroundColor: '#1A2930',
    },
    code: {
      backgroundColor: '#E7E6E5',
    },
    highlightedText: {
      color: '#33983E',
    },
    highlightedTextCaret: {
      color: '#33983E',
    },
    highlightedTextError: {
      color: '#FFFFFF',
      backgroundColor: '#CD2626',
    },
    highlightedTexErrorCaret: {
      color: '#CD2626',
    },
    button: {
      color: '#1A2930',
    },
    buttonFaded: {
      color: '#C2C6C7',
    },
    hovered: {
      color: '#1A2930',
      backgroundColor: '#0000000A',
    },
    selected: {
      color: '#1A2930',
      backgroundColor: '#F7CE3E',
    },
    selectedHovered: {
      color: '#1A2930',
      backgroundColor: '#F7CE3E44',
    },
    sizeableThingHovered: {
      color: '#1A2930',
      backgroundColor: '#0000000A',
    },
    sizeableThingSelected: {
      color: '#1A2930',
      backgroundColor: '#00000011',
    },
    sizeableThingSelectedHovered: {
      color: '#1A2930',
      backgroundColor: '#0000000D',
    },
    tooltip: {
      backgroundColor: '#2D2D2D',
      color: '#FFFFFF',
    },
    recording: {
      backgroundColor: '#CD2626',
    },
    progressContainer: {
      color: '#000000',
      backgroundColor: '#E7E6E5',
      borderColor: '#C5C1C0',
    },
    progress: {
      backgroundColor: '#F7CE3E66',
    },
    error: {
      color: '#CD2626',
    },
    required: {
      color: '#CD2626',
    },
    delete: {
      color: '#CD2626',
    },
    deleteSurface: {
      color: '#CD262666',
    },
    confirm: {
      color: '#33983E',
    },
    link: {
      color: '#0073CF',
    },
    auxiliary: {
      color: '#00000077',
    },
  },
};

export default themes;
