import { alignmentTypes, alignmentTypesValues, defaultAlignmentType } from '../constants/alignmentTypes';
import { Editor, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

export const getValidAlignmentType = (alignmentType) =>
  alignmentTypesValues.includes(alignmentType) ? alignmentType : defaultAlignmentType;

export const alignmentTypeToTextClass = (alignmentType) => {
  alignmentType = getValidAlignmentType(alignmentType);
  switch (alignmentType) {
    case alignmentTypes.left:
      return 'text-left';
    case alignmentTypes.center:
      return 'text-center';
    case alignmentTypes.right:
      return 'text-right';
    case alignmentTypes.justify:
      return 'text-justify';
  }
};

export const alignmentTypeToFlexJustifyClass = (alignmentType) => {
  alignmentType = getValidAlignmentType(alignmentType);
  switch (alignmentType) {
    case alignmentTypes.left:
      return 'justify-start';
    case alignmentTypes.center:
      return 'justify-center';
    case alignmentTypes.right:
      return 'justify-end';
    case alignmentTypes.justify:
      return 'justify-start';
  }
};

export const alignmentTypeToIconButtonVariant = (alignmentType) => {
  alignmentType = getValidAlignmentType(alignmentType);
  switch (alignmentType) {
    case alignmentTypes.left:
      return 'leftText';
    case alignmentTypes.center:
      return 'centerText';
    case alignmentTypes.right:
      return 'rightText';
    case alignmentTypes.justify:
      return 'justifyText';
  }
};

export const produceToggleAlignmentType = (editor, alignmentType) => (event) => {
  if (event) event.preventDefault();
  alignmentType = getValidAlignmentType(alignmentType);
  Transforms.setNodes(editor, { alignmentType }, { at: editor.selection, match: (n) => Editor.isBlock(editor, n) });
  ReactEditor.focus(editor);
};

export const getActiveAlignmentTypes = (editor) => {
  const result = new Set();

  for (let [node] of Editor.nodes(editor, {
    at: editor.selection,
    mode: 'highest',
    match: (n) => Editor.isBlock(editor, n),
  })) {
    result.add(getValidAlignmentType(node.alignmentType));
  }

  return result;
};
