import React from 'react';
import ThemeCrumb from '../../../../ThemeCrumb';

const ColorMenu = (props) => {
  const { themeType = 'selectorMenu', options } = props;

  return (
    <ThemeCrumb type={themeType}>
      <div className="grid grid-cols-5 gap-1 p-1 border rounded-md">
        {options.map((option) => React.cloneElement(option, props))}
      </div>
    </ThemeCrumb>
  );
};

export default ColorMenu;
