import frenchkiss from 'frenchkiss';

frenchkiss.extend('en', {
  pages: {
    error: {
      404: {
        message: 'Page not found',
      },
    },
  },
});
