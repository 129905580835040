import React, { useRef } from 'react';
import { getVisibleTodoTasks } from '../../../helpers/taskHelpers';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { categoriesActionFetch } from '../../../stateManagement/actions/categoriesActions';
import { tasksActionFetch } from '../../../stateManagement/actions/tasksActions';
import List from '../../../shared/lists/List';
import { draggedElements } from '../../../constants/draggedElements';
import TodoIcon from '../../../icons/todoIcon';
import TaskPanel from '../../../shared/features/taskPanel';
import { activeElementTypes } from '../../../constants/activeElementTypes';

const TodoList = (props) => {
  const { categories, tasks, incognitoMode } = props;

  const draggableListRef = useRef(null);

  const todoTasks = getVisibleTodoTasks(categories, tasks, incognitoMode);

  return (
    <List
      collection={todoTasks}
      draggable={{
        ref: draggableListRef,
        draggedElementType: draggedElements.todoTask,
      }}
    >
      {({ task, color }) => (
        <div key={task.id} className="mt-2 first:mt-0 flex justify-center px-25px">
          <TaskPanel
            task={task}
            color={color}
            disabled
            activeElementType={activeElementTypes.doneTask}
            widthClass="w-full"
            activeWidthClass="w-full"
            bulletIcon={<TodoIcon className="w-3 h-3" />}
          />
        </div>
      )}
    </List>
  );
};

export default connect(prepareState(['categories', 'tasks', 'incognitoMode']), {
  categoriesActionFetch,
  tasksActionFetch,
})(TodoList);
