import React from 'react';
import StandardContentTemplate from '../../../shared/contentTemplates/StandardContentTemplate';
import Left from '../index/Left';
import Main from './Main';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { notesWorkspacesActionFetch } from '../../../stateManagement/actions/notesWorkspacesActions';
import { notesActionFetch } from '../../../stateManagement/actions/notesActions';
import { useWorkspace } from '../shared/useWorkspace';
import Top from './Top';

const NotesConsolidatedPdfPage = (props) => {
  const { incognitoMode, notesWorkspaces, notes, config, notesWorkspacesActionFetch, notesActionFetch } = props;

  const [workspaceSelected, workspace] = useWorkspace({
    notesWorkspaces,
    incognitoMode,
    config,
    notes,
    notesActionFetch,
    notesWorkspacesActionFetch,
  });

  return (
    <StandardContentTemplate
      top={<Top />}
      leftReady={notesWorkspaces.fetched && workspaceSelected && notes.fetched(workspace.id)}
      left={<Left workspace={workspace} />}
      mainReady={notesWorkspaces.fetched && workspaceSelected && notes.fetched(workspace.id)}
      main={<Main workspace={workspace} />}
    />
  );
};
export default connect(prepareState(['incognitoMode', 'notesWorkspaces', 'notes', 'config']), {
  notesWorkspacesActionFetch,
  notesActionFetch,
})(NotesConsolidatedPdfPage);
