import React from 'react';
import * as yup from 'yup';
import { frontEndErrorT } from '../../../helpers/i18nHelpers';
import { withFormik } from 'formik';
import SmartForm from '../../../shared/form/SmartForm';
import SmartField from '../../../shared/form/SmartField';
import TextField from '../../../shared/form/fieldTypes/TextField';
import H1 from '../../../shared/titles/H1';
import OnClickOutsideWrapper from '../../onClickOutside/OnClickOutsideWrapper';

const InnerForm = (props) => {
  const { handleSubmit, wrappingComponent = H1, heightClass = 'h-40px', fontWeightClass = 'font-bold' } = props;
  return (
    <OnClickOutsideWrapper onClickOutside={handleSubmit}>
      <SmartForm className={`flex justify-center items-center ${heightClass}`}>
        {React.createElement(
          wrappingComponent,
          {
            className: 'flex justify-center',
          },
          <SmartField
            name="title"
            themeType="transparentInput"
            component={TextField}
            className={fontWeightClass}
            autoFocus
            displayErrorAsPlaceholder
            inputClassName={fontWeightClass}
          />,
        )}
      </SmartForm>
    </OnClickOutsideWrapper>
  );
};

export const TitleEditor = withFormik({
  validationSchema: () =>
    yup.object().shape({
      title: yup.string().required(frontEndErrorT('shared.title.empty')),
    }),
  enableReinitialize: true,
  mapPropsToValues: ({ title }) => ({ title }),
  handleSubmit: (values, formikBag) => formikBag.props.handleSubmit(values, formikBag),
})(InnerForm);
