import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { prepareCollection } from '../../../helpers/model/modelHelpers';
import { labelsActionSet } from '../../actions/labelsActions';
import { labelFromDto } from '../../../helpers/model/labelHelpers';

export default function* fetchLabelsSaga(action) {
  const { ok, data } = yield call(api.labels.index, action.payload);
  if (ok) {
    yield put(labelsActionSet(prepareCollection(data, labelFromDto)));
  }
}
