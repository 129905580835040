import React from 'react';
import ThemeCrumb from '../../ThemeCrumb';
import ErrorWrapper from './auxiliary/ErrorWrapper';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { getThemeTypeDefinition } from '../../../helpers/themeHelpers';
import { getThemeType } from '../../../helpers/formHelpers';
import { get } from 'lodash';

const defaultOnChange = async (props) => {
  const {
    event,
    field: { name },
    form: { setFieldValue, setFieldTouched },
  } = props;
  await setFieldValue(name, event.target.value);
  await setFieldTouched(name);
};

const TextAreaField = (props) => {
  const {
    id,
    placeholder,
    autoFocus,
    innerRef,
    themeType = 'input',
    textAlignClass = 'text-center',
    displayErrorAsPlaceholder = false,
    displayError,
    fixedErrorHeight,
    handleErrorThemeType,
    handleDisabledThemeType,
    handlePresentationModeThemeType,
    theme,
    disabled = false,
    presentationMode = false,
    onChange = (onChangeProps, _defaultOnChange) => _defaultOnChange(onChangeProps),
    onBlur = () => {},
    field,
    field: { name },
    form: { errors, touched, setFieldTouched },
  } = props;

  const error = get(touched, name) ? get(errors, name) : null;
  const _displayError = displayErrorAsPlaceholder ? false : displayError;
  const _placeholder = displayErrorAsPlaceholder && error ? error : placeholder;
  const actualThemeType = getThemeType({
    themeType,
    error,
    handleErrorThemeType,
    presentationMode,
    handlePresentationModeThemeType,
    disabled,
    handleDisabledThemeType,
  });
  const borderColor = getThemeTypeDefinition(theme, actualThemeType).borderColor;
  return (
    <ErrorWrapper displayError={_displayError} fixedErrorHeight={fixedErrorHeight} error={error}>
      <div className="flex border p-1 rounded" style={{ borderColor }}>
        <ThemeCrumb type={actualThemeType}>
          <textarea
            id={id}
            className={`focus:outline-none w-full ${textAlignClass}
                                          placeholder-custom`}
            autoFocus={autoFocus}
            placeholder={_placeholder}
            ref={innerRef}
            disabled={disabled || presentationMode}
            {...field}
            onChange={(event) => onChange({ ...props, event }, defaultOnChange)}
            onBlur={async () => {
              await setFieldTouched(name);
              await onBlur();
            }}
          />
        </ThemeCrumb>
      </div>
    </ErrorWrapper>
  );
};

export default connect(prepareState('theme'))(TextAreaField);
