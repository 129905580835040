import React, { useRef, useState } from 'react';
import { getNavbarHeight, getStandardTemplateTopHeight } from '../../../helpers/elementsSizeHelpers';
import useResizeEffect from '../../../hooks/useResizeEffect';

const GoldenRatioMarginTopWrapper = ({ children }) => {
  const [marginTopPx, setMarginTopPx] = useState(0);
  const ref = useRef(null);

  useResizeEffect(
    () => {
      let _marginTopPx = window.innerHeight;
      _marginTopPx -= getNavbarHeight();
      _marginTopPx -= getStandardTemplateTopHeight();
      _marginTopPx -= ref.current.clientHeight;
      _marginTopPx *= 0.382;
      setMarginTopPx(Math.round(_marginTopPx));
    },
    [],
    true,
  );

  return (
    <div
      ref={ref}
      className={`${marginTopPx ? '' : 'invisible'} flex justify-center transition-opacity`}
      style={{ marginTop: `${marginTopPx}px` }}
    >
      <div>{children}</div>
    </div>
  );
};

export default GoldenRatioMarginTopWrapper;
