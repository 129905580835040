import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { getNotesTreeHash, getSortedNotesIds } from '../../../helpers/notesHelpers';
import useNarrowMainWidth from '../../../hooks/useNarrowMainWidth';
import MainTopSpace from '../../../shared/organizers/MainTopSpace';
import SectionTitle from '../../../shared/titles/SectionTitle';
import { frontEndErrorT, initT } from '../../../helpers/i18nHelpers';
import LabelledButton from '../../../shared/buttons/LabelledButton';
import CheckboxField from '../../../shared/form/fieldTypes/CheckBoxField';
import Container from '../../../shared/organizers/containers/Container';
import PdfGenerator from './PdfGenerator';
import Loader from '../../../shared/loaders/Loader';
import { snackbarMessagesActionAddDefaultError } from '../../../stateManagement/actions/snackbarMessagesActions';
import { withCustomReinitialize } from '../../../helpers/formHelpers';
import { withFormik } from 'formik';
import SmartForm from '../../../shared/form/SmartForm';
import SmartField from '../../../shared/form/SmartField';
import LabelField from '../../../shared/form/LabelField';
import TextField from '../../../shared/form/fieldTypes/TextField';
import * as yup from 'yup';

const t = initT('pages.notes.consolidatedPdfForm');

export const validationSchema = () =>
  yup.object().shape({
    title: yup.string().required(frontEndErrorT('shared.title.empty')),
  });

const Option = (props) => {
  const {
    note: { id, name },
    values,
    nestingLevel,
  } = props;

  const selected = values.selectedNotes[id];
  let paddingClass = '';
  if (nestingLevel === 1) paddingClass = 'pl-6';
  else if (nestingLevel === 2) paddingClass = 'pl-12';

  return (
    <div className={`${selected ? '' : 'opacity-50'} pt-2 first:pt-0 pb-2 last:pb-0`}>
      <div className={`w-full ${paddingClass}`}>
        <SmartField name={`selectedNotes.${id}`} component={CheckboxField} label={name} />
      </div>
    </div>
  );
};

const PdfOptionsInnerForm = (props) => {
  const {
    snackbarMessagesActionAddDefaultError: displayError,
    values,
    values: {
      title,
      showTitle,
      increasedSideMargins,
      selectedNotes,
      auxiliary: { notesTreeHash, sortedNotesIds, pdfGenerating },
    },
    handleSubmit,
    setFieldValue,
  } = props;

  const width = useNarrowMainWidth();

  const onReady = useCallback(() => {
    setFieldValue('auxiliary.pdfGenerating', true);
  });
  const onError = useCallback(() => {
    setFieldValue('auxiliary.pdfGenerating', false);
    displayError();
  }, []);

  const selectedNotesValues = sortedNotesIds
    .filter((id) => selectedNotes[id])
    .map((id) => notesTreeHash[parseInt(id)].note);

  return (
    <>
      <MainTopSpace />
      {pdfGenerating ? (
        <Loader />
      ) : (
        <SmartForm className="w-full flex justify-center pb-25px">
          <div style={{ width }}>
            <SectionTitle>{t('details')}</SectionTitle>
            <div className="w-full flex justify-center pb-25px">
              <div className="w-big-golden-ratio">
                <Container className="p-25px">
                  <LabelField name="title" label={t('title')} required component={TextField} />
                  <SmartField name="showTitle" className="pt-25px" component={CheckboxField} label={t('showTitle')} />
                  <SmartField
                    name="increasedSideMargins"
                    className="pt-2"
                    component={CheckboxField}
                    label={t('increasedSideMargins')}
                  />
                </Container>
              </div>
            </div>
            <SectionTitle>{t('selectNotes')}</SectionTitle>
            <div className="w-full flex justify-center">
              <div className="w-big-golden-ratio">
                <Container className="p-25px">
                  {sortedNotesIds.map((noteId) => (
                    <Option key={noteId} values={values} {...notesTreeHash[noteId]} />
                  ))}
                </Container>
              </div>
            </div>
            <div className="w-full flex justify-center pt-25px">
              <LabelledButton variant="confirm" disabled={selectedNotesValues.length === 0} onMouseDown={handleSubmit}>
                {t('submit')}
              </LabelledButton>
            </div>
          </div>
        </SmartForm>
      )}
      {pdfGenerating ? (
        <PdfGenerator
          selectedNotes={selectedNotesValues}
          title={title}
          showTitle={showTitle}
          increasedSideMargins={increasedSideMargins}
          onReady={onReady}
          onError={onError}
        />
      ) : (
        <></>
      )}
    </>
  );
};

const PdfOptionsForm = withCustomReinitialize(['workspace', 'incognitoMode', 'notes'])(
  withFormik({
    validationSchema,
    mapPropsToValues: (props) => {
      const { workspace, incognitoMode, notes } = props;

      const notesTreeHash = getNotesTreeHash(notes, workspace.id, incognitoMode);
      const sortedNotesIds = getSortedNotesIds(notesTreeHash);

      const selectedNotes = {};
      sortedNotesIds.forEach((id) => {
        selectedNotes[id] = false;
      });

      return {
        title: workspace.name,
        showTitle: true,
        increasedSideMargins: false,
        selectedNotes,
        auxiliary: {
          notesTreeHash,
          sortedNotesIds,
        },
      };
    },
    handleSubmit: (values, formikBag) => {
      formikBag.setFieldValue('auxiliary.pdfGenerating', true);
    },
  })(PdfOptionsInnerForm),
);

export default connect(prepareState(['notes', 'incognitoMode']), {
  snackbarMessagesActionAddDefaultError,
})(PdfOptionsForm);
