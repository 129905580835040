import React from 'react';
import IconButton from '../../../../buttons/IconButton';
import CustomColorCrumb from '../../../../CustomColorCrumb';

const ColorTrigger = (props) => {
  const { toggleMenu, menuOpen, value } = props;
  return (
    <CustomColorCrumb styleKeys={['color']} color={value}>
      <IconButton variant="color" onMouseDown={toggleMenu} tooltip={menuOpen ? false : undefined} />
    </CustomColorCrumb>
  );
};

export default ColorTrigger;
