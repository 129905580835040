import React from 'react';
import { connect } from 'react-redux';
import { quickPassActionAutoActivate } from './stateManagement/actions/quickPassActions';
import AppRouter from './AppRouter';
import { useIdleTimer } from 'react-idle-timer';
import quickPass from './constants/quickPass';
import { setCookieLastActivityDate } from './helpers/lastActivityDateHelpers';

// to ensure that:  moment() > last_activity_date from cookie + quickPass.idleTimeToActivate
const idleTimeoutThrottle = 1000 * 10;

const AppAuthenticated = ({ quickPassActionAutoActivate, browserHistory }) => {
  useIdleTimer({
    timeout: quickPass.idleTimeToActivate + idleTimeoutThrottle,
    onIdle: quickPassActionAutoActivate,
    onAction: setCookieLastActivityDate,
  });

  return <AppRouter browserHistory={browserHistory} />;
};

export default connect(null, { quickPassActionAutoActivate })(AppAuthenticated);
