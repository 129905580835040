import { pastDayInBackendFormat } from '../../helpers/dateTimeHelpers';
import Cookies from 'js-cookie';
import { DateTime } from 'luxon';

const CALENDAR_LOCKS_COOKIE_IDENTIFIER = 'calendar_locks';

const invariablyUnlockedPastDaysCount = 2; // if 0, only today is unlocked
const unlockDurationInHours = 48;

const sanitizedCalendarLocksCookieValue = () => {
  let sanitized = {};
  try {
    const previousTerminalDates = JSON.parse(Cookies.get(CALENDAR_LOCKS_COOKIE_IDENTIFIER));
    Object.keys(previousTerminalDates).forEach((dateFromCookie) => {
      const terminalDate = DateTime.fromISO(previousTerminalDates[dateFromCookie]);
      if (!terminalDate.invalid && terminalDate > DateTime.now()) {
        sanitized = {
          ...sanitized,
          [dateFromCookie]: terminalDate,
        };
      }
    });
    // eslint-disable-next-line no-empty
  } catch {}
  return sanitized;
};

export const unlock = (date) => {
  Cookies.set(CALENDAR_LOCKS_COOKIE_IDENTIFIER, {
    ...sanitizedCalendarLocksCookieValue(),
    [date]: DateTime.now().plus({ hours: unlockDurationInHours }).toISO(),
  });
};

export const isLocked = (date) => {
  for (let i = 0; i <= invariablyUnlockedPastDaysCount; i++) {
    if (date === pastDayInBackendFormat(i)) {
      unlock(date);
      return false;
    }
  }

  let result = true;
  try {
    const terminalDate = DateTime.fromISO(JSON.parse(Cookies.get(CALENDAR_LOCKS_COOKIE_IDENTIFIER))[date]);
    if (!terminalDate.invalid && DateTime.fromISO(terminalDate) > DateTime.now()) {
      result = false;
      // prolong the time this date is going to be unlocked
      unlock(date);
    }
    // eslint-disable-next-line no-empty
  } catch {}

  return result;
};
