import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AdditionalImagePresenter from './AdditionalImagePresenter';
import TextEditor from './textEditor';
import { getUuid } from '../../../../helpers/identifierHelpers';
import { isAndroid } from '../../../../helpers/platformHelpers';
import TextTextareaEditor from './textTextareaEditor';
import ImageEditor from '../shared/ImageEditor';
import { isNumber } from 'lodash';
import { runIfDraggingNotEnabled } from '../../../../helpers/draggingHelpers';

const parts = {
  top: 0,
  main: 1,
  bottom: 2,
};

// hack to avoid keeping partEdited state in NodePanel - NodeText component sometimes gets unmounted
let initialValuesForPartEdited = {};

const NodeText = (props) => {
  const { editionAllowed, editionMode, enableEditionMode, disableEditionMode, node, update, dragged, onModal } = props;

  const ref = useRef(null);
  const eventTargetWithinWrapper = useCallback((e) => !!ref.current && ref.current.contains(e.target), []);

  const [tempNode, setTempNode] = useState(node);
  useEffect(() => {
    setTempNode(node);
  }, [node]);

  const noEdition = !editionAllowed || !editionMode || dragged;
  const partToEdit = useMemo(() => {
    const newValue = initialValuesForPartEdited[tempNode.id];
    return isNumber(newValue) ? newValue : parts.main;
  }, [tempNode.id, editionMode]);
  const key = useMemo(getUuid, [tempNode.locked]);

  // destructor
  useEffect(
    () => () => {
      initialValuesForPartEdited[tempNode.id] = parts.main;
    },
    [],
  );

  const onClickOutsidePart = (event, updateAttributesCb) => {
    const newNode = tempNode.shallowClone();
    updateAttributesCb(newNode);

    if (eventTargetWithinWrapper(event)) {
      setTempNode(newNode);
    } else {
      update(newNode);
    }
    disableEditionMode();
  };

  const contentAbove = tempNode.additionalContentAbove || {};
  const contentBelow = tempNode.additionalContentBelow || {};

  const contentAbovePresent = !!contentAbove.url;
  const contentBelowPresent = !!contentBelow.url;

  let top;
  let main;
  let bottom;

  if (!noEdition && partToEdit === parts.top)
    top = (
      <ImageEditor
        useDescription={false}
        url={contentAbove.url}
        size={contentAbove.size}
        handleSubmit={({ url, size, event }) => {
          onClickOutsidePart(event, (newNode) => {
            newNode.additionalContentAbove = { url, size };
          });
        }}
        roundedClass="rounded-t"
        onCancel={() => {
          disableEditionMode();
          tempNode.additionalContentAbove = null;
          update(tempNode);
        }}
        onModal={onModal}
      />
    );
  else
    top =
      editionAllowed || contentAbovePresent ? (
        <div className={noEdition ? '' : 'opacity-30'}>
          <AdditionalImagePresenter
            clickable={editionAllowed && !dragged}
            onMouseDown={() => {
              initialValuesForPartEdited[tempNode.id] = parts.top;
              runIfDraggingNotEnabled(enableEditionMode);
            }}
            url={contentAbove.url}
            size={contentAbove.size}
            themeType={editionAllowed ? 'input' : 'additionalNodeContent'}
            borderClass="border-x border-t"
            roundedClass={editionAllowed ? '' : 'rounded-t'}
          />
        </div>
      ) : (
        <></>
      );

  if (!noEdition && partToEdit === parts.main) {
    if (isAndroid())
      main = (
        <TextTextareaEditor
          key={key}
          initialValue={tempNode.content}
          onClickOutside={(event, newValue) => {
            onClickOutsidePart(event, (newNode) => {
              newNode.content = newValue;
            });
          }}
          roundedClass={''}
          onModal={onModal}
        />
      );
    else
      main = (
        <TextEditor
          {...props}
          key={key}
          autoFocus
          onBlur={(event, newValue) => {
            onClickOutsidePart(event, (newNode) => {
              newNode.content = newValue;
            });
          }}
          initialValue={tempNode.content}
          themeType={editionAllowed ? 'input' : 'node'}
          roundedClass={''}
          onModal={onModal}
        />
      );
  } else
    main = (
      <div className={noEdition ? '' : 'opacity-30'}>
        <TextEditor
          {...props}
          key={key}
          readOnly
          clickable={editionAllowed && !dragged}
          onMouseDown={() => {
            initialValuesForPartEdited[tempNode.id] = parts.main;
            // TextEditor does not make use of Clickable under the hood
            // Hence, setTimeout needs to be provided manually
            setTimeout(() => runIfDraggingNotEnabled(enableEditionMode));
          }}
          initialValue={tempNode.content}
          themeType={editionAllowed ? 'input' : 'node'}
          checklistItemClickable={!editionAllowed}
          onChecklistItemClicked={(newValue) => {
            node.content = newValue;
            update(node);
          }}
          roundedClass={`
                                ${contentAbovePresent || editionAllowed ? '' : 'rounded-t'}
                                ${contentBelowPresent ? '' : 'rounded-b'}
                            `}
          onModal={onModal}
        />
      </div>
    );

  if (!noEdition && partToEdit === parts.bottom)
    bottom = (
      <ImageEditor
        useDescription={false}
        url={contentBelow.url}
        size={contentBelow.size}
        handleSubmit={({ url, size, event }) => {
          onClickOutsidePart(event, (newNode) => {
            newNode.additionalContentBelow = { url, size };
          });
        }}
        roundedClass="rounded-b"
        onCancel={() => {
          disableEditionMode();
          tempNode.additionalContentBelow = null;
          update(tempNode);
        }}
        onModal={onModal}
      />
    );
  else
    bottom =
      editionAllowed || contentBelowPresent ? (
        <div className={noEdition ? '' : 'opacity-30'}>
          <AdditionalImagePresenter
            clickable={editionAllowed && !dragged}
            onMouseDown={() => {
              initialValuesForPartEdited[tempNode.id] = parts.bottom;
              runIfDraggingNotEnabled(enableEditionMode);
            }}
            url={contentBelow.url}
            size={contentBelow.size}
            themeType={editionAllowed ? 'input' : 'additionalNodeContent'}
            borderClass="border-x border-b"
            roundedClass="rounded-b"
          />
        </div>
      ) : (
        <></>
      );

  return (
    <div ref={ref}>
      {top}
      {main}
      {bottom}
    </div>
  );
};

export default NodeText;
