import frenchkiss from 'frenchkiss';

frenchkiss.extend('en', {
  modals: {
    habitSummaryRequestModal: {
      title: 'New summary to show',
      labels: {
        aggregationMethodFollowUp: 'of values accumulated:',
        sinceLast: 'since last',
        duringLast: 'during last',
        days: 'day(s)',
        monthly: 'this month',
        annually: 'this year',
      },
      submit: 'Submit',
    },
  },
});
