import React, { useState } from 'react';
import SmartField from './SmartField';
import { generateTagId } from '../../helpers/identifierHelpers';
import Label from './Label';

const LabelField = (props) => {
  const {
    id = generateTagId(),
    label,
    className = '',
    labelClass = '',
    smartFieldClass = '',
    hidden = false,
    hint = false,
    ...smartFieldProps
  } = props;
  const { required, presentationMode } = smartFieldProps;

  const [memorisedId] = useState(id);
  if (hidden) return <></>;
  return (
    <div className={`max-w-fit-content ${className}`}>
      <Label className={labelClass} htmlFor={memorisedId} required={required && !presentationMode} hint={hint}>
        {label}
      </Label>
      <SmartField id={memorisedId} className={smartFieldClass} {...smartFieldProps} />
    </div>
  );
};

export default LabelField;
