import React from 'react';
import get from 'lodash/get';
import flatten from 'lodash/flatten';
import join from 'lodash/join';
import range from 'lodash/range';
import { enumT } from './i18nHelpers';
import { monthTypes } from '../constants/enums/monthTypes';

export const withCustomReinitialize = (relevantPropsKeys) => (FormComponent) => (props) => {
  const key = join(
    flatten([relevantPropsKeys]).map((propKey) => JSON.stringify(get(props, propKey))),
    '-',
  );
  return <FormComponent {...props} key={key} />;
};

export const passDefaultAttributesToChildren = (children, defaultChildrenAttributes) => {
  if (!defaultChildrenAttributes) return children;
  return React.Children.map(children, (child) => {
    if (!child) {
      return null;
    }
    const newAttributes = {};
    const keys = Object.keys(child.props);
    Object.keys(defaultChildrenAttributes).forEach((attributeKey) => {
      if (!keys.includes(attributeKey)) {
        newAttributes[attributeKey] = defaultChildrenAttributes[attributeKey];
      }
    });
    newAttributes['defaultChildrenAttributes'] = {
      ...defaultChildrenAttributes,
      ...child.props['defaultChildrenAttributes'],
    };
    return React.cloneElement(child, newAttributes);
  });
};

export const produceShouldUpdate =
  (additionalRelevantFields = [], additionalRelevantAttributes = []) =>
  (newProps, prevProps) => {
    if (newProps.formik.isSubmitting !== prevProps.formik.isSubmitting) return true;

    const relevantAttributes = [
      'name',
      'disabled',
      'presentationMode',
      'error',
      'hidden',
      'invisible',
      'options',
      ...additionalRelevantAttributes,
    ];
    const relevantAttributeChanged = relevantAttributes.some(
      (attribute) => newProps[attribute] !== prevProps[attribute],
    );
    if (relevantAttributeChanged) return true;

    const relevantCollections = ['values', 'errors', 'touched'];
    const relevantFields = [newProps.name, ...additionalRelevantFields];

    return relevantCollections.some((collection) =>
      relevantFields.some((field) => {
        const prevValue = get(prevProps.formik[collection], field);
        const newValue = get(newProps.formik[collection], field);
        return prevValue !== newValue;
      }),
    );
  };

export const defaultShouldUpdate = produceShouldUpdate();

export const produceValuesFilter = (relevantAttributes) => (values) => {
  const result = {};
  relevantAttributes.forEach((attributeName) => {
    if (typeof attributeName === 'object') {
      Object.keys(attributeName).forEach((arrayKey) => {
        const elementAttributes = attributeName[arrayKey];
        let value = values[arrayKey];
        if (value === null || value === undefined) value = [];
        value = value.map((element) => produceValuesFilter(elementAttributes)(element));
        result[arrayKey] = value;
      });
      return;
    }
    let value = values[attributeName];
    if (value === null || value === undefined) value = '';
    result[attributeName] = value;
  });
  return result;
};

export const customPropsToValues = (props, filterValues, auxiliaryAttributes = []) => {
  const auxiliary = {};
  auxiliaryAttributes.forEach((attributeName) => {
    let value = props[attributeName];
    if (value === null || value === undefined) value = '';
    auxiliary[attributeName] = value;
  });
  return { ...filterValues(props), auxiliary };
};

export const enumToSelectorValues = (enumDefinition, translationKey) => {
  return Object.keys(enumDefinition).map((enumKey) => ({
    value: enumDefinition[enumKey],
    label: enumT(`${translationKey}.${enumKey}`),
  }));
};

export const selectorValuesMap =
  (labelKey, valueKey = 'id') =>
  (element) => ({
    value: element[valueKey],
    label: element[labelKey],
  });

export const allowedMonthDaysCount = (month) => {
  let monthIndex;
  Object.entries(monthTypes).forEach(({ 0: monthName }, index) => {
    if (monthName === month) monthIndex = index + 1;
  });
  const intercalaryYear = 1996;
  return new Date(intercalaryYear, monthIndex, 0).getDate();
};

export const monthDaySelectorValues = (month = 'january', includeLast = true) => {
  const appendix = includeLast ? [-1] : [];
  return [...range(1, allowedMonthDaysCount(month) + 1), ...appendix].map((value) => ({
    value: value,
    label: enumT(`monthDays.${value}`),
  }));
};

export const getThemeType = (props) => {
  const {
    themeType,
    error,
    handleErrorThemeType,
    disabled,
    handleDisabledThemeType,
    presentationMode,
    handlePresentationModeThemeType,
  } = props;

  if (error && handleErrorThemeType) return `${themeType}Error`;
  else if (presentationMode && handlePresentationModeThemeType) return `${themeType}PresentationMode`;
  else if (disabled && handleDisabledThemeType) return `${themeType}Disabled`;
  return themeType;
};

export const isFormValid = (errors) => Object.keys(errors || {}).length === 0;

export const isFormTouched = (touched) => Object.keys(touched || {}).length > 0;
