import filter from 'lodash/filter';
import some from 'lodash/some';

const initialState = () => [];

let lastPathname;

export default function (state = initialState(), action) {
  switch (action.type) {
    case 'ACTIVE_ELEMENTS_ACTION_ACTIVATE_IF_OTHER_INACTIVE':
      if (some(state, { type: action.payload.type })) return state;
      else return [...filter(state, (e) => e.type !== action.payload.type), action.payload];
    case 'ACTIVE_ELEMENTS_ACTION_ACTIVATE':
      return [...filter(state, (e) => e.type !== action.payload.type), action.payload];
    case 'ACTIVE_ELEMENTS_ACTION_DEACTIVATE': {
      const type = action.payload.type;
      const identifier = action.payload.identifier;
      return filter(state, (e) => e.type !== type || e.identifier !== identifier);
    }
    case 'ACTIVE_ELEMENTS_ACTION_DEACTIVATE_ALL_OF_TYPE': {
      const type = action.payload;
      return filter(state, (e) => e.type !== type);
    }
    case '@@router/LOCATION_CHANGE': {
      if (action.payload.location.pathname !== lastPathname) {
        lastPathname = action.payload.location.pathname;
        return initialState();
      } else return state;
    }
    case 'GLOBAL_ACTION_RESET':
      return initialState();
    default:
      return state;
  }
}
