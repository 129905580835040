import DraggedElement from '../../models/auxiliary/draggedElement';

export const draggedElementActionDrop = (type, draggedIdentifier, successorIdentifier, additionalData = {}) => ({
  type: 'DRAGGED_ELEMENT_ACTION_DROP',
  payload: {
    draggedElement: new DraggedElement(type, draggedIdentifier),
    successorElement: new DraggedElement(type, successorIdentifier),
    additionalData,
  },
});
