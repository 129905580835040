import { api } from '../../../helpers/apiHelpers';
import { uniq, isNumber } from 'lodash';

const updateLearningChunk = ({ currentChunk, finalWpm, currentChunk: { bestWpm } }) => {
  if (isNumber(bestWpm) && isNumber(finalWpm) && finalWpm > bestWpm) currentChunk.bestWpm = finalWpm;
  currentChunk.nodeLearningCount++;
};

export const conditionalLearn = async (props) => {
  const {
    queue,
    currentChunk,
    finalWpm,
    learningProgress,
    alreadyLearnedChunks,
    setAlreadyLearnedChunks,
    updateGlobalStateLastLearnedNodeId,
    backendReset,
    shouldResetQueueAfterwards = false,
  } = props;
  const { nodeId } = currentChunk || {};

  if (isNumber(learningProgress)) {
    const { ok } = await api.learning.learn(queue, nodeId, learningProgress, finalWpm);
    if (!ok) return;

    updateLearningChunk(props);
    setAlreadyLearnedChunks(uniq([...alreadyLearnedChunks, nodeId]));

    if (shouldResetQueueAfterwards) await backendReset();
    else updateGlobalStateLastLearnedNodeId(nodeId);
  }
};
