import frenchkiss from 'frenchkiss';

frenchkiss.extend('en', {
  pages: {
    learning: {
      changeWorkspace: 'Change workspace',
      createQueue: 'Create learning queue',
      editQueue: 'Edit queue settings',
      enableWithAudio: 'Enable audio',
      disableWithAudio: 'Disable audio',
      queueFields: {
        name: 'Name',
        variant: 'Variant',
        withAudio: 'With audio',
        withRepetitionDelay: 'With a delay for repetition',
        sourceVariant: 'Source variant',
        sourceLabelId: "Source notes' label",
        sourceNotesWorkspaceId: "Source notes' workspace",
        auxiliaryNotesWorkspaceId: "Source note's workspace",
        sourceNoteId: 'Source note',
        considerNestedNotes: 'Include nested notes',
        feedOrderVariant: 'Feed ordering',
      },
      queuePresenter: {
        run: 'Run queue!',
        variant: 'Variant',
        source: 'Source',
        feedOrderVariant: 'Feed ordering',
        withAudio: '(with audio)',
        withRepetitionDelay: '(with a delay for repetition)',
      },
      queueRun: {
        exitRunMode: 'Exit run mode',
        enableWithAudio: 'Enable audio',
        disableWithAudio: 'Disable audio',
        restart: 'Restart',
        empty: 'Queue is empty',
        ended: 'Queue has ended',
        pause: 'Pause queue',
        resume: 'Resume queue',
        back: 'Back',
        next: 'Next',
        nextTimeout: 'Proceeding to the next element...',
        repetitionTime: "Repeat what you've just heard",
        chunksLimit: 'Number of elements to include in the pdf (max: {max})',
        generatePdf: 'Generate PDF',
        pressAnyKeyToContinue: 'PRESS ANY KEY TO CONTINUE',
        chunkStats: {
          title: 'Current element stats',
          learnedCount: 'Learned',
          times: 'time(s)',
          currentWpm: 'Current WPM',
          maxWpm: 'Max. WPM',
          status: 'Learning status',
          modifyStatus: 'Modify status',
          progressMarkPositive: 'I know this one well',
          progressMarkNegative: 'Revision required',
        },
      },
    },
  },
});
