import React from 'react';

export const RightTextIcon = ({ className = '', style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className={`fill-current ${className}`}
    style={style}
  >
    <path
      d="M0 1h24v2h-24v-2zm12 7h12v-2h-12v2zm-12 5h24v-2h-24v2zm12 5h12v-2h-12v2zm-12
                 5h24v-2h-24v2z"
    />
  </svg>
);
export default RightTextIcon;
