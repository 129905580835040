import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { prepareCollection } from '../../../helpers/model/modelHelpers';
import { nodesActionSet } from '../../actions/nodesActions';
import { nodeFromDto } from '../../../helpers/model/nodeHelpers';

export default function* fetchNodesSaga(action) {
  const { nodesGroupId } = action.payload;
  const { ok, data } = yield call(api.nodes.index, nodesGroupId);
  if (ok) {
    yield put(nodesActionSet(nodesGroupId, prepareCollection(data, nodeFromDto)));
  }
}
