import { blockVariants } from '../constants/blockVariants';
import { Editor, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { maxNestingLevel, minNestingLevel } from '../constants/nestingLevels';
import { propsWhitelists } from '../constants/propsWhitelists';

export const isNestableBlockVariant = (blockVariant) => propsWhitelists[blockVariant].includes('nestingLevel');

export const getActiveNestingLevels = (editor) => {
  const result = new Set();

  for (let [node] of Editor.nodes(editor, {
    at: editor.selection,
    mode: 'highest',
    match: (n) => Editor.isBlock(editor, n),
  })) {
    if (!isNestableBlockVariant(node.type)) return new Set();
    result.add(node.nestingLevel);
  }

  return result;
};

export const canDecreaseNestingLevel = (editor) => {
  for (let nestingLevel of getActiveNestingLevels(editor).values()) {
    if (nestingLevel > minNestingLevel) return true;
  }
  return false;
};

export const canIncreaseNestingLevel = (editor) => {
  for (let nestingLevel of getActiveNestingLevels(editor).values()) {
    if (nestingLevel < maxNestingLevel) return true;
  }
  return false;
};

export const produceIncreaseNestingLevel = (editor) => (event) => {
  if (event) event.preventDefault();
  if (!canIncreaseNestingLevel(editor)) return;

  for (let [node] of Editor.nodes(editor, {
    at: editor.selection,
    mode: 'highest',
    match: (n) => Editor.isBlock(editor, n) && isNestableBlockVariant(n.type),
  })) {
    if (isNestableBlockVariant(node.type) && node.nestingLevel < maxNestingLevel) {
      const path = ReactEditor.findPath(editor, node);
      Transforms.setNodes(editor, { nestingLevel: node.nestingLevel + 1 }, { at: path });
    }
  }
  ReactEditor.focus(editor);
};

export const produceDecreaseNestingLevel = (editor) => (event) => {
  if (event) event.preventDefault();
  if (!canDecreaseNestingLevel(editor)) return;

  for (let [node] of Editor.nodes(editor, {
    at: editor.selection,
    mode: 'highest',
    match: (n) => Editor.isBlock(editor, n) && isNestableBlockVariant(n.type),
  })) {
    if (isNestableBlockVariant(node.type) && node.nestingLevel > minNestingLevel) {
      const path = ReactEditor.findPath(editor, node);
      Transforms.setNodes(editor, { nestingLevel: node.nestingLevel - 1 }, { at: path });
    }
  }
  ReactEditor.focus(editor);
};

export const recalculateBulletsOrdinals = (editor) => {
  let counters = [];

  editor.children.forEach((node, index) => {
    if (node.type === blockVariants.orderedBullet && Number.isInteger(node.nestingLevel)) {
      while (counters.length < node.nestingLevel + 1) counters.push(-1);
      while (counters.length > node.nestingLevel + 1) counters.pop();

      const ordinal = counters[node.nestingLevel] + 1;
      counters[node.nestingLevel] = ordinal;

      if (ordinal !== node.ordinal) {
        Transforms.setNodes(editor, { ordinal }, { at: [index] });
      }
    } else {
      counters = [];
    }
  });
};
