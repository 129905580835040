import React, { useCallback } from 'react';
import { withFormik } from 'formik';
import { customPropsToValues, enumToSelectorValues, produceValuesFilter } from '../../../../helpers/formHelpers';
import { activeElementTypes } from '../../../../constants/activeElementTypes';
import { enumT, initT } from '../../../../helpers/i18nHelpers';
import { connect } from 'react-redux';
import { activeElementsActionDeactivate } from '../../../../stateManagement/actions/activeElementsActions';
import HabitChartRequest from '../../../../models/habitChartRequest';
import SmartForm from '../../../form/SmartForm';
import ModalHeader from '../../ModalHeader';
import ModalBody from '../../ModalBody';
import ModalFooter from '../../ModalFooter';
import isEmpty from 'lodash/isEmpty';
import SmartField from '../../../form/SmartField';
import { aggregationMethods } from '../../../../constants/enums/aggregationMethods';
import RadioField from '../../../form/fieldTypes/RadioField';
import { habitChartRequestVariants } from '../../../../constants/enums/habitChartRequestVariants';
import toLower from 'lodash/toLower';
import LabelledButton from '../../../buttons/LabelledButton';
import SelectorField from '../../../form/fieldTypes/SelectorField';

const t = initT('modals.habitChartRequestModal');

const relevantAttributes = ['aggregationMethod', 'variant'];
const filterValues = produceValuesFilter(relevantAttributes);

const HabitChartRequestInnerForm = (props) => {
  const { errors, handleSubmit, activeElementsActionDeactivate: deactivate } = props;

  const hideModal = useCallback(() => deactivate(activeElementTypes.habitChartRequestModal, null), [deactivate]);
  const aggregationMethodOptions = enumToSelectorValues(aggregationMethods, 'aggregationMethods');

  return (
    <SmartForm>
      <ModalHeader type={activeElementTypes.habitChartRequestModal} identifier={null}>
        {t('title')}
      </ModalHeader>
      <ModalBody>
        <div className="max-w-fit-content min-w-48">
          <div className="flex items-center">
            <div className="w-24 mr-2">
              <SmartField
                name="aggregationMethod"
                options={aggregationMethodOptions}
                component={SelectorField}
                leftPadding={false}
              />
            </div>
            {t('labels.aggregationMethodFollowUp')}
          </div>
        </div>
        <div className="mt-25px">
          <SmartField
            name="variant"
            component={RadioField}
            radioValue={habitChartRequestVariants.daily}
            label={toLower(enumT('habitChartRequestVariants.daily'))}
          />
        </div>
        <div className="mt-25px">
          <SmartField
            name="variant"
            component={RadioField}
            radioValue={habitChartRequestVariants.weekly}
            label={toLower(enumT('habitChartRequestVariants.weekly'))}
          />
        </div>
        <div className="mt-25px">
          <SmartField
            name="variant"
            component={RadioField}
            radioValue={habitChartRequestVariants.monthly}
            label={toLower(enumT('habitChartRequestVariants.monthly'))}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <LabelledButton variant="cancel" onMouseDown={hideModal} />
        <LabelledButton variant="ok" disabled={!isEmpty(errors)} onMouseDown={handleSubmit} />
      </ModalFooter>
    </SmartForm>
  );
};

const HabitChartRequestForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: (props) => customPropsToValues(props, filterValues),
  handleSubmit: (values, formikBag) => {
    const { addElement, activeElementsActionDeactivate } = formikBag.props;
    const habitChartRequest = new HabitChartRequest().assignValues(filterValues(values));
    addElement(habitChartRequest);
    activeElementsActionDeactivate(activeElementTypes.habitChartRequestModal, null);
  },
})(HabitChartRequestInnerForm);

export default connect(null, { activeElementsActionDeactivate })(HabitChartRequestForm);
