import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { notificationT } from '../../../helpers/i18nHelpers';
import { activeElementsActionActivateIfOtherInactive } from '../../actions/activeElementsActions';
import { activeElementTypes } from '../../../constants/activeElementTypes';
import { recurringEventFromDto, recurringEventToDto } from '../../../helpers/model/recurringEventHelpers';
import {
  recurringEventsActionSetCreatedElement,
  recurringEventsActionUnlock,
} from '../../actions/recurringEventsActions';

export default function* createElementRecurringEventsSaga(action) {
  const { ok, data } = yield call(api.recurringEvents.create, recurringEventToDto(action.payload));
  if (ok) {
    const newRecurringEvent = recurringEventFromDto(data);
    yield put(snackbarMessagesActionAdd(notificationT('recurringEvents.create.success')));
    yield put(recurringEventsActionSetCreatedElement(newRecurringEvent));
    yield put(activeElementsActionActivateIfOtherInactive(activeElementTypes.recurringEvent, newRecurringEvent.id));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(recurringEventsActionUnlock());
  }
}
