import { useLocation } from 'react-router-dom';
import Clickable from '../../../shared/Clickable';
import Hoverable from '../../../shared/Hoverable';
import ThemeCrumb from '../../../shared/ThemeCrumb';
import TooltipWrapper from '../../../shared/TooltipWrapper';
import React from 'react';
import ConfirmationButton from '../../../shared/buttons/ConfirmationButton';

const NavbarLink = (props) => {
  const {
    children,
    to,
    customLinkOnClick = false,
    onMouseDown,
    tooltip,
    confirmationI18nKey,
    marginClass = 'mx-2',
    widthClass = 'w-24 xl:w-32',
  } = props;

  const location = useLocation();
  const selected = typeof to === 'string' && location.pathname.startsWith(to.split('?')[0]);

  let result = (
    <Hoverable className="py-3 rounded-xl flex justify-center" hoveredThemeType="hovered" sizeClass={widthClass}>
      {children}
    </Hoverable>
  );

  result = confirmationI18nKey ? (
    <ConfirmationButton
      onConfirmed={onMouseDown}
      component={Clickable}
      className="w-full"
      i18nKey={confirmationI18nKey}
    >
      {result}
    </ConfirmationButton>
  ) : (
    <Clickable to={to} customLinkOnClick={customLinkOnClick} onMouseDown={onMouseDown} className="w-full">
      {result}
    </Clickable>
  );

  result = (
    <ThemeCrumb type={selected ? 'navbarLinkSelected' : 'navbarLink'}>
      <div
        className={`${marginClass} ${widthClass} h-56px flex justify-center items-center
                             ${selected ? 'border-b-4 font-bold' : ''}`}
      >
        {result}
      </div>
    </ThemeCrumb>
  );

  if (tooltip)
    result = (
      <TooltipWrapper tooltip={tooltip} className="w-full flex justify-center items-center">
        {result}
      </TooltipWrapper>
    );

  return result;
};

export default NavbarLink;
