import React, { useState } from 'react';
import useResizeEffect from '../../hooks/useResizeEffect';
import {
  getNavbarHeight,
  getStandardTemplateBottomHeight,
  getStandardTemplateTopHeight,
} from '../../helpers/elementsSizeHelpers';
import NoData from './NoData';

// applicable to StandardContentTemplate main only
const FullPageNoData = () => {
  const [topPx, setTopPx] = useState(null);

  useResizeEffect(
    () => {
      const subtrahend = getNavbarHeight() + getStandardTemplateTopHeight() + getStandardTemplateBottomHeight();
      setTopPx((window.innerHeight - subtrahend) * 0.382);
    },
    [],
    true,
  );

  if (!topPx) return <></>;
  return (
    <div className="relative w-full">
      <div className="absolute left-0 right-0" style={{ top: `${topPx}px` }}>
        <NoData />
      </div>
    </div>
  );
};

export default FullPageNoData;
