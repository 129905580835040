import React, { useMemo, useState } from 'react';
import DayPlaceholder from './DayPlaceholder';
import range from 'lodash/range';
import DayTile from './DayTile';
import useResizeEffect from '../../hooks/useResizeEffect';
import { getNavbarHeight, getStandardTemplateTopHeight } from '../../helpers/elementsSizeHelpers';
import {
  calendarBottomMargin,
  calendarGap,
  calendarTopMargin,
  dayContainerBorder,
  dayContentPadding,
  dayHeaderHeight,
} from './metrics';
import { useHistory } from 'react-router-dom';

const MonthlyCalendar = (props) => {
  const { currentMonthDT } = props;

  const history = useHistory();
  const [dayTileContentHeight, setDayTileContentHeight] = useState(null);

  const endPlaceholdersCount = useMemo(() => {
    const result = 7 - ((currentMonthDT.weekday - 1 + currentMonthDT.daysInMonth) % 7);
    return result === 7 ? 0 : result;
  }, [currentMonthDT]);

  const rowsCount = useMemo(
    () => Math.ceil((currentMonthDT.weekday - 1 + currentMonthDT.daysInMonth) / 7),
    [currentMonthDT],
  );

  useResizeEffect(
    () => {
      let newHeight = window.innerHeight;
      newHeight = newHeight - getNavbarHeight() - getStandardTemplateTopHeight();
      newHeight = newHeight - calendarTopMargin - calendarBottomMargin;
      newHeight = newHeight - (rowsCount - 1) * calendarGap;
      newHeight = Math.floor(newHeight / rowsCount);
      newHeight = newHeight - dayContainerBorder * 2 - dayHeaderHeight - dayContentPadding * 2;
      setDayTileContentHeight(newHeight);
    },
    [rowsCount],
    true,
  );

  return (
    <div className="w-full grid grid-cols-7" style={{ marginTop: calendarTopMargin, gap: calendarGap }}>
      {range(1, currentMonthDT.weekday).map((index) => (
        <DayPlaceholder key={index} />
      ))}
      {range(1, currentMonthDT.daysInMonth + 1).map((day) => (
        <DayTile key={day} dt={currentMonthDT.set({ day })} contentHeight={dayTileContentHeight} history={history} />
      ))}
      {range(0, endPlaceholdersCount).map((index) => (
        <DayPlaceholder key={index} />
      ))}
    </div>
  );
};

export default MonthlyCalendar;
