import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { prepareCollection } from '../../../helpers/model/modelHelpers';
import { tasksActionSet } from '../../actions/tasksActions';
import { taskFromDto } from '../../../helpers/model/taskHelpers';

export default function* fetchTasksSaga() {
  const { ok, data } = yield call(api.tasks.index);
  if (ok) {
    yield put(tasksActionSet(prepareCollection(data, taskFromDto)));
  }
}
