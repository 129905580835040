import Session from '../../models/session';

const initialState = () => new Session();

export default function (state = initialState(), action) {
  switch (action.type) {
    case 'SESSION_ACTION_LOOKUP':
      return initialState();
    case 'SESSION_ACTION_SIGN_IN':
      return initialState();
    case 'SESSION_ACTION_SET':
      return action.payload;
    case 'GLOBAL_ACTION_RESET': {
      if (action.payload?.skipSessionConfig) return state;
      else return initialState();
    }
    default:
      return state;
  }
}
