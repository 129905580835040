import React, { PureComponent, useEffect, useState } from 'react';
import Sortable from 'sortablejs';
import flatten from 'lodash/flatten';
import { connect } from 'react-redux';
import { draggedElementActionDrop } from '../../stateManagement/actions/draggedElementActions';
import { generateTagId, getUuid } from '../../helpers/identifierHelpers';

const identifiersDict = {};
const dropPayloadsDict = {};

const defaultOnMove = (event) => event.related.className.indexOf('not-draggable-item') === -1;

const Inner = (props) => {
  const {
    children,
    draggedElementType,
    draggedElementActionDrop,
    childIdentifierAttribute = 'key',
    className = '',
    reinitializer = '',
    draggingDisabled = false,
    onDragStart = () => {},
    onDragMove = defaultOnMove,
    onDragEnd = () => {},
    onDragSuccess = false,
    group,
    additionalDropPayload,
  } = props;

  const [id] = useState(generateTagId());
  const [sortable, setSortable] = useState(null);
  const flatChildren = flatten(children);

  useEffect(() => {
    identifiersDict[id] = flatChildren.map((ch) => ch[childIdentifierAttribute]);
  }, [flatChildren.map((ch) => ch.key).toString()]);
  useEffect(() => {
    dropPayloadsDict[id] = additionalDropPayload;
  }, [dropPayloadsDict]);

  useEffect(() => {
    if (sortable) sortable.destroy();
    if (draggingDisabled) {
      setSortable(null);
      return;
    }
    const sortableUuid = getUuid();

    const newSortable = Sortable.create(document.getElementById(id), {
      delay: 300,
      animation: 150,
      delayOnTouchOnly: true,
      filter: '.not-draggable-item',
      group,
      fallbackOnBody: !!group,
      swapThreshold: 1,
      onChoose: (e) => {
        window.draggingActive = true;
        window.lastSortableUuid = sortableUuid;
        window.lastDraggingActionUuid = getUuid();
        onDragStart(e);
      },
      onUnchoose: (e) => {
        window.draggingActive = false;
        onDragEnd(e);
      },
      onMove: (event, originalEvent) => onDragMove(event, originalEvent, defaultOnMove),
    });

    setSortable(newSortable);

    const defaultOnSuccess = (event) => {
      const { oldIndex, newIndex, from, to } = event;
      const draggedIdentifier = flatChildren[event.oldIndex][childIdentifierAttribute];
      let successorIdentifier;
      if (from === to) {
        if (oldIndex === newIndex) return;
        successorIdentifier = identifiersDict[id][oldIndex > newIndex ? newIndex : newIndex + 1];
      } else {
        successorIdentifier = identifiersDict[to.id][newIndex];
      }
      successorIdentifier = successorIdentifier === undefined ? null : successorIdentifier;
      draggedElementActionDrop(draggedElementType, draggedIdentifier, successorIdentifier, dropPayloadsDict[to.id]);
    };

    // If draggedElementType is not specified there is no need to handle communication with
    // backend. onEnd option is specified after newSortable creation because we have to have
    // access to newSortable reference in the function.
    if (draggedElementType) {
      newSortable.option(
        'onEnd',
        onDragSuccess
          ? (event) => onDragSuccess(event, flatChildren[event.oldIndex][childIdentifierAttribute], defaultOnSuccess)
          : defaultOnSuccess,
      );
    }

    return () => {
      if (window.draggingActive && window.lastSortableUuid === sortableUuid) {
        window.draggingActive = false;
        onDragEnd();
      }
    };
  }, [draggingDisabled, flatChildren.map((ch) => ch.key).toString(), reinitializer]);

  return (
    <div id={id} className={className}>
      {flatChildren}
    </div>
  );
};

const ConnectedInner = connect(null, {
  draggedElementActionDrop,
})(Inner);

class DraggableList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      reinitializer: generateTagId(),
      draggingDisabled: undefined,
    };
  }

  reinitialize() {
    // with timeout to make sure Inner destructor runs onDragEnd if necessary
    setTimeout(() => {
      window.draggingActive = false;
    });
    this.setState({ reinitializer: generateTagId() });
  }

  disableDragging() {
    // with timeout to make sure Inner destructor runs onDragEnd if necessary
    setTimeout(() => {
      window.draggingActive = false;
    });
    this.setState({ draggingDisabled: true });
  }

  enableDragging() {
    this.setState({ draggingDisabled: false });
  }

  render() {
    const { reinitializer, draggingDisabled: stateDraggingDisabled } = this.state;
    const { draggingDisabled: propsDraggingDisabled, ...remainingProps } = this.props;

    let draggingDisabled;
    if (propsDraggingDisabled === undefined) draggingDisabled = stateDraggingDisabled;
    else if (stateDraggingDisabled === undefined) draggingDisabled = propsDraggingDisabled;
    else draggingDisabled = propsDraggingDisabled || stateDraggingDisabled;

    return <ConnectedInner reinitializer={reinitializer} draggingDisabled={draggingDisabled} {...remainingProps} />;
  }
}

export default DraggableList;
