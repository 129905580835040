import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { prepareCollection } from '../../../helpers/model/modelHelpers';
import { learningWorkspacesActionSet } from '../../actions/learningWorkspacesActions';
import { learningWorkspaceFromDto } from '../../../helpers/model/learningWorkspacesHelpers';

export default function* fetchLearningWorkspacesSaga(action) {
  const { ok, data } = yield call(api.learningWorkspaces.index, action.payload);
  if (ok) {
    yield put(learningWorkspacesActionSet(prepareCollection(data, learningWorkspaceFromDto)));
  }
}
