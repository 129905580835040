import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { sessionActionLookup } from '../../actions/sessionActions';
import { notificationT } from '../../../helpers/i18nHelpers';

export default function* incognitoModeDisableSaga() {
  const { ok } = yield call(api.users.incognitoMode.disable);
  if (ok) {
    yield put(snackbarMessagesActionAdd(notificationT('incognitoMode.disable.success')));
    yield put(sessionActionLookup());
  } else yield put(snackbarMessagesActionHandleBackendErrors());
}
