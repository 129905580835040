import React from 'react';
import StandardContentTemplate from '../../shared/contentTemplates/StandardContentTemplate';
import SearchingSpace from './SearchingSpace';
import { initT } from '../../helpers/i18nHelpers';
import { PageTitle } from '../../shared/titles/PageTitle';

const t = initT('pages.finder');

const FinderPage = () => {
  return <StandardContentTemplate top={<PageTitle>{t('title')}</PageTitle>} main={<SearchingSpace />} />;
};

export default FinderPage;
