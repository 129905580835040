import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import flatten from 'lodash/flatten';
import compact from 'lodash/compact';

export const getVisibleHabitsAndAnswers = (visibleHabits, habitAnswers, date, showOnlyUpdated) => {
  const result = compact(
    habitAnswers[date]
      .filter((answer) => {
        const habit = find(visibleHabits, { id: answer.habitId });
        if (!habit) return false;
        return !answer.hidden && !(showOnlyUpdated && habit.considerOnlyUpdatedValues && !answer.wasUpdated);
      })
      .map((answer) => {
        const habit = find(visibleHabits, { id: answer.habitId });
        return { habit, answer };
      }),
  );
  return sortBy(result, 'habit.defaultOrder');
};

export const getVisibleHabitsAndAnswersForSection = (
  section,
  habits,
  habitAnswers,
  date,
  showOnlyUpdated,
  incognitoMode,
) => {
  const visibleHabits = habits.visible(incognitoMode, { habitsSectionId: section.id, hidden: false });
  return getVisibleHabitsAndAnswers(visibleHabits, habitAnswers, date, showOnlyUpdated);
};

export const getVisibleHabitsAndAnswersForSections = (
  visibleSections,
  habits,
  habitAnswers,
  date,
  showOnlyUpdated,
  incognitoMode,
) => {
  return flatten(
    visibleSections.map((section) => {
      const visibleHabits = habits.visible(incognitoMode, { habitsSectionId: section.id, hidden: false });
      return getVisibleHabitsAndAnswers(visibleHabits, habitAnswers, date, showOnlyUpdated);
    }),
  );
};

export const getHiddenHabitsAndAnswersForSection = (
  section,
  habits,
  habitAnswers,
  date,
  incognitoMode,
  showOnlyUpdated,
) => {
  const relevantHabits = habits.visible(incognitoMode, { habitsSectionId: section.id });
  const result = compact(
    habitAnswers[date]
      .filter((answer) => {
        const habit = find(relevantHabits, { id: answer.habitId });
        if (!habit) return false;
        return (
          (habit.hidden || answer.hidden) && !(showOnlyUpdated && habit.considerOnlyUpdatedValues && !answer.wasUpdated)
        );
      })
      .map((answer) => {
        const habit = find(relevantHabits, { id: answer.habitId });
        return { habit, answer };
      }),
  );
  return sortBy(result, 'habit.defaultOrder');
};
