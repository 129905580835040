import Lockable from './parents/lockable';

export default class DayEvaluation extends Lockable {
  constructor() {
    super();
    this.id = null;
    this.evaluation = null;
    this.date = null;
  }

  visible() {
    return true;
  }
}
