export const customColors = {
  berryRed: {
    primary: '#B8255F',
    secondary: 'rgba(184,37,95,0.5)',
    third: 'rgba(184,37,95,0.25)',
    fourth: 'rgba(184,37,95,0.1)',
  },
  red: {
    primary: '#DB4035',
    secondary: 'rgba(219,64,53,0.5)',
    third: 'rgba(219,64,53,0.25)',
    fourth: 'rgba(219,64,53,0.1)',
  },
  orange: {
    primary: '#FF9933',
    secondary: 'rgba(255,153,51,0.5)',
    third: 'rgba(255,153,51,0.25)',
    fourth: 'rgba(255,153,51,0.1)',
  },
  yellow: {
    primary: '#FAD000',
    secondary: 'rgba(250,208,0,0.5)',
    third: 'rgba(250,208,0,0.25)',
    fourth: 'rgba(250,208,0,0.1)',
  },
  oliveGreen: {
    primary: '#AFB83B',
    secondary: 'rgba(175,184,59,0.5)',
    third: 'rgba(175,184,59,0.25)',
    fourth: 'rgba(175,184,59,0.1)',
  },
  limeGreen: {
    primary: '#7ECC49',
    secondary: 'rgba(126,204,73,0.5)',
    third: 'rgba(126,204,73,0.25)',
    fourth: 'rgba(126,204,73,0.1)',
  },
  green: {
    primary: '#299438',
    secondary: 'rgba(41,148,56,0.5)',
    third: 'rgba(41,148,56,0.25)',
    fourth: 'rgba(41,148,56,0.1)',
  },
  mintGreen: {
    primary: '#6ACCBC',
    secondary: 'rgba(106,204,188,0.5)',
    third: 'rgba(106,204,188,0.25)',
    fourth: 'rgba(106,204,188,0.1)',
  },
  teal: {
    primary: '#158FAD',
    secondary: 'rgba(21,143,173,0.5)',
    third: 'rgba(21,143,173,0.25)',
    fourth: 'rgba(21,143,173,0.1)',
  },
  skyBlue: {
    primary: '#14AAF5',
    secondary: 'rgba(20,170,245,0.5)',
    third: 'rgba(20,170,245,0.25)',
    fourth: 'rgba(20,170,245,0.1)',
  },
  lightBlue: {
    primary: '#96C3EB',
    secondary: 'rgba(150,195,235,0.5)',
    third: 'rgba(150,195,235,0.25)',
    fourth: 'rgba(150,195,235,0.1)',
  },
  blue: {
    primary: '#4073FF',
    secondary: 'rgba(64,115,255,0.5)',
    third: 'rgba(64,115,255,0.25)',
    fourth: 'rgba(64,115,255,0.1)',
  },
  grape: {
    primary: '#884DFF',
    secondary: 'rgba(136,77,255,0.5)',
    third: 'rgba(136,77,255,0.25)',
    fourth: 'rgba(136,77,255,0.1)',
  },
  violet: {
    primary: '#AF38EB',
    secondary: 'rgba(175,56,235,0.5)',
    third: 'rgba(175,56,235,0.25)',
    fourth: 'rgba(175,56,235,0.1)',
  },
  lavender: {
    primary: '#EB96EB',
    secondary: 'rgba(235,150,235,0.5)',
    third: 'rgba(235,150,235,0.25)',
    fourth: 'rgba(235,150,235,0.1)',
  },
  magenta: {
    primary: '#E05194',
    secondary: 'rgba(224,81,148,0.5)',
    third: 'rgba(224,81,148,0.25)',
    fourth: 'rgba(224,81,148,0.1)',
  },
  salmon: {
    primary: '#FF8D85',
    secondary: 'rgba(255,141,133,0.5)',
    third: 'rgba(255,141,133,0.25)',
    fourth: 'rgba(255,141,133,0.1)',
  },
  charcoal: {
    primary: '#808080',
    secondary: 'rgba(128,128,128,0.5)',
    third: 'rgba(128,128,128,0.25)',
    fourth: 'rgba(128,128,128,0.1)',
  },
  grey: {
    primary: '#B8B8B8',
    secondary: 'rgba(184,184,184,0.5)',
    third: 'rgba(184,184,184,0.25)',
    fourth: 'rgba(184,184,184,0.1)',
  },
  taupe: {
    primary: '#CCAC93',
    secondary: 'rgba(204,172,147,0.5)',
    third: 'rgba(204,172,147,0.25)',
    fourth: 'rgba(204,172,147,0.1)',
  },
};
