export const habitsSectionsActionFetch = () => ({
  type: 'HABITS_SECTIONS_ACTION_FETCH',
});

export const habitsSectionsActionSet = (habitsSections) => ({
  type: 'HABITS_SECTIONS_ACTION_SET',
  payload: habitsSections,
});

export const habitsSectionsActionCreateElement = (habitsSection) => ({
  type: 'HABITS_SECTIONS_ACTION_CREATE_ELEMENT',
  payload: habitsSection,
});

export const habitsSectionsActionUnlock = () => ({
  type: 'HABITS_SECTIONS_ACTION_UNLOCK',
});

export const habitsSectionsActionUpdateElement = (habitsSection) => ({
  type: 'HABITS_SECTIONS_ACTION_UPDATE_ELEMENT',
  payload: habitsSection,
});

export const habitsSectionsActionMarkPrivateElement = (habitsSection) => ({
  type: 'HABITS_SECTIONS_ACTION_MARK_PRIVATE_ELEMENT',
  payload: habitsSection.assignValues({ private: true }),
});

export const habitsSectionsActionUnmarkPrivateElement = (habitsSection) => ({
  type: 'HABITS_SECTIONS_ACTION_UNMARK_PRIVATE_ELEMENT',
  payload: habitsSection.assignValues({ private: false }),
});

export const habitsSectionsActionMarkHiddenElement = (habitsSection) => ({
  type: 'HABITS_SECTIONS_ACTION_MARK_HIDDEN_ELEMENT',
  payload: habitsSection.assignValues({ hidden: true }),
});

export const habitsSectionsActionUnmarkHiddenElement = (habitsSection) => ({
  type: 'HABITS_SECTIONS_ACTION_UNMARK_HIDDEN_ELEMENT',
  payload: habitsSection.assignValues({ hidden: false }),
});

export const habitsSectionsActionLockElement = (habitsSection) => ({
  type: 'HABITS_SECTIONS_ACTION_LOCK_ELEMENT',
  payload: habitsSection,
});

export const habitsSectionsActionUnlockElement = (habitsSection) => ({
  type: 'HABITS_SECTIONS_ACTION_UNLOCK_ELEMENT',
  payload: habitsSection,
});

export const habitsSectionsActionDeleteElement = (habitsSection) => ({
  type: 'HABITS_SECTIONS_ACTION_DELETE_ELEMENT',
  payload: habitsSection,
});

export const habitsSectionsActionSetCreatedElement = (habitsSection) => ({
  type: 'HABITS_SECTIONS_ACTION_SET_CREATED_ELEMENT',
  payload: habitsSection,
});

export const habitsSectionsActionSetElement = (habitsSection) => ({
  type: 'HABITS_SECTIONS_ACTION_SET_ELEMENT',
  payload: habitsSection,
});

export const habitsSectionsActionUnset = (oneOrManyElements) => ({
  type: 'HABITS_SECTIONS_ACTION_UNSET',
  payload: oneOrManyElements,
});
