import React from 'react';
import Container from '../../shared/organizers/containers/Container';

const DayPlaceholder = (props) => {
  const { active = false, children } = props;

  return (
    <Container themeType="easilyDenotableContainer" className={active ? '' : 'opacity-30'}>
      {children}
    </Container>
  );
};

export default DayPlaceholder;
