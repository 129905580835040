import { getCurrentCookieTheme, removeCurrentCookieTheme, setCurrentCookieTheme } from '../../helpers/themeHelpers';

const initialState = () => getCurrentCookieTheme();

export default function (state = initialState(), action) {
  switch (action.type) {
    case 'THEME_ACTION_REMOVE':
      removeCurrentCookieTheme();
      return getCurrentCookieTheme();
    case 'THEME_ACTION_SET':
      setCurrentCookieTheme(action.payload);
      return getCurrentCookieTheme();
    case 'GLOBAL_ACTION_RESET': {
      if (action.payload?.skipSessionConfig) return state;
      else return initialState();
    }
    default:
      return state;
  }
}
