import { put } from 'redux-saga/effects';
import { flatten } from 'lodash';
import { nodesActionUnsetGroup } from '../../actions/nodesActions';

export default function* unsetTasksSaga(action) {
  const tasks = flatten([action.payload]);

  for (const task of tasks) {
    yield put(nodesActionUnsetGroup(task.nodesGroupId));
  }
}
