import React from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../helpers/stateManagementHelpers';
import { getThemeTypeDefinition } from '../../helpers/themeHelpers';
import { Chart as ChartJS } from 'react-chartjs-2';
import { getScrollYParent } from '../../helpers/jsHandlersHelpers';

let panningActive = false;

const Chart = (props) => {
  const {
    theme,
    labels,
    values,
    labelTitle,
    yMin,
    yMax,
    stepSize,
    heightClass = 'h-200px',
    labelsMaxNumber = 10,
    onMouseDown = false,
  } = props;

  const chartLabelsColor = getThemeTypeDefinition(theme, 'chartLabels').color;
  const chartGridColor = getThemeTypeDefinition(theme, 'chartGrid').color;
  const chartTitleLabelColor = getThemeTypeDefinition(theme, 'chartTitleLabel').color;
  const chartDataColors = getThemeTypeDefinition(theme, 'chartData');

  return (
    <div
      className={`w-full ${heightClass} ${onMouseDown ? 'cursor-pointer' : ''}`}
      onPointerMove={(event) => {
        const { movementY, movementX, pointerType } = event;
        if (pointerType === 'touch' && Math.abs(movementY) > Math.abs(movementX))
          getScrollYParent(event.target, false).scrollTop -= movementY;
      }}
    >
      <ChartJS
        type="line"
        onMouseDown={() => {
          if (!panningActive && onMouseDown) onMouseDown();
        }}
        data={{
          labels: labels,
          datasets: [
            {
              data: values,
              label: labelTitle,
              spanGaps: true,
              ...chartDataColors,
            },
          ],
        }}
        options={{
          color: chartTitleLabelColor,
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              min: labels.length < labelsMaxNumber ? 0 : labels.length - labelsMaxNumber,
              suggestedMax: labels.length,
              grid: {
                color: chartGridColor,
                borderColor: chartGridColor,
              },
              ticks: {
                color: chartLabelsColor,
              },
            },
            y: {
              suggestedMin: yMin,
              suggestedMax: yMax,
              grid: {
                color: chartGridColor,
                borderColor: chartGridColor,
              },
              ticks: {
                color: chartLabelsColor,
                stepSize: stepSize,
              },
            },
          },
          plugins: {
            zoom: {
              pan: {
                enabled: true,
                mode: 'x',
                onPanStart: ({ event: { deltaX, deltaY } }) => {
                  if (Math.abs(deltaY) > Math.abs(deltaX)) return false;
                  panningActive = true;
                  return true;
                },
                onPanComplete: () => {
                  setTimeout(() => {
                    panningActive = false;
                  }, 0);
                },
              },
            },
          },
        }}
      />
    </div>
  );
};

export default connect(prepareState(['theme']))(Chart);
