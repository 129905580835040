import frenchkiss from 'frenchkiss';

frenchkiss.extend('en', {
  enums: {
    inputTypes: {
      checkbox: 'Checkbox',
      counter: 'Counter',
      realNumber: 'Number',
      text: 'Text',
    },
    weekdayTypes: {
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
    },
    monthTypes: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December',
    },
    monthDays: {
      1: '1st',
      2: '2nd',
      3: '3rd',
      4: '4th',
      5: '5th',
      6: '6th',
      7: '7th',
      8: '8th',
      9: '9th',
      10: '10th',
      11: '11th',
      12: '12th',
      13: '13th',
      14: '14th',
      15: '15th',
      16: '16th',
      17: '17th',
      18: '18th',
      19: '19th',
      20: '20th',
      21: '21st',
      22: '22nd',
      23: '23rd',
      24: '24th',
      25: '25th',
      26: '26th',
      27: '27th',
      28: '28th',
      29: '29th',
      30: '30th',
      31: '31st',
      '-1': 'Last',
    },
    habitsVariants: {
      good: 'Good habit',
      bad: 'Bad habit',
    },
    aggregationMethods: {
      valuesSum: 'Sum',
      valuesAverage: 'Average',
    },
    habitChartRequestVariants: {
      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly',
    },
    dayEvaluationsChartRequestVariants: {
      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly',
    },
    learningQueueVariants: {
      overview: 'Overview',
      typing: 'Typing',
      audio: 'Audio',
      pdf: 'PDF',
    },
    learningQueueFeedOrderVariants: {
      biasedRandom: 'Smart',
      random: 'Random',
      onlyNodesOrdered: 'Random (only containers, nodes ordered)',
      ordered: 'Ordered',
    },
    learningQueueSourceVariants: {
      label: "Notes' label",
      note: 'Note',
      notesWorkspace: "Notes' workspace",
    },
  },
});
