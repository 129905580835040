import React from 'react';
import ThemeCrumb from '../ThemeCrumb';
import Hint from '../Hint';

const getAsterisk = (required) => {
  if (required)
    return (
      <ThemeCrumb type="required">
        <span>*</span>
      </ThemeCrumb>
    );
  return null;
};

const Label = (props) => {
  const { className = '', htmlFor, children, required = false, hint = false } = props;
  return (
    <div className={`mb-1 ${className}`}>
      <label htmlFor={htmlFor} className="flex justify-center">
        {children}
        {getAsterisk(required)}
        {hint ? <Hint className="ml-1">{hint}</Hint> : <></>}
      </label>
    </div>
  );
};

export default Label;
