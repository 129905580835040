import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { prepareCollection } from '../../../helpers/model/modelHelpers';
import { habitAnswersActionSet } from '../../actions/habitAnswersActions';
import { habitAnswerFromDto } from '../../../helpers/model/habitAnswersHelpers';

export default function* fetchHabitAnswersSaga(action) {
  const { date, readonly } = action.payload;
  const { ok, data } = yield call(api.habitAnswers.index, { date, readonly });
  if (ok) {
    yield put(habitAnswersActionSet(date, prepareCollection(data, habitAnswerFromDto)));
  }
}
