import React, { useEffect, useRef, useState } from 'react';
import ThemeCrumb from '../../../ThemeCrumb';
import { FieldArray, withFormik } from 'formik';
import SmartForm from '../../../form/SmartForm';
import SmartField from '../../../form/SmartField';
import FileField from '../../../form/fieldTypes/FileField';
import { imageFileExtensions } from '../../../../constants/fileExtensions';
import LabelField from '../../../form/LabelField';
import TextField from '../../../form/fieldTypes/TextField';
import { initT } from '../../../../helpers/i18nHelpers';
import IconButton from '../../../buttons/IconButton';
import AttachmentIcon from '../../../../icons/attachmentIcon';
import last from 'lodash/last';
import Loader from '../../../loaders/Loader';
import LockedEntityLoader from '../../../loaders/LockedEntityLoader';
import { generateTagId } from '../../../../helpers/identifierHelpers';
import Sortable from 'sortablejs';
import useAttachmentsGridColsClass from './useAttachmentsGridColsClass';
import OnClickOutsideWrapper from '../../../onClickOutside/OnClickOutsideWrapper';
import compact from 'lodash/compact';

const t = initT('features.nodesGroupPanel');

const FilePresenter = (name) => (props) => {
  const { id, value } = props;

  const isImage = name && imageFileExtensions.includes(`.${last(name.split('.'))}`);

  let content;
  if (value && isImage) content = <img src={value} alt="Attachment image" className="max-h-16" />;
  else if (value)
    content = (
      <ThemeCrumb type="input">
        <AttachmentIcon className="w-full w-6 h-6" />
      </ThemeCrumb>
    );
  else content = <Loader sizeClass="w-16 h-16" />;

  return (
    <div className="w-full flex justify-center">
      <label htmlFor={id} className="cursor-pointer hover:opacity-50 w-full">
        <div className="h-16 flex justify-center items-center">{content}</div>
        <div className="mt-2 w-full overflow-hidden text-xs h-8">{name}</div>
      </label>
    </div>
  );
};

let filePickerLocks = [];

const AttachmentsEditorForm = (props) => {
  const {
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    values: { attachments, onModal },
  } = props;

  const [listId] = useState(generateTagId());
  const [sortable, setSortable] = useState(null);
  const [indexToClickOn, setIndexToClickOn] = useState(null);

  const ref = useRef(null);
  const gridColsClass = useAttachmentsGridColsClass(ref);

  useEffect(() => {
    if (sortable) sortable.destroy();
    const newSortable = Sortable.create(document.getElementById(listId), {
      delay: 300,
      animation: 150,
      delayOnTouchOnly: true,
      filter: '.not-draggable-item',
    });
    setSortable(newSortable);
  }, [attachments.map((a) => a.url).toString()]);

  return (
    <OnClickOutsideWrapper onClickOutside={handleSubmit} exceptionModals={compact([onModal])}>
      <ThemeCrumb type="input">
        <SmartForm className="p-25px border rounded">
          <FieldArray
            name="attachments"
            render={(arrayHelpers) => {
              if (sortable)
                sortable.option('onEnd', (event) => {
                  const { oldIndex, newIndex } = event;
                  arrayHelpers.move(oldIndex, newIndex);
                });

              const attachmentsLength = attachments ? attachments.length : 0;
              const descriptionLabel = t('attachmentsEditor.description');
              const removeFilePickerLock = (elementId) => {
                filePickerLocks = filePickerLocks.filter((id) => id !== elementId);
              };
              const pruneAttachments = () => {
                const filtered = attachments.filter((a) => !!a.url);
                if (attachments.length !== filtered.length) setFieldValue('attachments', filtered);
              };
              const autoClickHandling = (elementId) => () => {
                filePickerLocks.push(elementId);

                // need to wait for onChange to execute (or not execute)
                setTimeout(() => {
                  if (filePickerLocks.includes(elementId)) {
                    pruneAttachments();
                    removeFilePickerLock(elementId);
                  }
                  // not very clean, but the best found solution
                }, 300);
              };

              return (
                <div id={listId} ref={ref} className={`grid ${gridColsClass} gap-25px`}>
                  {attachmentsLength > 0 ? (
                    attachments.map((attachment, index) => (
                      <div key={attachment.url} className="flex h-full items-center">
                        <LockedEntityLoader visible={!attachment.url}>
                          <div className="w-full flex justify-end mb-1">
                            <IconButton
                              onMouseDown={() => {
                                arrayHelpers.remove(index);
                              }}
                              iconSizeClass="w-2 h-2"
                              variant="close"
                              tooltip={t('attachmentsEditor.remove')}
                            />
                          </div>
                          <SmartField
                            name={`attachments.${index}.url`}
                            component={FileField}
                            innerRef={(element) => {
                              if (index === indexToClickOn && element) {
                                setIndexToClickOn(null);
                                // the only way to detect cancel also
                                window.addEventListener('focus', autoClickHandling(element.id), { once: true });
                                element.click();
                              }
                            }}
                            onChange={(onChangeProps, defaultOnChange) => {
                              removeFilePickerLock(onChangeProps.target.id);
                              defaultOnChange(onChangeProps);
                            }}
                            onUpload={(props, defaultOnUpload) => {
                              defaultOnUpload(props);
                              const fieldName = `attachments.${index}.name`;
                              setFieldTouched(fieldName);
                              setFieldValue(fieldName, props.name);
                            }}
                            onError={(props, defaultOnError) => {
                              defaultOnError(props);
                              pruneAttachments();
                            }}
                            labelComponent={FilePresenter(attachment.name)}
                          />
                          <LabelField
                            name={`attachments.${index}.description`}
                            className="mt-4"
                            label={descriptionLabel}
                            component={TextField}
                          />
                        </LockedEntityLoader>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                  <div
                    className="w-full h-48 flex justify-center items-center
                                                    not-draggable-item"
                  >
                    <IconButton
                      variant="add"
                      tooltip={t('attachmentsEditor.add')}
                      // onMouseDown does not work for
                      // not-draggable-item
                      onClick={() => {
                        setIndexToClickOn(attachmentsLength);
                        arrayHelpers.insert(attachmentsLength, { url: '', description: '', name: '' });
                      }}
                    />
                  </div>
                </div>
              );
            }}
          />
        </SmartForm>
      </ThemeCrumb>
    </OnClickOutsideWrapper>
  );
};

const AttachmentsEditor = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ content, onModal }) => {
    let { attachments = [] } = content || {};
    attachments = attachments
      .map(({ url = '', description = '', name = '' }) => ({
        url,
        description,
        name,
      }))
      .filter(({ url, name }) => url && name);
    return { attachments, onModal };
  },
  handleSubmit: (values, formikBag) => {
    const { attachments } = values;
    formikBag.props.handleSubmit({ content: { attachments } });
  },
})(AttachmentsEditorForm);

export default AttachmentsEditor;
