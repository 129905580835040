export const blockVariants = {
  block: 'block',
  bullet: 'bullet',
  orderedBullet: 'orderedBullet',
  checklistBullet: 'checklistBullet',
};

export const blockVariantsValues = Object.values(blockVariants);

export const defaultBlockVariant = blockVariants.block;
