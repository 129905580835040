import React from 'react';
import HabitAnswers from './HabitAnswers';
import { connect } from 'react-redux';
import { prepareState } from '../../helpers/stateManagementHelpers';
import { getVisibleTodoTasks } from '../../helpers/taskHelpers';
import { initT } from '../../helpers/i18nHelpers';
import { getVisibleHabitsSections } from '../../helpers/habitsSectionHelpers';
import { getVisibleHabitsAndAnswersForSections } from '../../helpers/habitAnswerHelpers';
import NoData from '../../shared/placeholders/NoData';
import TodoList from './TodoList';
import find from 'lodash/find';
import compact from 'lodash/compact';
import { activeElementTypes } from '../../constants/activeElementTypes';
import HiddenHabitsSection from './HiddenHabitsSection';
import SectionTitle from '../../shared/titles/SectionTitle';
import MainTopSpace from '../../shared/organizers/MainTopSpace';
import LabelledButton from '../../shared/buttons/LabelledButton';
import { activeElementsActionActivate } from '../../stateManagement/actions/activeElementsActions';
import { sortBy } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { elementToFocusOnActionSet } from '../../stateManagement/actions/elementToFocusOnActions';
import { elementToFocusOnTypes } from '../../constants/elementToFocusOnTypes';
import useNarrowMainWidth from '../../hooks/useNarrowMainWidth';
import EventsList from './EventsList';

const t = initT('pages.calendar');

const Main = (props) => {
  const {
    locked,
    date,
    incognitoMode,
    categories,
    tasks,
    habitsSections,
    habits,
    habitAnswers,
    events,
    activeElements,
    activeElementsActionActivate: activate,
    elementToFocusOnActionSet,
  } = props;

  const { journalSectionId } = useParams();

  const history = useHistory();
  const nothingActive = !journalSectionId;
  const todoActive = !nothingActive && journalSectionId === 'todo';
  const eventsActive = !nothingActive && journalSectionId === 'events';

  const visibleCategories = sortBy(categories.visible(incognitoMode), 'tasksOrder');
  const todoTasks = getVisibleTodoTasks(categories, tasks, incognitoMode, date);
  const todoTasksPresent = todoTasks.length > 0;
  const todoPresent = (nothingActive && todoTasksPresent) || todoActive;
  const todoCreationActive = !!find(activeElements, { type: activeElementTypes.calendarTaskCreator });
  const visibleEvents = sortBy(events.visible(incognitoMode, { date }), 'defaultOrder');
  const eventsPresent = visibleEvents.length > 0;
  const eventsSectionPresent = (nothingActive && eventsPresent) || eventsActive;
  const eventsCreationActive = !!find(activeElements, { type: activeElementTypes.calendarEventCreator });

  const sections = nothingActive
    ? getVisibleHabitsSections(habitsSections, incognitoMode)
    : compact([
        habitsSections.find(
          {
            id: parseInt(journalSectionId, 10),
          },
          true,
          incognitoMode,
        ),
      ]);
  const answers = getVisibleHabitsAndAnswersForSections(sections, habits, habitAnswers, date, locked, incognitoMode);
  const answersPresent = answers.length > 0;

  const hiddenHabitsActive = !nothingActive && journalSectionId === 'hidden-habits';

  const narrowTitleWidth = useNarrowMainWidth();
  const eventsTitle = (
    <div style={{ width: narrowTitleWidth }}>
      <SectionTitle
        additionalContent={
          !eventsCreationActive ? (
            <LabelledButton
              variant="add"
              wrapperClass={locked ? 'hidden' : ''}
              onMouseDown={() => {
                elementToFocusOnActionSet(elementToFocusOnTypes.eventCreationForm, date);
                activate(activeElementTypes.calendarEventCreator, null);
              }}
            />
          ) : (
            <></>
          )
        }
      >
        {t('events.title')}
      </SectionTitle>
    </div>
  );
  const todoTitle = (
    <div style={{ width: narrowTitleWidth }}>
      <SectionTitle
        additionalContent={
          visibleCategories.length > 0 && !todoCreationActive ? (
            <LabelledButton
              variant="add"
              wrapperClass={locked ? 'hidden' : ''}
              onMouseDown={() => {
                elementToFocusOnActionSet(elementToFocusOnTypes.taskCreationForm, null);
                activate(activeElementTypes.calendarTaskCreator, null);
              }}
            />
          ) : (
            <></>
          )
        }
      >
        {t('todoTasks.title')}
      </SectionTitle>
    </div>
  );

  let noDataTitle = <></>;
  if (eventsActive) noDataTitle = eventsTitle;
  else if (todoActive) noDataTitle = todoTitle;
  else if (!hiddenHabitsActive && !nothingActive)
    noDataTitle = (() => {
      const section = habitsSections.find({ id: parseInt(journalSectionId, 10) }, true, incognitoMode);
      if (!section) {
        // a bit hacky without setTimeout, but it makes the UX better
        history.replace('/journal');
        return null;
      }
      return <SectionTitle>{section.name}</SectionTitle>;
    })();

  return (
    <div className={'w-full pb-25px px-25px'}>
      <MainTopSpace />
      {!eventsSectionPresent && !todoPresent && !answersPresent && !hiddenHabitsActive ? (
        <div className="w-full">
          <div className="w-full flex justify-center">{noDataTitle}</div>
          <NoData />
        </div>
      ) : (
        <></>
      )}
      {eventsSectionPresent && (
        <div className="flex flex-wrap justify-center mb-64px last:mb-0">
          {eventsTitle}
          <EventsList events={visibleEvents} date={date} disabled={locked} creationActive={eventsCreationActive} />
        </div>
      )}
      {todoPresent && (
        <div className={'flex flex-wrap justify-center mb-64px last:mb-0'}>
          {todoTitle}
          <TodoList todoTasks={todoTasks} disabled={locked} todoCreationActive={todoCreationActive} />
        </div>
      )}
      {nothingActive && answersPresent && <SectionTitle>{t('habitAnswers.title')}</SectionTitle>}
      {answersPresent && (
        <HabitAnswers date={date} locked={locked} sections={sections} useSectionTitle={!nothingActive} />
      )}
      {hiddenHabitsActive && <HiddenHabitsSection date={date} locked={locked} />}
    </div>
  );
};

export default connect(
  prepareState([
    'incognitoMode',
    'categories',
    'tasks',
    'incognitoMode',
    'habitsSections',
    'habits',
    'habitAnswers',
    'events',
    'activeElements',
  ]),
  { activeElementsActionActivate, elementToFocusOnActionSet },
)(Main);
