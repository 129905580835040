import React, { useState, useEffect, useMemo } from 'react';
import PdfGenerator from './PdfGenerator';
import LimitForm from './LimitForm';
import MainTopSpace from '../../../../shared/organizers/MainTopSpace';

const PdfVariant = (props) => {
  const { chunksCount } = props;

  const [limit, setLimit] = useState(null);
  useEffect(() => {
    setLimit(chunksCount);
  }, [chunksCount]);

  const [generationInProgress, setGenerationInProgress] = useState(false);

  const showLimitForm = useMemo(() => !!chunksCount && !generationInProgress, [chunksCount, generationInProgress]);
  const showPdfGenerator = useMemo(() => !!chunksCount && generationInProgress, [chunksCount, generationInProgress]);

  return (
    <>
      <MainTopSpace />
      {showLimitForm && (
        <LimitForm {...props} limit={limit} setLimit={setLimit} generatePdf={() => setGenerationInProgress(true)} />
      )}
      {showPdfGenerator && <PdfGenerator {...props} limit={limit} />}
    </>
  );
};

export default PdfVariant;
