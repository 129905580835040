import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { nodesActionUnlockElement, nodesActionUnset } from '../../actions/nodesActions';
import { notificationT } from '../../../helpers/i18nHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';

export default function* deleteElementNodesSaga(action) {
  const { element: initialNode } = action.payload;
  const { ok, data } = yield call(api.nodes.delete, initialNode.id);
  if (ok) {
    yield put(snackbarMessagesActionAdd(notificationT('nodes.delete.success')));
    yield put(nodesActionUnset(initialNode));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(nodesActionUnlockElement(initialNode));
  }
}
