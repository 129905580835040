import React from 'react';
import { withFormik } from 'formik';
import SmartField from '../../../form/SmartField';
import FileField from '../../../form/fieldTypes/FileField';
import { acceptImage } from '../../../../constants/fileExtensions';
import ImageIcon from '../../../../icons/imageIcon';
import ThemeCrumb from '../../../ThemeCrumb';
import TextField from '../../../form/fieldTypes/TextField';
import LabelField from '../../../form/LabelField';
import { initT } from '../../../../helpers/i18nHelpers';
import SmartForm from '../../../form/SmartForm';
import OnClickOutsideWrapper from '../../../onClickOutside/OnClickOutsideWrapper';
import { imageSizes } from '../../../../constants/enums/imageSizes';
import Clickable from '../../../Clickable';
import Label from '../../../form/Label';
import TooltipWrapper from '../../../TooltipWrapper';
import { produceShouldUpdate } from '../../../../helpers/formHelpers';
import IconButton from '../../../buttons/IconButton';
import compact from 'lodash/compact';

const t = initT('features.nodesGroupPanel');

const getImagePresenter = (size) => (props) => {
  const { id, value } = props;

  let maxWidthClass = '';
  if (size === imageSizes.small) maxWidthClass = 'max-w-64';
  else if (size === imageSizes.medium) maxWidthClass = 'max-w-96';

  return (
    <div className="w-full flex justify-center">
      <label htmlFor={id} className="cursor-pointer hover:opacity-50">
        <div className="w-full flex justify-center">
          {value ? (
            <img className={maxWidthClass} src={value} alt="Node image" />
          ) : (
            <ThemeCrumb type="input">
              <ImageIcon className="w-full max-w-24" />
            </ThemeCrumb>
          )}
        </div>
      </label>
    </div>
  );
};

const ImageEditorForm = (props) => {
  const {
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    values: { size, useDescription, borderClass, roundedClass, onCancel, onModal },
  } = props;

  const produceChangeSize = (size) => () => {
    setFieldTouched('size');
    setFieldValue('size', size);
  };

  const _handleSubmit = async (e) => {
    await setFieldValue('event', e);
    handleSubmit();
  };

  return (
    <OnClickOutsideWrapper onClickOutside={_handleSubmit} exceptionModals={compact([onModal])}>
      <ThemeCrumb type="input">
        <SmartForm className={`p-4 ${roundedClass} ${borderClass}`}>
          {onCancel ? (
            <div className="flex pb-4 justify-end">
              <IconButton
                variant="cancel"
                tooltip={t('imageEditor.removeImage')}
                onMouseDown={onCancel}
                iconSizeClass="w-3 h-3"
              />
            </div>
          ) : (
            <></>
          )}
          <SmartField
            name="url"
            component={FileField}
            labelComponent={getImagePresenter(size)}
            accept={acceptImage}
            shouldUpdate={produceShouldUpdate(['size'])}
          />
          <Label className="mt-25px">{t('imageEditor.size')}</Label>
          <div className="w-full flex justify-center">
            <div className="w-full max-w-64 grid grid-cols-3 gap-25px">
              <div
                className={`w-full h-full
                                             ${size === imageSizes.small ? '' : 'opacity-50'}`}
              >
                <TooltipWrapper className="w-full h-full" tooltip={t('imageEditor.sizes.small')}>
                  <Clickable
                    className="w-full h-full flex justify-center items-end"
                    onMouseDown={produceChangeSize(imageSizes.small)}
                    hoverOpacity
                  >
                    <ImageIcon className="w-6 h-6" />
                  </Clickable>
                </TooltipWrapper>
              </div>
              <div
                className={`w-full h-full
                                             ${size === imageSizes.medium ? '' : 'opacity-50'}`}
              >
                <TooltipWrapper className="w-full h-full" tooltip={t('imageEditor.sizes.medium')}>
                  <Clickable
                    className="w-full h-full flex justify-center items-end"
                    onMouseDown={produceChangeSize(imageSizes.medium)}
                    hoverOpacity
                  >
                    <ImageIcon className="w-8 h-8" />
                  </Clickable>
                </TooltipWrapper>
              </div>
              <div
                className={`w-full h-full
                                             ${size === imageSizes.large ? '' : 'opacity-50'}`}
              >
                <TooltipWrapper className="w-full h-full" tooltip={t('imageEditor.sizes.large')}>
                  <Clickable
                    className="w-full h-full flex justify-center items-end"
                    onMouseDown={produceChangeSize(imageSizes.large)}
                    hoverOpacity
                  >
                    <ImageIcon className="w-10 h-10" />
                  </Clickable>
                </TooltipWrapper>
              </div>
            </div>
          </div>
          {useDescription ? (
            <LabelField
              name="description"
              className="mt-25px"
              label={t('imageEditor.description')}
              component={TextField}
            />
          ) : (
            <></>
          )}
        </SmartForm>
      </ThemeCrumb>
    </OnClickOutsideWrapper>
  );
};

const ImageEditor = withFormik({
  mapPropsToValues: (props) => {
    const {
      url = '',
      description = '',
      size = imageSizes.large,
      useDescription = true,
      borderClass = 'border',
      roundedClass = 'rounded',
      onCancel = false,
      onModal,
    } = props;
    return {
      url,
      description,
      size,
      useDescription,
      borderClass,
      roundedClass,
      onCancel,
      onModal,
    };
  },
  handleSubmit: ({ url, size, description, event }, formikBag) => {
    formikBag.props.handleSubmit({ url, size, description, event });
  },
})(ImageEditorForm);

export default ImageEditor;
