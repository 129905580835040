import React from 'react';
import IconButton from './IconButton';
import { initT } from '../../helpers/i18nHelpers';
import ThemeCrumb from '../ThemeCrumb';

const t = initT('button');

const LabelledButton = (props) => {
  const {
    variant,
    children = t(variant),
    labelThemeType = 'button',
    wrapperClass = 'w-fit-content',
    paddingClass = 'pl-2 py-1 pr-3',
    iconRightPadding = 'pr-2',
    ...propsToPass
  } = props;

  return (
    <IconButton
      tooltip={false}
      paddingClass={paddingClass}
      preprocessIcon={(icon) => <span className={iconRightPadding}>{icon}</span>}
      iconSizeClass="w-4 h-4"
      wrapperClass={wrapperClass}
      variant={variant}
      {...propsToPass}
    >
      {(icon) => (
        <>
          {icon}
          <ThemeCrumb type={labelThemeType}>{children}</ThemeCrumb>
        </>
      )}
    </IconButton>
  );
};

export default LabelledButton;
