import React, { useState } from 'react';
import RecurringEventAdditionPanel from './RecurringEventAdditionPanel';
import RecurringEventsList from './RecurringEventsList';

const RecurringEvents = () => {
  const [additionMode, setAdditionMode] = useState(false);

  return (
    <div className="w-full pb-25px">
      <RecurringEventAdditionPanel additionMode={additionMode} setAdditionMode={setAdditionMode} />
      <div className="px-25px">
        <RecurringEventsList additionMode={additionMode} />
      </div>
    </div>
  );
};

export default RecurringEvents;
