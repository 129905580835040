import * as yup from 'yup';
import { frontEndErrorT, initT } from '../../../helpers/i18nHelpers';
import {
  customPropsToValues,
  enumToSelectorValues,
  produceValuesFilter,
  selectorValuesMap,
} from '../../../helpers/formHelpers';
import { learningQueueVariants } from '../../../constants/enums/learningQueueVariants';
import { useEffect, useMemo } from 'react';
import { learningQueueFeedOrderVariants as feedOrderVariants } from '../../../constants/enums/learningQueueFeedOrderVariants';
import { getAllNotesWorkspaces, getNotesTreeHash } from '../../../helpers/notesHelpers';
import { get, filter, reverse } from 'lodash';
import { learningQueueSourceVariants as sourceVariants } from '../../../constants/enums/learningQueueSourceVariant';
import { getSourceVariant } from '../../../helpers/model/learningQueueHelpers';
import { useNoteFromUnknownWorkspace } from '../../../hooks/useNoteFromUnknownWorkspace';

export const t = initT('pages.learning');
export const relevantAttributes = [
  'id',
  'name',
  'variant',
  'considerNestedNotes',
  'feedOrderVariant',
  'withAudio',
  'withRepetitionDelay',
  'learningWorkspaceId',
  'sourceNotesWorkspaceId',
  'sourceNoteId',
  'sourceLabelId',
  'private',
];

export const auxiliaryAttributes = ['incognitoMode', 'sourceVariant', 'auxiliaryNotesWorkspaceId', 'hideForm'];

export const filterValues = (values) => {
  const baseResult = produceValuesFilter(relevantAttributes)(values);
  const sourceVariant = get(values, 'auxiliary.sourceVariant', null);
  if (!sourceVariant) return baseResult;

  if (!sourceVariant) return baseResult;

  if (sourceVariant !== sourceVariants.label) {
    baseResult.sourceLabelId = null;
  }
  if (sourceVariant !== sourceVariants.notesWorkspace) {
    baseResult.sourceNotesWorkspaceId = null;
  }
  if (sourceVariant !== sourceVariants.note) {
    baseResult.sourceNoteId = null;
    baseResult.considerNestedNotes = false;
  }

  return baseResult;
};

export const mapPropsToValues = (props) => {
  const sourceVariant = getSourceVariant(props);

  return customPropsToValues({ ...props, sourceVariant }, filterValues, auxiliaryAttributes);
};

export const validationSchema = () =>
  yup.object().shape({
    name: yup.string().required(frontEndErrorT('learningQueue.name.empty')),
    variant: yup.string().required(frontEndErrorT('learningQueue.variant.empty')),
    feedOrderVariant: yup.string().required(frontEndErrorT('learningQueue.feedOrderVariant.empty')),
    sourceLabelId: yup.string().when('auxiliary.sourceVariant', {
      is: sourceVariants.label,
      then: yup.string().required(frontEndErrorT('learningQueue.source.empty')),
    }),
    sourceNotesWorkspaceId: yup.string().when('auxiliary.sourceVariant', {
      is: sourceVariants.notesWorkspace,
      then: yup.string().required(frontEndErrorT('learningQueue.source.empty')),
    }),
    sourceNoteId: yup.string().when('auxiliary.sourceVariant', {
      is: sourceVariants.note,
      then: yup.string().required(frontEndErrorT('learningQueue.source.empty')),
    }),
    auxiliary: yup.object().shape({
      sourceVariant: yup.string().required(frontEndErrorT('learningQueue.source.empty')),
    }),
  });

export const useSourceNote = (props) => {
  const { values, setFieldValue } = props;
  const {
    sourceNoteId,
    auxiliary: { auxiliaryNotesWorkspaceId },
  } = values;

  const sourceNote = useNoteFromUnknownWorkspace({ ...props, noteId: sourceNoteId });

  useEffect(() => {
    if (sourceNote && !auxiliaryNotesWorkspaceId)
      setFieldValue('auxiliary.auxiliaryNotesWorkspaceId', sourceNote.notesWorkspaceId);
  }, [sourceNote]);
};

export const useValuesConsistency = (props) => {
  const { values, setFieldValue } = props;
  const {
    feedOrderVariant,
    auxiliary: { sourceVariant },
  } = values;

  useEffect(() => {
    if (feedOrderVariant === feedOrderVariants.ordered && sourceVariant === sourceVariants.label) {
      setFieldValue('feedOrderVariant', feedOrderVariants.biasedRandom);
    }
  }, [sourceVariant]);
};

export const useOptions = (props) => {
  const {
    notesWorkspaces,
    notes,
    labels,
    incognitoMode,
    notesWorkspacesActionFetch,
    notesActionFetch,
    labelsActionFetch,
    values: {
      auxiliary: { auxiliaryNotesWorkspaceId, sourceVariant: _sourceVariant },
    },
  } = props;

  useEffect(() => {
    if (!labels.fetched) labelsActionFetch();
    if (!notesWorkspaces.fetched) notesWorkspacesActionFetch();
  }, []);

  useEffect(() => {
    if (auxiliaryNotesWorkspaceId && !notes.fetched(auxiliaryNotesWorkspaceId))
      notesActionFetch(auxiliaryNotesWorkspaceId);
  }, [auxiliaryNotesWorkspaceId]);

  const variant = useMemo(() => enumToSelectorValues(learningQueueVariants, 'learningQueueVariants'), []);

  const sourceVariant = useMemo(() => enumToSelectorValues(sourceVariants, 'learningQueueSourceVariants'), []);

  const feedOrderVariant = useMemo(
    () =>
      filter(
        enumToSelectorValues(feedOrderVariants, 'learningQueueFeedOrderVariants'),
        (option) => _sourceVariant !== sourceVariants.label || option.value !== feedOrderVariants.ordered,
      ),
    [_sourceVariant],
  );

  const label = useMemo(() => labels.visible(incognitoMode).map(selectorValuesMap('name')), [labels, incognitoMode]);

  const notesWorkspace = useMemo(
    () => getAllNotesWorkspaces(notesWorkspaces, incognitoMode).map(selectorValuesMap('name')),
    [notesWorkspaces, incognitoMode],
  );

  const note = useMemo(() => {
    const treeHash = getNotesTreeHash(notes, auxiliaryNotesWorkspaceId, incognitoMode);

    const result = [];
    const queue = [treeHash[null]];
    while (queue.length > 0) {
      const { note, childrenIds, nestingLevel } = queue.shift();
      reverse(childrenIds).forEach((id) => queue.unshift(treeHash[id]));

      if (nestingLevel >= 0) {
        result.push({
          value: note.id,
          label: note.name,
          nestingLevel: nestingLevel,
        });
      }
    }
    return result;
  }, [notes, auxiliaryNotesWorkspaceId, incognitoMode]);

  return {
    variant,
    sourceVariant,
    feedOrderVariant,
    notesWorkspace,
    note,
    label,
  };
};
