import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { notificationT } from '../../../helpers/i18nHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { push } from 'connected-react-router';
import { notesWorkspacesActionUnset, notesWorkspacesActionUnlockElement } from '../../actions/notesWorkspacesActions';

export default function* deleteElementNotesWorkspacesSaga(action) {
  const initialWorkspace = action.payload;
  const { ok, data } = yield call(api.notesWorkspaces.delete, initialWorkspace.id);
  if (ok) {
    yield put(push('/notes/workspaces'));
    yield put(snackbarMessagesActionAdd(notificationT('notesWorkspaces.delete.success')));
    yield put(notesWorkspacesActionUnset(initialWorkspace));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(notesWorkspacesActionUnlockElement(initialWorkspace));
  }
}
