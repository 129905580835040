import React, { useMemo } from 'react';
import AuxiliaryText from '../../shared/AuxiliaryText';
import SubSectionTitle from '../../shared/titles/SubSectionTitle';
import Clickable from '../../shared/Clickable';

const TopInfoBox = (props) => {
  const {
    workspace = {},
    notesPath = [],
    heading = {},
    // for better display on PDF
    headingTitlePaddingClass = '',
  } = props;

  const notesPresentation = useMemo(
    () =>
      notesPath.map((note, index) => (
        <span key={note.id}>
          {index !== 0 ? <span>{' > '}</span> : <></>}
          <Clickable hoverOpacity to={`/notes/${note.id}`}>
            <b>{note.name}</b>
          </Clickable>
        </span>
      )),
    [notesPath],
  );

  return (
    <div className="w-full h-full overflow-x-hidden flex items-end">
      <div className="w-full">
        <div className="w-full flex justify-start whitespace-nowrap mb-1">
          <AuxiliaryText widthClass="w-auto">{workspace.name}</AuxiliaryText>
        </div>
        <div className="w-full flex justify-start whitespace-nowrap mb-1">
          <AuxiliaryText widthClass="w-auto">{notesPresentation}</AuxiliaryText>
        </div>
        <SubSectionTitle marginClass={headingTitlePaddingClass}>{heading.content}</SubSectionTitle>
      </div>
    </div>
  );
};

export default TopInfoBox;
