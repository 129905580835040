import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { nodesActionFetch } from '../../../stateManagement/actions/nodesActions';
import NodesGroupPanel from '../../../shared/features/nodesGroupPanel';
import LockedEntityLoader from '../../../shared/loaders/LockedEntityLoader';
import MainTopSpace from '../../../shared/organizers/MainTopSpace';
import FullPageNoData from '../../../shared/placeholders/FullPageNoData';
import useNarrowMainWidth from '../../../hooks/useNarrowMainWidth';
import LabelledButton from '../../../shared/buttons/LabelledButton';
import Loader from '../../../shared/loaders/Loader';
import NodesGroupPdfDisplay from '../../../shared/features/nodesGroupPanel/NodesGroupPdfDisplay';
import { useCheckIfMounted } from '../../../hooks/useCheckIfMounted';
import { pdfDefaultOptions, getPdfGenerator, prepareImagesToPdfGeneration } from '../../../helpers/pdfHelpers';
import { snackbarMessagesActionAddDefaultError } from '../../../stateManagement/actions/snackbarMessagesActions';
import OnClickOutsideWrapper from '../../../shared/onClickOutside/OnClickOutsideWrapper';
import { nodeVariants } from '../../../constants/nodeVariants';

const Main = (props) => {
  const {
    note: { id, name, nodesGroupId },
    nodes,
    nodesActionFetch,
    editionMode,
    setEditionMode,
    pdfGenerating,
    setPdfGenerating,
    snackbarMessagesActionAddDefaultError: displayError,
  } = props;

  useEffect(() => {
    if (nodesGroupId !== undefined && !nodes.fetched(nodesGroupId)) nodesActionFetch(nodesGroupId);
  }, [nodesGroupId, nodes.fetched(nodesGroupId)]);
  const checkIfMounted = useCheckIfMounted();

  let nodesCount;
  if (nodes.fetched(nodesGroupId)) {
    nodesCount = nodes.filter({ nodesGroupId: nodesGroupId }).length;
  }

  useEffect(() => {
    if (nodesCount === 0 && !editionMode) setEditionMode(true);
    if (nodesCount > 0 && editionMode) setEditionMode(false);
  }, [nodes.fetched(nodesGroupId), nodesGroupId]);

  const width = useNarrowMainWidth();

  const onClickOutside = () => {
    if (editionMode && nodesCount > 0) setTimeout(() => setEditionMode(false));
  };

  const initPdfGeneration = useCallback(() => {
    setPdfGenerating(true);
  }, []);
  const generatePdf = useCallback(
    (element) => {
      if (!checkIfMounted()) return;
      if (!pdfGenerating) return;

      prepareImagesToPdfGeneration(element);
      getPdfGenerator().html(element, {
        ...pdfDefaultOptions,
        margin: 10,
        callback: (pdf) => {
          pdf.save(`${name}.pdf`);

          // Currently, second run of the generation causes problems probably related
          // to the lib.
          // Once it is fixed, reload should be replaced with setPdfGenerating(false).
          location.reload();
        },
      });
    },
    [pdfGenerating],
  );
  const onPdfGenerationError = useCallback(() => {
    setPdfGenerating(false);
    displayError();
  }, [pdfGenerating]);

  if (!id) return <FullPageNoData />;

  return (
    <>
      {pdfGenerating ? (
        <div className="fixed left-full">
          <NodesGroupPdfDisplay
            key={nodesGroupId}
            title={name}
            nodesGroupId={nodesGroupId}
            onReady={generatePdf}
            onError={onPdfGenerationError}
          />
        </div>
      ) : (
        <></>
      )}
      <MainTopSpace>
        {pdfGenerating ? (
          <Loader sizeClass="w-7 h-7 mr-25px" />
        ) : (
          <LabelledButton
            variant="pdf"
            disabled={!nodes.fetched(nodesGroupId)}
            onMouseDown={initPdfGeneration}
            wrapperClass={editionMode ? 'invisible' : ''}
          />
        )}
      </MainTopSpace>
      <div className="w-full flex justify-center pb-25px">
        <div style={{ width }}>
          <OnClickOutsideWrapper onClickOutside={onClickOutside}>
            <LockedEntityLoader visible={!nodes.fetched(nodesGroupId)}>
              <NodesGroupPanel
                nodesGroupId={nodesGroupId}
                editionMode={editionMode}
                audioEnabledForVariants={[nodeVariants.text, nodeVariants.image]}
                labelsEnabledForVariants={[nodeVariants.text, nodeVariants.image]}
              />
            </LockedEntityLoader>
          </OnClickOutsideWrapper>
        </div>
      </div>
    </>
  );
};

export default connect(prepareState(['nodes']), {
  nodesActionFetch,
  snackbarMessagesActionAddDefaultError,
})(Main);
