import React from 'react';
import { aggregationMethods } from '../../constants/enums/aggregationMethods';
import isEmpty from 'lodash/isEmpty';
import AuxiliaryText from '../../shared/AuxiliaryText';
import { enumT, initT } from '../../helpers/i18nHelpers';
import { habitHidingConditionVariants } from '../../constants/enums/habitHidingConditionVariants';
import isNil from 'lodash/isNil';
import find from 'lodash/find';

const t = initT('pages.calendar.habitAnswers');

const aggregationMethodT = (aggregationMethod) => enumT(`aggregationMethods.${aggregationMethod}`);

const summaryT = (summaryRequest) => {
  if (summaryRequest.variant === habitHidingConditionVariants.sinceWeekday) {
    const weekday = enumT(`weekdayTypes.${summaryRequest.weekdayType}`);
    return t('summaries.variants.sinceWeekday', { weekday });
  } else if (summaryRequest.variant === habitHidingConditionVariants.sinceCustomPeriod) {
    const props = { daysCounter: summaryRequest.customPeriodDaysCounter };
    return t('summaries.variants.sinceCustomPeriod', props);
  } else {
    return t(`summaries.variants.${summaryRequest.variant}`);
  }
};

const prepareSummaryValue = (summaryValue) =>
  isNil(summaryValue) ? 'N/A' : Math.round(parseFloat(summaryValue) * 100) / 100;

const HabitSummaries = (props) => {
  const { habit, habitSummaries } = props;

  const sumSummaries = [];
  const averageSummaries = [];

  habitSummaries.forEach((summary) => {
    const request = find(habit.habitSummaryRequests, { id: summary.summaryRequestId }) || {};
    const { aggregationMethod } = request;
    const parsedSummary = { request, summaryValue: summary.summaryValue, aggregationMethod };
    if (aggregationMethod === aggregationMethods.valuesSum) sumSummaries.push(parsedSummary);
    else if (aggregationMethod === aggregationMethods.valuesAverage) averageSummaries.push(parsedSummary);
  });

  return (
    <div className="w-full">
      {[
        [sumSummaries, aggregationMethods.valuesSum],
        [averageSummaries, aggregationMethods.valuesAverage],
      ].map(({ 0: summaries, 1: aggregationMethod }) => {
        if (isEmpty(summaries)) return null;
        return (
          <div key={aggregationMethod} className="w-full first:mt-4 mt-2">
            {summaries.length > 0 && (
              <AuxiliaryText>
                <div className="w-full text-xs">
                  <div>{aggregationMethodT(aggregationMethod)}</div>
                  {summaries.map(({ request, summaryValue }) => (
                    <div key={request.id} className="mt-1">
                      <span>• {summaryT(request)}: </span>
                      <b>{prepareSummaryValue(summaryValue)}</b>
                    </div>
                  ))}
                </div>
              </AuxiliaryText>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default HabitSummaries;
