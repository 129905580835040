import sortBy from 'lodash/sortBy';
import { nodeVariants } from '../../../constants/nodeVariants';

export const getTopLevelConsts = (props) => {
  const { editionMode = false, nodesGroupId, nodes, incognitoMode } = props;

  const visibleNodes = sortBy(nodes.visible(incognitoMode, { nodesGroupId }), 'defaultOrder');

  const determineMarginClass = (node, handleLast = true) => {
    if (editionMode) return 'pt-12';

    let variablePart = 'pb-25px';

    if (node.variant === nodeVariants.heading) variablePart = 'py-3';

    return `${variablePart} ${handleLast ? 'last:pb-0' : ''}`;
  };

  return {
    visibleNodes,
    determineMarginClass,
  };
};
