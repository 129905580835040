import frenchkiss from 'frenchkiss';

frenchkiss.locale('en');

import './common';
import './confirmations';
import './enums';
import './notifications';
import './frontEndErrors';
import './features/audioController';
import './features/taskCreator';
import './features/taskPanel';
import './features/eventPanel';
import './features/eventCreator';
import './features/labelsSelector';
import './features/nodesGroupPanel';
import './modals/audioSelectionModal';
import './modals/habitHidingConditionModal';
import './modals/habitSummaryRequestModal';
import './modals/habitChartRequestModal';
import './modals/dayEvaluationsChartModal';
import './modals/calendarDayModal';
import './pages/quickPass';
import './pages/tasks';
import './pages/calendar';
import './pages/monthlyCalendar';
import './pages/finder';
import './pages/habits';
import './pages/notes';
import './pages/notesWorkspaces';
import './pages/learningWorkspaces';
import './pages/learning';
import './pages/languageWorkspaces';
import './pages/language';
import './pages/other';
import './pages/wasteBin';
import './pages/error/default';
import './pages/error/404';
