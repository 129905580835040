import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { categoriesActionFetch } from '../../../stateManagement/actions/categoriesActions';
import { tasksActionFetch, tasksActionGeneratedLookup } from '../../../stateManagement/actions/tasksActions';
import StandardContentTemplate from '../../../shared/contentTemplates/StandardContentTemplate/index';
import Right from './Right';
import Left from './Left';
import Top from './Top';
import Tasks from './Tasks';
import { useHistory, useParams } from 'react-router-dom';
import { getVisibleCategories } from '../../../helpers/categoryHelpers';
import find from 'lodash/find';
import { currentDateInBackendFormat } from '../../../helpers/dateTimeHelpers';

const TasksIndexPage = (props) => {
  const { categories, tasks, categoriesActionFetch, tasksActionFetch, incognitoMode, tasksActionGeneratedLookup } =
    props;

  const { taskCategoryId } = useParams();
  const history = useHistory();

  const currentDate = currentDateInBackendFormat();
  useEffect(() => {
    if (tasks.fetched) tasksActionGeneratedLookup(currentDate);
  }, [tasks.fetched, currentDate]);

  useEffect(() => {
    if (!categories.fetched) categoriesActionFetch();
    if (!tasks.fetched) tasksActionFetch();
  }, []);

  let category;
  if (categories.fetched) {
    const visibleCategories = getVisibleCategories(categories, incognitoMode);
    category = find(visibleCategories, { id: parseInt(taskCategoryId, 10) });
    if (!category && visibleCategories.length > 0)
      // a bit hacky without setTimeout, but it makes the UX better
      history.replace(`/tasks/${visibleCategories[0].id}`);
  }

  return (
    <StandardContentTemplate
      topReady={categories.fetched && tasks.fetched}
      top={!!category && <Top category={category} />}
      leftReady={categories.fetched}
      left={<Left activeCategory={category} />}
      mainReady={categories.fetched && tasks.fetched}
      main={<Tasks category={category} />}
      rightReady={tasks.fetched}
      right={<Right />}
    />
  );
};

export default connect(prepareState(['categories', 'tasks', 'incognitoMode']), {
  categoriesActionFetch,
  tasksActionFetch,
  tasksActionGeneratedLookup,
})(TasksIndexPage);
