import React, { useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import ThemeCrumb from './ThemeCrumb';
import { supportsHover } from '../helpers/platformHelpers';
import { useCheckIfMounted } from '../hooks/useCheckIfMounted';

const TooltipWrapper = (props) => {
  const { children, tooltip: tooltipText, widthClass = 'w-auto', className = '' } = props;

  if (!supportsHover()) return children;

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [hidden, setHidden] = useState(true);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'offset', options: { offset: [0, 2] } }],
  });
  const dirtyHack = useMemo(() => ({ mouseOver: false }), []);
  const checkIfMounted = useCheckIfMounted();

  const show = () => {
    dirtyHack.mouseOver = true;
    setTimeout(() => {
      if (!referenceElement || !dirtyHack.mouseOver || !checkIfMounted()) return;
      setHidden(false);
    }, 700);
  };

  const hide = () => {
    dirtyHack.mouseOver = false;
    setHidden(true);
  };

  const tooltipClass = `tooltip px-1 rounded text-sm z-50 opacity-90 ${hidden ? 'hidden' : ''}`;

  return (
    <>
      {/* flex necessary to maintain height */}
      <div
        className={`flex ${widthClass} ${className}`}
        ref={setReferenceElement}
        onMouseEnter={show}
        onMouseLeave={hide}
        onFocus={show}
        onBlur={hide}
      >
        {children}
      </div>
      {!hidden &&
        ReactDOM.createPortal(
          <ThemeCrumb type="tooltip">
            <div className={tooltipClass} ref={setPopperElement} style={{ ...styles.popper }} {...attributes.popper}>
              {tooltipText}
            </div>
          </ThemeCrumb>,
          document.querySelector('#tooltip-portal'),
        )}
    </>
  );
};

export default TooltipWrapper;
