import React, { useCallback } from 'react';
import SmartForm from '../../../form/SmartForm';
import { withFormik } from 'formik';
import { initT } from '../../../../helpers/i18nHelpers';
import { connect } from 'react-redux';
import { customPropsToValues, enumToSelectorValues, produceValuesFilter } from '../../../../helpers/formHelpers';
import isEmpty from 'lodash/isEmpty';
import ModalFooter from '../../ModalFooter';
import { activeElementsActionDeactivate } from '../../../../stateManagement/actions/activeElementsActions';
import { activeElementTypes } from '../../../../constants/activeElementTypes';
import HabitHidingCondition from '../../../../models/habitHidingCondition';
import CounterField from '../../../form/fieldTypes/CounterField';
import RadioField from '../../../form/fieldTypes/RadioField';
import SmartField from '../../../form/SmartField';
import { habitHidingConditionVariants } from '../../../../constants/enums/habitHidingConditionVariants';
import { weekdayTypes } from '../../../../constants/enums/weekdayTypes';
import ModalHeader from '../../ModalHeader';
import ModalBody from '../../ModalBody';
import LabelledButton from '../../../buttons/LabelledButton';
import SelectorField from '../../../form/fieldTypes/SelectorField';

const t = initT('modals.habitHidingConditionModal');

const relevantAttributes = ['timesRequired', 'variant', 'weekdayType', 'customPeriodDaysCounter'];
const filterValues = produceValuesFilter(relevantAttributes);

const HabitHidingConditionInnerForm = (props) => {
  const { errors, handleSubmit, activeElementsActionDeactivate: deactivate } = props;
  const hideModal = useCallback(() => deactivate(activeElementTypes.habitHidingConditionModal, null), [deactivate]);
  const weekdayTypeOptions = enumToSelectorValues(weekdayTypes, 'weekdayTypes');
  return (
    <SmartForm>
      <ModalHeader type={activeElementTypes.habitHidingConditionModal} identifier={null}>
        {t('title')}
      </ModalHeader>
      <ModalBody>
        <div className="max-w-fit-content min-w-48">
          <div className="flex items-center">
            <div>{t('labels.hideIf')}</div>
            <div className="my-0 mx-2 w-36">
              <SmartField name="timesRequired" min={1} component={CounterField} />
            </div>
            <div>{t('labels.times')}</div>
          </div>
          <div className="mt-25px">
            <SmartField
              name="variant"
              component={RadioField}
              radioValue={habitHidingConditionVariants.sinceWeekday}
              label={
                <div className="flex flex-nowrap items-center">
                  <div>{t('labels.sinceLast')}</div>
                  <div className="mt-0 ml-2 w-36">
                    <SmartField
                      name="weekdayType"
                      options={weekdayTypeOptions}
                      component={SelectorField}
                      leftPadding={false}
                    />
                  </div>
                </div>
              }
            />
          </div>
          <div className="mt-25px">
            <SmartField
              name="variant"
              component={RadioField}
              radioValue={habitHidingConditionVariants.sinceCustomPeriod}
              label={
                <div className="flex flex-nowrap items-center">
                  <div>{t('labels.duringLast')}</div>
                  <div className="my-0 mx-2 w-36">
                    <SmartField name="customPeriodDaysCounter" min={1} component={CounterField} />
                  </div>
                  <div>{t('labels.days')}</div>
                </div>
              }
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <LabelledButton variant="cancel" onMouseDown={hideModal} />
        <LabelledButton variant="ok" disabled={!isEmpty(errors)} onMouseDown={handleSubmit} />
      </ModalFooter>
    </SmartForm>
  );
};

const HabitHidingConditionForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: (props) => customPropsToValues(props, filterValues),
  handleSubmit: (values, formikBag) => {
    const { addElement, activeElementsActionDeactivate } = formikBag.props;
    const habitHidingCondition = new HabitHidingCondition().assignValues(filterValues(values));
    addElement(habitHidingCondition);
    activeElementsActionDeactivate(activeElementTypes.habitHidingConditionModal, null);
  },
})(HabitHidingConditionInnerForm);

export default connect(null, { activeElementsActionDeactivate })(HabitHidingConditionForm);
