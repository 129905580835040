import React, { useCallback } from 'react';
import { customColors } from '../../../constants/customColors';
import ColorOption from '../components/selector/colorParts/ColorOption';
import ThemeCrumb from '../../ThemeCrumb';

const colorOptions = (() => {
  const result = [];
  Object.keys(customColors).forEach((key) => {
    result.push({
      label: key,
      value: key,
    });
  });
  return result;
})();

const defaultOnChange = async (props) => {
  const {
    newValue,
    field: { name },
    form: { setFieldValue, setFieldTouched },
  } = props;
  await setFieldValue(name, newValue);
  await setFieldTouched(name);
};

const ColorVerboseField = (props) => {
  const {
    themeType = 'input',
    disabled = false,
    presentationMode = false,
    onChange = (onChangeProps, _defaultOnChange) => _defaultOnChange(onChangeProps),
    field: { value: selectedValue },
  } = props;

  const onSelect = useCallback((value) => {
    onChange({ ...props, newValue: value }, defaultOnChange);
  }, []);

  return (
    <ThemeCrumb type={themeType}>
      <div className="grid grid-cols-7 sm:grid-cols-10 gap-2 p-2 border rounded-md">
        {colorOptions.map(({ label, value }) => (
          <div key={value} className="w-full flex justify-center">
            <ColorOption
              label={label}
              value={value}
              onSelect={onSelect}
              selectedValue={selectedValue}
              disabled={disabled || presentationMode}
            />
          </div>
        ))}
      </div>
    </ThemeCrumb>
  );
};

export default ColorVerboseField;
