import React from 'react';
import { customColors } from '../../../constants/customColors';
import ColorOption from '../components/selector/colorParts/ColorOption';
import ColorMenu from '../components/selector/colorParts/ColorMenu';
import ColorTrigger from '../components/selector/colorParts/ColorTrigger';
import SelectorField from './SelectorField';

const colorOptions = (() => {
  const result = [];
  Object.keys(customColors).forEach((key) => {
    result.push({
      label: key,
      value: key,
    });
  });
  return result;
})();

const ColorField = (props) => (
  <SelectorField
    {...props}
    options={colorOptions}
    optionComponent={ColorOption}
    menu={<ColorMenu />}
    trigger={<ColorTrigger />}
    fixedMenuWidth={false}
  />
);

export default ColorField;
