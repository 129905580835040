import React, { createRef, PureComponent } from 'react';

export default class MovementAnimator extends PureComponent {
  constructor(props) {
    super(props);
    const refs = {};
    props.children.forEach((child) => {
      refs[child.key] = createRef();
    });
    this.state = { refs, prevRects: {} };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const refs = {};
    const prevRects = {};

    const childrenKeys = nextProps.children.map((child) => child.key);

    childrenKeys.forEach((key) => {
      refs[key] = prevState.refs[key] || createRef();
    });

    Object.keys(refs).forEach((key) => {
      if (!childrenKeys.includes(key)) delete refs[key];
    });

    Object.entries(refs).forEach(([key, { current }]) => {
      if (current) prevRects[key] = current.getBoundingClientRect();
    });
    return {
      ...prevState,
      refs,
      prevRects,
    };
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(previousProps, prevState, snapshot) {
    const { xAxis = true, yAxis = true, allowOutsideViewport = false } = this.props;
    const { prevRects, refs } = this.state;
    Object.keys(prevRects).forEach((key) => {
      const currentRef = refs[key].current;
      if (!currentRef) return;

      const prevRect = prevRects[key];
      const currentRect = currentRef.getBoundingClientRect();

      const bodyWidth = document.body.clientWidth;
      const prevXCenter = prevRect.left + prevRect.width / 2;
      const currentXCenter = currentRect.left + currentRect.width / 2;
      let deltaX = prevXCenter - currentXCenter;
      if (!allowOutsideViewport) {
        if (currentRect.left + deltaX < 0) {
          deltaX = -currentRect.left;
        } else if (currentRect.right + deltaX > bodyWidth && deltaX !== 0) {
          deltaX = bodyWidth - currentRect.right;
        }
      }

      const bodyHeight = document.body.clientHeight;
      const prevYCenter = prevRect.top + prevRect.height / 2;
      const currentYCenter = currentRect.top + currentRect.height / 2;
      let deltaY = prevYCenter - currentYCenter;
      if (!allowOutsideViewport) {
        if (currentRect.top + deltaY < 0) {
          deltaY = -currentRect.top;
        } else if (currentRect.bottom + deltaY > bodyHeight && deltaY !== 0) {
          deltaY = bodyHeight - currentRect.bottom;
        }
      }

      const translationX = xAxis ? deltaX : 0;
      const translationY = yAxis ? deltaY : 0;

      requestAnimationFrame(() => {
        currentRef.style.transform = `translate(${translationX}px, ${translationY}px)`;
        currentRef.style.transition = 'transform 0s';

        requestAnimationFrame(() => {
          currentRef.style.transform = '';
          currentRef.style.transition = 'transform 500ms';
        });
      });
    });
  }

  render() {
    const { children } = this.props;
    const { refs } = this.state;
    return children.map((child) => React.cloneElement(child, { ref: refs[child.key] }));
  }
}
