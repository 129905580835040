import Task from '../../models/task';
import { produceModelFromDto, produceModelToDto } from './modelHelpers';

const mappings = [
  ['id', 'id'],
  ['category_id', 'categoryId'],
  ['nodes_group_id', 'nodesGroupId'],
  ['description', 'description'],
  ['todo', 'todo'],
  ['default_order', 'defaultOrder'],
  ['todo_order', 'todoOrder'],
  ['todo_from', 'todoFrom'],
  ['private', 'private'],
  ['checked', 'checked'],
  ['checked_date', 'checkedDate'],
];

export const taskFromDto = produceModelFromDto(Task, mappings);

export const taskToDto = produceModelToDto(mappings);
