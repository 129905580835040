import { Editor, Element, Range, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import take from 'lodash/take';
import last from 'lodash/last';
import reduce from 'lodash/reduce';
import isEqual from 'lodash/isEqual';
import { initT } from '../../../../../../helpers/i18nHelpers';
import { getToolbarActiveLeavesVariants } from './leafVariantHelpers';
import { nestedElementVariants } from '../constants/nestedElementVariants';

const t = initT('textEditor');

export const isLinkNodeAtSelection = (editor) => {
  if (editor.selection == null) return null;
  for (let [node] of Editor.levels(editor)) {
    if (node.type === 'link') return true;
  }
  return false;
};

export const getLinkAt = (editor, at) => {
  for (let [node] of Editor.levels(editor, { at })) {
    if (node.type === 'link') return node;
  }
  return null;
};

export const getSelectedLinkNode = (editor) => {
  if (editor.selection == null) return null;
  return getLinkAt(editor, editor.selection);
};

export const selectionContainsLink = (editor) =>
  !!Editor.nodes(editor, { match: (n) => n.type === 'link' }).next().value;

export const isEndOfLink = (editor, linkNodeAtSelection = isLinkNodeAtSelection(editor)) => {
  if (!linkNodeAtSelection || !Range.isCollapsed(editor.selection)) return false;

  const {
    anchor,
    anchor: { path },
  } = editor.selection;
  const nextPoint = Editor.after(editor, anchor);
  if (!nextPoint) return true;

  const { path: nextPath } = nextPoint;
  return !isEqual(take(path, path.length - 1), take(nextPath, nextPath.length - 1));
};

export const getNewBlankTextNode = (editor, text = '') => {
  const toolbarActiveLeavesVariants = getToolbarActiveLeavesVariants(editor);
  const result = { text };
  for (let variantName of toolbarActiveLeavesVariants.values()) result[variantName] = true;
  return result;
};

export const getNewBlankLink = (editor, text = '') => {
  return {
    type: nestedElementVariants.link,
    url: t('linkUrlPlaceholder'),
    children: [getNewBlankTextNode(editor, text)],
  };
};

export const toggleLink = (editor) =>
  Editor.withoutNormalizing(editor, () => {
    const { selection } = editor;
    if (!selection) return;

    const linkNodeAtSelection = isLinkNodeAtSelection(editor);

    const linkFeatureDisabled = !linkNodeAtSelection && selectionContainsLink(editor);
    if (linkFeatureDisabled) return;

    const endOfLink = isEndOfLink(editor, linkNodeAtSelection);
    if (endOfLink) {
      const newNode = { text: '' };
      const activeVariants = Array.from(getToolbarActiveLeavesVariants(editor));
      activeVariants.forEach((variant) => {
        newNode[variant] = true;
      });
      Transforms.move(editor, { unit: 'offset' });
      editor.enableNormalization = false;
      Transforms.insertNodes(editor, newNode);
      setTimeout(() => {
        ReactEditor.focus(editor);
        editor.enableNormalization = true;
      }, 100);
      return;
    }

    if (isLinkNodeAtSelection(editor)) {
      const { anchor: previousAnchor } = editor.selection;

      const link = getLinkAt(editor, previousAnchor);
      const offset = reduce(
        link.children,
        (sum, node) => {
          let addend = 0;
          if (node.text) addend = node.text.length;
          return sum + addend;
        },
        0,
      );

      const linkPath = take(previousAnchor.path, previousAnchor.path.length - 1);
      const newAnchor = Editor.before(editor, Editor.start(editor, linkPath));

      Transforms.unwrapNodes(editor, {
        match: (n) => Element.isElement(n) && n.type === nestedElementVariants.link,
      });

      const newFocus = Editor.after(editor, newAnchor, { distance: offset, unit: 'character' });
      Transforms.select(editor, {
        anchor: newAnchor,
        focus: newFocus,
      });
    } else {
      const isSelectionCollapsed = Range.isCollapsed(selection);

      if (isSelectionCollapsed) {
        const newLinkTextDefault = t('linkTextDefault');

        Transforms.insertNodes(editor, getNewBlankLink(editor, newLinkTextDefault));

        const {
          anchor: { path },
        } = selection;
        const newPath = take(path, path.length - 1);

        const currentBlock = editor.children;
        let newPath2ndIndex = last(path) + 1;
        const check2ndIndex = (value) => {
          if (value < 0) return false;
          const leaves = (currentBlock[newPath[0]] || {}).children;
          if (!leaves || !leaves[value]) return false;
          return leaves[value].type === 'link';
        };
        if (!check2ndIndex(newPath2ndIndex)) newPath2ndIndex -= 1;
        if (!check2ndIndex(newPath2ndIndex)) newPath2ndIndex += 2;
        if (!check2ndIndex(newPath2ndIndex)) return;
        newPath.push(newPath2ndIndex);
        newPath.push(0);
        const focusOffset = newLinkTextDefault.length;

        Transforms.select(editor, {
          anchor: { path: newPath, offset: 0 },
          focus: { path: newPath, offset: focusOffset },
        });
      } else {
        Transforms.wrapNodes(editor, getNewBlankLink(editor), { split: true });

        const { anchor: newAnchor, focus: newFocus } = editor.selection;
        Transforms.select(editor, {
          anchor: newAnchor,
          focus: newFocus,
        });
      }
    }
    setTimeout(() => ReactEditor.focus(editor), 0);
  });
