import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { initT } from '../../../helpers/i18nHelpers';
import List from '../../../shared/lists/List';
import { draggedElements } from '../../../constants/draggedElements';
import LockedEntityLoader from '../../../shared/loaders/LockedEntityLoader';
import SidebarButton from '../../../shared/buttons/SidebarButton';
import { getVisibleCategories } from '../../../helpers/categoryHelpers';
import MenuTitle from '../../../shared/titles/MenuTitle';
import MenuContent from '../../../shared/organizers/MenuContent';
import LabelledButton from '../../../shared/buttons/LabelledButton';
import { categoriesActionCreateElement } from '../../../stateManagement/actions/categoriesActions';
import Category from '../../../models/category';
import { customColors } from '../../../constants/customColors';
import CustomColorCrumb from '../../../shared/CustomColorCrumb';
import { useCheckIfMounted } from '../../../hooks/useCheckIfMounted';

const t = initT('pages.tasks');

const getUniqueName = (categories) => {
  const nameBase = t('category.newCategoryName');
  let newSectionName = nameBase;
  let counter = 1;
  while (categories.find({ name: newSectionName })) {
    newSectionName = `${nameBase} ${counter}`;
    counter += 1;
  }
  return newSectionName;
};

const getUniqueColor = (categories) => {
  const colorNames = Object.keys(customColors);
  let result = colorNames[0];
  for (let colorName of colorNames) {
    if (!categories.find({ color: colorName })) return colorName;
  }
  return result;
};

const Left = (props) => {
  const { activeCategory = {}, categories, incognitoMode, categoriesActionCreateElement: createCategory } = props;
  const visibleCategories = getVisibleCategories(categories, incognitoMode);

  const [creationEnabled, setCreationEnabled] = useState(true);
  const checkIfMounted = useCheckIfMounted();
  const createNewCategory = useCallback(() => {
    setCreationEnabled(false);
    const newCategory = new Category();

    newCategory.name = getUniqueName(categories);
    newCategory.color = getUniqueColor(categories);

    createCategory(newCategory);
    setTimeout(() => {
      if (checkIfMounted()) setCreationEnabled(true);
    }, 1000);
  }, [createCategory, categories]);

  const getListElement = (category) => (
    <LockedEntityLoader key={category.id} visible={category.locked}>
      <SidebarButton
        selected={activeCategory.id === category.id}
        to={`/tasks/${category.id}`}
        bullet={
          <CustomColorCrumb color={category.color} styleKeys={['color']}>
            <span className="pr-2">◉</span>
          </CustomColorCrumb>
        }
      >
        {category.name}
      </SidebarButton>
    </LockedEntityLoader>
  );

  return (
    <MenuContent
      top={
        <>
          <MenuTitle marginClass="mx-25px py-25px">{t('category.title')}</MenuTitle>
          <div className="pt-25px px-25px pb-4 flex justify-center">
            <LabelledButton
              variant="add"
              onMouseDown={createNewCategory}
              disabled={categories.locked || !creationEnabled}
            >
              {t('category.add')}
            </LabelledButton>
          </div>
        </>
      }
      main={
        <div className="w-full px-25px pb-25px">
          <List collection={visibleCategories} draggable={{ draggedElementType: draggedElements.category }}>
            {getListElement}
          </List>
        </div>
      }
    />
  );
};

export default connect(prepareState(['categories', 'incognitoMode']), { categoriesActionCreateElement })(Left);
