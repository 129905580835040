import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { sessionActionLookup } from '../../actions/sessionActions';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { notificationT } from '../../../helpers/i18nHelpers';

export default function* sessionSignInSaga(action) {
  const { ok } = yield call(api.users.sessions.create.google, action.payload);
  if (ok) {
    yield put(snackbarMessagesActionAdd(notificationT('session.signIn.success')));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors());
  }
  yield put(sessionActionLookup());
}
