import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import {
  languageWorkspacesActionCreateElement,
  languageWorkspacesActionFetch,
} from '../../../stateManagement/actions/languageWorkspacesActions';
import StandardContentTemplate from '../../../shared/contentTemplates/StandardContentTemplate';
import { initT } from '../../../helpers/i18nHelpers';
import { PageTitle } from '../../../shared/titles/PageTitle';
import Main from './Main';
import { getAllLanguageWorkspaces } from '../../../helpers/languageHelpers';

const t = (editionMode) => initT(`pages.languageWorkspaces.${editionMode ? 'edition' : 'selection'}`);

const LanguageWorkspacesIndexPage = (props) => {
  const {
    incognitoMode,
    languageWorkspaces,
    languageWorkspacesActionFetch,
    languageWorkspacesActionCreateElement: createWorkspace,
  } = props;

  useEffect(() => {
    if (!languageWorkspaces.fetched) languageWorkspacesActionFetch();
  }, []);

  const [editionMode, setEditionMode] = useState(false);

  let visibleWorkspaces = [];
  if (languageWorkspaces.fetched) {
    visibleWorkspaces = getAllLanguageWorkspaces(languageWorkspaces, incognitoMode);
  }

  useEffect(() => {
    if (visibleWorkspaces.length === 0 && languageWorkspaces.fetched) setEditionMode(true);
  }, [languageWorkspaces.fetched, visibleWorkspaces.length]);

  const _setEditionMode = (newValue) => {
    if (visibleWorkspaces.length === 0) setEditionMode(true);
    else setEditionMode(newValue);
  };

  return (
    <StandardContentTemplate
      top={<PageTitle>{t(editionMode)('title')}</PageTitle>}
      mainReady={languageWorkspaces.fetched}
      main={
        <Main
          visibleWorkspaces={visibleWorkspaces}
          editionMode={editionMode}
          setEditionMode={_setEditionMode}
          createWorkspace={createWorkspace}
        />
      }
    />
  );
};

export default connect(prepareState(['incognitoMode', 'languageWorkspaces']), {
  languageWorkspacesActionFetch,
  languageWorkspacesActionCreateElement,
})(LanguageWorkspacesIndexPage);
