import frenchkiss from 'frenchkiss';

frenchkiss.extend('en', {
  modals: {
    habitHidingConditionModal: {
      title: 'New hiding condition',
      labels: {
        hideIf: 'Hide if happened',
        times: 'time(s):',
        sinceLast: 'since last',
        duringLast: 'during preceding',
        days: 'day(s)',
      },
      submit: 'Submit',
    },
  },
});
