import React from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../helpers/stateManagementHelpers';
import Collection from '../../models/auxiliary/collection';
import LockedEntityLoader from '../../shared/loaders/LockedEntityLoader';
import HabitChart from '../../shared/charts/habitChart';
import { activeElementsActionActivate } from '../../stateManagement/actions/activeElementsActions';
import { activeElementTypes } from '../../constants/activeElementTypes';

const ChartPanel = (props) => {
  const { date, habit, chartRequest, habitChartsData, activeElementsActionActivate } = props;

  const chartRequestId = chartRequest.id.toString();

  const chartDataForDate = habitChartsData[date] || {};
  const chartDataForHabit = chartDataForDate[habit.id] || new Collection();
  const chartData = chartDataForHabit.find({
    habitChartRequestId: chartRequestId,
  });

  const openModal = () =>
    activeElementsActionActivate(activeElementTypes.habitChartModal, `${date}-${chartRequestId}`, {
      date,
      habitId: habit.id,
      chartRequestId,
    });

  if (!chartData)
    return (
      <div className="h-200px flex justify-center items-center">
        <LockedEntityLoader />
      </div>
    );

  return (
    <div className="p-4">
      <HabitChart habit={habit} habitChartRequest={chartRequest} chartData={chartData} onMouseDown={openModal} />
    </div>
  );
};

export default connect(prepareState(['habitChartsData']), { activeElementsActionActivate })(ChartPanel);
