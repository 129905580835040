import React, { useState } from 'react';
import ThemeCrumb from '../ThemeCrumb';
import useResizeEffect from '../../hooks/useResizeEffect';

const ModalBody = ({ children, modalHeaderHeight = '75px', modalFooterHeight = '75px', maxBodyHeight }) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  useResizeEffect(() => {
    setWindowHeight(window.innerHeight);
  });

  if (!maxBodyHeight) {
    maxBodyHeight = `calc(${windowHeight * 0.9 - 10}px - ${modalHeaderHeight} - ${modalFooterHeight})`;
  }

  return (
    <ThemeCrumb type="modalBody">
      <div className="p-1">
        <div className="overflow-y-auto overscroll-contain" style={{ maxHeight: maxBodyHeight }}>
          <ThemeCrumb type="modalBody">
            <div className="p-25px">{children}</div>
          </ThemeCrumb>
        </div>
      </div>
    </ThemeCrumb>
  );
};

export default ModalBody;
