import React from 'react';
import IconButton from '../../buttons/IconButton';
import ThemeCrumb from '../../ThemeCrumb';

const CollectionElementField = (props) => {
  const { themeType, elementLabel, removeElement, disabled = false, presentationMode = false } = props;

  const changesDisabled = disabled || presentationMode;

  return (
    <ThemeCrumb type="collectionFieldSeparator">
      <div className="border-b last:border-0 w-full text-xs">
        <ThemeCrumb type={themeType}>
          <span className="flex items-center w-full">
            <div className="w-full text-left px-4 py-3 flex">
              <div className="pr-2">▻</div>
              {elementLabel}
            </div>
            {changesDisabled ? <></> : <IconButton variant="delete" onMouseDown={removeElement} wrapperClass="pr-2" />}
          </span>
        </ThemeCrumb>
      </div>
    </ThemeCrumb>
  );
};

export default CollectionElementField;
