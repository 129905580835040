import BaseModel from './parents/baseModel';

export default class LearningChunk extends BaseModel {
  constructor() {
    super();
    this.notesWorkspaceId = null;
    this.noteId = null;
    this.nodesGroupId = null;
    this.nodeId = null;
    this.nodeLearningProgress = 0;
    this.nodeLearningCount = 0;
    this.notesPath = [];
    this.bestWpm = null;
    this.defaultOrder = 0;
    this.private = false;
  }

  visible(incognitoMode = false) {
    return incognitoMode ? !this.private : true;
  }
}
