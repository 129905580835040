import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { snackbarMessagesActionHandleBackendErrors } from '../../actions/snackbarMessagesActions';
import { learningQueueFromDto, learningQueueToDto } from '../../../helpers/model/learningQueueHelpers';
import { learningQueuesActionSetElement, learningQueuesActionUnlockElement } from '../../actions/learningQueuesActions';

export default function* markPrivateElementLearningQueuesSaga(action) {
  const { element: initialNote } = action.payload;
  const { ok, data } = yield call(api.learningQueues.markPrivate, learningQueueToDto(initialNote));
  if (ok) {
    yield put(learningQueuesActionSetElement(learningQueueFromDto(data)));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(learningQueuesActionUnlockElement(initialNote));
  }
}
