import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { categoriesActionUnset, categoriesActionUnlockElement } from '../../actions/categoriesActions';
import { notificationT } from '../../../helpers/i18nHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { push } from 'connected-react-router';

export default function* deleteElementCategoriesSaga(action) {
  const initialCategory = action.payload;
  const { ok, data } = yield call(api.categories.delete, initialCategory.id);
  if (ok) {
    yield put(push('/tasks'));
    yield put(snackbarMessagesActionAdd(notificationT('categories.delete.success')));
    yield put(categoriesActionUnset(initialCategory));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(categoriesActionUnlockElement(initialCategory));
  }
}
