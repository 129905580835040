import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { prepareCollection } from '../../../helpers/model/modelHelpers';
import { labelsActionSet, labelsActionUnlock } from '../../actions/labelsActions';
import { labelFromDto } from '../../../helpers/model/labelHelpers';
import { snackbarMessagesActionHandleBackendErrors } from '../../actions/snackbarMessagesActions';
import { nodesActionSetElement } from '../../actions/nodesActions';
import Node from '../../../models/node';
import { nodeFromDto } from '../../../helpers/model/nodeHelpers';

const getObjectType = (object) => {
  if (object instanceof Node) return 'Node';
};

function* setObject(object, objectType) {
  if (objectType === 'Node') {
    yield put(nodesActionSetElement(nodeFromDto(object)));
  }
}

export default function* toggleAssignmentLabelsSaga(action) {
  const { object, labelName } = action.payload;
  const objectType = getObjectType(object);

  const { ok, data } = yield call(api.labels.toggleAssignment, labelName, objectType, object.id);
  if (ok) {
    const { labels, object: object } = data;

    yield put(labelsActionSet(prepareCollection(labels, labelFromDto)));
    yield setObject(object, objectType);
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(labelsActionUnlock());
  }
}
