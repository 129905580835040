import { withCustomReinitialize } from '../../../helpers/formHelpers';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import {
  learningQueuesActionCreateElement,
  learningQueuesActionUpdateElement,
} from '../../../stateManagement/actions/learningQueuesActions';
import { filterValues, mapPropsToValues, validationSchema } from './helpers';
import LearningQueue from '../../../models/learningQueue';
import QueueInnerForm from './QueueInnerForm';
import { notesWorkspacesActionFetch } from '../../../stateManagement/actions/notesWorkspacesActions';
import { notesActionFetch } from '../../../stateManagement/actions/notesActions';
import { labelsActionFetch } from '../../../stateManagement/actions/labelsActions';

const ConnectedInnerForm = connect(prepareState(['incognitoMode', 'notesWorkspaces', 'notes', 'labels']), {
  notesWorkspacesActionFetch,
  notesActionFetch,
  labelsActionFetch,
})(QueueInnerForm);

const Form = withCustomReinitialize(['id', 'locked'])(
  withFormik({
    validationSchema,
    mapPropsToValues,
    handleSubmit: (values, formikBag) => {
      const queue = new LearningQueue().assignValues(filterValues(values));
      if (queue.id) formikBag.props.learningQueuesActionUpdateElement(queue);
      else formikBag.props.learningQueuesActionCreateElement(queue);
      formikBag.props.hideForm();
    },
  })(ConnectedInnerForm),
);

export default connect(null, {
  learningQueuesActionCreateElement,
  learningQueuesActionUpdateElement,
})(Form);
