import React from 'react';
import find from 'lodash/find';
import Clickable from '../../Clickable';
import Container from './Container';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { connect } from 'react-redux';
import {
  activeElementsActionActivate,
  activeElementsActionDeactivate,
} from '../../../stateManagement/actions/activeElementsActions';
import { runIfDraggingNotEnabled } from '../../../helpers/draggingHelpers';

const ActivableContainer = ({
  children,
  renderTogglingPart = false,
  renderCommonPart = () => children,
  renderInactive = renderCommonPart,
  disabled = false,

  themeType = 'container',
  themeCrumbProps = {},
  className = '',
  width,
  widthClass = width ? '' : 'w-full',
  roundedClass = 'rounded-xl',
  borderClass = 'border',

  activeElement,
  renderActiveTogglingPart = renderTogglingPart,
  renderActive = renderCommonPart,
  afterActivityChange = () => {},
  activeElements,
  activeElementsActionActivate: activateElement,
  activeElementsActionDeactivate: deactivateElement,

  activeThemeType = themeType,
  activeThemeCrumbProps = themeCrumbProps,
  activeClass = className,
  activeWidth = width,
  activeWidthClass = activeWidth ? widthClass : widthClass,
  activeRoundedClass = roundedClass,
  activeBorderClass = borderClass,

  ...props
}) => {
  const { type, identifier } = activeElement;
  const isContainerActive = !!find(activeElements, { type, identifier });

  const actualThemeType = isContainerActive ? activeThemeType : themeType;
  const actualThemeCrumbProps = isContainerActive ? activeThemeCrumbProps : themeCrumbProps;
  const actualClassName = isContainerActive ? activeClass : className;
  const actualWidth = isContainerActive ? activeWidth : width;
  const actualWidthClass = isContainerActive ? activeWidthClass : widthClass;
  const actualRoundedClass = isContainerActive ? activeRoundedClass : roundedClass;
  const actualBorderClass = isContainerActive ? activeBorderClass : borderClass;

  const activateContainer = () => {
    runIfDraggingNotEnabled(() => {
      activateElement(type, identifier);
      setTimeout(() => afterActivityChange(true));
    });
  };
  const deactivateContainer = () => {
    runIfDraggingNotEnabled(() => {
      deactivateElement(type, identifier);
      setTimeout(() => afterActivityChange(true));
    });
  };
  const toggleContainerActivity = () => {
    isContainerActive ? deactivateContainer() : activateContainer();
  };

  const wrapAsToggling = (content) => (
    <Clickable className={'w-full'} onMouseDown={toggleContainerActivity} disabled={disabled}>
      <div>{content}</div>
    </Clickable>
  );
  const actualRenderTogglingPart = isContainerActive ? renderActiveTogglingPart : renderTogglingPart;

  const render = isContainerActive ? renderActive : renderInactive;
  let renderProps = {
    isContainerActive,
    wrapAsToggling,
    toggleContainerActivity,
    activateContainer,
    deactivateContainer,
  };
  if (actualRenderTogglingPart) renderProps = { ...renderProps, togglingPart: actualRenderTogglingPart(renderProps) };
  renderProps = { ...renderProps, commonPart: renderCommonPart(renderProps) };

  return (
    <Container
      {...props}
      themeType={actualThemeType}
      className={actualClassName}
      width={actualWidth}
      widthClass={actualWidthClass}
      roundedClass={actualRoundedClass}
      borderClass={actualBorderClass}
      themeCrumbProps={actualThemeCrumbProps}
    >
      {render(renderProps)}
    </Container>
  );
};

export default connect(prepareState(['activeElements']), {
  activeElementsActionActivate,
  activeElementsActionDeactivate,
})(ActivableContainer);
