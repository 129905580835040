import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { learningQueuesActionSetElement } from '../../../stateManagement/actions/learningQueuesActions';
import { notesWorkspacesActionFetch } from '../../../stateManagement/actions/notesWorkspacesActions';
import { notesActionFetch } from '../../../stateManagement/actions/notesActions';
import { nodesActionFetch, nodesActionSet } from '../../../stateManagement/actions/nodesActions';
import { useQueueRunToolkit } from '../queueRunToolkit/useQueueRunToolkit';

const QueueRunController = (props) => {
  const { setQueueRunToolkit } = props;

  const queueRunToolkit = useQueueRunToolkit(props);

  useEffect(() => {
    setQueueRunToolkit(queueRunToolkit);
  }, Object.values(queueRunToolkit));

  return <></>;
};

export default connect(prepareState(['incognitoMode', 'notesWorkspaces', 'notes', 'nodes']), {
  learningQueuesActionSetElement,
  notesWorkspacesActionFetch,
  notesActionFetch,
  nodesActionFetch,
  nodesActionSet,
})(QueueRunController);
