export const sessionActionLookup = () => ({
  type: 'SESSION_ACTION_LOOKUP',
});

export const sessionActionSet = (newSession) => ({
  type: 'SESSION_ACTION_SET',
  payload: newSession,
});

export const sessionActionSignIn = (googleCredential) => ({
  type: 'SESSION_ACTION_SIGN_IN',
  payload: googleCredential,
});

export const sessionActionSignOut = () => ({
  type: 'SESSION_ACTION_SIGN_OUT',
});
