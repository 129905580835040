const initialState = () => null;

export default function (state = initialState(), action) {
  switch (action.type) {
    case 'ELEMENT_TO_FOCUS_ON_ACTION_SET':
      return action.payload;
    case '@@router/LOCATION_CHANGE':
    case 'ELEMENT_TO_FOCUS_ON_ACTION_RESET':
    case 'GLOBAL_ACTION_RESET':
      return initialState();
    default:
      return state;
  }
}
