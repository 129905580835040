// very dirty hack to resolve conflicts with dragging clickable entities
export const runIfDraggingNotEnabled = (callback) => {
  let interval;
  const draggingActionUuid = window.lastDraggingActionUuid;

  const handler = () => {
    if (!window.draggingActive && window.lastDraggingActionUuid === draggingActionUuid) {
      callback();
      clearInterval(interval);
    } else if (window.lastDraggingActionUuid !== draggingActionUuid) {
      clearInterval(interval);
    }
  };

  interval = setInterval(handler, 1);
  handler();
  setTimeout(() => {
    clearInterval(interval);
  }, 200);
};
