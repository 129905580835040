import React from 'react';
import ThemeCrumb from '../../../shared/ThemeCrumb';
import { initT } from '../../../helpers/i18nHelpers';
import { learningQueueVariants } from '../../../constants/enums/learningQueueVariants';
import RadioField from '../../../shared/form/fieldTypes/RadioField';
import FormikFieldSub from '../../../shared/form/FormikFieldSub';
import { progressMarks } from './progressMarks';
import ThumbUpIcon from '../../../icons/thumbUpIcon';
import ThumbDownIcon from '../../../icons/thumbDownIcon';

const t = initT('pages.learning.queueRun.chunkStats');

const ProgressRadioField = (props) => {
  const { id, themeType, innerRef, innerId, changesDisabled, label, checked, Icon } = props;

  const _label = checked ? <ThemeCrumb type={themeType}>{label}</ThemeCrumb> : label;

  return (
    <label
      id={id}
      ref={innerRef}
      htmlFor={innerId}
      className={`flex items-center ${changesDisabled ? '' : 'cursor-pointer'}
                       ${checked ? 'font-bold' : 'opacity-60'}`}
    >
      <ThemeCrumb type={themeType}>
        <Icon className="w-4 h-4" />
      </ThemeCrumb>
      <div className={'ml-2 text-left'}>{_label}</div>
    </label>
  );
};

const ChunkStats = (props) => {
  const {
    queue,
    queueRunToolkit: { currentChunk: chunk, progressMark, setProgressMark, alreadyLearnedChunks, currentWpm },
  } = props;

  const progressWidth = `${(chunk.nodeLearningProgress + 5) * 10}%`;

  return (
    <ThemeCrumb type="auxiliary">
      <div className="px-25px text-xs">
        <div className="pb-4 flex justify-between">
          <span>{t('learnedCount')}:</span>
          <span>
            <b>{`${chunk.nodeLearningCount} ${t('times')}`}</b>
          </span>
        </div>
        {queue.variant === learningQueueVariants.typing ? (
          <>
            <div className="pb-4 flex justify-between">
              <span>{t('currentWpm')}:</span>
              <span>
                <b>{currentWpm || '-'}</b>
              </span>
            </div>
            <div className="pb-4 flex justify-between">
              <span>{t('maxWpm')}:</span>
              <span>
                <b>{chunk.bestWpm || '-'}</b>
              </span>
            </div>
          </>
        ) : (
          <></>
        )}
        {alreadyLearnedChunks.includes(chunk.nodeId) ? (
          <></>
        ) : (
          <div>
            <div className="pb-4">
              <div className="w-full flex justify-start pb-2">{t('status')}:</div>
              <ThemeCrumb type="progressContainer">
                <div className="rounded-xl w-full border flex justify-start">
                  <ThemeCrumb type="progress">
                    <div className="rounded-l-xl h-4" style={{ width: progressWidth }} />
                  </ThemeCrumb>
                </div>
              </ThemeCrumb>
            </div>
            <div className="w-full flex justify-start pb-2">{t('modifyStatus')}:</div>
            <div className="pb-2">
              <FormikFieldSub
                component={RadioField}
                value={progressMark}
                radioValue={progressMarks.positive}
                onChange={() => {
                  const newValue = progressMark === progressMarks.positive ? null : progressMarks.positive;
                  setProgressMark(newValue);
                }}
                label={t('progressMarkPositive')}
                themeType="goodHabitDenotation"
                Icon={ThumbUpIcon}
                AlternativeDisplayComponent={ProgressRadioField}
              />
            </div>
            <div>
              <FormikFieldSub
                component={RadioField}
                value={progressMark}
                radioValue={progressMarks.negative}
                onChange={() => {
                  const newValue = progressMark === progressMarks.negative ? null : progressMarks.negative;
                  setProgressMark(newValue);
                }}
                label={t('progressMarkNegative')}
                themeType="badHabitDenotation"
                Icon={ThumbDownIcon}
                AlternativeDisplayComponent={ProgressRadioField}
              />
            </div>
          </div>
        )}
      </div>
    </ThemeCrumb>
  );
};

export default ChunkStats;
