export const audioConfigActionLock = (id) => ({
  type: 'AUDIO_CONFIG_ACTION_LOCK',
  payload: { id },
});

export const audioConfigActionUnlock = (id) => ({
  type: 'AUDIO_CONFIG_ACTION_UNLOCK',
  payload: { id },
});

export const audioConfigActionSetAudioBuffer = (src, audioBuffer) => ({
  type: 'AUDIO_CONFIG_ACTION_SET_AUDIO_BUFFER',
  payload: { src, audioBuffer },
});
