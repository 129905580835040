import React, { useState, useEffect } from 'react';
import CenteredContent from '../../../shared/organizers/CenteredContent';
import H1 from '../../../shared/titles/H1';
import { TitleEditor } from '../../../shared/features/titleEditor';
import LockedEntityLoader from '../../../shared/loaders/LockedEntityLoader';
import ButtonsGroup from '../../../shared/buttons/ButtonsGroup';
import IconButton from '../../../shared/buttons/IconButton';
import { connect } from 'react-redux';
import {
  notesActionDeleteElement,
  notesActionMarkPrivateElement,
  notesActionUnmarkPrivateElement,
  notesActionUpdateElement,
} from '../../../stateManagement/actions/notesActions';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { elementToFocusOnTypes } from '../../../constants/elementToFocusOnTypes';
import WorkspaceSelector from './NotesWorkspaceSelector';
import { elementToFocusOnActionReset } from '../../../stateManagement/actions/elementToFocusOnActions';
import { initT } from '../../../helpers/i18nHelpers';
import ConfirmationButton from '../../../shared/buttons/ConfirmationButton';

const t = initT('pages.notes');

const Top = (props) => {
  const {
    note,
    elementToFocusOn,
    incognitoMode,
    notesActionUpdateElement: update,
    notesActionMarkPrivateElement: markPrivate,
    notesActionUnmarkPrivateElement: unmarkPrivate,
    notesActionDeleteElement: deleteElement,
    elementToFocusOnActionReset,
    editionMode: contentEditionMode,
    setEditionMode: setContentEditionMode,
    pdfGenerating,
  } = props;

  const [editionMode, setEditionMode] = useState(false);

  const switchPrivateVariant = note.private ? 'unmarkPrivate' : 'markPrivate';
  const switchPrivate = () => (note.private ? unmarkPrivate(note) : markPrivate(note));

  const handleSubmit = ({ title }) => {
    const updatedNote = note.shallowClone().assignValues({ ...note, name: title });
    if (note.name !== title) update(updatedNote);
    setEditionMode(false);
  };

  useEffect(() => {
    if (elementToFocusOn && elementToFocusOn.equals(elementToFocusOnTypes.noteTitle, note.id)) {
      setEditionMode(true);
      elementToFocusOnActionReset();
    }
  }, [elementToFocusOn]);

  return (
    <div className="w-full h-40px">
      <LockedEntityLoader visible={note.locked}>
        {editionMode ? (
          <TitleEditor title={note.name} handleSubmit={handleSubmit} />
        ) : (
          <CenteredContent
            heightPx={40}
            left={
              <ButtonsGroup
                className="flex px-2 sm:px-25px"
                buttons={[
                  <WorkspaceSelector key="changeWorkspace" note={note} />,
                  incognitoMode ? null : (
                    <IconButton key="switchPrivate" variant={switchPrivateVariant} onMouseDown={switchPrivate} />
                  ),
                ]}
              />
            }
            main={<H1 onDoubleClick={() => setEditionMode(true)}>{note.name}</H1>}
            right={
              <ButtonsGroup
                className="flex px-2 sm:px-25px"
                buttons={[
                  contentEditionMode ? null : (
                    <IconButton
                      key="unlock"
                      variant="unlock"
                      disabled={pdfGenerating}
                      tooltip={t('enableEdition')}
                      onMouseDown={() => setContentEditionMode(true)}
                    />
                  ),
                  <ConfirmationButton
                    key="delete"
                    variant="delete"
                    i18nKey="notes.delete"
                    disabled={pdfGenerating}
                    onConfirmed={() => deleteElement(note)}
                    resourceName={note.name}
                    textConfirmation
                  />,
                ]}
              />
            }
          />
        )}
      </LockedEntityLoader>
    </div>
  );
};

export default connect(prepareState(['incognitoMode', 'elementToFocusOn']), {
  notesActionUpdateElement,
  notesActionMarkPrivateElement,
  notesActionUnmarkPrivateElement,
  notesActionDeleteElement,
  elementToFocusOnActionReset,
})(Top);
