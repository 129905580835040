import themes from '../constants/themes';
import Cookies from 'js-cookie';

const THEME_COOKIE_IDENTIFIER = 'theme';

export const getThemeTypeDefinition = (theme = 'default', type = 'main') => {
  const themeDefinition = themes[theme] || themes.default;
  return themeDefinition[type] || themeDefinition.main;
};

export const getCurrentCookieTheme = () => Cookies.get(THEME_COOKIE_IDENTIFIER) || 'default';

export const setCurrentCookieTheme = (theme) => Cookies.set(THEME_COOKIE_IDENTIFIER, theme);

export const removeCurrentCookieTheme = () => Cookies.remove(THEME_COOKIE_IDENTIFIER);
