import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import RecurringEvent from '../../../../models/recurringEvent';
import { recurringEventsActionUpdateElement } from '../../../../stateManagement/actions/recurringEventsActions';
import ColorField from '../../../../shared/form/fieldTypes/ColorField';
import FormikFieldSub from '../../../../shared/form/FormikFieldSub';

const ColorSelector = (props) => {
  const { recurringEvent, recurringEventsActionUpdateElement: update } = props;

  const onChange = useCallback(
    ({ newValue, additionalProps: { closeMenu } }) => {
      closeMenu();
      const updatedRecurringEvent = new RecurringEvent().assignValues({
        ...recurringEvent,
        color: newValue,
      });
      update(updatedRecurringEvent, false);
    },
    [recurringEvent, update],
  );

  return <FormikFieldSub onChange={onChange} value={recurringEvent.color} component={ColorField} />;
};

export default connect(null, {
  recurringEventsActionUpdateElement,
})(ColorSelector);
