export const categoriesActionFetch = () => ({
  type: 'CATEGORIES_ACTION_FETCH',
});

export const categoriesActionSet = (categories) => ({
  type: 'CATEGORIES_ACTION_SET',
  payload: categories,
});

export const categoriesActionCreateElement = (category) => ({
  type: 'CATEGORIES_ACTION_CREATE_ELEMENT',
  payload: category,
});

export const categoriesActionUnlock = () => ({
  type: 'CATEGORIES_ACTION_UNLOCK',
});

export const categoriesActionUpdateElement = (category) => ({
  type: 'CATEGORIES_ACTION_UPDATE_ELEMENT',
  payload: category,
});

export const categoriesActionMarkPrivateElement = (category) => ({
  type: 'CATEGORIES_ACTION_MARK_PRIVATE_ELEMENT',
  payload: category.assignValues({ private: true }),
});

export const categoriesActionUnmarkPrivateElement = (category) => ({
  type: 'CATEGORIES_ACTION_UNMARK_PRIVATE_ELEMENT',
  payload: category.assignValues({ private: false }),
});

export const categoriesActionLockElement = (category) => ({
  type: 'CATEGORIES_ACTION_LOCK_ELEMENT',
  payload: category,
});

export const categoriesActionUnlockElement = (category) => ({
  type: 'CATEGORIES_ACTION_UNLOCK_ELEMENT',
  payload: category,
});

export const categoriesActionDeleteElement = (category) => ({
  type: 'CATEGORIES_ACTION_DELETE_ELEMENT',
  payload: category,
});

export const categoriesActionSetCreatedElement = (category) => ({
  type: 'CATEGORIES_ACTION_SET_CREATED_ELEMENT',
  payload: category,
});

export const categoriesActionSetElement = (category) => ({
  type: 'CATEGORIES_ACTION_SET_ELEMENT',
  payload: category,
});

export const categoriesActionUnset = (oneOrManyElements) => ({
  type: 'CATEGORIES_ACTION_UNSET',
  payload: oneOrManyElements,
});
