export const habitsActionFetch = () => ({
  type: 'HABITS_ACTION_FETCH',
});

export const habitsActionSet = (habits) => ({
  type: 'HABITS_ACTION_SET',
  payload: habits,
});

export const habitsActionCreateElement = (habit) => ({
  type: 'HABITS_ACTION_CREATE_ELEMENT',
  payload: habit,
});

export const habitsActionUnlock = () => ({
  type: 'HABITS_ACTION_UNLOCK',
});

export const habitsActionUpdateElement = (habit) => ({
  type: 'HABITS_ACTION_UPDATE_ELEMENT',
  payload: habit,
});

export const habitsActionMarkPrivateElement = (habit) => ({
  type: 'HABITS_ACTION_MARK_PRIVATE_ELEMENT',
  payload: habit.assignValues({ private: true }),
});

export const habitsActionUnmarkPrivateElement = (habit) => ({
  type: 'HABITS_ACTION_UNMARK_PRIVATE_ELEMENT',
  payload: habit.assignValues({ private: false }),
});

export const habitsActionMarkHiddenElement = (habit) => ({
  type: 'HABITS_ACTION_MARK_HIDDEN_ELEMENT',
  payload: habit.assignValues({ hidden: true }),
});

export const habitsActionUnmarkHiddenElement = (habit) => ({
  type: 'HABITS_ACTION_UNMARK_HIDDEN_ELEMENT',
  payload: habit.assignValues({ hidden: false }),
});

export const habitsActionLockElement = (habit) => ({
  type: 'HABITS_ACTION_LOCK_ELEMENT',
  payload: habit,
});

export const habitsActionUnlockElement = (habit) => ({
  type: 'HABITS_ACTION_UNLOCK_ELEMENT',
  payload: habit,
});

export const habitsActionDeleteElement = (habit) => ({
  type: 'HABITS_ACTION_DELETE_ELEMENT',
  payload: habit,
});

export const habitsActionSetCreatedElement = (habit) => ({
  type: 'HABITS_ACTION_SET_CREATED_ELEMENT',
  payload: habit,
});

export const habitsActionSetElement = (habit) => ({
  type: 'HABITS_ACTION_SET_ELEMENT',
  payload: habit,
});

export const habitsActionUnset = (oneOrManyElements) => ({
  type: 'HABITS_ACTION_UNSET',
  payload: oneOrManyElements,
});
