import React, { useEffect, useState, useMemo } from 'react';
import { useSelected, useSlateStatic, ReactEditor } from 'slate-react';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import ThemeCrumb from '../../../../../ThemeCrumb';
import { connect } from 'react-redux';
import { prepareState } from '../../../../../../helpers/stateManagementHelpers';
import { getThemeTypeDefinition } from '../../../../../../helpers/themeHelpers';
import FormikFieldSub from '../../../../../form/FormikFieldSub';
import TextField from '../../../../../form/fieldTypes/TextField';
import { initT } from '../../../../../../helpers/i18nHelpers';
import { getSelectedLinkNode, isLinkNodeAtSelection } from '../helpers/linkHelpers';
import { Transforms } from 'slate';
import isHotkey from 'is-hotkey';

const t = initT('textEditor');

const Link = React.forwardRef((props, ref) => {
  const { children, url, theme, attributes } = props;
  const editor = useSlateStatic();
  const selected = useSelected() && isLinkNodeAtSelection(editor);

  const [popoverOpened, setPopoverOpened] = useState(false);
  const urlInputId = 'link-popover-input';
  const [urlTempValue, setUrlTempValue] = useState(url);
  const [linkPath, setLinkPath] = useState(null);

  useEffect(() => {
    if (!popoverOpened && selected) {
      setPopoverOpened(true);
    } else if (!selected && editor.selection) {
      setPopoverOpened(false);
    }
    if (editor.selection) {
      const linkNode = getSelectedLinkNode(editor);
      if (linkNode) setLinkPath(ReactEditor.findPath(editor, linkNode));
    }
  }, [selected]);

  useEffect(() => {
    setUrlTempValue(url);
  }, [url]);

  const onBlur = useMemo(() => {
    if (!linkPath) return () => {};
    return () => {
      ReactEditor.focus(editor);
    };
  }, [linkPath]);

  return (
    <Popover
      isOpen={popoverOpened}
      positions={['bottom']}
      onClickOutside={() => {
        if (selected) return;
        setPopoverOpened(false);
      }}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          arrowSize={5}
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={getThemeTypeDefinition(theme, 'textEditorLinkPopover').borderColor}
        >
          <ThemeCrumb type="textEditorLinkPopover">
            <div
              className="rounded-md border p-4 flex items-center link-popover"
              onBlur={onBlur}
              onKeyDown={(event) => {
                if (isHotkey('tab', event)) {
                  event.preventDefault();
                  Transforms.select(editor, linkPath);
                  Transforms.collapse(editor, {
                    edge: 'end',
                  });
                  onBlur();
                }
              }}
            >
              <label htmlFor={urlInputId} className="pr-2">
                {t('linkUrl')}:
              </label>
              <FormikFieldSub
                id={urlInputId}
                component={TextField}
                value={urlTempValue}
                onChange={({ event }) => {
                  Transforms.setNodes(editor, { url: event.target.value }, { at: linkPath });
                  setUrlTempValue(event.target.value);
                }}
              />
            </div>
          </ThemeCrumb>
        </ArrowContainer>
      )}
    >
      <span>
        <ThemeCrumb type="link">
          <span ref={ref} {...attributes}>
            {children}
          </span>
        </ThemeCrumb>
      </span>
    </Popover>
  );
});

export default connect(prepareState('theme'), null, null, { forwardRef: true })(Link);
