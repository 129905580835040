import { useEffect, useMemo, useRef, useState } from 'react';
import { progressMarks } from '../queueRun/progressMarks';
import { useCheckIfMounted } from '../../../hooks/useCheckIfMounted';
import { learningQueueVariants } from '../../../constants/enums/learningQueueVariants';

export const useLearningProgress = (props) => {
  let { queue, currentChunk, currentChunkUuid, progressMark, alreadyLearnedChunks, audioFinished, finalWpm } = props;
  queue ||= {};
  currentChunk ||= {};

  const checkIfMounted = useCheckIfMounted();

  const chunkUuid = useRef(currentChunkUuid);
  useEffect(() => {
    chunkUuid.current = currentChunkUuid;
  }, [queue.id, currentChunkUuid]);

  const [fiveSecsPassed, setFiveSecsPassed] = useState(false);
  useEffect(() => {
    const id = chunkUuid.current;
    setFiveSecsPassed(false);

    setTimeout(() => {
      if (checkIfMounted() && id === chunkUuid.current) setFiveSecsPassed(true);
    }, 5000);
  }, [queue.id, currentChunkUuid]);

  // not numeric learningProgress (null) means that learn action should not be taken on next/back
  return useMemo(() => {
    if (alreadyLearnedChunks.includes(currentChunk.nodeId)) return null;
    if (progressMark === progressMarks.positive) return 2;
    if (progressMark === progressMarks.negative) return -2;
    if (fiveSecsPassed && queue.variant === learningQueueVariants.overview) return 1;
    if (audioFinished && queue.variant === learningQueueVariants.audio) return 1;
    if (typeof finalWpm === 'number' && queue.variant === learningQueueVariants.typing) return 2;
    return 0;
  }, [queue.id, currentChunkUuid, progressMark, fiveSecsPassed, alreadyLearnedChunks, audioFinished, finalWpm]);
};
