import React from 'react';
import LabelledButton from '../buttons/LabelledButton';
import FormFooter from './FormFooter';

const StandardFormFooter = (props) => {
  const { handleCancel, handleSubmit } = props;
  return (
    <FormFooter {...props}>
      {handleCancel && <LabelledButton variant="cancel" onClick={handleCancel} />}
      {handleSubmit && <LabelledButton variant="submit" onClick={handleSubmit} />}
    </FormFooter>
  );
};

export default StandardFormFooter;
