import React, { useMemo } from 'react';
import ThemeCrumb from '../../ThemeCrumb';
import Hoverable from '../../Hoverable';
import Clickable from '../../Clickable';
import CheckIndicator from '../../CheckIndicator';
import IconButton from '../../buttons/IconButton';

const LabelOption = (props) => {
  const {
    label,
    value,
    labelModel,
    selectedValue,
    object,
    incognitoMode,
    labelsActionToggleAssignment: toggleAssignment,
    labelsActionMarkPrivateElement: markPrivate,
    labelsActionUnmarkPrivateElement: unmarkPrivate,
  } = props;

  const switchPrivate = () => (labelModel.private ? unmarkPrivate(labelModel) : markPrivate(labelModel));

  const selected = useMemo(() => Array.isArray(selectedValue) && selectedValue.includes(value), [value, selectedValue]);

  return (
    <ThemeCrumb type="selectorOption">
      <Hoverable className="" hoveredThemeType="sizeableThingHovered">
        <Clickable
          className="w-full max-w-56"
          onMouseDown={() => {
            toggleAssignment(object, label);
          }}
        >
          <div
            className={`w-full px-2 py-1 flex items-center text-clip overflow-hidden
                                    font-xs ${selected ? 'font-bold' : ''}`}
          >
            <CheckIndicator theme="input" checked={selected} />
            <div className="pl-2">{label}</div>
          </div>
        </Clickable>
        {incognitoMode ? null : (
          <IconButton
            key="switchPrivate"
            onMouseDown={switchPrivate}
            variant={labelModel.private ? 'unmarkPrivate' : 'markPrivate'}
          />
        )}
      </Hoverable>
    </ThemeCrumb>
  );
};

export default LabelOption;
