import { produceModelFromDto, produceModelToDto } from './modelHelpers';
import Habit from '../../models/habit';
import { habitSummaryRequestFromDto, habitSummaryRequestToDto } from './habitSummaryRequestsHelpers';
import { habitHidingConditionFromDto, habitHidingConditionToDto } from './habitHidingConditionsHelpers';
import { habitChartRequestFromDto, habitChartRequestToDto } from './habitChartRequestsHelpers';

const mappings = [
  ['id', 'id'],
  ['habits_section_id', 'habitsSectionId'],
  ['name', 'name'],
  ['input_type', 'inputType', 'enum'],
  ['variant', 'variant', 'enum'],
  ['habit_default_order', 'defaultOrder'],
  ['consider_only_updated_values', 'considerOnlyUpdatedValues'],
  ['hidden', 'hidden'],
  ['private', 'private'],
  [
    'habit_hiding_conditions',
    'habitHidingConditions',
    'association',
    { fromDto: habitHidingConditionFromDto, toDto: habitHidingConditionToDto },
  ],
  [
    'habit_summary_requests',
    'habitSummaryRequests',
    'association',
    { fromDto: habitSummaryRequestFromDto, toDto: habitSummaryRequestToDto },
  ],
  [
    'habit_chart_requests',
    'habitChartRequests',
    'association',
    { fromDto: habitChartRequestFromDto, toDto: habitChartRequestToDto },
  ],
];

export const habitFromDto = produceModelFromDto(Habit, mappings);

export const habitToDto = produceModelToDto(mappings);
