export const modelNames = [
  'Category',
  'Task',
  'RecurringTask',
  'HabitsSection',
  'Habit',
  'Event',
  'RecurringEvent',
  'NotesWorkspace',
  'Note',
  'Node',
  'LearningWorkspace',
  'LearningQueue',
  'LanguageWorkspace',
];
