export const nodesActionFetch = (nodesGroupId) => ({
  type: 'NODES_ACTION_FETCH',
  payload: { nodesGroupId },
});

export const nodesActionSet = (nodesGroupId, newElements) => ({
  type: 'NODES_ACTION_SET',
  payload: { nodesGroupId, newElements },
});

export const nodesActionCreateElement = (element, successorId) => ({
  type: 'NODES_ACTION_CREATE_ELEMENT',
  payload: { element, successorId },
});

export const nodesActionUnlock = () => ({
  type: 'NODES_ACTION_UNLOCK',
});

export const nodesActionUpdateElement = (element) => ({
  type: 'NODES_ACTION_UPDATE_ELEMENT',
  payload: { element },
});

export const nodesActionUpdateAudioUrlElement = (element, data) => ({
  type: 'NODES_ACTION_UPDATE_AUDIO_URL_ELEMENT',
  payload: { element, data },
});

export const nodesActionDuplicateElement = (element) => ({
  type: 'NODES_ACTION_DUPLICATE_ELEMENT',
  payload: { element },
});

export const nodesActionMarkPrivateElement = (element) => ({
  type: 'NODES_ACTION_MARK_PRIVATE_ELEMENT',
  payload: { element: element.assignValues({ private: true }) },
});

export const nodesActionUnmarkPrivateElement = (element) => ({
  type: 'NODES_ACTION_UNMARK_PRIVATE_ELEMENT',
  payload: { element: element.assignValues({ private: false }) },
});

export const nodesActionLockElement = (element) => ({
  type: 'NODES_ACTION_LOCK_ELEMENT',
  payload: { element },
});

export const nodesActionUnlockElement = (element) => ({
  type: 'NODES_ACTION_UNLOCK_ELEMENT',
  payload: { element },
});

export const nodesActionDeleteElement = (element) => ({
  type: 'NODES_ACTION_DELETE_ELEMENT',
  payload: { element },
});

export const nodesActionSetCreatedElement = (element) => ({
  type: 'NODES_ACTION_SET_CREATED_ELEMENT',
  payload: { element },
});

export const nodesActionSetElement = (element) => ({
  type: 'NODES_ACTION_SET_ELEMENT',
  payload: { element },
});

export const nodesActionUnset = (oneOrManyElements) => ({
  type: 'NODES_ACTION_UNSET',
  payload: { oneOrManyElements },
});

export const nodesActionUnsetGroup = (nodesGroupId) => ({
  type: 'NODES_ACTION_UNSET_GROUP',
  payload: { nodesGroupId },
});
