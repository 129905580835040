import Category from '../../models/category';
import { produceModelFromDto, produceModelToDto } from './modelHelpers';

const mappings = [
  ['id', 'id'],
  ['name', 'name'],
  ['color', 'color', 'enum'],
  ['tasks_order', 'tasksOrder'],
  ['private', 'private'],
];

export const categoryFromDto = produceModelFromDto(Category, mappings);

export const categoryToDto = produceModelToDto(mappings);
