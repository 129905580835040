import { call, select, put } from 'redux-saga/effects';
import { nodesActionUnlockElement, nodesActionUpdateElement } from '../../actions/nodesActions';
import uploadElementFilesSaga from '../files/uploadElementFilesSaga';
import { getUuid } from '../../../helpers/identifierHelpers';

export default function* updateAudioUrlElementNodesSaga(action) {
  const { element, data = {} } = action.payload;

  const shouldUpdateFile = Object.keys(data).includes('file');
  const { file, mask } = data;

  if (shouldUpdateFile && file) {
    const uploadIdentifier = getUuid();
    yield call(uploadElementFilesSaga, { payload: { identifier: uploadIdentifier, file } });
    const uploadedFile = yield select((state) => state.files.find({ id: uploadIdentifier }));
    if (!uploadedFile) {
      yield put(nodesActionUnlockElement(element));
      return;
    }
    element.audioUrl = uploadedFile.url;
    element.audioMask = mask;
  } else if (shouldUpdateFile) {
    element.audioUrl = null;
    element.audioMask = null;
  } else {
    element.audioMask = mask;
  }
  yield put(nodesActionUpdateElement(element));
}
