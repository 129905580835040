export const alignmentTypes = {
  left: 'left',
  center: 'center',
  right: 'right',
  justify: 'justify',
};

export const alignmentTypesValues = Object.values(alignmentTypes);

export const defaultAlignmentType = alignmentTypes.left;
