import { create } from 'apisauce';
import every from 'lodash/every';
export const fetchDecodedBufferFromSrc = async (src) => {
  const response = await create({ headers: {}, responseType: 'blob' }).get(src);
  return await fetchDecodedBuffer(new Blob([response.data]));
};

export const fetchDecodedBuffer = async (blob) => {
  const arrayBuffer = await blob.arrayBuffer();
  const audioCtx = new AudioContext();
  return await audioCtx.decodeAudioData(arrayBuffer);
};

export const validateMask = (mask) => {
  if (
    !Array.isArray(mask) ||
    !every(
      mask,
      (element) =>
        Array.isArray(element) &&
        element.length === 2 &&
        !isNaN(element[0]) &&
        !isNaN(element[1]) &&
        element[0] >= 0 &&
        element[1] >= 0 &&
        element[1] > element[0],
    )
  ) {
    throw Error(
      'mask must be an array of arrays with two non-negative numbers, ' + 'the latter greater than its predecessor.',
    );
  }
};

export const applyMask = (decodedBuffer, mask) => {
  if (!mask) return decodedBuffer;
  validateMask(mask);

  const channels = decodedBuffer.numberOfChannels;
  const rate = decodedBuffer.sampleRate;

  if (mask.length === 0) return null;

  let totalFrameCount = 0;
  const parsedMask = mask.map(([start, end]) => {
    const startOffset = rate * start;
    const endOffset = rate * end;
    const frameCount = endOffset - startOffset;
    totalFrameCount += frameCount;
    return [startOffset, frameCount];
  });

  const audioCtx = new AudioContext();
  const newBuffer = audioCtx.createBuffer(channels, totalFrameCount, rate);
  let currentOffset = 0;
  parsedMask.forEach(([startOffset, frameCount]) => {
    const tempArray = new Float32Array(frameCount);

    for (let channel = 0; channel < channels; channel++) {
      decodedBuffer.copyFromChannel(tempArray, channel, startOffset);
      newBuffer.copyToChannel(tempArray, channel, currentOffset);
    }
    currentOffset += frameCount;
  });
  return newBuffer;
};

export const invertMask = (mask, baseDuration) => {
  validateMask(mask);

  let result = [[0, baseDuration]];

  mask.forEach(([start, end]) => {
    const newResult = [];

    let ongoingTrimming = false;
    result.forEach(([resultStart, resultEnd]) => {
      const startInRange = start >= resultStart && start <= resultEnd;
      const endInRange = end >= resultStart && end <= resultEnd;

      if (resultStart > start && resultStart <= end) ongoingTrimming = true;
      if (resultStart > end) ongoingTrimming = false;

      if (!ongoingTrimming && !startInRange && !endInRange) {
        newResult.push([resultStart, resultEnd]);
        return;
      }

      if (!ongoingTrimming && startInRange) {
        ongoingTrimming = true;
        if (start !== resultStart) newResult.push([resultStart, start]);
      }
      if (ongoingTrimming && endInRange) {
        ongoingTrimming = false;
        if (end !== resultEnd) newResult.push([end, resultEnd]);
      }
    });
    result = newResult;
  });

  return result;
};

export const combineMasks = (baseMask, maskToApply) => {
  validateMask(maskToApply);
  if (!baseMask) return maskToApply;
  validateMask(baseMask);

  let currentStart = 0;
  const amassedTotalTimes = baseMask.map(([start, end]) => {
    let result = currentStart;
    currentStart += end - start;
    return result;
  });

  const result = [];
  maskToApply.forEach(([start, end]) => {
    let index = -1;
    amassedTotalTimes.forEach((totalTime) => {
      if (start >= totalTime) index++;
    });

    const startDelay = start - amassedTotalTimes[index];
    let newStart = baseMask[index][0] + startDelay;
    let firstPart = true;

    amassedTotalTimes.slice(index + 1).forEach((nextTotalTime) => {
      if (newStart === null) return;

      if (end > nextTotalTime) {
        firstPart = false;
        result.push([newStart, baseMask[index][1]]);
        newStart = baseMask[index + 1][0];
      } else {
        const subtrahend = firstPart ? start : amassedTotalTimes[index];
        const endDelay = end - subtrahend;
        result.push([newStart, newStart + endDelay]);
        newStart = null;
      }
      index++;
    });

    if (newStart !== null) {
      const subtrahend = firstPart ? start : amassedTotalTimes[index];
      const endDelay = end - subtrahend;
      result.push([newStart, newStart + endDelay]);
    }
  });

  return result;
};

export const sanitizeMask = (mask) => {
  validateMask(mask);

  return mask.filter(([start, end]) => end - start >= 0.1);
};
