import React from 'react';
import { connect } from 'react-redux';
import { wastesActionDeleteElement, wastesActionRestoreElement } from '../../../stateManagement/actions/wastesActions';
import Container from '../../../shared/organizers/containers/Container';
import ButtonsGroup from '../../../shared/buttons/ButtonsGroup';
import IconButton from '../../../shared/buttons/IconButton';
import { initT } from '../../../helpers/i18nHelpers';
import ConfirmationButton from '../../../shared/buttons/ConfirmationButton';

const t = initT('pages.wasteBin');

const WasteItem = (props) => {
  const { item, wastesActionRestoreElement, wastesActionDeleteElement } = props;

  return (
    <Container loading={item.locked}>
      <div className="flex items-center px-2 py-2">
        <div className="w-full pl-3 text-left truncate">{item.name}</div>
        <ButtonsGroup
          className="flex justify-end pl-1"
          buttons={[
            <IconButton
              key="restore"
              variant="reset"
              tooltip={item.canBeRestored ? t('restore') : t('cannotBeRestored')}
              disabled={!item.canBeRestored}
              onMouseDown={() => wastesActionRestoreElement(item)}
            />,
            <ConfirmationButton
              key="delete"
              variant="delete"
              tooltip={t('delete')}
              i18nKey="wastes.delete"
              onConfirmed={() => wastesActionDeleteElement(item)}
              resourceName={item.name}
            />,
          ]}
        />
      </div>
    </Container>
  );
};

export default connect(null, {
  wastesActionRestoreElement,
  wastesActionDeleteElement,
})(WasteItem);
