import React, { useMemo } from 'react';
import { useSlateStatic } from 'slate-react';
import ThemeCrumb from '../../../../ThemeCrumb';
import {
  getToolbarActiveLeavesVariants,
  leafVariantToIconButtonVariant,
  produceToggleLeafVariant,
} from './helpers/leafVariantHelpers';
import { isLinkNodeAtSelection, selectionContainsLink, toggleLink } from './helpers/linkHelpers';
import { produceRedo, produceReset, produceUndo } from './helpers/historyHelpers';
import { alignmentTypesValues } from './constants/alignmentTypes';
import {
  alignmentTypeToIconButtonVariant,
  getActiveAlignmentTypes,
  produceToggleAlignmentType,
} from './helpers/alignmentHelpers';
import { leafVariantsValues } from './constants/leafVariants';
import {
  blockVariantToIconButtonVariant,
  getActiveBlockVariants,
  produceToggleBlockVariant,
} from './helpers/blockVariantHelpers';
import { blockVariants } from './constants/blockVariants';
import {
  canDecreaseNestingLevel,
  canIncreaseNestingLevel,
  produceDecreaseNestingLevel,
  produceIncreaseNestingLevel,
} from './helpers/blockNestingHelpers';
import IconButton from '../../../../buttons/IconButton';
import LastStickyElement from '../../../../LastStickyElement';

const getLvl1ContainerClass = (sizeMode) => {
  const common = 'rounded-t-md pl-2 pb-2 pr-1 pt-1';
  switch (sizeMode) {
    case 'xs':
      return `${common}`;
    case 'sm':
      return `${common}`;
    case 'md':
    case 'lg':
    case 'xl':
      return `${common} flex flex-wrap`;
  }
};

const getLvl2Container = (sizeMode, children) => {
  switch (sizeMode) {
    case 'xs':
      return children;
    case 'sm':
      return <div className="flex flex-wrap last:justify-between">{children}</div>;
    case 'md':
      return <div className="w-full flex justify-between">{children}</div>;
    case 'lg':
      return <div className="w-full flex last:justify-between">{children}</div>;
    case 'xl':
      return children;
  }
};

const getLvl3Container = (sizeMode, children) => {
  switch (sizeMode) {
    case 'xs':
      return <div className="w-full flex flex-wrap justify-start last:justify-end last:pt-2">{children}</div>;
    case 'sm':
      return <div className="flex flex-wrap justify-start">{children}</div>;
    case 'md':
      return <div className="flex">{children}</div>;
    case 'lg':
      return <div className="flex first:mr-4">{children}</div>;
    case 'xl':
      return <div className="flex mr-4 last:mr-0 last:flex-grow justify-end">{children}</div>;
  }
};

const Toolbar = ({ editorWidth }) => {
  const editor = useSlateStatic();

  const sizeMode = useMemo(() => {
    if (editorWidth < 285) return 'xs';
    else if (editorWidth < 350) return 'sm';
    else if (editorWidth < 500) return 'md';
    else if (editorWidth < 665) return 'lg';
    else return 'xl';
  }, [editorWidth]);

  const toolbarActiveLeavesVariants = getToolbarActiveLeavesVariants(editor);
  const activeAlignmentTypes = getActiveAlignmentTypes(editor);
  const activeBlockVariants = getActiveBlockVariants(editor);
  const linkNodeAtSelection = isLinkNodeAtSelection(editor);
  const linkDisabled = !linkNodeAtSelection && selectionContainsLink(editor);

  const undoDisabled = editor.history.undos.length === 0;
  const redoDisabled = editor.history.redos.length === 0;

  return (
    <LastStickyElement>
      <ThemeCrumb type="nodeToolbar">
        <div className={`${getLvl1ContainerClass(sizeMode)} border-b`}>
          {getLvl2Container(
            sizeMode,
            <>
              {getLvl3Container(
                sizeMode,
                <>
                  {leafVariantsValues.map((leafVariant) => {
                    const active = toolbarActiveLeavesVariants.has(leafVariant);
                    return (
                      <div key={leafVariant} className="pt-1 pr-1">
                        <IconButton
                          variant={leafVariantToIconButtonVariant(leafVariant)}
                          themeType={active ? 'nodeToolbarIconSelected' : 'button'}
                          onMouseDown={produceToggleLeafVariant(editor, leafVariant)}
                        />
                      </div>
                    );
                  })}
                  <div className="pt-1 pr-1">
                    <IconButton
                      variant="link"
                      themeType={linkNodeAtSelection ? 'nodeToolbarIconSelected' : 'button'}
                      disabled={linkDisabled}
                      onMouseDown={() => toggleLink(editor)}
                    />
                  </div>
                </>,
              )}
              {getLvl3Container(
                sizeMode,
                <>
                  {alignmentTypesValues.map((alignmentType) => {
                    const active = activeAlignmentTypes.size === 1 && activeAlignmentTypes.has(alignmentType);
                    return (
                      <div key={alignmentType} className="pt-1 pr-1">
                        <IconButton
                          variant={alignmentTypeToIconButtonVariant(alignmentType)}
                          themeType={active ? 'nodeToolbarIconSelected' : 'button'}
                          onMouseDown={produceToggleAlignmentType(editor, alignmentType)}
                        />
                      </div>
                    );
                  })}
                </>,
              )}
            </>,
          )}
          {getLvl2Container(
            sizeMode,
            <>
              {getLvl3Container(
                sizeMode,
                <>
                  {[blockVariants.orderedBullet, blockVariants.bullet, blockVariants.checklistBullet].map(
                    (blockVariant) => {
                      const active = activeBlockVariants.size === 1 && activeBlockVariants.has(blockVariant);
                      return (
                        <div key={blockVariant} className="pt-1 pr-1">
                          <IconButton
                            variant={blockVariantToIconButtonVariant(blockVariant)}
                            themeType={active ? 'nodeToolbarIconSelected' : 'button'}
                            onMouseDown={produceToggleBlockVariant(editor, blockVariant)}
                          />
                        </div>
                      );
                    },
                  )}
                  <div className="pt-1 pr-1">
                    <IconButton
                      variant="decreaseIndent"
                      disabled={!canDecreaseNestingLevel(editor)}
                      onMouseDown={produceDecreaseNestingLevel(editor)}
                    />
                  </div>
                  <div className="pt-1 pr-1">
                    <IconButton
                      variant="increaseIndent"
                      disabled={!canIncreaseNestingLevel(editor)}
                      onMouseDown={produceIncreaseNestingLevel(editor)}
                    />
                  </div>
                </>,
              )}
              {getLvl3Container(
                sizeMode,
                <>
                  <div className="pt-1 pr-1">
                    <IconButton variant="undo" disabled={undoDisabled} onMouseDown={produceUndo(editor)} />
                  </div>
                  <div className="pt-1 pr-1">
                    <IconButton variant="redo" disabled={redoDisabled} onMouseDown={produceRedo(editor)} />
                  </div>
                  <div className="pt-1 pr-1">
                    <IconButton
                      variant="reset"
                      iconSizeClass="w-3 h-3"
                      disabled={undoDisabled}
                      onMouseDown={produceReset(editor)}
                    />
                  </div>
                </>,
              )}
            </>,
          )}
        </div>
      </ThemeCrumb>
    </LastStickyElement>
  );
};

export default Toolbar;
