import React, { useEffect, useMemo } from 'react';
import StandardContentTemplate from '../../shared/contentTemplates/StandardContentTemplate';
import { currentMonth } from '../../helpers/dateTimeHelpers';
import Header from './Header';
import { connect } from 'react-redux';
import { prepareState } from '../../helpers/stateManagementHelpers';
import { eventsActionFetch } from '../../stateManagement/actions/eventsActions';
import { determineDatesToFetch } from './helpers';
import { checkedTasksActionFetch } from '../../stateManagement/actions/checkedTasksActions';
import { categoriesActionFetch } from '../../stateManagement/actions/categoriesActions';
import { useHistory } from 'react-router-dom';
import useSearchParamsObject from '../../hooks/useSearchParamsObject';
import { updateSearchParams } from '../../helpers/locationHelpers';
import { DateTime } from 'luxon';
import { displayMonthFormat } from '../../constants/dateFormat';
import MonthlyCalendar from './MonthlyCalendar';

const MonthlyCalendarPage = (props) => {
  const { categories, events, checkedTasks, eventsActionFetch, checkedTasksActionFetch, categoriesActionFetch } = props;

  const history = useHistory();
  const searchParams = useSearchParamsObject();
  const { month = currentMonth() } = searchParams;
  const currentMonthDT = useMemo(() => DateTime.fromFormat(month, displayMonthFormat), [month]);

  const eventsDatesToFetch = useMemo(
    () => (currentMonthDT.invalid ? [] : determineDatesToFetch(events, currentMonthDT)),
    [events, currentMonthDT],
  );
  const checkedTasksDatesToFetch = useMemo(
    () => (currentMonthDT.invalid ? [] : determineDatesToFetch(checkedTasks, currentMonthDT)),
    [checkedTasks, currentMonthDT],
  );

  useEffect(() => {
    if (!categories.fetched) categoriesActionFetch();
  }, []);

  useEffect(() => {
    if (eventsDatesToFetch.length > 0) eventsActionFetch(eventsDatesToFetch);
    if (checkedTasksDatesToFetch.length > 0) checkedTasksActionFetch(checkedTasksDatesToFetch);
  }, [currentMonthDT]);

  if (currentMonthDT.invalid || !searchParams.month) {
    // a bit hacky without setTimeout, but it makes the UX better
    updateSearchParams(history, { month: currentMonth() });
  }

  return (
    <StandardContentTemplate
      narrowMain={false}
      topReady={!currentMonthDT.invalid}
      top={<Header currentMonthDT={currentMonthDT} />}
      mainReady={
        !currentMonthDT.invalid &&
        categories.fetched &&
        eventsDatesToFetch.length === 0 &&
        checkedTasksDatesToFetch.length === 0
      }
      main={<MonthlyCalendar currentMonthDT={currentMonthDT} />}
    />
  );
};

export default connect(prepareState(['events', 'checkedTasks', 'categories']), {
  eventsActionFetch,
  checkedTasksActionFetch,
  categoriesActionFetch,
})(MonthlyCalendarPage);
