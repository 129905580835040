import React from 'react';

export const TextIcon = ({ className = '', style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className={`fill-current ${className}`}
    style={style}
  >
    <path
      d="M9.737 0c-3.72 0-6.737 2.779-6.737 6.5s3.279 6.5 7
                 6.5v11h2v-22h5v22h1.969v-20c0-1.592.381-2 2.031-2v-2h-11.263z"
    />
  </svg>
);
export default TextIcon;
