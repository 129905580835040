export const learningQueuesActionFetch = (learningWorkspaceId) => ({
  type: 'LEARNING_QUEUES_ACTION_FETCH',
  payload: { learningWorkspaceId },
});

export const learningQueuesActionSet = (learningWorkspaceId, newElements) => ({
  type: 'LEARNING_QUEUES_ACTION_SET',
  payload: { learningWorkspaceId, newElements },
});

export const learningQueuesActionCreateElement = (element) => ({
  type: 'LEARNING_QUEUES_ACTION_CREATE_ELEMENT',
  payload: { element },
});

export const learningQueuesActionUnlock = () => ({
  type: 'LEARNING_QUEUES_ACTION_UNLOCK',
});

export const learningQueuesActionUpdateElement = (element) => ({
  type: 'LEARNING_QUEUES_ACTION_UPDATE_ELEMENT',
  payload: { element },
});

export const learningQueuesActionMarkPrivateElement = (element) => ({
  type: 'LEARNING_QUEUES_ACTION_MARK_PRIVATE_ELEMENT',
  payload: { element: element.assignValues({ private: true }) },
});

export const learningQueuesActionUnmarkPrivateElement = (element) => ({
  type: 'LEARNING_QUEUES_ACTION_UNMARK_PRIVATE_ELEMENT',
  payload: { element: element.assignValues({ private: false }) },
});

export const learningQueuesActionLockElement = (element) => ({
  type: 'LEARNING_QUEUES_ACTION_LOCK_ELEMENT',
  payload: { element },
});

export const learningQueuesActionUnlockElement = (element) => ({
  type: 'LEARNING_QUEUES_ACTION_UNLOCK_ELEMENT',
  payload: { element },
});

export const learningQueuesActionDeleteElement = (element) => ({
  type: 'LEARNING_QUEUES_ACTION_DELETE_ELEMENT',
  payload: { element },
});

export const learningQueuesActionSetCreatedElement = (element) => ({
  type: 'LEARNING_QUEUES_ACTION_SET_CREATED_ELEMENT',
  payload: { element },
});

export const learningQueuesActionSetElement = (element) => ({
  type: 'LEARNING_QUEUES_ACTION_SET_ELEMENT',
  payload: { element },
});

export const learningQueuesActionUnset = (oneOrManyElements) => ({
  type: 'LEARNING_QUEUES_ACTION_UNSET',
  payload: { oneOrManyElements },
});

export const learningQueuesActionReset = () => ({
  type: 'LEARNING_QUEUES_ACTION_RESET',
});
