import Lockable from './parents/lockable';

export default class HabitChartData extends Lockable {
  constructor() {
    super();
    this.habitChartRequestId = null;
    this.data = [];
  }

  visible() {
    return true;
  }
}
