import join from 'lodash/join';

export const imageFileExtensions = [
  '.apng',
  '.gif',
  '.ico',
  '.cur',
  '.jpg',
  '.jpeg',
  '.jfif',
  '.pjpeg',
  '.pjp',
  '.png',
  '.svg',
  '.webp',
];

export const acceptImage = join(imageFileExtensions, ',');

export const audioFileExtensions = ['.webm', '.mp3', '.wav', '.ogg', '.flac', '.aac'];

export const acceptAudio = join(audioFileExtensions, ',');
