import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import ModalBody from '../../ModalBody';
import ModalHeader from '../../ModalHeader';
import { activeElementTypes } from '../../../../constants/activeElementTypes';
import LabelledButton from '../../../buttons/LabelledButton';
import ModalFooter from '../../ModalFooter';
import { activeElementsActionDeactivate } from '../../../../stateManagement/actions/activeElementsActions';
import { TextConfirmation } from './TextConfirmation';

const ConfirmationModalContent = (props) => {
  const {
    identifier,
    onConfirmed,
    title,
    message,
    textConfirmation,
    textConfirmationPrompt,
    activeElementsActionDeactivate: deactivate,
  } = props;
  const useTextConfirmation = useMemo(
    () => textConfirmation && textConfirmationPrompt,
    [textConfirmation, textConfirmationPrompt],
  );
  const [disabled, setDisabled] = useState(useTextConfirmation);
  useEffect(() => {
    setDisabled(useTextConfirmation);
  }, [useTextConfirmation, textConfirmation]);

  const hide = useCallback(() => deactivate(activeElementTypes.confirmationModal, identifier), [identifier]);
  const confirm = useCallback(() => {
    onConfirmed();
    hide();
  }, [hide, onConfirmed]);

  return (
    <div>
      {title && (
        <ModalHeader type={activeElementTypes.confirmationModal} identifier={identifier}>
          {title}
        </ModalHeader>
      )}
      <ModalBody>
        {message}
        {textConfirmation && textConfirmationPrompt && (
          <TextConfirmation
            setDisabled={setDisabled}
            textConfirmation={textConfirmation}
            textConfirmationPrompt={textConfirmationPrompt}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <LabelledButton variant="cancel" onMouseDown={hide} />
        <LabelledButton variant="confirm" disabled={disabled} onMouseDown={confirm} />
      </ModalFooter>
    </div>
  );
};

export default connect(null, { activeElementsActionDeactivate })(ConfirmationModalContent);
