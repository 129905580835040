import { Editor, Transforms, Range } from 'slate';
import { nestedElementVariants } from '../constants/nestedElementVariants';
import { isNestableBlockVariant, recalculateBulletsOrdinals } from './blockNestingHelpers';
import { produceToggleBlockVariant } from './blockVariantHelpers';
import { blockVariants } from '../constants/blockVariants';
import last from 'lodash/last';
import { getToolbarActiveLeavesVariants, produceToggleLeafVariant } from './leafVariantHelpers';
import { setsDiff } from '../../../../../../helpers/generalHelpers';

export const produceApply = (nascentEditor) => {
  const { apply } = nascentEditor;
  return (operation) => {
    apply(operation);

    if (
      operation.type === 'split_node' ||
      (operation.type === 'set_node' && !Number.isInteger(operation.newProperties.ordinal))
    ) {
      recalculateBulletsOrdinals(nascentEditor);
    }
  };
};

export const produceIsInline = (nascentEditor) => {
  const { isInline } = nascentEditor;
  return (element) => {
    return element.type === nestedElementVariants.link ? true : isInline(element);
  };
};

export const produceInsertBreak = (nascentEditor) => {
  const { insertBreak } = nascentEditor;
  return () => {
    const previousLeavesVariants = getToolbarActiveLeavesVariants(nascentEditor);
    const [selectedElement] = Editor.parent(nascentEditor, nascentEditor.selection);

    if (selectedElement.type === nestedElementVariants.link) {
      const endPoint = Range.end(nascentEditor.selection);
      const [selectedLeaf] = Editor.node(nascentEditor, endPoint);
      const isLastLinkLeaf = last(endPoint.path) === selectedElement.children.length - 1;

      // When on the end of the link
      if (isLastLinkLeaf && selectedLeaf.text.length === endPoint.offset) {
        if (Range.isExpanded(nascentEditor.selection)) {
          Transforms.delete(nascentEditor);
        }

        Editor.withoutNormalizing(nascentEditor, () => {
          // Insert and then select a blank text
          // to ensure a new paragraph is not started with a link
          const linkEndPath = nascentEditor.selection.focus.path;
          const temporaryNodePath = [linkEndPath[0], linkEndPath[1] + 1];
          Transforms.insertNodes(
            nascentEditor,
            // text must have length > 0 to avoid its removal
            // during normalization which will occur before insertBreak invocation
            { text: ' ' },
            { at: temporaryNodePath },
          );
          Transforms.select(nascentEditor, temporaryNodePath);
        });
      }
    }

    insertBreak();

    setTimeout(() => {
      const currentLeavesVariants = getToolbarActiveLeavesVariants(nascentEditor);
      [
        ...setsDiff(previousLeavesVariants, currentLeavesVariants),
        ...setsDiff(currentLeavesVariants, previousLeavesVariants),
      ].forEach((key) => produceToggleLeafVariant(nascentEditor, key)());
    });
  };
};

export const produceDeleteBackward = (nascentEditor) => {
  const { deleteBackward } = nascentEditor;
  return (deletionVariant) => {
    if (deletionVariant === 'character' && Range.isCollapsed(nascentEditor.selection)) {
      const [blockNode] = Editor.node(nascentEditor, nascentEditor.selection, { depth: 1 });
      const [_, path] = Editor.node(nascentEditor, nascentEditor.selection);
      if (path[1] === 0 && nascentEditor.selection.anchor.offset === 0 && isNestableBlockVariant(blockNode.type)) {
        produceToggleBlockVariant(nascentEditor, blockVariants.block)(null);
        return;
      }
    }

    deleteBackward(deletionVariant);
  };
};
