import { call, delay, put } from 'redux-saga/effects';
import camelCase from 'lodash/camelCase';
import { api } from '../../../helpers/apiHelpers';
import { sessionActionLookup, sessionActionSet } from '../../actions/sessionActions';
import { sessionFromDto } from '../../../helpers/model/sessionHelpers';
import { themeActionRemove, themeActionSet } from '../../actions/themeActions';
import { quickPassActionRemoveHash, quickPassActionSetHash } from '../../actions/quickPassActions';
import { incognitoModeActionSet } from '../../actions/incognitoModeActions';
import { configActionSet } from '../../actions/configActions';

export default function* sessionLookupSaga() {
  const { ok, data } = yield call(api.users.sessions.get);
  if (ok) {
    const newSession = sessionFromDto(data);
    if (newSession.signedIn) {
      yield put(themeActionSet(camelCase(data.theme)));
      yield put(quickPassActionSetHash(data.quick_pass_hash));
      yield put(incognitoModeActionSet(data.incognito_mode));
      yield put(configActionSet(data));
    } else {
      yield put(themeActionRemove(data.theme));
      yield put(quickPassActionRemoveHash());
    }
    yield put(sessionActionSet(newSession));
  } else {
    yield delay(10000);
    yield put(sessionActionLookup());
  }
}
