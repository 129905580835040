import React from 'react';
import ThemeCrumb from '../ThemeCrumb';

const Loader = ({ visible = true, sizeClass = 'w-32 h-32' }) => {
  const visibilityClass = visible ? '' : 'invisible';
  return (
    <ThemeCrumb type="loader">
      <div className={`${visibilityClass} ${sizeClass} lds-ripple max-w-fit-content`}>
        <div></div>
        <div></div>
      </div>
    </ThemeCrumb>
  );
};

export default Loader;
