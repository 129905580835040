import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { slateToPlain } from '../../../../../helpers/textConversion/slateToPlain';
import { plainToSlate } from '../../../../../helpers/textConversion/plainToSlate';
import ThemeCrumb from '../../../../ThemeCrumb';
import OnClickOutsideWrapper from '../../../../onClickOutside/OnClickOutsideWrapper';
import compact from 'lodash/compact';

const minRows = 1;

const TextTextareaEditor = (props) => {
  const { initialValue, onClickOutside, roundedClass = 'rounded', borderClass = 'border', onModal } = props;

  const [value, setValue] = useState('');
  useEffect(() => {
    setValue(slateToPlain(initialValue));
  }, []);

  const ref = useRef(null);

  useLayoutEffect(() => {
    const el = ref.current;
    const matches = value.match(/\n/g);
    const breaks = matches ? matches.length : 0;
    el.rows = Math.max(breaks + 1, minRows);
  }, [value]);

  return (
    <OnClickOutsideWrapper
      onClickOutside={(e) => onClickOutside(e, plainToSlate(value))}
      exceptionModals={compact([onModal])}
    >
      <ThemeCrumb type="input">
        <textarea
          ref={ref}
          autoFocus
          className={`w-full resize-none focus:outline-none text-left placeholder-custom
                                ${roundedClass} ${borderClass} p-4 block`}
          value={value}
          onChange={(event) => setValue(event.target.value)}
        />
      </ThemeCrumb>
    </OnClickOutsideWrapper>
  );
};

export default TextTextareaEditor;
