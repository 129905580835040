import Lockable from './parents/lockable';
import { weekdayTypes } from '../constants/enums/weekdayTypes';
import { recurringTaskVariants } from '../constants/enums/recurringTaskVariants';
import { monthTypes } from '../constants/enums/monthTypes';
import { currentDateInBackendFormat } from '../helpers/dateTimeHelpers';

export default class RecurringTask extends Lockable {
  constructor() {
    super();
    this.id = null;
    this.categoryId = null;
    this.nodesGroupId = null;
    this.name = null;
    this.generationActive = true;
    this.startDate = currentDateInBackendFormat();
    this.variant = recurringTaskVariants.weekly;
    this.weekdayType = weekdayTypes.monday;
    this.monthDay = 1;
    this.monthType = monthTypes.january;
    this.yearDay = 1;
    this.daysCount = 30;
    this.status = null;
    this.nextGenerationDate = null;
    this.defaultOrder = 0;
    this.private = false;
  }

  visible(incognitoMode = false) {
    return incognitoMode ? !this.private : true;
  }
}
