import React from 'react';
import { initT } from '../../../helpers/i18nHelpers';
import MenuContent from '../../../shared/organizers/MenuContent';
import MenuTitle from '../../../shared/titles/MenuTitle';
import TodoList from './TodoList';

const t = initT('pages.tasks');

const Right = () => {
  return (
    <MenuContent
      top={<MenuTitle marginClass="mx-25px py-25px">{t('todoTasks.title')}</MenuTitle>}
      main={
        <div className="py-25px">
          <TodoList />
        </div>
      }
    />
  );
};

export default Right;
