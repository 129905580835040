import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { labelsActionFetch } from '../../../stateManagement/actions/labelsActions';
import FormikFieldSub from '../../form/FormikFieldSub';
import LabelSelectorField from './LabelSelectorField';

const LabelsSelector = (props) => {
  const { object, labels, labelsActionFetch } = props;

  useEffect(() => {
    if (!labels.fetched) labelsActionFetch();
  }, []);

  const onChange = () => {};

  return (
    <FormikFieldSub
      onChange={onChange}
      value={object.labelsIds}
      component={LabelSelectorField}
      labels={labels}
      object={object}
    />
  );
};

export default connect(prepareState(['labels']), {
  labelsActionFetch,
})(LabelsSelector);
