import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { dayEvaluationsActionSetElement } from '../../actions/dayEvaluationsActions';
import DayEvaluation from '../../../models/dayEvaluation';
import { dayEvaluationFromDto } from '../../../helpers/model/dayEvaluationHelpers';

export default function* fetchElementDayEvaluationsSaga(action) {
  const date = action.payload;
  const { ok, data } = yield call(api.dayEvaluations.index, { date });
  if (ok) {
    if (data) {
      yield put(dayEvaluationsActionSetElement(dayEvaluationFromDto(data)));
    } else {
      const newDayEvaluation = new DayEvaluation();
      newDayEvaluation.date = date;
      yield put(dayEvaluationsActionSetElement(newDayEvaluation));
    }
  }
}
