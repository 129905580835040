import Cookies from 'js-cookie';
import moment from 'moment';

const LAST_ACTIVITY_DATE_COOKIE_IDENTIFIER = 'last_activity_date';

export const getCookieLastActivityDate = () => {
  const cookieValue = Cookies.get(LAST_ACTIVITY_DATE_COOKIE_IDENTIFIER);
  return cookieValue ? moment(cookieValue) : undefined;
};

export const setExpiredCookieLastActivityDate = () =>
  Cookies.set(LAST_ACTIVITY_DATE_COOKIE_IDENTIFIER, moment(0).toISOString());

export const setCookieLastActivityDate = () =>
  Cookies.set(LAST_ACTIVITY_DATE_COOKIE_IDENTIFIER, moment().toISOString());

export const removeCookieLastActivityDate = () => Cookies.remove(LAST_ACTIVITY_DATE_COOKIE_IDENTIFIER);
