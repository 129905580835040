import React from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../helpers/stateManagementHelpers';
import HabitAnswerForm from './HabitAnswerForm';
import { getVisibleHabitsAndAnswersForSection } from '../../helpers/habitAnswerHelpers';
import SectionTitle from '../../shared/titles/SectionTitle';
import SubSectionTitle from '../../shared/titles/SubSectionTitle';

const HabitAnswersSection = (props) => {
  const { date, locked, section, incognitoMode, habits, habitAnswers, useSectionTitle = false } = props;
  const data = getVisibleHabitsAndAnswersForSection(section, habits, habitAnswers, date, locked, incognitoMode);
  if (data.length === 0) return <></>;

  return (
    <div>
      {useSectionTitle ? (
        <SectionTitle>{section.name}</SectionTitle>
      ) : (
        <SubSectionTitle>{section.name}</SubSectionTitle>
      )}
      <div>
        {data.map(({ answer, habit }) => (
          <HabitAnswerForm key={answer.id} habit={habit} calendarLocked={locked} section={section} {...answer} />
        ))}
      </div>
    </div>
  );
};

export default connect(prepareState(['incognitoMode', 'habits', 'habitAnswers']))(HabitAnswersSection);
