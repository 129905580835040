import Lockable from './parents/lockable';

export default class Category extends Lockable {
  constructor() {
    super();
    this.id = null;
    this.name = null;
    this.color = null;
    this.tasksOrder = 0;
    this.private = false;
  }

  visible(incognitoMode = false) {
    return incognitoMode ? !this.private : true;
  }
}
