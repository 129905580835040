import { useCallback, useEffect, useState } from 'react';
import { every } from 'lodash';
import useResizeEffect from '../../../hooks/useResizeEffect';
import {
  getNavbarHeight,
  getStandardTemplateTopHeight,
  getStandardTemplateBottomHeight,
} from '../../../helpers/elementsSizeHelpers';

const defaultCalcAvailableHeight = () => {
  const bottomMargin = 25;
  let availableHeight = window.innerHeight;
  availableHeight -= getNavbarHeight();
  availableHeight -= getStandardTemplateTopHeight();
  availableHeight -= getStandardTemplateBottomHeight();
  availableHeight -= bottomMargin;

  return Math.round(availableHeight);
};

const getHeight = (element) => {
  if (!element) return 0;
  return element.clientHeight;
};

// make sure 0.5 is rounded down
const round = (number) => Math.round(number - 0.000001);

export const useQueueRunDisplay = (props) => {
  const {
    wrapperRef,
    innerWrapperRef,
    imageAboveWrapperRef,
    textWrapperRef,
    imageBelowWrapperRef,
    minimumImageAboveHeight = 100,
    minimumTextHeight = 150,
    minimumImageBelowHeight = 100,
    calcAvailableHeight = (_defaultMethod) => _defaultMethod(),
  } = props;

  const [availableHeight, setAvailableHeight] = useState();
  const [imageAboveHeight, setImageAboveHeight] = useState();
  const [textHeight, setTextHeight] = useState();
  const [imageBelowHeight, setImageBelowHeight] = useState();
  const [marginTop, setMarginTop] = useState();

  const [initImageAboveHeight, setInitImageAboveHeight] = useState(0);
  const [initTextHeight, setInitTextHeight] = useState(0);
  const [initImageBelowHeight, setInitImageBelowHeight] = useState(0);

  const [ready, _setReady] = useState(false);
  const setReady = useCallback(
    (newValue) => {
      if (!ready && newValue) {
        // In case wrapper width is 100%, innerWrapper does not inherit its real value
        // (as it has position fixed).
        innerWrapperRef.current.style.width = `${wrapperRef.current.clientWidth}px`;
        setInitImageAboveHeight(getHeight(imageAboveWrapperRef.current));
        setInitTextHeight(getHeight(textWrapperRef.current));
        setInitImageBelowHeight(getHeight(imageBelowWrapperRef.current));
        innerWrapperRef.current.style.removeProperty('width');
      }
      _setReady(newValue);
    },
    [ready],
  );

  useEffect(() => {
    const images = Array.from(wrapperRef.current.getElementsByTagName('img'));
    setReady(images.length === 0);
  }, []);

  const onImgLoad = useCallback(() => {
    const images = Array.from(wrapperRef.current.getElementsByTagName('img'));
    if (every(images, (img) => img.complete)) {
      setReady(true);
    }
  }, []);

  const _calcAvailableHeight = useCallback(
    () => calcAvailableHeight(defaultCalcAvailableHeight),
    [calcAvailableHeight],
  );

  useResizeEffect(
    () => {
      if (!ready) return;

      const availableHeight = _calcAvailableHeight();

      const _minimumImageAboveHeight = initImageAboveHeight ? minimumImageAboveHeight : 0;
      const _minimumTextHeight = initTextHeight ? Math.min(initTextHeight, minimumTextHeight) : 0;
      const _minimumImageBelowHeight = initImageBelowHeight ? minimumImageBelowHeight : 0;

      const initJointImagesHeight = initImageAboveHeight + initImageBelowHeight;
      const availableForImages = Math.floor(availableHeight - initTextHeight);

      let _imageAboveHeight = initImageAboveHeight;
      let _imageBelowHeight = initImageBelowHeight;

      if (availableForImages < initJointImagesHeight) {
        if (initImageAboveHeight && initImageBelowHeight) {
          _imageAboveHeight = round(availableForImages * (initImageAboveHeight / initJointImagesHeight));
          _imageBelowHeight = round(availableForImages - _imageAboveHeight);

          if (_imageAboveHeight < _minimumImageAboveHeight) {
            _imageAboveHeight = _minimumImageAboveHeight;
            _imageBelowHeight = Math.max(availableForImages - _imageAboveHeight, _minimumImageBelowHeight);
          } else if (_imageBelowHeight < _minimumImageBelowHeight) {
            _imageBelowHeight = _minimumImageBelowHeight;
            _imageAboveHeight = Math.max(availableForImages - _imageBelowHeight, _minimumImageAboveHeight);
          }
        } else if (initImageAboveHeight) {
          _imageAboveHeight = Math.max(availableForImages, _minimumImageAboveHeight);
        } else if (initImageBelowHeight) {
          _imageBelowHeight = Math.max(availableForImages, _minimumImageBelowHeight);
        }
      }

      const availableForText = round(availableHeight - _imageAboveHeight - _imageBelowHeight);

      let _textHeight = initTextHeight;

      if (availableForText < initTextHeight) {
        _textHeight = Math.max(availableForText, _minimumTextHeight);
      }

      const _marginTop = Math.floor((availableHeight - _imageAboveHeight - _textHeight - _imageBelowHeight) * 0.1);

      setAvailableHeight(availableHeight);
      setImageAboveHeight(_imageAboveHeight);
      setTextHeight(_textHeight);
      setImageBelowHeight(_imageBelowHeight);
      setMarginTop(_marginTop);
    },
    [ready, _calcAvailableHeight],
    true,
  );

  return {
    ready,
    availableHeight,
    imageAboveHeight,
    textHeight,
    imageBelowHeight,
    marginTop,
    onImgLoad,
  };
};
