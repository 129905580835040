import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { snackbarMessagesActionHandleBackendErrors } from '../../actions/snackbarMessagesActions';
import { notesWorkspaceFromDto, notesWorkspaceToDto } from '../../../helpers/model/notesWorkspacesHelpers';
import {
  notesWorkspacesActionSetElement,
  notesWorkspacesActionUnlockElement,
} from '../../actions/notesWorkspacesActions';

export default function* unmarkPrivateElementNotesWorkspacesSaga(action) {
  const initialWorkspace = action.payload;
  const { ok, data } = yield call(api.notesWorkspaces.unmarkPrivate, notesWorkspaceToDto(initialWorkspace));
  if (ok) {
    yield put(notesWorkspacesActionSetElement(notesWorkspaceFromDto(data)));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(notesWorkspacesActionUnlockElement(initialWorkspace));
  }
}
