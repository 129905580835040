import MultiFetchableCollection from '../../models/auxiliary/MultiFetchableCollection';

const initialState = () => new MultiFetchableCollection();

export default function (state = initialState(), action) {
  const { dates, newElements } = action.payload || {};

  switch (action.type) {
    case 'CHECKED_TASKS_ACTION_FETCH':
      return state.toNotFetched(dates);
    case 'CHECKED_TASKS_ACTION_SET':
      return state.toFetched(dates, false).toUnlocked(false).withReplacedElements(newElements);
    case 'TASKS_ACTION_SET_ELEMENT': {
      const { checked, checkedDate } = action.payload;
      if (checked && checkedDate && state.fetched(checkedDate)) {
        return state.withReplacedElements(action.payload);
      }
      return state;
    }
    case 'GLOBAL_ACTION_RESET':
      return initialState();
    default:
      return state;
  }
}
