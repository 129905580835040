import React from 'react';
import ThemeCrumb from '../../../ThemeCrumb';
import ImageIcon from '../../../../icons/imageIcon';
import { imageSizes } from '../../../../constants/enums/imageSizes';

const ImagePresenter = (props) => {
  const { content, onImgLoad } = props;
  const { url, description, size } = content || {};

  let maxWidthClass = '';
  if (size === imageSizes.small) maxWidthClass = 'max-w-64';
  else if (size === imageSizes.medium) maxWidthClass = 'max-w-96';

  return (
    <div className="w-full">
      <div className="w-full flex justify-center">
        {url ? (
          <img className={maxWidthClass} src={url} alt={description} onLoad={onImgLoad} />
        ) : (
          <ThemeCrumb type="input">
            <ImageIcon className="w-full max-w-24" />
          </ThemeCrumb>
        )}
      </div>
      {description ? <div className="my-2 w-full text-center">{description}</div> : <></>}
    </div>
  );
};

export default ImagePresenter;
