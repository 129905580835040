import React from 'react';

export const DecreaseIndentIcon = ({ className = '', style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className={`fill-current stroke-current ${className}`}
    style={style}
  >
    <path
      d="M0 1h24v2h-24v-2zm11 7h13v-2h-13v2zm0 5h13v-2h-13v2zm0 5h13v-2h-13v2zm-11
                 5h24v-2h-24v2zm8-17l-8 6 8 6v-12z"
    />
  </svg>
);
export default DecreaseIndentIcon;
