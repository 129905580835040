const initialState = () => ({
  selectedNotesWorkspaceId: null,
  selectedLearningWorkspaceId: null,
  selectedLanguageWorkspaceId: null,
});

export default function (state = initialState(), action) {
  switch (action.type) {
    case 'CONFIG_ACTION_SET': {
      const newState = {};
      [
        ['selected_notes_workspace_id', 'selectedNotesWorkspaceId'],
        ['selected_learning_workspace_id', 'selectedLearningWorkspaceId'],
        ['selected_language_workspace_id', 'selectedLanguageWorkspaceId'],
      ].forEach(({ 0: backendKey, 1: key }) => {
        newState[key] = action.payload[backendKey];
      });
      return { ...state, ...newState };
    }
    case 'GLOBAL_ACTION_RESET': {
      if (action.payload?.skipSessionConfig) return state;
      else return initialState();
    }
    default:
      return state;
  }
}
