import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { notificationT } from '../../../helpers/i18nHelpers';
import { activeElementsActionActivateIfOtherInactive } from '../../actions/activeElementsActions';
import { activeElementTypes } from '../../../constants/activeElementTypes';
import { recurringTaskFromDto, recurringTaskToDto } from '../../../helpers/model/recurringTaskHelpers';
import { recurringTasksActionSetCreatedElement, recurringTasksActionUnlock } from '../../actions/recurringTasksActions';

export default function* createElementRecurringTasksSaga(action) {
  const { ok, data } = yield call(api.recurringTasks.create, recurringTaskToDto(action.payload));
  if (ok) {
    const newRecurringTask = recurringTaskFromDto(data);
    yield put(snackbarMessagesActionAdd(notificationT('recurringTasks.create.success')));
    yield put(recurringTasksActionSetCreatedElement(newRecurringTask));
    yield put(activeElementsActionActivateIfOtherInactive(activeElementTypes.recurringTask, newRecurringTask.id));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(recurringTasksActionUnlock());
  }
}
