import React, { useEffect } from 'react';
import NodesGroupPanel from '../nodesGroupPanel';
import CustomColorCrumb from '../../CustomColorCrumb';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { nodesActionFetch } from '../../../stateManagement/actions/nodesActions';
import LockedEntityLoader from '../../loaders/LockedEntityLoader';
import NoData from '../../placeholders/NoData';
import { initT } from '../../../helpers/i18nHelpers';
import AuxiliaryText from '../../AuxiliaryText';
import { formatDate } from '../../../helpers/dateTimeHelpers';

const t = initT('features.taskPanel');

const Details = (props) => {
  const {
    task: { todoFrom, nodesGroupId },
    color,
    nodes,
    nodesActionFetch,
  } = props;

  useEffect(() => {
    if (!nodes.fetched(nodesGroupId)) nodesActionFetch(nodesGroupId);
  }, [nodes.fetched(nodesGroupId)]);

  return (
    <CustomColorCrumb
      styleKeys={['borderColor', 'backgroundColor']}
      color={color}
      mixinThemeType="container"
      mixedStyleKeysMultipliers={{
        borderColor: 0.8,
        backgroundColor: 0.98,
      }}
    >
      <div className="w-full border-r border-l border-b rounded-b-xl">
        {todoFrom ? (
          <div className="w-full flex justify-end pt-2 pr-4">
            <AuxiliaryText widthClass="">{`${t('todoFromDetail')}: ${formatDate(todoFrom)}`}</AuxiliaryText>
          </div>
        ) : (
          <></>
        )}
        <div className="p-25px">
          <LockedEntityLoader visible={!nodes.fetched(nodesGroupId)}>
            <NodesGroupPanel nodesGroupId={nodesGroupId} noDataPlaceholder={<NoData placeholder={t('noDetails')} />} />
          </LockedEntityLoader>
        </div>
      </div>
    </CustomColorCrumb>
  );
};

export default connect(prepareState('nodes'), {
  nodesActionFetch,
})(Details);
