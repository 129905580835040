import React, { useMemo } from 'react';
import LabelledButton from '../../../shared/buttons/LabelledButton';
import { initT } from '../../../helpers/i18nHelpers';
import Habit from '../../../models/habit';
import Container from '../../../shared/organizers/containers/Container';
import HabitForm from './HabitForm';
import MainTopSpace from '../../../shared/organizers/MainTopSpace';
import useNarrowMainWidth from '../../../hooks/useNarrowMainWidth';

const t = initT('pages.habits');

const HabitAdditionPanel = (props) => {
  const { section, additionMode, setAdditionMode } = props;

  const newHabit = useMemo(() => new Habit().assignValues({ habitsSectionId: section.id }), [section.id]);

  const activeWidth = useNarrowMainWidth(false);

  return (
    <>
      <MainTopSpace>
        <LabelledButton
          variant="add"
          wrapperClass={additionMode ? 'invisible' : ''}
          onMouseDown={() => setAdditionMode(true)}
        >
          {t('habit.add')}
        </LabelledButton>
      </MainTopSpace>
      {additionMode ? (
        <div className="w-full flex justify-center px-25px">
          <div style={{ width: activeWidth }}>
            <Container>
              <HabitForm
                {...newHabit}
                editionMode
                deactivateEditionMode={() => {
                  setAdditionMode(false);
                }}
              />
            </Container>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default HabitAdditionPanel;
