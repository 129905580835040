import { allPossibleProps, propsWhitelists } from '../constants/propsWhitelists';

export const sanitizeProps = (props, unsetting = false) => {
  const result = {};
  const whitelist = propsWhitelists[props.type];

  if (unsetting) {
    [...allPossibleProps, ...Object.keys(props)].forEach((key) => {
      result[key] = undefined;
    });
  }

  whitelist.forEach((allowedKey) => {
    result[allowedKey] = props[allowedKey];
  });

  return result;
};
