import Lockable from './parents/lockable';
import { getUuid } from '../helpers/identifierHelpers';
import { aggregationMethods } from '../constants/enums/aggregationMethods';
import { habitChartRequestVariants } from '../constants/enums/habitChartRequestVariants';

export default class HabitChartRequest extends Lockable {
  constructor() {
    super();
    this.id = null;
    this.nestedAttributeUniqueIdentifier = getUuid();
    this.aggregationMethod = aggregationMethods.valuesAverage;
    this.variant = habitChartRequestVariants.weekly;
  }

  visible() {
    return true;
  }
}
