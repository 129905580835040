import frenchkiss from 'frenchkiss';

frenchkiss.extend('en', {
  pages: {
    learningWorkspaces: {
      selection: {
        title: 'Workspace selection',
        enableEdition: 'Edit workspaces',
      },
      edition: {
        title: 'Edit workspaces',
        add: 'Add workspace',
        newWorkspaceName: 'New Workspace',
      },
    },
  },
});
