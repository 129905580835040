import React, { useEffect, useRef, useState } from 'react';
import NodeAdditionButton from './NodeAdditionButton';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import List from '../../lists/List';
import { draggedElements } from '../../../constants/draggedElements';
import NodePanel from './nodePanel';
import find from 'lodash/find';
import { activeElementTypes } from '../../../constants/activeElementTypes';
import { activeElementsActionDeactivateAllOfType } from '../../../stateManagement/actions/activeElementsActions';
import NoData from '../../placeholders/NoData';
import some from 'lodash/some';
import { getTopLevelConsts } from './getTopLevelConsts';

export const NodesGroupPanel = (props) => {
  const {
    nodes,
    incognitoMode,
    nodesGroupId,
    activeElements,
    noDataPlaceholder = <NoData />,
    editionMode = false,
    activeElementsActionDeactivateAllOfType: deactivateAll,
    onModal,
    audioEnabledForVariants = [],
    labelsEnabledForVariants = [],
  } = props;

  const activeNode = find(activeElements, { type: activeElementTypes.node });

  const draggableListRef = useRef(null);
  const { visibleNodes, determineMarginClass } = getTopLevelConsts({ editionMode, nodesGroupId, nodes, incognitoMode });
  const someLocked = some(visibleNodes, { locked: true });
  const [dragged, setDragged] = useState(false);

  useEffect(() => () => deactivateAll(activeElementTypes.nodeCreator), []);

  return (
    <>
      <List
        collection={visibleNodes}
        draggable={
          editionMode && !someLocked
            ? {
                ref: draggableListRef,
                draggedElementType: draggedElements.node,
              }
            : false
        }
        onDragStart={() => setTimeout(() => setDragged(true))}
        onDragEnd={() => setTimeout(() => setDragged(false))}
        noDataPlaceholder={editionMode ? null : noDataPlaceholder}
      >
        {(node) => (
          <div key={node.id} className={`relative ${determineMarginClass(node)}`}>
            {editionMode ? (
              <div
                className={`absolute top-0 w-full flex justify-center
                                                 items-center ${dragged ? 'invisible' : ''}
                                                 ${activeNode ? 'opacity-30' : ''}`}
              >
                <NodeAdditionButton
                  nodesGroupId={nodesGroupId}
                  successorId={node.id}
                  nodesLocked={nodes.locked}
                  onModal={onModal}
                />
              </div>
            ) : null}
            <NodePanel
              node={node}
              editionAllowed={editionMode}
              draggableListRef={draggableListRef}
              dragged={dragged}
              audioEnabledForVariants={audioEnabledForVariants}
              labelsEnabledForVariants={labelsEnabledForVariants}
              {...props}
            />
          </div>
        )}
      </List>
      {editionMode ? (
        <div
          className={`w-full pb-25px flex justify-center ${dragged ? 'invisible' : ''}
                                 ${activeNode ? 'opacity-30' : ''}`}
        >
          <NodeAdditionButton nodesGroupId={nodesGroupId} nodesLocked={nodes.locked} />
        </div>
      ) : null}
    </>
  );
};

export default connect(prepareState(['nodes', 'incognitoMode', 'activeElements']), {
  activeElementsActionDeactivateAllOfType,
})(NodesGroupPanel);
