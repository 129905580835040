import React from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../helpers/stateManagementHelpers';
import { eventsActionFetch } from '../../stateManagement/actions/eventsActions';
import Found from './Found';
import EventPanel from '../../shared/features/eventPanel';

const FoundEvents = (props) => {
  const { dates, needle, events, incognitoMode, eventsActionFetch } = props;

  return (
    <Found
      needle={needle}
      dates={dates}
      collection={events}
      dateAttributeName="date"
      searchedAttributeName="name"
      incognitoMode={incognitoMode}
      fetchAction={eventsActionFetch}
      renderFoundElement={(event) => (
        <div className="first:pt-0 pt-2" key={event.id}>
          <EventPanel event={event} disabled />
        </div>
      )}
    />
  );
};

export default connect(prepareState(['events', 'incognitoMode']), { eventsActionFetch })(FoundEvents);
