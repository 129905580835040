import React, { useCallback, useEffect, useState } from 'react';
import get from 'lodash/get';
import Header from './Header';
import { categoriesActionFetch } from '../../stateManagement/actions/categoriesActions';
import { tasksActionFetch, tasksActionGeneratedLookup } from '../../stateManagement/actions/tasksActions';
import { connect } from 'react-redux';
import { prepareState } from '../../helpers/stateManagementHelpers';
import DayEvaluation from './DayEvaluation';
import { habitsSectionsActionFetch } from '../../stateManagement/actions/habitsSectionsActions';
import { habitsActionFetch } from '../../stateManagement/actions/habitActions';
import { dayEvaluationsActionFetchElement } from '../../stateManagement/actions/dayEvaluationsActions';
import { habitAnswersActionFetch } from '../../stateManagement/actions/habitAnswersActions';
import StandardContentTemplate from '../../shared/contentTemplates/StandardContentTemplate/index';
import { checkedTasksActionFetch } from '../../stateManagement/actions/checkedTasksActions';
import Main from './Main';
import Menu from './Menu';
import CheckedTasks from './CheckedTasks';
import { eventsActionFetch } from '../../stateManagement/actions/eventsActions';
import { currentDateInBackendFormat, isDateInBackendFormat } from '../../helpers/dateTimeHelpers';
import useSearchParamsObject from '../../hooks/useSearchParamsObject';
import { useHistory } from 'react-router-dom';
import { updateSearchParams } from '../../helpers/locationHelpers';
import { isLocked } from './calendarLockHelpers';

const CalendarPage = ({
  categories,
  tasks,
  habitsSections,
  habits,
  habitAnswers,
  dayEvaluations,
  checkedTasks,
  events,
  categoriesActionFetch,
  tasksActionFetch,
  habitsSectionsActionFetch,
  habitsActionFetch,
  dayEvaluationsActionFetchElement,
  habitAnswersActionFetch,
  checkedTasksActionFetch,
  tasksActionGeneratedLookup,
  eventsActionFetch,
}) => {
  const history = useHistory();
  const searchParams = useSearchParamsObject();
  const { date = currentDateInBackendFormat() } = searchParams;
  const [locked, setLocked] = useState(isLocked(date));
  const updateLocked = useCallback(() => {
    const newLocked = isLocked(date);
    setLocked(newLocked);
    habitAnswersActionFetch(date, newLocked);
  }, [date]);
  useEffect(updateLocked, [date]);

  useEffect(() => {
    if (!categories.fetched) categoriesActionFetch();
    if (!tasks.fetched) tasksActionFetch();
    if (!habitsSections.fetched) habitsSectionsActionFetch();
    if (!habits.fetched) habitsActionFetch();
  }, []);

  useEffect(() => {
    if (tasks.fetched) tasksActionGeneratedLookup(date);
  }, [tasks.fetched, date]);

  useEffect(() => {
    const dayEvaluationFetched = dayEvaluations.find({ date: date });
    if (!dayEvaluationFetched) dayEvaluationsActionFetchElement(date);
    const habitAnswersFetched = get(habitAnswers, `[${date}].fetched`, false);
    // use isLocked(date) instead of locked, because locked may not be updated yet
    if (!habitAnswersFetched) habitAnswersActionFetch(date, isLocked(date));
    const checkedTasksFetched = checkedTasks.fetched(date);
    if (!checkedTasksFetched) checkedTasksActionFetch(date);
    if (!events.fetched(date)) eventsActionFetch([date]);
  }, [date]);

  const habitAnswersFetched = get(habitAnswers, `[${date}].fetched`, false);
  const checkedTasksFetched = checkedTasks.fetched(date);

  if (!isDateInBackendFormat(date) || !searchParams.date) {
    // a bit hacky without setTimeout, but it makes the UX better
    updateSearchParams(history, { date: currentDateInBackendFormat() });
  }

  return (
    <StandardContentTemplate
      top={<Header locked={locked} date={date} history={history} updateLocked={updateLocked} />}
      leftReady={habitsSections.fetched}
      left={<Menu date={date} />}
      mainReady={categories.fetched && tasks.fetched && habitsSections.fetched && habits.fetched && habitAnswersFetched}
      main={<Main date={date} locked={locked} />}
      rightReady={tasks.fetched && checkedTasksFetched}
      right={<CheckedTasks date={date} />}
      bottomReady={dayEvaluations.fetched && !!dayEvaluations.find({ date })}
      bottom={<DayEvaluation date={date} locked={locked} />}
    />
  );
};

export default connect(
  prepareState([
    'categories',
    'tasks',
    'habitsSections',
    'habits',
    'habitAnswers',
    'dayEvaluations',
    'checkedTasks',
    'events',
  ]),
  {
    categoriesActionFetch,
    tasksActionFetch,
    habitsSectionsActionFetch,
    habitsActionFetch,
    dayEvaluationsActionFetchElement,
    habitAnswersActionFetch,
    checkedTasksActionFetch,
    tasksActionGeneratedLookup,
    eventsActionFetch,
  },
)(CalendarPage);
