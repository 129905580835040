import { produceValuesFilter } from '../../../helpers/formHelpers';
import * as yup from 'yup';
import { enumT, frontEndErrorT, initT } from '../../../helpers/i18nHelpers';
import { habitHidingConditionVariants } from '../../../constants/enums/habitHidingConditionVariants';
import toLower from 'lodash/toLower';

export const t = initT('pages.habits.habit');

export const relevantAttributes = [
  'id',
  'habitsSectionId',
  'name',
  'inputType',
  'variant',
  'considerOnlyUpdatedValues',
  'hidden',
  'private',
  {
    habitHidingConditions: [
      'id',
      'nestedAttributeUniqueIdentifier',
      'timesRequired',
      'variant',
      'weekdayType',
      'customPeriodDaysCounter',
    ],
    habitSummaryRequests: [
      'id',
      'nestedAttributeUniqueIdentifier',
      'aggregationMethod',
      'variant',
      'weekdayType',
      'customPeriodDaysCounter',
    ],
    habitChartRequests: ['id', 'nestedAttributeUniqueIdentifier', 'aggregationMethod', 'variant'],
  },
];

export const auxiliaryAttributes = [
  'incognitoMode',
  'habitsSections',
  'habitHeaderParams',
  'editionMode',
  'deactivateEditionMode',
  'deactivateContainer',
];

export const filterValues = produceValuesFilter(relevantAttributes);

export const validationSchema = () =>
  yup.object().shape({
    name: yup.string().required(frontEndErrorT('habit.name.empty')),
    inputType: yup.string().required(frontEndErrorT('habit.name.empty')),
    variant: yup.string().required(frontEndErrorT('habit.name.empty')),
  });

export const hidingConditionT = (hidingCondition) => {
  let props = { times: hidingCondition.timesRequired };
  if (hidingCondition.variant === habitHidingConditionVariants.sinceWeekday) {
    const weekday = enumT(`weekdayTypes.${hidingCondition.weekdayType}`);
    return t('hidingConditions.sinceWeekday', { ...props, weekday });
  }
  props = { ...props, daysCounter: hidingCondition.customPeriodDaysCounter };
  return t('hidingConditions.sinceCustomPeriod', props);
};

export const summaryRequestT = (summaryRequest) => {
  let suffix;
  if (summaryRequest.variant === habitHidingConditionVariants.sinceWeekday) {
    const weekday = enumT(`weekdayTypes.${summaryRequest.weekdayType}`);
    suffix = t('summaryRequests.variants.sinceWeekday', { weekday });
  } else if (summaryRequest.variant === habitHidingConditionVariants.sinceCustomPeriod) {
    const props = { daysCounter: summaryRequest.customPeriodDaysCounter };
    suffix = t('summaryRequests.variants.sinceCustomPeriod', props);
  } else {
    suffix = t(`summaryRequests.variants.${summaryRequest.variant}`);
  }
  return `${enumT(`aggregationMethods.${summaryRequest.aggregationMethod}`)} ${suffix}`;
};

export const chartRequestT = (chartRequest) => {
  const prefix = enumT(`aggregationMethods.${chartRequest.aggregationMethod}`);
  const suffix = toLower(enumT(`habitChartRequestVariants.${chartRequest.variant}`));
  return `${prefix} ${t('chartRequests.requestSummaryMiddlePart')} ${suffix}`;
};
