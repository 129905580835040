import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { snackbarMessagesActionHandleBackendErrors } from '../../actions/snackbarMessagesActions';
import { eventFromDto, eventToDto } from '../../../helpers/model/eventHelpers';
import { eventsActionSetElement, eventsActionUnlockElement } from '../../actions/eventsActions';

export default function* markPrivateElementEventsSaga(action) {
  const { element: initialEvent } = action.payload;
  const { ok, data } = yield call(api.events.markPrivate, eventToDto(initialEvent));
  if (ok) {
    yield put(eventsActionSetElement(eventFromDto(data)));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(eventsActionUnlockElement(initialEvent));
  }
}
