import React, { useState } from 'react';
import TextField from '../../../form/fieldTypes/TextField';
import FormikFieldSub from '../../../form/FormikFieldSub';
import ThemeCrumb from '../../../ThemeCrumb';

export const TextConfirmation = (props) => {
  const { setDisabled, textConfirmation, textConfirmationPrompt } = props;

  const [value, setValue] = useState('');

  return (
    <div className="mt-25px">
      <div>{textConfirmationPrompt}</div>
      <ThemeCrumb type="modalBodyDenotation">
        <div className="w-full text-center p-2">{textConfirmation}</div>
      </ThemeCrumb>
      <FormikFieldSub
        component={TextField}
        value={value}
        onChange={({ event }) => {
          setValue(event.target.value);
          setDisabled(event.target.value !== textConfirmation);
        }}
        additionalInputProps={{
          onPaste: (e) => {
            e.preventDefault();
          },
        }}
      />
    </div>
  );
};
