import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { notificationT } from '../../../helpers/i18nHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { recurringTasksActionUnset, recurringTasksActionUnlockElement } from '../../actions/recurringTasksActions';

export default function* deleteElementRecurringTasksSaga(action) {
  const initialRecurringTask = action.payload;
  const { ok, data } = yield call(api.recurringTasks.delete, initialRecurringTask.id);
  if (ok) {
    yield put(snackbarMessagesActionAdd(notificationT('recurringTasks.delete.success')));
    yield put(recurringTasksActionUnset(initialRecurringTask));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(recurringTasksActionUnlockElement(initialRecurringTask));
  }
}
