import { produceModelFromDto, produceModelToDto } from './modelHelpers';
import HabitSummary from '../../models/habitSummary';

const mappings = [
  ['summary_request_id', 'summaryRequestId'],
  ['summary_value', 'summaryValue'],
];

export const habitSummaryFromDto = produceModelFromDto(HabitSummary, mappings);

export const habitSummaryToDto = produceModelToDto(mappings);
