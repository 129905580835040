import React from 'react';

const ExchangeableContents = (props) => {
  const { leaderVisible = true, children, leader = children, follower } = props;
  const leaderVisibilityClass = leaderVisible ? '' : 'invisible';
  return (
    <div className="w-full relative">
      {!leaderVisible && (
        <div className="w-full h-full flex justify-center items-center absolute inset-0">{follower}</div>
      )}
      <div className={`w-full ${leaderVisibilityClass}`}>{leader}</div>
    </div>
  );
};

export default ExchangeableContents;
