import { useCallback, useEffect, useState } from 'react';
import { getThemeTypeDefinition } from '../../../../helpers/themeHelpers';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions';
import CursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor';
import { applyMask, fetchDecodedBuffer, fetchDecodedBufferFromSrc } from './utils';
import { formatTime } from '../../../../helpers/dateTimeHelpers';
import { useCheckIfMounted } from '../../../../hooks/useCheckIfMounted';
import { compact } from 'lodash';

export const useWavesurfer = (props) => {
  const {
    timerId,
    durationId,
    src,
    file,
    mask,
    theme,
    waveHeight,
    waveFormId,
    disabled,
    audioBuffers,
    audioConfigActionUnlock,
    audioConfigActionSetAudioBuffer,
    onAudioProcess,
    onFinish,
  } = props;

  const checkIfMounted = useCheckIfMounted();

  const [audioBuffer, setAudioBuffer] = useState(undefined);
  const [wavesurfer, setWavesurfer] = useState(null);

  const updateTime = useCallback((seconds) => {
    const timer = document.getElementById(timerId);
    if (!timer) return;

    const time = formatTime(seconds);
    if (timer.innerHTML !== time) timer.innerHTML = time;
  }, []);

  const produceOnAudioProcess = useCallback(
    (duration) => (seconds) => {
      if (onAudioProcess) onAudioProcess({ seconds, duration });
      updateTime(seconds);
    },
    [updateTime, onAudioProcess],
  );

  const updateDuration = useCallback((seconds) => {
    const duration = document.getElementById(durationId);
    if (!duration) return;

    const time = formatTime(seconds);
    if (duration.innerHTML !== time) duration.innerHTML = time;
  }, []);

  useEffect(() => {
    (async () => {
      setAudioBuffer(undefined);
      if (!src && !file) return;

      let newAudioBuffer;
      if (file) newAudioBuffer = await fetchDecodedBuffer(file);
      else {
        newAudioBuffer = audioBuffers[src];
        if (!newAudioBuffer) {
          newAudioBuffer = await fetchDecodedBufferFromSrc(src);
          audioConfigActionSetAudioBuffer(src, newAudioBuffer);
        }
      }

      if (!checkIfMounted()) return;

      setAudioBuffer(applyMask(newAudioBuffer, mask));
    })();
  }, [src, file, mask]);

  useEffect(() => {
    if (audioBuffer === undefined) {
      setWavesurfer(null);
      return;
    }

    const mainColor = getThemeTypeDefinition(theme, 'main').color;
    const colors = getThemeTypeDefinition(theme, 'chartData');

    const newWavesurfer = WaveSurfer.create({
      container: '#' + waveFormId,
      cursorColor: mainColor,
      waveColor: colors.backgroundColor,
      progressColor: colors.borderColor,
      height: waveHeight,
      hideScrollbar: true,
      interact: !disabled,
      plugins: compact([
        RegionsPlugin.create(),
        disabled ? undefined : CursorPlugin.create({ color: mainColor, opacity: 0.25 }),
      ]),
    });
    if (audioBuffer !== null) newWavesurfer.loadDecodedBuffer(audioBuffer);
    newWavesurfer.on('region-in', (region) => {
      // add 0.0001s for consistency with on "seek" callback
      const seekTo = (region.end + 0.0001) / newWavesurfer.getDuration();
      if (seekTo >= 1.0) {
        audioConfigActionUnlock(waveFormId);
        newWavesurfer.seekTo(0);
      } else newWavesurfer.seekTo(seekTo);
    });
    newWavesurfer.on('audioprocess', produceOnAudioProcess(newWavesurfer.getDuration()));
    newWavesurfer.on('seek', (progress) => {
      const duration = newWavesurfer.getDuration();
      const time = progress * duration;
      updateTime(time);

      const regions = Object.values(newWavesurfer.regions.list);
      let realSeekToInSecs;
      regions.forEach(({ start, end }) => {
        const valueToCheck = realSeekToInSecs || time;
        if (start <= valueToCheck && valueToCheck <= end)
          // add 0.0001s to avoid an infinite loop
          realSeekToInSecs = end + 0.0001;
      });
      if (realSeekToInSecs) newWavesurfer.seekTo(realSeekToInSecs / duration);
    });
    newWavesurfer.on('finish', () => {
      audioConfigActionUnlock(waveFormId);
      newWavesurfer.seekTo(0);
      onFinish({ duration: newWavesurfer.getDuration() });
    });
    updateTime(0);
    updateDuration(newWavesurfer.getDuration());
    setWavesurfer(newWavesurfer);

    return () => {
      if (newWavesurfer) newWavesurfer.destroy();
    };
  }, [audioBuffer, theme, disabled]);

  useEffect(() => {
    const mainColor = getThemeTypeDefinition(theme, 'main').color;
    const dStyle = document.querySelector('#audio-controller-style');

    dStyle.innerHTML = `.wavesurfer-handle {
                background-color: ${mainColor} !important;
            }`;
  }, [theme]);

  return wavesurfer;
};
