import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { tasksActionUnset, tasksActionUnlockElement } from '../../actions/tasksActions';
import { notificationT } from '../../../helpers/i18nHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';

export default function* deleteElementTasksSaga(action) {
  const initialTask = action.payload;
  const { ok, data } = yield call(api.tasks.delete, initialTask.id);
  if (ok) {
    yield put(snackbarMessagesActionAdd(notificationT('tasks.delete.success')));
    yield put(tasksActionUnset(initialTask));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(tasksActionUnlockElement(initialTask));
  }
}
