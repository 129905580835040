import frenchkiss from 'frenchkiss';

frenchkiss.extend('en', {
  modals: {
    habitChartRequestModal: {
      title: 'New chart to show',
      labels: {
        aggregationMethodFollowUp: 'of values aggregated:',
      },
      submit: 'Submit',
    },
  },
});
