import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

export const useWorkspace = (props) => {
  const { languageWorkspaces, incognitoMode, config, languageWorkspacesActionFetch } = props;

  const history = useHistory();

  useEffect(() => {
    if (!languageWorkspaces.fetched) languageWorkspacesActionFetch();
  }, []);

  let workspace = {};
  const workspaceCandidates = languageWorkspaces.visible(incognitoMode, { id: config.selectedLanguageWorkspaceId });
  if (languageWorkspaces.fetched) {
    if (workspaceCandidates.length === 1) workspace = workspaceCandidates[0];
    // a bit hacky without setTimeout, but it makes the UX better
    else history.replace('/language/workspaces');
  }
  const workspaceSelected = workspace.id !== undefined;

  return [workspaceSelected, workspace];
};
