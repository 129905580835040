import React from 'react';
import NoData from '../placeholders/NoData';
import DraggableList from './DraggableList';

const List = (props) => {
  const {
    collection = [],
    wrapper,
    draggable,
    draggingDisabled,
    children,
    widthClass = 'w-full',
    heightClass = 'h-full',
    className = '',
    noDataPlaceholder = <NoData />,
    noDataCondition = collection.length === 0,
    onDragStart,
    onDragMove,
    onDragEnd,
    onDragSuccess,
    ...otherProps
  } = props;

  const wrapContent = (content) => (
    <div className={`${widthClass} ${heightClass} ${className}`} {...otherProps}>
      {content}
    </div>
  );

  if (noDataCondition) return wrapContent(noDataPlaceholder);

  let content = collection.map((element, index) => children(element, index));

  if (draggable) {
    content = (
      <DraggableList
        {...draggable}
        onDragStart={onDragStart}
        onDragMove={onDragMove}
        onDragEnd={onDragEnd}
        onDragSuccess={onDragSuccess}
        draggingDisabled={draggingDisabled}
      >
        {content}
      </DraggableList>
    );
  }
  content = wrapContent(content);

  if (wrapper) {
    return React.createElement(wrapper, {}, content);
  }
  return content;
};

export default List;
