import React, { useEffect, useState } from 'react';
import { generateTagId, getUuid } from '../../../helpers/identifierHelpers';
import ErrorWrapper from './auxiliary/ErrorWrapper';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import ThemeCrumb from '../../ThemeCrumb';
import { getThemeType } from '../../../helpers/formHelpers';
import { filesActionUploadElement } from '../../../stateManagement/actions/filesActions';
import LockedEntityLoader from '../../loaders/LockedEntityLoader';
import AddIcon from '../../../icons/addIcon';
import { maxFileSizeInMb } from '../../../constants/files';
import { get } from 'lodash';

const DefaultLabelComponent = (props) => {
  const { id } = props;

  return (
    <div className="w-full flex justify-center">
      <div className="w-4 h-4">
        <label htmlFor={id} className="cursor-pointer">
          <AddIcon className="w-4 h-4" />
        </label>
      </div>
    </div>
  );
};

const FileField = (props) => {
  const {
    id = generateTagId(),
    labelComponent = DefaultLabelComponent,
    themeType = 'input',
    innerRef,
    displayError,
    fixedErrorHeight,
    handleErrorThemeType,
    handleDisabledThemeType,
    handlePresentationModeThemeType,
    disabled = false,
    presentationMode = false,
    onChange = (onChangeProps, _defaultOnChange) => _defaultOnChange(onChangeProps),
    onUpload = (onUploadProps, _defaultOnUpload) => _defaultOnUpload(onUploadProps),
    onError = (onErrorProps, _defaultOnError) => _defaultOnError(onErrorProps),
    field: { name, value },
    form: { errors, touched, setFieldTouched, setFieldValue },
    files,
    filesActionUploadElement,
    accept,
  } = props;

  const [currentFileId, setCurrentFileId] = useState(null);
  const currentFile = currentFileId && files.find({ id: currentFileId });

  const defaultOnChange = async (e) => {
    await setFieldTouched(name);
    const { files } = e.target;
    if (files.length === 0) return;

    const file = e.target.files[0];
    if (file.size > maxFileSizeInMb * 1024 * 1024) return;

    const newFileId = getUuid();
    setCurrentFileId(newFileId);
    filesActionUploadElement(newFileId, file);
  };
  const defaultOnUpload = async ({ url }) => {
    await setFieldValue(name, url);
    await setFieldTouched(name);
  };
  const defaultOnError = async () => {
    setCurrentFileId(null);
    await setFieldTouched(name);
  };

  useEffect(() => {
    if (currentFile && !currentFile.loading && !currentFile.failed) onUpload(currentFile, defaultOnUpload);
  }, [(currentFile || {}).loading]);
  useEffect(() => {
    if (currentFile && currentFile.failed) onError({}, defaultOnError);
  }, [(currentFile || {}).failed]);

  const error = get(touched, name) ? get(errors, name) : null;
  const actualThemeType = getThemeType({
    themeType,
    error,
    handleErrorThemeType,
    presentationMode,
    handlePresentationModeThemeType,
    disabled,
    handleDisabledThemeType,
  });

  return (
    <LockedEntityLoader visible={currentFile && currentFile.loading}>
      <ErrorWrapper displayError={displayError} fixedErrorHeight={fixedErrorHeight} error={error}>
        <ThemeCrumb type={actualThemeType}>{React.createElement(labelComponent, { id, value, error })}</ThemeCrumb>
      </ErrorWrapper>
      <input
        id={id}
        ref={innerRef}
        type="file"
        className="hidden"
        disabled={disabled || presentationMode}
        onChange={(e) => onChange(e, defaultOnChange)}
        accept={accept}
      />
    </LockedEntityLoader>
  );
};

export default connect(prepareState(['files']), { filesActionUploadElement })(FileField);
