import React, { useCallback } from 'react';
import CenteredContent from '../../shared/organizers/CenteredContent';
import IconButton from '../../shared/buttons/IconButton';
import H1 from '../../shared/titles/H1';
import { initT } from '../../helpers/i18nHelpers';
import { displayMonthFormat } from '../../constants/dateFormat';
import { useHistory } from 'react-router-dom';
import { updateSearchParams } from '../../helpers/locationHelpers';

const t = initT('pages.monthlyCalendar');

const Header = (props) => {
  const { currentMonthDT } = props;

  const history = useHistory();

  const produceAddMonth = useCallback(
    (value) => () => {
      const month = currentMonthDT.plus({ month: value }).toFormat(displayMonthFormat);
      updateSearchParams(history, { month });
    },
    [history, currentMonthDT],
  );

  const monthPicker = useCallback(
    (small) => (
      <div className="flex justify-center items-center" style={small ? { maxWidth: 200 } : {}}>
        <IconButton variant="arrowLeft" tooltip={t('previousMonth')} onMouseDown={produceAddMonth(-1)} />
        <div className="w-60 flex justify-center items-center">
          {small ? (
            <div>
              <H1 textSizeClass="text-lg">{currentMonthDT.toFormat(displayMonthFormat)}</H1>
            </div>
          ) : (
            <H1>{currentMonthDT.toFormat(displayMonthFormat)}</H1>
          )}
        </div>
        <IconButton variant="arrowRight" tooltip={t('nextMonth')} onMouseDown={produceAddMonth(1)} />
      </div>
    ),
    [currentMonthDT, produceAddMonth],
  );

  return (
    <div className="w-full flex items-center">
      <CenteredContent
        className="h-40px flex"
        minMainWidthPx={200}
        main={
          <>
            <div className="sm:hidden">{monthPicker(true)}</div>
            <div className="hidden sm:block">{monthPicker(false)}</div>
          </>
        }
        right={
          <div className="px-2 sm:px-25px">
            <IconButton variant="search" to="/finder" tooltip={t('finderTooltip')} />
          </div>
        }
      />
    </div>
  );
};

export default Header;
