import React, { useCallback, useState } from 'react';
import LockedEntityLoader from '../../shared/loaders/LockedEntityLoader';
import { TitleEditor } from '../../shared/features/titleEditor';
import {
  learningQueuesActionDeleteElement,
  learningQueuesActionMarkPrivateElement,
  learningQueuesActionSetElement,
  learningQueuesActionUnmarkPrivateElement,
  learningQueuesActionUpdateElement,
} from '../../stateManagement/actions/learningQueuesActions';
import { connect } from 'react-redux';
import { prepareState } from '../../helpers/stateManagementHelpers';
import QueueHeader from './QueueHeader';
import H1 from '../../shared/titles/H1';
import { initT } from '../../helpers/i18nHelpers';
import QueueRunHeader from './queueRun/QueueRunHeader';

const t = initT('pages.learning');

const getContent = (props) => {
  const { queue, creationMode, runMode, titleEditionMode, handleSubmit } = props;

  if (titleEditionMode) return <TitleEditor title={queue.name} handleSubmit={handleSubmit} />;

  if (creationMode)
    return (
      <div className="h-40px flex items-center">
        <H1>{t('createQueue')}</H1>
      </div>
    );

  if (runMode) return <QueueRunHeader {...props} />;

  return <QueueHeader {...props} />;
};

const Top = (props) => {
  const { queue, learningQueuesActionUpdateElement: update } = props;

  const [titleEditionMode, setTitleEditionMode] = useState(false);

  const handleSubmit = useCallback(({ title }) => {
    if (queue.name !== title) update(queue.shallowClone().assignValues({ ...queue, name: title }));
    setTitleEditionMode(false);
  }, []);

  const onH1DoubleClick = useCallback(() => {
    setTitleEditionMode(true);
  }, []);

  return (
    <div className="w-full h-40px">
      <LockedEntityLoader visible={queue.locked}>
        {getContent({ ...props, titleEditionMode, handleSubmit, onH1DoubleClick })}
      </LockedEntityLoader>
    </div>
  );
};

export default connect(prepareState(['incognitoMode']), {
  learningQueuesActionMarkPrivateElement,
  learningQueuesActionUnmarkPrivateElement,
  learningQueuesActionSetElement,
  learningQueuesActionUpdateElement,
  learningQueuesActionDeleteElement,
})(Top);
