import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { labelsActionFetch } from '../../../stateManagement/actions/labelsActions';
import sortBy from 'lodash/sortBy';
import join from 'lodash/join';
import AuxiliaryText from '../../AuxiliaryText';
import ThemeCrumb from '../../ThemeCrumb';
import CompactAudioController from '../audioController/CompactAudioController';
import { initT } from '../../../helpers/i18nHelpers';

const t = initT('features.nodesGroupPanel');

const AdditionalInformation = (props) => {
  const {
    node: { audioUrl, audioMask, labelsIds },
    labels,
    incognitoMode,
    labelsActionFetch,
    mouseOver,
  } = props;

  useEffect(() => {
    if (!labels.fetched) labelsActionFetch();
  }, [labels.fetched]);

  const visibleLabels = sortBy(
    labels.visible(incognitoMode).filter((label) => labelsIds.includes(label.id)),
    'name',
  );
  const labelsString = join(
    visibleLabels.map((label) => label.name),
    ', ',
  );

  const wrapperClass = `absolute bottom-0 flex w-full items-center ${mouseOver ? '' : 'invisible'}`;

  return (
    <div className="relative">
      <div className={wrapperClass}>
        {audioUrl ? (
          <ThemeCrumb type="main">
            <div className="z-10 pb-0.5 pt-1.5">
              <CompactAudioController
                src={audioUrl}
                mask={audioMask}
                playTooltip={t('playAudio')}
                stopTooltip={t('stopAudio')}
              />
            </div>
          </ThemeCrumb>
        ) : (
          <></>
        )}
        {labelsString ? (
          <ThemeCrumb type="main">
            <div className={`z-10 pb-0.5 pt-1.5 ${audioUrl ? 'px-2' : 'pr-2'}`}>
              <AuxiliaryText>{labelsString}</AuxiliaryText>
            </div>
          </ThemeCrumb>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default connect(prepareState(['labels', 'incognitoMode']), { labelsActionFetch })(AdditionalInformation);
