import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { notificationT } from '../../../helpers/i18nHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { learningQueuesActionUnlockElement, learningQueuesActionUnset } from '../../actions/learningQueuesActions';

export default function* deleteElementLearningQueuesSaga(action) {
  const { element: initialQueue } = action.payload;
  const { ok, data } = yield call(api.learningQueues.delete, initialQueue.id);
  if (ok) {
    yield put(snackbarMessagesActionAdd(notificationT('learningQueues.delete.success')));
    yield put(learningQueuesActionUnset(initialQueue));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(learningQueuesActionUnlockElement(initialQueue));
  }
}
