import React, { useMemo } from 'react';
import useNarrowMainWidth from '../../hooks/useNarrowMainWidth';
import Container from '../../shared/organizers/containers/Container';
import LabelledButton from '../../shared/buttons/LabelledButton';
import H3 from '../../shared/titles/H3';
import { initT } from '../../helpers/i18nHelpers';
import { DateTime } from 'luxon';
import { backendDateFormat, displayDateFormat, displayMonthFormat } from '../../constants/dateFormat';

const t = initT('pages.finder.foundElement');

const FoundForDate = (props) => {
  const { date, collection = [], renderElement = () => <></> } = props;

  const width = useNarrowMainWidth();

  const dt = useMemo(() => DateTime.fromFormat(date, backendDateFormat), [date]);

  return (
    <div className="w-full flex justify-center first:pt-0 pt-25px">
      <div style={{ width }}>
        <Container className="p-4">
          <H3>{dt.toFormat(displayDateFormat)}</H3>
          <div className="py-4 grid grid-cols-2">
            <div className="w-full flex justify-center">
              <LabelledButton variant="journal" to={`/journal?date=${date}`}>
                {t('seeInJournal')}
              </LabelledButton>
            </div>
            <div className="w-full flex justify-center">
              <LabelledButton variant="calendar" to={`/calendar?month=${dt.toFormat(displayMonthFormat)}`}>
                {t('seeInCalendar')}
              </LabelledButton>
            </div>
          </div>
          {collection.map(renderElement)}
        </Container>
      </div>
    </div>
  );
};

export default FoundForDate;
