import React, { useCallback, useEffect, useMemo } from 'react';
import NodesGroupPdfDisplay from '../../../shared/features/nodesGroupPanel/NodesGroupPdfDisplay';
import {
  pdfDefaultOptions,
  pdfMaxPageHeight,
  getPdfGenerator,
  prepareImagesToPdfGeneration,
} from '../../../helpers/pdfHelpers';
import { useCheckIfMounted } from '../../../hooks/useCheckIfMounted';
import { generateTagId } from '../../../helpers/identifierHelpers';
import intersection from 'lodash/intersection';
import H1 from '../../../shared/titles/H1';

const readyIds = {};

const eachChildOnSeparatePdfPage = (element, maxPageHeight) => {
  const nodes = Array.from(element.children);

  nodes.forEach((node, index) => {
    if (index === nodes.length - 1) return;

    const height = node.clientHeight;
    const div = document.createElement('div');
    // 1 because pageHeight is slightly more than 1108 and less than 1109
    div.style.height = `${maxPageHeight + 1 - (height % maxPageHeight)}px`;
    node.after(div);
  });
};

const PdfGenerator = (props) => {
  const {
    title,
    showTitle,
    selectedNotes,
    increasedSideMargins,
    // awaits for more reliant version of the jsPDF lib
    // eslint-disable-next-line no-unused-vars
    onReady,
    onError,
  } = props;

  const tagId = useMemo(generateTagId, []);
  useEffect(() => {
    readyIds[tagId] = [];
  });

  const sideMargin = increasedSideMargins ? 40 : 10;
  const margins = [10, sideMargin];

  // (210 (default width in px) - 2 * 10 (margin)) / 0.25 (scale)
  const width = Math.floor((210 - 2 * sideMargin) / 0.25);

  const checkIfMounted = useCheckIfMounted();

  const generatePdf = useCallback((element) => {
    eachChildOnSeparatePdfPage(element, pdfMaxPageHeight);

    setTimeout(() => {
      if (!checkIfMounted()) return;

      prepareImagesToPdfGeneration(element);
      getPdfGenerator().html(element, {
        ...pdfDefaultOptions,
        margin: margins,
        callback: (pdf) => {
          pdf.save(title);

          // Currently, second run of the generation causes problems probably related
          // to the lib.
          // Once it is fixed, reload should be replaced with setPdfGenerating(false).
          location.reload();
        },
      });
    });
  }, []);

  const onPartReady = useCallback(
    (id) => {
      readyIds[tagId].push(id);
      const commonPart = intersection(
        readyIds[tagId],
        selectedNotes.map((note) => note.id),
      );
      if (selectedNotes.length === commonPart.length) setTimeout(() => generatePdf(document.getElementById(tagId)));
    },
    [selectedNotes],
  );

  return (
    <div className="fixed left-full">
      <div id={tagId}>
        {showTitle ? (
          <div style={{ width: `${width}px`, fontFamily: 'PTSans' }}>
            <H1 textSizeClass="text-6xl pt-64">{title}</H1>
          </div>
        ) : (
          <></>
        )}
        {selectedNotes.map((note) => (
          <NodesGroupPdfDisplay
            key={note.id}
            title={note.name}
            nodesGroupId={note.nodesGroupId}
            onReady={() => onPartReady(note.id)}
            onError={onError}
            width={width}
          />
        ))}
      </div>
    </div>
  );
};

export default PdfGenerator;
