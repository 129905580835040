import React from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../helpers/stateManagementHelpers';
import sortBy from 'lodash/sortBy';
import { initT } from '../../helpers/i18nHelpers';
import List from '../../shared/lists/List';
import TaskPanel from '../../shared/features/taskPanel';
import MenuContent from '../../shared/organizers/MenuContent';
import MenuTitle from '../../shared/titles/MenuTitle';
import { activeElementTypes } from '../../constants/activeElementTypes';
import CheckIcon from '../../icons/checkIcon';
import { customColors } from '../../constants/customColors';

const t = initT('pages.calendar');

const CheckedTasks = (props) => {
  const { date, categories, checkedTasks, incognitoMode } = props;

  const tasks = sortBy(checkedTasks.visible(incognitoMode, { checked: true, checkedDate: date }), 'defaultOrder').map(
    (task) => {
      const category = categories.find({ id: task.categoryId }) || {};
      return { ...task, color: category.color || Object.keys(customColors)[0] };
    },
  );

  return (
    <MenuContent
      top={<MenuTitle marginClass="mx-25px py-25px">{t('checkedTasks.title')}</MenuTitle>}
      main={
        <div className="py-25px">
          <List collection={tasks}>
            {({ color, ...task }) => (
              <div key={task.id} className="mt-2 first:mt-0 flex justify-center px-25px">
                <TaskPanel
                  task={task}
                  color={color}
                  disabled
                  activeElementType={activeElementTypes.doneTask}
                  descriptionAdditionalClass="line-through"
                  bulletIcon={<CheckIcon className="w-3 h-3" />}
                />
              </div>
            )}
          </List>
        </div>
      }
    />
  );
};

export default connect(prepareState(['categories', 'checkedTasks', 'incognitoMode']))(CheckedTasks);
