import React from 'react';
import Clickable from '../../../../shared/Clickable';
import ArrowDownIcon from '../../../../icons/arrowDownIcon';
import ArrowRightIcon from '../../../../icons/arrowRightIcon';
import CustomColorCrumb from '../../../../shared/CustomColorCrumb';
import ThemeCrumb from '../../../../shared/ThemeCrumb';
import { customColors } from '../../../../constants/customColors';
import ButtonsGroup from '../../../../shared/buttons/ButtonsGroup';
import IconButton from '../../../../shared/buttons/IconButton';
import { connect } from 'react-redux';
import { prepareState } from '../../../../helpers/stateManagementHelpers';
import {
  recurringTasksActionDeleteElement,
  recurringTasksActionMarkPrivateElement,
  recurringTasksActionUnmarkPrivateElement,
} from '../../../../stateManagement/actions/recurringTasksActions';
import ConfirmationButton from '../../../../shared/buttons/ConfirmationButton';

const RecurringTaskHeader = (props) => {
  const {
    recurringTask,
    category,
    toggleContainerActivity,
    isContainerActive,
    activateEditionMode,
    incognitoMode,
    recurringTasksActionMarkPrivateElement,
    recurringTasksActionUnmarkPrivateElement,
    recurringTasksActionDeleteElement: deleteElement,
  } = props;

  const switchPrivate = recurringTask.private
    ? () => recurringTasksActionUnmarkPrivateElement(recurringTask)
    : () => recurringTasksActionMarkPrivateElement(recurringTask);
  const switchPrivateVariant = recurringTask.private ? 'unmarkPrivate' : 'markPrivate';

  let result = (
    <div className={`flex items-center ${isContainerActive ? 'border-b rounded-t-xl' : ''}`}>
      <Clickable
        className="flex w-full items-center truncate"
        onMouseDown={() => {
          toggleContainerActivity();
        }}
      >
        <CustomColorCrumb color={category.color} styleKeys={['color']}>
          <div className="p-5">
            {isContainerActive ? <ArrowDownIcon className="w-3 h-3" /> : <ArrowRightIcon className="w-3 h-3" />}
          </div>
        </CustomColorCrumb>
        <div>{recurringTask.name}</div>
      </Clickable>
      <div className="p-2">
        <ButtonsGroup
          className="w-full flex justify-end pl-1"
          buttons={[
            incognitoMode ? null : (
              <IconButton key="switchPrivate" onMouseDown={switchPrivate} variant={switchPrivateVariant} />
            ),
            <IconButton key="edit" variant="edit" onMouseDown={activateEditionMode} />,
            <ConfirmationButton
              key="delete"
              variant="delete"
              i18nKey="recurringTasks.delete"
              onConfirmed={() => deleteElement(recurringTask)}
              resourceName={recurringTask.name}
            />,
          ]}
        />
      </div>
    </div>
  );

  if (isContainerActive)
    result = (
      <ThemeCrumb
        type="container"
        mixinColor={customColors[category.color].primary}
        mixedStyleKeysMultipliers={{ borderColor: 0.2, backgroundColor: 0.1 }}
      >
        {result}
      </ThemeCrumb>
    );

  return result;
};

export default connect(prepareState(['incognitoMode']), {
  recurringTasksActionMarkPrivateElement,
  recurringTasksActionUnmarkPrivateElement,
  recurringTasksActionDeleteElement,
})(RecurringTaskHeader);
