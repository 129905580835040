import React, { useMemo } from 'react';
import FixedMenu from './FixedMenu';
import { fixedMenuSides } from '../../../constants/fixedMenu';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import {
  activeElementsActionActivate,
  activeElementsActionDeactivate,
} from '../../../stateManagement/actions/activeElementsActions';
import find from 'lodash/find';
import { activeElementTypes } from '../../../constants/activeElementTypes';

const FixedMenuController = (props) => {
  const {
    left,
    right,
    activeElements,
    activeElementsActionActivate: activate,
    activeElementsActionDeactivate: deactivate,
  } = props;

  const activeSide = useMemo(() => {
    const activeElement = find(activeElements, { type: activeElementTypes.fixedMenu });
    return activeElement ? activeElement.identifier : undefined;
  }, [activeElements]);

  const leftExpanded = activeSide === fixedMenuSides.left;
  const rightExpanded = activeSide === fixedMenuSides.right;

  const toggleLeft = () => {
    if (rightExpanded) deactivate(activeElementTypes.fixedMenu, fixedMenuSides.right);
    if (leftExpanded) deactivate(activeElementTypes.fixedMenu, fixedMenuSides.left);
    else {
      activate(activeElementTypes.fixedMenu, fixedMenuSides.left);
    }
  };
  const toggleRight = () => {
    if (leftExpanded) deactivate(activeElementTypes.fixedMenu, fixedMenuSides.left);
    if (rightExpanded) deactivate(activeElementTypes.fixedMenu, fixedMenuSides.right);
    else {
      activate(activeElementTypes.fixedMenu, fixedMenuSides.right);
    }
  };

  return (
    <>
      {left ? (
        <FixedMenu side={fixedMenuSides.left} disabled={rightExpanded} expanded={leftExpanded} toggle={toggleLeft}>
          {left}
        </FixedMenu>
      ) : (
        <></>
      )}
      {right ? (
        <FixedMenu side={fixedMenuSides.right} disabled={leftExpanded} expanded={rightExpanded} toggle={toggleRight}>
          {right}
        </FixedMenu>
      ) : (
        <></>
      )}
    </>
  );
};

export default connect(prepareState(['activeElements']), {
  activeElementsActionActivate,
  activeElementsActionDeactivate,
})(FixedMenuController);
