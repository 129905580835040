import { customColors } from '../constants/customColors';
import { getThemeTypeDefinition } from '../helpers/themeHelpers';
import { connect } from 'react-redux';
import { prepareState } from '../helpers/stateManagementHelpers';
import { applyStyle } from '../helpers/stylingHelpers';

const CustomColorCrumb = ({
  color,
  children,
  type = 'primary',
  styleKeys = ['backgroundColor'],
  styleKeysTypes = styleKeys.reduce((result, key) => {
    result[key] = type;
    return result;
  }, {}),
  mixinThemeType,
  mixinMultiplier = 0.5,
  mixedStyleKeys = styleKeys,
  mixedStyleKeysToMixinStyleKeyMappings = mixedStyleKeys.reduce((result, key) => {
    result[key] = key;
    return result;
  }, {}),
  mixedStyleKeysMultipliers = mixedStyleKeys.reduce((result, key) => {
    result[key] = mixinMultiplier;
    return result;
  }, {}),
  theme,
}) => {
  const additionalStyle = Object.keys(styleKeysTypes).reduce((result, key) => {
    result[key] = customColors[color][styleKeysTypes[key]];
    return result;
  }, {});

  let colorsMixinsConfig = {};
  if (mixinThemeType) {
    for (const mixedStyleKey of mixedStyleKeys) {
      const mixinStyleKey = mixedStyleKeysToMixinStyleKeyMappings[mixedStyleKey];
      colorsMixinsConfig[mixedStyleKey] = {
        color: getThemeTypeDefinition(theme, mixinThemeType)[mixinStyleKey],
        multiplier: mixedStyleKeysMultipliers[mixedStyleKey],
      };
    }
  }

  return applyStyle(children, additionalStyle, colorsMixinsConfig);
};

export default connect(prepareState('theme'))(CustomColorCrumb);
