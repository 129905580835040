import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { noteFromDto, noteToDto } from '../../../helpers/model/noteHelpers';
import { notesActionSetElement, notesActionUnlockElement } from '../../actions/notesActions';
import { notificationT } from '../../../helpers/i18nHelpers';
import { configActionUpdate } from '../../actions/configActions';

export default function* updateElementNotesSaga(action) {
  const { element: initialNote } = action.payload;
  const { ok, data } = yield call(api.notes.update, noteToDto(initialNote));
  if (ok) {
    const newNote = noteFromDto(data);
    yield put(snackbarMessagesActionAdd(notificationT('notes.update.success')));
    yield put(notesActionSetElement(newNote));
    yield put(configActionUpdate('selectNotesWorkspace', { id: newNote.notesWorkspaceId }));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(notesActionUnlockElement(initialNote));
  }
}
