import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { snackbarMessagesActionHandleBackendErrors } from '../../actions/snackbarMessagesActions';
import { categoryFromDto, categoryToDto } from '../../../helpers/model/categoryHelpers';
import { categoriesActionSetElement, categoriesActionUnlockElement } from '../../actions/categoriesActions';

export default function* unmarkPrivateElementCategoriesSaga(action) {
  const initialCategory = action.payload;
  const { ok, data } = yield call(api.categories.unmarkPrivate, categoryToDto(initialCategory));
  if (ok) {
    yield put(categoriesActionSetElement(categoryFromDto(data)));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(categoriesActionUnlockElement(initialCategory));
  }
}
