import React from 'react';
import TooltipWrapper from './TooltipWrapper';
import QuestionMarkIcon from '../icons/questionMarkIcon';

const Hint = (props) => {
  const { children, className = '', sizeClass = 'w-4 h-4' } = props;
  return (
    <TooltipWrapper tooltip={children}>
      <QuestionMarkIcon className={`${className} ${sizeClass}`} />
    </TooltipWrapper>
  );
};

export default Hint;
