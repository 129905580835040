import frenchkiss from 'frenchkiss';

frenchkiss.extend('en', {
  modals: {
    calendarDayModal: {
      events: 'Events',
      done: 'Done',
      seeInJournal: 'Go to {date} in journal',
    },
  },
});
