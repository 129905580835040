import React, { useState } from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { notesWorkspacesActionFetch } from '../../../stateManagement/actions/notesWorkspacesActions';
import StandardContentTemplate from '../../../shared/contentTemplates/StandardContentTemplate';
import Left from './Left';
import { useHistory, useParams } from 'react-router-dom';
import { notesActionFetch } from '../../../stateManagement/actions/notesActions';
import Main from './Main';
import find from 'lodash/find';
import Top from './Top';
import { configActionUpdate } from '../../../stateManagement/actions/configActions';
import { getNotes } from '../../../helpers/notesHelpers';
import { useWorkspace } from '../shared/useWorkspace';

const NotesIndexPage = (props) => {
  const {
    incognitoMode,
    notesWorkspaces,
    notes,
    config,
    notesWorkspacesActionFetch,
    notesActionFetch,
    configActionUpdate,
  } = props;

  const { noteId } = useParams();
  const history = useHistory();
  const [workspaceLookupAttempted, setWorkspaceLookupAttempted] = useState(false);

  const [editionMode, setEditionMode] = useState(false);
  const [pdfGenerating, setPdfGenerating] = useState(false);
  const sharedProps = { editionMode, setEditionMode, pdfGenerating, setPdfGenerating };

  const [workspaceSelected, workspace] = useWorkspace({
    notesWorkspaces,
    incognitoMode,
    config,
    notes,
    notesActionFetch,
    notesWorkspacesActionFetch,
  });

  let note;
  if (workspaceSelected && notes.fetched(workspace.id)) {
    const visibleNotes = notes.visible(incognitoMode, { notesWorkspaceId: workspace.id });
    const visibleTopNotes = getNotes(notes, workspace.id, null, incognitoMode);
    note = find(visibleNotes, { id: parseInt(noteId, 10) });
    if (!note) {
      if (noteId && !workspaceLookupAttempted)
        configActionUpdate(
          'selectNotesWorkspace',
          { id: noteId, identifierType: 'Note' },
          () => setWorkspaceLookupAttempted(true),
          () => setWorkspaceLookupAttempted(true),
          true,
        );
      else if (visibleTopNotes.length > 0)
        // a bit hacky without setTimeout, but it makes the UX better
        history.replace(`/notes/${visibleTopNotes[0].id}`);
    }
  }

  return (
    <StandardContentTemplate
      top={note ? <Top note={note} {...sharedProps} /> : false}
      leftReady={notesWorkspaces.fetched && workspaceSelected && notes.fetched(workspace.id)}
      left={<Left workspace={workspace} />}
      mainReady={notesWorkspaces.fetched && workspaceSelected && notes.fetched(workspace.id)}
      main={<Main note={note || {}} {...sharedProps} />}
    />
  );
};

export default connect(prepareState(['incognitoMode', 'notesWorkspaces', 'notes', 'config']), {
  notesWorkspacesActionFetch,
  notesActionFetch,
  configActionUpdate,
})(NotesIndexPage);
