import React from 'react';
import { alignmentTypeToFlexJustifyClass, alignmentTypeToTextClass } from '../helpers/alignmentHelpers';
import { alignmentTypes } from '../constants/alignmentTypes';

const Bullet = React.forwardRef((props, ref) => {
  const { children, alignmentType, nestingLevel, attributes } = props;

  let bullet;
  let leftMarginClass;
  switch (nestingLevel) {
    case 0:
      bullet = '•';
      leftMarginClass = '';
      break;
    case 1:
      bullet = '◦';
      leftMarginClass = alignmentType === alignmentTypes.center ? 'pl-8' : 'pl-4';
      break;
    case 2:
      bullet = '▸';
      leftMarginClass = alignmentType === alignmentTypes.center ? 'pl-16' : 'pl-8';
      break;
  }
  const containerClass = `${leftMarginClass} pt-2 first:pt-4 flex ${alignmentTypeToFlexJustifyClass(alignmentType)}`;

  return (
    <div className={containerClass} ref={ref} {...attributes}>
      <div contentEditable={false} className="mr-2 font-mono bullet-symbol">
        {bullet}
      </div>
      <div className={alignmentTypeToTextClass(alignmentType)}>{children}</div>
    </div>
  );
});

export default Bullet;
