import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../../../helpers/stateManagementHelpers';
import List from '../../../../shared/lists/List';
import { draggedElements } from '../../../../constants/draggedElements';
import { sortBy } from 'lodash';
import RecurringTaskTile from './RecurringTaskTile';

const RecurringTasksList = (props) => {
  const { additionMode, categories, recurringTasks, incognitoMode } = props;

  const draggableListRef = useRef(null);

  const visibleCategoriesIds = categories.visible(incognitoMode).map((e) => e.id);
  const visibleRecurringTasks = sortBy(
    recurringTasks.visible(incognitoMode, (recurringTask) => visibleCategoriesIds.includes(recurringTask.categoryId)),
    'defaultOrder',
  );

  const listEmpty = visibleRecurringTasks.length === 0;

  return (
    <List
      collection={visibleRecurringTasks}
      draggable={{
        ref: draggableListRef,
        draggedElementType: draggedElements.recurringTask,
      }}
      noDataCondition={additionMode ? false : listEmpty}
      className={additionMode && !listEmpty ? 'mt-4' : ''}
    >
      {(recurringTask) => (
        <div key={recurringTask.id} className="mb-4 last:mb-0 flex w-full justify-center">
          <RecurringTaskTile recurringTask={recurringTask} draggableListRef={draggableListRef} />
        </div>
      )}
    </List>
  );
};

export default connect(prepareState(['categories', 'recurringTasks', 'incognitoMode']))(RecurringTasksList);
