import { produceModelFromDto } from './modelHelpers';
import LearningChunk from '../../models/learningChunk';

const mappings = [
  ['notes_workspace_id', 'notesWorkspaceId'],
  ['note_id', 'noteId'],
  ['nodes_group_id', 'nodesGroupId'],
  ['node_id', 'nodeId'],
  ['learning_progress', 'nodeLearningProgress'],
  ['learning_count', 'nodeLearningCount'],
  ['notes_path', 'notesPath'],
  ['best_wpm', 'bestWpm'],
  ['default_order', 'defaultOrder'],
  ['private', 'private'],
];

export const learningChunkFromDto = produceModelFromDto(LearningChunk, mappings);
