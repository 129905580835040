export const recurringEventsActionFetch = () => ({
  type: 'RECURRING_EVENTS_ACTION_FETCH',
});

export const recurringEventsActionSet = (recurringEvents) => ({
  type: 'RECURRING_EVENTS_ACTION_SET',
  payload: recurringEvents,
});

export const recurringEventsActionCreateElement = (recurringEvent) => ({
  type: 'RECURRING_EVENTS_ACTION_CREATE_ELEMENT',
  payload: recurringEvent,
});

export const recurringEventsActionUnlock = () => ({
  type: 'RECURRING_EVENTS_ACTION_UNLOCK',
});

export const recurringEventsActionUpdateElement = (recurringEvent, activateUpdated = true) => ({
  type: 'RECURRING_EVENTS_ACTION_UPDATE_ELEMENT',
  payload: { recurringEvent, activateUpdated },
});

export const recurringEventsActionMarkPrivateElement = (recurringEvent) => ({
  type: 'RECURRING_EVENTS_ACTION_MARK_PRIVATE_ELEMENT',
  payload: recurringEvent.assignValues({ private: true }),
});

export const recurringEventsActionUnmarkPrivateElement = (recurringEvent) => ({
  type: 'RECURRING_EVENTS_ACTION_UNMARK_PRIVATE_ELEMENT',
  payload: recurringEvent.assignValues({ private: false }),
});

export const recurringEventsActionLockElement = (recurringEvent) => ({
  type: 'RECURRING_EVENTS_ACTION_LOCK_ELEMENT',
  payload: recurringEvent,
});

export const recurringEventsActionUnlockElement = (recurringEvent) => ({
  type: 'RECURRING_EVENTS_ACTION_UNLOCK_ELEMENT',
  payload: recurringEvent,
});

export const recurringEventsActionDeleteElement = (recurringEvent) => ({
  type: 'RECURRING_EVENTS_ACTION_DELETE_ELEMENT',
  payload: recurringEvent,
});

export const recurringEventsActionSetCreatedElement = (recurringEvent) => ({
  type: 'RECURRING_EVENTS_ACTION_SET_CREATED_ELEMENT',
  payload: recurringEvent,
});

export const recurringEventsActionSetElement = (recurringEvent) => ({
  type: 'RECURRING_EVENTS_ACTION_SET_ELEMENT',
  payload: recurringEvent,
});

export const recurringEventsActionUnset = (oneOrManyElements) => ({
  type: 'RECURRING_EVENTS_ACTION_UNSET',
  payload: oneOrManyElements,
});
