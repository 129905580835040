import sortBy from 'lodash/sortBy';
import flatten from 'lodash/flatten';
import compact from 'lodash/compact';
import { DateTime } from 'luxon';
import { backendDateFormat } from '../constants/dateFormat';
import { currentDateInBackendFormat } from './dateTimeHelpers';
import { getVisibleCategories } from './categoryHelpers';

export const getVisibleTodoTasks = (categories, tasks, incognitoMode, checkedDate = currentDateInBackendFormat()) => {
  const visibleCategories = getVisibleCategories(categories, incognitoMode);

  return sortBy(
    flatten(
      compact(
        visibleCategories.map((category) => {
          const { id: categoryId, color } = category;
          const visibleTasks = tasks.visible(incognitoMode, (task) => {
            const notCheckedAndFromCategory = !task.checked && task.categoryId === categoryId;
            const basicCondition = task.todo && notCheckedAndFromCategory;
            if (!task.todoFrom || !checkedDate) return basicCondition;
            const checkedDateTime = DateTime.fromFormat(checkedDate, backendDateFormat).startOf('day');
            const taskTodoFromDateTime = DateTime.fromFormat(task.todoFrom, backendDateFormat).startOf('day');
            const willBeTodo = taskTodoFromDateTime <= checkedDateTime;
            return basicCondition || (willBeTodo && notCheckedAndFromCategory);
          });
          if (visibleTasks.length === 0) return null;
          return visibleTasks.map((task) => ({
            order: task.todoOrder,
            task,
            color,
            checkedDate,
          }));
        }),
      ),
    ),
    'order',
  );
};
