import React, { useCallback, useEffect, useMemo, useState } from 'react';
import StandardContentTemplate from '../../shared/contentTemplates/StandardContentTemplate';
import Top from './Top';
import Left from './Left';
import Main from './Main';
import { connect } from 'react-redux';
import { prepareState } from '../../helpers/stateManagementHelpers';
import { configActionUpdate } from '../../stateManagement/actions/configActions';
import { learningWorkspacesActionFetch } from '../../stateManagement/actions/learningWorkspacesActions';
import { useWorkspace } from './useWorkspace';
import { useHistory, useParams } from 'react-router-dom';
import { find } from 'lodash';
import { learningQueuesActionFetch } from '../../stateManagement/actions/learningQueuesActions';
import LearningQueue from '../../models/learningQueue';
import { getAllLearningQueues } from '../../helpers/learningHelpers';
import QueueRunController from './queueRun/QueueRunController';
import Right from './Right';
import { learningQueueVariants } from '../../constants/enums/learningQueueVariants';
import Bottom from './Bottom';

const LearningPage = (props) => {
  const { incognitoMode, learningQueues, configActionUpdate } = props;

  const { queueId } = useParams();
  const history = useHistory();

  const creationMode = useMemo(() => queueId === 'new', [queueId]);

  const [runMode, setRunMode] = useState(false);
  useEffect(() => {
    if (creationMode) setRunMode(false);
  }, [creationMode, runMode]);
  useEffect(() => {
    setRunMode(false);
  }, [queueId]);

  const [formVisible, _setFormVisible] = useState(creationMode);
  const setFormVisible = useCallback(
    (value) => {
      if (!creationMode && !runMode) _setFormVisible(value);
    },
    [creationMode, runMode, _setFormVisible],
  );
  useEffect(() => {
    _setFormVisible(creationMode);
  }, [queueId]);

  const [queueRunToolkit, setQueueRunToolkit] = useState({});
  const [workspaceLookupAttempted, setWorkspaceLookupAttempted] = useState(false);
  const [workspaceSelected, workspace] = useWorkspace(props);

  let queue;
  let visibleQueues = [];
  if (workspaceSelected && learningQueues.fetched(workspace.id)) {
    visibleQueues = getAllLearningQueues(learningQueues, workspace.id, incognitoMode);
    if (creationMode) {
      queue = new LearningQueue();
      queue.learningWorkspaceId = workspace.id;
    } else {
      queue = find(visibleQueues, { id: parseInt(queueId, 10) });
      if (!queue) {
        if (queueId && !workspaceLookupAttempted)
          configActionUpdate(
            'selectLearningWorkspace',
            { id: queueId, identifierType: 'LearningQueue' },
            () => setWorkspaceLookupAttempted(true),
            () => setWorkspaceLookupAttempted(true),
            true,
          );
        else if (visibleQueues.length > 0)
          // a bit hacky without setTimeout, but it makes the UX better
          history.replace(`/learning/${visibleQueues[0].id}`);
      }
    }
  }

  const sharedProps = {
    creationMode,
    runMode,
    setRunMode,
    formVisible,
    setFormVisible,
    queue,
    visibleQueues,
    workspace,
    queueRunToolkit,
  };

  const { currentChunk } = queueRunToolkit;
  const renderRight = runMode && currentChunk && queue && queue.variant !== learningQueueVariants.pdf;
  const renderBottom = runMode && currentChunk && queue && queue.variant === learningQueueVariants.audio;

  return (
    <>
      {workspaceSelected && queue && queue.id && runMode ? (
        <QueueRunController key={queue.id} queue={queue} setQueueRunToolkit={setQueueRunToolkit} />
      ) : (
        <></>
      )}
      <StandardContentTemplate
        top={queue ? <Top {...sharedProps} /> : false}
        leftReady={workspaceSelected}
        left={<Left {...sharedProps} />}
        mainReady={workspaceSelected && learningQueues.fetched(workspace.id)}
        main={<Main {...sharedProps} />}
        right={renderRight ? <Right {...sharedProps} /> : false}
        bottom={renderBottom ? <Bottom /> : false}
      />
    </>
  );
};

export default connect(prepareState(['incognitoMode', 'learningWorkspaces', 'learningQueues', 'config']), {
  learningWorkspacesActionFetch,
  learningQueuesActionFetch,
  configActionUpdate,
})(LearningPage);
