import { produceModelFromDto, produceModelToDto } from './modelHelpers';
import Label from '../../models/label';

const mappings = [
  ['id', 'id'],
  ['name', 'name'],
  ['private', 'private'],
];

export const labelFromDto = produceModelFromDto(Label, mappings);

export const labelToDto = produceModelToDto(mappings);
