import React from 'react';
import { Form } from 'formik';
import { passDefaultAttributesToChildren } from '../../helpers/formHelpers';

const SmartForm = ({ defaultChildrenAttributes, children, className, ...props }) => (
  <Form {...props} className={className} noValidate>
    {passDefaultAttributesToChildren(children, defaultChildrenAttributes)}
  </Form>
);

export default SmartForm;
