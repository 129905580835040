export const getScrollYParent = (element) => {
  let style = getComputedStyle(element);
  if (style.position === 'fixed') return document.documentElement;

  const excludeStaticParent = style.position === 'absolute';
  const overflowRegex = /(auto|scroll)/;

  for (let parent = element; parent; parent = parent.parentElement) {
    style = getComputedStyle(parent);
    if (excludeStaticParent && style.position === 'static') continue;
    if (overflowRegex.test(style.overflow + style.overflowY)) return parent;
  }

  return document.documentElement;
};
