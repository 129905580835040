import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { prepareCollection } from '../../../helpers/model/modelHelpers';
import { habitsActionSet } from '../../actions/habitActions';
import { habitFromDto } from '../../../helpers/model/habitsHelpers';

export default function* fetchHabitsSaga(action) {
  const { ok, data } = yield call(api.habits.index, action.payload);
  if (ok) {
    yield put(habitsActionSet(prepareCollection(data, habitFromDto)));
  }
}
