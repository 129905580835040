import React from 'react';
import Clickable from '../../../Clickable';
import { runIfDraggingNotEnabled } from '../../../../helpers/draggingHelpers';
import ThemeCrumb from '../../../ThemeCrumb';
import AttachmentsEditor from './AttachmentsEditor';
import AttachmentsPresenter from './AttachmentsPresenter';

const NodeAttachments = (props) => {
  const {
    editionAllowed,
    editionMode,
    dragged,
    enableEditionMode,
    disableEditionMode,
    node,
    update,
    interactive,
    onModal,
  } = props;

  if (!editionAllowed)
    return (
      <ThemeCrumb type="node">
        <div className="w-full p-4 border rounded-md">
          <AttachmentsPresenter content={node.content} interactive={interactive} />
        </div>
      </ThemeCrumb>
    );

  if (!editionMode || dragged)
    return (
      <div>
        <Clickable
          className="w-full"
          onMouseDown={() => runIfDraggingNotEnabled(enableEditionMode)}
          disabled={dragged}
          opaqueWhenDisabled={false}
          hoverOpacity
        >
          <ThemeCrumb type="input">
            <div className="rounded-b border p-4">
              <AttachmentsPresenter content={node.content} interactive={false} />
            </div>
          </ThemeCrumb>
        </Clickable>
      </div>
    );

  const onSubmit = ({ content }) => {
    disableEditionMode();
    node.content = content;
    update(node);
  };

  return <AttachmentsEditor content={node.content} handleSubmit={onSubmit} onModal={onModal} />;
};

export default NodeAttachments;
