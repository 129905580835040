import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import {
  activeElementsActionActivate,
  activeElementsActionDeactivate,
} from '../../stateManagement/actions/activeElementsActions';
import LabelledButton from './LabelledButton';
import IconButton from './IconButton';
import { useConfirmation } from '../../hooks/useConfirmation';
import { confirmationT } from '../../helpers/i18nHelpers';
import Separator from '../Separator';

const getDefaultMessage = (resourceName, additionalMessage) => {
  return (
    <div className="max-w-128">
      {resourceName && (
        <div className="truncate">
          <b>{resourceName}</b>
        </div>
      )}
      {resourceName && additionalMessage && <Separator className="my-2" />}
      {additionalMessage && <div>{additionalMessage}</div>}
    </div>
  );
};

const toTextConfirmation = (text) => {
  if (!text) return '';

  return text
    .replace(/[^a-z0-9]/gi, '-')
    .replace(/-+/g, '-')
    .toLowerCase();
};

const getTextConfirmationString = (i18nKey, resourceName) => {
  if (!i18nKey && !resourceName) return null;

  let prefix = '';

  if (i18nKey) {
    const fullI18nPrefixKey = `${i18nKey}.textConfirmationPrefix`;
    const i18nPrefix = confirmationT(fullI18nPrefixKey);
    prefix = i18nPrefix === fullI18nPrefixKey ? '' : i18nPrefix;
  }

  return `${prefix}${toTextConfirmation(resourceName)}`;
};

const ConfirmationButton = (props) => {
  const {
    label,
    component,
    i18nKey = null,
    resourceName = null,
    title = i18nKey ? confirmationT(`${i18nKey}.title`) : null,
    additionalMessage = i18nKey ? confirmationT(`${i18nKey}.additionalMessage`) : null,
    message = getDefaultMessage(resourceName, additionalMessage),
    textConfirmation = false, // boolean
    textConfirmationPrompt = confirmationT('textConfirmationPrompt'),
  } = props;

  const textConfirmationString = textConfirmation ? getTextConfirmationString(i18nKey, resourceName) : null;

  const _component = useMemo(() => component || (label ? LabelledButton : IconButton), [component, label]);
  const { confirm } = useConfirmation({
    ...props,
    title,
    message,
    textConfirmation: textConfirmationString,
    textConfirmationPrompt,
  });

  return React.createElement(_component, { ...props, onMouseDown: confirm });
};

export default connect(null, { activeElementsActionActivate, activeElementsActionDeactivate })(ConfirmationButton);
