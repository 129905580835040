import React from 'react';

export const SunglassesIcon = ({ className = '', style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className={`fill-current ${className}`}
    style={style}
  >
    <path
      d="M17.945 8c-1.139 0-2.377.129-3.395.491-2.283.828-2.791.838-5.102
                 0-1.016-.362-2.257-.491-3.393-.491-1.971 0-4.17.387-6.055.878v1.789c.848.255
                 1.068.627 1.203 1.493.381 2.443 1.256 4.84 5.068 4.84 3.037 0 4.051-2.259
                 4.723-4.345.34-1.06 1.662-1.087 2.008-.015.674 2.089 1.682 4.36 4.725 4.36 3.814
                 0 4.689-2.397 5.07-4.841.135-.866.355-1.237
                 1.203-1.493v-1.788c-1.887-.491-4.084-.878-6.055-.878zm-15.472
                 4.915c-.117-.357-.223-.724-.312-1.101-.352-1.473-.043-1.789.434-2.074.695-.418
                 1.973-.665 3.295-.732-2.437.554-3.474 1.117-3.417 3.907zm11.75
                 0c-.117-.357-.223-.724-.312-1.101-.352-1.473-.043-1.789.434-2.074.695-.418
                 1.973-.665 3.295-.732-2.437.554-3.474 1.117-3.417 3.907z"
    />
  </svg>
);
export default SunglassesIcon;
