import React, { useCallback } from 'react';
import ModalHeader from '../../ModalHeader';
import { activeElementTypes } from '../../../../constants/activeElementTypes';
import { backendDateFormat, displayDateFormat } from '../../../../constants/dateFormat';
import { connect } from 'react-redux';
import { prepareState } from '../../../../helpers/stateManagementHelpers';
import { eventsActionFetch } from '../../../../stateManagement/actions/eventsActions';
import { sortBy } from 'lodash';
import ModalBody from '../../ModalBody';
import Events from './Events';
import CheckedTasks from './CheckedTasks';
import { customColors } from '../../../../constants/customColors';
import ModalFooter from '../../ModalFooter';
import LabelledButton from '../../../buttons/LabelledButton';
import { initT } from '../../../../helpers/i18nHelpers';

const t = initT('modals.calendarDayModal');

const CalendarDayModalContent = (props) => {
  const { identifier: dt, history, events, checkedTasks, categories, incognitoMode, eventsActionFetch } = props;

  const date = dt.toFormat(backendDateFormat);
  const displayDate = dt.toFormat(displayDateFormat);

  // We cannot simply use Link as we are outside Router
  const navigateToJournal = useCallback(() => {
    history.push(`/journal?date=${date}`);
  }, [history, date]);

  if (!events.fetched(date)) eventsActionFetch([date]);

  const visibleEvents = sortBy(events.visible(incognitoMode, { date }), 'defaultOrder');
  const visibleTasks = sortBy(
    checkedTasks.visible(incognitoMode, { checked: true, checkedDate: date }),
    'defaultOrder',
  ).map((task) => {
    const category = categories.find({ id: task.categoryId }) || {};
    return { ...task, color: category.color || Object.keys(customColors)[0] };
  });

  return (
    <div className="w-full sm:w-100">
      <ModalHeader type={activeElementTypes.calendarDayModal} identifier={dt}>
        {displayDate}
      </ModalHeader>
      <ModalBody>
        <Events events={visibleEvents} date={date} />
        <div className="pt-25px" />
        <CheckedTasks tasks={visibleTasks} />
      </ModalBody>
      <ModalFooter>
        <div className="w-full flex justify center">
          <LabelledButton variant="journal" onMouseDown={navigateToJournal}>
            {t('seeInJournal', { date: displayDate })}
          </LabelledButton>
        </div>
      </ModalFooter>
    </div>
  );
};

export default connect(prepareState(['events', 'checkedTasks', 'categories', 'incognitoMode']), { eventsActionFetch })(
  CalendarDayModalContent,
);
