import React from 'react';
import LockedEntityLoader from '../../loaders/LockedEntityLoader';
import AudioIcon from '../../../icons/audioIcon';
import LabelledButton from '../../buttons/LabelledButton';
import AudioControllerBase from './base';

const CompactAudioControllerInner = (props) => {
  const { wavesurfer, waveFormId, isPlaying, playPause, audioLocked, playTooltip, stopTooltip } = props;

  return (
    <div className="w-8">
      <LockedEntityLoader visible={!wavesurfer}>
        <div id={waveFormId} className="hidden" />
        <LabelledButton
          variant={isPlaying ? 'stop' : 'play'}
          tooltip={isPlaying ? stopTooltip : playTooltip}
          iconSizeClass="w-2 h-2"
          paddingClass="p-1"
          iconRightPadding="pr-1"
          onMouseDown={playPause}
          disabled={audioLocked}
        >
          <AudioIcon className="w-2 h-2 mr-1" />
        </LabelledButton>
      </LockedEntityLoader>
    </div>
  );
};

const CompactAudioController = (props) => {
  return (
    <AudioControllerBase {...props}>
      {(innerProps) => <CompactAudioControllerInner {...props} {...innerProps} />}
    </AudioControllerBase>
  );
};

export default CompactAudioController;
