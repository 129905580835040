import { produceModelFromDto, produceModelToDto } from './modelHelpers';
import HabitSummaryRequest from '../../models/habitSummaryRequest';

const mappings = [
  ['id', 'id'],
  ['aggregation_method', 'aggregationMethod', 'enum'],
  ['variant', 'variant', 'enum'],
  ['custom_period_days_counter', 'customPeriodDaysCounter'],
  ['weekday_type', 'weekdayType'],
];

export const habitSummaryRequestFromDto = produceModelFromDto(HabitSummaryRequest, mappings);

export const habitSummaryRequestToDto = produceModelToDto(mappings);
