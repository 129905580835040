import { produceModelFromDto, produceModelToDto } from './modelHelpers';
import Note from '../../models/note';

const mappings = [
  ['id', 'id'],
  ['name', 'name'],
  ['notes_workspace_id', 'notesWorkspaceId'],
  ['nodes_group_id', 'nodesGroupId'],
  ['parent_id', 'parentId'],
  ['default_order', 'defaultOrder'],
  ['private', 'private'],
];

export const noteFromDto = produceModelFromDto(Note, mappings);

export const noteToDto = produceModelToDto(mappings);
