export const habitChartsDataActionFetch = (date, habitId) => ({
  type: 'HABIT_CHARTS_DATA_ACTION_FETCH',
  payload: { date, habitId },
});

export const habitChartsDataActionSet = (date, habitId, newElements) => ({
  type: 'HABIT_CHARTS_DATA_ACTION_SET',
  payload: { date, habitId, newElements },
});

export const habitChartsDataActionReset = () => ({
  type: 'HABIT_CHARTS_DATA_ACTION_RESET',
});
