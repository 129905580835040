import frenchkiss from 'frenchkiss';

frenchkiss.extend('en', {
  frontEndErrors: {
    quickPass: {
      password: {
        invalid: 'Provided password is invalid',
      },
    },
    category: {
      name: {
        empty: 'Name is required',
      },
      color: {
        empty: 'Color is required',
      },
    },
    task: {
      description: {
        empty: 'Name is required',
      },
    },
    recurringTask: {
      name: {
        empty: 'Name is required',
      },
      categoryId: {
        empty: 'Category is required',
      },
      startDate: {
        empty: 'Start date is required',
      },
    },
    habit: {
      name: {
        empty: 'Name is required',
      },
      inputType: {
        empty: 'Input type is required',
      },
    },
    habitAnswer: {
      answer: {
        empty: 'Valid value is required',
      },
    },
    notes: {
      maxNestingLevel: 'Maximum nesting level was exceeded',
    },
    event: {
      name: {
        empty: 'Name is required',
      },
    },
    recurringEvent: {
      name: {
        empty: 'Name is required',
      },
      startDate: {
        empty: 'Start date is required',
      },
    },
    label: {
      name: {
        empty: 'Name is required',
      },
    },
    learningQueue: {
      name: {
        empty: 'Name is required',
      },
      variant: {
        empty: 'Variant is required',
      },
      feedOrderVariant: {
        empty: 'Feed order is required',
      },
      source: {
        empty: 'Source is required',
      },
    },
    shared: {
      title: {
        empty: 'Title is required',
      },
      file: {
        tooBig: 'The file is too big (max size: {maxFileSizeInMb} MB)',
      },
    },
  },
});
