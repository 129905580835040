import BaseModel from '../parents/baseModel';

export default class ElementToFocusOn extends BaseModel {
  constructor(type, identifier) {
    super();
    this.type = type;
    this.identifier = identifier;
  }

  equals(type, identifier) {
    return this.type === type && this.identifier === identifier;
  }
}
