import React, { useCallback, useMemo, useRef } from 'react';
import { useQueueRunDisplay } from './useQueueRunDisplay';
import TopInfoBox from '../TopInfoBox';
import ImagePresenter from './ImagePresenter';
import TextEditor from '../../../shared/features/nodesGroupPanel/nodeText/textEditor';
import useNarrowMainWidth from '../../../hooks/useNarrowMainWidth';
import WholePageMessage from '../../../shared/titles/WholePageMessage';
import Loader from '../../../shared/loaders/Loader';
import MainTopSpace from '../../../shared/organizers/MainTopSpace';

const ChunkDisplay = (props) => {
  const {
    node,
    imageAbove,
    textValue,
    imageBelow,
    contentOnMouseDown,
    className = '',
    minimumImageAboveHeight = 100,
    minimumTextHeight = 150,
    minimumImageBelowHeight = 100,
    renderAdditionalContentBelow,
    additionalContentHeightInPx = 0,
    highlightedTextProps,
    textEditorTheme,
  } = props;

  const width = useNarrowMainWidth();

  const wrapperRef = useRef();
  const innerWrapperRef = useRef();
  const topInfoRef = useRef();
  const imageAboveWrapperRef = useRef();
  const textWrapperRef = useRef();
  const imageBelowWrapperRef = useRef();
  const bottomWrapperRef = useRef();

  const calcAvailableHeight = useCallback(
    (defaultCalcAvailableHeight) =>
      defaultCalcAvailableHeight() - topInfoRef.current.clientHeight - additionalContentHeightInPx,
    [additionalContentHeightInPx],
  );

  const {
    ready,
    availableHeight: contentHeight,
    imageAboveHeight,
    textHeight,
    imageBelowHeight,
    marginTop,
    onImgLoad,
  } = useQueueRunDisplay({
    wrapperRef,
    innerWrapperRef,
    imageAboveWrapperRef,
    textWrapperRef,
    imageBelowWrapperRef,
    calcAvailableHeight,
    minimumImageAboveHeight,
    minimumTextHeight,
    minimumImageBelowHeight,
  });

  const imageAboveRoundedClass = useMemo(
    () => `${textValue || imageBelow ? '' : 'rounded-b'} rounded-t`,
    [textValue, imageBelow],
  );
  const textRoundedClass = useMemo(
    () => `${imageAbove ? '' : 'rounded-t'} ${imageBelow ? '' : 'rounded-b'}`,
    [imageAbove, imageBelow],
  );
  const imageBelowRoundedClass = useMemo(
    () => `${imageAbove || textValue ? '' : 'rounded-t'} rounded-b`,
    [imageAbove, textValue],
  );

  const bottom = useMemo(
    () => (renderAdditionalContentBelow ? renderAdditionalContentBelow(ready) : <></>),
    [renderAdditionalContentBelow, ready],
  );

  return (
    <>
      {ready ? (
        <></>
      ) : (
        <WholePageMessage>
          <Loader />
        </WholePageMessage>
      )}
      <div
        className={`pb-25px transition-opacity duration-500 ${className}
                             ${ready ? 'opacity-100' : 'opacity-0'}`}
      >
        <div ref={topInfoRef}>
          <MainTopSpace customContent>
            <TopInfoBox {...props} />
          </MainTopSpace>
        </div>
        <div
          style={{ height: contentHeight }}
          className="flex justify-center overflow-y-auto"
          onMouseDown={contentOnMouseDown}
        >
          <div ref={wrapperRef} className="overflow-y-auto h-inherit" style={{ marginTop, width }}>
            <div ref={innerWrapperRef} className={ready ? '' : 'fixed'}>
              {imageAbove ? (
                <div ref={imageAboveWrapperRef} className="w-full">
                  <ImagePresenter
                    content={imageAbove}
                    onImgLoad={onImgLoad}
                    height={imageAboveHeight}
                    roundedClass={imageAboveRoundedClass}
                  />
                </div>
              ) : (
                <></>
              )}
              {textValue ? (
                <div ref={textWrapperRef} className="w-full overflow-y-auto" style={{ height: textHeight }}>
                  <TextEditor
                    key={node.id}
                    readOnly
                    initialValue={textValue}
                    additionalContentMarginClass="py-4"
                    roundedClass={textRoundedClass}
                    highlightedTextProps={highlightedTextProps}
                    themeType={textEditorTheme}
                  />
                </div>
              ) : (
                <></>
              )}
              {imageBelow ? (
                <div ref={imageBelowWrapperRef} className="w-full">
                  <ImagePresenter
                    content={imageBelow}
                    onImgLoad={onImgLoad}
                    height={imageBelowHeight}
                    roundedClass={imageBelowRoundedClass}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div ref={bottomWrapperRef}>{bottom}</div>
      </div>
    </>
  );
};

export default ChunkDisplay;
