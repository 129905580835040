import React, { useEffect } from 'react';
import { withFormik } from 'formik';
import DateField from '../../shared/form/fieldTypes/DateField';
import SmartField from '../../shared/form/SmartField';
import { DateTime } from 'luxon';
import { backendDateFormat } from '../../constants/dateFormat';
import { initT, weekdayT } from '../../helpers/i18nHelpers';
import IconButton from '../../shared/buttons/IconButton';
import AuxiliaryText from '../../shared/AuxiliaryText';
import CenteredContent from '../../shared/organizers/CenteredContent';
import H1 from '../../shared/titles/H1';
import { updateSearchParams } from '../../helpers/locationHelpers';
import { unlock } from './calendarLockHelpers';

const t = initT('pages.calendar');

const addDays = (date, days) => DateTime.fromFormat(date, backendDateFormat).plus({ days }).toFormat(backendDateFormat);

const HeaderInnerForm = (props) => {
  const { locked = false, date, values, initialValues, submitForm, setFieldValue } = props;
  useEffect(() => {
    if (values !== initialValues) submitForm(values);
  }, [values]);
  const isToday = date === DateTime.now().toFormat(backendDateFormat);
  const weekday = DateTime.fromFormat(date, backendDateFormat).weekday;

  const weekdayText = (
    <AuxiliaryText className="sm:whitespace-nowrap overflow-ellipsis">
      {`${weekdayT(weekday)}${isToday ? ` (${t('today')})` : ''}`}
    </AuxiliaryText>
  );
  const dateField = (
    <SmartField
      name="date"
      themeType="transparentInput"
      component={DateField}
      isClearable={false}
      inputClassName="font-bold"
      displayError={false}
    />
  );
  const datePicker = (small) => (
    <div className="flex justify-center items-center" style={small ? { maxWidth: 200 } : {}}>
      <IconButton
        variant="arrowLeft"
        tooltip={t('previousDay')}
        onMouseDown={() => setFieldValue('date', addDays(date, -1))}
      />
      {small ? (
        <div>
          <H1 textSizeClass="text-lg">{dateField}</H1>
        </div>
      ) : (
        <H1>{dateField}</H1>
      )}
      <IconButton
        variant="arrowRight"
        tooltip={t('nextDay')}
        onMouseDown={() => setFieldValue('date', addDays(date, 1))}
      />
    </div>
  );
  const unlockIcon = locked ? (
    <IconButton
      variant="unlock"
      tooltip={t('unlock')}
      onMouseDown={() => {
        unlock(date);
        values.updateLocked();
      }}
    >
      {t('unlock')}
    </IconButton>
  ) : (
    <></>
  );

  return (
    <div className="w-full flex items-center">
      <CenteredContent
        className="h-40px flex"
        minMainWidthPx={200}
        left={<div className="px-2 sm:px-25px">{weekdayText}</div>}
        main={
          <>
            <div className="sm:hidden">{datePicker(true)}</div>
            <div className="hidden sm:block">{datePicker(false)}</div>
          </>
        }
        right={<div className="px-2 sm:px-25px">{unlockIcon}</div>}
      />
    </div>
  );
};

const Header = withFormik({
  mapPropsToValues: ({ locked, date, history, updateLocked }) => ({ locked, date, history, updateLocked }),
  enableReinitialize: true,
  handleSubmit: (values) => {
    updateSearchParams(values.history, { date: values.date });
  },
})(HeaderInnerForm);

export default Header;
