import React from 'react';
import ThemeCrumb from '../../ThemeCrumb';
import LockedEntityLoader from '../../loaders/LockedEntityLoader';

const Container = (props) => {
  const {
    children,
    hidden = false,
    loading = false,
    themeType = 'container',
    themeCrumbProps = {},
    className = '',
    width,
    widthClass = width ? '' : 'w-full',
    roundedClass = 'rounded-xl',
    borderClass = 'border',
  } = props;

  const actualClassName = `${widthClass} ${roundedClass} ${className} ${borderClass}`;

  let result = (
    <div className={actualClassName} style={{ width }} hidden={hidden}>
      <LockedEntityLoader visible={loading}>{children}</LockedEntityLoader>
    </div>
  );

  if (themeType) {
    result = (
      <ThemeCrumb {...themeCrumbProps} type={themeType}>
        {result}
      </ThemeCrumb>
    );
  }

  return result;
};

export default Container;
