import React from 'react';
import { frontEndErrorT, initT } from '../../../helpers/i18nHelpers';
import { customPropsToValues, produceValuesFilter } from '../../../helpers/formHelpers';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { eventsActionCreateElement, eventsActionUpdateElement } from '../../../stateManagement/actions/eventsActions';
import EventModel from '../../../models/eventModel';
import SmartForm from '../../form/SmartForm';
import LabelField from '../../form/LabelField';
import TextField from '../../form/fieldTypes/TextField';
import Label from '../../form/Label';
import NodesGroupPanel from '../nodesGroupPanel';
import DateField from '../../form/fieldTypes/DateField';
import OnClickOutsideWrapper from '../../onClickOutside/OnClickOutsideWrapper';
import { compact } from 'lodash';
import { useDoubleClickToBlur } from '../../../hooks/useDoubleClickToBlur';
import StandardFormFooter from '../../form/StandardFormFooter';

const t = initT('features.eventPanel');

const relevantAttributes = ['id', 'name', 'date', 'color', 'private'];
const auxiliaryAttributes = ['deactivateEditionMode', 'nodesGroupId', 'onModal'];
const filterValues = produceValuesFilter(relevantAttributes);

const validationSchema = () =>
  yup.object().shape({
    name: yup.string().required(frontEndErrorT('event.name.empty')),
  });

const InnerForm = (props) => {
  const { values, handleSubmit } = props;
  const { auxiliary } = values;
  const { deactivateEditionMode, nodesGroupId, onModal } = auxiliary;

  const { onClickInside, onClickOutside, aboutToBlur } = useDoubleClickToBlur({ onBlur: deactivateEditionMode });

  return (
    <OnClickOutsideWrapper
      onClickOutside={onClickOutside}
      onClick={onClickInside}
      className={aboutToBlur ? 'animate-inactive-pulse ' : ''}
      exceptionModals={compact([onModal])}
    >
      <SmartForm className="p-25px">
        <div className="flex justify-center">
          <div className="w-full sm:w-big-golden-ratio max-w-xs">
            <LabelField name="name" label={t('name')} required component={TextField} autoFocus />
            <LabelField name="date" className="pt-25px" label={t('date')} required component={DateField} autoFocus />
          </div>
        </div>
        <StandardFormFooter marginClass="mt-64px" handleSubmit={handleSubmit} handleCancel={deactivateEditionMode} />
      </SmartForm>
      <div className="pb-25px px-25px">
        <div className="pb-3">
          <Label>{t('details')}</Label>
        </div>
        <NodesGroupPanel nodesGroupId={nodesGroupId} editionMode onModal={onModal} />
      </div>
    </OnClickOutsideWrapper>
  );
};

const Form = withFormik({
  validationSchema,
  enableReinitialize: true,
  mapPropsToValues: (props) => customPropsToValues(props, filterValues, auxiliaryAttributes),
  handleSubmit: (values, formikBag) => {
    const event = new EventModel().assignValues(filterValues(values));
    if (event.id) formikBag.props.eventsActionUpdateElement(event);
    else formikBag.props.eventsActionCreateElement(event);
    // wait for nodes to save
    setTimeout(formikBag.props.deactivateEditionMode);
  },
})(InnerForm);

export default connect(null, { eventsActionCreateElement, eventsActionUpdateElement })(Form);
