import { put } from 'redux-saga/effects';
import { flatten } from 'lodash';
import { nodesActionUnsetGroup } from '../../actions/nodesActions';

export default function* unsetEventsSaga(action) {
  const { oneOrManyElements } = action.payload;
  const events = flatten([oneOrManyElements]);

  for (const relevantEvent of events) {
    yield put(nodesActionUnsetGroup(relevantEvent.nodesGroupId));
  }
}
