import { useState } from 'react';
import useResizeEffect from './useResizeEffect';
import { getStandardTemplateMainWidth } from '../helpers/elementsSizeHelpers';

let lastValidMainWidth = 0;

const calcWidth = (smallerVariant) => {
  const mainWidth = getStandardTemplateMainWidth();
  if (mainWidth !== 0) lastValidMainWidth = mainWidth;

  if (lastValidMainWidth < 800) return '100%';

  const factor = smallerVariant ? 0.618 : 3 / 4;
  return `${Math.round(factor * (lastValidMainWidth - 50))}px`;
};

export default function useNarrowMainWidth(smallerVariant = true) {
  const [width, setWidth] = useState(calcWidth(smallerVariant));

  useResizeEffect(
    () => {
      setWidth(calcWidth(smallerVariant));
    },
    [smallerVariant],
    true,
  );

  return width;
}
