import React from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { activeElementsActionActivate } from '../../../stateManagement/actions/activeElementsActions';
import {
  eventsActionDeleteElement,
  eventsActionMarkPrivateElement,
  eventsActionUnmarkPrivateElement,
} from '../../../stateManagement/actions/eventsActions';
import Clickable from '../../Clickable';
import CenteredContent from '../../organizers/CenteredContent';
import ButtonsGroup from '../../buttons/ButtonsGroup';
import IconButton from '../../buttons/IconButton';
import ColorSelector from './ColorSelector';
import CustomColorCrumb from '../../CustomColorCrumb';
import ConfirmationButton from '../../buttons/ConfirmationButton';

const Header = (props) => {
  const {
    event,
    disabled,
    isContainerActive,
    toggleContainerActivity,
    activateEditionMode,
    incognitoMode,
    eventsActionMarkPrivateElement: markPrivate,
    eventsActionUnmarkPrivateElement: unmarkPrivate,
    eventsActionDeleteElement: deleteElement,
  } = props;

  const switchPrivate = () => (event.private ? unmarkPrivate(event) : markPrivate(event));
  const switchPrivateVariant = event.private ? 'unmarkPrivate' : 'markPrivate';

  return (
    <CustomColorCrumb
      styleKeys={['borderColor', 'backgroundColor']}
      color={event.color}
      mixinThemeType="container"
      mixedStyleKeysMultipliers={{ borderColor: 0.8, backgroundColor: 0.95 }}
    >
      <div className={`border ${isContainerActive ? 'rounded-t' : 'rounded'}`}>
        <CenteredContent
          className="py-1 px-2"
          left={
            isContainerActive && !disabled ? (
              <ButtonsGroup
                buttons={[
                  <ColorSelector key="color" event={event} />,
                  !incognitoMode && (
                    <IconButton key="switchPrivate" variant={switchPrivateVariant} onMouseDown={switchPrivate} />
                  ),
                ]}
              />
            ) : (
              <></>
            )
          }
          main={
            <Clickable className="px-2 py-1.5 w-full" onMouseDown={toggleContainerActivity}>
              <div className="w-full">{event.name}</div>
            </Clickable>
          }
          right={
            isContainerActive && !disabled ? (
              <ButtonsGroup
                buttons={[
                  <IconButton key="edit" variant="edit" onMouseDown={activateEditionMode} />,
                  <ConfirmationButton
                    key="delete"
                    variant="delete"
                    i18nKey="events.delete"
                    onConfirmed={() => deleteElement(event)}
                    resourceName={event.name}
                  />,
                ]}
              />
            ) : (
              <></>
            )
          }
        />
      </div>
    </CustomColorCrumb>
  );
};

export default connect(prepareState(['incognitoMode']), {
  activeElementsActionActivate,
  eventsActionMarkPrivateElement,
  eventsActionUnmarkPrivateElement,
  eventsActionDeleteElement,
})(Header);
