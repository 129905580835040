import React from 'react';
import { connect } from 'react-redux';
import List from '../../shared/lists/List';
import LockedEntityLoader from '../../shared/loaders/LockedEntityLoader';
import SidebarButton from '../../shared/buttons/SidebarButton';
import { initT } from '../../helpers/i18nHelpers';
import { prepareState } from '../../helpers/stateManagementHelpers';
import flatten from 'lodash/flatten';
import { getAllHabitsSections } from '../../helpers/habitsSectionHelpers';
import MenuContent from '../../shared/organizers/MenuContent';
import MenuTitle from '../../shared/titles/MenuTitle';
import TodoIcon from '../../icons/todoIcon';
import ListIcon from '../../icons/listIcon';
import { useParams } from 'react-router-dom';
import EventIcon from '../../icons/eventIcon';
import useSearchParams from '../../hooks/useSearchParams';
import LabelledButton from '../../shared/buttons/LabelledButton';
import { DateTime } from 'luxon';
import { backendDateFormat, displayMonthFormat } from '../../constants/dateFormat';

const t = initT('pages.calendar');

const Menu = (props) => {
  const { date, habitsSections, habits, habitAnswers, incognitoMode, tasks, events } = props;

  const searchParams = useSearchParams();
  const { journalSectionId } = useParams();

  const month = DateTime.fromFormat(date, backendDateFormat).toFormat(displayMonthFormat);
  const visibleSections = getAllHabitsSections(habitsSections, incognitoMode);

  const calendarSections = flatten([
    {
      name: t('menu.general'),
    },
    {
      identifier: 'events',
      name: t('events.title'),
      locked: events.locked,
      hidden: false,
      bullet: (
        <div className="pr-2">
          <EventIcon className="w-4 h-4" />
        </div>
      ),
    },
    {
      identifier: 'todo',
      name: t('todoTasks.title'),
      locked: tasks.locked,
      hidden: false,
      bullet: (
        <div className="pr-2">
          <TodoIcon className="w-4 h-4" />
        </div>
      ),
    },
    visibleSections.map((section) => ({
      identifier: section.id,
      name: section.name,
      locked: section.locked,
      hidden: section.hidden,
    })),
    {
      identifier: 'hidden-habits',
      name: t('hiddenHabits.title'),
      locked: habitsSections.locked || habits.locked || !habitAnswers[date] || habitAnswers[date].locked,
      hidden: true,
    },
  ]);

  const getListElement = ({ identifier, locked, hidden, name, bullet }) => {
    if (!identifier)
      return (
        <SidebarButton
          key="general"
          selected={!journalSectionId}
          to={`/journal${searchParams}`}
          bullet={
            <div className="pr-2">
              <ListIcon className="w-4 h-4" />
            </div>
          }
        >
          {name}
        </SidebarButton>
      );

    const selected = journalSectionId && journalSectionId === identifier.toString();

    return (
      <LockedEntityLoader key={identifier} visible={locked}>
        <SidebarButton
          selected={selected}
          to={`/journal/${identifier}${searchParams}`}
          faded={hidden && !selected}
          bullet={bullet}
        >
          {name}
        </SidebarButton>
      </LockedEntityLoader>
    );
  };

  return (
    <MenuContent
      top={<MenuTitle>{t('menu.title')}</MenuTitle>}
      main={
        <div className="px-25px pb-25px">
          <List collection={calendarSections}>{getListElement}</List>
        </div>
      }
      bottom={
        <div className="w-full flex justify-center p-25px">
          <LabelledButton variant="calendar" preventContextMenu to={`/calendar?month=${month}`}>
            {t('menu.calendar')}
          </LabelledButton>
        </div>
      }
    />
  );
};

export default connect(prepareState(['habitsSections', 'habits', 'habitAnswers', 'incognitoMode', 'tasks', 'events']))(
  Menu,
);
