import Collection from '../../models/auxiliary/collection';

const initialState = () => new Collection();

export default function (state = initialState(), action) {
  switch (action.type) {
    case 'TASKS_ACTION_FETCH':
      return state.toNotFetched();
    case 'TASKS_ACTION_SET':
      return state.toFetched(false).toUnlocked(false).withReplacedElements(action.payload);
    case 'TASKS_ACTION_CREATE_ELEMENT':
      return state.toLocked();
    case 'TASKS_ACTION_UNLOCK':
      return state.toUnlocked();
    case 'TASKS_ACTION_UPDATE_ELEMENT':
    case 'TASKS_ACTION_MARK_TODO_ELEMENT':
    case 'TASKS_ACTION_UNMARK_TODO_ELEMENT':
    case 'TASKS_ACTION_MARK_PRIVATE_ELEMENT':
    case 'TASKS_ACTION_UNMARK_PRIVATE_ELEMENT':
    case 'TASKS_ACTION_CHECK_ELEMENT':
    case 'TASKS_ACTION_DELETE_ELEMENT':
    case 'TASKS_ACTION_LOCK_ELEMENT':
      return state.withActualizedElements(action.payload.toLocked(), ['locked']);
    case 'TASKS_ACTION_MOVE_ELEMENT':
      return state.withActualizedElements(action.payload.task.toLocked(), ['locked']);
    case 'TASKS_ACTION_UNLOCK_ELEMENT':
      return state.withActualizedElements(action.payload.toUnlocked(), ['locked']);
    case 'TASKS_ACTION_SET_CREATED_ELEMENT':
      return state.toUnlocked().withReplacedElements(action.payload);
    case 'TASKS_ACTION_SET_ELEMENT':
      return state.withReplacedElements(action.payload.toUnlocked());
    case 'TASKS_ACTION_UNSET':
      return state.withRemovedElements(action.payload);
    case 'GLOBAL_ACTION_RESET':
      return initialState();
    default:
      return state;
  }
}
