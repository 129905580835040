import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { prepareCollection } from '../../../helpers/model/modelHelpers';
import { learningQueuesActionSet } from '../../actions/learningQueuesActions';
import { learningQueueFromDto } from '../../../helpers/model/learningQueueHelpers';

export default function* fetchLearningQueuesSaga(action) {
  const { learningWorkspaceId } = action.payload;
  const { ok, data } = yield call(api.learningQueues.index, learningWorkspaceId);
  if (ok) {
    yield put(learningQueuesActionSet(learningWorkspaceId, prepareCollection(data, learningQueueFromDto)));
  }
}
