import { DateTime } from 'luxon';
import { backendDateFormat, displayDateFormat, displayMonthFormat } from '../constants/dateFormat';

export const currentDateInBackendFormat = () => DateTime.now().toFormat(backendDateFormat);

export const pastDayInBackendFormat = (days) => DateTime.now().minus({ days }).toFormat(backendDateFormat);

export const currentMonth = () => DateTime.now().toFormat(displayMonthFormat);

export const isDateInBackendFormat = (dateCandidate) => {
  if (typeof dateCandidate !== 'string') return false;
  return !DateTime.fromFormat(dateCandidate, backendDateFormat).invalid;
};

export const formatDate = (date) => {
  if (!date) return null;
  return DateTime.fromFormat(date, backendDateFormat).toFormat(displayDateFormat);
};

export const dateTimesRange = (start, length) => {
  const result = [start];
  let current = start;
  while (result.length !== length) {
    current = current.plus({ days: 1 });
    result.push(current);
  }
  return result;
};

export const formatTime = (seconds) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.round(seconds % 60);
  return [h, m > 9 ? m : h ? '0' + m : m || '0', s > 9 ? s : '0' + s].filter(Boolean).join(':');
};
