import React from 'react';

export const LeftTextIcon = ({ className = '', style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className={`fill-current ${className}`}
    style={style}
  >
    <path
      d="M24 3h-24v-2h24v2zm-12 3h-12v2h12v-2zm12 5h-24v2h24v-2zm-12
                 5h-12v2h12v-2zm12 5h-24v2h24v-2z"
    />
  </svg>
);
export default LeftTextIcon;
