import React, { useEffect, useState } from 'react';
import find from 'lodash/find';
import LockedEntityLoader from '../../../shared/loaders/LockedEntityLoader';
import { getSectionContent } from './getSectionContent';
import { TitleEditor } from '../../../shared/features/titleEditor';
import { useParams } from 'react-router-dom';
import { getAllHabitsSections } from '../../../helpers/habitsSectionHelpers';
import { elementToFocusOnTypes } from '../../../constants/elementToFocusOnTypes';

const getSection = (props) => {
  const {
    habitsSections,
    incognitoMode,
    elementToFocusOn,
    habitsSectionsActionDeleteElement: deleteElement,
    habitsSectionsActionMarkPrivateElement: markPrivate,
    habitsSectionsActionUnmarkPrivateElement: unmarkPrivate,
    habitsSectionsActionMarkHiddenElement: markHidden,
    habitsSectionsActionUnmarkHiddenElement: unmarkHidden,
    habitsSectionsActionUpdateElement: update,
    elementToFocusOnActionReset,
  } = props;

  const { habitsSectionId } = useParams();
  const habitsSectionIdInt = parseInt(habitsSectionId, 10);
  const [editionMode, setEditionMode] = useState(false);

  useEffect(() => {
    if (elementToFocusOn && elementToFocusOn.equals(elementToFocusOnTypes.habitsSectionTitle, habitsSectionIdInt)) {
      setEditionMode(true);
      elementToFocusOnActionReset();
    }
  }, [elementToFocusOn]);

  const visibleSections = getAllHabitsSections(habitsSections, incognitoMode);
  const section = find(visibleSections, { id: habitsSectionIdInt });
  if (!section) return false;

  const switchPrivateVariant = section.private ? 'unmarkPrivate' : 'markPrivate';
  const switchPrivate = () => (section.private ? unmarkPrivate(section) : markPrivate(section));
  const switchHiddenVariant = section.hidden ? 'unmarkHidden' : 'markHidden';
  const switchHidden = () => (section.hidden ? unmarkHidden(section) : markHidden(section));

  const handleSubmit = ({ title }) => {
    const habitsSection = section.shallowClone().assignValues({ ...section, name: title });
    if (section.name !== title) update(habitsSection);
    setEditionMode(false);
  };

  return (
    <div className="w-full h-40px">
      <LockedEntityLoader visible={section.locked}>
        {editionMode ? (
          <TitleEditor title={section.name} handleSubmit={handleSubmit} />
        ) : (
          getSectionContent({
            section,
            incognitoMode,
            switchHiddenVariant,
            switchHidden,
            switchPrivateVariant,
            switchPrivate,
            enableEditionMode: () => setEditionMode(true),
            deleteElement: () => deleteElement(section),
          })
        )}
      </LockedEntityLoader>
    </div>
  );
};

export default getSection;
