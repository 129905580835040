import React, { useMemo, useCallback, useState, useRef, useEffect } from 'react';
import QueueRunAudioController from '../QueueRunAudioController';
import ChunkDisplay from '../ChunkDisplay';
import { slateToPureText } from '../../../../helpers/textConversion/slateToPureText';
import compact from 'lodash/compact';
import FormikFieldSub from '../../../../shared/form/FormikFieldSub';
import TextField from '../../../../shared/form/fieldTypes/TextField';
import { useOnInputChange } from './useOnInputChange';
import { DateTime } from 'luxon';
import { isHotkey } from 'is-hotkey';
import { initT } from '../../../../helpers/i18nHelpers';
import { useKeydown } from './useKeydown';
import Countdown from './Countdown';
import { useCheckIfMounted } from '../../../../hooks/useCheckIfMounted';

const cooldownBeforeNextAllowedMs = 1000;

const t = initT('pages.learning.queueRun');

const calcWpm = (startedAt, typedWordsCount) => {
  if (!startedAt) return null;

  const durationInMinutes = DateTime.now().diff(startedAt).values.milliseconds / 1000.0 / 60.0;
  return Math.round(typedWordsCount / durationInMinutes);
};

const TypingMachine = (props) => {
  const { next, node, paused, textValue, setFinalWpm, setCurrentWpm, queue = {} } = props;

  const checkIfMounted = useCheckIfMounted();

  const [countdownMode, setCountdownMode] = useState(true);
  const [audioAllowed, setAudioAllowed] = useState(false);
  const [finishedMode, setFinishedMode] = useState(false);
  const [nextAllowed, setNextAllowed] = useState(false);

  const nodeWithAudio = useMemo(() => !!(queue.withAudio && node && node.audioUrl), [queue, node]);
  const [audioPaused, setAudioPaused] = useState(false);

  const [highlightedCharsInPreviousWordsCount, setHighlightedCharsInPreviousWordsCount] = useState(0);
  const [hightlightedCharsInCurrentWordCount, setHightlightedCharsInCurrentWordCount] = useState(0);

  const [startedAt, setStartedAt] = useState(null);

  const onAudioFinish = useCallback(() => {
    setAudioPaused(true);
  }, [setAudioPaused]);

  const words = useMemo(() => {
    return compact(slateToPureText(textValue).match(/[^\s]*/g));
  }, []);

  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const currentWord = useMemo(() => words[currentWordIndex] || '', [words, currentWordIndex]);

  const [inputValue, setInputValue] = useState('');
  const [erroneousCharsCount, setErroneousCharsCount] = useState(0);

  const inputRef = useRef(null);

  useEffect(() => {
    // If there is no currentWord, the same calculation is going to be triggered by the "onFinished" callback
    if (currentWord) setCurrentWpm(calcWpm(startedAt, currentWordIndex));
  }, [startedAt, currentWordIndex, currentWord]);

  const onCountdownEnd = useCallback(() => {
    setCountdownMode(false);

    // Needed also to make sure "go.mp3" is already played.
    const audioCooldownMs = 1000;
    setTimeout(() => {
      if (!checkIfMounted()) return;

      setAudioAllowed(true);
    }, audioCooldownMs);
  }, []);

  const onFinished = useCallback(() => {
    const wpm = calcWpm(startedAt, words.length);
    setCurrentWpm(wpm);
    setFinalWpm(wpm);
    setFinishedMode(true);

    setTimeout(() => {
      setNextAllowed(true);
    }, cooldownBeforeNextAllowedMs);
  }, [startedAt, words]);

  const onInputChange = useOnInputChange({
    words,
    currentWord,
    currentWordIndex,
    setErroneousCharsCount,
    setInputValue,
    setCurrentWordIndex,
    setHightlightedCharsInCurrentWordCount,
    setHighlightedCharsInPreviousWordsCount,
    onFinished,
    setStartedAt,
  });

  useKeydown({ next, nextAllowed });

  const onClick = useCallback(() => {
    if (nextAllowed) next();
  }, [next, nextAllowed]);

  return (
    <>
      <div className={`transition ${countdownMode || finishedMode || paused ? 'opacity-60' : 'opacity-100'}`}>
        <ChunkDisplay
          {...props}
          contentOnMouseDown={onClick}
          additionalContentHeightInPx={125}
          highlightedTextProps={{
            charsCount: highlightedCharsInPreviousWordsCount + hightlightedCharsInCurrentWordCount,
            showCaret: !!currentWord && !paused,
            caretAtTheBeginningOfNextWord: inputValue.length === 0 || inputValue.length === erroneousCharsCount,
            erroneousCharsCount,
          }}
          renderAdditionalContentBelow={(ready) =>
            ready && (
              <>
                {!finishedMode && !countdownMode && (
                  <div className="w-full my-25px">
                    <FormikFieldSub
                      component={TextField}
                      value={inputValue}
                      innerRef={inputRef}
                      themeType={erroneousCharsCount > 0 ? 'inputError' : 'input'}
                      disabled={!currentWord}
                      onKeyDown={(e) => {
                        if (isHotkey('enter', e)) {
                          onInputChange({ event: { target: { value: inputValue + ' ' } } });
                        }
                      }}
                      onChange={onInputChange}
                      autoFocus
                      paddingClass="p-4"
                      className="text-4xl"
                      onBlur={() => {
                        if (currentWord) inputRef.current.focus();
                      }}
                    />
                  </div>
                )}
                {nodeWithAudio && audioAllowed && !paused && (
                  <QueueRunAudioController
                    src={node.audioUrl}
                    mask={node.audioMask}
                    audioPaused={audioPaused}
                    onFinish={onAudioFinish}
                  />
                )}
              </>
            )
          }
        />
      </div>
      {finishedMode && (
        <div className="w-full py-25px text-2xl" onClick={onClick}>
          <div className="animate-test truncate">{t('pressAnyKeyToContinue')}</div>
        </div>
      )}
      {/* Countdown component hides itself onCountdownEnd - and we need to let it render nonetheless */}
      {/* since it controls some audio as well. */}
      {!paused && <Countdown onCountdownEnd={onCountdownEnd} />}
    </>
  );
};

export default TypingMachine;
