import React, { useEffect, useState, useMemo } from 'react';
import { useCheckIfMounted } from '../../../../hooks/useCheckIfMounted';
import AudioControllerBase from '../../../../shared/features/audioController/base';

const oneDigitDurationMs = 800;

const getAudioSource = (digit) => {
  if (digit === 3) return '/countdown/3.mp3';
  if (digit === 2) return '/countdown/2.mp3';
  if (digit === 1) return '/countdown/1.mp3';
  return '/countdown/go.mp3';
};

const InnerAudioController = (props) => {
  const { wavesurfer, waveFormId, playPause } = props;

  useEffect(() => {
    if (wavesurfer) playPause();
  }, [wavesurfer]);

  return <div id={waveFormId} className="hidden" />;
};

const Countdown = ({ onCountdownEnd }) => {
  const checkIfMounted = useCheckIfMounted();
  const [currentDigit, setCurrentDigit] = useState(3);
  const currentAudioSource = useMemo(() => getAudioSource(currentDigit), [currentDigit]);

  useEffect(() => {
    setTimeout(() => {
      if (!checkIfMounted()) return;

      if (currentDigit > 1) setCurrentDigit(currentDigit - 1);
      else {
        setCurrentDigit(NaN);
        onCountdownEnd();
      }
    }, oneDigitDurationMs);
  }, [currentDigit]);

  return (
    <>
      {!isNaN(currentDigit) && (
        <div className="w-full flex justify-center pt-25px" key={currentDigit}>
          <div className="text-4xl animate-ping-once">{currentDigit}</div>
        </div>
      )}
      <AudioControllerBase src={currentAudioSource}>
        {(innerProps) => <InnerAudioController {...innerProps} />}
      </AudioControllerBase>
    </>
  );
};

export default Countdown;
