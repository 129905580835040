import { backendDateFormat } from '../../constants/dateFormat';

export const determineDatesToFetch = (collection, monthStartDate) => {
  const result = [];

  for (let day = 1; day <= monthStartDate.daysInMonth; day++) {
    const date = monthStartDate.set({ day }).toFormat(backendDateFormat);
    if (!collection.fetched(date)) result.push(date);
  }

  return result;
};
