import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { notificationT } from '../../../helpers/i18nHelpers';
import { learningQueueFromDto, learningQueueToDto } from '../../../helpers/model/learningQueueHelpers';
import { learningQueuesActionSetCreatedElement, learningQueuesActionUnlock } from '../../actions/learningQueuesActions';
import { push } from 'connected-react-router';

export default function* createElementLearningQueuesSaga(action) {
  const { element: initialQueue } = action.payload;
  const { ok, data } = yield call(api.learningQueues.create, learningQueueToDto(initialQueue));
  if (ok) {
    yield put(snackbarMessagesActionAdd(notificationT('learningQueues.create.success')));
    const learningQueue = learningQueueFromDto(data);
    yield put(learningQueuesActionSetCreatedElement(learningQueue));
    yield put(push(`/learning/${learningQueue.id}`));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(learningQueuesActionUnlock());
  }
}
