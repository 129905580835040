import MultiFetchableCollection from '../../models/auxiliary/MultiFetchableCollection';
import difference from 'lodash/difference';

const initialState = () => new MultiFetchableCollection();

export default function (state = initialState(), action) {
  const { modelName, elements, element, oneOrManyElements, skipWastes } = action.payload || {};

  // reset whenever any resource of other type is deleted
  if (action.type && action.type !== 'WASTES_ACTION_DELETE_ELEMENT' && String(action.type).includes('DELETE')) {
    return initialState();
  }

  switch (action.type) {
    case 'WASTES_ACTION_FETCH':
      return state.toNotFetched(modelName);
    case 'WASTES_ACTION_SET':
      return state.toFetched(modelName, false).toUnlocked(false).withReplacedElements(elements);
    case 'WASTES_ACTION_CREATE_ELEMENT':
    case 'WASTES_ACTION_DUPLICATE_ELEMENT':
      return state.toLocked();
    case 'WASTES_ACTION_UNLOCK':
      return state.toUnlocked();
    case 'WASTES_ACTION_RESTORE_ELEMENT':
    case 'WASTES_ACTION_DELETE_ELEMENT':
    case 'WASTES_ACTION_LOCK_ELEMENT':
      return state.withActualizedElements(element.toLocked(), ['locked']);
    case 'WASTES_ACTION_UNLOCK_ELEMENT':
      return state.withActualizedElements(element.toUnlocked(), ['locked']);
    case 'WASTES_ACTION_UNSET':
      return state.withRemovedElements(oneOrManyElements);
    case 'WASTES_ACTION_RESET_ALL_EXCEPT': {
      const modelNamesToUnfetch = difference(state.fetchedForIdentifiers, [modelName]);
      let result = state;
      modelNamesToUnfetch.forEach((modelNameToUnfetch) => {
        result = result.toNotFetched(modelNameToUnfetch, false);
      });
      const elementsToRemove = state.elements.filter((element) => element.modelName !== modelName);
      return result.withRemovedElements(elementsToRemove, { createClone: true });
    }
    case 'GLOBAL_ACTION_RESET': {
      if (skipWastes) return state;
      return initialState();
    }
    default:
      return state;
  }
}
