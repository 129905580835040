import React, { useMemo, useEffect, useCallback } from 'react';
import { generateTagId } from '../../helpers/identifierHelpers';
import { useCheckIfMounted } from '../../hooks/useCheckIfMounted';
import { connect } from 'react-redux';
import { prepareState } from '../../helpers/stateManagementHelpers';
import find from 'lodash/find';
import { activeElementModalTypes } from '../../constants/activeElementTypes';

const clickListeners = {};
const blurListeners = {};
const blurLocks = {};
const popupLocks = [];

const NotCentralizedOnClickOnOutsideWrapper = (props) => {
  const {
    onClickOutside,
    children,
    baseExceptionClasses = ['link-popover', 'checklist-bullet', 'selectable-menu-wrapper'],
    additionalExceptionClasses = [],
    exceptionClasses = [...baseExceptionClasses, ...additionalExceptionClasses],
    exceptionIds = ['DatePickerPortal'],
    sizeClass = 'w-full h-full',
    className = '',
    disableOnClickOutside = false,
    disableWhenModalOpen = true,
    ignoreRecentlyRemovedElements = true,
    ignoreWindowPopups = true,
    reactOnClickOutsideWindow = false,
    activeElements,
    // redux related, should not be passed down to children
    // eslint-disable-next-line no-unused-vars
    dispatch,
    ...otherProps
  } = props;

  const id = useMemo(generateTagId, []);
  const checkIfMounted = useCheckIfMounted();

  const modalsLock = useMemo(
    () =>
      disableWhenModalOpen &&
      !!find(activeElements || [], (activeElement) => activeElementModalTypes.includes((activeElement || {}).type)),
    [disableWhenModalOpen, activeElements],
  );
  const removePopupLock = () => {
    let index;
    popupLocks.forEach((_id, _index) => {
      if (_id === id) index = _index;
    });
    if (index !== undefined) delete popupLocks[index];
  };

  const onWindowClick = useCallback((e) => {
    if (clickListeners[id]) clickListeners[id](e);
  }, []);
  const onWindowBlur = useCallback((e) => {
    if (blurListeners[id]) blurListeners[id](e);
  }, []);
  const onWindowFocus = useCallback(() => {
    blurLocks[id] = undefined;
  }, []);
  const cancelPreviousListeners = useCallback(() => {
    window.removeEventListener('mousedown', onWindowClick);
    window.removeEventListener('blur', onWindowBlur);
    window.removeEventListener('focus', onWindowFocus);
    removePopupLock();
    window.removeWindowPopupOpenListener(id);
    window.removeWindowPopupCloseListener(id);
  }, []);

  useEffect(() => {
    window.addEventListener('mousedown', onWindowClick);
    window.addEventListener('blur', onWindowBlur);
    window.addEventListener('focus', onWindowFocus);
    if (ignoreWindowPopups) {
      window.addWindowPopupOpenListener(id, () => {
        popupLocks.push(id);
      });
      window.addWindowPopupCloseListener(id, () => setTimeout(removePopupLock));
    }
    return cancelPreviousListeners;
  }, [ignoreWindowPopups]);

  useEffect(() => {
    setTimeout(() => {
      if (!checkIfMounted()) return;

      clickListeners[id] = (e) => {
        if (!checkIfMounted() || disableOnClickOutside || modalsLock) return;
        if (popupLocks.includes(id)) return;
        if (ignoreRecentlyRemovedElements && !document.contains(e.target)) return;

        let shouldProceed = true;
        exceptionClasses.forEach((exceptionClass) => {
          for (let exceptionElement of document.getElementsByClassName(exceptionClass))
            if (exceptionElement.contains(e.target)) shouldProceed = false;
        });
        exceptionIds.forEach((exceptionId) => {
          const element = document.getElementById(exceptionId);
          if (element && element.contains(e.target)) shouldProceed = false;
        });
        if (!shouldProceed) return;

        const element = document.getElementById(id);
        if (element) {
          if (!element.contains(e.target)) onClickOutside(e);
        } else {
          cancelPreviousListeners();
        }
      };

      blurListeners[id] = (e) => {
        if (!reactOnClickOutsideWindow || !checkIfMounted() || blurLocks[id] || disableOnClickOutside || modalsLock)
          return;
        if (popupLocks.includes(id)) return;
        blurLocks[id] = true;

        onClickOutside(e);
      };
    });
  }, [onClickOutside, exceptionClasses, disableOnClickOutside, modalsLock, ignoreRecentlyRemovedElements]);

  return (
    <div id={id} className={`${sizeClass} ${className}`} {...otherProps}>
      {children}
    </div>
  );
};

export default connect(prepareState(['activeElements']))(NotCentralizedOnClickOnOutsideWrapper);
