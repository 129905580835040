import React, { useCallback, useEffect, useRef, useState } from 'react';
import Loader from '../../../../shared/loaders/Loader';
import PdfContent from './PdfContent';
import {
  adjustContentsToPdfDisplay,
  adjustPdfPagesHeights,
  getPdfGenerator,
  pdfDefaultOptions,
  pdfMaxPageHeight,
  prepareImagesToPdfGeneration,
} from '../../../../helpers/pdfHelpers';
import { useCheckIfMounted } from '../../../../hooks/useCheckIfMounted';

const PdfGenerator = (props) => {
  const {
    queue,
    limit,
    loading,
    currentChunkUuid,
    workspace,
    notesPath,
    heading,
    node,
    notDebouncedNext,
    nextEnabled,
  } = props;

  const checkIfMounted = useCheckIfMounted();
  const [limitAchieved, setLimitAchieved] = useState(false);
  const data = useRef([]);
  const lastHandledChunkUuid = useRef(null);

  useEffect(() => {
    if (currentChunkUuid && !loading) {
      data.current.push({
        node,
        workspace,
        notesPath,
        heading,
      });
      if (data.current.length >= limit) setLimitAchieved(true);
    }
  }, [currentChunkUuid, loading]);

  useEffect(() => {
    if (!limitAchieved && nextEnabled && currentChunkUuid !== lastHandledChunkUuid.current) {
      lastHandledChunkUuid.current = currentChunkUuid;

      // need to make sure that the toolkit state is going to be fully reload before the next "next" call
      setTimeout(notDebouncedNext);
    }
  }, [currentChunkUuid, nextEnabled]);

  const generatePdf = useCallback((element) => {
    adjustContentsToPdfDisplay(element);

    setTimeout(() => {
      if (!checkIfMounted()) return;

      adjustPdfPagesHeights(element, pdfMaxPageHeight);

      setTimeout(() => {
        if (!checkIfMounted()) return;

        prepareImagesToPdfGeneration(element);
        getPdfGenerator().html(element, {
          ...pdfDefaultOptions,
          margin: [10, 10],
          callback: (pdf) => {
            pdf.save(queue.name);
            location.reload();
          },
        });
      });
    });
  }, []);

  return (
    <>
      <Loader />
      {limitAchieved ? <PdfContent data={data.current} onReady={generatePdf} /> : <></>}
    </>
  );
};

export default PdfGenerator;
