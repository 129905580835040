import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { nodesActionCreateElement } from '../../../stateManagement/actions/nodesActions';
import Node from '../../../models/node';
import LockedEntityLoader from '../../loaders/LockedEntityLoader';
import { initT } from '../../../helpers/i18nHelpers';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import {
  activeElementsActionActivate,
  activeElementsActionDeactivate,
} from '../../../stateManagement/actions/activeElementsActions';
import { activeElementTypes } from '../../../constants/activeElementTypes';
import find from 'lodash/find';
import IconButton from '../../buttons/IconButton';
import { nodeVariants } from '../../../constants/nodeVariants';
import OnClickOutsideWrapper from '../../onClickOutside/OnClickOutsideWrapper';
import compact from 'lodash/compact';

const t = initT('features.nodesGroupPanel');

const NodeAdditionButton = (props) => {
  const {
    nodesGroupId,
    successorId,
    nodesLocked,
    onModal,
    activeElements,
    nodesActionCreateElement: create,
    activeElementsActionActivate: activate,
    activeElementsActionDeactivate: deactivate,
  } = props;

  const uuid = `${nodesGroupId}-${successorId}`;
  const isActive = !!find(activeElements, { type: activeElementTypes.nodeCreator, identifier: uuid });

  const onFirstClick = useCallback(() => {
    activate(activeElementTypes.nodeCreator, uuid);
  }, []);
  const onClickOutside = useCallback(() => {
    if (isActive) deactivate(activeElementTypes.nodeCreator, uuid);
  }, [isActive]);
  const addNode = useCallback(
    (variant) => () => {
      const newNode = new Node();
      deactivate(activeElementTypes.nodeCreator, uuid);
      newNode.nodesGroupId = nodesGroupId;
      newNode.variant = variant;
      create(newNode, successorId);
    },
    [nodesGroupId, successorId],
  );

  return (
    <LockedEntityLoader visible={nodesLocked}>
      <div className="w-full flex justify-center">
        <OnClickOutsideWrapper
          onClickOutside={onClickOutside}
          sizeClass={''}
          active={isActive}
          exceptionModals={compact([onModal])}
        >
          {isActive ? (
            <div className="w-full flex justify-center mt-2">
              <IconButton variant="heading" onMouseDown={addNode(nodeVariants.heading)} wrapperClass="mr-3" />
              <IconButton variant="text" onMouseDown={addNode(nodeVariants.text)} wrapperClass="mr-3" />
              <IconButton variant="image" onMouseDown={addNode(nodeVariants.image)} wrapperClass="mr-3" />
              <IconButton
                variant="attachment"
                tooltip={t('attachments')}
                onMouseDown={addNode(nodeVariants.attachments)}
              />
            </div>
          ) : (
            <IconButton variant="add" onMouseDown={onFirstClick} tooltip={t('addNode')} wrapperClass="mt-3" />
          )}
        </OnClickOutsideWrapper>
      </div>
    </LockedEntityLoader>
  );
};

export default connect(prepareState(['activeElements']), {
  nodesActionCreateElement,
  activeElementsActionActivate,
  activeElementsActionDeactivate,
})(NodeAdditionButton);
