import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { notificationT } from '../../../helpers/i18nHelpers';
import { habitFromDto, habitToDto } from '../../../helpers/model/habitsHelpers';
import { habitsActionSetElement, habitsActionUnlockElement } from '../../actions/habitActions';
import { habitAnswersActionReset } from '../../actions/habitAnswersActions';
import { habitChartsDataActionReset } from '../../actions/habitChartsDataActions';
import { push } from 'connected-react-router';
import { activeElementsActionActivateIfOtherInactive } from '../../actions/activeElementsActions';
import { activeElementTypes } from '../../../constants/activeElementTypes';
import { getCurrentHref } from '../../../helpers/locationHelpers';
import reloadCurrentPageSagaHelper from '../helpers/reloadCurrentPageSagaHelper';

export default function* updateElementHabitsSaga(action) {
  const initialHabit = action.payload;
  const { ok, data } = yield call(api.habits.update, habitToDto(initialHabit));
  if (ok) {
    const updatedHabit = habitFromDto(data);
    yield put(snackbarMessagesActionAdd(notificationT('habits.update.success')));
    yield put(habitAnswersActionReset());
    yield put(habitChartsDataActionReset());
    yield put(habitsActionSetElement(updatedHabit));
    const currentHref = getCurrentHref();
    if (currentHref.startsWith('/habits')) {
      yield put(push(`/habits/${updatedHabit.habitsSectionId}`));
      yield put(activeElementsActionActivateIfOtherInactive(activeElementTypes.habit, updatedHabit.id));
    } else if (currentHref.startsWith('/journal')) {
      // in unlikely case in which user navigated to the journal, we need to reload the page
      // as answers & charts data were reset in the meantime
      yield reloadCurrentPageSagaHelper();
    }
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data, 'habits.update.errors'));
    yield put(habitsActionUnlockElement(initialHabit));
  }
}
