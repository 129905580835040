import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { snackbarMessagesActionHandleBackendErrors } from '../../actions/snackbarMessagesActions';
import { habitsSectionFromDto, habitsSectionToDto } from '../../../helpers/model/habitsSectionHelpers';
import { habitsSectionsActionSetElement, habitsSectionsActionUnlockElement } from '../../actions/habitsSectionsActions';

export default function* markHiddenElementHabitsSectionsSaga(action) {
  const initialHabitsSection = action.payload;
  const { ok, data } = yield call(api.habitsSections.markHidden, habitsSectionToDto(initialHabitsSection));
  if (ok) {
    yield put(habitsSectionsActionSetElement(habitsSectionFromDto(data)));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(habitsSectionsActionUnlockElement(initialHabitsSection));
  }
}
