import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Loader from './shared/loaders/Loader';
import { sessionActionLookup } from './stateManagement/actions/sessionActions';
import SignInPage from './pages/signIn';
import { getThemeTypeDefinition } from './helpers/themeHelpers';
import QuickPassPage from './pages/quickPass/index';
import AppAuthenticated from './AppAuthenticated';
import { applyTemplate } from './helpers/templateHelpers';
import CenteredTemplate from './templates/CenteredTemplate';

const App = ({ sessionChecked, signedIn, sessionActionLookup, theme, quickPassActive, browserHistory }) => {
  useEffect(() => {
    sessionActionLookup();
  }, []);

  const themeTypeDefinition = getThemeTypeDefinition(theme, 'main');

  document.body.style.color = themeTypeDefinition.color;
  document.body.style.backgroundColor = themeTypeDefinition.backgroundColor;

  if (!sessionChecked) return applyTemplate(Loader, CenteredTemplate, 'Loader', {})({ className: 'w-96 h-96' });
  if (!signedIn) return applyTemplate(SignInPage, CenteredTemplate, 'SignInPage')();
  if (quickPassActive) return applyTemplate(QuickPassPage, CenteredTemplate, 'QuickPassPage')();

  return <AppAuthenticated browserHistory={browserHistory} />;
};

export default connect(
  ({ theme, quickPass: { active }, session: { fetched, signedIn } }) => ({
    sessionChecked: fetched,
    signedIn,
    theme,
    quickPassActive: active,
  }),
  { sessionActionLookup },
)(App);
