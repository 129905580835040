import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { notificationT } from '../../../helpers/i18nHelpers';
import { notesWorkspaceFromDto, notesWorkspaceToDto } from '../../../helpers/model/notesWorkspacesHelpers';
import {
  notesWorkspacesActionSetElement,
  notesWorkspacesActionUnlockElement,
} from '../../actions/notesWorkspacesActions';

export default function* updateElementNotesWorkspacesSaga(action) {
  const initialWorkspace = action.payload;
  const { ok, data } = yield call(api.notesWorkspaces.update, notesWorkspaceToDto(initialWorkspace));
  if (ok) {
    yield put(snackbarMessagesActionAdd(notificationT('notesWorkspaces.update.success')));
    yield put(notesWorkspacesActionSetElement(notesWorkspaceFromDto(data)));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data, 'notesWorkspaces.update.errors'));
    yield put(notesWorkspacesActionUnlockElement(initialWorkspace));
  }
}
