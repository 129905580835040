import frenchkiss from 'frenchkiss';

frenchkiss.extend('en', {
  pages: {
    notes: {
      changeWorkspace: 'Change workspace',
      create: 'Create note',
      createChild: 'Create child note',
      newNoteName: 'New note',
      enableEdition: 'Enable changes',
      expand: 'Expand',
      collapse: 'Collapse',
      pdfFromMultipleNotes: 'PDF from multiple notes',
      consolidatedPdfForm: {
        defaultTitle: '',
        title: 'Title',
        showTitle: 'Show title on pdf',
        increasedSideMargins: 'Increased side margins',
        details: 'Details',
        selectNotes: 'Select notes',
        submit: 'Generate PDF',
      },
    },
  },
});
