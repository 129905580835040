import { connect } from 'react-redux';
import { getThemeTypeDefinition } from '../helpers/themeHelpers';
import { prepareState } from '../helpers/stateManagementHelpers';
import { applyStyle } from '../helpers/stylingHelpers';

const ThemeCrumb = (props) => {
  let { mixinColor } = props;
  const {
    theme,
    type,
    children,
    mixinType,
    mixinMultiplier = 0.5,
    mixedStyleKeys = [],
    mixinStyleKey = 'color',
    mixedStyleKeysMultipliers = mixedStyleKeys.reduce((result, key) => {
      result[key] = mixinMultiplier;
      return result;
    }, {}),
  } = props;
  const typeDefinition = getThemeTypeDefinition(theme, type);
  if (mixinType && !mixinColor) mixinColor = getThemeTypeDefinition(theme, mixinType)[mixinStyleKey];

  let colorsMixinsConfig = {};
  if (mixinColor) {
    for (const styleKey in mixedStyleKeysMultipliers) {
      colorsMixinsConfig[styleKey] = {
        color: mixinColor,
        multiplier: mixedStyleKeysMultipliers[styleKey],
      };
    }
  }

  return applyStyle(children, typeDefinition, colorsMixinsConfig);
};

export default connect(prepareState('theme'))(ThemeCrumb);
