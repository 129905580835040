import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { notificationT } from '../../../helpers/i18nHelpers';
import { push } from 'connected-react-router';
import { notesWorkspaceFromDto, notesWorkspaceToDto } from '../../../helpers/model/notesWorkspacesHelpers';
import {
  notesWorkspacesActionSetCreatedElement,
  notesWorkspacesActionUnlock,
} from '../../actions/notesWorkspacesActions';
import { elementToFocusOnActionSet } from '../../actions/elementToFocusOnActions';
import { elementToFocusOnTypes } from '../../../constants/elementToFocusOnTypes';

export default function* createElementNotesWorkspacesSaga(action) {
  const { ok, data } = yield call(api.notesWorkspaces.create, notesWorkspaceToDto(action.payload));
  if (ok) {
    const newWorkspace = notesWorkspaceFromDto(data);
    yield put(snackbarMessagesActionAdd(notificationT('notesWorkspaces.create.success')));
    yield put(notesWorkspacesActionSetCreatedElement(newWorkspace));
    yield put(push('/notes/workspaces'));
    yield put(elementToFocusOnActionSet(elementToFocusOnTypes.notesWorkspaceTitle, newWorkspace.id));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data, 'notesWorkspaces.create.errors'));
    yield put(notesWorkspacesActionUnlock());
  }
}
