import React, { useEffect, useRef } from 'react';
import { customPropsToValues, produceValuesFilter, withCustomReinitialize } from '../../../helpers/formHelpers';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { frontEndErrorT, initT } from '../../../helpers/i18nHelpers';
import EventModel from '../../../models/eventModel';
import { elementToFocusOnTypes } from '../../../constants/elementToFocusOnTypes';
import SmartForm from '../../form/SmartForm';
import CustomColorCrumb from '../../CustomColorCrumb';
import SmartField from '../../form/SmartField';
import TextField from '../../form/fieldTypes/TextField';
import ButtonsGroup from '../../buttons/ButtonsGroup';
import IconButton from '../../buttons/IconButton';
import isEmpty from 'lodash/isEmpty';
import CenteredContent from '../../organizers/CenteredContent';
import ColorField from '../../form/fieldTypes/ColorField';

const t = initT('features.eventCreator');

const validationSchema = () =>
  yup.object().shape({
    name: yup.string().required(frontEndErrorT('event.name.empty')),
  });
const relevantAttributes = ['name', 'date', 'color', 'private'];
const auxiliaryAttributes = [
  'locked',
  'incognitoMode',
  'setPrivate',
  'setColor',
  'elementToFocusOn',
  'elementToFocusOnActionReset',
  'initialFocus',
];
const filterValues = produceValuesFilter(relevantAttributes);

const InnerForm = (props) => {
  const { values, setFieldValue, setFieldTouched, handleSubmit, errors, touched, hideCreator, cancellable } = props;
  const { date, color, private: _private, auxiliary } = values;
  const { locked, incognitoMode, elementToFocusOn, elementToFocusOnActionReset, setPrivate, setColor, initialFocus } =
    auxiliary;

  const nameInputRef = useRef(null);

  useEffect(() => {
    if (locked === false && initialFocus) nameInputRef.current.focus();
  }, []);

  useEffect(() => {
    const shouldBeFocused = elementToFocusOn && elementToFocusOn.equals(elementToFocusOnTypes.eventCreationForm, date);
    if (locked === false && shouldBeFocused) {
      nameInputRef.current.focus();
      elementToFocusOnActionReset();
    }
  }, [locked]);

  const switchPrivate = () => {
    setFieldValue('private', !_private);
    setFieldTouched('private');
    setPrivate(!_private);
    nameInputRef.current.focus();
  };
  const switchPrivateVariant = _private ? 'unmarkPrivate' : 'markPrivate';
  const submissionDisabled = locked || !isEmpty(errors) || isEmpty(touched);

  return (
    <CustomColorCrumb
      styleKeys={['borderColor', 'backgroundColor']}
      color={color}
      mixinThemeType="container"
      mixedStyleKeysMultipliers={{ borderColor: 0.8, backgroundColor: 0.95 }}
    >
      <SmartForm className="flex items-end border rounded border-dotted">
        <div className="flex w-full py-1 pl-2">
          <CenteredContent
            left={
              <ButtonsGroup
                className="flex justify-end items-end px-1"
                buttons={[
                  <SmartField
                    key="color"
                    name="color"
                    component={ColorField}
                    onChange={(props, _defaultOnChange) => {
                      setColor(props.newValue);
                      _defaultOnChange(props);
                      nameInputRef.current.focus();
                    }}
                    displayError={false}
                    handleErrorThemeType={false}
                  />,
                  !incognitoMode && (
                    <IconButton key="switchPrivate" variant={switchPrivateVariant} onMouseDown={switchPrivate} />
                  ),
                ]}
              />
            }
            main={
              <SmartField
                name="name"
                ref={nameInputRef}
                placeholder={t('descriptionPrompt')}
                component={TextField}
                themeType="transparentInput"
                textAlignClass="text-center"
                displayError={false}
                handleErrorThemeType={false}
              />
            }
            right={
              <ButtonsGroup
                className="flex justify-end items-center px-1"
                buttons={[
                  cancellable && <IconButton key="cancel" variant="cancel" onMouseDown={hideCreator} />,
                  <IconButton
                    key="submit"
                    variant="submit"
                    disabled={submissionDisabled}
                    onMouseDown={handleSubmit}
                    paddingClass="p-2"
                  />,
                ]}
              />
            }
          />
        </div>
      </SmartForm>
    </CustomColorCrumb>
  );
};

const Form = withCustomReinitialize(['locked', 'date', 'incognitoMode'])(
  withFormik({
    validationSchema,
    mapPropsToValues: (props) => customPropsToValues(props, filterValues, auxiliaryAttributes),
    handleSubmit: (values, formikBag) => {
      const event = new EventModel().assignValues(filterValues(values));
      formikBag.props.eventsActionCreateElement(event);
      // the form is reinitialized because 'locked' prop is changed externally
    },
  })(InnerForm),
);

export default Form;
