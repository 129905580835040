import React from 'react';
import find from 'lodash/find';
import { connect } from 'react-redux';
import { prepareState } from '../../helpers/stateManagementHelpers';
import { activeElementTypes } from '../../constants/activeElementTypes';
import Modal from './Modal';
import HabitHidingConditionModalContent from './modalContents/habitHidingConditionModalContent';
import HabitSummaryRequestModalContent from './modalContents/habitSummaryRequestModalContent';
import { activeElementsActionDeactivate } from '../../stateManagement/actions/activeElementsActions';
import HabitChartRequestModalContent from './modalContents/habitChartRequestModalContent';
import HabitChartModalContent from './modalContents/habitChartModalContent';
import DayEvaluationsChartModalContent from './modalContents/dayEvaluationsChartModalContent';
import AudioSelectionModalContent from './modalContents/audioSelectionModalContent';
import CalendarDayModalContent from './modalContents/calendarDayModalContent';
import ConfirmationModalContent from './modalContents/confirmationModalContent';

const ModalsDisplay = ({ activeElements, theme, quickPass, activeElementsActionDeactivate }) => {
  const {
    confirmationModal,
    habitHidingConditionModal,
    habitSummaryRequestModal,
    habitChartRequestModal,
    habitChartModal,
    dayEvaluationsChartModal,
    audioSelectionModal,
    calendarDayModal,
  } = activeElementTypes;

  const modalsData = [
    [find(activeElements, { type: confirmationModal }), ConfirmationModalContent],
    [find(activeElements, { type: habitHidingConditionModal }), HabitHidingConditionModalContent],
    [find(activeElements, { type: habitSummaryRequestModal }), HabitSummaryRequestModalContent],
    [find(activeElements, { type: habitChartRequestModal }), HabitChartRequestModalContent],
    [find(activeElements, { type: habitChartModal }), HabitChartModalContent],
    [find(activeElements, { type: dayEvaluationsChartModal }), DayEvaluationsChartModalContent],
    [find(activeElements, { type: audioSelectionModal }), AudioSelectionModalContent],
    [find(activeElements, { type: calendarDayModal }), CalendarDayModalContent],
  ];

  return (
    <>
      {modalsData.map(([data, component]) => {
        if (quickPass.active || !data) return null;
        const { type, identifier, additionalData } = data;
        return (
          <Modal
            key={data.type}
            isOpen
            theme={theme}
            onRequestClose={() => activeElementsActionDeactivate(type, identifier)}
          >
            {React.createElement(component, { identifier, ...additionalData })}
          </Modal>
        );
      })}
    </>
  );
};

export default connect(prepareState(['activeElements', 'theme', 'quickPass']), { activeElementsActionDeactivate })(
  ModalsDisplay,
);
