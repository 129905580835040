import DayEvaluationsChartData from '../../models/dayEvaluationsChartData';

const initialState = () => ({});

export default function (state = initialState(), action) {
  const { date, variant, newDayEvaluationsChartData } = action.payload || {};

  switch (action.type) {
    case 'DAY_EVALUATIONS_CHARTS_DATA_ACTION_FETCH': {
      const dateDictionary = state[date] || {};
      const newDateDictionary = {
        ...dateDictionary,
        [variant]: new DayEvaluationsChartData(),
      };
      return { ...state, [date]: newDateDictionary };
    }
    case 'DAY_EVALUATIONS_CHARTS_DATA_ACTION_SET': {
      const dateDictionary = state[date] || {};
      return {
        ...state,
        [date]: { ...dateDictionary, [variant]: newDayEvaluationsChartData.toFetched() },
      };
    }
    case 'DAY_EVALUATIONS_CHARTS_DATA_ACTION_RESET':
    case 'GLOBAL_ACTION_RESET':
      return initialState();
    default:
      return state;
  }
}
