import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { prepareCollection } from '../../../helpers/model/modelHelpers';
import { eventsActionSet } from '../../actions/eventsActions';
import { eventFromDto } from '../../../helpers/model/eventHelpers';

export default function* fetchEventsSaga(action) {
  const { dates } = action.payload;
  const { ok, data } = yield call(api.events.index, action.payload.dates);
  if (ok) {
    yield put(eventsActionSet(dates, prepareCollection(data, eventFromDto)));
  }
}
