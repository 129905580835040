import React, { useState } from 'react';
import useResizeEffect from '../../../hooks/useResizeEffect';
import { getNavbarHeight } from '../../../helpers/elementsSizeHelpers';
import ThemeCrumb from '../../../shared/ThemeCrumb';
import SidebarButton from '../../../shared/buttons/SidebarButton';
import { useLocation } from 'react-router-dom';

const NavbarAdditionalMenu = (props) => {
  const { links, expanded, hide } = props;

  const [navbarHeightPx, setNavbarHeightPx] = useState();
  useResizeEffect(
    () => {
      setNavbarHeightPx(getNavbarHeight());
    },
    [],
    true,
  );
  const maxHeight = `calc(100vh - ${navbarHeightPx}px)`;

  const location = useLocation();

  if (!expanded) return <></>;

  return (
    <>
      <ThemeCrumb type="modalOverlay">
        <div
          className="fixed w-screen left-0 z-10"
          style={{
            height: maxHeight,
            maxHeight,
            top: `${navbarHeightPx}px`,
          }}
          onMouseDown={hide}
        />
      </ThemeCrumb>
      <ThemeCrumb type="navbarAdditionalMenu">
        <div
          className="fixed z-11 max-w-almost-screen p-25px
                               overflow-y-auto overscroll-contain rounded-bl-md"
          style={{
            maxHeight,
            right: 0,
            top: `${navbarHeightPx}px`,
          }}
        >
          {links.map(({ key, to, onMouseDown, tooltip, children }) => {
            const selected = typeof to === 'string' && location.pathname.startsWith(to.split('?')[0]);
            const buttonProps = {
              to,
              onMouseDown,
              children: tooltip || children,
              selected,
            };
            return <SidebarButton key={key} {...buttonProps} />;
          })}
        </div>
      </ThemeCrumb>
    </>
  );
};

export default NavbarAdditionalMenu;
