import React, { useCallback, useState } from 'react';
import useResizeEffect from '../../hooks/useResizeEffect';
import { produceGetHeightFunction } from '../../helpers/elementsSizeHelpers';
import { generateTagId } from '../../helpers/identifierHelpers';
import FullWindowHeightWrapper from '../heightWrappers/FullWindowHeightWrapper';
import { useCheckIfMounted } from '../../hooks/useCheckIfMounted';

const defaultTop = <div className="pt-25px" />;
const defaultBottom = <div className="pb-25px" />;

const getContent = (props) => {
  const { top, main, bottom, bottomId, bottomHeightPx, bottomReady } = props;

  return (
    <>
      {top && <div className="w-full">{top}</div>}
      {main && bottomReady && (
        <FullWindowHeightWrapper minHeight="10rem" marginBottomPx={bottomHeightPx}>
          {main}
        </FullWindowHeightWrapper>
      )}
      {bottom && (
        <div id={bottomId} className={`w-full ${bottomReady ? '' : 'invisible'}`}>
          {bottom}
        </div>
      )}
    </>
  );
};

const MenuContent = (props) => {
  const { top = defaultTop, main, bottom = defaultBottom } = props;

  const [bottomId] = useState(generateTagId());
  const [bottomReady, setBottomReady] = useState(false);
  const [bottomHeightPx, setBottomHeightPx] = useState();
  const checkIfMounted = useCheckIfMounted();

  const getBottomHeightPx = useCallback(produceGetHeightFunction(bottomId), [bottomId]);
  useResizeEffect(
    () => {
      // wait for bottom to rerender with new height
      setTimeout(() => {
        if (!checkIfMounted()) return;
        setBottomHeightPx(getBottomHeightPx());

        // wait for FullWindowHeightWrapper to refresh with regard to the bottom height - only then show the bottom
        setTimeout(() => {
          if (!checkIfMounted()) return;
          setBottomReady(true);
        });
      });
    },
    [getBottomHeightPx],
    true,
  );

  return getContent({
    top,
    main,
    bottom,
    bottomId,
    bottomHeightPx,
    bottomReady,
  });
};

export default MenuContent;
