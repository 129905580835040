import { produceValuesFilter } from '../../../../helpers/formHelpers';
import * as yup from 'yup';
import { frontEndErrorT, initT } from '../../../../helpers/i18nHelpers';

export const t = initT('pages.other.recurringTasks');

export const relevantAttributes = [
  'id',
  'categoryId',
  'nodesGroupId',
  'name',
  'generationActive',
  'startDate',
  'variant',
  'weekdayType',
  'monthType',
  'monthDay',
  'yearDay',
  'daysCount',
  'private',
];

export const auxiliaryAttributes = [
  'incognitoMode',
  'categories',
  'nodes',
  'editionMode',
  'onBlur',
  'nodesActionFetch',
  'status',
  'nextGenerationDate',
];

export const filterValues = produceValuesFilter(relevantAttributes);

export const validationSchema = () =>
  yup.object().shape({
    name: yup.string().required(frontEndErrorT('recurringTask.name.empty')),
    categoryId: yup.string().required(frontEndErrorT('recurringTask.categoryId.empty')),
    startDate: yup.string().required(frontEndErrorT('recurringTask.startDate.empty')),
  });
