import { put } from 'redux-saga/effects';
import { snackbarMessagesActionActivate } from '../../actions/snackbarMessagesActions';

export default function* snackbarMessagesAddSaga(action) {
  const { uuid } = action.payload;
  yield put(snackbarMessagesActionActivate(uuid));
  // one can uncomment to restore the previous approach with awaitingMessages queue
  // const activeMessage =
  //     yield select(({ snackbarMessages: { activeMessage } }) => activeMessage);
  // if (!activeMessage) {
  //     yield put(snackbarMessagesActionActivate(uuid));
  // }
}
