import filter from 'lodash/filter';

export const activeElementTypes = {
  fixedMenu: 'FIXED_MENU',
  habitsSection: 'HABITS_SECTION',
  habit: 'HABIT',
  task: 'TASK',
  recurringTask: 'RECURRING_TASK',
  doneTask: 'DONE_TASK',
  todoTask: 'TODO_TASK',
  calendarTaskCreator: 'CALENDAR_TASK_CREATOR',
  calendarEventCreator: 'CALENDAR_EVENT_CREATOR',
  nodesGroup: 'NODES_GROUP',
  node: 'NODE',
  nodeCreator: 'NODE_CREATOR',
  event: 'EVENT',
  recurringEvent: 'RECURRING_EVENT',
  confirmationModal: 'CONFIRMATION_MODAL',
  audioSelectionModal: 'AUDIO_SELECTION_MODAL',
  habitHidingConditionModal: 'HABIT_HIDING_CONDITION_MODAL',
  habitSummaryRequestModal: 'HABIT_SUMMARY_REQUEST_MODAL',
  habitChartRequestModal: 'HABIT_CHART_REQUEST_MODAL',
  habitChartModal: 'HABIT_CHART_MODAL',
  dayEvaluationsChartModal: 'DAY_EVALUATIONS_CHART_MODAL',
  calendarDayModal: 'CALENDAR_DAY_MODAL',
};

export const activeElementModalTypes = (() =>
  filter(Object.values(activeElementTypes), (value) => /.*_MODAL$/.test(value)))();
