import { useEffect } from 'react';
import isHotkey from 'is-hotkey';

export const useKeydown = (props) => {
  let { queue, currentChunk } = props;
  queue ||= {};
  currentChunk ||= {};
  const { next, back, empty, ended, nodeWithAudio, audioPaused, setAudioPaused } = props;

  useEffect(() => {
    if (!queue.id || empty) return;

    const nextCb = (e) => {
      if (isHotkey('space', e)) {
        if (nodeWithAudio && !audioPaused) setAudioPaused(true);
        else next();
      }
    };
    window.addEventListener('keydown', nextCb);
    return () => window.removeEventListener('keydown', nextCb);
  }, [currentChunk.nodeId, next, back, empty, ended, audioPaused, setAudioPaused]);
};
