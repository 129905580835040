import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { nodesActionSetElement, nodesActionUnlockElement } from '../../actions/nodesActions';
import { nodeFromDto, nodeToDto } from '../../../helpers/model/nodeHelpers';
import { snackbarMessagesActionHandleBackendErrors } from '../../actions/snackbarMessagesActions';

export default function* updateElementNodesSaga(action) {
  const { element: initialNode } = action.payload;
  const { ok, data } = yield call(api.nodes.update, nodeToDto(initialNode));
  if (ok) {
    yield put(nodesActionSetElement(nodeFromDto(data)));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(nodesActionUnlockElement(initialNode));
  }
}
