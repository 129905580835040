import { produceModelFromDto, produceModelToDto } from './modelHelpers';
import NotesWorkspace from '../../models/notesWorkspace';

const mappings = [
  ['id', 'id'],
  ['name', 'name'],
  ['default_order', 'defaultOrder'],
  ['private', 'private'],
];

export const notesWorkspaceFromDto = produceModelFromDto(NotesWorkspace, mappings);

export const notesWorkspaceToDto = produceModelToDto(mappings);
