import React from 'react';
import ThemeCrumb from '../../../../../ThemeCrumb';
import Clickable from '../../../../../Clickable';

const ReadOnlyLink = React.forwardRef((props, ref) => {
  const { disabled, children, url, attributes } = props;
  return (
    <ThemeCrumb type="link">
      <Clickable href={disabled ? null : url} ref={ref} target="_blank" className="readonly-link" {...attributes}>
        {children}
      </Clickable>
    </ThemeCrumb>
  );
});

export default ReadOnlyLink;
