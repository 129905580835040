import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { notificationT } from '../../../helpers/i18nHelpers';
import { activeElementsActionActivateIfOtherInactive } from '../../actions/activeElementsActions';
import { activeElementTypes } from '../../../constants/activeElementTypes';
import { eventsActionSetElement, eventsActionUnlockElement } from '../../actions/eventsActions';
import { eventFromDto, eventToDto } from '../../../helpers/model/eventHelpers';

export default function* updateElementEventsSaga(action) {
  const { element: initialEvent } = action.payload;
  const { ok, data } = yield call(api.events.update, eventToDto(initialEvent));
  if (ok) {
    const updatedEvent = eventFromDto(data);
    yield put(snackbarMessagesActionAdd(notificationT('events.update.success')));
    yield put(eventsActionSetElement(updatedEvent));
    yield put(activeElementsActionActivateIfOtherInactive(activeElementTypes.event, updatedEvent.id));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(eventsActionUnlockElement(initialEvent));
  }
}
