import React from 'react';
import { customPropsToValues, enumToSelectorValues, withCustomReinitialize } from '../../../helpers/formHelpers';
import { withFormik } from 'formik';
import Habit from '../../../models/habit';
import { connect } from 'react-redux';
import { habitsActionCreateElement, habitsActionUpdateElement } from '../../../stateManagement/actions/habitActions';
import HabitHeader from './HabitHeader';
import LabelField from '../../../shared/form/LabelField';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { inputTypes } from '../../../constants/enums/inputTypes';
import { habitsVariants } from '../../../constants/enums/habitsVariants';
import { getAllHabitsSections } from '../../../helpers/habitsSectionHelpers';
import SmartField from '../../../shared/form/SmartField';
import CheckboxField from '../../../shared/form/fieldTypes/CheckBoxField';
import SelectorField from '../../../shared/form/fieldTypes/SelectorField';
import { activeElementsActionActivate } from '../../../stateManagement/actions/activeElementsActions';
import TextField from '../../../shared/form/fieldTypes/TextField';
import CollectionField from '../../../shared/form/collectionField/CollectionField';
import {
  auxiliaryAttributes,
  chartRequestT,
  filterValues,
  hidingConditionT,
  summaryRequestT,
  t,
  validationSchema,
} from './HabitFormHelpers';
import { activeElementTypes } from '../../../constants/activeElementTypes';
import SmartForm from '../../../shared/form/SmartForm';
import OnClickOutsideWrapper from '../../../shared/onClickOutside/OnClickOutsideWrapper';
import { useDoubleClickToBlur } from '../../../hooks/useDoubleClickToBlur';
import StandardFormFooter from '../../../shared/form/StandardFormFooter';

const HabitInnerForm = (props) => {
  const { values, handleSubmit } = props;
  const {
    id,
    inputType,
    habitHidingConditions,
    habitSummaryRequests,
    habitChartRequests,
    auxiliary: {
      habitHeaderParams,
      incognitoMode,
      habitsSections: sections,
      editionMode,
      deactivateEditionMode,
      deactivateContainer,
    },
  } = values;
  const { onClickInside, onClickOutside, aboutToBlur } = useDoubleClickToBlur({
    onBlur: editionMode ? deactivateEditionMode : deactivateContainer,
    enabled: editionMode,
  });

  const hidingConditionsAllowed =
    [inputTypes.checkbox, inputTypes.counter].includes(inputType) && (editionMode || habitHidingConditions.length > 0);
  const summaryRequestsAllowed =
    [inputTypes.checkbox, inputTypes.counter, inputTypes.realNumber].includes(inputType) &&
    (editionMode || habitSummaryRequests.length > 0);
  const chartRequestsAllowed =
    [inputTypes.checkbox, inputTypes.counter, inputTypes.realNumber].includes(inputType) &&
    (editionMode || habitChartRequests.length > 0);

  const inputTypesOptions = enumToSelectorValues(inputTypes, 'inputTypes');
  const habitsVariantsOptions = enumToSelectorValues(habitsVariants, 'habitsVariants');
  const sectionsOptions = getAllHabitsSections(sections, incognitoMode).map((section) => ({
    value: section.id,
    label: section.name,
  }));

  return (
    <OnClickOutsideWrapper
      onClickOutside={onClickOutside}
      onClick={onClickInside}
      className={aboutToBlur ? 'animate-inactive-pulse ' : ''}
    >
      {editionMode ? <></> : <HabitHeader {...habitHeaderParams} />}
      <SmartForm className="p-25px">
        <div className="flex justify-center">
          <div className="w-full sm:w-big-golden-ratio max-w-xs">
            {editionMode ? (
              <>
                <LabelField name="name" label={t('fields.name')} required component={TextField} />
                {id ? (
                  <></>
                ) : (
                  <LabelField
                    name="inputType"
                    className="pt-25px"
                    label={t('fields.inputType')}
                    options={inputTypesOptions}
                    required
                    component={SelectorField}
                  />
                )}
                <LabelField
                  name="habitsSectionId"
                  className="pt-25px"
                  label={t('fields.habitsSectionId')}
                  options={sectionsOptions}
                  required
                  component={SelectorField}
                  selectedOptionOnTop
                />
                <LabelField
                  name="variant"
                  className="py-25px"
                  label={t('fields.variant')}
                  options={habitsVariantsOptions}
                  presentationMode={!editionMode}
                  required
                  component={SelectorField}
                />
              </>
            ) : (
              <></>
            )}
            <div className="flex justify-center">
              <SmartField
                name="considerOnlyUpdatedValues"
                widthClass={editionMode ? 'w-full' : 'w-fit-content'}
                label={t('fields.considerOnlyUpdatedValues')}
                presentationMode={!editionMode}
                component={CheckboxField}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center">
          <div className="w-full sm:w-3/4 max-w-md">
            <CollectionField
              name="habitHidingConditions"
              className="pt-25px"
              label={t('hidingConditions.title')}
              hidden={!hidingConditionsAllowed}
              presentationMode={!editionMode}
              getElementLabel={(element) => hidingConditionT(element)}
              modalToOpen={activeElementTypes.habitHidingConditionModal}
            />
            <CollectionField
              name="habitSummaryRequests"
              className="pt-25px"
              label={t('summaryRequests.title')}
              hidden={!summaryRequestsAllowed}
              presentationMode={!editionMode}
              getElementLabel={(element) => summaryRequestT(element)}
              modalToOpen={activeElementTypes.habitSummaryRequestModal}
            />
            <CollectionField
              name="habitChartRequests"
              className="pt-25px"
              label={t('chartRequests.title')}
              hidden={!chartRequestsAllowed}
              presentationMode={!editionMode}
              getElementLabel={(element) => chartRequestT(element)}
              modalToOpen={activeElementTypes.habitChartRequestModal}
            />
          </div>
        </div>
        {editionMode && (
          <StandardFormFooter marginClass="mt-64px" handleSubmit={handleSubmit} handleCancel={deactivateEditionMode} />
        )}
      </SmartForm>
    </OnClickOutsideWrapper>
  );
};

const HabitForm = withCustomReinitialize(['incognitoMode', 'habitsSections'])(
  withFormik({
    validationSchema,
    mapPropsToValues: (props) => customPropsToValues(props, filterValues, auxiliaryAttributes),
    handleSubmit: (values, formikBag) => {
      const habit = new Habit().assignValues(filterValues(values));
      if (habit.id) formikBag.props.habitsActionUpdateElement(habit);
      else formikBag.props.habitsActionCreateElement(habit);
      setTimeout(formikBag.props.deactivateEditionMode());
    },
  })(HabitInnerForm),
);

export default connect(prepareState(['incognitoMode', 'habitsSections']), {
  habitsActionCreateElement,
  habitsActionUpdateElement,
  activeElementsActionActivate,
})(HabitForm);
