import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { notificationT } from '../../../helpers/i18nHelpers';
import { push } from 'connected-react-router';
import { languageWorkspaceFromDto, languageWorkspaceToDto } from '../../../helpers/model/languageWorkspacesHelpers';
import {
  languageWorkspacesActionSetCreatedElement,
  languageWorkspacesActionUnlock,
} from '../../actions/languageWorkspacesActions';
import { elementToFocusOnActionSet } from '../../actions/elementToFocusOnActions';
import { elementToFocusOnTypes } from '../../../constants/elementToFocusOnTypes';

export default function* createElementLanguageWorkspacesSaga(action) {
  const { ok, data } = yield call(api.languageWorkspaces.create, languageWorkspaceToDto(action.payload));
  if (ok) {
    const newWorkspace = languageWorkspaceFromDto(data);
    yield put(snackbarMessagesActionAdd(notificationT('languageWorkspaces.create.success')));
    yield put(languageWorkspacesActionSetCreatedElement(newWorkspace));
    yield put(push('/language/workspaces'));
    yield put(elementToFocusOnActionSet(elementToFocusOnTypes.languageWorkspaceTitle, newWorkspace.id));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data, 'languageWorkspaces.create.errors'));
    yield put(languageWorkspacesActionUnlock());
  }
}
