import React from 'react';
import Bullet from '../elementVariants/Bullet';
import Link from '../elementVariants/Link';
import Block from '../elementVariants/Block';
import { blockVariants } from '../constants/blockVariants';
import { nestedElementVariants } from '../constants/nestedElementVariants';
import OrderedBullet from '../elementVariants/OrderedBullet';
import ChecklistBullet from '../elementVariants/ChecklistBullet';
import { sanitizeProps } from '../helpers/propsHelpers';
import ReadOnlyLink from '../elementVariants/ReadOnlyLink';

export const produceRenderElement =
  (readOnly, clickable, checklistItemClickable, onChecklistItemClicked) =>
  ({ element, children, attributes }) => {
    const props = { ...sanitizeProps(element), attributes, element };
    switch (element.type) {
      // highest elements
      case blockVariants.block:
        return <Block {...props}>{children}</Block>;
      case blockVariants.bullet:
        return <Bullet {...props}>{children}</Bullet>;
      case blockVariants.orderedBullet:
        return <OrderedBullet {...props}>{children}</OrderedBullet>;
      case blockVariants.checklistBullet:
        return (
          <ChecklistBullet
            {...props}
            readOnly={readOnly}
            checklistItemClickable={checklistItemClickable}
            onChecklistItemClicked={onChecklistItemClicked}
          >
            {children}
          </ChecklistBullet>
        );

      // nested elements
      case nestedElementVariants.link:
        if (readOnly)
          return (
            <ReadOnlyLink {...props} disabled={clickable}>
              {children}
            </ReadOnlyLink>
          );
        else return <Link {...props}>{children}</Link>;

      default:
        return <></>;
    }
  };
