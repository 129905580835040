import ActiveElement from '../../models/auxiliary/activeElement';

export const activeElementsActionActivate = (type, identifier, additionalData = {}) => ({
  type: 'ACTIVE_ELEMENTS_ACTION_ACTIVATE',
  payload: new ActiveElement(type, identifier, additionalData),
});

export const activeElementsActionActivateIfOtherInactive = (type, identifier, additionalData = {}) => ({
  type: 'ACTIVE_ELEMENTS_ACTION_ACTIVATE_IF_OTHER_INACTIVE',
  payload: new ActiveElement(type, identifier, additionalData),
});

export const activeElementsActionDeactivate = (type, identifier) => ({
  type: 'ACTIVE_ELEMENTS_ACTION_DEACTIVATE',
  payload: new ActiveElement(type, identifier),
});

export const activeElementsActionDeactivateAllOfType = (type) => ({
  type: 'ACTIVE_ELEMENTS_ACTION_DEACTIVATE_ALL_OF_TYPE',
  payload: type,
});
