import React from 'react';
import { enumT } from '../../helpers/i18nHelpers';
import toLower from 'lodash/toLower';
import { inputTypes } from '../../constants/enums/inputTypes';
import min from 'lodash/min';
import { aggregationMethods } from '../../constants/enums/aggregationMethods';
import { habitChartRequestVariants } from '../../constants/enums/habitChartRequestVariants';
import max from 'lodash/max';
import Chart from './chart';

const getLabelTitle = (requestVariant, aggregationMethod) => {
  const requestVariantT = enumT(`habitChartRequestVariants.${requestVariant}`);
  const aggregationMethodT = toLower(enumT(`aggregationMethods.${aggregationMethod}`));
  return `${requestVariantT} ${aggregationMethodT}`;
};

const getYMinValue = (inputType, values) => {
  if (inputType === inputTypes.checkbox) return 0;
  return min(values);
};

const getYMaxValue = (inputType, variant, aggregationMethod, values) => {
  if (inputType === inputTypes.checkbox) {
    if (aggregationMethod === aggregationMethods.valuesAverage) return 1.0;
    if (aggregationMethod === aggregationMethods.valuesAverage) {
      if (variant === habitChartRequestVariants.daily) return 1;
      if (variant === habitChartRequestVariants.weekly) return 7;
      if (variant === habitChartRequestVariants.monthly) return 31;
    }
  }
  return max(values);
};

const getStepSize = (inputType, aggregationMethod) => {
  if (inputType === inputTypes.checkbox && aggregationMethod === aggregationMethods.valuesSum) return 1;
  if (inputType === inputTypes.counter && aggregationMethod === aggregationMethods.valuesSum) return 1;
};

const HabitChart = (props) => {
  const {
    habit: { inputType },
    habitChartRequest: { variant, aggregationMethod },
    chartData,
    ...remainingProps
  } = props;

  if (!chartData || !chartData.data) return <></>;

  const labels = chartData.data.map((element) => element.label);
  const values = chartData.data.map((element) => element.value);

  return (
    <Chart
      labels={labels}
      values={values}
      labelTitle={getLabelTitle(variant, aggregationMethod)}
      yMin={getYMinValue(inputType, values)}
      yMax={getYMaxValue(inputType, variant, aggregationMethod, values)}
      stepSize={getStepSize(inputType, aggregationMethod)}
      {...remainingProps}
    />
  );
};

export default HabitChart;
