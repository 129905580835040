export const learningWorkspacesActionFetch = () => ({
  type: 'LEARNING_WORKSPACES_ACTION_FETCH',
});

export const learningWorkspacesActionSet = (learningWorkspaces) => ({
  type: 'LEARNING_WORKSPACES_ACTION_SET',
  payload: learningWorkspaces,
});

export const learningWorkspacesActionCreateElement = (learningWorkspace) => ({
  type: 'LEARNING_WORKSPACES_ACTION_CREATE_ELEMENT',
  payload: learningWorkspace,
});

export const learningWorkspacesActionUnlock = () => ({
  type: 'LEARNING_WORKSPACES_ACTION_UNLOCK',
});

export const learningWorkspacesActionUpdateElement = (learningWorkspace) => ({
  type: 'LEARNING_WORKSPACES_ACTION_UPDATE_ELEMENT',
  payload: learningWorkspace,
});

export const learningWorkspacesActionMarkPrivateElement = (learningWorkspace) => ({
  type: 'LEARNING_WORKSPACES_ACTION_MARK_PRIVATE_ELEMENT',
  payload: learningWorkspace.assignValues({ private: true }),
});

export const learningWorkspacesActionUnmarkPrivateElement = (learningWorkspace) => ({
  type: 'LEARNING_WORKSPACES_ACTION_UNMARK_PRIVATE_ELEMENT',
  payload: learningWorkspace.assignValues({ private: false }),
});

export const learningWorkspacesActionLockElement = (learningWorkspace) => ({
  type: 'LEARNING_WORKSPACES_ACTION_LOCK_ELEMENT',
  payload: learningWorkspace,
});

export const learningWorkspacesActionUnlockElement = (learningWorkspace) => ({
  type: 'LEARNING_WORKSPACES_ACTION_UNLOCK_ELEMENT',
  payload: learningWorkspace,
});

export const learningWorkspacesActionDeleteElement = (learningWorkspace) => ({
  type: 'LEARNING_WORKSPACES_ACTION_DELETE_ELEMENT',
  payload: learningWorkspace,
});

export const learningWorkspacesActionSetCreatedElement = (learningWorkspace) => ({
  type: 'LEARNING_WORKSPACES_ACTION_SET_CREATED_ELEMENT',
  payload: learningWorkspace,
});

export const learningWorkspacesActionSetElement = (learningWorkspace) => ({
  type: 'LEARNING_WORKSPACES_ACTION_SET_ELEMENT',
  payload: learningWorkspace,
});

export const learningWorkspacesActionUnset = (oneOrManyElements) => ({
  type: 'LEARNING_WORKSPACES_ACTION_UNSET',
  payload: oneOrManyElements,
});
