import React from 'react';
import ThemeCrumb from '../ThemeCrumb';
import H3 from './H3';

const SubSectionTitle = (props) => {
  const { children, marginClass = 'mb-2', additionalContent = <></> } = props;

  return (
    <div className={`w-full flex justify-start truncate ${marginClass}`}>
      <ThemeCrumb type="sectionTitle">
        <span>
          <H3>{children}</H3>
        </span>
      </ThemeCrumb>
      {additionalContent}
    </div>
  );
};

export default SubSectionTitle;
