import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { tasksActionSetCreatedElement, tasksActionUnlock } from '../../actions/tasksActions';
import { taskFromDto, taskToDto } from '../../../helpers/model/taskHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { notificationT } from '../../../helpers/i18nHelpers';
import { elementToFocusOnActionSet } from '../../actions/elementToFocusOnActions';
import { elementToFocusOnTypes } from '../../../constants/elementToFocusOnTypes';

export default function* createElementTasksSaga(action) {
  const task = action.payload;
  const { ok, data } = yield call(api.tasks.create, taskToDto(task));
  if (ok) {
    const newTask = taskFromDto(data);
    yield put(elementToFocusOnActionSet(elementToFocusOnTypes.taskCreationForm, null));
    yield put(snackbarMessagesActionAdd(notificationT('tasks.create.success')));
    yield put(tasksActionSetCreatedElement(newTask));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(tasksActionUnlock());
  }
}
