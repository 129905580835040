import React from 'react';
import ThemeCrumb from '../../shared/ThemeCrumb';

const Bottom = () => {
  return (
    <div className="w-full">
      <ThemeCrumb type="progressContainer">
        <div className="rounded-t-xl w-full border flex justify-start">
          <ThemeCrumb type="progress">
            <div id="audio-queue-run-bottom-progress-bar" className="rounded-tl-xl h-5" style={{ width: 0 }} />
          </ThemeCrumb>
          <div id="audio-queue-run-bottom-label" className="absolute left-0 right-0 pulse" />
        </div>
      </ThemeCrumb>
    </div>
  );
};

export default Bottom;
