import React from 'react';
import TextField from './TextField';

const NumberField = (props) => {
  const { min, max, step } = props;

  return <TextField {...props} inputType="number" additionalInputProps={{ min, max, step }} />;
};

export default NumberField;
