import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import Error404Page from './pages/error/404';
import ErrorDefaultPage from './pages/error/default';
import { applyTemplate } from './helpers/templateHelpers';
import StandardTemplate from './templates/StandardTemplate';
import NotesIndexPage from './pages/notes/index';
import CalendarPage from './pages/calendar';
import TasksIndexPage from './pages/tasks/index';
import HabitsIndexPage from './pages/habits/index';
import ProfilePage from './pages/profile';
import NotesWorkspacesIndexPage from './pages/notesWorkspaces/index';
import OtherIndexPage from './pages/other/index';
import NotesConsolidatedPdfPage from './pages/notes/consolidatedPdf';
import MonthlyCalendarPage from './pages/monthlyCalendar';
import FinderPage from './pages/finder';
import LearningWorkspacesIndexPage from './pages/learningWorkspaces/index';
import LanguageWorkspacesIndexPage from './pages/languageWorkspaces/index';
import LearningPage from './pages/learning';
import LanguagePage from './pages/language';
import WastesIndexPage from './pages/wasteBin/index';

const AppRouter = ({ browserHistory }) => (
  <ConnectedRouter history={browserHistory}>
    <Switch>
      <Redirect exact from="/" to="/journal" />
      <Route
        exact
        path="/calendar"
        component={applyTemplate(MonthlyCalendarPage, StandardTemplate, 'MonthlyCalendarPage')}
      />
      <Route exact path="/finder" component={applyTemplate(FinderPage, StandardTemplate, 'FinderPage')} />
      <Route
        exact
        path="/journal/:journalSectionId?"
        component={applyTemplate(CalendarPage, StandardTemplate, 'CalendarPage')}
      />
      <Route
        exact
        path="/notes/workspaces"
        component={applyTemplate(NotesWorkspacesIndexPage, StandardTemplate, 'NotesWorkspacesIndexPage')}
      />
      <Route
        exact
        path="/learning/workspaces"
        component={applyTemplate(LearningWorkspacesIndexPage, StandardTemplate, 'LearningWorkspacesIndexPage')}
      />
      <Route
        exact
        path="/language/workspaces"
        component={applyTemplate(LanguageWorkspacesIndexPage, StandardTemplate, 'LanguageWorkspacesIndexPage')}
      />
      <Route
        exact
        path="/notes/consolidated-pdf"
        component={applyTemplate(NotesConsolidatedPdfPage, StandardTemplate, 'NotesConsolidatedPdfPage')}
      />
      <Route
        exact
        path="/notes/:noteId?"
        component={applyTemplate(NotesIndexPage, StandardTemplate, 'NotesIndexPage')}
      />
      <Route
        exact
        path="/habits/:habitsSectionId?"
        component={applyTemplate(HabitsIndexPage, StandardTemplate, 'HabitsIndexPage')}
      />
      <Route exact path="/profile" component={applyTemplate(ProfilePage, StandardTemplate, 'ProfilePage')} />
      <Route
        exact
        path="/tasks/:taskCategoryId?"
        component={applyTemplate(TasksIndexPage, StandardTemplate, 'TasksIndexPage')}
      />
      <Route
        exact
        path="/other/:otherSectionName?"
        component={applyTemplate(OtherIndexPage, StandardTemplate, 'OtherIndexPage')}
      />
      <Route
        exact
        path="/learning/:queueId?"
        component={applyTemplate(LearningPage, StandardTemplate, 'LearningPage')}
      />
      <Route
        exact
        path="/waste-bin/:modelName?"
        component={applyTemplate(WastesIndexPage, StandardTemplate, 'WastesIndexPage')}
      />
      <Route exact path="/language" component={applyTemplate(LanguagePage, StandardTemplate, 'LanguagePage')} />
      <Route exact path="/error" component={applyTemplate(ErrorDefaultPage, StandardTemplate, 'ErrorDefaultPage')} />
      <Route path="" component={applyTemplate(Error404Page, StandardTemplate, 'Error404Page')} />
    </Switch>
  </ConnectedRouter>
);

export default AppRouter;
