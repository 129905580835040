import MultiFetchableCollection from '../../models/auxiliary/MultiFetchableCollection';

const initialState = () => new MultiFetchableCollection();

export default function (state = initialState(), action) {
  const { learningWorkspaceId, newElements, element, oneOrManyElements } = action.payload || {};

  switch (action.type) {
    case 'LEARNING_QUEUES_ACTION_FETCH':
      return state.toNotFetched(learningWorkspaceId);
    case 'LEARNING_QUEUES_ACTION_SET':
      return state.toFetched(learningWorkspaceId, false).toUnlocked(false).withReplacedElements(newElements);
    case 'LEARNING_QUEUES_ACTION_CREATE_ELEMENT':
      return state.toLocked();
    case 'LEARNING_QUEUES_ACTION_UPDATE_ELEMENT':
    case 'LEARNING_QUEUES_ACTION_MARK_PRIVATE_ELEMENT':
    case 'LEARNING_QUEUES_ACTION_UNMARK_PRIVATE_ELEMENT':
    case 'LEARNING_QUEUES_ACTION_DELETE_ELEMENT':
    case 'LEARNING_QUEUES_ACTION_LOCK_ELEMENT':
      return state.withActualizedElements(element.toLocked(), ['locked']);
    case 'LEARNING_QUEUES_ACTION_UNLOCK_ELEMENT':
      return state.withActualizedElements(element.toUnlocked(), ['locked']);
    case 'LEARNING_QUEUES_ACTION_SET_CREATED_ELEMENT':
      return state.toUnlocked().withReplacedElements(element);
    case 'LEARNING_QUEUES_ACTION_SET_ELEMENT':
      return state.withReplacedElements(element.toUnlocked());
    case 'LEARNING_QUEUES_ACTION_UNSET':
      return state.withRemovedElements(oneOrManyElements);
    case 'LEARNING_WORKSPACES_ACTION_UNSET':
      return state.toNotFetched(action.payload.id, false);
    case 'LEARNING_QUEUES_ACTION_RESET':
    case 'GLOBAL_ACTION_RESET':
      return initialState();
    default:
      return state;
  }
}
