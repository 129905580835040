import frenchkiss from 'frenchkiss';

frenchkiss.extend('en', {
  pages: {
    finder: {
      title: 'Finder',
      searchPlaceholder: 'Search...',
      atLeast3ToSearch: 'Provide at least 3 characters to start searching',
      searchError: 'An error occurred and the search was unsuccessful',
      collectionNames: {
        events: 'Events',
        checkedTasks: 'Checked-off tasks',
      },
      foundElement: {
        seeInJournal: 'See this day in journal',
        seeInCalendar: 'See this month in calendar',
      },
    },
  },
});
