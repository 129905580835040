import React, { useEffect, useMemo } from 'react';
import {
  customPropsToValues,
  enumToSelectorValues,
  monthDaySelectorValues,
  produceShouldUpdate,
  withCustomReinitialize,
} from '../../../../helpers/formHelpers';
import { withFormik } from 'formik';
import { t, auxiliaryAttributes, filterValues, validationSchema } from './RecurringEventFormHelpers';
import { connect } from 'react-redux';
import { prepareState } from '../../../../helpers/stateManagementHelpers';
import { activeElementsActionActivate } from '../../../../stateManagement/actions/activeElementsActions';
import SmartForm from '../../../../shared/form/SmartForm';
import LabelField from '../../../../shared/form/LabelField';
import TextField from '../../../../shared/form/fieldTypes/TextField';
import DateField from '../../../../shared/form/fieldTypes/DateField';
import SelectorField from '../../../../shared/form/fieldTypes/SelectorField';
import AuxiliaryText from '../../../../shared/AuxiliaryText';
import SmartField from '../../../../shared/form/SmartField';
import RadioField from '../../../../shared/form/fieldTypes/RadioField';
import { weekdayTypes } from '../../../../constants/enums/weekdayTypes';
import { monthTypes } from '../../../../constants/enums/monthTypes';
import find from 'lodash/find';
import Hint from '../../../../shared/Hint';
import Label from '../../../../shared/form/Label';
import NodesGroupPanel from '../../../../shared/features/nodesGroupPanel';
import CheckboxField from '../../../../shared/form/fieldTypes/CheckBoxField';
import { nodesActionFetch } from '../../../../stateManagement/actions/nodesActions';
import {
  recurringEventsActionCreateElement,
  recurringEventsActionUpdateElement,
} from '../../../../stateManagement/actions/recurringEventsActions';
import RecurringEvent from '../../../../models/recurringEvent';
import { recurringEventVariants } from '../../../../constants/enums/recurringEventVariants';
import OnClickOutsideWrapper from '../../../../shared/onClickOutside/OnClickOutsideWrapper';
import { DateTime } from 'luxon';
import { backendDateFormat } from '../../../../constants/dateFormat';
import ColorVerboseField from '../../../../shared/form/fieldTypes/ColorVerboseField';
import { useDoubleClickToBlur } from '../../../../hooks/useDoubleClickToBlur';
import StandardFormFooter from '../../../../shared/form/StandardFormFooter';

const RecurringEventInnerForm = (props) => {
  const { handleSubmit, setFieldValue, setFieldTouched, values } = props;
  const { id, monthType, yearDay, variant, nodesGroupId, startDate } = values;
  let { nodes, editionMode, onBlur, nodesActionFetch } = values.auxiliary;
  if (editionMode === '') editionMode = true;
  if (onBlur === '') onBlur = () => {};

  const startDateTime = DateTime.fromFormat(startDate, backendDateFormat);
  const pastStartDate = !startDateTime.invalid && startDateTime < DateTime.now();

  const { onClickInside, onClickOutside, aboutToBlur } = useDoubleClickToBlur({ onBlur, enabled: editionMode });

  const weekdayTypeOptions = enumToSelectorValues(weekdayTypes, 'weekdayTypes');
  const monthTypeOptions = enumToSelectorValues(monthTypes, 'monthTypes');
  const monthDayOptions = useMemo(monthDaySelectorValues, []);
  const yearDayOptions = useMemo(() => monthDaySelectorValues(monthType), [monthType]);

  useEffect(() => {
    if (!find(yearDayOptions, { value: yearDay })) {
      setFieldValue('yearDay', 1);
      setFieldTouched('yearDay');
    }
  }, [yearDayOptions]);

  const nodesFetched = id && nodesGroupId && nodes.fetched(nodesGroupId);
  useEffect(() => {
    if (id && nodesGroupId && !nodesFetched) nodesActionFetch(nodesGroupId);
  }, [id, nodesGroupId, nodesFetched]);

  const showDetails = id && nodesGroupId;

  return (
    <OnClickOutsideWrapper
      onClickOutside={onClickOutside}
      onClick={onClickInside}
      className={aboutToBlur ? 'animate-inactive-pulse ' : ''}
    >
      <SmartForm className="p-25px">
        {editionMode && id ? <AuxiliaryText className="pb-25px">{t('updateDisclaimer')}</AuxiliaryText> : <></>}
        <div className="flex justify-center">
          <div className="w-full sm:w-big-golden-ratio max-w-xs">
            {editionMode ? (
              <>
                <LabelField name="name" label={t('fields.name')} required component={TextField} />
                <LabelField
                  name="color"
                  label={t('fields.color')}
                  required
                  className="my-25px"
                  component={ColorVerboseField}
                />
              </>
            ) : (
              <></>
            )}
            <SmartField
              name="generationActive"
              label={t('fields.generationActive')}
              presentationMode={!editionMode}
              component={CheckboxField}
            />
            {pastStartDate && !editionMode ? (
              <>
                <Label className="mt-25px" hint={t('hints.startDate')}>
                  {t('fields.startDate')}
                </Label>
                <AuxiliaryText>{t('pastStartDate')}</AuxiliaryText>
              </>
            ) : (
              <LabelField
                name="startDate"
                label={t('fields.startDate')}
                className="mt-25px"
                required
                presentationMode={!editionMode}
                component={DateField}
                isClearable={false}
                hint={t('hints.startDate')}
              />
            )}
            <SmartField
              name="variant"
              component={RadioField}
              className="mt-25px"
              hidden={variant !== recurringEventVariants.weekly && !editionMode}
              radioValue={recurringEventVariants.weekly}
              presentationMode={!editionMode}
              label={
                <div className="flex flex-nowrap items-center">
                  <div>{t('variants.weekly.every')}</div>
                  <div className="ml-2 w-36">
                    <SmartField
                      name="weekdayType"
                      presentationMode={!editionMode}
                      options={weekdayTypeOptions}
                      leftPadding={false}
                      component={SelectorField}
                    />
                  </div>
                </div>
              }
            />
            <SmartField
              name="variant"
              component={RadioField}
              className="mt-25px"
              hidden={variant !== recurringEventVariants.monthly && !editionMode}
              radioValue={recurringEventVariants.monthly}
              presentationMode={!editionMode}
              label={
                <div className="flex flex-nowrap items-center">
                  <div className="mr-2 w-20">
                    <SmartField
                      name="monthDay"
                      presentationMode={!editionMode}
                      options={monthDayOptions}
                      leftPadding={false}
                      component={SelectorField}
                    />
                  </div>
                  <div className="flex flex-no-wrap mr-1">{t('variants.monthly.dayOf')}</div>
                  <Hint>{t('hints.monthDay')}</Hint>
                </div>
              }
            />
            <SmartField
              name="variant"
              component={RadioField}
              className="mt-25px"
              hidden={variant !== recurringEventVariants.annually && !editionMode}
              radioValue={recurringEventVariants.annually}
              presentationMode={!editionMode}
              shouldUpdate={produceShouldUpdate(['monthType'])}
              label={
                <div
                  className="flex flex-wrap sm:flex-nowrap
                                                        items-center"
                >
                  <div className="mr-2 w-20">
                    <SmartField
                      name="yearDay"
                      presentationMode={!editionMode}
                      options={yearDayOptions}
                      leftPadding={false}
                      shouldUpdate={produceShouldUpdate(['monthType'])}
                      component={SelectorField}
                    />
                  </div>
                  <div className="mr-2">{t('variants.annually.dayOf')}</div>
                  <div className="w-36">
                    <SmartField
                      name="monthType"
                      presentationMode={!editionMode}
                      options={monthTypeOptions}
                      leftPadding={false}
                      component={SelectorField}
                    />
                  </div>
                </div>
              }
            />
          </div>
        </div>
        {editionMode && <StandardFormFooter marginClass="mt-64px" handleSubmit={handleSubmit} handleCancel={onBlur} />}
      </SmartForm>
      {showDetails ? (
        <div className="pb-25px px-25px">
          <div className="pb-3">
            <Label>{t('fields.details')}</Label>
          </div>
          <NodesGroupPanel nodesGroupId={nodesGroupId} editionMode={editionMode} />
        </div>
      ) : (
        <></>
      )}
    </OnClickOutsideWrapper>
  );
};

const RecurringEventForm = withCustomReinitialize(['editionMode'])(
  withFormik({
    validationSchema,
    mapPropsToValues: (props) => customPropsToValues(props, filterValues, auxiliaryAttributes),
    handleSubmit: (values, formikBag) => {
      const recurringEvent = new RecurringEvent().assignValues(filterValues(values));
      if (recurringEvent.id) formikBag.props.recurringEventsActionUpdateElement(recurringEvent);
      else {
        formikBag.props.recurringEventsActionCreateElement(recurringEvent);
      }
      setTimeout(formikBag.props.onBlur);
    },
  })(RecurringEventInnerForm),
);

export default connect(prepareState(['nodes']), {
  recurringEventsActionCreateElement,
  recurringEventsActionUpdateElement,
  activeElementsActionActivate,
  nodesActionFetch,
})(RecurringEventForm);
