import React, { useEffect, useState } from 'react';
import { getUuid } from '../../../../helpers/identifierHelpers';
import TypingMachine from './TypingMachine';

const TypingVariant = (props) => {
  const { paused, finalWpm } = props;

  const [reloadKey, setReloadKey] = useState(getUuid());

  useEffect(() => {
    // if finalWpm is set, given queue run is ended
    if (paused && !finalWpm) {
      setReloadKey(getUuid());
    }
  }, [paused]);

  return <TypingMachine key={reloadKey} {...props} />;
};

export default TypingVariant;
