export const filesActionUploadElement = (identifier, file) => ({
  type: 'FILES_ACTION_UPLOAD_ELEMENT',
  payload: { identifier, file },
});

export const filesActionSetElement = (identifier, url, name) => ({
  type: 'FILES_ACTION_SET_ELEMENT',
  payload: { identifier, url, name },
});

export const filesActionMarkFailedElement = (identifier) => ({
  type: 'FILES_ACTION_MARK_FAILED_ELEMENT',
  payload: { identifier },
});
