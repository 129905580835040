import React from 'react';
import { mixHexes } from './colorHelpers';

export const applyStyle = (
  element,
  additionalStyle,
  // must be an object shaped like { styleKey: { color: mixinColor, multiplier: mixinMultiplier }}
  // e.g. { backgroundColor: { color: '#000000', multiplier: 0.9 } }
  colorsMixinsConfig = false,
) => {
  let result = element;
  if (typeof element === 'string' || !element) result = <span>{result}</span>;

  if (Array.isArray(element)) return element.map((_elem) => applyStyle(_elem, additionalStyle, colorsMixinsConfig));

  const styleToApply = { ...additionalStyle };

  if (colorsMixinsConfig) {
    for (const mixinKey in colorsMixinsConfig) {
      const { color, multiplier } = colorsMixinsConfig[mixinKey];
      styleToApply[mixinKey] = mixHexes(additionalStyle[mixinKey], color, multiplier);
    }
  }

  return React.cloneElement(result, { ...result.props, style: { ...styleToApply, ...(result.props.style || {}) } });
};
