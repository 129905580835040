import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import saga from '../saga';
import initializeReducer from './initializeReducer';

const crossTabStateSyncConfig = {
  whitelist: ['QUICK_PASS_ACTION_ACTIVATE', 'QUICK_PASS_ACTION_AUTO_ACTIVATE', 'QUICK_PASS_ACTION_VALIDATE'],
};

const initializeStore = (browserHistory) => {
  const sagaMiddleware = createSagaMiddleware();
  const result = createStore(
    initializeReducer(browserHistory),
    applyMiddleware(
      sagaMiddleware,
      routerMiddleware(browserHistory),
      createStateSyncMiddleware(crossTabStateSyncConfig),
    ),
  );
  sagaMiddleware.run(saga);
  initMessageListener(result);
  return result;
};

export default initializeStore;
