import React, { useEffect, useState } from 'react';
import StandardContentTemplate from '../../../shared/contentTemplates/StandardContentTemplate';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { categoriesActionFetch } from '../../../stateManagement/actions/categoriesActions';
import { otherSectionsNames } from '../../../constants/otherSectionsNames';
import { initT } from '../../../helpers/i18nHelpers';
import RecurringTasks from './recurringTasks/RecurringTasks';
import Top from './Top';
import Left from './Left';
import { recurringTasksActionFetch } from '../../../stateManagement/actions/recurringTasksActions';
import { dateTimesRange } from '../../../helpers/dateTimeHelpers';
import { DateTime } from 'luxon';
import { backendDateFormat } from '../../../constants/dateFormat';
import { eventsActionFetch } from '../../../stateManagement/actions/eventsActions';
import { recurringEventsActionFetch } from '../../../stateManagement/actions/recurringEventsActions';
import RecurringEvents from './recurringEvents/RecurringEvents';

const t = initT('pages.other');

const OtherIndexPage = (props) => {
  const {
    categories,
    recurringTasks,
    events,
    recurringEvents,
    categoriesActionFetch,
    recurringTasksActionFetch,
    recurringEventsActionFetch,
    eventsActionFetch,
  } = props;

  const { otherSectionName } = useParams();
  const history = useHistory();
  const [eventsRange, _setEventsRange] = useState(dateTimesRange(DateTime.now(), 7));
  const formattedEventsRange = eventsRange.map((dt) => dt.toFormat(backendDateFormat));

  useEffect(() => {
    if (otherSectionName !== otherSectionsNames.recurringTasks) return;
    if (!categories.fetched) categoriesActionFetch();
    if (!recurringTasks.fetched) recurringTasksActionFetch();
  }, [categories.fetched, recurringTasks.fetched, otherSectionName]);
  useEffect(() => {
    if (otherSectionName !== otherSectionsNames.upcomingEvents) return;
    if (!events.fetched(formattedEventsRange)) eventsActionFetch(formattedEventsRange);
  }, [events.fetched(formattedEventsRange), otherSectionName]);
  useEffect(() => {
    if (otherSectionName !== otherSectionsNames.recurringEvents) return;
    if (!recurringEvents.fetched) recurringEventsActionFetch();
  }, [recurringEvents.fetched, otherSectionName]);

  let topTitle = '';
  let mainReady = false;
  let main = false;

  if (otherSectionName === otherSectionsNames.recurringTasks) {
    topTitle = t('recurringTasks.sectionName');
    mainReady = categories.fetched && recurringTasks.fetched;
    main = <RecurringTasks />;
  }
  // else if (otherSectionName === otherSectionsNames.upcomingEvents) {
  //     topTitle = t('upcomingEvents.sectionName');
  //     mainReady = events.fetched(formattedEventsRange);
  //     main = <UpcomingEvents initialRange={eventsRange} setRange={setEventsRange} />;
  // }
  else if (otherSectionName === otherSectionsNames.recurringEvents) {
    topTitle = t('recurringEvents.sectionName');
    mainReady = recurringEvents.fetched;
    main = <RecurringEvents />;
  } else {
    // a bit hacky without setTimeout, but it makes the UX better
    history.replace(`/other/${otherSectionsNames.recurringTasks}`);
  }

  return <StandardContentTemplate top={<Top title={topTitle} />} left={<Left />} mainReady={mainReady} main={main} />;
};

export default connect(prepareState(['categories', 'recurringTasks', 'recurringEvents', 'events']), {
  categoriesActionFetch,
  recurringTasksActionFetch,
  recurringEventsActionFetch,
  eventsActionFetch,
})(OtherIndexPage);
