import { produceModelFromDto, produceModelToDto } from './modelHelpers';
import EventModel from '../../models/eventModel';

const mappings = [
  ['id', 'id'],
  ['nodes_group_id', 'nodesGroupId'],
  ['name', 'name'],
  ['date', 'date'],
  ['color', 'color', 'enum'],
  ['default_order', 'defaultOrder'],
  ['private', 'private'],
];

export const eventFromDto = produceModelFromDto(EventModel, mappings);

export const eventToDto = produceModelToDto(mappings);
