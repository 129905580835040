import React, { useCallback } from 'react';
import FormikFieldSub from '../../../shared/form/FormikFieldSub';
import ColorField from '../../../shared/form/fieldTypes/ColorField';
import { connect } from 'react-redux';
import { eventsActionUpdateElement } from '../../../stateManagement/actions/eventsActions';
import EventModel from '../../../models/eventModel';

const ColorSelector = (props) => {
  const { event, eventsActionUpdateElement: update } = props;

  const onChange = useCallback(
    ({ newValue, additionalProps: { closeMenu } }) => {
      closeMenu();
      const updatedEvent = new EventModel().assignValues({ ...event, color: newValue });
      update(updatedEvent);
    },
    [event, update],
  );

  return <FormikFieldSub onChange={onChange} value={event.color} component={ColorField} />;
};

export default connect(null, {
  eventsActionUpdateElement,
})(ColorSelector);
