import React from 'react';
import { Link, useHistory } from 'react-router-dom';

const Clickable = React.forwardRef((props, ref) => {
  const {
    id,
    children,
    to,
    href,
    onClick,
    onMouseDown,
    className = '',
    style = {},
    hidden = false,
    disabled = false,
    opaqueWhenDisabled = true,
    buttonType = 'button',
    target,
    hoverOpacity = false,
    useTimeout = true,
    customLinkOnClick = false,
    preventContextMenu = false,
    download = false,
  } = props;

  const history = useHistory();

  const _onClick = onClick
    ? (e) => {
        if (useTimeout) setTimeout(() => onClick(e));
        else onClick(e);
      }
    : undefined;
  const _onMouseDown = onMouseDown
    ? (e) => {
        // only on left mouse button click
        if (e.button !== 0) return;

        if (useTimeout) setTimeout(() => onMouseDown(e));
        else onMouseDown(e);
      }
    : undefined;

  const onContextMenu = (e) => {
    if (!preventContextMenu) return;
    e.preventDefault();
  };

  const hiddenClasses = hidden ? 'hidden pointer-events-none' : '';
  const disabledClasses = disabled
    ? `${opaqueWhenDisabled ? 'opacity-20' : ''} cursor-default pointer-events-none`
    : '';
  const hoverClasses = hoverOpacity && !disabled && !hidden ? 'opacity-50-on-devices-supporting-hover' : '';
  const classes = `${className} ${hiddenClasses} ${disabledClasses} ${hoverClasses}`;

  if (to) {
    return (
      <Link
        id={id}
        to={to}
        disabled={disabled}
        style={style}
        className={classes}
        ref={ref}
        onContextMenu={onContextMenu}
        onClick={(e) => {
          if (useTimeout || customLinkOnClick) {
            e.preventDefault();
            const defaultOnClick = () => {
              if (target === '_blank') window.open(to);
              else history.push(to);
            };
            const finalOnClick = customLinkOnClick ? () => customLinkOnClick(e, to, defaultOnClick) : defaultOnClick;
            useTimeout ? setTimeout(finalOnClick) : finalOnClick();
          }
        }}
        target={target}
      >
        {children}
      </Link>
    );
  }

  if (href) {
    return (
      <a
        id={id}
        href={href}
        style={style}
        className={classes}
        target={target}
        ref={ref}
        onContextMenu={onContextMenu}
        download={download}
        onClick={(e) => {
          if (useTimeout || customLinkOnClick) {
            e.preventDefault();
            const defaultOnClick = () => {
              if (target === '_blank') window.open(href);
              else window.location.href = href;
            };
            const finalOnClick = customLinkOnClick ? () => customLinkOnClick(e, to, defaultOnClick) : defaultOnClick;
            useTimeout ? setTimeout(finalOnClick) : finalOnClick();
          }
        }}
      >
        {children}
      </a>
    );
  }

  if (onClick || _onMouseDown) {
    return (
      <button
        id={id}
        type={buttonType}
        onMouseDown={_onMouseDown}
        onClick={_onClick}
        className={`focus:outline-none ${classes}`}
        style={style}
        disabled={disabled}
        ref={ref}
        onContextMenu={onContextMenu}
      >
        {children}
      </button>
    );
  }

  return (
    <span style={style} className={classes} ref={ref}>
      {children}
    </span>
  );
});

export default Clickable;
