import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../../../helpers/stateManagementHelpers';
import ModalHeader from '../../ModalHeader';
import { activeElementTypes } from '../../../../constants/activeElementTypes';
import ModalBody from '../../ModalBody';
import { dayEvaluationsChartRequestVariants } from '../../../../constants/enums/dayEvaluationsChartRequestVariants';
import { dayEvaluationsChartsDataActionFetch } from '../../../../stateManagement/actions/dayEvaluationsChartsDataActions';
import Chart from '../../../charts/chart';
import ModalLoader from '../../../loaders/ModalLoader';
import { enumT, initT } from '../../../../helpers/i18nHelpers';
import toLower from 'lodash/toLower';
import RadioField from '../../../form/fieldTypes/RadioField';
import FormikFieldSub from '../../../form/FormikFieldSub';

const t = initT('modals.dayEvaluationsChartModal');

const getLabelTitle = (variant) => {
  const variantT = toLower(enumT(`dayEvaluationsChartRequestVariants.${variant}`));
  return `${t('labelPrefix')} ${variantT}`;
};

const DayEvaluationsChartModalContent = (props) => {
  const { identifier: date, dayEvaluationsChartsData, dayEvaluationsChartsDataActionFetch } = props;
  const [variant, setVariant] = useState(dayEvaluationsChartRequestVariants.weekly);
  const chartsDataPerVariant = dayEvaluationsChartsData[date];
  const chartData = chartsDataPerVariant ? chartsDataPerVariant[variant] : null;

  useEffect(() => {
    if (!chartData) {
      dayEvaluationsChartsDataActionFetch(date, variant);
    }
  }, [variant]);

  if (!chartData) {
    return (
      <ModalLoader loaderVisible={true}>
        <div className="w-almost-screen h-half-screen h-max-half-screen-width" />
      </ModalLoader>
    );
  }

  const labels = chartData.data.map((element) => element.label);
  const values = chartData.data.map((element) => element.value);

  const minPixelsPerLabel = 50;
  const almostScreenMultiplier = 0.9;
  let labelsMaxNumber = (window.innerWidth * almostScreenMultiplier) / minPixelsPerLabel;
  if (labelsMaxNumber < 5) labelsMaxNumber = 5;

  return (
    <div className="w-almost-screen">
      <ModalHeader type={activeElementTypes.dayEvaluationsChartModal} identifier={date}>
        {t('title')}
      </ModalHeader>
      <ModalBody>
        <div className="flex justify-center pb-25px">
          <div className="px-4">
            <FormikFieldSub
              component={RadioField}
              value={variant}
              radioValue={dayEvaluationsChartRequestVariants.daily}
              label={enumT('dayEvaluationsChartRequestVariants.daily')}
              onChange={() => setVariant(dayEvaluationsChartRequestVariants.daily)}
            />
          </div>
          <div className="px-4">
            <FormikFieldSub
              component={RadioField}
              value={variant}
              radioValue={dayEvaluationsChartRequestVariants.weekly}
              label={enumT('dayEvaluationsChartRequestVariants.weekly')}
              onChange={() => setVariant(dayEvaluationsChartRequestVariants.weekly)}
            />
          </div>
          <div className="px-4">
            <FormikFieldSub
              component={RadioField}
              value={variant}
              radioValue={dayEvaluationsChartRequestVariants.monthly}
              label={enumT('dayEvaluationsChartRequestVariants.monthly')}
              onChange={() => setVariant(dayEvaluationsChartRequestVariants.monthly)}
            />
          </div>
        </div>
        <Chart
          labels={labels}
          values={values}
          labelTitle={getLabelTitle(variant)}
          yMin={1}
          yMax={5}
          stepSize={1}
          heightClass="h-half-screen h-max-half-screen-width"
          labelsMaxNumber={labelsMaxNumber}
        />
      </ModalBody>
    </div>
  );
};

export default connect(prepareState(['dayEvaluationsChartsData']), { dayEvaluationsChartsDataActionFetch })(
  DayEvaluationsChartModalContent,
);
