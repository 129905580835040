import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { notificationT } from '../../../helpers/i18nHelpers';
import { push } from 'connected-react-router';
import { learningWorkspaceFromDto, learningWorkspaceToDto } from '../../../helpers/model/learningWorkspacesHelpers';
import {
  learningWorkspacesActionSetCreatedElement,
  learningWorkspacesActionUnlock,
} from '../../actions/learningWorkspacesActions';
import { elementToFocusOnActionSet } from '../../actions/elementToFocusOnActions';
import { elementToFocusOnTypes } from '../../../constants/elementToFocusOnTypes';

export default function* createElementLearningWorkspacesSaga(action) {
  const { ok, data } = yield call(api.learningWorkspaces.create, learningWorkspaceToDto(action.payload));
  if (ok) {
    const newWorkspace = learningWorkspaceFromDto(data);
    yield put(snackbarMessagesActionAdd(notificationT('learningWorkspaces.create.success')));
    yield put(learningWorkspacesActionSetCreatedElement(newWorkspace));
    yield put(push('/learning/workspaces'));
    yield put(elementToFocusOnActionSet(elementToFocusOnTypes.learningWorkspaceTitle, newWorkspace.id));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data, 'learningWorkspaces.create.errors'));
    yield put(learningWorkspacesActionUnlock());
  }
}
