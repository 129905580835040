import React from 'react';

export const FaceNeutralIcon = ({ className = '', style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className={`fill-current ${className}`}
    style={style}
  >
    <path
      d="M17 15.134l-.562-.742c-3.568 2.728-4.703-3.279-9.438 1.574l.641.771c3.664-3.036
                 4.726 3.144 9.359-1.603zm-5-13.134c5.514 0 10 4.486 10 10s-4.486 10-10
                 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12
                 12-5.373 12-12-5.373-12-12-12zm-3.5 8c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5
                 1.5-.671 1.5-1.5-.672-1.5-1.5-1.5zm7 0c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5
                 1.5-.671 1.5-1.5-.672-1.5-1.5-1.5z"
    />
  </svg>
);
export default FaceNeutralIcon;
