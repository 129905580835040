import React from 'react';
import frenchkiss from 'frenchkiss';
import ThemeCrumb from '../ThemeCrumb';

const NoData = (props) => {
  const { collection = [], className = '', placeholder = frenchkiss.t('noDataPlaceholder') } = props;

  if (collection.length === 0) {
    return (
      <ThemeCrumb type="bigMessage">
        <div className={`h-full flex items-center justify-center ${className}`} style={{ minHeight: '40px' }}>
          {placeholder}
        </div>
      </ThemeCrumb>
    );
  }
  return <></>;
};

export default NoData;
