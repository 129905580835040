import React from 'react';
import NavbarLink from './NavbarLink';

const NavbarBigScreenContent = (props) => {
  const { leftLinksConfig, mainLinksConfig, rightLinksConfig } = props;
  return (
    <>
      <div className="flex ml-2">
        {leftLinksConfig.map(({ key, ...otherProps }) => (
          <NavbarLink key={key} marginClass="" widthClass="w-12" {...otherProps} />
        ))}
      </div>
      <div className="w-full flex flex-grow justify-center items-center">
        {mainLinksConfig.map(({ key, ...otherProps }) => (
          <NavbarLink key={key} {...otherProps} />
        ))}
      </div>
      <div className="flex mr-2">
        {rightLinksConfig.map(({ key, ...otherProps }) => (
          <NavbarLink key={key} marginClass="" widthClass="w-12" {...otherProps} />
        ))}
      </div>
    </>
  );
};

export default NavbarBigScreenContent;
