import Collection from '../../models/auxiliary/collection';

const initialState = () => new Collection().toFetched(false).toUnlocked(false);

export default function (state = initialState(), action) {
  switch (action.type) {
    case 'DAY_EVALUATIONS_ACTION_FETCH_ELEMENT':
      return state;
    case 'DAY_EVALUATIONS_ACTION_SET_ELEMENT':
      return state.withReplacedElements(action.payload.toUnlocked(), { idKey: 'date' });
    case 'DAY_EVALUATIONS_ACTION_UPDATE_ELEMENT':
    case 'DAY_EVALUATIONS_ACTION_DELETE_ELEMENT':
      return state.withActualizedElements(action.payload.toLocked(), ['locked'], { idKey: 'date' });
    case 'DAY_EVALUATIONS_ACTION_UNLOCK_ELEMENT':
      return state.withActualizedElements(action.payload.toUnlocked(), ['locked'], { idKey: 'date' });
    case 'GLOBAL_ACTION_RESET':
      return initialState();
    default:
      return state;
  }
}
