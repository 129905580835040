import React, { useEffect, useMemo } from 'react';
import ReactModal from 'react-modal';
import themes from '../../constants/themes';
import isHotkey from 'is-hotkey';
import { generateTagId } from '../../helpers/identifierHelpers';

const customStyles = (theme) => ({
  overlay: {
    ...themes[theme].modalOverlay,
    zIndex: 30,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    borderRadius: '1rem',
    maxWidth: '90vw',
    maxHeight: '90vh',
    overflow: 'hidden',
    ...themes[theme].modal,
  },
});

const Modal = ({ isOpen, children, theme, onRequestClose }) => {
  // unfortunately ReactModal support for onRequestClose is not 100% reliable
  useEffect(() => {
    const listener = (event) => {
      if (isHotkey('esc', event)) onRequestClose();
    };
    window.addEventListener('keydown', listener, true);
    return () => window.removeEventListener('keydown', listener, true);
  }, []);

  const id = useMemo(generateTagId, []);
  const exceptionClasses = ['link-popover', 'checklist-bullet', 'selectable-menu-wrapper'];
  const onOverlayClick = (event) => {
    const content = document.getElementById(id);
    if (content && !content.contains(event.target)) {
      let shouldProceed = true;
      exceptionClasses.forEach((exceptionClass) => {
        for (let exceptionElement of document.getElementsByClassName(exceptionClass))
          if (exceptionElement.contains(event.target)) shouldProceed = false;
      });
      if (shouldProceed) onRequestClose();
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      appElement={document.body}
      style={theme ? customStyles(theme) : {}}
      overlayElement={(props, contentElement) => (
        <div {...props} onMouseDown={onOverlayClick}>
          {contentElement}
        </div>
      )}
    >
      <div id={id}>{React.cloneElement(children)}</div>
    </ReactModal>
  );
};

export default Modal;
