import React from 'react';

const MainTopSpace = (props) => {
  const { children, customContent = false } = props;

  if (customContent)
    return (
      <div className="w-full" style={{ minHeight: 76, height: 76, maxHeight: 76 }}>
        {children}
      </div>
    );

  return (
    <div className="flex justify-center pt-2 pb-40px">
      <div className="w-full flex justify-end" style={{ height: '28px' }}>
        {children}
      </div>
    </div>
  );
};

export default MainTopSpace;
