import Lockable from './parents/lockable';

export default class Waste extends Lockable {
  constructor() {
    super();
    this.id = null;
    this.name = null;
    this.archivedAt = null;
    this.modelName = null;
    this.canBeRestored = false;
  }

  visible(incognitoMode = false) {
    return !incognitoMode;
  }
}
