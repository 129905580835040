import BaseModel from './baseModel';

export default class Fetchable extends BaseModel {
  constructor() {
    super();
    this.fetched = false;
  }

  toFetched(createClone = true) {
    let result = this;
    if (createClone) result = this.shallowClone();
    result.fetched = true;
    return result;
  }

  toNotFetched(createClone = true) {
    let result = this;
    if (createClone) result = this.shallowClone();
    result.fetched = false;
    return result;
  }
}
