import React, { useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { getTopLevelConsts } from './getTopLevelConsts';
import NodePanel from './nodePanel';
import every from 'lodash/every';
import { useCheckIfMounted } from '../../../hooks/useCheckIfMounted';
import H1 from '../../titles/H1';
import { nodesActionFetch } from '../../../stateManagement/actions/nodesActions';
import { adjustContentsToPdfDisplay, adjustPdfPagesHeights, pdfMaxPageHeight } from '../../../helpers/pdfHelpers';
import { nodeVariants } from '../../../constants/nodeVariants';
import { generateTagId } from '../../../helpers/identifierHelpers';

const getGroupedNodes = (nodes) => {
  const result = [];

  let currentGroup = { tagId: generateTagId(), nodes: [] };
  nodes.forEach((node) => {
    if (node.variant === nodeVariants.attachments) return;

    currentGroup.nodes.push(node);
    if (node.variant === nodeVariants.heading) return;

    result.push(currentGroup);
    currentGroup = { tagId: generateTagId(), nodes: [] };
  });

  if (currentGroup.length > 0) result.push(currentGroup);

  return result;
};

const NodesGroupPdfDisplay = (props) => {
  const {
    title = '',
    nodesGroupId,
    nodes,
    incognitoMode,
    onReady,
    onError,
    nodesActionFetch,
    // (210 (default width in px) - 2 * 10 (margin)) / 0.25 (scale)
    width = 760,
  } = props;

  useEffect(() => {
    if (!nodes.fetched(nodesGroupId)) nodesActionFetch(nodesGroupId);
  }, [nodesGroupId]);

  const { visibleNodes, determineMarginClass } = getTopLevelConsts({ nodesGroupId, nodes, incognitoMode });

  const ref = useRef(null);
  const checkIfMounted = useCheckIfMounted();

  const groupedNodes = useMemo(() => getGroupedNodes(visibleNodes), [nodesGroupId, nodes.fetched(nodesGroupId)]);
  useEffect(() => {
    if (!nodes.fetched(nodesGroupId)) return;

    const main = () => {
      if (!checkIfMounted()) return;

      const initialImages = Array.from(ref.current.getElementsByTagName('img'));
      initialImages.forEach((img) => {
        img.onerror = onError;
      });

      const check = () => {
        if (!checkIfMounted()) return;

        const images = Array.from(ref.current.getElementsByTagName('img'));
        if (every(images.map((img) => img.complete))) {
          adjustContentsToPdfDisplay(ref.current);
          setTimeout(() => {
            if (!checkIfMounted()) return;

            adjustPdfPagesHeights(ref.current, pdfMaxPageHeight, [0]);
            onReady(ref.current);
          });
        } else {
          setTimeout(check, 10);
        }
      };

      setTimeout(check);
    };
    setTimeout(main);
  }, [nodesGroupId, nodes.fetched(nodesGroupId)]);

  return (
    <div ref={ref} style={{ width: `${width}px`, fontFamily: 'PTSans', wordSpacing: '2px' }}>
      <div className="w-full pb-40px">
        <H1>{title}</H1>
      </div>
      {groupedNodes.map(({ tagId, nodes }, groupIndex) => {
        const lastGroup = groupedNodes.length - 1 === groupIndex;
        return (
          <div key={tagId} className="w-full">
            {nodes.map((node, index) => {
              const lastNode = nodes.length - 1 === index;
              const marginClass = lastGroup && lastNode ? 'pb-0' : determineMarginClass(node, false);
              const className = `w-full ${marginClass}`;
              return (
                <div key={node.id} className={className}>
                  <NodePanel node={node} interactive={false} />
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default connect(prepareState(['nodes', 'incognitoMode']), { nodesActionFetch })(NodesGroupPdfDisplay);
