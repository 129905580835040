import React from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../../../helpers/stateManagementHelpers';
import {
  recurringEventsActionDeleteElement,
  recurringEventsActionMarkPrivateElement,
  recurringEventsActionUnmarkPrivateElement,
} from '../../../../stateManagement/actions/recurringEventsActions';
import Clickable from '../../../../shared/Clickable';
import CustomColorCrumb from '../../../../shared/CustomColorCrumb';
import ArrowDownIcon from '../../../../icons/arrowDownIcon';
import ArrowRightIcon from '../../../../icons/arrowRightIcon';
import ButtonsGroup from '../../../../shared/buttons/ButtonsGroup';
import IconButton from '../../../../shared/buttons/IconButton';
import ThemeCrumb from '../../../../shared/ThemeCrumb';
import { customColors } from '../../../../constants/customColors';
import ColorSelector from './ColorSelector';
import ConfirmationButton from '../../../../shared/buttons/ConfirmationButton';

const RecurringEventHeader = (props) => {
  const {
    recurringEvent,
    isContainerActive,
    toggleContainerActivity,
    activateEditionMode,
    incognitoMode,
    recurringEventsActionMarkPrivateElement: markPrivate,
    recurringEventsActionUnmarkPrivateElement: unmarkPrivate,
    recurringEventsActionDeleteElement: deleteElement,
  } = props;

  const switchPrivate = recurringEvent.private
    ? () => unmarkPrivate(recurringEvent)
    : () => markPrivate(recurringEvent);
  const switchPrivateVariant = recurringEvent.private ? 'unmarkPrivate' : 'markPrivate';

  let result = (
    <div className={`flex items-center ${isContainerActive ? 'border-b rounded-t-xl' : ''}`}>
      <Clickable
        className="flex w-full items-center truncate"
        onMouseDown={() => {
          toggleContainerActivity();
        }}
      >
        <CustomColorCrumb color={recurringEvent.color} styleKeys={['color']}>
          <div className="p-5">
            {isContainerActive ? <ArrowDownIcon className="w-3 h-3" /> : <ArrowRightIcon className="w-3 h-3" />}
          </div>
        </CustomColorCrumb>
        <div>{recurringEvent.name}</div>
      </Clickable>
      <div className="p-2">
        <ButtonsGroup
          className="w-full flex justify-end pl-1"
          buttons={[
            <ColorSelector key="color" recurringEvent={recurringEvent} />,
            incognitoMode ? null : (
              <IconButton key="switchPrivate" onMouseDown={switchPrivate} variant={switchPrivateVariant} />
            ),
            <IconButton key="edit" variant="edit" onMouseDown={activateEditionMode} />,
            <ConfirmationButton
              key="delete"
              variant="delete"
              i18nKey="recurringEvents.delete"
              onConfirmed={() => deleteElement(recurringEvent)}
              resourceName={recurringEvent.name}
            />,
          ]}
        />
      </div>
    </div>
  );

  if (isContainerActive)
    result = (
      <ThemeCrumb
        type="container"
        mixinColor={customColors[recurringEvent.color].primary}
        mixedStyleKeysMultipliers={{ borderColor: 0.2, backgroundColor: 0.1 }}
      >
        {result}
      </ThemeCrumb>
    );

  return result;
};

export default connect(prepareState(['incognitoMode']), {
  recurringEventsActionMarkPrivateElement,
  recurringEventsActionUnmarkPrivateElement,
  recurringEventsActionDeleteElement,
})(RecurringEventHeader);
