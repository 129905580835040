import jsPDF from 'jspdf';
import { PTSans } from '../constants/fonts/ptSans';
import { PTSansItalic } from '../constants/fonts/ptSansItalic';
import { PTSansBold } from '../constants/fonts/ptSansBold';
import { PTSansBoldItalic } from '../constants/fonts/ptSansBoldItalic';
import { SourceCodePro } from '../constants/fonts/sourceCodePro';
import { pdfColors } from '../constants/pdfColors';

export const pdfMaxPageHeight = 1108;

export const getPdfGenerator = () => {
  const newPdf = new jsPDF({ filters: ['ASCIIHexEncode'] });

  newPdf.addFileToVFS('PTSans.ttf', PTSans);
  newPdf.addFileToVFS('PTSansBold.ttf', PTSansBold);
  newPdf.addFileToVFS('PTSansItalic.ttf', PTSansItalic);
  newPdf.addFileToVFS('PTSansBoldItalic.ttf', PTSansBoldItalic);
  newPdf.addFileToVFS('SourceCodePro.ttf', SourceCodePro);
  newPdf.addFont('PTSans.ttf', 'PTSans', 'normal');
  newPdf.addFont('PTSansBold.ttf', 'PTSans', 'bold');
  newPdf.addFont('PTSansItalic.ttf', 'PTSans', 'italic');
  newPdf.addFont('PTSansBoldItalic.ttf', 'PTSans', 'bolditalic');
  newPdf.addFont('SourceCodePro.ttf', 'SourceCodePro', 'normal');

  return newPdf;
};

export const pdfDefaultOptions = {
  html2canvas: {
    // only known way to manipulate number of pixels
    // for scale: 1, width of the page is 210px
    scale: 0.25,
  },
};

export const adjustContentsToPdfDisplay = (element) => {
  Array.from(element.getElementsByTagName('*')).forEach((element) => {
    element.style.color = pdfColors.color;
    element.style.borderColor = pdfColors.borderColor;
  });

  Array.from(element.getElementsByTagName('svg')).forEach((svg) => {
    svg.remove();
  });

  Array.from(element.getElementsByClassName('readonly-link')).forEach((linkRoot) => {
    linkRoot.style.color = pdfColors.linkColor;
    Array.from(linkRoot.getElementsByTagName('*')).forEach((element) => {
      element.style.color = pdfColors.linkColor;
    });
  });

  Array.from(element.getElementsByClassName('line-through')).forEach((root) => {
    root.style.color = pdfColors.secondaryColor;
    Array.from(root.getElementsByTagName('*')).forEach((element) => {
      element.style.color = pdfColors.secondaryColor;
    });
  });

  Array.from(element.getElementsByTagName('s')).forEach((root) => {
    root.style.color = pdfColors.secondaryColor;
    Array.from(root.getElementsByTagName('*')).forEach((element) => {
      element.style.color = pdfColors.secondaryColor;
    });
  });

  Array.from(element.getElementsByTagName('u')).forEach((root) => {
    root.style.textDecoration = 'underline';
    Array.from(root.getElementsByTagName('*')).forEach((element) => {
      element.style.textDecoration = 'underline';
    });
  });

  Array.from(element.getElementsByClassName('checklist-bullet-symbol')).forEach((element) => {
    element.style.fontFamily = 'SourceCodePro';
  });

  Array.from(element.getElementsByClassName('bullet-symbol')).forEach((element) => {
    element.style.fontFamily = 'SourceCodePro';
  });

  Array.from(element.getElementsByClassName('node-heading')).forEach((heading) => {
    Array.from(heading.getElementsByTagName('h3')).forEach((element) => {
      element.style.paddingBottom = '10px';
    });
  });

  Array.from(element.getElementsByTagName('code')).forEach((element) => {
    element.style.backgroundColor = pdfColors.codeBackgroundColor;
    element.style.paddingBottom = '10px';
  });

  Array.from(element.getElementsByClassName('node-image-name')).forEach((element) => {
    element.style.height = '50px';
  });
  Array.from(element.getElementsByClassName('node-image-description')).forEach((element) => {
    element.style.height = '24px';
  });
  Array.from(element.getElementsByClassName('border')).forEach((element) => {
    const initial = parseInt(window.getComputedStyle(element, null).getPropertyValue('padding-left'));
    // workaround for a strange jspdf issue with dislocated border
    element.style.paddingBottom = `${initial + 10}px`;
  });
};

export const adjustPdfPagesHeights = (element, maxPageHeight, avoidNextPageAfterIndices = []) => {
  const parts = Array.from(element.children);
  let currentPageSize = 0;

  const toNextPage = (part) => {
    const div = document.createElement('div');
    // 1 because pageHeight is slightly more than 1108 and less than 1109
    div.style.height = `${maxPageHeight + 1 - currentPageSize}px`;
    part.parentNode.insertBefore(div, part);
  };

  parts.forEach((part, index) => {
    const height = part.clientHeight;

    if (currentPageSize + height > maxPageHeight) {
      if (currentPageSize > 0 && !avoidNextPageAfterIndices.includes(index - 1)) {
        toNextPage(part);
        currentPageSize = 1;
      }
      currentPageSize = (currentPageSize + height) % maxPageHeight;
    } else {
      currentPageSize += height;
    }
  });
};

export const prepareImagesToPdfGeneration = (element) => {
  Array.from(element.getElementsByTagName('img')).forEach((img) => {
    img.src = img.src + '?r=' + Math.floor(Math.random() * 100000);
    img.crossOrigin = 'anonymous';
  });
};
