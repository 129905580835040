import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { snackbarMessagesActionHandleBackendErrors } from '../../actions/snackbarMessagesActions';
import { configActionSet } from '../../actions/configActions';

export default function* updateConfigSaga(action) {
  const { apiKey, payload, successCallback, failureCallback, preventErrorMsg } = action.payload;
  const { ok, data } = yield call(api.users.configuration[apiKey], payload);
  if (ok) {
    yield put(configActionSet(data));
    successCallback(data);
  } else {
    if (!preventErrorMsg) yield put(snackbarMessagesActionHandleBackendErrors(data));
    failureCallback(data);
  }
}
