import React from 'react';
import Clickable from '../../Clickable';
import ArrowDownIcon from '../../../icons/arrowDownIcon';
import { fixedMenuSides } from '../../../constants/fixedMenu';
import ThemeCrumb from '../../ThemeCrumb';

const widthInPx = 62;
const heightInPx = 20;

const FixedMenuToggle = (props) => {
  const { expanded, side, toggle = () => {}, disabled } = props;

  const containerClass = side === fixedMenuSides.left ? 'right-0 rotate-90' : 'left-0 -rotate-90';

  const containerStyle =
    side === fixedMenuSides.left
      ? { right: `-${Math.round(widthInPx / 2 + heightInPx / 2)}px` }
      : { left: `-${Math.round(widthInPx / 2 + heightInPx / 2)}px` };

  const iconRotationClass = expanded ? 'rotate-0' : 'rotate-180';
  const auxiliaryClass = expanded ? 'hide-fixed-menu-toggle' : 'show-fixed-menu-toggle';

  return (
    <div
      className={`fixed-menu-toggle ${auxiliaryClass} absolute flex items-end justify-center
                        transform rounded-t-xl ${containerClass}`}
      style={{
        top: '38.2%',
        width: `${widthInPx}px`,
        height: `${heightInPx}px`,
        ...containerStyle,
      }}
    >
      <Clickable onMouseDown={toggle} disabled={disabled}>
        <ThemeCrumb type="fixedMenuToggle">
          <div className="py-1 px-25px rounded-t-xl border">
            <div
              className={`transform transition-transform duration-500
                                 ${iconRotationClass}`}
            >
              <ArrowDownIcon className="w-3 h-3" />
            </div>
          </div>
        </ThemeCrumb>
      </Clickable>
    </div>
  );
};

export default FixedMenuToggle;
