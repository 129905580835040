import React from 'react';
import CheckIcon from '../../icons/checkIcon';
import CustomColorCrumb from '../../shared/CustomColorCrumb';

const CheckedTaskPresenter = (props) => {
  const { task, incognitoMode, categories } = props;

  const { color } = [...categories.visible(incognitoMode, { id: task.categoryId }), {}][0];

  return (
    <div className="flex items-start overflow-hidden min-w-16">
      <div className="pt-1 pr-2">
        {color ? (
          <CustomColorCrumb styleKeys={['color']} color={color}>
            <CheckIcon className="w-3 h-3" />
          </CustomColorCrumb>
        ) : (
          <CheckIcon className="w-3 h-3" />
        )}
      </div>
      <div
        className="line-through pb-1 w-full justify-start flex text-left text-clip
                            overflow-hidden"
      >
        {task.description}
      </div>
    </div>
  );
};

export default CheckedTaskPresenter;
