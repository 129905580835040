import Lockable from './parents/lockable';

export default class HabitSummary extends Lockable {
  constructor() {
    super();
    this.summaryRequestId = null;
    this.summaryValue = null;
  }

  visible() {
    return true;
  }
}
