import frenchkiss from 'frenchkiss';

frenchkiss.extend('en', {
  modals: {
    dayEvaluationsChartModal: {
      title: 'Day evaluations chart',
      labelPrefix: 'Day evaluations',
    },
  },
});
