import { call, put, select } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { habitChartDataFromDto } from '../../../helpers/model/habitChartDataHelpers';
import { habitChartsDataActionSet } from '../../actions/habitChartsDataActions';

export default function* fetchHabitChartsDataSaga(action) {
  const { date, habitId } = action.payload;
  const { ok, data } = yield call(api.habitChartsData.index, { date, habit_id: habitId });
  const habit = yield select((state) => state.habits.find({ id: habitId }));
  if (ok) {
    const chartsData = Object.keys(data).map((habitChartRequestId) =>
      habitChartDataFromDto({ habitChartRequestId, data: data[habitChartRequestId] }, habitChartRequestId, habit, date),
    );
    yield put(habitChartsDataActionSet(date, habitId, chartsData));
  }
}
