import frenchkiss from 'frenchkiss';

frenchkiss.extend('en', {
  modals: {
    audioSelectionModal: {
      title: 'Manage assigned audio',
      selection: 'Selection',
      recordingFileName: 'Recording',
      startRecording: 'Start recording',
      chooseFile: 'Choose file',
      currentAudioName: 'Currently assigned audio',
      noAudio: 'No audio assigned',
      recording: 'Recording...',
      clickToStop: '(click anywhere to stop)',
      notSelectedLossWarning: 'Not selected elements are going to be lost.',
    },
  },
});
