const isHex = (hex) => /^#([0-9a-fA-F]){6}$/.test(hex);

const validateHex = (hex) => {
  if (!isHex(hex)) throw `${hex} is not a valid color hex`;
};

const hexToRgb = (hex) =>
  hex
    .replace('#', '')
    .match(/.{2}/g)
    .map((n) => parseInt(n, 16));

const rgbToHex = (dec) =>
  '#' +
  dec
    .map((c) => c.toString(16).padStart(2, '0'))
    .join('')
    .toUpperCase();

const roundRgbValue = (rgbValue) => Math.max(0, Math.min(255, Math.round(rgbValue)));

export const mixHexes = (hex1, hex2, mixinMultiplier = 0.5) => {
  validateHex(hex1);
  validateHex(hex2);
  const rgb2 = hexToRgb(hex2);
  const rgbResult = hexToRgb(hex1).map((rgbValue1, index) =>
    roundRgbValue(rgbValue1 * (1.0 - mixinMultiplier) + rgb2[index] * mixinMultiplier),
  );
  return rgbToHex(rgbResult);
};
