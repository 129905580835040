import React from 'react';
import H1 from '../../../shared/titles/H1';

const Top = (props) => {
  const { title } = props;
  return (
    <div className="w-full h-40px flex items-center justify-center">
      <H1>{title}</H1>
    </div>
  );
};

export default Top;
