export const prepareState = (keys) => (state) => {
  const result = {};
  if (typeof keys === 'string') {
    result[keys] = state[keys];
    return result;
  } else if (Array.isArray(keys)) {
    keys.forEach((key) => {
      result[key] = state[key];
    });
    return result;
  }
  return null;
};
