export const elementToFocusOnTypes = {
  taskCreationForm: 'TASK_CREATION_FORM',
  eventCreationForm: 'EVENT_CREATION_FORM',
  node: 'NODE',
  categoryTitle: 'CATEGORY_TITLE',
  habitsSectionTitle: 'HABITS_SECTION_TITLE',
  notesWorkspaceTitle: 'NOTES_WORKSPACE_TITLE',
  noteTitle: 'NOTE_TITLE',
  learningWorkspaceTitle: 'LEARNING_WORKSPACE_TITLE',
  languageWorkspaceTitle: 'LANGUAGE_WORKSPACE_TITLE',
};
