export const onClickOutsideCallbacksActionRegisterElement = (
  htmlElementId,
  callback,
  {
    disableWhenModalOpen = true,
    disableWhenPopupOpen = true,
    exceptionClasses = [],
    exceptionIds = [],
    exceptionModals = [],
  },
) => ({
  type: 'ON_CLICK_OUTSIDE_CALLBACKS_ACTION_REGISTER_ELEMENT',
  payload: {
    htmlElementId,
    callback,
    disableWhenModalOpen,
    disableWhenPopupOpen,
    exceptionClasses,
    exceptionIds,
    exceptionModals,
  },
});

export const onClickOutsideCallbacksActionUnregisterElement = (htmlElementId) => ({
  type: 'ON_CLICK_OUTSIDE_CALLBACKS_ACTION_UNREGISTER_ELEMENT',
  payload: { htmlElementId },
});
