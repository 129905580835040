import { useLayoutEffect } from 'react';
import debounce from 'lodash/debounce';
import { useCheckIfMounted } from './useCheckIfMounted';

export default function useResizeEffect(effect, deps = [], initialInvocation = false) {
  const checkIfMounted = useCheckIfMounted();

  useLayoutEffect(() => {
    if (!checkIfMounted()) return;

    const safeEffect = (initialRun = false) => {
      if (!checkIfMounted()) return;
      effect({ initialRun });
    };

    if (initialInvocation) {
      safeEffect(true);
    }
    const debouncedEffect = debounce(safeEffect, 100);
    window.addEventListener('resize', debouncedEffect);
    return () => {
      window.removeEventListener('resize', debouncedEffect);
    };
  }, deps);
}
