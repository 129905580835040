export const incognitoModeActionEnable = () => ({
  type: 'INCOGNITO_MODE_ACTION_ENABLE',
});

export const incognitoModeActionDisable = () => ({
  type: 'INCOGNITO_MODE_ACTION_DISABLE',
});

export const incognitoModeActionSet = (incognitoMode) => ({
  type: 'INCOGNITO_MODE_ACTION_SET',
  payload: incognitoMode,
});
