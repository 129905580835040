import Lockable from './parents/lockable';
import { learningQueueVariants } from '../constants/enums/learningQueueVariants';
import { learningQueueFeedOrderVariants } from '../constants/enums/learningQueueFeedOrderVariants';

export default class LearningQueue extends Lockable {
  constructor() {
    super();
    this.id = null;
    this.name = null;
    this.variant = learningQueueVariants.overview;
    this.feedOrderVariant = learningQueueFeedOrderVariants.biasedRandom;
    this.considerNestedNotes = false;
    this.withAudio = true;
    this.withRepetitionDelay = false;
    this.learningWorkspaceId = null;
    this.sourceNotesWorkspaceId = null;
    this.sourceNoteId = null;
    this.sourceLabelId = null;
    this.lastLearnedNodeId = null;
    this.defaultOrder = 0;
    this.private = false;
  }

  visible(incognitoMode = false) {
    return incognitoMode ? !this.private : true;
  }
}
