import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

export const useWorkspace = (props) => {
  const {
    learningQueues,
    learningWorkspaces,
    incognitoMode,
    config,
    learningWorkspacesActionFetch,
    learningQueuesActionFetch,
  } = props;

  const history = useHistory();

  useEffect(() => {
    if (!learningWorkspaces.fetched) learningWorkspacesActionFetch();
  }, []);

  let workspace = {};
  const workspaceCandidates = learningWorkspaces.visible(incognitoMode, { id: config.selectedLearningWorkspaceId });
  if (learningWorkspaces.fetched) {
    if (workspaceCandidates.length === 1) workspace = workspaceCandidates[0];
    // a bit hacky without setTimeout, but it makes the UX better
    else history.replace('/learning/workspaces');
  }
  const workspaceSelected = workspace.id !== undefined;

  useEffect(() => {
    if (workspaceSelected && !learningQueues.fetched(workspace.id)) learningQueuesActionFetch(workspace.id);
  }, [workspace, workspace && learningQueues.fetched(workspace.id)]);

  return [workspaceSelected, workspace];
};
