import React from 'react';
import TaskPanel from '../../../features/taskPanel';
import { activeElementTypes } from '../../../../constants/activeElementTypes';
import CheckIcon from '../../../../icons/checkIcon';
import List from '../../../lists/List';
import SubSectionTitle from '../../../titles/SubSectionTitle';
import { initT } from '../../../../helpers/i18nHelpers';

const t = initT('modals.calendarDayModal');

const CheckedTasks = (props) => {
  const { tasks } = props;

  return (
    <>
      <SubSectionTitle>{t('done')}</SubSectionTitle>
      <List collection={tasks}>
        {({ color, ...task }) => (
          <div key={task.id} className="mt-2 first:mt-0">
            <TaskPanel
              task={task}
              color={color}
              disabled
              activeElementType={activeElementTypes.doneTask}
              descriptionAdditionalClass="line-through"
              bulletIcon={<CheckIcon className="w-3 h-3" />}
            />
          </div>
        )}
      </List>
    </>
  );
};

export default CheckedTasks;
