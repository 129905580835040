import React from 'react';
import MenuTitle from '../../shared/titles/MenuTitle';
import { initT } from '../../helpers/i18nHelpers';
import MenuContent from '../../shared/organizers/MenuContent';
import ChunkStats from './queueRun/ChunkStats';

const t = initT('pages.learning.queueRun.chunkStats');

const Right = (props) => {
  return (
    <MenuContent
      top={<MenuTitle marginClass="mx-25px py-25px">{t('title')}</MenuTitle>}
      main={
        <div className="w-full px-25px py-25px">
          <ChunkStats {...props} />
        </div>
      }
    />
  );
};

export default Right;
