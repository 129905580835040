import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { dayEvaluationFromDto, dayEvaluationToDto } from '../../../helpers/model/dayEvaluationHelpers';
import { snackbarMessagesActionHandleBackendErrors } from '../../actions/snackbarMessagesActions';
import { dayEvaluationsActionSetElement, dayEvaluationsActionUnlockElement } from '../../actions/dayEvaluationsActions';
import { dayEvaluationsChartsDataActionReset } from '../../actions/dayEvaluationsChartsDataActions';

export default function* updateElementDayEvaluationsSaga(action) {
  const initialDayEvaluation = action.payload;
  const { ok, data } = yield call(api.dayEvaluations.update, dayEvaluationToDto(initialDayEvaluation));
  if (ok) {
    yield put(dayEvaluationsChartsDataActionReset());
    yield put(dayEvaluationsActionSetElement(dayEvaluationFromDto(data)));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(dayEvaluationsActionUnlockElement(initialDayEvaluation));
  }
}
