import React, { useCallback } from 'react';
import FormikFieldSub from '../../../shared/form/FormikFieldSub';
import ColorField from '../../../shared/form/fieldTypes/ColorField';
import { connect } from 'react-redux';
import { categoriesActionUpdateElement } from '../../../stateManagement/actions/categoriesActions';
import Category from '../../../models/category';

const ColorSelector = (props) => {
  const { category, categoriesActionUpdateElement: update } = props;

  const onChange = useCallback(
    ({ newValue, additionalProps: { closeMenu } }) => {
      closeMenu();
      const updatedCategory = new Category().assignValues({ ...category, color: newValue });
      update(updatedCategory);
    },
    [category, update],
  );

  return <FormikFieldSub onChange={onChange} value={category.color} component={ColorField} />;
};

export default connect(null, {
  categoriesActionUpdateElement,
})(ColorSelector);
