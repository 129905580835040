import React from 'react';
import ThemeCrumb from '../../ThemeCrumb';
import ErrorWrapper from './auxiliary/ErrorWrapper';
import { getThemeType } from '../../../helpers/formHelpers';
import IconButton from '../../buttons/IconButton';
import get from 'lodash/get';

const defaultOnChange = async (props) => {
  const {
    addend,
    onBlur = () => {},
    field: { value, name },
    form: { setFieldValue, setFieldTouched },
  } = props;
  let numberValue = value;
  if (typeof value === 'string') numberValue = parseInt(value, 10);
  await setFieldValue(name, numberValue + addend);
  await setFieldTouched(name);
  await onBlur();
};

const CounterField = (props) => {
  const {
    id,
    innerRef,
    themeType = 'input',
    displayError,
    fixedErrorHeight,
    handleErrorThemeType,
    handleDisabledThemeType,
    handlePresentationModeThemeType,
    min = 0,
    max = 9999,
    disabled = false,
    presentationMode = false,
    onChange = (onChangeProps, _defaultOnChange) => _defaultOnChange(onChangeProps),
    // eslint-disable-next-line no-unused-vars
    onBlur = () => {},
    field: { name, value },
    form: { errors, touched },
  } = props;

  const error = get(touched, name) ? get(errors, name) : null;
  const actualThemeType = getThemeType({
    themeType,
    error,
    handleErrorThemeType,
    presentationMode,
    handlePresentationModeThemeType,
    disabled,
    handleDisabledThemeType,
  });
  const changesDisabled = disabled || presentationMode;

  const prepareOnChange = (addend) => () => onChange({ ...props, addend }, defaultOnChange);

  return (
    <>
      <input hidden readOnly id={id} ref={innerRef} type="number" value={value} />
      <ErrorWrapper displayError={displayError} fixedErrorHeight={fixedErrorHeight} error={error}>
        <ThemeCrumb type={actualThemeType}>
          <div className="w-full flex items-center border rounded-md">
            <ThemeCrumb type={actualThemeType}>
              <div
                className={`border-r rounded-l-md
                                             ${presentationMode ? 'invisible' : ''}`}
              >
                <IconButton
                  variant="minus"
                  disabled={changesDisabled || value <= min}
                  onMouseDown={prepareOnChange(-1)}
                  tooltip={false}
                  iconSizeClass="w-4 h-4"
                />
              </div>
            </ThemeCrumb>
            <div className="w-full flex justify-center items-center p-1">{value}</div>
            <ThemeCrumb type={actualThemeType}>
              <div
                className={`border-l rounded-r-md
                                             ${presentationMode ? 'invisible' : ''}`}
              >
                <IconButton
                  variant="plus"
                  disabled={changesDisabled || value >= max}
                  onMouseDown={prepareOnChange(1)}
                  tooltip={false}
                  iconSizeClass="w-4 h-4"
                />
              </div>
            </ThemeCrumb>
          </div>
        </ThemeCrumb>
      </ErrorWrapper>
    </>
  );
};

export default CounterField;
