import React from 'react';
import { connect } from 'react-redux';
import ThemeCrumb from '../ThemeCrumb';
import { snackbarMessagesActionRemoveActive } from '../../stateManagement/actions/snackbarMessagesActions';
import IconButton from '../buttons/IconButton';

const SnackbarMessage = ({ uuid, children, type, snackbarMessagesActionRemoveActive }) => (
  <ThemeCrumb type={type}>
    <div className="flex w-full">
      <div className="pl-8 py-1 flex-grow">{children}</div>
      <IconButton
        variant="close"
        onMouseDown={() => snackbarMessagesActionRemoveActive(uuid)}
        tooltip={false}
        wrapperClass="px-1"
      />
    </div>
  </ThemeCrumb>
);

export default connect(null, { snackbarMessagesActionRemoveActive })(SnackbarMessage);
