import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCheckIfMounted } from '../../../hooks/useCheckIfMounted';
import { api } from '../../../helpers/apiHelpers';
import { every, find, findLast, get, some } from 'lodash';
import { learningChunkFromDto } from '../../../helpers/model/learningChunkHelpers';
import { learningQueueFeedOrderVariants } from '../../../constants/enums/learningQueueFeedOrderVariants';
import { useChunkData } from './useChunkData';
import { learningQueueVariants, learningQueuePausableVariants } from '../../../constants/enums/learningQueueVariants';
import { useLearningProgress } from './useLearningProgress';
import { useKeydown } from './useKeydown';
import { useNext } from './useNext';
import { useBack } from './useBack';
import { getUuid } from '../../../helpers/identifierHelpers';

const produceGetChunk = (iterator) => (plan, incognitoMode, lastChunkNodeId) => {
  if (!plan) return null;
  let nextOneIsResult = !lastChunkNodeId;

  return (
    iterator(plan, (chunk) => {
      const visible = !incognitoMode || !chunk.private;
      const nextOneIsResultMemo = nextOneIsResult;
      nextOneIsResult = chunk.nodeId === lastChunkNodeId;
      if (visible && nextOneIsResultMemo) return true;
    }) || null
  );
};

const getNextChunk = produceGetChunk(find);
const getPreviousChunk = produceGetChunk(findLast);

export const useQueueRunToolkit = (props) => {
  const {
    queue,
    incognitoMode,
    notesWorkspaces,
    notes,
    nodes,
    learningQueuesActionSetElement,
    notesWorkspacesActionFetch,
    notesActionFetch,
    nodesActionFetch,
  } = props;

  const checkIfMounted = useCheckIfMounted();
  const [plan, setPlan] = useState(null);
  const [previousChunk, setPreviousChunk] = useState(null);
  const [currentChunkUuid, setCurrentChunkUuid] = useState(null);
  const [currentChunk, _setCurrentChunk] = useState(null);
  const [loading, setLoading] = useState(true);
  const [empty, setEmpty] = useState(false);
  const [ended, setEnded] = useState(false);
  const [audioFinished, setAudioFinished] = useState(null);
  const [currentWpm, setCurrentWpm] = useState(null);
  const [finalWpm, setFinalWpm] = useState(null);
  const [progressMark, setProgressMark] = useState(null);
  const [alreadyLearnedChunks, setAlreadyLearnedChunks] = useState([]);

  const setCurrentChunk = useCallback((newValue) => {
    setProgressMark(null);
    setCurrentWpm(null);
    setFinalWpm(null);
    _setCurrentChunk(newValue);
    setCurrentChunkUuid(newValue ? getUuid() : null);
  }, []);

  const [paused, setPaused] = useState(false);
  const pausableVariant = useMemo(() => learningQueuePausableVariants.includes(queue.variant), [queue.variant]);
  const pauseEnabled = useMemo(() => !loading && !empty && pausableVariant, [loading, empty, pausableVariant]);
  useEffect(() => {
    if (empty && pausableVariant) setPaused(true);
  }, [empty, pausableVariant]);

  const pause = useCallback(() => {
    if (pauseEnabled) setPaused(true);
  }, [pauseEnabled]);
  const resume = useCallback(() => setPaused(false), []);

  const updateGlobalStateLastLearnedNodeId = useCallback(
    (newValue) => {
      const newQueue = queue.shallowClone().assignValues({
        ...queue,
        lastLearnedNodeId: newValue,
      });
      learningQueuesActionSetElement(newQueue);
    },
    [queue],
  );

  const backendReset = useCallback(async () => {
    const { ok } = await api.learning.reset(queue);
    if (ok) updateGlobalStateLastLearnedNodeId(null);
  }, [queue]);

  const fetchPlan = useCallback(
    async (lastChunkNodeId) => {
      setPlan(null);
      setEnded(false);
      setPaused(false);
      setEmpty(false);
      setPreviousChunk(null);

      const { ok, data } = await api.learning.plan(queue);
      if (ok && checkIfMounted()) {
        const newPlan = data.map((chunk) => learningChunkFromDto(chunk));
        setPlan(newPlan);
        let nextChunk = getNextChunk(newPlan, incognitoMode, lastChunkNodeId);
        if (!nextChunk && lastChunkNodeId !== null) {
          nextChunk = getNextChunk(newPlan, incognitoMode, null);
          backendReset(); // no need to await
        } else if (nextChunk) {
          setCurrentChunk(nextChunk);
          setPreviousChunk(getPreviousChunk(newPlan, incognitoMode, nextChunk.nodeId));
        }
        // else - queue is empty
      }
    },
    [queue.id],
  );

  useEffect(() => {
    const ordered = queue.feedOrderVariant === learningQueueFeedOrderVariants.ordered;
    const pdf = queue.variant === learningQueueVariants.pdf;
    const lastChunkNodeId = ordered && !pdf ? queue.lastLearnedNodeId || null : null;
    fetchPlan(lastChunkNodeId);
  }, [queue.id]);

  useEffect(() => {
    if (plan && previousChunk && previousChunk.private && incognitoMode)
      setPreviousChunk(getPreviousChunk(plan, true, previousChunk.nodeId));
    if (plan && currentChunk && currentChunk.private && incognitoMode)
      setCurrentChunk(getNextChunk(plan, true, currentChunk.nodeId));
  }, [incognitoMode]);

  const reset = useCallback(async () => {
    setLoading(true);
    await backendReset();
    setAlreadyLearnedChunks([]);
    if (checkIfMounted()) await fetchPlan(null);
  }, [fetchPlan, backendReset]);

  const reloadCurrentChunk = useCallback(() => {
    if (currentChunkUuid) setCurrentChunkUuid(getUuid());
  }, [currentChunkUuid]);

  useEffect(() => {
    if (!plan || currentChunk) return;

    const _empty = !some(plan, (chunk) => !incognitoMode || !chunk.private);
    setEmpty(_empty);
  }, [currentChunk, plan, incognitoMode]);

  const chunkData = useChunkData({ ...props, currentChunk });

  const learningProgress = useLearningProgress({
    ...props,
    currentChunk,
    progressMark,
    alreadyLearnedChunks,
    audioFinished,
    finalWpm,
    nodesActionFetch,
  });

  const sharedBackNextProps = {
    ...props,
    loading,
    plan,
    updateGlobalStateLastLearnedNodeId,
    currentChunk,
    learningProgress,
    finalWpm,
    alreadyLearnedChunks,
    getNextChunk,
    setPreviousChunk,
    setCurrentChunk,
    setEnded,
    setPaused,
    pausableVariant,
    setAlreadyLearnedChunks,
    setAudioFinished,
    backendReset,
    getPreviousChunk,
    previousChunk,
    ended,
    empty,
    currentChunkUuid,
    reloadCurrentChunk,
    chunkData,
  };
  const [back, backEnabled] = useBack(sharedBackNextProps);
  const [next, nextEnabled, notDebouncedNext] = useNext(sharedBackNextProps);

  const associationsFetched = useMemo(() => {
    const { notesWorkspaceId, nodesGroupId } = currentChunk || {};
    if (!every([notesWorkspaceId, nodesGroupId])) return false;
    return notesWorkspaces.fetched && notes.fetched(notesWorkspaceId) && nodes.fetched(nodesGroupId);
  }, [currentChunk, notesWorkspaces, notes, nodes]);

  useEffect(() => {
    if (!plan) {
      setLoading(true);
      return;
    }

    if (empty || ended) setLoading(false);
    else setLoading(!associationsFetched);
  }, [plan, associationsFetched, empty, ended]);

  useEffect(() => {
    const { notesWorkspaceId, nodesGroupId } = currentChunk || {};
    if (!every([notesWorkspaceId, nodesGroupId])) return;

    if (!notesWorkspaces.fetched) notesWorkspacesActionFetch();
    if (!notes.fetched(notesWorkspaceId)) notesActionFetch(notesWorkspaceId);
    if (!nodes.fetched(nodesGroupId)) nodesActionFetch(nodesGroupId);
  }, [currentChunk, notesWorkspacesActionFetch, notesActionFetch, nodesActionFetch]);

  useEffect(() => {
    setAudioFinished(get(chunkData, 'node.audioUrl') ? false : null);
  }, [chunkData.node, currentChunkUuid]);

  const chunksCount = useMemo(
    () => (plan || []).filter((chunk) => !incognitoMode || !chunk.private).length,
    [plan, incognitoMode],
  );

  const result = {
    ...chunkData,
    loading,
    currentChunk,
    previousChunk,
    back,
    backEnabled,
    next,
    nextEnabled,
    notDebouncedNext,
    reset,
    empty,
    ended,
    paused,
    pauseEnabled,
    pause,
    resume,
    progressMark,
    setProgressMark,
    currentWpm,
    setCurrentWpm,
    finalWpm,
    setFinalWpm,
    alreadyLearnedChunks,
    audioFinished,
    setAudioFinished,
    reloadCurrentChunk,
    currentChunkUuid,
    chunksCount,
  };

  useKeydown({ ...props, ...result });

  return result;
};
