import Lockable from './parents/lockable';

export default class Note extends Lockable {
  constructor() {
    super();
    this.id = null;
    this.name = null;
    this.notesWorkspaceId = null;
    this.nodesGroupId = null;
    this.parentId = null;
    this.defaultOrder = 0;
    this.private = false;
  }

  visible(incognitoMode = false) {
    return incognitoMode ? !this.private : true;
  }
}
