import DayEvaluation from '../../models/dayEvaluation';
import { produceModelFromDto, produceModelToDto } from './modelHelpers';

const mappings = [
  ['id', 'id'],
  ['date', 'date'],
  ['evaluation', 'evaluation'],
];

export const dayEvaluationFromDto = produceModelFromDto(DayEvaluation, mappings);

export const dayEvaluationToDto = produceModelToDto(mappings);
