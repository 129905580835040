import React from 'react';
import { connect } from 'react-redux';
import {
  tasksActionCheckElement,
  tasksActionDeleteElement,
  tasksActionMarkPrivateElement,
  tasksActionMarkTodoElement,
  tasksActionUnmarkPrivateElement,
  tasksActionUnmarkTodoElement,
} from '../../../stateManagement/actions/tasksActions';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import CustomColorCrumb from '../../CustomColorCrumb';
import ArrowRightIcon from '../../../icons/arrowRightIcon';
import ButtonsGroup from '../../buttons/ButtonsGroup';
import IconButton from '../../buttons/IconButton';
import Clickable from '../../Clickable';
import ArrowDownIcon from '../../../icons/arrowDownIcon';
import ConfirmationButton from '../../buttons/ConfirmationButton';

const Header = (props) => {
  const {
    task,
    checkedDate,
    incognitoMode,
    tasksActionCheckElement: check,
    tasksActionMarkTodoElement: markTodo,
    tasksActionUnmarkTodoElement: unmarkTodo,
    tasksActionMarkPrivateElement: markPrivate,
    tasksActionUnmarkPrivateElement: unmarkPrivate,
    tasksActionDeleteElement: deleteAction,
    color,
    isContainerActive,
    toggleContainerActivity,
    activateEditionMode,
    managementMode = true,
    disabled = false,
    descriptionAdditionalClass = '',
    bulletIcon,
  } = props;

  const switchTodo = () => (task.todo ? unmarkTodo(task) : markTodo(task));
  const switchPrivate = () => (task.private ? unmarkPrivate(task) : markPrivate(task));
  const switchPrivateVariant = task.private ? 'unmarkPrivate' : 'markPrivate';

  const wrapperStyleKeys = isContainerActive ? ['borderColor', 'backgroundColor'] : ['borderColor'];
  const wrapperMixedStyleKeysMultipliers = isContainerActive
    ? { borderColor: 0.8, backgroundColor: 0.9 }
    : { borderColor: 0.8 };
  const wrapperClassName = `w-full rounded-t-xl ${isContainerActive ? 'border' : 'border-b'}`;
  const buttonsGroupClassName = `flex justify-end items-end px-1 ${disabled ? 'hidden' : ''}
         ${isContainerActive ? 'w-full sm:w-fit-content' : ''}`;

  const buttonWrappersClassNames = [
    isContainerActive ? '' : managementMode ? 'hidden sm:block' : 'hidden',
    isContainerActive ? '' : managementMode ? 'hidden sm:block' : 'hidden',
    isContainerActive ? '' : managementMode ? 'hidden sm:block' : 'hidden',
    isContainerActive ? '' : managementMode ? 'hidden sm:block' : 'hidden',
    '',
  ];

  const description = (
    <div
      className={`pb-1 w-full justify-start flex text-left text-clip overflow-hidden
                         ${descriptionAdditionalClass}`}
    >
      {task.description}
    </div>
  );

  return (
    <CustomColorCrumb
      styleKeys={wrapperStyleKeys}
      color={color}
      mixinThemeType={isContainerActive ? 'container' : 'easilyDenotableContainer'}
      mixedStyleKeysMultipliers={wrapperMixedStyleKeysMultipliers}
    >
      <div className={wrapperClassName}>
        {isContainerActive ? (
          <Clickable onMouseDown={toggleContainerActivity} className="w-full flex sm:hidden pt-3 px-4">
            <div className="pr-4 pt-1">
              <CustomColorCrumb styleKeys={['color']} color={color}>
                {bulletIcon || <ArrowDownIcon className="w-3 h-3" />}
              </CustomColorCrumb>
            </div>
            {description}
          </Clickable>
        ) : (
          <></>
        )}
        <div className="flex items-end py-1">
          <Clickable
            onMouseDown={toggleContainerActivity}
            className={`w-full items-start overflow-hidden pt-2
                                           ${isContainerActive ? 'hidden sm:flex' : 'flex'}`}
          >
            <div className="px-4 pt-1">
              <CustomColorCrumb styleKeys={['color']} color={color}>
                {bulletIcon ||
                  (isContainerActive ? <ArrowDownIcon className="w-3 h-3" /> : <ArrowRightIcon className="w-3 h-3" />)}
              </CustomColorCrumb>
            </div>
            {description}
          </Clickable>
          <ButtonsGroup
            className={buttonsGroupClassName}
            buttonWrappersClassNames={buttonWrappersClassNames}
            buttons={[
              <IconButton key="switchTodo" variant={task.todo ? 'unmarkTodo' : 'markTodo'} onMouseDown={switchTodo} />,
              !incognitoMode && (
                <IconButton key="switchPrivate" variant={switchPrivateVariant} onMouseDown={switchPrivate} />
              ),
              <IconButton key="edit" variant="edit" onMouseDown={activateEditionMode} />,
              <ConfirmationButton
                key="delete"
                variant="delete"
                i18nKey="tasks.delete"
                onConfirmed={() => deleteAction(task)}
                resourceName={task.description}
              />,
              <ConfirmationButton
                key="check"
                variant="check"
                i18nKey="tasks.check"
                onConfirmed={() => check(task, checkedDate)}
                resourceName={task.description}
              />,
            ]}
          />
        </div>
      </div>
    </CustomColorCrumb>
  );
};

export default connect(prepareState(['incognitoMode']), {
  tasksActionMarkTodoElement,
  tasksActionUnmarkTodoElement,
  tasksActionMarkPrivateElement,
  tasksActionUnmarkPrivateElement,
  tasksActionCheckElement,
  tasksActionDeleteElement,
})(Header);
