import React, { useCallback } from 'react';
import DayPlaceholder from './DayPlaceholder';
import { connect } from 'react-redux';
import { prepareState } from '../../helpers/stateManagementHelpers';
import { sortBy } from 'lodash';
import { backendDateFormat } from '../../constants/dateFormat';
import EventPresenter from './EventPresenter';
import H2 from '../../shared/titles/H2';
import CheckedTaskPresenter from './CheckedTaskPresenter';
import { dayContentPadding, dayHeaderHeight } from './metrics';
import Clickable from '../../shared/Clickable';
import { activeElementsActionActivate } from '../../stateManagement/actions/activeElementsActions';
import { activeElementTypes } from '../../constants/activeElementTypes';
import ThemeCrumb from '../../shared/ThemeCrumb';
import { currentDateInBackendFormat } from '../../helpers/dateTimeHelpers';

const DayTile = (props) => {
  const { dt, history, events, checkedTasks, categories, contentHeight, incognitoMode, activeElementsActionActivate } =
    props;

  const openModal = useCallback(
    () => activeElementsActionActivate(activeElementTypes.calendarDayModal, dt, { history }),
    [dt],
  );

  const date = dt.toFormat(backendDateFormat);

  const visibleEvents = sortBy(events.visible(incognitoMode, { date }), 'defaultOrder');

  const visibleCheckedTasks = sortBy(
    checkedTasks.visible(incognitoMode, { checked: true, checkedDate: date }),
    'defaultOrder',
  );

  return (
    <DayPlaceholder active>
      <Clickable hoverOpacity className="w-full" onClick={openModal}>
        <div className="w-full">
          <div className="w-full flex items-end justify-center" style={{ height: dayHeaderHeight }}>
            {dt.toFormat(backendDateFormat) === currentDateInBackendFormat() ? (
              <ThemeCrumb type="selected">
                <div className="px-2 rounded-full">
                  <H2>{dt.day}</H2>
                </div>
              </ThemeCrumb>
            ) : (
              <H2>{dt.day}</H2>
            )}
          </div>
          <div className="w-full" style={{ padding: dayContentPadding }}>
            <div className="w-full overflow-y-auto" style={{ height: contentHeight }}>
              <div className="w-full">
                {visibleEvents.map((event) => (
                  <div key={event.id} className="w-full first:mt-0 mt-1">
                    <EventPresenter event={event} />
                  </div>
                ))}
              </div>
              {visibleEvents.length > 0 && visibleCheckedTasks.length > 0 ? <div className="pt-3" /> : <></>}
              <div className="w-full">
                {visibleCheckedTasks.map((task) => (
                  <div key={task.id} className="w-full first:mt-0 mt-1">
                    <CheckedTaskPresenter task={task} categories={categories} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Clickable>
    </DayPlaceholder>
  );
};

export default connect(prepareState(['events', 'checkedTasks', 'categories', 'incognitoMode']), {
  activeElementsActionActivate,
})(DayTile);
