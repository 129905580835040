import { ReactEditor } from 'slate-react';

export const produceUndo = (editor) => (event) => {
  if (event) event.preventDefault();
  editor.undo();
  setTimeout(() => ReactEditor.focus(editor), 0);
};

export const produceRedo = (editor) => (event) => {
  if (event) event.preventDefault();
  editor.redo();
  setTimeout(() => ReactEditor.focus(editor), 0);
};

export const produceReset = (editor) => (event) => {
  if (event) event.preventDefault();
  editor.reset();
};
