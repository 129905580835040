import frenchkiss from 'frenchkiss';

frenchkiss.extend('en', {
  pages: {
    quickPass: {
      password: 'Password',
      unlock: 'Unlock',
    },
  },
});
