import find from 'lodash/find';

const initialState = () => [];

export default function (state = initialState(), action) {
  const {
    htmlElementId,
    callback,
    disableWhenModalOpen,
    disableWhenPopupOpen,
    exceptionClasses,
    exceptionIds,
    exceptionModals,
  } = action.payload || {};

  switch (action.type) {
    case 'ON_CLICK_OUTSIDE_CALLBACKS_ACTION_REGISTER_ELEMENT': {
      const newElement = {
        htmlElementId,
        callback,
        disableWhenModalOpen,
        disableWhenPopupOpen,
        exceptionClasses,
        exceptionIds,
        exceptionModals,
      };
      if (find(state, (elem) => elem.htmlElementId === htmlElementId)) {
        return state.map((elem) => (elem.htmlElementId === htmlElementId ? newElement : elem));
      } else {
        return [newElement, ...state];
      }
    }
    case 'ON_CLICK_OUTSIDE_CALLBACKS_ACTION_UNREGISTER_ELEMENT':
      return state.filter((e) => e.htmlElementId !== htmlElementId);
    case 'GLOBAL_ACTION_RESET':
      return initialState();
    default:
      return state;
  }
}
