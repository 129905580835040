import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { categoriesActionSetElement, categoriesActionUnlockElement } from '../../actions/categoriesActions';
import { categoryFromDto, categoryToDto } from '../../../helpers/model/categoryHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { notificationT } from '../../../helpers/i18nHelpers';

export default function* updateElementCategoriesSaga(action) {
  const initialCategory = action.payload;
  const { ok, data } = yield call(api.categories.update, categoryToDto(initialCategory));
  if (ok) {
    yield put(snackbarMessagesActionAdd(notificationT('categories.update.success')));
    yield put(categoriesActionSetElement(categoryFromDto(data)));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data, 'categories.update.errors'));
    yield put(categoriesActionUnlockElement(initialCategory));
  }
}
