import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { activeElementsActionActivate } from '../../../stateManagement/actions/activeElementsActions';
import { draggedElements } from '../../../constants/draggedElements';
import { initT } from '../../../helpers/i18nHelpers';
import SidebarButton from '../../../shared/buttons/SidebarButton';
import LockedEntityLoader from '../../../shared/loaders/LockedEntityLoader';
import List from '../../../shared/lists/List';
import { getAllHabitsSections } from '../../../helpers/habitsSectionHelpers';
import MenuTitle from '../../../shared/titles/MenuTitle';
import MenuContent from '../../../shared/organizers/MenuContent';
import { habitsSectionsActionCreateElement } from '../../../stateManagement/actions/habitsSectionsActions';
import HabitsSection from '../../../models/habitsSection';
import LabelledButton from '../../../shared/buttons/LabelledButton';
import { useParams } from 'react-router-dom';
import { useCheckIfMounted } from '../../../hooks/useCheckIfMounted';

const t = initT('pages.habits');

const Sections = (props) => {
  const { habitsSections, incognitoMode, habitsSectionsActionCreateElement } = props;

  const { habitsSectionId } = useParams();

  const visibleSections = getAllHabitsSections(habitsSections, incognitoMode);

  const [creationEnabled, setCreationEnabled] = useState(true);
  const checkIfMounted = useCheckIfMounted();
  const createNewSection = useCallback(() => {
    setCreationEnabled(false);
    const newSection = new HabitsSection();
    const nameBase = t('section.newSectionName');
    let newSectionName = nameBase;
    let counter = 1;
    while (habitsSections.find({ name: newSectionName })) {
      newSectionName = `${nameBase} ${counter}`;
      counter += 1;
    }
    newSection.name = newSectionName;
    habitsSectionsActionCreateElement(newSection);
    setTimeout(() => {
      if (checkIfMounted()) setCreationEnabled(true);
    }, 1000);
  }, [habitsSectionsActionCreateElement, habitsSections]);

  const getListElement = (section) => (
    <LockedEntityLoader key={section.id} visible={section.locked}>
      <SidebarButton
        selected={habitsSectionId && section.id === parseInt(habitsSectionId, 10)}
        to={`/habits/${section.id}`}
      >
        {section.name}
      </SidebarButton>
    </LockedEntityLoader>
  );

  return (
    <MenuContent
      top={
        <>
          <MenuTitle marginClass="mx-25px py-25px">{t('section.title')}</MenuTitle>
          <div className="pt-25px px-25px pb-4 flex justify-center">
            <LabelledButton
              variant="add"
              onMouseDown={createNewSection}
              disabled={habitsSections.locked || !creationEnabled}
            >
              {t('section.add')}
            </LabelledButton>
          </div>
        </>
      }
      main={
        <div className="w-full px-25px pb-25px">
          <List collection={visibleSections} draggable={{ draggedElementType: draggedElements.habitsSection }}>
            {getListElement}
          </List>
        </div>
      }
    />
  );
};

export default connect(prepareState(['habitsSections', 'incognitoMode', 'activeElements']), {
  activeElementsActionActivate,
  habitsSectionsActionCreateElement,
})(Sections);
