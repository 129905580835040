import React from 'react';
import H2 from './H2';
import ThemeCrumb from '../ThemeCrumb';
import Clickable from '../Clickable';
import TooltipWrapper from '../TooltipWrapper';

const MenuTitle = (props) => {
  const { children, marginClass = 'mx-25px mb-25px py-25px', to, tooltip } = props;

  let content = <H2>{children}</H2>;
  if (to)
    content = (
      <Clickable to={to}>
        <ThemeCrumb type="button">{content}</ThemeCrumb>
      </Clickable>
    );
  if (tooltip) content = <TooltipWrapper tooltip={tooltip}>{content}</TooltipWrapper>;

  return (
    <ThemeCrumb type="menuTitle">
      <div className={`${marginClass} border-b border-dotted`}>
        <div className={'truncate w-full flex justify-center items-center h-40px'}>{content}</div>
      </div>
    </ThemeCrumb>
  );
};

export default MenuTitle;
