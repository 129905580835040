import BaseModel from '../parents/baseModel';

export default class ActiveElement extends BaseModel {
  constructor(type, identifier, additionalData = {}) {
    super();
    this.type = type;
    this.identifier = identifier;
    this.additionalData = additionalData;
  }
}
