import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { prepareCollection } from '../../../helpers/model/modelHelpers';
import { notesActionSet } from '../../actions/notesActions';
import { noteFromDto } from '../../../helpers/model/noteHelpers';

export default function* fetchNotesSaga(action) {
  const { notesWorkspaceId } = action.payload;
  const { ok, data } = yield call(api.notes.index, notesWorkspaceId);
  if (ok) {
    yield put(notesActionSet(notesWorkspaceId, prepareCollection(data, noteFromDto)));
  }
}
