import React, { useCallback } from 'react';
import SmartForm from '../../../form/SmartForm';
import { withFormik } from 'formik';
import { initT } from '../../../../helpers/i18nHelpers';
import { connect } from 'react-redux';
import { customPropsToValues, enumToSelectorValues, produceValuesFilter } from '../../../../helpers/formHelpers';
import isEmpty from 'lodash/isEmpty';
import ModalFooter from '../../ModalFooter';
import { activeElementsActionDeactivate } from '../../../../stateManagement/actions/activeElementsActions';
import { activeElementTypes } from '../../../../constants/activeElementTypes';
import CounterField from '../../../form/fieldTypes/CounterField';
import RadioField from '../../../form/fieldTypes/RadioField';
import SmartField from '../../../form/SmartField';
import { weekdayTypes } from '../../../../constants/enums/weekdayTypes';
import HabitSummaryRequest from '../../../../models/habitSummaryRequest';
import { habitSummaryRequestVariants } from '../../../../constants/enums/habitSummaryRequestVariants';
import { aggregationMethods } from '../../../../constants/enums/aggregationMethods';
import ModalHeader from '../../ModalHeader';
import ModalBody from '../../ModalBody';
import LabelledButton from '../../../buttons/LabelledButton';
import SelectorField from '../../../form/fieldTypes/SelectorField';

const t = initT('modals.habitSummaryRequestModal');

const relevantAttributes = ['aggregationMethod', 'variant', 'weekdayType', 'customPeriodDaysCounter'];
const filterValues = produceValuesFilter(relevantAttributes);

const HabitSummaryRequestInnerForm = (props) => {
  const { errors, handleSubmit, activeElementsActionDeactivate: deactivate } = props;
  const hideModal = useCallback(() => deactivate(activeElementTypes.habitSummaryRequestModal, null), [deactivate]);
  let aggregationMethodOptions = enumToSelectorValues(aggregationMethods, 'aggregationMethods');
  const weekdayTypeOptions = enumToSelectorValues(weekdayTypes, 'weekdayTypes');
  return (
    <SmartForm>
      <ModalHeader type={activeElementTypes.habitSummaryRequestModal} identifier={null}>
        {t('title')}
      </ModalHeader>
      <ModalBody>
        <div className="max-w-fit-content min-w-48">
          <div className="flex items-center">
            <div className="w-24 mr-2">
              <SmartField
                name="aggregationMethod"
                options={aggregationMethodOptions}
                component={SelectorField}
                leftPadding={false}
              />
            </div>
            {t('labels.aggregationMethodFollowUp')}
          </div>
          <div className="mt-25px">
            <SmartField
              name="variant"
              component={RadioField}
              radioValue={habitSummaryRequestVariants.sinceWeekday}
              label={
                <div className="flex flex-nowrap items-center">
                  <div>{t('labels.sinceLast')}</div>
                  <div className="mt-0 ml-2 w-36">
                    <SmartField
                      name="weekdayType"
                      options={weekdayTypeOptions}
                      component={SelectorField}
                      leftPadding={false}
                    />
                  </div>
                </div>
              }
            />
          </div>
          <div className="mt-25px">
            <SmartField
              name="variant"
              component={RadioField}
              radioValue={habitSummaryRequestVariants.sinceCustomPeriod}
              label={
                <div className="flex flex-nowrap items-center">
                  <div>{t('labels.duringLast')}</div>
                  <div className="my-0 mx-2 w-36">
                    <SmartField name="customPeriodDaysCounter" min={1} component={CounterField} />
                  </div>
                  <div>{t('labels.days')}</div>
                </div>
              }
            />
          </div>
          <div className="mt-25px">
            <SmartField
              name="variant"
              component={RadioField}
              radioValue={habitSummaryRequestVariants.monthly}
              label={t('labels.monthly')}
            />
          </div>
          <div className="mt-25px">
            <SmartField
              name="variant"
              component={RadioField}
              radioValue={habitSummaryRequestVariants.annually}
              label={t('labels.annually')}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <LabelledButton variant="cancel" onMouseDown={hideModal} />
        <LabelledButton variant="ok" disabled={!isEmpty(errors)} onMouseDown={handleSubmit} />
      </ModalFooter>
    </SmartForm>
  );
};

const HabitSummaryRequestForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: (props) => customPropsToValues(props, filterValues),
  handleSubmit: (values, formikBag) => {
    const { addElement, activeElementsActionDeactivate } = formikBag.props;
    const habitSummaryRequest = new HabitSummaryRequest().assignValues(filterValues(values));
    addElement(habitSummaryRequest);
    activeElementsActionDeactivate(activeElementTypes.habitSummaryRequestModal, null);
  },
})(HabitSummaryRequestInnerForm);

export default connect(null, { activeElementsActionDeactivate })(HabitSummaryRequestForm);
