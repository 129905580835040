export const quickPassActionActivate = () => ({
  type: 'QUICK_PASS_ACTION_ACTIVATE',
});

export const quickPassActionAutoActivate = () => ({
  type: 'QUICK_PASS_ACTION_AUTO_ACTIVATE',
});

export const quickPassActionDeactivate = () => ({
  type: 'QUICK_PASS_ACTION_DEACTIVATE',
});

export const quickPassActionRemoveHash = () => ({
  type: 'QUICK_PASS_ACTION_REMOVE_HASH',
});

export const quickPassActionSetHash = (hash) => ({
  type: 'QUICK_PASS_ACTION_SET_HASH',
  payload: hash,
});
