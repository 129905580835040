import React from 'react';
import compact from 'lodash/compact';
import { connect } from 'react-redux';
import SnackbarMessage from './SnackbarMessage';
import MovementAnimator from '../MovementAnimator';

const SnackbarMessagesDisplay = ({ awaitingMessages, activeMessage }) => {
  return (
    <div className="flex justify-center w-full fixed bottom-0 right-0 left-0 z-60">
      <div className="max-w-ultra w-full flex justify-end relative">
        <MovementAnimator allowOutsideViewport>
          {compact([
            activeMessage ? (
              <div key={activeMessage.uuid} className="w-full">
                <SnackbarMessage type={activeMessage.type} uuid={activeMessage.uuid}>
                  {activeMessage.message}
                </SnackbarMessage>
              </div>
            ) : null,
            ...awaitingMessages.map(({ uuid, type, message }) => (
              <div key={uuid} className="w-full" style={{ position: 'absolute', bottom: '-500px', right: '0px' }}>
                <SnackbarMessage type={type} uuid={uuid}>
                  {message}
                </SnackbarMessage>
              </div>
            )),
          ])}
        </MovementAnimator>
      </div>
    </div>
  );
};

const mapStateToProps = ({ snackbarMessages: { awaitingMessages, activeMessage } }) => ({
  awaitingMessages,
  activeMessage,
});

export default connect(mapStateToProps)(SnackbarMessagesDisplay);
