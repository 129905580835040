import { useState } from 'react';
import useResizeEffect from '../../../../hooks/useResizeEffect';

export default function useAttachmentsGridColsClass(ref) {
  const [gridColsClass, setGridColsClass] = useState('');
  useResizeEffect(
    () => {
      const { clientWidth } = ref.current;
      if (clientWidth < 200) {
        setGridColsClass('grid-cols-1');
      } else if (clientWidth < 400) {
        setGridColsClass('grid-cols-2');
      } else if (clientWidth < 600) {
        setGridColsClass('grid-cols-3');
      } else if (clientWidth < 800) {
        setGridColsClass('grid-cols-4');
      } else if (clientWidth < 1000) {
        setGridColsClass('grid-cols-5');
      } else if (clientWidth < 1200) {
        setGridColsClass('grid-cols-6');
      } else if (clientWidth < 1400) {
        setGridColsClass('grid-cols-7');
      } else {
        setGridColsClass('grid-cols-8');
      }
    },
    [],
    true,
  );

  return gridColsClass;
}
