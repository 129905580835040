import ElementToFocusOn from '../../models/auxiliary/elementToFocusOn';

export const elementToFocusOnActionSet = (type, identifier) => ({
  type: 'ELEMENT_TO_FOCUS_ON_ACTION_SET',
  payload: new ElementToFocusOn(type, identifier),
});

export const elementToFocusOnActionReset = () => ({
  type: 'ELEMENT_TO_FOCUS_ON_ACTION_RESET',
});
