import isHotkey from 'is-hotkey';
import { isEndOfLink, isLinkNodeAtSelection, toggleLink } from '../helpers/linkHelpers';
import { produceToggleLeafVariant } from '../helpers/leafVariantHelpers';
import { alignmentTypes } from '../constants/alignmentTypes';
import { produceToggleAlignmentType } from '../helpers/alignmentHelpers';
import { leafVariants } from '../constants/leafVariants';
import { produceDecreaseNestingLevel, produceIncreaseNestingLevel } from '../helpers/blockNestingHelpers';
import { produceToggleBlockVariant } from '../helpers/blockVariantHelpers';
import { blockVariants } from '../constants/blockVariants';

export const produceOnKeyDown = (editor) => (event) => {
  if (isHotkey('shift+enter', event)) {
    event.preventDefault();
    const linkNodeSelected = isLinkNodeAtSelection(editor);
    if (linkNodeSelected && !isEndOfLink(editor)) return;

    if (linkNodeSelected) {
      toggleLink(editor);
      setTimeout(() => editor.insertText('\n'));
    } else {
      editor.insertText('\n');
    }
  } else if (isHotkey('right', event) && isEndOfLink(editor)) {
    event.preventDefault();
    toggleLink(editor);
  } else if (isHotkey('mod+b', event)) {
    produceToggleLeafVariant(editor, leafVariants.bold)(event);
  } else if (isHotkey('mod+i', event)) {
    produceToggleLeafVariant(editor, leafVariants.italic)(event);
  } else if (isHotkey('mod+u', event)) {
    produceToggleLeafVariant(editor, leafVariants.underline)(event);
  } else if (isHotkey('shift+mod+s', event)) {
    produceToggleLeafVariant(editor, leafVariants.strikethrough)(event);
  } else if (isHotkey('shift+mod+m', event)) {
    produceToggleLeafVariant(editor, leafVariants.code)(event);
  } else if (isHotkey('shift+mod+l', event)) {
    produceToggleAlignmentType(editor, alignmentTypes.left)(event);
  } else if (isHotkey('shift+mod+e', event)) {
    produceToggleAlignmentType(editor, alignmentTypes.center)(event);
  } else if (isHotkey('shift+mod+r', event)) {
    produceToggleAlignmentType(editor, alignmentTypes.right)(event);
  } else if (isHotkey('shift+mod+j', event)) {
    produceToggleAlignmentType(editor, alignmentTypes.justify)(event);
  } else if (isHotkey('tab', event)) {
    const linkNodeSelected = isLinkNodeAtSelection(editor);
    if (linkNodeSelected) {
      event.preventDefault();
      const linkPopoverInput = document.getElementById('link-popover-input');
      if (linkPopoverInput) {
        linkPopoverInput.focus();
        linkPopoverInput.select();
      }
    } else produceIncreaseNestingLevel(editor)(event);
  } else if (isHotkey('shift+tab', event)) {
    produceDecreaseNestingLevel(editor)(event);
  } else if (isHotkey('shift+mod+7', event)) {
    produceToggleBlockVariant(editor, blockVariants.orderedBullet)(event);
  } else if (isHotkey('shift+mod+8', event)) {
    produceToggleBlockVariant(editor, blockVariants.bullet)(event);
  } else if (isHotkey('shift+mod+9', event)) {
    produceToggleBlockVariant(editor, blockVariants.checklistBullet)(event);
  } else if (isHotkey('mod+k', event)) {
    toggleLink(editor);
  }
};
