import React from 'react';
import AudioControllerBase from './base';
import IconButton from '../../buttons/IconButton';
import LockedEntityLoader from '../../loaders/LockedEntityLoader';
import AuxiliaryText from '../../AuxiliaryText';
import { initT } from '../../../helpers/i18nHelpers';

const t = initT('features.audioController');

const AudioControllerInner = (props) => {
  const {
    wavesurfer,
    isPlaying,
    playPause,
    audioLocked,
    timerId,
    waveHeight,
    waveFormId,
    durationId,
    trimMode,
    toggleTrimMode,
    enableTrim,
    disabled,
  } = props;

  return (
    <LockedEntityLoader visible={!wavesurfer}>
      <div>
        {enableTrim ? <div className="h-4" /> : <></>}
        <div className="w-full flex items-center">
          <IconButton
            variant={isPlaying ? 'pause' : 'play'}
            onMouseDown={playPause}
            disabled={disabled || audioLocked}
          />
          <div id={timerId} className="w-24 px-2 text-right" />
          <div className="w-full flex justify-center relative" style={{ height: waveHeight }}>
            <div id={waveFormId} className="w-full" />
          </div>
          <div id={durationId} className="w-24 px-2" />
          {enableTrim ? (
            <IconButton
              variant={trimMode ? 'confirm' : 'cut'}
              onMouseDown={toggleTrimMode}
              tooltip={t(trimMode ? 'confirmTrim' : 'enableTrim')}
            />
          ) : (
            <></>
          )}
        </div>
        {enableTrim ? (
          <div className="w-full h-4">
            <AuxiliaryText className="text-center">{trimMode ? t('trimPrompt') : ''}</AuxiliaryText>
          </div>
        ) : (
          <></>
        )}
      </div>
    </LockedEntityLoader>
  );
};

const AudioController = (props) => {
  return (
    <AudioControllerBase waveHeight={24} {...props}>
      {(innerProps) => <AudioControllerInner {...props} {...innerProps} />}
    </AudioControllerBase>
  );
};

export default AudioController;
