import React, { useCallback, useMemo, useState } from 'react';
import LabelInput from './LabelInput';
import ThemeCrumb from '../../ThemeCrumb';
import { connect } from 'react-redux';
import {
  labelsActionMarkPrivateElement,
  labelsActionToggleAssignment,
  labelsActionUnmarkPrivateElement,
} from '../../../stateManagement/actions/labelsActions';
import sortBy from 'lodash/sortBy';
import lowerCase from 'lodash/lowerCase';
import { prepareState } from '../../../helpers/stateManagementHelpers';

const prepareOptions = (selectedIds, initialOptions, needle) => {
  const selectedOptions = [];
  const remainingOptions = [];

  sortBy(initialOptions, (o) => lowerCase(o.props.label)).forEach((option) => {
    if (needle && !lowerCase(option.props.label).includes(lowerCase(needle))) return;
    if (selectedIds.includes(option.props.value)) selectedOptions.push(option);
    else remainingOptions.push(option);
  });

  return [...selectedOptions, ...remainingOptions];
};

const LabelMenu = (props) => {
  const { selectedValue, options, object, labelsNames } = props;

  const [needle, setNeedle] = useState('');
  const onNeedleChange = useCallback((newNeedle) => setNeedle(newNeedle), [setNeedle]);

  const preparedOptions = useMemo(
    () => prepareOptions(Array.isArray(selectedValue) ? selectedValue : [], options, needle),
    [options, needle],
  );

  return (
    <ThemeCrumb type="selectorMenu">
      <div className={'border rounded-md'}>
        <LabelInput object={object} labelsNames={labelsNames} onChange={onNeedleChange} />
        <div className="max-h-40 w-64 max-w-64 overflow-y-auto py-2">
          {preparedOptions.map((option) => React.cloneElement(option, props))}
        </div>
      </div>
    </ThemeCrumb>
  );
};

// global state values are used in LabelOption
export default connect(prepareState(['incognitoMode']), {
  labelsActionToggleAssignment,
  labelsActionMarkPrivateElement,
  labelsActionUnmarkPrivateElement,
})(LabelMenu);
