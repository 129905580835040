import frenchkiss from 'frenchkiss';

frenchkiss.extend('en', {
  confirmations: {
    incognitoMode: {
      exit: {
        title: 'Exit incognito mode',
        additionalMessage: 'Do you really want to show private items?',
      },
    },
    categories: {
      delete: {
        title: 'Delete category',
        additionalMessage: 'Do you want to delete this category?',
        textConfirmationPrefix: 'delete-category-',
      },
    },
    tasks: {
      check: {
        title: 'Check off task',
        additionalMessage: 'Is it done?',
      },
      delete: {
        title: 'Delete task',
        additionalMessage: 'Do you want to delete this task?',
      },
    },
    recurringTasks: {
      delete: {
        title: 'Delete recurring task',
        additionalMessage: 'Do you want to delete this recurring task?',
      },
    },
    habitsSections: {
      delete: {
        title: 'Delete section',
        additionalMessage: 'Do you want to delete this section?',
        textConfirmationPrefix: 'delete-section-',
      },
    },
    habits: {
      delete: {
        title: 'Delete habit',
        additionalMessage: 'Do you want to delete this habit?',
      },
    },
    nodes: {
      delete: {
        title: 'Delete node',
        additionalMessage: 'Do you want to delete this node?',
      },
    },
    notesWorkspaces: {
      delete: {
        title: 'Delete workspace',
        additionalMessage: 'Do you want to delete this workspace?',
        textConfirmationPrefix: 'delete-workspace-',
      },
    },
    notes: {
      delete: {
        title: 'Delete note',
        additionalMessage: 'Do you want to delete this note?',
        textConfirmationPrefix: 'delete-note-',
      },
    },
    events: {
      delete: {
        title: 'Delete event',
        additionalMessage: 'Do you want to delete this event?',
      },
    },
    recurringEvents: {
      delete: {
        title: 'Delete recurring event',
        additionalMessage: 'Do you want to delete this recurring event?',
      },
    },
    learningWorkspaces: {
      delete: {
        title: 'Delete workspace',
        additionalMessage: 'Do you want to delete this workspace?',
        textConfirmationPrefix: 'delete-workspace-',
      },
    },
    learningQueues: {
      delete: {
        title: 'Delete learning queue',
        additionalMessage: 'Do you want to delete this learning queue?',
      },
    },
    languageWorkspaces: {
      delete: {
        title: 'Delete workspace',
        additionalMessage: 'Do you want to delete this workspace?',
        textConfirmationPrefix: 'delete-workspace-',
      },
    },
    wastes: {
      delete: {
        title: 'Delete permanently',
        additionalMessage: "Do you want to delete this item permanently? There won't be any way to restore it.",
      },
    },
    textConfirmationPrompt: 'Rewrite the following text to confirm:',
  },
});
