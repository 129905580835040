import React from 'react';
import { initT } from '../../../helpers/i18nHelpers';
import CenteredContent from '../../../shared/organizers/CenteredContent';
import H1 from '../../../shared/titles/H1';

const t = initT('pages.notes');

const Top = () => {
  return <CenteredContent heightPx={40} main={<H1>{t('pdfFromMultipleNotes')}</H1>} />;
};

export default Top;
