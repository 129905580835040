import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { categoriesActionSet } from '../../actions/categoriesActions';
import { prepareCollection } from '../../../helpers/model/modelHelpers';
import { categoryFromDto } from '../../../helpers/model/categoryHelpers';

export default function* fetchCategoriesSaga(action) {
  const { ok, data } = yield call(api.categories.index, action.payload);
  if (ok) {
    yield put(categoriesActionSet(prepareCollection(data, categoryFromDto)));
  }
}
