import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
  nodesActionDeleteElement,
  nodesActionDuplicateElement,
  nodesActionMarkPrivateElement,
  nodesActionUnmarkPrivateElement,
  nodesActionUpdateElement,
} from '../../../stateManagement/actions/nodesActions';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import ThemeCrumb from '../../ThemeCrumb';
import { elementToFocusOnTypes } from '../../../constants/elementToFocusOnTypes';
import { elementToFocusOnActionReset } from '../../../stateManagement/actions/elementToFocusOnActions';
import { initT } from '../../../helpers/i18nHelpers';
import { nodeVariants } from '../../../constants/nodeVariants';
import NodeText from './nodeText';
import {
  activeElementsActionActivate,
  activeElementsActionDeactivate,
} from '../../../stateManagement/actions/activeElementsActions';
import { activeElementTypes } from '../../../constants/activeElementTypes';
import find from 'lodash/find';
import LockedEntityLoader from '../../loaders/LockedEntityLoader';
import IconButton from '../../buttons/IconButton';
import ConfirmationButton from '../../buttons/ConfirmationButton';
import NodeHeading from './nodeHeading';
import NodeImage from './nodeImage';
import NodeAttachments from './nodeAttachments';
import LabelsSelector from '../labelsSelector';
import AdditionalInformation from './AdditionalInformation';
import NotCentralizedOnClickOnOutsideWrapper from '../../onClickOutside/NotCentralizedOnClickOnOutsideWrapper';

const t = initT('features.nodesGroupPanel');

const NodePanel = (props) => {
  const {
    editionAllowed = false,
    interactive = true,
    node,
    dragged = false,
    draggableListRef = {},
    audioEnabledForVariants = [],
    labelsEnabledForVariants = [],
    onModal,
    incognitoMode,
    elementToFocusOn,
    nodesActionUpdateElement: update,
    elementToFocusOnActionReset,
    nodesActionDeleteElement: deleteNode,
    nodesActionDuplicateElement: duplicate,
    nodesActionMarkPrivateElement: markPrivate,
    nodesActionUnmarkPrivateElement: unmarkPrivate,
    activeElements,
    activeElementsActionActivate: activate,
    activeElementsActionDeactivate: deactivate,
  } = props;

  const [mouseOver, setMouseOver] = useState(false);

  const activeNode = find(activeElements, { type: activeElementTypes.node });
  const editionMode = !!(activeNode && activeNode.identifier === node.id);

  const audioEnabled = useMemo(
    () => audioEnabledForVariants.includes(node.variant),
    [audioEnabledForVariants, node.variant],
  );
  const labelsEnabled = useMemo(
    () => labelsEnabledForVariants.includes(node.variant),
    [labelsEnabledForVariants, node.variant],
  );

  const enableEditionMode = () => {
    if (draggableListRef.current) draggableListRef.current.disableDragging();
    activate(activeElementTypes.node, node.id);
  };
  const disableEditionMode = () => {
    if (draggableListRef.current) draggableListRef.current.enableDragging();
    deactivate(activeElementTypes.node, node.id);
  };
  const openAudioSelectionModal = () => {
    activate(activeElementTypes.audioSelectionModal, node.id, { node: node });
  };

  useEffect(() => {
    if (elementToFocusOn && elementToFocusOn.equals(elementToFocusOnTypes.node, node.id)) {
      enableEditionMode();
      elementToFocusOnActionReset();
    }
  }, [elementToFocusOn]);

  useEffect(() => {
    if (!editionAllowed && editionMode) disableEditionMode();
  }, [editionAllowed, editionMode]);

  let actualNodePanel = <></>;
  const actualNodePanelProps = {
    editionAllowed,
    editionMode,
    dragged,
    enableEditionMode,
    disableEditionMode,
    activeNode,
    node,
    update,
    interactive,
    onModal,
  };

  if (node.variant === nodeVariants.heading) actualNodePanel = <NodeHeading {...actualNodePanelProps} />;
  else if (node.variant === nodeVariants.text) actualNodePanel = <NodeText {...actualNodePanelProps} />;
  else if (node.variant === nodeVariants.image) actualNodePanel = <NodeImage {...actualNodePanelProps} />;
  else if (node.variant === nodeVariants.attachments) actualNodePanel = <NodeAttachments {...actualNodePanelProps} />;

  const opacityClass = editionAllowed && activeNode && !editionMode ? 'opacity-30' : '';

  const shouldShowAdditionalInformation = useMemo(() => {
    if (editionAllowed) return false;
    if (!interactive) return false;
    if (audioEnabled && node.audioUrl) return true;
    if (labelsEnabled && node.labelsIds.length > 0) return true;
    return false;
  }, [editionAllowed, node.audioUrl, node.labelsIds.length, interactive, audioEnabled, labelsEnabled]);

  return (
    <NotCentralizedOnClickOnOutsideWrapper onClickOutside={() => setMouseOver(false)}>
      <LockedEntityLoader visible={node.locked}>
        <div
          className={`w-full ${opacityClass}`}
          onMouseEnter={() => setMouseOver(true)}
          onMouseLeave={() => setMouseOver(false)}
        >
          {editionAllowed && !editionMode ? (
            <ThemeCrumb type="nodeToolbar">
              <div className="w-full flex border-l border-r border-t rounded-t-md p-1">
                {labelsEnabled ? (
                  <div className="mr-2">
                    <LabelsSelector object={node} />
                  </div>
                ) : (
                  <></>
                )}
                {audioEnabled ? (
                  <IconButton
                    variant="audio"
                    onMouseDown={openAudioSelectionModal}
                    tooltip={t('manageAudio')}
                    iconSizeClass="w-3 h-3"
                  />
                ) : (
                  <></>
                )}
                <div className="w-full flex justify-end">
                  {!incognitoMode ? (
                    node.private ? (
                      <IconButton
                        variant="unmarkPrivate"
                        wrapperClass="mr-2"
                        iconSizeClass="w-3 h-3"
                        onMouseDown={() => unmarkPrivate(node)}
                      />
                    ) : (
                      <IconButton
                        variant="markPrivate"
                        wrapperClass="mr-2"
                        iconSizeClass="w-3 h-3"
                        onMouseDown={() => markPrivate(node)}
                      />
                    )
                  ) : null}
                  <IconButton
                    variant="duplicate"
                    wrapperClass="mr-2"
                    iconSizeClass="w-3 h-3"
                    onMouseDown={() => duplicate(node)}
                  />
                  <ConfirmationButton
                    variant="delete"
                    tooltip={t('deleteNode')}
                    i18nKey="nodes.delete"
                    iconSizeClass="w-3 h-3"
                    onConfirmed={() => deleteNode(node)}
                  />
                </div>
              </div>
            </ThemeCrumb>
          ) : (
            <></>
          )}
          {shouldShowAdditionalInformation ? <AdditionalInformation node={node} mouseOver={mouseOver} /> : <></>}
          <div className="w-full">{actualNodePanel}</div>
        </div>
      </LockedEntityLoader>
    </NotCentralizedOnClickOnOutsideWrapper>
  );
};

export default connect(prepareState(['incognitoMode', 'elementToFocusOn', 'activeElements']), {
  elementToFocusOnActionReset,
  nodesActionUpdateElement,
  nodesActionDeleteElement,
  nodesActionDuplicateElement,
  nodesActionMarkPrivateElement,
  nodesActionUnmarkPrivateElement,
  activeElementsActionActivate,
  activeElementsActionDeactivate,
})(NodePanel);
