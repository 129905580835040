import React, { useEffect, useRef } from 'react';
import WorkspaceTile from './WorkspaceTile';
import MainTopSpace from '../../../shared/organizers/MainTopSpace';
import LabelledButton from '../../../shared/buttons/LabelledButton';
import { initT } from '../../../helpers/i18nHelpers';
import List from '../../../shared/lists/List';
import { draggedElements } from '../../../constants/draggedElements';
import LearningWorkspace from '../../../models/learningWorkspace';
import find from 'lodash/find';
import OnClickOutsideWrapper from '../../../shared/onClickOutside/OnClickOutsideWrapper';

const t = (editionMode) => initT(`pages.learningWorkspaces.${editionMode ? 'edition' : 'selection'}`);

const Main = (props) => {
  const { visibleWorkspaces, editionMode, setEditionMode, createWorkspace } = props;

  const draggableListRef = useRef();
  useEffect(() => {
    if (!draggableListRef.current) return;
    if (editionMode) draggableListRef.current.enableDragging();
    else draggableListRef.current.disableDragging();
  }, [editionMode, draggableListRef.current]);

  const _createWorkspace = () => {
    const workspace = new LearningWorkspace();
    const nameBase = t(editionMode)('newWorkspaceName');
    let newName = nameBase;
    let counter = 1;
    while (find(visibleWorkspaces, { name: newName })) {
      newName = `${nameBase} ${counter}`;
      counter += 1;
    }
    workspace.name = newName;
    createWorkspace(workspace);
  };
  const onClickOutside = () => {
    setTimeout(() => setEditionMode(false));
  };

  return (
    <>
      <div className="learning-workspaces-top-space">
        <MainTopSpace>
          {editionMode ? (
            <LabelledButton variant="add" onMouseDown={_createWorkspace}>
              {t(editionMode)('add')}
            </LabelledButton>
          ) : (
            <LabelledButton
              variant="edit"
              themeType="button"
              disabled={editionMode}
              onMouseDown={() => setEditionMode(true)}
            >
              {t(editionMode)('enableEdition')}
            </LabelledButton>
          )}
        </MainTopSpace>
      </div>
      <div className="px-25px pb-25px">
        <OnClickOutsideWrapper onClickOutside={onClickOutside} exceptionClasses={['learning-workspaces-top-space']}>
          <List
            collection={visibleWorkspaces}
            draggable={{
              ref: draggableListRef,
              draggedElementType: draggedElements.learningWorkspace,
              className: 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-25px',
            }}
          >
            {(workspace) => (
              <WorkspaceTile
                key={workspace.id}
                workspace={workspace}
                editionMode={editionMode}
                setEditionMode={setEditionMode}
              />
            )}
          </List>
        </OnClickOutsideWrapper>
      </div>
    </>
  );
};

export default Main;
