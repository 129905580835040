import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { notificationT } from '../../../helpers/i18nHelpers';
import { habitsSectionFromDto, habitsSectionToDto } from '../../../helpers/model/habitsSectionHelpers';
import { habitsSectionsActionSetCreatedElement, habitsSectionsActionUnlock } from '../../actions/habitsSectionsActions';
import { push } from 'connected-react-router';
import { elementToFocusOnActionSet } from '../../actions/elementToFocusOnActions';
import { elementToFocusOnTypes } from '../../../constants/elementToFocusOnTypes';

export default function* createElementHabitsSectionsSaga(action) {
  const { ok, data } = yield call(api.habitsSections.create, habitsSectionToDto(action.payload));
  if (ok) {
    const newHabitsSection = habitsSectionFromDto(data);
    yield put(snackbarMessagesActionAdd(notificationT('habitsSections.create.success')));
    yield put(habitsSectionsActionSetCreatedElement(newHabitsSection));
    yield put(push(`/habits/${newHabitsSection.id}`));
    yield put(elementToFocusOnActionSet(elementToFocusOnTypes.habitsSectionTitle, newHabitsSection.id));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data, 'habitsSections.create.errors'));
    yield put(habitsSectionsActionUnlock());
  }
}
