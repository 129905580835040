import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { snackbarMessagesActionHandleBackendErrors } from '../../actions/snackbarMessagesActions';
import { noteFromDto, noteToDto } from '../../../helpers/model/noteHelpers';
import { notesActionSetElement, notesActionUnlockElement } from '../../actions/notesActions';

export default function* markPrivateElementNotesSaga(action) {
  const { element: initialNote } = action.payload;
  const { ok, data } = yield call(api.notes.markPrivate, noteToDto(initialNote));
  if (ok) {
    yield put(notesActionSetElement(noteFromDto(data)));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(notesActionUnlockElement(initialNote));
  }
}
