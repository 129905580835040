import { produceModelFromDto, produceModelToDto } from './modelHelpers';
import Waste from '../../models/waste';

const mappings = [
  ['id', 'id'],
  ['name', 'name'],
  ['model_name', 'modelName'],
  ['can_be_restored', 'canBeRestored'],
  ['archived_at', 'archivedAt'],
];

export const wasteFromDto = produceModelFromDto(Waste, mappings);

export const wasteToDto = produceModelToDto(mappings);
