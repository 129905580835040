import React from 'react';
import ThemeCrumb from './ThemeCrumb';
import CheckBoxCheckIcon from '../icons/checkBoxCheckIcon';

const CheckIndicator = (props) => {
  const { theme, checked } = props;
  return (
    <ThemeCrumb type={theme}>
      <div
        className="rounded border w-4 h-4 min-w-4 flex items-center
                                        justify-center"
      >
        <ThemeCrumb type="confirm">
          <CheckBoxCheckIcon className={`${checked ? '' : 'invisible'} w-2.5 h-2.5`} />
        </ThemeCrumb>
      </div>
    </ThemeCrumb>
  );
};

export default CheckIndicator;
