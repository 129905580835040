import React from 'react';
import ThemeCrumb from '../ThemeCrumb';

const ModalLoader = ({ children, loaderVisible = true, sizeClass = 'w-32 h-32' }) => {
  const childrenVisibilityClass = loaderVisible ? 'invisible' : '';
  return (
    <div className="w-full relative">
      {loaderVisible && (
        <ThemeCrumb type="modalBody">
          <div className="flex justify-center items-center absolute inset-0">
            <ThemeCrumb type="loader">
              <div className={`${sizeClass} lds-ripple max-w-fit-content`}>
                <div></div>
                <div></div>
              </div>
            </ThemeCrumb>
          </div>
        </ThemeCrumb>
      )}
      <div className={`w-full ${childrenVisibilityClass}`}>{children}</div>
    </div>
  );
};

export default ModalLoader;
