import React, { useEffect, useState } from 'react';
import Container from '../../../shared/organizers/containers/Container';
import Clickable from '../../../shared/Clickable';
import ButtonsGroup from '../../../shared/buttons/ButtonsGroup';
import IconButton from '../../../shared/buttons/IconButton';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { connect } from 'react-redux';
import {
  notesWorkspacesActionDeleteElement,
  notesWorkspacesActionMarkPrivateElement,
  notesWorkspacesActionUnmarkPrivateElement,
  notesWorkspacesActionUpdateElement,
} from '../../../stateManagement/actions/notesWorkspacesActions';
import { TitleEditor } from '../../../shared/features/titleEditor';
import { configActionUpdate } from '../../../stateManagement/actions/configActions';
import { push } from 'connected-react-router';
import { elementToFocusOnTypes } from '../../../constants/elementToFocusOnTypes';
import { elementToFocusOnActionReset } from '../../../stateManagement/actions/elementToFocusOnActions';
import DoubleClickWrapper from '../../../shared/DoubleClickWrapper';
import ConfirmationButton from '../../../shared/buttons/ConfirmationButton';

const WorkspaceTile = (props) => {
  const {
    workspace,
    editionMode,
    incognitoMode,
    elementToFocusOn,
    config: { selectedNotesWorkspaceId },
    notesWorkspacesActionMarkPrivateElement: markPrivate,
    notesWorkspacesActionUnmarkPrivateElement: unmarkPrivate,
    notesWorkspacesActionDeleteElement: deleteElement,
    notesWorkspacesActionUpdateElement: update,
    configActionUpdate,
    push,
    elementToFocusOnActionReset,
  } = props;

  const [workspaceEditionMode, setWorkspaceEditionMode] = useState(false);

  useEffect(() => {
    if (!editionMode) setWorkspaceEditionMode(false);
  }, [editionMode]);

  useEffect(() => {
    if (elementToFocusOn && elementToFocusOn.equals(elementToFocusOnTypes.notesWorkspaceTitle, workspace.id)) {
      setWorkspaceEditionMode(true);
      elementToFocusOnActionReset();
    }
  }, [elementToFocusOn]);

  if (!editionMode) {
    const activate = () => configActionUpdate('selectNotesWorkspace', { id: workspace.id }, () => push('/notes'));
    const isSelected = selectedNotesWorkspaceId === workspace.id;
    return (
      <Clickable onMouseDown={activate} hoverOpacity>
        <Container className="py-2 px-25px" themeType={isSelected ? 'selectedContainer' : 'container'}>
          <div
            className="h-32px flex items-center justify-center w-full font-semibold
                                    truncate"
          >
            {workspace.name}
          </div>
        </Container>
      </Clickable>
    );
  }

  const switchPrivate = () => (workspace.private ? unmarkPrivate : markPrivate)(workspace);
  const switchPrivateIconVariant = workspace.private ? 'unmarkPrivate' : 'markPrivate';

  const handleSubmit = ({ title }) => {
    const updatedWorkspace = workspace.shallowClone().assignValues({ ...workspace, name: title });
    if (workspace.name !== title) update(updatedWorkspace);
    setWorkspaceEditionMode(false);
  };

  return (
    <div className="notes-workspace-editor">
      <Container className="py-2 px-2">
        <div className="flex items-center h-32px">
          <div className="flex-grow truncate text-left pl-3 font-semibold">
            {workspaceEditionMode ? (
              <TitleEditor
                title={workspace.name}
                handleSubmit={handleSubmit}
                wrappingComponent={'div'}
                heightClass=""
                fontWeightClass="font-semibold"
              />
            ) : (
              <DoubleClickWrapper
                onDoubleClick={(e) => {
                  // the following logic is causing the element to be removed
                  // from DOM - if event is not prevented this will cause
                  // onClickOutside to be unnecessarily fired
                  e.preventDefault();
                  e.stopPropagation();
                  setWorkspaceEditionMode(true);
                }}
              >
                {workspace.name}
              </DoubleClickWrapper>
            )}
          </div>
          {workspaceEditionMode ? (
            <></>
          ) : (
            <ButtonsGroup
              className="flex justify-end items-center"
              buttons={[
                incognitoMode ? null : (
                  <IconButton key="switchPrivate" onMouseDown={switchPrivate} variant={switchPrivateIconVariant} />
                ),
                <ConfirmationButton
                  key="delete"
                  variant="delete"
                  i18nKey="notesWorkspaces.delete"
                  onConfirmed={() => deleteElement(workspace)}
                  resourceName={workspace.name}
                  textConfirmation
                />,
              ]}
            />
          )}
        </div>
      </Container>
    </div>
  );
};

export default connect(prepareState(['incognitoMode', 'config', 'elementToFocusOn']), {
  notesWorkspacesActionMarkPrivateElement,
  notesWorkspacesActionUnmarkPrivateElement,
  notesWorkspacesActionDeleteElement,
  notesWorkspacesActionUpdateElement,
  configActionUpdate,
  push,
  elementToFocusOnActionReset,
})(WorkspaceTile);
