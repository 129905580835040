import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { nodesActionSetCreatedElement, nodesActionUnlock } from '../../actions/nodesActions';
import { nodeFromDto, nodeToDto } from '../../../helpers/model/nodeHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { notificationT } from '../../../helpers/i18nHelpers';

export default function* duplicateElementNodesSaga(action) {
  const { element: initialNode } = action.payload;
  const { ok, data } = yield call(api.nodes.duplicate, nodeToDto(initialNode));
  if (ok) {
    yield put(snackbarMessagesActionAdd(notificationT('nodes.create.success')));
    yield put(nodesActionSetCreatedElement(nodeFromDto(data)));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(nodesActionUnlock());
  }
}
