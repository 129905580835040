import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { dayEvaluationsChartsDataActionSet } from '../../actions/dayEvaluationsChartsDataActions';
import { dayEvaluationsChartDataFromDto } from '../../../helpers/model/dayEvaluationsChartDataHelpers';

export default function* fetchDayEvaluationsChartsDataSaga(action) {
  const { date, variant } = action.payload;
  const { ok, data } = yield call(api.dayEvaluationsChartsData.index, { date, variant });
  if (ok) {
    yield put(dayEvaluationsChartsDataActionSet(date, variant, dayEvaluationsChartDataFromDto(data, date, variant)));
  }
}
