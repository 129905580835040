import React from 'react';
import Clickable from '../../../../Clickable';
import CustomColorCrumb from '../../../../CustomColorCrumb';
import CheckBoxCheckIcon from '../../../../../icons/checkBoxCheckIcon';
import { connect } from 'react-redux';
import { prepareState } from '../../../../../helpers/stateManagementHelpers';
import { getThemeTypeDefinition } from '../../../../../helpers/themeHelpers';

const ColorOption = (props) => {
  const {
    label,
    value,
    selectedValue,
    onSelect = (_, { closeMenu }) => closeMenu(),
    checkIfSelected = (optionValue, selectedValue) => optionValue === selectedValue,
    themeType: selectorMenuThemeType,
    theme,
    disabled,
    ...otherProps
  } = props;

  const isSelected = checkIfSelected(value, selectedValue);
  const checkboxColor = getThemeTypeDefinition(theme, selectorMenuThemeType).backgroundColor;

  return (
    <CustomColorCrumb color={value}>
      <Clickable
        className="w-6 h-6 rounded flex justify-center items-center"
        onMouseDown={() => onSelect(value, { label, previousValue: selectedValue, ...otherProps })}
        disabled={isSelected || disabled}
        hoverOpacity
        opaqueWhenDisabled={false}
      >
        {isSelected ? <CheckBoxCheckIcon className="w-3 h-3" style={{ color: checkboxColor }} /> : <></>}
      </Clickable>
    </CustomColorCrumb>
  );
};

export default connect(prepareState('theme'))(ColorOption);
