import BaseModel from './baseModel';

export default class Lockable extends BaseModel {
  constructor() {
    super();
    this.locked = false;
  }

  toLocked(createClone = true) {
    let result = this;
    if (createClone) result = this.shallowClone();
    result.locked = true;
    return result;
  }

  toUnlocked(createClone = true) {
    let result = this;
    if (createClone) result = this.shallowClone();
    result.locked = false;
    return result;
  }
}
