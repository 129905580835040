import snackbarMessages from '../../constants/snackbarMessages';
import { getUuid } from '../../helpers/identifierHelpers';
import { getSnackbarBackendErrorMessage } from '../../helpers/snackbarMessagesHelpers';
import { notificationT } from '../../helpers/i18nHelpers';

export const snackbarMessagesActionAdd = (message, type = snackbarMessages.types.default) => ({
  type: 'SNACKBAR_MESSAGES_ACTION_ADD',
  payload: { message, type, uuid: getUuid() },
});

export const snackbarMessagesActionAddDefaultError = () => ({
  type: 'SNACKBAR_MESSAGES_ACTION_ADD',
  payload: {
    message: notificationT('unexpectedError'),
    type: snackbarMessages.types.error,
    uuid: getUuid(),
  },
});

export const snackbarMessagesActionHandleBackendErrors = (errors = null, i18nKey = null) => ({
  type: 'SNACKBAR_MESSAGES_ACTION_ADD',
  payload: getSnackbarBackendErrorMessage(errors, i18nKey),
});

export const snackbarMessagesActionActivate = (uuid) => ({
  type: 'SNACKBAR_MESSAGES_ACTION_ACTIVATE',
  payload: uuid,
});

export const snackbarMessagesActionRemoveActive = (uuid) => ({
  type: 'SNACKBAR_MESSAGES_ACTION_REMOVE_ACTIVE',
  payload: uuid,
});
