import Lockable from './parents/lockable';

export default class Node extends Lockable {
  constructor() {
    super();
    this.id = null;
    this.variant = null;
    this.nodesGroupId = null;
    this.content = null;
    this.additionalContentAbove = null;
    this.additionalContentBelow = null;
    this.audioUrl = null;
    this.audioMask = null;
    this.labelsIds = [];
    this.defaultOrder = 0;
    this.private = false;
  }

  visible(incognitoMode = false) {
    return incognitoMode ? !this.private : true;
  }
}
