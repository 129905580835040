import React, { useMemo } from 'react';
import { getThemeType } from '../../../helpers/formHelpers';
import ErrorWrapper from './auxiliary/ErrorWrapper';
import Selector from '../components/selector/Selector';
import find from 'lodash/find';
import get from 'lodash/get';

const defaultOnChange = async (props) => {
  const {
    newValue,
    formikProps: {
      onBlur = () => {},
      field: { name },
      form: { setFieldValue, setFieldTouched },
    },
    additionalProps: { closeMenu },
  } = props;
  closeMenu();
  await setFieldValue(name, newValue);
  await setFieldTouched(name, true);
  await onBlur();
};

const SelectorField = (props) => {
  let {
    // should be an array of { label: ..., value: ... } objects
    options,
  } = props;
  const {
    id,
    placeholder,
    innerRef,
    themeType = 'input',
    displayError,
    fixedErrorHeight,
    handleErrorThemeType,
    handleDisabledThemeType,
    handlePresentationModeThemeType,
    disabled = false,
    presentationMode = false,
    selectedOptionOnTop = false,
    optionComponent,
    menu,
    trigger,
    leftPadding,
    fixedMenuWidth,
    onChange = (onChangeProps, _defaultOnChange) => _defaultOnChange(onChangeProps),
    onBlur = () => {},
    field: { name, value },
    form: { errors, touched, setFieldTouched },
  } = props;

  const error = get(touched, name) ? get(errors, name) : null;
  const actualThemeType = getThemeType({
    themeType,
    error,
    handleErrorThemeType,
    presentationMode,
    handlePresentationModeThemeType,
    disabled,
    handleDisabledThemeType,
  });
  const onSelect = (newValue, additionalProps) =>
    onChange(
      {
        newValue,
        formikProps: props,
        additionalProps,
      },
      defaultOnChange,
    );

  const selectedOption = useMemo(() => find(options, { value }), [options, value]);
  if (selectedOptionOnTop && selectedOption) {
    options = [selectedOption, ...options.filter((o) => o !== selectedOption)];
  }

  return (
    <ErrorWrapper displayError={displayError} fixedErrorHeight={fixedErrorHeight} error={error}>
      <Selector
        id={id}
        ref={innerRef}
        options={options}
        value={value}
        placeholder={placeholder}
        disabled={disabled || presentationMode}
        triggerProps={{
          id,
          themeType: actualThemeType,
          showArrow: !presentationMode,
          leftPadding: leftPadding,
        }}
        optionComponent={optionComponent}
        menu={menu}
        trigger={trigger}
        onSelect={onSelect}
        fixedMenuWidth={fixedMenuWidth}
        onBlur={async () => {
          await setFieldTouched(name, true);
          await onBlur();
        }}
      />
    </ErrorWrapper>
  );
};

export default SelectorField;
