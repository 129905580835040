import React from 'react';
import { frontEndErrorT, initT } from '../../../helpers/i18nHelpers';
import { customPropsToValues, produceValuesFilter } from '../../../helpers/formHelpers';
import * as yup from 'yup';
import { getVisibleCategories } from '../../../helpers/categoryHelpers';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { tasksActionCreateElement, tasksActionUpdateElement } from '../../../stateManagement/actions/tasksActions';
import { withFormik } from 'formik';
import Task from '../../../models/task';
import SelectorField from '../../form/fieldTypes/SelectorField';
import TextField from '../../form/fieldTypes/TextField';
import SmartForm from '../../form/SmartForm';
import LabelField from '../../form/LabelField';
import DateField from '../../form/fieldTypes/DateField';
import NodesGroupPanel from '../nodesGroupPanel';
import Label from '../../form/Label';
import OnClickOutsideWrapper from '../../onClickOutside/OnClickOutsideWrapper';
import { useDoubleClickToBlur } from '../../../hooks/useDoubleClickToBlur';
import StandardFormFooter from '../../form/StandardFormFooter';

const t = initT('features.taskPanel');

const relevantAttributes = ['id', 'description', 'todoFrom', 'todo', 'private', 'categoryId'];
const auxiliaryAttributes = ['locked', 'incognitoMode', 'categories', 'deactivateEditionMode', 'nodesGroupId'];
const filterValues = produceValuesFilter(relevantAttributes);

const validationSchema = () =>
  yup.object().shape({
    description: yup.string().required(frontEndErrorT('task.description.empty')),
  });

const TaskInnerForm = (props) => {
  const { values, handleSubmit } = props;
  const {
    todo,
    auxiliary: { incognitoMode, categories, deactivateEditionMode, nodesGroupId },
  } = values;

  const { onClickInside, onClickOutside, aboutToBlur } = useDoubleClickToBlur({
    onBlur: deactivateEditionMode,
  });

  const categoriesOptions = getVisibleCategories(categories, incognitoMode).map((category) => ({
    value: category.id,
    label: category.name,
  }));

  return (
    <OnClickOutsideWrapper
      onClickOutside={onClickOutside}
      onClick={onClickInside}
      className={aboutToBlur ? 'animate-inactive-pulse ' : ''}
    >
      <SmartForm className="p-25px">
        <div className="flex justify-center">
          <div className="w-full sm:w-big-golden-ratio max-w-xs">
            <LabelField name="description" label={t('description')} required component={TextField} autoFocus />
            <LabelField
              name="categoryId"
              label={t('categoryId')}
              className="pt-25px"
              required
              component={SelectorField}
              options={categoriesOptions}
              selectedOptionOnTop
            />
            <LabelField name="todoFrom" label={t('todoFrom')} className="pt-25px" hidden={todo} component={DateField} />
          </div>
        </div>
        <StandardFormFooter marginClass="mt-64px" handleSubmit={handleSubmit} handleCancel={deactivateEditionMode} />
      </SmartForm>
      <div className="pb-25px px-25px">
        <div className="pb-3">
          <Label>{t('details')}</Label>
        </div>
        <NodesGroupPanel nodesGroupId={nodesGroupId} editionMode />
      </div>
    </OnClickOutsideWrapper>
  );
};

const TaskForm = withFormik({
  validationSchema,
  enableReinitialize: true,
  mapPropsToValues: (props) => customPropsToValues(props, filterValues, auxiliaryAttributes),
  handleSubmit: (values, formikBag) => {
    const task = new Task().assignValues(filterValues(values));
    if (task.id) formikBag.props.tasksActionUpdateElement(task);
    else formikBag.props.tasksActionCreateElement(task);
    // wait for nodes to save
    setTimeout(formikBag.props.deactivateEditionMode);
  },
})(TaskInnerForm);

export default connect(prepareState(['incognitoMode', 'categories']), {
  tasksActionCreateElement,
  tasksActionUpdateElement,
})(TaskForm);
