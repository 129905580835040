import React from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../helpers/stateManagementHelpers';
import GoldenRatioMarginTopWrapper from '../../templates/StandardTemplate/utilities/GoldenRatioMarginTopWrapper';

const ProfilePage = ({ session }) => (
  <GoldenRatioMarginTopWrapper>
    <div>{session.email}</div>
    <div>{session.fullName}</div>
  </GoldenRatioMarginTopWrapper>
);

export default connect(prepareState('session'))(ProfilePage);
