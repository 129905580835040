import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../helpers/stateManagementHelpers';
import { getThemeTypeDefinition } from '../helpers/themeHelpers';
import { supportsHover } from '../helpers/platformHelpers';

const Hoverable = (props) => {
  const {
    children,
    theme,
    hoveredThemeType = 'hovered',
    hoveredAdditionalStyles = {},
    style = {},
    className = '',
    sizeClass = 'w-full',
    hoverDisabled = false,
    cursorClass = hoverDisabled ? '' : 'cursor-pointer',
    otherProps,
  } = props;

  const [hovered, setHovered] = useState(false);

  const hoverStyles = useMemo(
    () => ({
      ...getThemeTypeDefinition(theme, hoveredThemeType),
      ...hoveredAdditionalStyles,
    }),
    [theme, hoveredThemeType, hoveredAdditionalStyles],
  );

  const newStyle =
    supportsHover() && hovered && !hoverDisabled
      ? {
          ...style,
          ...hoverStyles,
        }
      : style;

  return (
    // flex necessary to maintain height
    <div
      {...otherProps}
      className={`flex ${className} ${sizeClass} ${cursorClass}`}
      style={newStyle}
      onMouseOver={() => {
        if (!hovered && !window.draggingActive) setHovered(true);
        else if (hovered && window.draggingActive) setHovered(false);
      }}
      onMouseLeave={() => {
        if (hovered) setHovered(false);
      }}
    >
      {children}
    </div>
  );
};

export default connect(prepareState('theme'))(Hoverable);
