import React from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../helpers/stateManagementHelpers';
import { getAllHabitsSections } from '../../helpers/habitsSectionHelpers';
import HabitAnswerForm from './HabitAnswerForm';
import { getHiddenHabitsAndAnswersForSection } from '../../helpers/habitAnswerHelpers';
import compact from 'lodash/compact';
import { initT } from '../../helpers/i18nHelpers';
import SectionTitle from '../../shared/titles/SectionTitle';
import NoData from '../../shared/placeholders/NoData';

const t = initT('pages.calendar');

const HiddenHabitsSection = (props) => {
  const { locked, date, habitsSections, habits, habitAnswers, incognitoMode } = props;

  const sections = getAllHabitsSections(habitsSections, incognitoMode);
  const habitsAndAnswersPerSection = compact(
    sections.map((section) => {
      const habitsAndAnswers = getHiddenHabitsAndAnswersForSection(
        section,
        habits,
        habitAnswers,
        date,
        incognitoMode,
        locked,
      );
      if (habitsAndAnswers.length === 0) return false;
      return {
        section,
        habitsAndAnswers,
      };
    }),
  );

  return (
    <div>
      <SectionTitle>{t('hiddenHabits.title')}</SectionTitle>
      {habitsAndAnswersPerSection.length === 0 ? <NoData /> : <></>}
      {habitsAndAnswersPerSection.map(({ section, habitsAndAnswers }) =>
        habitsAndAnswers.map(({ answer, habit }) => (
          <HabitAnswerForm key={answer.id} habit={habit} calendarLocked={locked} section={section} {...answer} />
        )),
      )}
    </div>
  );
};

export default connect(prepareState(['habitsSections', 'habits', 'habitAnswers', 'incognitoMode']))(
  HiddenHabitsSection,
);
