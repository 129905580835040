import React, { useMemo, useCallback, useState } from 'react';
import QueueRunAudioController from '../QueueRunAudioController';
import ChunkDisplay from '../ChunkDisplay';
import { useKeydown } from './useKeydown';

const OverviewVariant = (props) => {
  const { next, node, queue = {} } = props;
  const [audioPaused, setAudioPaused] = useState(false);
  const nodeWithAudio = useMemo(() => !!(queue.withAudio && node && node.audioUrl), [queue, node]);
  const contentOnMouseDown = useCallback(() => {
    if (nodeWithAudio && !audioPaused) setAudioPaused(true);
    else next();
  }, [next, nodeWithAudio, audioPaused, setAudioPaused]);
  const onAudioFinish = useCallback(() => {
    setAudioPaused(true);
  }, [setAudioPaused]);

  useKeydown({ ...props, nodeWithAudio, audioPaused, setAudioPaused });

  return (
    <>
      <ChunkDisplay
        {...props}
        contentOnMouseDown={contentOnMouseDown}
        className={audioPaused ? '' : 'select-none'}
        renderAdditionalContentBelow={(ready) =>
          ready && nodeWithAudio ? (
            <QueueRunAudioController
              src={node.audioUrl}
              mask={node.audioMask}
              audioPaused={audioPaused}
              onFinish={onAudioFinish}
            />
          ) : (
            <></>
          )
        }
      />
    </>
  );
};

export default OverviewVariant;
