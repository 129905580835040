import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { notificationT } from '../../../helpers/i18nHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { eventsActionUnset, eventsActionUnlockElement } from '../../actions/eventsActions';

export default function* deleteElementEventsSaga(action) {
  const { element: initialEvent } = action.payload;
  const { ok, data } = yield call(api.events.delete, initialEvent.id);
  if (ok) {
    yield put(snackbarMessagesActionAdd(notificationT('events.delete.success')));
    yield put(eventsActionUnset(initialEvent));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(eventsActionUnlockElement(initialEvent));
  }
}
