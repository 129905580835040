import React from 'react';
import ErrorWrapper from './auxiliary/ErrorWrapper';
import { generateTagId } from '../../../helpers/identifierHelpers';
import { getThemeType } from '../../../helpers/formHelpers';
import CheckIndicator from '../../CheckIndicator';
import get from 'lodash/get';

const getBooleanValue = (value) => (typeof value === 'string' ? ['true', 't'].includes(value) : value);

const defaultOnChange = async (props) => {
  const {
    onBlur = () => {},
    field: { value, name },
    form: { setFieldValue, setFieldTouched },
  } = props;
  await setFieldValue(name, !getBooleanValue(value));
  await setFieldTouched(name);
  await onBlur();
};

const CheckboxField = (props) => {
  const {
    id,
    label = '',
    innerRef,
    themeType = 'input',
    displayError,
    fixedErrorHeight,
    handleErrorThemeType,
    handleDisabledThemeType,
    handlePresentationModeThemeType,
    centered = false,
    disabled = false,
    presentationMode = false,
    onChange = (onChangeProps, _defaultOnChange) => _defaultOnChange(onChangeProps),
    // eslint-disable-next-line no-unused-vars
    onBlur = () => {},
    field: { name, value },
    form: { errors, touched },
  } = props;

  const error = get(touched, name) ? get(errors, name) : null;
  const actualThemeType = getThemeType({
    themeType,
    error,
    handleErrorThemeType,
    presentationMode,
    handlePresentationModeThemeType,
    disabled,
    handleDisabledThemeType,
  });
  const innerId = generateTagId();
  const booleanValue = getBooleanValue(value);
  const changesDisabled = disabled || presentationMode;

  return (
    <>
      <input
        id={innerId}
        hidden
        type="checkbox"
        checked={booleanValue}
        onChange={() => onChange(props, defaultOnChange)}
        disabled={changesDisabled}
      />
      <ErrorWrapper displayError={displayError} fixedErrorHeight={fixedErrorHeight} error={error}>
        <label
          id={id}
          ref={innerRef}
          htmlFor={innerId}
          className={`flex items-center ${changesDisabled ? '' : 'cursor-pointer'}
                                   ${centered ? 'w-full justify-center' : ''}`}
        >
          <CheckIndicator theme={actualThemeType} checked={booleanValue} />
          {label ? <div className="ml-2 text-left">{label}</div> : null}
        </label>
      </ErrorWrapper>
    </>
  );
};

export default CheckboxField;
