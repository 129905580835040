import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { categoriesActionSetCreatedElement, categoriesActionUnlock } from '../../actions/categoriesActions';
import { categoryFromDto, categoryToDto } from '../../../helpers/model/categoryHelpers';
import { tasksActionFetch } from '../../actions/tasksActions';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { notificationT } from '../../../helpers/i18nHelpers';
import { push } from 'connected-react-router';
import { elementToFocusOnActionSet } from '../../actions/elementToFocusOnActions';
import { elementToFocusOnTypes } from '../../../constants/elementToFocusOnTypes';

export default function* createElementCategoriesSaga(action) {
  const { ok, data } = yield call(api.categories.create, categoryToDto(action.payload));
  if (ok) {
    const newCategory = categoryFromDto(data);
    yield put(snackbarMessagesActionAdd(notificationT('categories.create.success')));
    yield put(categoriesActionSetCreatedElement(newCategory));
    yield put(push(`/tasks/${newCategory.id}`));
    yield put(tasksActionFetch(newCategory.id));
    yield put(elementToFocusOnActionSet(elementToFocusOnTypes.categoryTitle, newCategory.id));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data, 'categories.create.errors'));
    yield put(categoriesActionUnlock());
  }
}
