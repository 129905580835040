import frenchkiss from 'frenchkiss';

frenchkiss.extend('en', {
  notifications: {
    session: {
      signIn: {
        success: 'Successfully signed in!',
      },
      signOut: {
        success: 'Successfully signed out!',
      },
    },
    incognitoMode: {
      enable: {
        success: 'All private items are now hidden!',
      },
      disable: {
        success: 'All private items are now accessible!',
      },
    },
    categories: {
      create: {
        success: 'Category created!',
        errors: {
          name: {
            taken: 'Name must be unique',
          },
        },
      },
      delete: {
        success: 'Category deleted!',
      },
      update: {
        success: 'Category updated!',
        errors: {
          name: {
            taken: 'Name must be unique',
          },
        },
      },
    },
    tasks: {
      create: {
        success: 'Task created!',
      },
      update: {
        success: 'Task updated!',
      },
      delete: {
        success: 'Task deleted!',
      },
      check: {
        success: 'Task done!',
      },
    },
    recurringTasks: {
      create: {
        success: 'Recurring task created!',
      },
      delete: {
        success: 'Recurring task deleted!',
      },
      update: {
        success: 'Recurring task updated!',
      },
    },
    habitsSections: {
      create: {
        success: 'Section created!',
        errors: {
          name: {
            taken: 'Name must be unique',
          },
        },
      },
      delete: {
        success: 'Section deleted!',
      },
      update: {
        success: 'Section updated!',
        errors: {
          name: {
            taken: 'Name must be unique',
          },
        },
      },
    },
    habits: {
      create: {
        success: 'Habit created!',
        errors: {
          name: {
            taken: 'Name must be unique',
          },
        },
      },
      delete: {
        success: 'Habit deleted!',
      },
      update: {
        success: 'Habit updated!',
        errors: {
          name: {
            taken: 'Name must be unique',
          },
        },
      },
    },
    nodes: {
      create: {
        success: 'Node created!',
      },
      delete: {
        success: 'Node deleted!',
      },
    },
    notesWorkspaces: {
      create: {
        success: 'Workspace created!',
        errors: {
          name: {
            taken: 'Name must be unique',
          },
        },
      },
      delete: {
        success: 'Workspace deleted!',
      },
      update: {
        success: 'Workspace updated!',
        errors: {
          name: {
            taken: 'Name must be unique',
          },
        },
      },
    },
    notes: {
      create: {
        success: 'Note created!',
      },
      update: {
        success: 'Note updated!',
      },
      delete: {
        success: 'Note deleted!',
      },
    },
    events: {
      create: {
        success: 'Event created!',
      },
      update: {
        success: 'Event updated!',
      },
      delete: {
        success: 'Event deleted!',
      },
    },
    recurringEvents: {
      create: {
        success: 'Recurring event created!',
      },
      delete: {
        success: 'Recurring event deleted!',
      },
      update: {
        success: 'Recurring event updated!',
      },
    },
    learningWorkspaces: {
      create: {
        success: 'Workspace created!',
        errors: {
          name: {
            taken: 'Name must be unique',
          },
        },
      },
      delete: {
        success: 'Workspace deleted!',
      },
      update: {
        success: 'Workspace updated!',
        errors: {
          name: {
            taken: 'Name must be unique',
          },
        },
      },
    },
    learningQueues: {
      create: {
        success: 'Learning queue created!',
      },
      update: {
        success: 'Learning queue updated!',
      },
      delete: {
        success: 'Learning queue deleted!',
      },
    },
    languageWorkspaces: {
      create: {
        success: 'Workspace created!',
        errors: {
          name: {
            taken: 'Name must be unique',
          },
        },
      },
      delete: {
        success: 'Workspace deleted!',
      },
      update: {
        success: 'Workspace updated!',
        errors: {
          name: {
            taken: 'Name must be unique',
          },
        },
      },
    },
    wastes: {
      restore: {
        success: 'Item restored!',
      },
      delete: {
        success: 'Item deleted permanently!',
      },
    },
    unexpectedError: 'Something went wrong',
  },
});
