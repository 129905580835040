import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { snackbarMessagesActionHandleBackendErrors } from '../../actions/snackbarMessagesActions';
import { recurringTaskFromDto, recurringTaskToDto } from '../../../helpers/model/recurringTaskHelpers';
import { recurringTasksActionSetElement, recurringTasksActionUnlockElement } from '../../actions/recurringTasksActions';

export default function* unmarkPrivateElementRecurringTasksSaga(action) {
  const initialRecurringTask = action.payload;
  const { ok, data } = yield call(api.recurringTasks.unmarkPrivate, recurringTaskToDto(initialRecurringTask));
  if (ok) {
    yield put(recurringTasksActionSetElement(recurringTaskFromDto(data)));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(recurringTasksActionUnlockElement(initialRecurringTask));
  }
}
