import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { prepareCollection } from '../../../helpers/model/modelHelpers';
import { languageWorkspacesActionSet } from '../../actions/languageWorkspacesActions';
import { languageWorkspaceFromDto } from '../../../helpers/model/languageWorkspacesHelpers';

export default function* fetchLanguageWorkspacesSaga(action) {
  const { ok, data } = yield call(api.languageWorkspaces.index, action.payload);
  if (ok) {
    yield put(languageWorkspacesActionSet(prepareCollection(data, languageWorkspaceFromDto)));
  }
}
