import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { snackbarMessagesActionHandleBackendErrors } from '../../actions/snackbarMessagesActions';
import { habitFromDto, habitToDto } from '../../../helpers/model/habitsHelpers';
import { habitsActionSetElement, habitsActionUnlockElement } from '../../actions/habitActions';

export default function* unmarkHiddenElementHabitsSaga(action) {
  const initialHabit = action.payload;
  const { ok, data } = yield call(api.habits.unmarkHidden, habitToDto(initialHabit));
  if (ok) {
    yield put(habitsActionSetElement(habitFromDto(data)));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(habitsActionUnlockElement(initialHabit));
  }
}
