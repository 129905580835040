import React from 'react';
import WasteItem from './WasteItem';
import MainTopSpace from '../../../shared/organizers/MainTopSpace';
import useNarrowMainWidth from '../../../hooks/useNarrowMainWidth';
import List from '../../../shared/lists/List';

const Main = (props) => {
  const { wastes } = props;

  const width = useNarrowMainWidth();

  return (
    <div>
      <MainTopSpace />
      <div className="w-full flex justify-center">
        <div style={{ width }}>
          <List collection={wastes} className="mb-25px">
            {(waste) => (
              <div key={waste.id} className="pb-4 last:pb-0">
                <WasteItem item={waste} />
              </div>
            )}
          </List>
        </div>
      </div>
    </div>
  );
};

export default Main;
