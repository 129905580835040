import { produceModelFromDto, produceModelToDto } from './modelHelpers';
import RecurringTask from '../../models/recurringTask';

const mappings = [
  ['id', 'id'],
  ['category_id', 'categoryId'],
  ['nodes_group_id', 'nodesGroupId'],
  ['name', 'name'],
  ['generation_active', 'generationActive'],
  ['start_date', 'startDate'],
  ['variant', 'variant', 'enum'],
  ['weekday_type', 'weekdayType', 'enum'],
  ['month_day', 'monthDay'],
  ['month_type', 'monthType', 'enum'],
  ['year_day', 'yearDay'],
  ['days_count', 'daysCount'],
  ['status', 'status'],
  ['next_generation_date', 'nextGenerationDate'],
  ['default_order', 'defaultOrder'],
  ['private', 'private'],
];

export const recurringTaskFromDto = produceModelFromDto(RecurringTask, mappings);

export const recurringTaskToDto = produceModelToDto(mappings);
