import React from 'react';
import ThemeCrumb from '../../../../../ThemeCrumb';

const getHighlightedCaret = (highlightedCaretRef, themeType = 'highlightedText') => (
  <span className="relative animate-bounce pr-3px">
    <ThemeCrumb type={themeType}>
      <span className="absolute left-0 -top-1.5 text-lg animate-cursor" ref={highlightedCaretRef}>
        |
      </span>
    </ThemeCrumb>
  </span>
);

export const produceRenderLeaf =
  (highlightedCaretRef) =>
  ({ leaf, children, attributes }) => {
    const { text } = children.props.text;
    let el = <>{children}</>;

    if (leaf.bold) {
      el = <strong>{el}</strong>;
    }
    if (leaf.italic) {
      el = <em>{el}</em>;
    }
    if (leaf.underline) {
      el = <u>{el}</u>;
    }
    if (leaf.strikethrough) {
      el = <s>{el}</s>;
    }

    if (leaf.highlightedCaretBefore && text?.length > 0) {
      el = (
        <>
          {getHighlightedCaret(highlightedCaretRef)}
          {el}
        </>
      );
    } else if (leaf.highlighted) {
      el = (
        <>
          <ThemeCrumb type="highlightedText">
            <span>{el}</span>
          </ThemeCrumb>
          {leaf.highlightedCaretAfter && text?.length > 0 && getHighlightedCaret(highlightedCaretRef)}
        </>
      );
    }

    if (leaf.code && text.length > 0) {
      el = (
        <ThemeCrumb type="code">
          <code>{el}</code>
        </ThemeCrumb>
      );
    }

    if (leaf.highlightedError) {
      el = (
        <>
          <ThemeCrumb type="highlightedTextError">
            <span>{el}</span>
          </ThemeCrumb>
          {leaf.lastHighlightedError && getHighlightedCaret(highlightedCaretRef, 'highlightedTexErrorCaret')}
        </>
      );
    }

    return <span {...attributes}>{el}</span>;
  };
