window.windowPopupOpenListeners = {};
window.windowPopupCloseListeners = {};

window.nativeAlert = window.alert;
window.alert = (message) => {
  Object.values(window.windowPopupOpenListeners).forEach((func) => func({ message, variant: 'alert' }));
  window.nativeAlert(message);
  Object.values(window.windowPopupCloseListeners).forEach((func) => func({ message, variant: 'alert' }));
};

window.nativeConfirm = window.confirm;
window.confirm = (message) => {
  Object.values(window.windowPopupOpenListeners).forEach((func) => func({ message, variant: 'confirm' }));
  const result = window.nativeConfirm(message);
  Object.values(window.windowPopupCloseListeners).forEach((func) => func({ message, result, variant: 'confirm' }));
  return result;
};

window.nativePrompt = window.prompt;
window.prompt = (message, _default) => {
  Object.values(window.windowPopupOpenListeners).forEach((func) => func({ message, _default, variant: 'prompt' }));
  const result = window.nativePrompt(message);
  Object.values(window.windowPopupCloseListeners).forEach((func) =>
    func({ message, _default, result, variant: 'prompt' }),
  );
  return result;
};

window.addWindowPopupOpenListener = (id, func) => {
  window.windowPopupOpenListeners[id] = func;
};

window.removeWindowPopupOpenListener = (id) => {
  delete window.windowPopupOpenListeners[id];
};

window.addWindowPopupCloseListener = (id, func) => {
  window.windowPopupCloseListeners[id] = func;
};

window.removeWindowPopupCloseListener = (id) => {
  delete window.windowPopupCloseListeners[id];
};
