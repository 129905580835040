import { produceModelFromDto, produceModelToDto } from './modelHelpers';
import LearningWorkspace from '../../models/learningWorkspace';

const mappings = [
  ['id', 'id'],
  ['name', 'name'],
  ['default_order', 'defaultOrder'],
  ['private', 'private'],
];

export const learningWorkspaceFromDto = produceModelFromDto(LearningWorkspace, mappings);

export const learningWorkspaceToDto = produceModelToDto(mappings);
