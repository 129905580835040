import React from 'react';
import WholePageMessage from '../../../shared/titles/WholePageMessage';
import LabelledButton from '../../../shared/buttons/LabelledButton';
import { initT } from '../../../helpers/i18nHelpers';

const t = initT('pages.learning.queueRun');

const EndedPage = (props) => {
  const { queueRunToolkit } = props;
  const { reset } = queueRunToolkit;
  return (
    <div className="select-none">
      <WholePageMessage>
        <div>{t('ended')}</div>
        <div className="mt-25px w-full flex justify-center text-base">
          <LabelledButton key="reset" variant="reset" onMouseDown={reset}>
            {t('restart')}
          </LabelledButton>
        </div>
      </WholePageMessage>
    </div>
  );
};

export default EndedPage;
