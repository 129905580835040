import { produceModelFromDto, produceModelToDto } from './modelHelpers';
import HabitAnswer from '../../models/habitAnswer';
import { habitSummaryFromDto, habitSummaryToDto } from './habitSummariesHelpers';

const mappings = [
  ['id', 'id'],
  ['hidden', 'hidden'],
  ['answer', 'answer'],
  ['date', 'date'],
  ['was_updated', 'wasUpdated'],
  ['habit_id', 'habitId'],
  ['habit_summaries', 'habitSummaries', 'association', { fromDto: habitSummaryFromDto, toDto: habitSummaryToDto }],
];

export const habitAnswerFromDto = produceModelFromDto(HabitAnswer, mappings);

export const habitAnswerToDto = produceModelToDto(mappings);
