import React from 'react';

const CenteredTemplate = ({ children }) => (
  <div
    className="absolute m-auto inset-0 h-96 w-96 flex justify-center
                    items-center max-w-fit-content"
  >
    {children}
  </div>
);

export default CenteredTemplate;
