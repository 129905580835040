import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import {
  habitAnswersActionReset,
  habitAnswersActionSetElement,
  habitAnswersActionUnlockElement,
} from '../../actions/habitAnswersActions';
import { habitAnswerFromDto, habitAnswerToDto } from '../../../helpers/model/habitAnswersHelpers';
import { snackbarMessagesActionHandleBackendErrors } from '../../actions/snackbarMessagesActions';
import { habitChartsDataActionFetch } from '../../actions/habitChartsDataActions';

export default function* updateElementHabitAnswersSaga(action) {
  const { element: initialHabitAnswer } = action.payload;
  const { ok, data } = yield call(api.habitAnswers.update, habitAnswerToDto(initialHabitAnswer));
  if (ok) {
    const newHabitAnswer = habitAnswerFromDto(data);
    yield put(habitAnswersActionReset(newHabitAnswer.date));
    yield put(habitAnswersActionSetElement(newHabitAnswer));
    yield put(habitChartsDataActionFetch(newHabitAnswer.date, newHabitAnswer.habitId));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(habitAnswersActionUnlockElement(initialHabitAnswer));
  }
}
