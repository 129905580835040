import React from 'react';
import { connect } from 'react-redux';
import MenuContent from '../../../shared/organizers/MenuContent';
import SidebarButton from '../../../shared/buttons/SidebarButton';
import { useParams } from 'react-router-dom';
import { otherSectionsNames } from '../../../constants/otherSectionsNames';
import { initT } from '../../../helpers/i18nHelpers';
import MenuTitle from '../../../shared/titles/MenuTitle';
import LabelledButton from '../../../shared/buttons/LabelledButton';
import { prepareState } from '../../../helpers/stateManagementHelpers';

const t = initT('pages.other');

const Left = (props) => {
  const { incognitoMode } = props;
  const { otherSectionName } = useParams();

  return (
    <MenuContent
      top={<MenuTitle>{t('menu.title')}</MenuTitle>}
      main={
        <div className="px-25px">
          {Object.keys(otherSectionsNames).map((key) => {
            const value = otherSectionsNames[key];
            return (
              <SidebarButton key={key} selected={value === otherSectionName} to={`/other/${value}`}>
                {t(`${key}.sectionName`)}
              </SidebarButton>
            );
          })}
        </div>
      }
      bottom={
        !incognitoMode && (
          <div className="w-full flex justify-center p-25px">
            <LabelledButton variant="delete" preventContextMenu to={`/waste-bin`}>
              {t('menu.wasteBin')}
            </LabelledButton>
          </div>
        )
      }
    />
  );
};

export default connect(prepareState(['incognitoMode']))(Left);
