import React from 'react';
import Navbar from './navbar/Navbar';

const StandardTemplate = ({ children }) => (
  <>
    <Navbar />
    {children}
  </>
);

export default StandardTemplate;
