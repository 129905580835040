import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { wastesActionUnlockElement, wastesActionUnset, wastesActionResetAllExcept } from '../../actions/wastesActions';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { notificationT } from '../../../helpers/i18nHelpers';
import { globalActionReset } from '../../actions/globalActions';

export default function* restoreElementWastesSaga(action) {
  const { element: initialWaste } = action.payload;
  const { ok, data } = yield call(api.wastes.restore, initialWaste);
  if (ok) {
    yield put(snackbarMessagesActionAdd(notificationT('wastes.restore.success')));
    yield put(globalActionReset({ skipSessionConfig: true, skipWastes: true }));
    yield put(wastesActionResetAllExcept(initialWaste.modelName));
    yield put(wastesActionUnset(initialWaste));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(wastesActionUnlockElement(initialWaste));
  }
}
