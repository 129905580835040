import React, { useCallback } from 'react';
import Label from '../../../../shared/form/Label';
import FormikFieldSub from '../../../../shared/form/FormikFieldSub';
import NumberField from '../../../../shared/form/fieldTypes/NumberField';
import LabelledButton from '../../../../shared/buttons/LabelledButton';
import { initT } from '../../../../helpers/i18nHelpers';

const t = initT('pages.learning.queueRun');

const limitMinValue = 1;

const LimitForm = (props) => {
  const { chunksCount, limit, setLimit, generatePdf } = props;

  const pruneLimit = useCallback(() => {
    setLimit(Math.min(chunksCount, Math.max(limitMinValue, Math.round(limit))));
  }, [limit, chunksCount]);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      generatePdf();
    },
    [limit],
  );

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <form className="w-full max-w-sm" onSubmit={onSubmit}>
        <Label htmlFor="pdf-queue-run-limit" required>
          {t('chunksLimit', { max: chunksCount })}
        </Label>
        <FormikFieldSub
          id="pdf-queue-run-limit"
          component={NumberField}
          value={limit || ''}
          onChange={({ event }) => setLimit(event.target.value)}
          onBlur={pruneLimit}
          autoFocus
          min={limitMinValue}
          max={chunksCount}
          step="1"
        />
        <div className="pt-25px w-full flex justify-center">
          <LabelledButton variant="pdf" onMouseDown={onSubmit}>
            {t('generatePdf')}
          </LabelledButton>
        </div>
      </form>
    </div>
  );
};

export default LimitForm;
