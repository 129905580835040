import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { taskFromDto } from '../../../helpers/model/taskHelpers';
import { tasksActionSetElement } from '../../actions/tasksActions';

export default function* generatedLookupTasksSaga(action) {
  const { date } = action.payload;
  const { ok, data } = yield call(api.tasks.generatedLookup, { date });
  if (ok) {
    for (const newTask of data) {
      yield put(tasksActionSetElement(taskFromDto(newTask)));
    }
  }
}
