import React, { useState } from 'react';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { connect } from 'react-redux';
import {
  habitsActionDeleteElement,
  habitsActionMarkHiddenElement,
  habitsActionMarkPrivateElement,
  habitsActionUnmarkHiddenElement,
  habitsActionUnmarkPrivateElement,
} from '../../../stateManagement/actions/habitActions';
import { activeElementTypes } from '../../../constants/activeElementTypes';
import ActiveElement from '../../../models/auxiliary/activeElement';
import ActivableContainer from '../../../shared/organizers/containers/ActivableContainer';
import HabitHeader from './HabitHeader';
import HabitForm from './HabitForm';
import useNarrowMainWidth from '../../../hooks/useNarrowMainWidth';

const HabitTile = (props) => {
  const {
    habit,
    draggableListRef,
    incognitoMode,
    habitsActionDeleteElement,
    habitsActionMarkPrivateElement: markPrivate,
    habitsActionUnmarkPrivateElement: unmarkPrivate,
    habitsActionMarkHiddenElement: markHidden,
    habitsActionUnmarkHiddenElement: unmarkHidden,
  } = props;

  const [editionMode, setEditionMode] = useState(false);

  const switchPrivate = () => (habit.private ? unmarkPrivate(habit) : markPrivate(habit));
  const switchHidden = () => (habit.hidden ? unmarkHidden(habit) : markHidden(habit));
  const deleteElement = () => habitsActionDeleteElement(habit);

  const getHabitHeaderParams = ({ activateContainer }) => ({
    habit,
    incognitoMode,
    switchPrivate,
    switchHidden,
    deleteElement,
    activateEditionMode: () => {
      activateContainer();
      setEditionMode(true);
      draggableListRef.current.disableDragging();
    },
  });
  const mixinType = editionMode ? false : `${habit.variant}HabitDenotation`;

  const width = useNarrowMainWidth();
  const activeWidth = useNarrowMainWidth(false);

  return (
    <ActivableContainer
      activeElement={new ActiveElement(activeElementTypes.habit, habit.id)}
      themeType="container"
      themeCrumbProps={{
        mixinType,
        mixedStyleKeysMultipliers: {
          borderColor: 0.2,
        },
      }}
      activeThemeCrumbProps={{
        mixinType,
        mixedStyleKeysMultipliers: {
          borderColor: 0.2,
        },
      }}
      afterActivityChange={() => {
        if (!editionMode) draggableListRef.current.reinitialize();
      }}
      loading={habit.locked}
      width={width}
      activeWidth={activeWidth}
      className={'transition-all'}
      renderInactive={(containerProps) => <HabitHeader {...getHabitHeaderParams(containerProps)} {...containerProps} />}
      renderActive={(containerProps) => (
        <HabitForm
          {...habit}
          key={`${editionMode}-${JSON.stringify(habit)}`}
          habitHeaderParams={{
            ...getHabitHeaderParams(containerProps),
            ...containerProps,
          }}
          editionMode={editionMode}
          deactivateEditionMode={() => {
            setEditionMode(false);
            draggableListRef.current.enableDragging();
          }}
          deactivateContainer={containerProps.deactivateContainer}
        />
      )}
    />
  );
};

export default connect(prepareState(['incognitoMode']), {
  habitsActionDeleteElement,
  habitsActionMarkPrivateElement,
  habitsActionUnmarkPrivateElement,
  habitsActionMarkHiddenElement,
  habitsActionUnmarkHiddenElement,
})(HabitTile);
