import frenchkiss from 'frenchkiss';

frenchkiss.extend('en', {
  pages: {
    error: {
      default: {
        message: 'Something went wrong',
      },
    },
  },
});
