import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { prepareCollection } from '../../../helpers/model/modelHelpers';
import { habitsSectionsActionSet } from '../../actions/habitsSectionsActions';
import { habitsSectionFromDto } from '../../../helpers/model/habitsSectionHelpers';

export default function* fetchHabitsSectionsSaga(action) {
  const { ok, data } = yield call(api.habitsSections.index, action.payload);
  if (ok) {
    yield put(habitsSectionsActionSet(prepareCollection(data, habitsSectionFromDto)));
  }
}
