import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { notificationT } from '../../../helpers/i18nHelpers';
import { activeElementsActionActivateIfOtherInactive } from '../../actions/activeElementsActions';
import { activeElementTypes } from '../../../constants/activeElementTypes';
import {
  recurringEventsActionSetElement,
  recurringEventsActionUnlockElement,
} from '../../actions/recurringEventsActions';
import { recurringEventFromDto, recurringEventToDto } from '../../../helpers/model/recurringEventHelpers';

export default function* updateElementRecurringEventsSaga(action) {
  const { recurringEvent: initialRecurringEvent, activateUpdated } = action.payload;
  const { ok, data } = yield call(api.recurringEvents.update, recurringEventToDto(initialRecurringEvent));
  if (ok) {
    const updatedRecurringEvent = recurringEventFromDto(data);
    yield put(snackbarMessagesActionAdd(notificationT('recurringEvents.update.success')));
    yield put(recurringEventsActionSetElement(updatedRecurringEvent));
    if (activateUpdated) {
      yield put(
        activeElementsActionActivateIfOtherInactive(activeElementTypes.recurringEvent, updatedRecurringEvent.id),
      );
    }
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(recurringEventsActionUnlockElement(initialRecurringEvent));
  }
}
