import React, { useCallback, useMemo } from 'react';
import ButtonsGroup from '../../../shared/buttons/ButtonsGroup';
import H1 from '../../../shared/titles/H1';
import CenteredContent from '../../../shared/organizers/CenteredContent';
import IconButton from '../../../shared/buttons/IconButton';
import { initT } from '../../../helpers/i18nHelpers';
import {
  learningQueueIterableVariants,
  learningQueueVariants,
  learningQueuePausableVariants,
} from '../../../constants/enums/learningQueueVariants';
import { connect } from 'react-redux';
import {
  learningQueuesActionSetElement,
  learningQueuesActionUpdateElement,
} from '../../../stateManagement/actions/learningQueuesActions';

const t = initT('pages.learning.queueRun');

const QueueRunHeader = (props) => {
  const { queue, queueRunToolkit, setRunMode, learningQueuesActionSetElement, learningQueuesActionUpdateElement } =
    props;

  const { next, back, backEnabled, reset, pauseEnabled, paused, pause, resume, nextEnabled, loading, ended, empty } =
    queueRunToolkit;

  const iterableVariant = useMemo(() => learningQueueIterableVariants.includes(queue.variant), [queue]);
  const pausableVariant = useMemo(() => learningQueuePausableVariants.includes(queue.variant), [queue]);

  const toggleWithAudioEnabled = useMemo(
    () => [learningQueueVariants.overview, learningQueueVariants.typing].includes(queue.variant),
    [],
  );

  const toggleWithAudio = useCallback(() => {
    if (queue.locked || !toggleWithAudioEnabled) return;

    const newQueue = queue.shallowClone().assignValues({
      ...queue,
      withAudio: !queue.withAudio,
    });
    // need to set element instantly to make sure audio stops if necessary
    learningQueuesActionSetElement(newQueue);
    learningQueuesActionUpdateElement(newQueue);
  }, [queue, toggleWithAudioEnabled]);

  return (
    <CenteredContent
      heightPx={40}
      left={
        <ButtonsGroup
          className="flex px-2 sm:px-25px"
          buttons={[
            iterableVariant && !ended && !empty ? (
              <IconButton
                key="back"
                variant="arrowLeft"
                disabled={!backEnabled}
                onMouseDown={back}
                tooltip={t('back')}
              />
            ) : null,
            pausableVariant && !ended && !empty ? (
              <IconButton
                key={paused ? 'play' : 'pause'}
                variant={paused ? 'play' : 'pause'}
                disabled={!pauseEnabled}
                onMouseDown={paused ? resume : pause}
                tooltip={t(paused ? 'resume' : 'pause')}
              />
            ) : null,
            iterableVariant && !ended && !empty ? (
              <IconButton
                key="next"
                variant="arrowRight"
                disabled={!nextEnabled}
                onMouseDown={next}
                tooltip={t('next')}
              />
            ) : null,
          ]}
        />
      }
      main={<H1>{queue.name}</H1>}
      right={
        <ButtonsGroup
          className="flex px-2 sm:px-25px"
          buttons={[
            toggleWithAudioEnabled ? (
              <IconButton
                key="toggleWithAudio"
                variant="audio"
                disabled={queue.locked}
                onMouseDown={toggleWithAudio}
                tooltip={t(queue.withAudio ? 'disableWithAudio' : 'enableWithAudio')}
                themeType={queue.withAudio ? 'button' : 'buttonFaded'}
              />
            ) : null,
            iterableVariant && !empty ? (
              <IconButton key="reset" variant="reset" onMouseDown={reset} disabled={loading} tooltip={t('restart')} />
            ) : null,
            <IconButton key="exit" variant="exit" onMouseDown={() => setRunMode(false)} tooltip={t('exitRunMode')} />,
          ]}
        />
      }
    />
  );
};

export default connect(null, {
  learningQueuesActionSetElement,
  learningQueuesActionUpdateElement,
})(QueueRunHeader);
