import React, { useRef } from 'react';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { sortBy } from 'lodash';
import { connect } from 'react-redux';
import { draggedElements } from '../../../constants/draggedElements';
import { activeElementsActionActivate } from '../../../stateManagement/actions/activeElementsActions';
import HabitTile from './HabitTile';
import List from '../../../shared/lists/List';

const Habits = (props) => {
  const { section, habits, incognitoMode, additionMode } = props;
  const draggableListRef = useRef(null);
  const visibleHabits = sortBy(habits.visible(incognitoMode, { habitsSectionId: section.id }), 'defaultOrder');
  const listEmpty = visibleHabits.length === 0;

  return (
    <>
      <List
        collection={visibleHabits}
        draggable={{ ref: draggableListRef, draggedElementType: draggedElements.habit }}
        noDataCondition={additionMode ? false : listEmpty}
        className={additionMode && !listEmpty ? 'mt-4' : ''}
      >
        {(habit) => (
          <div key={habit.id} className="mb-4 last:mb-0 flex w-full justify-center">
            <HabitTile habit={habit} section={section} draggableListRef={draggableListRef} />
          </div>
        )}
      </List>
    </>
  );
};

export default connect(prepareState(['habits', 'incognitoMode']), { activeElementsActionActivate })(Habits);
