import { put, select } from 'redux-saga/effects';
import { flatten } from 'lodash';
import { notesActionUnset } from '../../actions/notesActions';

export default function* unsetNotesWorkspacesSaga(action) {
  const workspaces = flatten([action.payload]);
  const ids = workspaces.map((workspace) => workspace.id);

  const relatedNotes = yield select((state) => state.notes.filter((note) => ids.includes(note.notesWorkspaceId)));

  if (relatedNotes.length > 0) yield put(notesActionUnset(relatedNotes));
}
