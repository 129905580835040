import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { DateTime } from 'luxon';
import ThemeCrumb from '../../ThemeCrumb';
import ErrorWrapper from './auxiliary/ErrorWrapper';
import getDatepickerCalendarContainer from './auxiliary/getDatepickerCalendarContainer';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { connect } from 'react-redux';
import { getThemeTypeDefinition } from '../../../helpers/themeHelpers';
import get from 'lodash/get';

import 'react-datepicker/dist/react-datepicker.css';
import { getThemeType } from '../../../helpers/formHelpers';
import {
  backendDateFormat as backendDateFormatConstant,
  displayDateFormat as displayDateFormatConstant,
} from '../../../constants/dateFormat';

const jsDateToBackendValue = (jsDate, backendDateFormat) => {
  if (!jsDate) return null;
  return DateTime.fromJSDate(jsDate).toLocal().toFormat(backendDateFormat);
};

const defaultOnChange = async (props) => {
  const {
    date,
    backendDateFormat = backendDateFormatConstant,
    field: { name },
    form: { setFieldValue, setFieldTouched },
  } = props;
  await setFieldValue(name, jsDateToBackendValue(date, backendDateFormat));
  await setFieldTouched(name, true);
};

const DateField = (props) => {
  const {
    id,
    backendDateFormat = backendDateFormatConstant,
    displayDateFormat = displayDateFormatConstant,
    placeholder = 'DD-MM-YYYY',
    innerRef,
    theme,
    themeType = 'input',
    textAlignClass = 'text-center',
    className = '',
    isClearable = true,
    displayError,
    fixedErrorHeight,
    handleErrorThemeType,
    handleDisabledThemeType,
    handlePresentationModeThemeType,
    disabled = false,
    presentationMode = false,
    onChange = (onChangeProps, _defaultOnChange) => _defaultOnChange(onChangeProps),
    onBlur = () => {},
    field: { name, value },
    form: { errors, touched, setFieldTouched },
  } = props;

  const error = get(touched, name) ? get(errors, name) : null;
  const actualThemeType = getThemeType({
    themeType,
    error,
    handleErrorThemeType,
    presentationMode,
    handlePresentationModeThemeType,
    disabled,
    handleDisabledThemeType,
  });
  const dateTimeValue = value ? DateTime.fromFormat(value, backendDateFormat) : null;
  const jsDateValue = value ? Date.parse(dateTimeValue.toString()) : null;
  const displayedValue = value ? dateTimeValue.toFormat(displayDateFormat) : '';
  const cursorClass = !disabled && !presentationMode ? 'cursor-pointer' : '';

  useEffect(() => {
    const selectedThemeDefinition = getThemeTypeDefinition(theme, 'selected');
    const dStyle = document.querySelector('#date-field-style');
    dStyle.innerHTML = `.react-datepicker__week > .react-datepicker__day--selected {
        color: ${selectedThemeDefinition.color} !important;
        background-color: ${selectedThemeDefinition.backgroundColor} !important;
      }
      .react-datepicker__week > .react-datepicker__day.react-datepicker__day--today {
        font-weight: 900 !important;
      },
      ..react-datepicker__close-icon {
        padding: 0 8px 0 0 !important;
      }
      .react-datepicker__close-icon::after {
        font-size: 18px !important;
      }`;
  }, [theme]);

  return (
    <ErrorWrapper displayError={displayError} fixedErrorHeight={fixedErrorHeight} error={error}>
      <DatePicker
        onChange={(date) => onChange({ ...props, date }, defaultOnChange)}
        onCalendarClose={async () => {
          await setFieldTouched(name, true);
          await onBlur();
        }}
        selected={jsDateValue}
        disabled={disabled || presentationMode}
        customInputRef={innerRef}
        customInput={
          // opacity-100 for safari display
          <div className="w-full" id={id}>
            <ThemeCrumb type={actualThemeType}>
              <input
                value={displayedValue}
                placeholder={placeholder}
                disabled={disabled || presentationMode}
                readOnly
                className={`w-full border rounded p-1 opacity-100 outline-none caret-transparent
                            ${textAlignClass} ${className} ${cursorClass}`}
              />
            </ThemeCrumb>
          </div>
        }
        calendarContainer={getDatepickerCalendarContainer('datePickerCalendar')}
        isClearable={isClearable}
        portalId="DatePickerPortal"
        popperClassName="z-40-important"
        popperPlacement="bottom"
        popperProps={{ style: { color: 'red' } }}
        calendarStartDay={1}
      />
    </ErrorWrapper>
  );
};

export default connect(prepareState('theme'))(DateField);
