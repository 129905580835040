export const dayEvaluationsActionFetchElement = (date) => ({
  type: 'DAY_EVALUATIONS_ACTION_FETCH_ELEMENT',
  payload: date,
});

export const dayEvaluationsActionUpdateElement = (dayEvaluation) => ({
  type: 'DAY_EVALUATIONS_ACTION_UPDATE_ELEMENT',
  payload: dayEvaluation,
});

export const dayEvaluationsActionDeleteElement = (dayEvaluation) => ({
  type: 'DAY_EVALUATIONS_ACTION_DELETE_ELEMENT',
  payload: dayEvaluation,
});

export const dayEvaluationsActionSetElement = (dayEvaluation) => ({
  type: 'DAY_EVALUATIONS_ACTION_SET_ELEMENT',
  payload: dayEvaluation,
});

export const dayEvaluationsActionUnlockElement = (dayEvaluation) => ({
  type: 'DAY_EVALUATIONS_ACTION_UNLOCK_ELEMENT',
  payload: dayEvaluation,
});
