import React from 'react';
import ChartPanel from './ChartPanel';

const ChartPanelsContainer = (props) => {
  const { date, habit, chartRequestsSet } = props;
  const chartsCount = chartRequestsSet.length;

  if (chartsCount === 1) {
    return (
      <div className="w-full pt-4 px-4 lg:grid lg:grid-cols-3 gap-25px">
        <div />
        <ChartPanel date={date} habit={habit} chartRequest={chartRequestsSet[0]} />
      </div>
    );
  }

  if (chartsCount === 2) {
    return (
      <div className="w-full pt-4 px-4">
        <div className="w-full lg:px-6px lg:flex justify-center">
          <div className="lg:w-2/3 lg:grid grid-cols-2 gap-25px">
            <ChartPanel date={date} habit={habit} chartRequest={chartRequestsSet[0]} />
            <ChartPanel date={date} habit={habit} chartRequest={chartRequestsSet[1]} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full pt-4 px-4 lg:grid lg:grid-cols-3 gap-25px">
      <ChartPanel date={date} habit={habit} chartRequest={chartRequestsSet[0]} />
      <ChartPanel date={date} habit={habit} chartRequest={chartRequestsSet[1]} />
      <ChartPanel date={date} habit={habit} chartRequest={chartRequestsSet[2]} />
    </div>
  );
};

export default ChartPanelsContainer;
