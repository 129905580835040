{
  /* eslint-disable max-len */
}
import React from 'react';

export const BulletsIcon = ({ className = '', style = {} }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
    style={style}
  >
    <rect x="10" y="2.5" width="13.466" height="0.85" style={{ stroke: 'rgb(0, 0, 0)' }} />
    <rect x="10" y="7" width="13.466" height="0.85" style={{ stroke: 'rgb(0, 0, 0)' }} />
    <rect x="10" y="11.5" width="13.466" height="0.85" style={{ stroke: 'rgb(0, 0, 0)' }} />
    <rect x="10" y="16" width="13.466" height="0.85" style={{ stroke: 'rgb(0, 0, 0)' }} />
    <rect x="10" y="20.5" width="13.466" height="0.85" style={{ stroke: 'rgb(0, 0, 0)' }} />
    <ellipse style={{ stroke: 'rgb(0, 0, 0)' }} cx="2.5" cy="4.564" rx="1.5" ry="1.5" />
    <ellipse style={{ stroke: 'rgb(0, 0, 0)' }} cx="2.5" cy="11.968" rx="1.5" ry="1.5" />
    <ellipse style={{ stroke: 'rgb(0, 0, 0)' }} cx="2.5" cy="19.218" rx="1.5" ry="1.5" />
  </svg>
);
export default BulletsIcon;
