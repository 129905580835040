import React, { useMemo, useState, useCallback } from 'react';
import EventModel from '../../../models/eventModel';
import { connect } from 'react-redux';
import { eventsActionCreateElement } from '../../../stateManagement/actions/eventsActions';
import { elementToFocusOnActionReset } from '../../../stateManagement/actions/elementToFocusOnActions';
import Form from './Form';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { customColors } from '../../../constants/customColors';
import { activeElementsActionDeactivateAllOfType } from '../../../stateManagement/actions/activeElementsActions';
import { activeElementTypes } from '../../../constants/activeElementTypes';

const EventCreator = (props) => {
  const { incognitoMode, events, date, initialFocus, activeElementsActionDeactivateAllOfType: deactivate } = props;

  const [_private, setPrivate] = useState(false);
  const [color, setColor] = useState(Object.keys(customColors)[0]);

  const newEvent = useMemo(() => {
    const event = new EventModel();
    event.date = date;
    event.private = incognitoMode ? false : _private;
    event.color = color;
    return event;
  }, [incognitoMode, date]);

  const hideCreator = useCallback(() => {
    deactivate(activeElementTypes.calendarEventCreator);
  }, [deactivate]);

  return (
    <Form
      {...props}
      {...newEvent}
      locked={events.locked}
      setPrivate={setPrivate}
      private={_private}
      setColor={setColor}
      color={color}
      initialFocus={initialFocus}
      hideCreator={hideCreator}
    />
  );
};

export default connect(prepareState(['incognitoMode', 'elementToFocusOn', 'events']), {
  eventsActionCreateElement,
  elementToFocusOnActionReset,
  activeElementsActionDeactivateAllOfType,
})(EventCreator);
