import Lockable from './parents/lockable';
import { weekdayTypes } from '../constants/enums/weekdayTypes';
import { monthTypes } from '../constants/enums/monthTypes';
import { recurringEventVariants } from '../constants/enums/recurringEventVariants';
import { currentDateInBackendFormat } from '../helpers/dateTimeHelpers';
import { customColors } from '../constants/customColors';

export default class RecurringEvent extends Lockable {
  constructor() {
    super();
    this.id = null;
    this.nodesGroupId = null;
    this.name = null;
    this.color = Object.keys(customColors)[0];
    this.generationActive = true;
    this.startDate = currentDateInBackendFormat();
    this.variant = recurringEventVariants.weekly;
    this.weekdayType = weekdayTypes.monday;
    this.monthDay = 1;
    this.monthType = monthTypes.january;
    this.yearDay = 1;
    this.defaultOrder = 0;
    this.private = false;
  }

  visible(incognitoMode = false) {
    return incognitoMode ? !this.private : true;
  }
}
