import React, { useEffect, useMemo, useRef, useState } from 'react';
import { initT } from '../../helpers/i18nHelpers';
import Loader from '../../shared/loaders/Loader';
import NoData from '../../shared/placeholders/NoData';
import { reduce, uniq } from 'lodash';
import FoundForDate from './FoundForDate';
import useOnScreen from '../../hooks/useOnScreen';

const t = initT('pages.finder');

const FoundInner = (props) => {
  const {
    dates,
    needle,
    collection,
    dateAttributeName,
    searchedAttributeName,
    incognitoMode,
    fetchAction,
    renderFoundElement,
  } = props;

  const bottomRef = useRef(null);
  const bottomOnScreen = useOnScreen(bottomRef);

  const [visibleDates, setVisibleDates] = useState([]);
  const [fetchingNewBatch, setFetchingNewBatch] = useState(false);

  useEffect(() => {
    if (bottomOnScreen && !fetchingNewBatch && dates.length !== visibleDates.length) {
      setFetchingNewBatch(true);
      const startIndex = visibleDates.length;
      const newVisibleDates = [...visibleDates, ...dates.slice(startIndex, startIndex + 5)];
      setVisibleDates(newVisibleDates);
      const datesToFetch = [];
      newVisibleDates.forEach((date) => {
        if (!collection.fetched(date)) datesToFetch.push(date);
      });
      if (datesToFetch.length > 0) fetchAction(datesToFetch);
    }
  }, [bottomOnScreen, fetchingNewBatch]);

  useEffect(() => {
    const datesToFetchCount = reduce(visibleDates, (count, date) => (collection.fetched(date) ? count : count + 1), 0);

    if (datesToFetchCount === 0 && fetchingNewBatch) setFetchingNewBatch(false);
  }, [collection, visibleDates, fetchingNewBatch]);

  const visibleEntitiesDict = useMemo(() => {
    const newDict = {};

    visibleDates.forEach((date) => {
      const visibleEntitiesIds = [];

      collection
        .visible(incognitoMode, { [dateAttributeName]: date, [searchedAttributeName]: needle })
        .forEach((e) => visibleEntitiesIds.push(e.id));
      collection
        .visible(
          incognitoMode,
          (e) => e[dateAttributeName] === date && new RegExp(`^${needle}`, 'i').test(e[searchedAttributeName]),
        )
        .forEach((e) => visibleEntitiesIds.push(e.id));
      collection
        .visible(
          incognitoMode,
          (e) => e[dateAttributeName] === date && new RegExp(needle, 'i').test(e[searchedAttributeName]),
        )
        .forEach((e) => visibleEntitiesIds.push(e.id));

      const visibleEntities = uniq(visibleEntitiesIds).map((id) => collection.find({ id }));

      if (visibleEntities.length > 0) newDict[date] = visibleEntities;
    });

    return newDict;
  }, [collection, incognitoMode, visibleDates]);

  return (
    <>
      {Object.keys(visibleEntitiesDict).length === 0 && <NoData />}
      {visibleDates.map((date) =>
        visibleEntitiesDict[date] ? (
          <FoundForDate
            key={date}
            date={date}
            collection={visibleEntitiesDict[date]}
            renderElement={renderFoundElement}
          />
        ) : (
          <></>
        ),
      )}
      <div ref={bottomRef}>{fetchingNewBatch ? <Loader /> : <></>}</div>
    </>
  );
};

const Found = (props) => {
  const { dates } = props;

  if (dates === 'error') return <div>{t('searchError')}</div>;
  if (dates === 'loading') return <Loader />;
  if (dates.length === 0) return <NoData />;

  return <FoundInner {...props} />;
};

export default Found;
