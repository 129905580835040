import React from 'react';
import DoubleClickWrapper from '../DoubleClickWrapper';

const H1 = (props) => {
  const {
    children,
    className = '',
    widthClass = 'w-full',
    textAlignmentClass = 'text-center',
    textSizeClass = 'text-xl',
    style = {},
    onDoubleClick,
  } = props;

  const _className = `${widthClass} ${textAlignmentClass} font-bold ${textSizeClass} ${className}`;

  return (
    <h1 className={_className} style={style}>
      {onDoubleClick ? <DoubleClickWrapper onDoubleClick={onDoubleClick}>{children}</DoubleClickWrapper> : children}
    </h1>
  );
};

export default H1;
