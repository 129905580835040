import React from 'react';
import ThemeCrumb from '../../../../ThemeCrumb';
import Hoverable from '../../../../Hoverable';
import Clickable from '../../../../Clickable';

const NoteOption = (props) => {
  const {
    label,
    value,
    nestingLevel,
    selectedValue,
    onSelect = (_, { closeMenu }) => closeMenu(),
    checkIfSelected = (optionValue, selectedValue) => optionValue === selectedValue,
    ...otherProps
  } = props;

  const isSelected = checkIfSelected(value, selectedValue);

  let leftPaddingClass = 'pl-1';
  if (nestingLevel === 1) leftPaddingClass = 'pl-4';
  if (nestingLevel === 2) leftPaddingClass = 'pl-8';

  return (
    <ThemeCrumb type={isSelected ? 'sizeableThingSelected' : 'selectorOption'}>
      <Hoverable className="" hoveredThemeType="sizeableThingHovered">
        <Clickable
          className="w-full "
          onMouseDown={() => onSelect(value, { label, previousValue: selectedValue, ...otherProps })}
          disabled={isSelected}
          opaqueWhenDisabled={false}
        >
          <div
            className={`w-full pr-1 py-1 text-clip overflow-hidden flex justify-left
                                    items-center
                                    ${leftPaddingClass} ${isSelected ? 'font-bold' : ''}`}
          >
            <span className="p-1">•</span>
            {label}
          </div>
        </Clickable>
      </Hoverable>
    </ThemeCrumb>
  );
};

export default NoteOption;
