import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { learningQueuesActionUpdateElement } from '../../stateManagement/actions/learningQueuesActions';
import { getAllLearningWorkspaces } from '../../helpers/learningHelpers';
import WorkspaceSelector from '../../shared/features/WorkspaceSelector';
import { prepareState } from '../../helpers/stateManagementHelpers';

const LearningWorkspaceSelector = (props) => {
  const { queue, learningWorkspaces, incognitoMode, learningQueuesActionUpdateElement: update } = props;

  const workspaces = useMemo(
    () => getAllLearningWorkspaces(learningWorkspaces, incognitoMode),
    [learningWorkspaces, incognitoMode],
  );

  const onChange = useCallback(
    (newValue) => {
      const updatedQueue = queue.shallowClone().assignValues({
        ...queue,
        learningWorkspaceId: newValue,
      });
      update(updatedQueue);
    },
    [queue, update],
  );

  return <WorkspaceSelector value={queue.learningWorkspaceId} workspaces={workspaces} onChange={onChange} />;
};

export default connect(prepareState(['learningWorkspaces', 'incognitoMode']), {
  learningQueuesActionUpdateElement,
})(LearningWorkspaceSelector);
