import React from 'react';
import HabitChartRequestForm from './HabitChartRequestForm';
import HabitChartRequest from '../../../../models/habitChartRequest';

const HabitChartRequestModalContent = (props) => {
  const { addElement } = props;
  const newChartRequest = new HabitChartRequest();
  return <HabitChartRequestForm {...newChartRequest} addElement={addElement} />;
};

export default HabitChartRequestModalContent;
