export default class BaseModel {
  constructor() {}

  shallowClone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  assignValues(newValues) {
    const newObject = this.shallowClone();
    Object.keys(newValues).forEach((key) => {
      newObject[key] = newValues[key];
    });
    return newObject;
  }
}
