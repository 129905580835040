import React, { useEffect } from 'react';
import ButtonsGroup from '../../../shared/buttons/ButtonsGroup';
import IconButton from '../../../shared/buttons/IconButton';
import H1 from '../../../shared/titles/H1';
import CenteredContent from '../../../shared/organizers/CenteredContent';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { activeElementsActionActivate } from '../../../stateManagement/actions/activeElementsActions';
import {
  categoriesActionDeleteElement,
  categoriesActionMarkPrivateElement,
  categoriesActionUnmarkPrivateElement,
} from '../../../stateManagement/actions/categoriesActions';
import ColorSelector from './ColorSelector';
import { elementToFocusOnTypes } from '../../../constants/elementToFocusOnTypes';
import { elementToFocusOnActionReset } from '../../../stateManagement/actions/elementToFocusOnActions';
import ConfirmationButton from '../../../shared/buttons/ConfirmationButton';

const CategoryHeader = (props) => {
  const {
    category,
    incognitoMode,
    elementToFocusOn,
    categoriesActionDeleteElement: deleteElement,
    categoriesActionMarkPrivateElement: markPrivate,
    categoriesActionUnmarkPrivateElement: unmarkPrivate,
    enableEditionMode,
    elementToFocusOnActionReset,
  } = props;

  const switchPrivateVariant = category.private ? 'unmarkPrivate' : 'markPrivate';
  const switchPrivate = () => (category.private ? unmarkPrivate(category) : markPrivate(category));

  useEffect(() => {
    const categoryType = elementToFocusOnTypes.categoryTitle;
    if (elementToFocusOn && elementToFocusOn.equals(categoryType, category.id)) {
      enableEditionMode();
      elementToFocusOnActionReset();
    }
  }, [elementToFocusOn]);

  return (
    <CenteredContent
      left={
        <ButtonsGroup
          className="flex px-2 sm:px-25px"
          buttons={[
            <ColorSelector key="colorSelector" category={category} />,
            incognitoMode ? null : (
              <IconButton key="switchPrivate" variant={switchPrivateVariant} onMouseDown={switchPrivate} />
            ),
          ]}
        />
      }
      main={<H1 onDoubleClick={enableEditionMode}>{category.name}</H1>}
      right={
        <ButtonsGroup
          className="flex px-2 sm:px-25px"
          buttons={[
            <ConfirmationButton
              key="delete"
              variant="delete"
              i18nKey="categories.delete"
              onConfirmed={() => deleteElement(category)}
              resourceName={category.name}
              textConfirmation
            />,
          ]}
        />
      }
      heightPx={40}
    />
  );
};

export default connect(prepareState(['incognitoMode', 'elementToFocusOn']), {
  activeElementsActionActivate,
  categoriesActionDeleteElement,
  categoriesActionMarkPrivateElement,
  categoriesActionUnmarkPrivateElement,
  elementToFocusOnActionReset,
})(CategoryHeader);
