import React from 'react';
import ThemeCrumb from '../ThemeCrumb';

const ModalFooter = ({ children }) => (
  <ThemeCrumb type="modalFooter">
    <div className={`w-full flex justify-end items-center border-t p-4`}>{children}</div>
  </ThemeCrumb>
);

export default ModalFooter;
