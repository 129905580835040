export const habitAnswersActionFetch = (date, readonly) => ({
  type: 'HABIT_ANSWERS_ACTION_FETCH',
  payload: { date, readonly },
});

export const habitAnswersActionSet = (date, newElements) => ({
  type: 'HABIT_ANSWERS_ACTION_SET',
  payload: { date, newElements },
});

export const habitAnswersActionUpdateElement = (element) => ({
  type: 'HABIT_ANSWERS_ACTION_UPDATE_ELEMENT',
  payload: { date: element.date, element },
});

export const habitAnswersActionMarkUpdatedElement = (element) => ({
  type: 'HABIT_ANSWERS_ACTION_MARK_UPDATED_ELEMENT',
  payload: { date: element.date, element },
});

export const habitAnswersActionResetElement = (element) => ({
  type: 'HABIT_ANSWERS_ACTION_RESET_ELEMENT',
  payload: { date: element.date, element },
});

export const habitAnswersActionUnlockElement = (element) => ({
  type: 'HABIT_ANSWERS_ACTION_UNLOCK_ELEMENT',
  payload: { date: element.date, element },
});

export const habitAnswersActionSetElement = (element) => ({
  type: 'HABIT_ANSWERS_ACTION_SET_ELEMENT',
  payload: { date: element.date, element },
});

export const habitAnswersActionReset = (dateExcluded) => ({
  type: 'HABIT_ANSWERS_ACTION_RESET',
  payload: { dateExcluded },
});
