import { produceModelFromDto, produceModelToDto } from './modelHelpers';
import HabitsSection from '../../models/habitsSection';

const mappings = [
  ['id', 'id'],
  ['name', 'name'],
  ['default_order', 'defaultOrder'],
  ['hidden', 'hidden'],
  ['private', 'private'],
];

export const habitsSectionFromDto = produceModelFromDto(HabitsSection, mappings);

export const habitsSectionToDto = produceModelToDto(mappings);
