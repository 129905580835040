import { useEffect } from 'react';

export const useKeydown = (props) => {
  const { next, nextAllowed } = props;

  useEffect(() => {
    if (!nextAllowed) return;

    const nextCb = () => {
      next();
    };
    window.addEventListener('keydown', nextCb);
    return () => window.removeEventListener('keydown', nextCb);
  }, [next, nextAllowed]);
};
