import React from 'react';
import GoldenRatioMarginTopWrapper from '../../templates/StandardTemplate/utilities/GoldenRatioMarginTopWrapper';
import ThemeCrumb from '../ThemeCrumb';

const WholePageMessage = (props) => {
  const { children } = props;
  return (
    <GoldenRatioMarginTopWrapper>
      <ThemeCrumb type="bigMessage">
        <h1 className="text-3xl">{children}</h1>
      </ThemeCrumb>
    </GoldenRatioMarginTopWrapper>
  );
};

export default WholePageMessage;
