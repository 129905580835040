import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { snackbarMessagesActionHandleBackendErrors } from '../../actions/snackbarMessagesActions';
import { labelFromDto, labelToDto } from '../../../helpers/model/labelHelpers';
import { labelsActionSetElement, labelsActionUnlockElement } from '../../actions/labelsActions';

export default function* markPrivateElementLabelsSaga(action) {
  const initialLabel = action.payload;
  const { ok, data } = yield call(api.labels.markPrivate, labelToDto(initialLabel));
  if (ok) {
    yield put(labelsActionSetElement(labelFromDto(data)));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(labelsActionUnlockElement(initialLabel));
  }
}
