import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import {
  learningWorkspacesActionCreateElement,
  learningWorkspacesActionFetch,
} from '../../../stateManagement/actions/learningWorkspacesActions';
import StandardContentTemplate from '../../../shared/contentTemplates/StandardContentTemplate';
import { initT } from '../../../helpers/i18nHelpers';
import { PageTitle } from '../../../shared/titles/PageTitle';
import Main from './Main';
import { getAllLearningWorkspaces } from '../../../helpers/learningHelpers';

const t = (editionMode) => initT(`pages.learningWorkspaces.${editionMode ? 'edition' : 'selection'}`);

const LearningWorkspacesIndexPage = (props) => {
  const {
    incognitoMode,
    learningWorkspaces,
    learningWorkspacesActionFetch,
    learningWorkspacesActionCreateElement: createWorkspace,
  } = props;

  useEffect(() => {
    if (!learningWorkspaces.fetched) learningWorkspacesActionFetch();
  }, []);

  const [editionMode, setEditionMode] = useState(false);

  let visibleWorkspaces = [];
  if (learningWorkspaces.fetched) {
    visibleWorkspaces = getAllLearningWorkspaces(learningWorkspaces, incognitoMode);
  }

  useEffect(() => {
    if (visibleWorkspaces.length === 0 && learningWorkspaces.fetched) setEditionMode(true);
  }, [learningWorkspaces.fetched, visibleWorkspaces.length]);

  const _setEditionMode = (newValue) => {
    if (visibleWorkspaces.length === 0) setEditionMode(true);
    else setEditionMode(newValue);
  };

  return (
    <StandardContentTemplate
      top={<PageTitle>{t(editionMode)('title')}</PageTitle>}
      mainReady={learningWorkspaces.fetched}
      main={
        <Main
          visibleWorkspaces={visibleWorkspaces}
          editionMode={editionMode}
          setEditionMode={_setEditionMode}
          createWorkspace={createWorkspace}
        />
      }
    />
  );
};

export default connect(prepareState(['incognitoMode', 'learningWorkspaces']), {
  learningWorkspacesActionFetch,
  learningWorkspacesActionCreateElement,
})(LearningWorkspacesIndexPage);
