import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import {
  notesWorkspacesActionCreateElement,
  notesWorkspacesActionFetch,
} from '../../../stateManagement/actions/notesWorkspacesActions';
import StandardContentTemplate from '../../../shared/contentTemplates/StandardContentTemplate';
import { initT } from '../../../helpers/i18nHelpers';
import { PageTitle } from '../../../shared/titles/PageTitle';
import Main from './Main';
import { getAllNotesWorkspaces } from '../../../helpers/notesHelpers';

const t = (editionMode) => initT(`pages.notesWorkspaces.${editionMode ? 'edition' : 'selection'}`);

const NotesWorkspacesIndexPage = (props) => {
  const {
    incognitoMode,
    notesWorkspaces,
    notesWorkspacesActionFetch,
    notesWorkspacesActionCreateElement: createWorkspace,
  } = props;

  useEffect(() => {
    if (!notesWorkspaces.fetched) notesWorkspacesActionFetch();
  }, []);

  const [editionMode, setEditionMode] = useState(false);

  let visibleWorkspaces = [];
  if (notesWorkspaces.fetched) {
    visibleWorkspaces = getAllNotesWorkspaces(notesWorkspaces, incognitoMode);
  }

  useEffect(() => {
    if (visibleWorkspaces.length === 0 && notesWorkspaces.fetched) setEditionMode(true);
  }, [notesWorkspaces.fetched, visibleWorkspaces.length]);

  const _setEditionMode = (newValue) => {
    if (visibleWorkspaces.length === 0) setEditionMode(true);
    else setEditionMode(newValue);
  };

  return (
    <StandardContentTemplate
      top={<PageTitle>{t(editionMode)('title')}</PageTitle>}
      mainReady={notesWorkspaces.fetched}
      main={
        <Main
          visibleWorkspaces={visibleWorkspaces}
          editionMode={editionMode}
          setEditionMode={_setEditionMode}
          createWorkspace={createWorkspace}
        />
      }
    />
  );
};

export default connect(prepareState(['incognitoMode', 'notesWorkspaces']), {
  notesWorkspacesActionFetch,
  notesWorkspacesActionCreateElement,
})(NotesWorkspacesIndexPage);
