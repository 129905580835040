import React, { useEffect, useRef } from 'react';
import ThemeCrumb from '../../ThemeCrumb';
import ErrorWrapper from './auxiliary/ErrorWrapper';
import { getThemeType } from '../../../helpers/formHelpers';
import { get } from 'lodash';

const defaultOnChange = async (props) => {
  const {
    event,
    field: { name },
    form: { setFieldValue, setFieldTouched },
  } = props;
  await setFieldValue(name, event.target.value);
  await setFieldTouched(name);
};

const TextField = (props) => {
  const {
    id,
    placeholder,
    autoFocus,
    innerRef,
    themeType = 'input',
    className = '',
    paddingClass = 'p-1',
    textAlignClass = 'text-center',
    displayErrorAsPlaceholder = false,
    displayError,
    fixedErrorHeight,
    handleErrorThemeType,
    handleDisabledThemeType,
    handlePresentationModeThemeType,
    inputType = 'text',
    disabled = false,
    presentationMode = false,
    onChange = (onChangeProps, _defaultOnChange) => _defaultOnChange(onChangeProps),
    onKeyDown = () => {},
    onBlur = () => {},
    field,
    field: { name },
    form: { errors, touched, setFieldTouched },
    additionalInputProps = {},
  } = props;

  const ref = useRef();
  useEffect(() => {
    setTimeout(() => {
      // autoFocus does not work as expected in many cases
      if (autoFocus && ref.current) ref.current.focus();
    });
  }, []);
  useEffect(() => {
    if (innerRef) {
      innerRef.current = ref.current;
    }
  }, [ref.current]);

  const error = get(touched, name) ? get(errors, name) : null;
  const _displayError = displayErrorAsPlaceholder ? false : displayError;
  const _placeholder = displayErrorAsPlaceholder && error ? error : placeholder;
  const actualThemeType = getThemeType({
    themeType,
    error,
    handleErrorThemeType,
    presentationMode,
    handlePresentationModeThemeType,
    disabled,
    handleDisabledThemeType,
  });

  return (
    <ErrorWrapper displayError={_displayError} fixedErrorHeight={fixedErrorHeight} error={error}>
      <ThemeCrumb type={actualThemeType}>
        <input
          id={id}
          className={`focus:outline-none w-full placeholder-custom rounded border 
                                   ${paddingClass} ${textAlignClass} ${className}`}
          autoFocus={autoFocus}
          placeholder={_placeholder}
          ref={ref}
          type={inputType}
          disabled={disabled || presentationMode}
          autoComplete="off"
          {...field}
          onChange={(event) => onChange({ ...props, event }, defaultOnChange)}
          onKeyDown={onKeyDown}
          onBlur={async () => {
            await setFieldTouched(name);
            await onBlur();
          }}
          {...additionalInputProps}
        />
      </ThemeCrumb>
    </ErrorWrapper>
  );
};

export default TextField;
