import React from 'react';
import ThemeCrumb from '../../shared/ThemeCrumb';
import { connect } from 'react-redux';
import { prepareState } from '../../helpers/stateManagementHelpers';
import {
  dayEvaluationsActionDeleteElement,
  dayEvaluationsActionFetchElement,
  dayEvaluationsActionUpdateElement,
} from '../../stateManagement/actions/dayEvaluationsActions';
import { dayEvaluations as dayEvaluationsEnum } from '../../constants/enums/dayEvaluations';
import LockedEntityLoader from '../../shared/loaders/LockedEntityLoader';
import { activeElementsActionActivate } from '../../stateManagement/actions/activeElementsActions';
import { activeElementTypes } from '../../constants/activeElementTypes';
import IconButton from '../../shared/buttons/IconButton';
import { initT } from '../../helpers/i18nHelpers';

const t = initT('pages.calendar.dayEvaluations');

const DayEvaluation = (props) => {
  const {
    locked,
    date,
    dayEvaluations,
    dayEvaluationsActionUpdateElement,
    dayEvaluationsActionDeleteElement,
    activeElementsActionActivate: activateElement,
  } = props;

  const dayEvaluation = dayEvaluations.find({ date });
  const openModal = () => activateElement(activeElementTypes.dayEvaluationsChartModal, date);

  const chartIcon = <IconButton variant="chart" onMouseDown={openModal} />;
  const containerClasses = 'w-full flex justify-center items-center';

  return (
    <LockedEntityLoader visible={dayEvaluation.locked}>
      <div className="w-full flex items-center py-1 px-2 sm:px-25px">
        <div className="invisible">{chartIcon}</div>
        <div className={containerClasses}>
          {Object.keys(dayEvaluationsEnum).map((evaluationKey) => {
            const evaluationValue = dayEvaluationsEnum[evaluationKey];
            const selected = dayEvaluation.evaluation === evaluationValue;
            const onMouseDown = () => {
              if (dayEvaluation.evaluation === evaluationValue) {
                dayEvaluationsActionDeleteElement(dayEvaluation);
              } else {
                const newDayEvaluation = dayEvaluation.shallowClone();
                newDayEvaluation.evaluation = evaluationValue;
                dayEvaluationsActionUpdateElement(newDayEvaluation);
              }
            };

            return (
              <IconButton
                key={evaluationKey}
                variant={evaluationKey}
                onMouseDown={onMouseDown}
                themeType="button"
                disabled={locked}
                opaqueWhenDisabled={!selected}
                tooltip={t(evaluationKey)}
                preprocessIcon={
                  selected
                    ? (icon) => (
                        <ThemeCrumb type="selected">
                          <div className="rounded-full">{icon}</div>
                        </ThemeCrumb>
                      )
                    : undefined
                }
                iconSizeClass="w-7 h-7 sm:w-8 sm:h-8"
              />
            );
          })}
        </div>
        {chartIcon}
      </div>
    </LockedEntityLoader>
  );
};

export default connect(prepareState(['dayEvaluations']), {
  dayEvaluationsActionFetchElement,
  dayEvaluationsActionUpdateElement,
  dayEvaluationsActionDeleteElement,
  activeElementsActionActivate,
})(DayEvaluation);
