// initial setup and overwrites
import './initialization';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import { createBrowserHistory } from 'history';
import initializeStore from './stateManagement/initialization/intializeStore';
import SnackbarMessagesDisplay from './shared/snackbarMessages/SnackbarMessagesDisplay';
import ModalsDisplay from './shared/modals/ModalsDisplay';
import { Chart } from 'chart.js';
import 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';
import { GoogleOAuthProvider } from '@react-oauth/google';

// loads i18n
import './i18n/index';
import OnClickOutsideController from './shared/onClickOutside/OnClickOutsideController';

const browserHistory = createBrowserHistory();
Chart.register(zoomPlugin);

ReactDOM.render(
  // eslint-disable-next-line no-undef
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID}>
    <Provider store={initializeStore(browserHistory)}>
      <div className="text-center">
        <App browserHistory={browserHistory} />
        <SnackbarMessagesDisplay />
        <ModalsDisplay />
        <OnClickOutsideController />
      </div>
    </Provider>
  </GoogleOAuthProvider>,
  document.getElementById('root'),
);
