import React from 'react';
import { FieldArray } from 'formik';
import ThemeCrumb from '../../ThemeCrumb';
import List from '../../lists/List';
import { getThemeType } from '../../../helpers/formHelpers';
import NoData from '../../placeholders/NoData';
import ErrorWrapper from '../fieldTypes/auxiliary/ErrorWrapper';
import CollectionElementField from './CollectionFieldElement';
import LabelledButton from '../../buttons/LabelledButton';
import frenchkiss from 'frenchkiss';
import { connect } from 'react-redux';
import { activeElementsActionActivate } from '../../../stateManagement/actions/activeElementsActions';
import { get } from 'lodash';

const getDefaultRenderElement = (getElementLabel) => (props) => (
  <CollectionElementField key={props.index} elementLabel={getElementLabel(props.element)} {...props} />
);

const CollectionFieldInner = (props) => {
  const {
    id,
    placeholder = <NoData />,
    innerRef,
    name,
    label,
    className = '',
    themeType = 'input',
    disabled = false,
    presentationMode = false,
    handleErrorThemeType = true,
    handleDisabledThemeType = true,
    handlePresentationModeThemeType = true,
    displayError,
    fixedErrorHeight = false,
    getElementLabel = (element) => element,
    renderElement = getDefaultRenderElement(getElementLabel),
    modalToOpen,
    onAdditionRequest,
    form: { values, errors, setFieldTouched },
    insert,
    remove,
    activeElementsActionActivate,
  } = props;

  const value = get(values, name);
  const error = get(errors, name);
  if (!Array.isArray(value)) return <></>;

  const addElement = (element) => {
    insert(value.length, element);
    setFieldTouched(name);
  };
  const produceRemoveElement = (index) => () => {
    remove(index);
    setFieldTouched(name);
  };

  const actualThemeType = getThemeType({
    themeType,
    error,
    handleErrorThemeType,
    presentationMode,
    handlePresentationModeThemeType,
    disabled,
    handleDisabledThemeType,
  });

  const changesDisabled = presentationMode || disabled;
  const _onAdditionRequest =
    onAdditionRequest ||
    (modalToOpen ? () => activeElementsActionActivate(modalToOpen, null, { addElement }) : () => {});
  const additionButton = changesDisabled ? (
    <></>
  ) : (
    <LabelledButton variant="add" onMouseDown={_onAdditionRequest}>
      {frenchkiss.t('button.add')}
    </LabelledButton>
  );

  return (
    <div className={`max-w-fit-content ${className}`}>
      <div className="flex items-center pb-1 px-1">
        <span className="invisible">{additionButton}</span>
        <div className="flex justify-center w-full">{label}</div>
        {additionButton}
      </div>
      <ErrorWrapper displayError={displayError} fixedErrorHeight={fixedErrorHeight} error={error}>
        <ThemeCrumb type={actualThemeType}>
          <div id={id} ref={innerRef} className="border w-full rounded p-1">
            <List collection={value} noDataPlaceholder={placeholder}>
              {(element, index) => {
                const removeElement = produceRemoveElement(index);
                return renderElement({
                  ...props,
                  element,
                  index,
                  removeElement,
                  themeType: actualThemeType,
                });
              }}
            </List>
          </div>
        </ThemeCrumb>
      </ErrorWrapper>
    </div>
  );
};

const CollectionField = (props) => {
  const { name, hidden, ...propsToPass } = props;

  if (hidden) return <></>;

  return (
    <FieldArray name={name} render={(arrayHelpers) => <CollectionFieldInner {...arrayHelpers} {...propsToPass} />} />
  );
};

export default connect(null, {
  activeElementsActionActivate,
})(CollectionField);
