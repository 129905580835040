/* eslint-disable max-len */
import frenchkiss from 'frenchkiss';

frenchkiss.extend('en', {
  pages: {
    other: {
      menu: {
        title: 'Menu',
        wasteBin: 'Waste bin',
      },
      recurringTasks: {
        sectionName: 'Recurring tasks',
        add: 'New recurring task',
        updateDisclaimer: "Update won't affect already generated tasks",
        pastStartDate: 'Start date has already been exceeded',
        noDetails: 'NO DETAILS',
        fields: {
          name: 'Name',
          generationActive: 'Active',
          categoryId: 'Category',
          startDate: 'Start date',
          details: 'Additional details',
        },
        variants: {
          weekly: {
            every: 'Every',
          },
          monthly: {
            dayOf: 'day of every month',
          },
          annually: {
            dayOf: 'day of',
          },
          sinceDays: {
            sinceLast: 'days since last',
          },
        },
        hints: {
          startDate: 'No task is going to be generated before the start date.',
          monthDay:
            'For months which does not include 29th, 30th or 31st day, such values are going to be treated as "last".',
          daysCount: 'First task is going to be generated on start date.',
          taskAlreadyCreated:
            'A task has already been generated.\nA new one can be generated only when the current one is checked off or deleted.',
          taskNotYetCreated: 'A new task is going to be generated on {nextGenerationDate}',
        },
      },
      upcomingEvents: {
        sectionName: 'Upcoming events',
        showMore: 'Show more',
      },
      recurringEvents: {
        sectionName: 'Recurring events',
        add: 'New recurring event',
        updateDisclaimer: "Update won't affect already generated events",
        pastStartDate: 'Start date has already been exceeded',
        noDetails: 'NO DETAILS',
        fields: {
          name: 'Name',
          color: 'Color',
          generationActive: 'Active',
          startDate: 'Start date',
          details: 'Additional details',
        },
        variants: {
          weekly: {
            every: 'Every',
          },
          monthly: {
            dayOf: 'day of every month',
          },
          annually: {
            dayOf: 'day of',
          },
        },
        hints: {
          startDate: 'No event is going to be generated before the start date.',
          monthDay:
            'For months which does not include 29th, 30th or 31st day, such values are going to be treated as "last".',
        },
      },
    },
  },
});
