import React, { useEffect, useState } from 'react';
import NavbarLink from './NavbarLink';
import NavbarAdditionalMenu from './NavbarAdditionalMenu';
import MenuIcon from '../../../icons/menuIcon';
import { connect } from 'react-redux';
import { activeElementsActionDeactivateAllOfType } from '../../../stateManagement/actions/activeElementsActions';
import { activeElementTypes } from '../../../constants/activeElementTypes';
import { useLocation } from 'react-router-dom';
import sortBy from 'lodash/sortBy';

const NavbarSmallScreenContent = (props) => {
  const {
    leftLinksConfig,
    mainLinksConfig,
    rightLinksConfig,
    activeElementsActionDeactivateAllOfType: deactivateAll,
  } = props;

  const [additionalMenuExpanded, setAdditionalMenuExpanded] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setAdditionalMenuExpanded(false);
  }, [location]);

  const additionalMenuLinks = sortBy([...mainLinksConfig, ...rightLinksConfig], 'smallScreenOrder');
  const toggle = () => {
    deactivateAll(activeElementTypes.fixedMenu);
    setAdditionalMenuExpanded(!additionalMenuExpanded);
  };

  return (
    <div className="flex w-full">
      <div className="flex ml-2">
        {leftLinksConfig.map(({ key, ...otherProps }) => (
          <NavbarLink key={key} marginClass="" widthClass="w-12" {...otherProps} />
        ))}
      </div>
      <div className="flex flex-grow justify-end mr-2">
        <NavbarLink marginClass="" widthClass="w-12" onMouseDown={toggle}>
          <MenuIcon className="w-6 h-6" />
        </NavbarLink>
      </div>
      <NavbarAdditionalMenu
        links={additionalMenuLinks}
        expanded={additionalMenuExpanded}
        hide={() => setAdditionalMenuExpanded(false)}
      />
    </div>
  );
};

export default connect(null, { activeElementsActionDeactivateAllOfType })(NavbarSmallScreenContent);
