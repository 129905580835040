import React, { useState } from 'react';
import List from '../../../shared/lists/List';
import { draggedElements } from '../../../constants/draggedElements';
import SidebarButton from '../../../shared/buttons/SidebarButton';
import { useParams } from 'react-router-dom';
import IconButton from '../../../shared/buttons/IconButton';
import { frontEndErrorT, initT } from '../../../helpers/i18nHelpers';
import { runIfDraggingNotEnabled } from '../../../helpers/draggingHelpers';
import { useCheckIfMounted } from '../../../hooks/useCheckIfMounted';

const t = initT('pages.notes');

const group = 'notes-draggable-group';
const maxNestingLevel = 2;

// needed for onMove function which is not going to listen to state changes
let currentElementToBeNestedInId = null;

// It is going to be fired only if onMove returns true or is not fired at all
// (on drag outside related).
const produceOnDragLeaveCallback = (setElementToBeNestedInId) => () => {
  if (currentElementToBeNestedInId) setElementToBeNestedInId(null);
};

const NotesList = (props) => {
  const {
    notesTreeHash,
    expandedNotes,
    setExpandedNotes,
    dragged,
    setDragged,
    parentId = null,
    nestingLevel = 0,
    draggedElementActionDrop,
    refresh,
    showError,
    locked,
    createNewNote,
    duplicateNote,
  } = props;

  const { noteId } = useParams();
  const checkIfMounted = useCheckIfMounted();

  const relevantNotes = notesTreeHash[parentId].childrenIds.map((id) => notesTreeHash[id].note);
  const [elementToBeNestedInId, setElementToBeNestedInId] = useState(null);
  const _setElementToBeNestedInId = (value) => {
    currentElementToBeNestedInId = value;
    setElementToBeNestedInId(value);
  };

  return (
    <div className={`w-full ${nestingLevel === 0 ? '' : 'pl-25px'}`} data-nesting-level={nestingLevel}>
      <List
        collection={relevantNotes}
        draggable={{
          draggedElementType: draggedElements.note,
          group,
          additionalDropPayload: { parentId },
        }}
        noDataCondition={false}
        onDragStart={(e) => {
          const draggingActionUuid = window.lastDraggingActionUuid;
          const draggedCandidate = e.item.dataset['noteId'];
          setTimeout(() => {
            const sameAction = draggingActionUuid === window.lastDraggingActionUuid;
            if (window.draggingActive && sameAction) setDragged(draggedCandidate);
          }, 200);
        }}
        onDragEnd={() => {
          setDragged(null);
          setTimeout(() => {
            if (checkIfMounted()) _setElementToBeNestedInId(null);
          });
        }}
        onDragMove={(event, { clientY }) => {
          const { relatedRect, draggedRect, related, from, to } = event;
          const relatedId = related.dataset['noteId'];
          const allowOnDropNesting = related.dataset['allowOnDropNesting'] === 'true';
          if (!allowOnDropNesting || from !== to) return true;

          const alreadySetAsToBeNestedIn = relatedId === currentElementToBeNestedInId;

          const relatedIsAbove = draggedRect.top - relatedRect.top > 0;
          const nestingSpaceThreshold = 0.3;
          const nestingSpace = {
            top: relatedIsAbove ? relatedRect.bottom - relatedRect.height * nestingSpaceThreshold : relatedRect.top,
            bottom: relatedIsAbove ? relatedRect.bottom : relatedRect.top + relatedRect.height * nestingSpaceThreshold,
          };
          const withinNestingSpace = nestingSpace.top <= clientY && clientY <= nestingSpace.bottom;

          if (withinNestingSpace && !alreadySetAsToBeNestedIn) {
            _setElementToBeNestedInId(relatedId);
            window.addEventListener('dragleave', produceOnDragLeaveCallback(_setElementToBeNestedInId), { once: true });
          } else if (!withinNestingSpace && alreadySetAsToBeNestedIn) _setElementToBeNestedInId(null);

          return !withinNestingSpace;
        }}
        onDragSuccess={(event, draggedIdentifier, defaultOnSuccess) => {
          const draggedNestedLevels = notesTreeHash[parseInt(draggedIdentifier, 10)].nestedLevels;
          if (currentElementToBeNestedInId) {
            const newParentId = parseInt(currentElementToBeNestedInId, 10);
            const newParentHash = notesTreeHash[newParentId];
            const resultantMaxNestingLevel = newParentHash.nestingLevel + 1 + draggedNestedLevels;
            if (resultantMaxNestingLevel > maxNestingLevel) {
              showError(frontEndErrorT('notes.maxNestingLevel'));
              refresh();
              return;
            }
            const newParentChildrenIds = newParentHash.childrenIds;
            const successorIdentifier = newParentChildrenIds.length === 0 ? null : newParentChildrenIds[0];
            draggedElementActionDrop(draggedElements.note, draggedIdentifier, successorIdentifier, {
              parentId: newParentId,
            });
            setExpandedNotes([...expandedNotes, newParentId]);
          } else {
            const newListNestingLevel = parseInt(event.to.parentElement.parentElement.dataset['nestingLevel'], 10);
            if (newListNestingLevel + draggedNestedLevels <= maxNestingLevel) defaultOnSuccess(event);
            else {
              showError(frontEndErrorT('notes.maxNestingLevel'));
              refresh();
            }
          }
        }}
      >
        {(note) => {
          const expanded = expandedNotes.includes(note.id) && note.id.toString() !== dragged;
          const hasChildren = notesTreeHash[note.id] && notesTreeHash[note.id].childrenIds.length > 0;
          const expansionButtonVariant = expanded ? 'arrowDown' : 'arrowRight';
          const preventDefaultBehaviour = (effect) => (e) => {
            e.stopPropagation();
            e.preventDefault();
            return effect(e);
          };
          const expand = preventDefaultBehaviour(() => {
            if (expanded) setExpandedNotes(expandedNotes.filter((id) => id !== note.id));
            else setExpandedNotes([...expandedNotes, note.id]);
          });
          const duplicate = preventDefaultBehaviour(() => {
            duplicateNote(note);
          });
          const createChild = preventDefaultBehaviour(() => {
            createNewNote(note.id);
          });
          const tooltip = expanded ? t('collapse') : t('expand');
          const isElementToBeNestedIn = elementToBeNestedInId === note.id.toString();
          const selected = noteId && note.id === parseInt(noteId, 10);
          return (
            <div
              key={note.id}
              className={`${expanded && dragged ? 'pb-2' : ''} w-full`}
              data-note-id={note.id}
              data-allow-on-drop-nesting={!expanded && nestingLevel < 2}
              onDragStart={(e) => {
                const toHide = e.target.getElementsByClassName('hidden-for-draggable-clone')[0];
                toHide.style.display = 'none';
              }}
              onDragEnd={(e) => {
                const toShowAgain = e.target.getElementsByClassName('hidden-for-draggable-clone')[0];
                toShowAgain.style.display = 'block';
              }}
            >
              <SidebarButton
                selected={selected || isElementToBeNestedIn}
                selectionThemeType={isElementToBeNestedIn ? 'selected' : undefined}
                paddingClass="pr-1"
                to={`/notes/${note.id}`}
                customLinkOnClick={(e, to, defaultOnClick) => {
                  runIfDraggingNotEnabled(defaultOnClick);
                }}
                bullet={
                  hasChildren ? (
                    <IconButton
                      variant={expansionButtonVariant}
                      tooltip={tooltip}
                      iconSizeClass="w-2 h-3"
                      paddingClass="py-3 pl-3 pr-1.5"
                      useTimeout={false}
                      // only onClick is going to appropriately
                      // prevent sidebar button redirection
                      onClick={expand}
                    />
                  ) : (
                    <span style={{ paddingRight: '7.75px' }} className="py-2 pl-3">
                      •
                    </span>
                  )
                }
                appendix={
                  selected ? (
                    <div className="flex">
                      <IconButton
                        variant="duplicate"
                        disabled={locked}
                        useTimeout={false}
                        onClick={duplicate}
                        iconSizeClass="w-3 h-3"
                      />
                      <IconButton
                        variant="add"
                        disabled={locked}
                        useTimeout={false}
                        onClick={createChild}
                        iconSizeClass="w-3 h-3"
                        tooltip={t('createChild')}
                      />
                    </div>
                  ) : (
                    <></>
                  )
                }
              >
                {note.name}
              </SidebarButton>
              <div className="hidden-for-draggable-clone">
                {nestingLevel <= maxNestingLevel && expanded ? (
                  <NotesList {...props} parentId={note.id} nestingLevel={nestingLevel + 1} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          );
        }}
      </List>
    </div>
  );
};

export default NotesList;
