import { produceValuesFilter } from '../../../../helpers/formHelpers';
import * as yup from 'yup';
import { frontEndErrorT, initT } from '../../../../helpers/i18nHelpers';

export const t = initT('pages.other.recurringEvents');

export const relevantAttributes = [
  'id',
  'nodesGroupId',
  'name',
  'color',
  'generationActive',
  'startDate',
  'variant',
  'weekdayType',
  'monthType',
  'monthDay',
  'yearDay',
  'private',
];

export const auxiliaryAttributes = ['nodes', 'editionMode', 'onBlur', 'nodesActionFetch'];

export const filterValues = produceValuesFilter(relevantAttributes);

export const validationSchema = () =>
  yup.object().shape({
    name: yup.string().required(frontEndErrorT('recurringEvent.name.empty')),
    startDate: yup.string().required(frontEndErrorT('recurringEvent.startDate.empty')),
  });
