import Lockable from './lockable';

export default class FetchableAndLockable extends Lockable {
  constructor() {
    super();
    this.fetched = false;
  }

  toFetched(createClone = true) {
    let result = this;
    if (createClone) result = this.shallowClone();
    result.fetched = true;
    return result;
  }

  toNotFetched(createClone = true) {
    let result = this;
    if (createClone) result = this.shallowClone();
    result.fetched = false;
    return result;
  }
}
