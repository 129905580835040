import React from 'react';
import Loader from './Loader';

const CenteredLoader = ({ sizeClass = 'w-32 h-32' }) => (
  <div className="w-full h-full flex justify-center items-center">
    <Loader sizeClass={sizeClass} />
  </div>
);

export default CenteredLoader;
