import React from 'react';
import { t, useOptions, useSourceNote, useValuesConsistency } from './helpers';
import OnClickOutsideWrapper from '../../../shared/onClickOutside/OnClickOutsideWrapper';
import SmartForm from '../../../shared/form/SmartForm';
import LabelField from '../../../shared/form/LabelField';
import TextField from '../../../shared/form/fieldTypes/TextField';
import SelectorField from '../../../shared/form/fieldTypes/SelectorField';
import SmartField from '../../../shared/form/SmartField';
import { learningQueueVariants } from '../../../constants/enums/learningQueueVariants';
import CheckboxField from '../../../shared/form/fieldTypes/CheckBoxField';
import NoteOption from '../../../shared/form/components/selector/noteParts/NoteOption';
import { learningQueueSourceVariants as sourceVariants } from '../../../constants/enums/learningQueueSourceVariant';
import { useDoubleClickToBlur } from '../../../hooks/useDoubleClickToBlur';
import StandardFormFooter from '../../../shared/form/StandardFormFooter';

const QueueInnerForm = (props) => {
  const { values, handleSubmit, setFieldValue } = props;
  const {
    id,
    variant,
    sourceNoteId,
    auxiliary: { hideForm, auxiliaryNotesWorkspaceId, sourceVariant },
  } = values;

  const { onClickInside, onClickOutside, aboutToBlur } = useDoubleClickToBlur({ onBlur: hideForm });

  useSourceNote(props);
  useValuesConsistency(props);
  const options = useOptions(props);

  return (
    <OnClickOutsideWrapper
      onClickOutside={onClickOutside}
      onClick={onClickInside}
      className={aboutToBlur ? 'animate-inactive-pulse ' : ''}
    >
      <SmartForm className="p-25px">
        <div className="flex justify-center">
          <div className="w-full sm:w-big-golden-ratio max-w-xs">
            <LabelField
              name="name"
              className="pb-25px"
              required
              hidden={id}
              widthClass="w-full"
              label={t('queueFields.name')}
              component={TextField}
            />
            <LabelField
              name="variant"
              label={t('queueFields.variant')}
              options={options.variant}
              required
              disabled={id}
              component={SelectorField}
            />
            <SmartField
              name="withRepetitionDelay"
              className="pt-25px"
              hidden={variant !== learningQueueVariants.audio}
              label={t('queueFields.withRepetitionDelay')}
              component={CheckboxField}
            />
            <LabelField
              name="auxiliary.sourceVariant"
              className="pt-25px"
              label={t('queueFields.sourceVariant')}
              options={options.sourceVariant}
              required
              component={SelectorField}
            />
            <LabelField
              name="sourceLabelId"
              className="pt-25px"
              hidden={sourceVariant !== sourceVariants.label}
              label={t('queueFields.sourceLabelId')}
              options={options.label}
              required
              component={SelectorField}
            />
            <LabelField
              name="sourceNotesWorkspaceId"
              className="pt-25px"
              hidden={sourceVariant !== sourceVariants.notesWorkspace}
              label={t('queueFields.sourceNotesWorkspaceId')}
              options={options.notesWorkspace}
              required
              component={SelectorField}
            />
            <LabelField
              name="auxiliary.auxiliaryNotesWorkspaceId"
              className="pt-25px"
              hidden={sourceVariant !== sourceVariants.note}
              label={t('queueFields.auxiliaryNotesWorkspaceId')}
              options={options.notesWorkspace}
              // in this case data is being fetched
              disabled={!auxiliaryNotesWorkspaceId && sourceNoteId}
              onChange={(onChangeProps, defaultOnChange) => {
                defaultOnChange(onChangeProps);
                setFieldValue('sourceNoteId', '');
              }}
              required
              component={SelectorField}
            />
            <LabelField
              name="sourceNoteId"
              className="pt-25px"
              hidden={sourceVariant !== sourceVariants.note}
              label={t('queueFields.sourceNoteId')}
              options={options.note}
              optionComponent={NoteOption}
              disabled={!auxiliaryNotesWorkspaceId}
              required
              component={SelectorField}
            />
            <SmartField
              name="considerNestedNotes"
              className="pt-25px"
              hidden={sourceVariant !== sourceVariants.note}
              label={t('queueFields.considerNestedNotes')}
              component={CheckboxField}
            />
            <LabelField
              name="feedOrderVariant"
              className="pt-25px"
              label={t('queueFields.feedOrderVariant')}
              options={options.feedOrderVariant}
              required
              component={SelectorField}
            />
          </div>
        </div>
        <StandardFormFooter marginClass="mt-64px" handleSubmit={handleSubmit} handleCancel={hideForm} />
      </SmartForm>
    </OnClickOutsideWrapper>
  );
};

export default QueueInnerForm;
