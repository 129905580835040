import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { quickPassActionDeactivate } from '../../stateManagement/actions/quickPassActions';
import SmartForm from '../../shared/form/SmartForm';
import SmartField from '../../shared/form/SmartField';
import PasswordField from '../../shared/form/fieldTypes/PasswordField';
import { frontEndErrorT, initT } from '../../helpers/i18nHelpers';
import { produceValuesFilter } from '../../helpers/formHelpers';
import isEmpty from 'lodash/isEmpty';
import { prepareState } from '../../helpers/stateManagementHelpers';
import LabelledButton from '../../shared/buttons/LabelledButton';

// eslint-disable-next-line no-undef
const bcrypt = require('bcryptjs');

const t = initT('pages.quickPass');

const relevantAttributes = ['password'];
const filterValues = produceValuesFilter(relevantAttributes);

const QuickPassForm = ({ errors, touched, handleSubmit }) => (
  <SmartForm className="w-72">
    <SmartField name="password" placeholder={t('password')} component={PasswordField} />
    <LabelledButton
      variant="ok"
      wrapperClass="pt-2 w-full flex justify-center"
      disabled={!isEmpty(errors) || isEmpty(touched)}
      onMouseDown={handleSubmit}
    />
  </SmartForm>
);

const QuickPassPage = withFormik({
  mapPropsToValues: filterValues,
  enableReinitialize: true,
  handleSubmit: (values, formikBag) => {
    const hash = formikBag.props.quickPass.hash;
    const password = filterValues(values).password;
    const quickPassValid = !hash || bcrypt.compareSync(password, hash);
    if (quickPassValid) {
      formikBag.props.quickPassActionDeactivate();
    } else {
      formikBag.setFieldValue('password', '');
      formikBag.setFieldError('password', frontEndErrorT('quickPass.password.invalid'));
    }
  },
})(QuickPassForm);

export default connect(prepareState('quickPass'), { quickPassActionDeactivate })(QuickPassPage);
