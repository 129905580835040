import React from 'react';

export const CheckIcon = ({ className = '', style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className={`fill-current ${className}`}
    style={style}
  >
    <path
      d="M20 12.194v9.806h-20v-20h18.272l-1.951
                 2h-14.321v16h16v-5.768l2-2.038zm.904-10.027l-9.404 9.639-4.405-4.176-3.095
                 3.097 7.5 7.273 12.5-12.737-3.096-3.096z"
    />
  </svg>
);
export default CheckIcon;
