import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { conditionalLearn } from './conditionalLearn';
import { learningQueueIterableVariants, learningQueueVariants } from '../../../constants/enums/learningQueueVariants';
import { useCheckIfMounted } from '../../../hooks/useCheckIfMounted';

export const useBack = (props) => {
  const {
    loading,
    currentChunkUuid,
    reloadCurrentChunk,
    queue,
    plan,
    incognitoMode,
    setPreviousChunk,
    setCurrentChunk,
    setEnded,
    getPreviousChunk,
    previousChunk,
    updateGlobalStateLastLearnedNodeId,
    currentChunk,
    learningProgress,
    alreadyLearnedChunks,
    finalWpm,
    chunkData,
  } = props;

  const checkIfMounted = useCheckIfMounted();
  const [threeSecsPassed, setThreeSecsPassed] = useState(false);
  const chunkUuid = useRef(currentChunkUuid);

  const playsAudio = useMemo(
    () => !!(queue?.withAudio && chunkData?.node?.audioUrl),
    [queue?.withAudio, chunkData?.node?.audioUrl],
  );

  useEffect(() => {
    setThreeSecsPassed(false);
    chunkUuid.current = currentChunkUuid;
    setTimeout(() => {
      if (checkIfMounted() && chunkUuid.current === currentChunkUuid) setThreeSecsPassed(true);
    }, 3000);
  }, [currentChunkUuid]);

  const iterableVariant = useMemo(() => learningQueueIterableVariants.includes(queue.variant), [queue]);
  const typingVariant = useMemo(() => queue?.variant === learningQueueVariants.typing, [queue?.variant]);
  const backEnabled = useMemo(
    () => iterableVariant && !loading && ((threeSecsPassed && (playsAudio || typingVariant)) || !!previousChunk),
    [previousChunk, queue, loading, threeSecsPassed, playsAudio, typingVariant],
  );

  const backRef = useRef(() => {});
  useEffect(() => {
    backRef.current = async () => {
      if (!backEnabled) return;

      if (threeSecsPassed && (playsAudio || typingVariant)) {
        reloadCurrentChunk();
        return;
      }

      if (!previousChunk) return;

      setCurrentChunk(previousChunk);
      setPreviousChunk(getPreviousChunk(plan, incognitoMode, previousChunk.nodeId));
      setEnded(false);

      await conditionalLearn(props);
    };
  }, [
    threeSecsPassed,
    plan,
    incognitoMode,
    backEnabled,
    previousChunk,
    queue,
    plan,
    incognitoMode,
    updateGlobalStateLastLearnedNodeId,
    currentChunk,
    learningProgress,
    finalWpm,
    alreadyLearnedChunks,
    playsAudio,
    typingVariant,
  ]);

  const back = useCallback(
    debounce(
      async () => {
        return await backRef.current();
      },
      300,
      { leading: true, trailing: false },
    ),
    [],
  );

  return [back, backEnabled];
};
