import React from 'react';
import HabitAnswersSection from './HabitAnswersSection';
import List from '../../shared/lists/List';

const HabitAnswers = (props) => {
  const { locked, date, sections, useSectionTitle } = props;
  return (
    <List collection={sections}>
      {(section) => (
        <div key={section.id} className="mt-25px first:mt-0">
          <HabitAnswersSection date={date} locked={locked} section={section} useSectionTitle={useSectionTitle} />
        </div>
      )}
    </List>
  );
};

export default HabitAnswers;
