import React, { isValidElement } from 'react';
import Clickable from '../Clickable';
import Hoverable from '../Hoverable';
import AddIcon from '../../icons/addIcon';
import ThemeCrumb from '../ThemeCrumb';
import HiddenEyeIcon from '../../icons/hiddenEyeIcon';
import SafeIcon from '../../icons/safeIcon';
import { initT } from '../../helpers/i18nHelpers';
import TooltipWrapper from '../TooltipWrapper';
import BinIcon from '../../icons/binIcon';
import EditIcon from '../../icons/editIcon';
import ArrowRightIcon from '../../icons/arrowRightIcon';
import ArrowDownIcon from '../../icons/arrowDownIcon';
import CancelIcon from '../../icons/cancelIcon';
import MinusIcon from '../../icons/minusIcon';
import ColorIcon from '../../icons/ColorIcon';
import TodoIcon from '../../icons/todoIcon';
import CheckIcon from '../../icons/checkIcon';
import AttachmentIcon from '../../icons/attachmentIcon';
import HeadingIcon from '../../icons/headingIcon';
import TextIcon from '../../icons/textIcon';
import ImageIcon from '../../icons/imageIcon';
import DuplicateIcon from '../../icons/duplicateIcon';
import BoldIcon from '../../icons/boldIcon';
import ItalicIcon from '../../icons/italicIcon';
import UnderlineIcon from '../../icons/underlineIcon';
import StrikethroughIcon from '../../icons/strikethroughIcon';
import CodeIcon from '../../icons/codeIcon';
import LeftTextIcon from '../../icons/leftTextIcon';
import CenterTextIcon from '../../icons/centerTextIcon';
import RightTextIcon from '../../icons/rightTextIcon';
import JustifyTextIcon from '../../icons/justifyTextIcon';
import LinkIcon from '../../icons/linkIcon';
import UndoIcon from '../../icons/undoIcon';
import RedoIcon from '../../icons/redoIcon';
import BulletsIcon from '../../icons/bulletsIcon';
import OrderedBulletsIcon from '../../icons/orderedBulletsIcon';
import ChecklistBulletsIcon from '../../icons/checklistBulletsIcon';
import IncreaseIndentIcon from '../../icons/increaseIndentIcon';
import DecreaseIndentIcon from '../../icons/decreaseIndentIcon';
import ResetIcon from '../../icons/resetIcon';
import UnlockIcon from '../../icons/unlockIcon';
import ArrowUpIcon from '../../icons/arrowUpIcon';
import ArrowLeftIcon from '../../icons/arrowLeftIcon';
import FaceEcstaticIcon from '../../icons/faceEcstaticIcon';
import ChartIcon from '../../icons/chartIcon';
import FaceHappyIcon from '../../icons/faceHappyIcon';
import FaceNeutralIcon from '../../icons/faceNeutralIcon';
import FaceSadIcon from '../../icons/faceSadIcon';
import FaceCryingIcon from '../../icons/faceCryingIcon';
import ChangeWorkspaceIcon from '../../icons/changeWorkspaceIcon';
import MicrophoneIcon from '../../icons/microphoneIcon';
import PlayIcon from '../../icons/playIcon';
import StopIcon from '../../icons/stopIcon';
import AudioIcon from '../../icons/audioIcon';
import PdfIcon from '../../icons/pdfIcon';
import LabelIcon from '../../icons/labelIcon';
import CategoryIcon from '../../icons/categoryIcon';
import CalendarIcon from '../../icons/calendarIcon';
import ListIcon from '../../icons/listIcon';
import SearchIcon from '../../icons/searchIcon';
import CutIcon from '../../icons/cutIcon';
import PauseIcon from '../../icons/pauseIcon';
import ExitIcon from '../../icons/exitIcon';

const t = initT('button');

const getThemeType = (variant) => {
  return (
    {
      markHidden: 'buttonFaded',
      markPrivate: 'buttonFaded',
      markTodo: 'buttonFaded',
      check: 'confirm',
      confirm: 'confirm',
      submit: 'confirm',
      ok: 'confirm',
    }[variant] || 'button'
  );
};

const getIconSizeClass = (variant) => {
  return (
    {
      check: 'w-4 h-4',
      close: 'w-3 h-3',
      cancel: 'w-3 h-3',
      bold: 'w-3 h-3',
      italic: 'w-3 h-3',
      underline: 'w-3 h-3',
      strikethrough: 'w-3 h-3',
      code: 'w-3 h-3',
      leftText: 'w-3 h-3',
      centerText: 'w-3 h-3',
      rightText: 'w-3 h-3',
      justifyText: 'w-3 h-3',
      link: 'w-3 h-3',
      undo: 'w-3 h-3',
      redo: 'w-3 h-3',
      bullets: 'w-3 h-3',
      orderedBullets: 'w-3 h-3',
      checklistBullets: 'w-3 h-3',
      increaseIndent: 'w-3 h-3',
      decreaseIndent: 'w-3 h-3',
    }[variant] || 'w-4 h-4'
  );
};

const getPaddingClass = (variant) => {
  return (
    {
      add: 'p-1',
    }[variant] || 'p-2'
  );
};

const getIcon = (variant, iconClass) => {
  return {
    add: <AddIcon className={iconClass} />,
    close: <CancelIcon className={iconClass} />,
    cancel: <CancelIcon className={iconClass} />,
    delete: <BinIcon className={iconClass} />,
    edit: <EditIcon className={iconClass} />,
    submit: <CheckIcon className={iconClass} />,
    confirm: <CheckIcon className={iconClass} />,
    ok: <CheckIcon className={iconClass} />,
    unlock: <UnlockIcon className={iconClass} />,
    chart: <ChartIcon className={iconClass} />,
    showDetails: <ArrowRightIcon className={iconClass} />,
    hideDetails: <ArrowDownIcon className={iconClass} />,
    markHidden: <HiddenEyeIcon className={iconClass} />,
    unmarkHidden: <HiddenEyeIcon className={iconClass} />,
    markPrivate: <SafeIcon className={iconClass} />,
    unmarkPrivate: <SafeIcon className={iconClass} />,
    markTodo: <TodoIcon className={iconClass} />,
    unmarkTodo: <TodoIcon className={iconClass} />,
    plus: <AddIcon className={iconClass} />,
    minus: <MinusIcon className={iconClass} />,
    color: <ColorIcon className={iconClass} />,
    check: <CheckIcon className={iconClass} />,
    heading: <HeadingIcon className={iconClass} />,
    text: <TextIcon className={iconClass} />,
    image: <ImageIcon className={iconClass} />,
    attachment: <AttachmentIcon className={iconClass} />,
    duplicate: <DuplicateIcon className={iconClass} />,
    bold: <BoldIcon className={iconClass} />,
    italic: <ItalicIcon className={iconClass} />,
    underline: <UnderlineIcon className={iconClass} />,
    strikethrough: <StrikethroughIcon className={iconClass} />,
    code: <CodeIcon className={iconClass} />,
    leftText: <LeftTextIcon className={iconClass} />,
    centerText: <CenterTextIcon className={iconClass} />,
    rightText: <RightTextIcon className={iconClass} />,
    justifyText: <JustifyTextIcon className={iconClass} />,
    link: <LinkIcon className={iconClass} />,
    undo: <UndoIcon className={iconClass} />,
    redo: <RedoIcon className={iconClass} />,
    bullets: <BulletsIcon className={iconClass} />,
    orderedBullets: <OrderedBulletsIcon className={iconClass} />,
    checklistBullets: <ChecklistBulletsIcon className={iconClass} />,
    increaseIndent: <IncreaseIndentIcon className={iconClass} />,
    decreaseIndent: <DecreaseIndentIcon className={iconClass} />,
    reset: <ResetIcon className={iconClass} />,
    markUpdated: <CheckIcon className={iconClass} />,
    arrowLeft: <ArrowLeftIcon className={iconClass} />,
    arrowUp: <ArrowUpIcon className={iconClass} />,
    arrowRight: <ArrowRightIcon className={iconClass} />,
    arrowDown: <ArrowDownIcon className={iconClass} />,
    crying: <FaceCryingIcon className={iconClass} />,
    sad: <FaceSadIcon className={iconClass} />,
    neutral: <FaceNeutralIcon className={iconClass} />,
    happy: <FaceHappyIcon className={iconClass} />,
    ecstatic: <FaceEcstaticIcon className={iconClass} />,
    changeWorkspace: <ChangeWorkspaceIcon className={iconClass} />,
    microphone: <MicrophoneIcon className={iconClass} />,
    play: <PlayIcon className={iconClass} />,
    pause: <PauseIcon className={iconClass} />,
    stop: <StopIcon className={iconClass} />,
    audio: <AudioIcon className={iconClass} />,
    pdf: <PdfIcon className={iconClass} />,
    label: <LabelIcon className={iconClass} />,
    category: <CategoryIcon className={iconClass} />,
    calendar: <CalendarIcon className={iconClass} />,
    journal: <ListIcon className={iconClass} />,
    search: <SearchIcon className={iconClass} />,
    cut: <CutIcon className={iconClass} />,
    exit: <ExitIcon className={iconClass} />,
  }[variant];
};

const IconButton = (props) => {
  const {
    variant,
    disabled = false,
    tooltip = t(variant),
    themeType = getThemeType(variant),
    hoveredThemeType = 'hovered',
    className = '',
    widthClass = '',
    displayClass = 'flex items-center',
    paddingClass = getPaddingClass(variant),
    iconSizeClass = getIconSizeClass(variant),
    preprocessIcon,
    children = false,
    wrapperClass = '',
    ...otherProps
  } = props;

  let _children = getIcon(variant, iconSizeClass);
  if (preprocessIcon) _children = preprocessIcon(_children);

  if (typeof children === 'function') _children = children(_children);
  else if (isValidElement(children)) _children = React.cloneElement(children, { icon: _children });

  let result = (
    <Clickable
      {...otherProps}
      className={`${className} ${widthClass} ${displayClass} ${paddingClass}`}
      disabled={disabled}
    >
      {_children}
    </Clickable>
  );

  if (!disabled)
    result = (
      <Hoverable hoveredThemeType={hoveredThemeType} className="rounded-md" sizeClass={widthClass}>
        {result}
      </Hoverable>
    );

  result = <ThemeCrumb type={themeType}>{result}</ThemeCrumb>;

  if (tooltip)
    result = (
      <TooltipWrapper tooltip={tooltip} widthClass={widthClass}>
        {result}
      </TooltipWrapper>
    );

  if (wrapperClass) result = <div className={wrapperClass}>{result}</div>;

  return result;
};

export default IconButton;
