import frenchkiss from 'frenchkiss';

frenchkiss.extend('en', {
  pages: {
    calendar: {
      today: 'today',
      unlock: 'Enable changes',
      previousDay: 'Previous day',
      nextDay: 'Next day',
      menu: {
        title: 'Menu',
        general: 'General',
        calendar: 'Events calendar',
      },
      events: {
        title: 'Events',
      },
      todoTasks: {
        title: 'TODO',
      },
      hiddenHabits: {
        title: 'Hidden habits',
      },
      checkedTasks: {
        title: 'Done',
      },
      habitAnswers: {
        title: 'Habits',
        notYetUpdated: 'Not yet updated',
        summaries: {
          variants: {
            sinceWeekday: 'since last {weekday}',
            sinceCustomPeriod: 'during last {daysCounter} day(s)',
            monthly: 'this month',
            annually: 'this year',
          },
        },
      },
      dayEvaluations: {
        crying: 'Today I felt like the world should ended',
        sad: "It certainly wasn't a good day",
        neutral: 'It was ok',
        happy: 'I felt happy today',
        ecstatic: 'It was a perfect day!',
      },
    },
  },
});
