import React from 'react';
import ThemeCrumb from '../ThemeCrumb';
import H2 from '../titles/H2';
import { activeElementsActionDeactivate } from '../../stateManagement/actions/activeElementsActions';
import { connect } from 'react-redux';
import IconButton from '../buttons/IconButton';

const ModalHeader = ({
  children,
  type,
  identifier,
  modalType = type,
  modalObjectIdentifier = identifier,
  activeElementsActionDeactivate,
  onRequestClose = () => activeElementsActionDeactivate(modalType, modalObjectIdentifier),
  modalHeaderHeight = '75px',
}) => {
  const closingIcon = (
    <div className="pr-2 pt-2">
      <IconButton variant="close" onMouseDown={onRequestClose} tooltip={false} />
    </div>
  );

  return (
    <ThemeCrumb type="modalHeader">
      <div className="flex border-b" style={{ height: modalHeaderHeight }}>
        <div className="px-25px overflow-y-hidden w-full h-full flex items-center">
          <H2 textAlignmentClass="text-left">{children}</H2>
        </div>
        {closingIcon}
      </div>
    </ThemeCrumb>
  );
};

export default connect(null, { activeElementsActionDeactivate })(ModalHeader);
