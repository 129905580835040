import { useEffect, useMemo, useState } from 'react';
import { useCheckIfMounted } from './useCheckIfMounted';
import { api } from '../helpers/apiHelpers';
import { getNotesTreeHash } from '../helpers/notesHelpers';

const produceFetchWorkspaceId = (noteId, setWorkspaceId, checkIfMounted) => async () => {
  const response = await api.notes.getWorkspaceId(noteId);
  if (response.ok && checkIfMounted()) setWorkspaceId(response.data);
};

export const useNoteFromUnknownWorkspace = (props) => {
  const { noteId, notesWorkspaces, notes, incognitoMode, notesWorkspacesActionFetch, notesActionFetch } = props;

  const checkIfMounted = useCheckIfMounted();

  const [workspaceId, setWorkspaceId] = useState(null);

  const note = useMemo(() => {
    if (noteId) return notes.find({ id: noteId }, false);
  }, [notes, noteId]);

  useEffect(() => {
    if (!note && noteId) produceFetchWorkspaceId(noteId, setWorkspaceId, checkIfMounted)();
  }, [note, noteId]);

  useEffect(() => {
    if (workspaceId) notesActionFetch(workspaceId);
  }, [workspaceId]);

  useEffect(() => {
    if (!notesWorkspaces.fetched) notesWorkspacesActionFetch();
  }, []);

  return useMemo(() => {
    if (!note || !notesWorkspaces.fetched) return undefined;
    const workspace = notesWorkspaces.find({ id: note.notesWorkspaceId }, true, incognitoMode);
    if (!workspace) return undefined;

    const visible = !!getNotesTreeHash(notes, note.notesWorkspaceId, incognitoMode)[note.id];
    return visible ? note : undefined;
  }, [notesWorkspaces, incognitoMode, notes, note]);
};
