export const dayEvaluationsChartsDataActionFetch = (date, variant) => ({
  type: 'DAY_EVALUATIONS_CHARTS_DATA_ACTION_FETCH',
  payload: { date, variant },
});

export const dayEvaluationsChartsDataActionSet = (date, variant, newDayEvaluationsChartData) => ({
  type: 'DAY_EVALUATIONS_CHARTS_DATA_ACTION_SET',
  payload: { date, variant, newDayEvaluationsChartData },
});

export const dayEvaluationsChartsDataActionReset = () => ({
  type: 'DAY_EVALUATIONS_CHARTS_DATA_ACTION_RESET',
});
