import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { snackbarMessagesActionHandleBackendErrors } from '../../actions/snackbarMessagesActions';
import { recurringEventFromDto, recurringEventToDto } from '../../../helpers/model/recurringEventHelpers';
import {
  recurringEventsActionSetElement,
  recurringEventsActionUnlockElement,
} from '../../actions/recurringEventsActions';

export default function* unmarkPrivateElementRecurringEventsSaga(action) {
  const initialRecurringEvent = action.payload;
  const { ok, data } = yield call(api.recurringEvents.unmarkPrivate, recurringEventToDto(initialRecurringEvent));
  if (ok) {
    yield put(recurringEventsActionSetElement(recurringEventFromDto(data)));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(recurringEventsActionUnlockElement(initialRecurringEvent));
  }
}
