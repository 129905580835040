import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import Habits from './Habits';
import find from 'lodash/find';
import FullPageNoData from '../../../shared/placeholders/FullPageNoData';
import HabitAdditionPanel from './HabitAdditionPanel';
import { useParams, useHistory } from 'react-router-dom';
import { getAllHabitsSections } from '../../../helpers/habitsSectionHelpers';

const Main = (props) => {
  const { incognitoMode, habitsSections } = props;

  const { habitsSectionId } = useParams();
  const history = useHistory();

  const visibleSections = getAllHabitsSections(habitsSections, incognitoMode);
  const section = find(visibleSections, { id: parseInt(habitsSectionId, 10) });
  if (!section && visibleSections.length > 0)
    // a bit hacky without setTimeout, but it makes the UX better
    history.replace(`/habits/${visibleSections[0].id}`);

  const [additionMode, setAdditionMode] = useState(false);
  useEffect(() => setAdditionMode(false), [(section || {}).id]);

  if (!section) return <FullPageNoData />;

  return (
    <div className="w-full pb-25px">
      <HabitAdditionPanel
        key={section.id}
        section={section}
        additionMode={additionMode}
        setAdditionMode={setAdditionMode}
      />
      <div className="px-25px">
        <Habits section={section} additionMode={additionMode} />
      </div>
    </div>
  );
};

export default connect(prepareState(['incognitoMode', 'habitsSections']))(Main);
