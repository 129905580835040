export const learningQueueVariants = {
  overview: 'overview',
  typing: 'typing',
  audio: 'audio',
  pdf: 'pdf',
};

export const learningQueueIterableVariants = [
  learningQueueVariants.overview,
  learningQueueVariants.typing,
  learningQueueVariants.audio,
];

export const learningQueuePausableVariants = [learningQueueVariants.audio, learningQueueVariants.typing];
