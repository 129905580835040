import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { prepareCollection } from '../../../helpers/model/modelHelpers';
import { recurringTasksActionSet } from '../../actions/recurringTasksActions';
import { recurringTaskFromDto } from '../../../helpers/model/recurringTaskHelpers';

export default function* fetchRecurringTasksSaga(action) {
  const { ok, data } = yield call(api.recurringTasks.index, action.payload);
  if (ok) {
    yield put(recurringTasksActionSet(prepareCollection(data, recurringTaskFromDto)));
  }
}
