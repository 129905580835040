import frenchkiss from 'frenchkiss';

frenchkiss.extend('en', {
  features: {
    nodesGroupPanel: {
      addNode: 'Add node',
      deleteNode: 'Delete node',
      manageAudio: 'Manage assigned audio',
      playAudio: 'Play assigned audio',
      stopAudio: 'Stop assigned audio',
      attachments: 'Attachments',
      download: 'Download',
      imageEditor: {
        size: 'Size',
        sizes: {
          small: 'Small',
          medium: 'Medium',
          large: 'Large',
        },
        description: 'Description',
        removeImage: 'Remove image',
      },
      attachmentsEditor: {
        add: 'Add attachment',
        remove: 'Remove attachment',
        description: 'Description',
      },
    },
  },
});
