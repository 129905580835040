export const notesActionFetch = (notesWorkspaceId) => ({
  type: 'NOTES_ACTION_FETCH',
  payload: { notesWorkspaceId },
});

export const notesActionSet = (notesWorkspaceId, newElements) => ({
  type: 'NOTES_ACTION_SET',
  payload: { notesWorkspaceId, newElements },
});

export const notesActionCreateElement = (element) => ({
  type: 'NOTES_ACTION_CREATE_ELEMENT',
  payload: { element },
});

export const notesActionUnlock = () => ({
  type: 'NOTES_ACTION_UNLOCK',
});

export const notesActionUpdateElement = (element) => ({
  type: 'NOTES_ACTION_UPDATE_ELEMENT',
  payload: { element },
});

export const notesActionDuplicateElement = (element) => ({
  type: 'NOTES_ACTION_DUPLICATE_ELEMENT',
  payload: { element },
});

export const notesActionMarkPrivateElement = (element) => ({
  type: 'NOTES_ACTION_MARK_PRIVATE_ELEMENT',
  payload: { element: element.assignValues({ private: true }) },
});

export const notesActionUnmarkPrivateElement = (element) => ({
  type: 'NOTES_ACTION_UNMARK_PRIVATE_ELEMENT',
  payload: { element: element.assignValues({ private: false }) },
});

export const notesActionLockElement = (element) => ({
  type: 'NOTES_ACTION_LOCK_ELEMENT',
  payload: { element },
});

export const notesActionUnlockElement = (element) => ({
  type: 'NOTES_ACTION_UNLOCK_ELEMENT',
  payload: { element },
});

export const notesActionDeleteElement = (element) => ({
  type: 'NOTES_ACTION_DELETE_ELEMENT',
  payload: { element },
});

export const notesActionSetCreatedElement = (element) => ({
  type: 'NOTES_ACTION_SET_CREATED_ELEMENT',
  payload: { element },
});

export const notesActionSetElement = (element) => ({
  type: 'NOTES_ACTION_SET_ELEMENT',
  payload: { element },
});

export const notesActionUnset = (oneOrManyElements) => ({
  type: 'NOTES_ACTION_UNSET',
  payload: { oneOrManyElements },
});

export const notesActionReset = () => ({
  type: 'NOTES_ACTION_RESET',
});
