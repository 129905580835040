import React from 'react';
import { useParams } from 'react-router-dom';
import MenuContent from '../../../shared/organizers/MenuContent';
import MenuTitle from '../../../shared/titles/MenuTitle';
import { initT } from '../../../helpers/i18nHelpers';
import { modelNames } from './helpers';
import SidebarButton from '../../../shared/buttons/SidebarButton';

const t = initT('pages.wasteBin');

const Left = () => {
  const { modelName: selectedModelName } = useParams();

  return (
    <MenuContent
      top={<MenuTitle>{t('menu.title')}</MenuTitle>}
      main={
        <div className="px-25px">
          {modelNames.map((modelName) => {
            return (
              <SidebarButton key={modelName} selected={selectedModelName === modelName} to={`/waste-bin/${modelName}`}>
                {t(`pluralResources.${modelName}`)}
              </SidebarButton>
            );
          })}
        </div>
      }
    />
  );
};

export default Left;
