import React from 'react';
import ThemeCrumb from '../ThemeCrumb';
import ExchangeableContents from '../ExchangeableContents';

const LockedEntityLoader = ({ children, visible = true, sizeClass = 'w-8 h-8' }) => {
  return (
    <ExchangeableContents
      leaderVisible={!visible}
      leader={children}
      follower={
        <ThemeCrumb type="loader">
          <div className={`${sizeClass} lds-ripple max-w-fit-content`}>
            <div></div>
            <div></div>
          </div>
        </ThemeCrumb>
      }
    />
  );
};

export default LockedEntityLoader;
