import React from 'react';
import StandardContentTemplate from '../../shared/contentTemplates/StandardContentTemplate';
import Top from './Top';
import Left from './Left';
import Main from './Main';
import { connect } from 'react-redux';
import { prepareState } from '../../helpers/stateManagementHelpers';
import { configActionUpdate } from '../../stateManagement/actions/configActions';
import { useWorkspace } from './useWorkspace';
import { languageWorkspacesActionFetch } from '../../stateManagement/actions/languageWorkspacesActions';

const LanguagePage = (props) => {
  const [workspaceSelected, workspace] = useWorkspace(props);

  return (
    <StandardContentTemplate
      top={<Top />}
      leftReady={workspaceSelected}
      left={<Left workspace={workspace} />}
      mainReady={workspaceSelected}
      main={<Main />}
    />
  );
};

export default connect(prepareState(['incognitoMode', 'languageWorkspaces', 'config']), {
  languageWorkspacesActionFetch,
  configActionUpdate,
})(LanguagePage);
