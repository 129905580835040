import fromPairs from 'lodash/fromPairs';

export const getCurrentHref = () => window.location.href.toString().split(window.location.host)[1];

export const searchParamsToObject = (searchParams) => fromPairs([...new URLSearchParams(searchParams).entries()]);
export const updateSearchParams = (history, updates) => {
  history.replace({
    pathname: history.pathname,
    search:
      '?' +
      new URLSearchParams({
        ...searchParamsToObject(history.location.search),
        ...updates,
      }).toString(),
  });
};
