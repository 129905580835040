import React from 'react';

export const ChartIcon = ({ className = '', style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className={`fill-current ${className}`}
    style={style}
  >
    <path
      d="M24 3.875l-6 1.221 1.716 1.708-5.351 5.358-3.001-3.002-7.336 7.242 1.41 1.418
                 5.922-5.834 2.991 2.993 6.781-6.762 1.667 1.66 1.201-6.002zm0
                 16.125v2h-24v-20h2v18h22z"
    />
  </svg>
);
export default ChartIcon;
