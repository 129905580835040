import { put } from 'redux-saga/effects';
import { flatten } from 'lodash';
import { nodesActionUnsetGroup } from '../../actions/nodesActions';

export default function* unsetRecurringEventsSaga(action) {
  const recurringEvents = flatten([action.payload]);

  for (const recurringEvent of recurringEvents) {
    yield put(nodesActionUnsetGroup(recurringEvent.nodesGroupId));
  }
}
