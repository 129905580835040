import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../helpers/stateManagementHelpers';
import Found from './Found';
import { checkedTasksActionFetch } from '../../stateManagement/actions/checkedTasksActions';
import { categoriesActionFetch } from '../../stateManagement/actions/categoriesActions';
import { activeElementTypes } from '../../constants/activeElementTypes';
import CheckIcon from '../../icons/checkIcon';
import TaskPanel from '../../shared/features/taskPanel';

const FoundCheckedTask = (props) => {
  const { dates, needle, checkedTasks, categories, incognitoMode, checkedTasksActionFetch, categoriesActionFetch } =
    props;

  useEffect(() => {
    if (!categories.fetched) categoriesActionFetch();
  }, []);

  return (
    <Found
      needle={needle}
      dates={dates}
      collection={checkedTasks}
      dateAttributeName="checkedDate"
      searchedAttributeName="description"
      incognitoMode={incognitoMode}
      fetchAction={checkedTasksActionFetch}
      renderFoundElement={(checkedTask) => {
        const category = categories.find({ id: checkedTask.categoryId }) || {};
        return (
          <div className="pb-2 flex justify-center" key={checkedTask.id}>
            <div className="w-full max-w-96">
              <TaskPanel
                task={checkedTask}
                color={category.color}
                disabled
                activeElementType={activeElementTypes.doneTask}
                descriptionAdditionalClass="line-through"
                bulletIcon={<CheckIcon className="w-3 h-3" />}
              />
            </div>
          </div>
        );
      }}
    />
  );
};

export default connect(prepareState(['categories', 'checkedTasks', 'incognitoMode']), {
  checkedTasksActionFetch,
  categoriesActionFetch,
})(FoundCheckedTask);
