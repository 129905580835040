import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { tasksActionSetElement, tasksActionUnlockElement } from '../../actions/tasksActions';
import { taskFromDto, taskToDto } from '../../../helpers/model/taskHelpers';
import { snackbarMessagesActionHandleBackendErrors } from '../../actions/snackbarMessagesActions';

export default function* markTodoElementTasksSaga(action) {
  const initialTask = action.payload;
  const { ok, data } = yield call(api.tasks.markTodo, taskToDto(initialTask));
  if (ok) {
    yield put(tasksActionSetElement(taskFromDto(data)));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(tasksActionUnlockElement(initialTask));
  }
}
