import frenchkiss from 'frenchkiss';

export const modelNames = [
  'Category',
  'Task',
  'RecurringTask',
  'HabitsSection',
  'Habit',
  'Event',
  'RecurringEvent',
  'NotesWorkspace',
  'Note',
  'Node',
  'LearningWorkspace',
  'LearningQueue',
  'LanguageWorkspace',
];

frenchkiss.extend('en', {
  pages: {
    wasteBin: {
      menu: {
        title: 'Waste bin',
      },
      singularResources: {
        Category: 'Tasks Category',
        Task: 'Task',
        RecurringTask: 'Recurring Task',
        HabitsSection: 'Habits Section',
        Habit: 'Habit',
        Event: 'Event',
        RecurringEvent: 'Recurring Event',
        NotesWorkspace: 'Notes Workspace',
        Note: 'Note',
        Node: 'Node',
        LearningWorkspace: 'Learning Workspace',
        LearningQueue: 'Learning Queue',
        LanguageWorkspace: 'Language Workspace',
      },
      pluralResources: {
        Category: 'Tasks Categories',
        Task: 'Tasks',
        RecurringTask: 'Recurring Tasks',
        HabitsSection: 'Habits Sections',
        Habit: 'Habits',
        Event: 'Events',
        RecurringEvent: 'Recurring Events',
        NotesWorkspace: 'Notes Workspaces',
        Note: 'Notes',
        Node: 'Nodes',
        LearningWorkspace: 'Learning Workspaces',
        LearningQueue: 'Learning Queues',
        LanguageWorkspace: 'Language Workspaces',
      },
      restore: 'Restore',
      cannotBeRestored: 'This item cannot be restored, probably its parent was deleted as well',
      delete: 'Delete permanently',
    },
  },
});
