import { put } from 'redux-saga/effects';
import { flatten } from 'lodash';
import { nodesActionUnsetGroup } from '../../actions/nodesActions';

export default function* unsetRecurringTasksSaga(action) {
  const recurringTasks = flatten([action.payload]);

  for (const recurringTask of recurringTasks) {
    yield put(nodesActionUnsetGroup(recurringTask.nodesGroupId));
  }
}
