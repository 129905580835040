import React, { PureComponent, createRef } from 'react';

class FullWindowHeightWrapper extends PureComponent {
  constructor(props) {
    super(props);
    const { marginTopPx, marginBottomPx = 25 } = props;
    this.ref = createRef();
    this.state = {
      height: null,
      marginTopPx,
      marginBottomPx,
    };
  }

  updateHeight() {
    setTimeout(() => {
      if (this.ref && this.ref.current) {
        const { marginTopPx = this.ref.current.getBoundingClientRect().top, marginBottomPx } = this.state;
        const height = window.innerHeight - marginTopPx - marginBottomPx;
        this.setState({ height });
      }
    });
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateHeight.bind(this));
    this.updateHeight();
  }

  componentDidUpdate() {
    const { marginBottomPx = 25 } = this.props;
    this.setState({ marginBottomPx }, () => this.updateHeight());
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateHeight.bind(this));
  }

  render() {
    const { height } = this.state;
    const { children, minHeight } = this.props;
    return (
      <div ref={this.ref} className="w-full overflow-y-auto overscroll-contain" style={{ height, minHeight }}>
        {children}
      </div>
    );
  }
}

export default FullWindowHeightWrapper;
