import { DateTime } from 'luxon';
import { backendDateFormat } from '../../constants/dateFormat';
import DayEvaluationsChartData from '../../models/dayEvaluationsChartData';
import { dayEvaluationsChartRequestVariants } from '../../constants/enums/dayEvaluationsChartRequestVariants';

export const dayEvaluationsChartDataFromDto = (dto, date, variant) => {
  const result = new DayEvaluationsChartData();
  result.variant = variant;
  result.data = fillInGaps(dto, date, variant);
  return result;
};

const fillInGaps = (data, endDate, variant) => {
  if ([0, 1].includes(data.length)) return data;
  if (variant === dayEvaluationsChartRequestVariants.daily) return fillInDailyGaps(data, endDate);
  if (variant === dayEvaluationsChartRequestVariants.weekly) return fillInWeeklyGaps(data, endDate);
  if (variant === dayEvaluationsChartRequestVariants.monthly) return fillInMonthlyGaps(data, endDate);
  return data;
};

const fillInDailyGaps = (data, endDate) => {
  return fillInGapsCommonLogic(data, endDate, (previousLabel) => {
    const currentDateTime = DateTime.fromFormat(previousLabel, backendDateFormat);
    return currentDateTime.plus({ days: 1 }).toFormat(backendDateFormat);
  });
};

const fillInWeeklyGaps = (data, endDate) => {
  const endDateTime = DateTime.fromFormat(endDate, backendDateFormat);
  return fillInGapsCommonLogic(
    data,
    `${endDateTime.get('weekNumber')}/${endDateTime.get('weekYear')}`,
    (previousLabel) => {
      const splitted = previousLabel.split('/');
      const isoWeek = parseInt(splitted[0], 10);
      const isoYear = parseInt(splitted[1], 10);
      const maxIsoWeek = DateTime.utc(isoYear, 12, 31).get('weekNumber');
      if (isoWeek !== maxIsoWeek) return `${isoWeek + 1}/${isoYear}`;
      else return `1/${isoYear + 1}`;
    },
  );
};

const fillInMonthlyGaps = (data, endDate) => {
  const endDateTime = DateTime.fromFormat(endDate, backendDateFormat);
  const maxMonth = 12;
  return fillInGapsCommonLogic(data, `${endDateTime.get('month')}/${endDateTime.get('year')}`, (previousLabel) => {
    const splitted = previousLabel.split('/');
    const month = parseInt(splitted[0], 10);
    const year = parseInt(splitted[1], 10);
    if (month !== maxMonth) return `${month + 1}/${year}`;
    else return `1/${year + 1}`;
  });
};

const fillInGapsCommonLogic = (data, endLabel, createNextLabel) => {
  const newData = [];
  let currentLabel = data[0].label;
  let currentIterator = 0;
  let safetyCounter = 0;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    if (currentLabel === data[currentIterator].label) {
      safetyCounter = 0;
      newData.push(data[currentIterator]);
      if (currentIterator < data.length - 1) currentIterator += 1;
    } else {
      newData.push({ label: currentLabel, value: undefined });
      safetyCounter += 1;
      if (safetyCounter === 1000) {
        currentLabel = currentIterator < data.length ? data[currentIterator].label : endLabel;
        safetyCounter = 0;
        continue;
      }
    }

    if (currentLabel === endLabel) break;

    currentLabel = createNextLabel(currentLabel);
  }
  return newData;
};
