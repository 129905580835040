import React, { useCallback, useMemo } from 'react';
import ButtonsGroup from '../../shared/buttons/ButtonsGroup';
import LearningWorkspaceSelector from './LearningWorkspaceSelector';
import IconButton from '../../shared/buttons/IconButton';
import H1 from '../../shared/titles/H1';
import CenteredContent from '../../shared/organizers/CenteredContent';
import { initT } from '../../helpers/i18nHelpers';
import { learningQueueVariants } from '../../constants/enums/learningQueueVariants';
import ConfirmationButton from '../../shared/buttons/ConfirmationButton';

const t = initT('pages.learning');

const QueueHeader = (props) => {
  const {
    queue,
    onH1DoubleClick,
    formVisible,
    setFormVisible,
    incognitoMode,
    learningQueuesActionMarkPrivateElement: markPrivate,
    learningQueuesActionUnmarkPrivateElement: unmarkPrivate,
    learningQueuesActionSetElement: setElement,
    learningQueuesActionUpdateElement: updateElement,
    learningQueuesActionDeleteElement: deleteElement,
  } = props;

  const switchPrivateVariant = queue.private ? 'unmarkPrivate' : 'markPrivate';
  const switchPrivate = () => (queue.private ? unmarkPrivate(queue) : markPrivate(queue));

  const toggleWithAudioEnabled = useMemo(
    () => [learningQueueVariants.overview, learningQueueVariants.typing].includes(queue.variant),
    [queue.variant],
  );
  const toggleWithAudio = useCallback(() => {
    if (queue.locked || !toggleWithAudioEnabled) return;

    const newQueue = queue.shallowClone().assignValues({
      ...queue,
      withAudio: !queue.withAudio,
    });
    // need to set element instantly to make sure audio stops if necessary
    setElement(newQueue);
    updateElement(newQueue);
  }, [queue, toggleWithAudioEnabled]);

  return (
    <CenteredContent
      heightPx={40}
      left={
        <ButtonsGroup
          className="flex px-2 sm:px-25px"
          buttons={[
            <LearningWorkspaceSelector key="changeWorkspace" queue={queue} />,
            incognitoMode ? null : (
              <IconButton key="switchPrivate" variant={switchPrivateVariant} onMouseDown={switchPrivate} />
            ),
          ]}
        />
      }
      main={<H1 onDoubleClick={onH1DoubleClick}>{queue.name}</H1>}
      right={
        <ButtonsGroup
          className="flex px-2 sm:px-25px"
          buttons={[
            toggleWithAudioEnabled ? (
              <IconButton
                key="toggleWithAudio"
                variant="audio"
                disabled={queue.locked}
                onMouseDown={toggleWithAudio}
                tooltip={t(queue.withAudio ? 'disableWithAudio' : 'enableWithAudio')}
                themeType={queue.withAudio ? 'button' : 'buttonFaded'}
              />
            ) : (
              <></>
            ),
            formVisible ? null : (
              <IconButton key="edit" variant="edit" tooltip={t('editQueue')} onMouseDown={() => setFormVisible(true)} />
            ),
            <ConfirmationButton
              key="delete"
              variant="delete"
              i18nKey="learningQueues.delete"
              onConfirmed={() => deleteElement(queue)}
              resourceName={queue.name}
            />,
          ]}
        />
      }
    />
  );
};

export default QueueHeader;
