import FetchableAndLockable from '../parents/fetchableAndLockable';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';
import find from 'lodash/find';
import some from 'lodash/some';

export default class Collection extends FetchableAndLockable {
  constructor(additionalData = {}) {
    super();
    this.additionalData = additionalData;
    this.elements = [];
  }

  withActualizedAdditionalData(newData, additionalOptions = {}) {
    const { createClone = true } = additionalOptions;
    let result = this;
    if (createClone) result = this.shallowClone();
    result.additionalData = { ...result.additionalData, ...newData };
    return result;
  }

  shallowClone() {
    const result = super.shallowClone();
    result.elements = [...result.elements];
    return result;
  }

  withActualizedElements(actualizedElements, relevantAttributes, additionalOptions = {}) {
    const { idKey = 'id', createClone = true } = additionalOptions;
    let result = this;
    if (createClone) result = this.shallowClone();
    if (!Array.isArray(actualizedElements)) actualizedElements = [actualizedElements];
    actualizedElements.forEach((element) => {
      const predicate = { [idKey]: element[idKey] };
      const index = findIndex(result.elements, predicate);
      if (index !== -1) {
        let currentElement = result.elements[index];
        if (createClone) currentElement = currentElement.shallowClone();
        relevantAttributes.forEach((attributeName) => {
          currentElement[attributeName] = element[attributeName];
        });
        result.elements[index] = currentElement;
      }
    });
    return result;
  }

  withReplacedElements(newElements, additionalOptions = {}) {
    const { idKey = 'id', createClone = true } = additionalOptions;
    let result = this;
    if (createClone) result = this.shallowClone();
    if (!Array.isArray(newElements)) newElements = [newElements];
    newElements.forEach((element) => {
      const predicate = { [idKey]: element[idKey] };
      const index = findIndex(result.elements, predicate);
      if (index === -1) {
        result.elements.push(element);
      }
      result.elements[index] = element;
    });
    return result;
  }

  withRemovedElements(elementsToRemove, additionalOptions = {}) {
    const { idKey = 'id', createClone = true } = additionalOptions;
    let result = this;
    if (createClone) result = this.shallowClone();
    if (!Array.isArray(elementsToRemove)) elementsToRemove = [elementsToRemove];
    result.elements = result.filter((e) => !some(elementsToRemove, [idKey, e[idKey]]));
    return result;
  }

  find(predicate, onlyVisible = true, incognitoMode = false) {
    const scope = onlyVisible ? this.visible(incognitoMode) : this.elements;
    return find(scope, predicate);
  }

  filter(predicate) {
    return filter(this.elements, predicate);
  }

  visible(incognitoMode = false, additionalPredicate = undefined) {
    const result = additionalPredicate ? this.filter(additionalPredicate) : this.elements;
    return filter(result, (element) => {
      if (element.visible === undefined) return true;
      return element.visible(incognitoMode);
    });
  }
}
