import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { notificationT } from '../../../helpers/i18nHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { habitsSectionsActionUnset, habitsSectionsActionUnlockElement } from '../../actions/habitsSectionsActions';
import { push } from 'connected-react-router';

export default function* deleteElementHabitsSectionsSaga(action) {
  const initialHabitsSection = action.payload;
  const { ok, data } = yield call(api.habitsSections.delete, initialHabitsSection.id);
  if (ok) {
    yield put(snackbarMessagesActionAdd(notificationT('habitsSections.delete.success')));
    yield put(habitsSectionsActionUnset(initialHabitsSection));
    yield put(push('/habits'));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(habitsSectionsActionUnlockElement(initialHabitsSection));
  }
}
