import React, { useCallback, useEffect, useRef, useMemo } from 'react';
import ChunkDisplay from '../ChunkDisplay';
import QueueRunAudioController from '../QueueRunAudioController';
import { useCheckIfMounted } from '../../../../hooks/useCheckIfMounted';
import { initT } from '../../../../helpers/i18nHelpers';

const t = initT('pages.learning.queueRun');

const defaultOnFinishTimeout = 2000;

const setProgress = (progress) => {
  const progressElement = document.getElementById('audio-queue-run-bottom-progress-bar');
  if (progressElement) progressElement.style.width = `${progress}%`;
};
const setLabel = (label) => {
  const labelElement = document.getElementById('audio-queue-run-bottom-label');
  if (labelElement) labelElement.innerHTML = label;
};

const AudioVariant = (props) => {
  const {
    paused,
    node,
    next,
    pause,
    resume,
    queue,
    audioFinished,
    setAudioFinished,
    queue: { withRepetitionDelay },
  } = props;

  const checkIfMounted = useCheckIfMounted();
  const nodeWithAudio = useMemo(() => !!(queue.withAudio && node && node.audioUrl), [queue, node]);

  const pausedRef = useRef(paused);
  useEffect(() => {
    pausedRef.current = paused;
  }, [paused]);

  const nextRef = useRef(next);
  useEffect(() => {
    nextRef.current = next;
  }, [next]);

  useEffect(() => {
    setProgress(0);
    setLabel('');
  }, []);

  const onAudioProcess = useCallback(({ seconds, duration }) => {
    setProgress((seconds / duration) * 100);
    setLabel('');
  }, []);

  const onAudioFinish = useCallback(({ duration }) => {
    setAudioFinished(true);
    const timeout = withRepetitionDelay ? duration * 1000 + defaultOnFinishTimeout : defaultOnFinishTimeout;
    let millisecondsPassed = 0;
    let lastTime = Date.now();

    setProgress(0);
    setLabel(t(withRepetitionDelay ? 'repetitionTime' : 'nextTimeout'));

    const intervalId = setInterval(() => {
      if (!checkIfMounted()) {
        clearInterval(intervalId);
        return;
      }
      if (pausedRef.current) {
        lastTime = null;
        return;
      }

      const newTime = Date.now();
      if (lastTime) millisecondsPassed += newTime - lastTime;
      lastTime = newTime;

      setProgress((millisecondsPassed / timeout) * 100);

      if (millisecondsPassed >= timeout) {
        clearInterval(intervalId);
        nextRef.current();
      }
    }, 10);
  }, []);

  const contentOnMouseDown = useCallback(() => {
    if (paused) resume();
    else pause();
  }, [paused, pause, resume]);

  return (
    <>
      <ChunkDisplay
        {...props}
        contentOnMouseDown={contentOnMouseDown}
        renderAdditionalContentBelow={(ready) =>
          ready && nodeWithAudio && !audioFinished ? (
            <QueueRunAudioController
              src={node.audioUrl}
              mask={node.audioMask}
              audioPaused={paused}
              onAudioProcess={onAudioProcess}
              onFinish={onAudioFinish}
            />
          ) : (
            <></>
          )
        }
      />
    </>
  );
};

export default AudioVariant;
