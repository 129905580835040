import React, { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import ReactDOM from 'react-dom';
import get from 'lodash/get';
import OnClickOutsideWrapper from '../../../onClickOutside/OnClickOutsideWrapper';

const Selectable = React.forwardRef((props, ref) => {
  const {
    id,
    trigger,
    menu,
    menuOpen: fixedMenuOpen,
    disabled,
    onBlur,
    widthClass = 'w-full',
    fixedMenuWidth = true,
  } = props;

  const [menuOpen, setMenuOpen] = useState(false);
  const [menuWidth, setMenuWidth] = useState();
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          altAxis: true,
          padding: 25,
        },
      },
    ],
  });

  useEffect(() => {
    if (ref) ref.current = referenceElement;
  }, [referenceElement]);

  const menuOpenOnTop = get(attributes, 'popper.data-popper-placement') === 'top';
  const menuOpenOnBottom = get(attributes, 'popper.data-popper-placement') === 'bottom';

  const openMenu = () => {
    if (fixedMenuOpen !== undefined) return;
    if (disabled) return;
    if (!referenceElement) return;

    setMenuOpen(true);
    setMenuWidth(
      fixedMenuWidth === true
        ? referenceElement.clientWidth
        : typeof fixedMenuWidth === 'number'
          ? `${fixedMenuWidth}px`
          : undefined,
    );
  };
  const closeMenu = () => {
    if (fixedMenuOpen !== undefined) return;
    if (disabled) return;

    setMenuOpen(false);
  };
  const toggleMenu = () => {
    menuOpen ? closeMenu() : openMenu();
  };

  const propsToPass = {
    openMenu,
    closeMenu,
    toggleMenu,
    menuOpen,
    menuOpenOnTop,
    menuOpenOnBottom,
    disabled,
  };

  return (
    <OnClickOutsideWrapper
      onClickOutside={closeMenu}
      sizeClass={widthClass}
      disableWhenModalOpen={false}
      active={menuOpen}
    >
      <div id={id} ref={setReferenceElement} className={`${widthClass} relative select-none`} onBlur={onBlur}>
        {React.cloneElement(trigger, propsToPass)}
        {menuOpen ? (
          ReactDOM.createPortal(
            <div
              ref={setPopperElement}
              className="z-40 selectable-menu-wrapper"
              style={{ ...styles.popper, width: menuWidth }}
              {...attributes.popper}
            >
              {React.cloneElement(menu, propsToPass)}
            </div>,
            document.querySelector('#tooltip-portal'),
          )
        ) : (
          <></>
        )}
      </div>
    </OnClickOutsideWrapper>
  );
});

export default Selectable;
