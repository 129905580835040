import React, { useEffect, useMemo } from 'react';
import LabelledButton from '../../shared/buttons/LabelledButton';
import AuxiliaryText from '../../shared/AuxiliaryText';
import Container from '../../shared/organizers/containers/Container';
import Separator from '../../shared/Separator';
import { enumT, initT } from '../../helpers/i18nHelpers';
import { learningQueueVariants as variants } from '../../constants/enums/learningQueueVariants';
import { getSourceVariant } from '../../helpers/model/learningQueueHelpers';
import { prepareState } from '../../helpers/stateManagementHelpers';
import { labelsActionFetch } from '../../stateManagement/actions/labelsActions';
import { connect } from 'react-redux';
import { notesActionFetch } from '../../stateManagement/actions/notesActions';
import { notesWorkspacesActionFetch } from '../../stateManagement/actions/notesWorkspacesActions';
import { learningQueueSourceVariants as sourceVariants } from '../../constants/enums/learningQueueSourceVariant';
import { getAllNotesWorkspaces } from '../../helpers/notesHelpers';
import { find, get } from 'lodash';
import { useNoteFromUnknownWorkspace } from '../../hooks/useNoteFromUnknownWorkspace';
import isHotkey from 'is-hotkey';
import QueueVariantIcon from './QueueVariantIcon';

const t = initT('pages.learning.queuePresenter');

const useSource = (sourceVariant, props) => {
  const { queue, incognitoMode, labels, notesWorkspaces, labelsActionFetch, notesWorkspacesActionFetch } = props;

  const sourceNote = useNoteFromUnknownWorkspace({ ...props, noteId: queue.sourceNoteId });

  useEffect(() => {
    if (sourceVariant === sourceVariants.label && !labels.fetched) labelsActionFetch();
    if (sourceVariant === sourceVariants.notesWorkspace && !notesWorkspaces.fetched) notesWorkspacesActionFetch();
  }, [sourceVariant]);

  return useMemo(() => {
    if (sourceVariant === sourceVariants.label && labels.fetched) {
      const label = labels.visible(incognitoMode, { id: queue.sourceLabelId })[0];
      return get(label, 'name');
    }
    if (sourceVariant === sourceVariants.notesWorkspace && notesWorkspaces.fetched) {
      const workspaces = getAllNotesWorkspaces(notesWorkspaces, incognitoMode);
      const workspace = find(workspaces, { id: queue.sourceNotesWorkspaceId });
      return get(workspace, 'name');
    }
    if (sourceVariant === sourceVariants.note && sourceNote) {
      return get(sourceNote, 'name');
    }
  }, [queue, sourceVariant, incognitoMode, labels, notesWorkspaces, sourceNote]);
};

const drawDataRow = (label, value) =>
  value ? (
    <div className="w-full flex items-center">
      <AuxiliaryText widthClass="w-inherit whitespace-nowrap">
        <b>{label}</b>
      </AuxiliaryText>
      <div className="w-full flex justify-end truncate">
        <AuxiliaryText widthClass="w-inherit whitespace-nowrap">{value}</AuxiliaryText>
      </div>
    </div>
  ) : (
    <></>
  );

const QueuePresenter = (props) => {
  const { queue, setRunMode } = props;

  useEffect(() => {
    const nextCb = (e) => {
      if (isHotkey('space', e)) setRunMode(true);
    };
    window.addEventListener('keydown', nextCb);
    return () => window.removeEventListener('keydown', nextCb);
  }, []);

  const variant = enumT(`learningQueueVariants.${queue.variant}`);
  let variantAdditionalInfo;
  if ([variants.overview, variants.typing].includes(queue.variant) && queue.withAudio)
    variantAdditionalInfo = t('withAudio');
  else if (queue.variant === variants.audio && queue.withRepetitionDelay)
    variantAdditionalInfo = t('withRepetitionDelay');

  const _sourceVariant = getSourceVariant(queue);
  const sourceVariant = enumT(`learningQueueSourceVariants.${_sourceVariant}`);
  const source = useSource(_sourceVariant, props);

  const feedOrderVariant = enumT(`learningQueueFeedOrderVariants.${queue.feedOrderVariant}`);

  return (
    <div className="w-full">
      <div className="w-full flex justify-center">
        <LabelledButton variant="play" onMouseDown={() => setRunMode(true)}>
          {t('run')}
        </LabelledButton>
      </div>
      <div className="w-full pt-25px flex justify-center">
        <div className="w-full max-w-96">
          <Container className="p-4">
            {drawDataRow(
              t('variant'),
              <div className="flex items-center">
                <QueueVariantIcon variant={queue.variant} className="w-3 h-3 mr-2" />
                {variant}
              </div>,
            )}
            {drawDataRow(null, variantAdditionalInfo)}
            <Separator className="my-2" />
            {drawDataRow(t('source'), `${sourceVariant}${source ? ':' : ''}`)}
            {drawDataRow('', <b>{source}</b>)}
            <Separator className="my-2" />
            {drawDataRow(t('feedOrderVariant'), feedOrderVariant)}
          </Container>
        </div>
      </div>
    </div>
  );
};

export default connect(prepareState(['notes', 'notesWorkspaces', 'labels', 'incognitoMode']), {
  labelsActionFetch,
  notesActionFetch,
  notesWorkspacesActionFetch,
})(QueuePresenter);
