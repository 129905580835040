import frenchkiss from 'frenchkiss';

frenchkiss.extend('en', {
  pages: {
    tasks: {
      title: 'Tasks',
      category: {
        title: 'Categories',
        add: 'New category',
        newCategoryName: 'New category',
      },
      todoTasks: {
        title: 'TODO',
      },
    },
  },
});
