import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { prepareCollection } from '../../../helpers/model/modelHelpers';
import { taskFromDto } from '../../../helpers/model/taskHelpers';
import { checkedTasksActionSet } from '../../actions/checkedTasksActions';

export default function* fetchCheckedTasksSaga(action) {
  const { dates } = action.payload;
  const { ok, data } = yield call(api.checkedTasks.index, { dates });
  if (ok) {
    yield put(checkedTasksActionSet(dates, prepareCollection(data, taskFromDto)));
  }
}
