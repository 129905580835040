import React from 'react';
import ImageIcon from '../../../../icons/imageIcon';
import Clickable from '../../../Clickable';
import ThemeCrumb from '../../../ThemeCrumb';
import { imageSizes } from '../../../../constants/enums/imageSizes';

const AdditionalImagePresenter = (props) => {
  const { url, size, themeType, borderClass, roundedClass = '', clickable, onMouseDown } = props;

  let maxWidthClass = '';
  if (size === imageSizes.small) maxWidthClass = 'max-w-64';
  else if (size === imageSizes.medium) maxWidthClass = 'max-w-96';

  const paddingClass = url ? (size !== imageSizes.large ? 'p-4' : '') : 'p-1';

  let result = (
    <ThemeCrumb type={themeType}>
      <div
        className={`w-full flex justify-center
                             ${paddingClass} ${borderClass} ${roundedClass}`}
      >
        {url ? (
          <img className={`${maxWidthClass} ${roundedClass}`} src={url} />
        ) : (
          <ThemeCrumb type="input">
            <ImageIcon className="w-3 h-3" />
          </ThemeCrumb>
        )}
      </div>
    </ThemeCrumb>
  );

  if (clickable && onMouseDown)
    result = (
      <Clickable className="w-full flex" onMouseDown={onMouseDown} hoverOpacity>
        {result}
      </Clickable>
    );

  return result;
};

export default AdditionalImagePresenter;
