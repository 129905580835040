import { produceModelFromDto } from './modelHelpers';
import Session from '../../models/session';

const mappings = [
  ['signed_in', 'signedIn'],
  ['email', 'email'],
  ['full_name', 'fullName'],
  ['picture', 'picture'],
];

export const sessionFromDto = produceModelFromDto(Session, mappings, { fetched: true });
