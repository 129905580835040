import { put, select } from 'redux-saga/effects';
import { nodesActionUnsetGroup } from '../../actions/nodesActions';
import { notesActionReset } from '../../actions/notesActions';

export default function* unsetNotesSaga() {
  const allNotes = yield select((state) => state.notes);
  for (const note of allNotes.elements) {
    yield put(nodesActionUnsetGroup(note.nodesGroupId));
  }
  // unfortunately removing a note can result in changing parentId of other notes, so it is easier this way
  yield put(notesActionReset());
}
