import Collection from '../../models/auxiliary/collection';

const initialState = () => ({});

export default function (state = initialState(), action) {
  const { date, newElements, element, dateExcluded } = action.payload || {};

  switch (action.type) {
    case 'HABIT_ANSWERS_ACTION_FETCH':
      return { ...state, [date]: new Collection() };
    case 'HABIT_ANSWERS_ACTION_SET': {
      if (!state[date]) return state;
      const newCollection = state[date].toFetched(false).toUnlocked(false).withReplacedElements(newElements);
      return { ...state, [date]: newCollection };
    }
    case 'HABIT_ANSWERS_ACTION_UPDATE_ELEMENT':
    case 'HABIT_ANSWERS_ACTION_MARK_UPDATED_ELEMENT':
    case 'HABIT_ANSWERS_ACTION_RESET_ELEMENT': {
      if (!state[date]) return state;
      const newCollection = state[date].withActualizedElements(element.toLocked(), ['locked']);
      return { ...state, [date]: newCollection };
    }
    case 'HABIT_ANSWERS_ACTION_UNLOCK_ELEMENT': {
      if (!state[date]) return state;
      const newCollection = state[date].withActualizedElements(element.toUnlocked(), ['locked']);
      return { ...state, [date]: newCollection };
    }
    case 'HABIT_ANSWERS_ACTION_SET_ELEMENT': {
      if (!state[date]) return state;
      const newCollection = state[date].withReplacedElements(element.toUnlocked());
      return { ...state, [date]: newCollection };
    }
    case 'HABIT_ANSWERS_ACTION_RESET': {
      if (dateExcluded) return { [dateExcluded]: state[dateExcluded].shallowClone() };
      else return initialState();
    }
    case 'GLOBAL_ACTION_RESET':
      return initialState();
    default:
      return state;
  }
}
