import React from 'react';
import FormikFieldSub from '../../../form/FormikFieldSub';
import CheckboxField from '../../../form/fieldTypes/CheckBoxField';
import AudioController from '../../../features/audioController/AudioController';

const AudioPanel = (props) => {
  const { src, file, mask, label, selected, onSelect, onMaskUpdated } = props;

  return (
    <div className={`w-full ${selected ? '' : 'opacity-50'}`}>
      <div className="w-full">
        <FormikFieldSub
          value={selected}
          disabled={selected}
          component={CheckboxField}
          onChange={onSelect}
          label={<div className="w-full">{label}</div>}
        />
      </div>
      <div
        className={`w-full px-25px
                            ${selected ? '' : 'cursor-pointer not-selected-audio-panel'}`}
        onMouseDown={onSelect}
      >
        <AudioController
          src={src}
          file={file}
          mask={mask}
          disabled={!selected}
          enableTrim
          onMaskUpdated={onMaskUpdated}
        />
      </div>
    </div>
  );
};

export default AudioPanel;
