import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { connect } from 'react-redux';
import { sessionActionSignIn } from '../../stateManagement/actions/sessionActions';
import { snackbarMessagesActionHandleBackendErrors } from '../../stateManagement/actions/snackbarMessagesActions';

const SignInPage = ({ sessionActionSignIn, snackbarMessagesActionHandleBackendErrors }) => (
  <GoogleLogin
    onSuccess={({ credential }) => {
      sessionActionSignIn(credential);
    }}
    onError={snackbarMessagesActionHandleBackendErrors}
  />
);

export default connect(null, {
  sessionActionSignIn,
  snackbarMessagesActionHandleBackendErrors,
})(SignInPage);
