import React from 'react';
import { withFormik } from 'formik';
import SmartForm from '../../../form/SmartForm';
import SmartField from '../../../form/SmartField';
import TextField from '../../../form/fieldTypes/TextField';
import OnClickOutsideWrapper from '../../../onClickOutside/OnClickOutsideWrapper';
import compact from 'lodash/compact';

const InnerForm = (props) => {
  const { handleSubmit, onModal } = props;
  return (
    <OnClickOutsideWrapper onClickOutside={handleSubmit} exceptionModals={compact([onModal])}>
      <SmartForm className={'flex items-center w-full'}>
        <SmartField
          name="content"
          component={TextField}
          inputClassName="font-medium text-base p-4"
          textAlignClass="text-left"
          autoFocus
        />
      </SmartForm>
    </OnClickOutsideWrapper>
  );
};

export const HeadingEditor = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ content }) => ({ content }),
  handleSubmit: (values, formikBag) => formikBag.props.handleSubmit(values, formikBag),
})(InnerForm);
