import React, { useCallback, useMemo } from 'react';
import IconButton from '../../buttons/IconButton';
import SelectorField from '../../form/fieldTypes/SelectorField';
import Menu from '../../form/components/selector/standardParts/Menu';
import FormikFieldSub from '../../form/FormikFieldSub';

const WorkspaceTrigger = ({ toggleMenu }) => <IconButton variant="changeWorkspace" onMouseDown={toggleMenu} />;

const getWorkspaceField = (options) => (props) => (
  <SelectorField
    {...props}
    options={options}
    menu={<Menu fullyBordered />}
    trigger={<WorkspaceTrigger />}
    fixedMenuWidth={200}
    selectedOptionOnTop
  />
);

const WorkspaceSelector = (props) => {
  const { value, workspaces, onChange } = props;

  const options = useMemo(
    () =>
      workspaces.map((workspace) => ({
        label: workspace.name,
        value: workspace.id,
      })),
    [workspaces],
  );

  const _onChange = useCallback(
    ({ newValue, additionalProps }) => {
      additionalProps.closeMenu();
      onChange(newValue);
    },
    [onChange],
  );

  return <FormikFieldSub onChange={_onChange} value={value} component={getWorkspaceField(options)} />;
};

export default WorkspaceSelector;
