import Lockable from './parents/lockable';
import { currentDateInBackendFormat } from '../helpers/dateTimeHelpers';
import { customColors } from '../constants/customColors';

export default class EventModel extends Lockable {
  constructor() {
    super();
    this.id = null;
    this.nodesGroupId = null;
    this.name = null;
    this.date = currentDateInBackendFormat();
    this.color = Object.keys(customColors)[0];
    this.defaultOrder = 0;
    this.private = false;
  }

  visible(incognitoMode = false) {
    return incognitoMode ? !this.private : true;
  }
}
