import React from 'react';
import { FastField } from 'formik';
import { defaultShouldUpdate } from '../../helpers/formHelpers';

const SmartField = React.forwardRef((props, ref) => {
  const {
    hidden,
    widthClass = 'w-full',
    className = '',
    inputClassName = '',
    shouldUpdate = defaultShouldUpdate,
    ...remainingProps
  } = props;
  if (hidden) return <></>;
  return (
    <div className={`${widthClass} max-w-fit-content ${className}`}>
      <FastField
        innerRef={ref}
        shouldUpdate={shouldUpdate}
        fixedErrorHeight={false}
        displayError={true}
        handleErrorThemeType={true}
        handleDisabledThemeType={true}
        handlePresentationModeThemeType={true}
        className={inputClassName}
        {...remainingProps}
      />
    </div>
  );
});

export default SmartField;
