import Lockable from './parents/lockable';
import { inputTypes } from '../constants/enums/inputTypes';
import { habitsVariants } from '../constants/enums/habitsVariants';

export default class Habit extends Lockable {
  constructor() {
    super();
    this.id = null;
    this.habitsSectionId = null;
    this.name = null;
    this.inputType = inputTypes.checkbox;
    this.variant = habitsVariants.good;
    this.considerOnlyUpdatedValues = false;
    this.defaultOrder = 0;
    this.hidden = false;
    this.private = false;
    this.habitHidingConditions = [];
    this.habitSummaryRequests = [];
    this.habitChartRequests = [];
  }

  visible(incognitoMode = false) {
    return incognitoMode ? !this.private : true;
  }
}
