import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { imageSizes } from '../../../constants/enums/imageSizes';
import ThemeCrumb from '../../../shared/ThemeCrumb';

const ImagePresenter = (props) => {
  const { content, onImgLoad, height, roundedClass = 'rounded' } = props;

  const { url: src, description: alt, size } = content;

  const imgRef = useRef();
  const [imgRealHeight, setImgRealHeight] = useState();
  const [imgRatio, setImgRatio] = useState();

  useEffect(() => {
    setImgRatio(undefined);
    setImgRealHeight(undefined);
  }, [src]);

  const imgWidth = useMemo(() => {
    if (!height || !imgRatio || !imgRealHeight || imgRealHeight <= height) return;
    return Math.floor(height * imgRatio);
  }, [height, imgRealHeight, imgRatio]);

  const maxWidthClass = useMemo(() => {
    if (size === imageSizes.small) return 'max-w-64';
    else if (size === imageSizes.medium) return 'max-w-96';
  }, [size]);

  const _onLoad = useCallback((e) => {
    const img = imgRef.current;
    if (img && img.width && img.height) {
      setImgRealHeight(img.height);
      setImgRatio(img.width / img.height);
      if (onImgLoad) onImgLoad(e);
    }
  }, []);

  return (
    <div className="w-full flex flex-col items-end" style={{ height }}>
      <ThemeCrumb type="additionalNodeContent">
        <div className={`w-full flex justify-center ${roundedClass}`}>
          <img
            ref={imgRef}
            style={{ width: imgWidth }}
            src={src}
            alt={alt}
            onLoad={_onLoad}
            className={`${maxWidthClass} ${roundedClass}`}
          />
        </div>
      </ThemeCrumb>
    </div>
  );
};

export default ImagePresenter;
