{
  /* eslint-disable max-len */
}
import React from 'react';

export const ChecklistBulletsIcon = ({ className = '', style = {} }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
    style={style}
  >
    <rect x="10" y="2.5" width="13.466" height="0.85" style={{ stroke: 'rgb(0, 0, 0)' }} />
    <rect x="10" y="7" width="13.466" height="0.85" style={{ stroke: 'rgb(0, 0, 0)' }} />
    <rect x="10" y="11.5" width="13.466" height="0.85" style={{ stroke: 'rgb(0, 0, 0)' }} />
    <rect x="10" y="16" width="13.466" height="0.85" style={{ stroke: 'rgb(0, 0, 0)' }} />
    <rect x="10" y="20.5" width="13.466" height="0.85" style={{ stroke: 'rgb(0, 0, 0)' }} />
    <rect
      style={{ stroke: 'rgb(0, 0, 0)', fill: 'rgba(255, 255, 255, 0)' }}
      x="0"
      y="2.548"
      width="3.707"
      height="3.707"
    />
    <rect
      style={{ stroke: 'rgb(0, 0, 0)', fill: 'rgba(255, 255, 255, 0)' }}
      x="0"
      y="10.068"
      width="3.707"
      height="3.707"
    />
    <rect
      style={{ stroke: 'rgb(0, 0, 0)', fill: 'rgba(255, 255, 255, 0)' }}
      x="0"
      y="17.555"
      width="3.707"
      height="3.707"
    />
  </svg>
);
export default ChecklistBulletsIcon;
