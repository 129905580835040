import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { conditionalLearn } from './conditionalLearn';

export const useNext = (props) => {
  const {
    loading,
    queue,
    plan,
    incognitoMode,
    updateGlobalStateLastLearnedNodeId,
    currentChunk,
    learningProgress,
    finalWpm,
    alreadyLearnedChunks,
    getNextChunk,
    setPreviousChunk,
    setCurrentChunk,
    setEnded,
    setPaused,
    pausableVariant,
    ended,
    empty,
  } = props;

  const nextEnabled = useMemo(
    () => !!(!loading && !ended && !empty && currentChunk && currentChunk.nodeId),
    [ended, empty, currentChunk, queue, loading],
  );

  const nextRef = useRef(() => {});
  useEffect(() => {
    nextRef.current = async () => {
      if (!nextEnabled) return;

      const nextChunk = getNextChunk(plan, incognitoMode, currentChunk.nodeId);
      setPreviousChunk(currentChunk);
      setCurrentChunk(nextChunk);
      if (!nextChunk) {
        setEnded(true);
        if (pausableVariant) setPaused(true);
      }

      await conditionalLearn({
        ...props,
        shouldResetQueueAfterwards: !nextChunk,
      });
    };
  }, [
    queue,
    plan,
    incognitoMode,
    updateGlobalStateLastLearnedNodeId,
    currentChunk,
    nextEnabled,
    learningProgress,
    finalWpm,
    alreadyLearnedChunks,
    pausableVariant,
    setEnded,
    setPaused,
  ]);

  const next = useCallback(
    debounce(
      async () => {
        nextRef.current();
      },
      300,
      { leading: true, trailing: false },
    ),
    [],
  );

  const notDebouncedNext = useCallback(() => {
    nextRef.current();
  }, []);

  return [next, nextEnabled, notDebouncedNext];
};
