import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  habitsSectionsActionDeleteElement,
  habitsSectionsActionFetch,
  habitsSectionsActionMarkHiddenElement,
  habitsSectionsActionMarkPrivateElement,
  habitsSectionsActionUnmarkHiddenElement,
  habitsSectionsActionUnmarkPrivateElement,
  habitsSectionsActionUpdateElement,
} from '../../../stateManagement/actions/habitsSectionsActions';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { habitsActionFetch } from '../../../stateManagement/actions/habitActions';
import StandardContentTemplate from '../../../shared/contentTemplates/StandardContentTemplate/index';
import Sections from './Sections';
import Section from './Main';
import getSection from './getSection';
import { elementToFocusOnActionReset } from '../../../stateManagement/actions/elementToFocusOnActions';

const HabitsIndexPage = (props) => {
  const { habitsSections, habits, habitsSectionsActionFetch, habitsActionFetch, ...otherProps } = props;

  useEffect(() => {
    if (!habitsSections.fetched) habitsSectionsActionFetch();
    if (!habits.fetched) habitsActionFetch();
  }, []);

  return (
    <StandardContentTemplate
      top={getSection({ habitsSections, ...otherProps })}
      leftReady={habitsSections.fetched}
      left={<Sections />}
      mainReady={habitsSections.fetched && habits.fetched}
      main={<Section />}
    />
  );
};

export default connect(
  prepareState(['activeElements', 'incognitoMode', 'habitsSections', 'habits', 'elementToFocusOn']),
  {
    habitsSectionsActionFetch,
    habitsActionFetch,
    habitsSectionsActionDeleteElement,
    habitsSectionsActionMarkPrivateElement,
    habitsSectionsActionUnmarkPrivateElement,
    habitsSectionsActionMarkHiddenElement,
    habitsSectionsActionUnmarkHiddenElement,
    habitsSectionsActionUpdateElement,
    elementToFocusOnActionReset,
  },
)(HabitsIndexPage);
