import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { sessionActionLookup } from '../../actions/sessionActions';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { notificationT } from '../../../helpers/i18nHelpers';

export default function* incognitoModeEnableSaga() {
  const { ok } = yield call(api.users.incognitoMode.enable);
  if (ok) {
    yield put(snackbarMessagesActionAdd(notificationT('incognitoMode.enable.success')));
    yield put(sessionActionLookup());
  } else yield put(snackbarMessagesActionHandleBackendErrors());
}
