import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { habitsSectionsActionSetElement, habitsSectionsActionUnlockElement } from '../../actions/habitsSectionsActions';
import { habitsSectionFromDto, habitsSectionToDto } from '../../../helpers/model/habitsSectionHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { notificationT } from '../../../helpers/i18nHelpers';

export default function* updateElementHabitsSectionsSaga(action) {
  const initialHabitsSection = action.payload;
  const { ok, data } = yield call(api.habitsSections.update, habitsSectionToDto(initialHabitsSection));
  if (ok) {
    yield put(snackbarMessagesActionAdd(notificationT('habitsSections.update.success')));
    yield put(habitsSectionsActionSetElement(habitsSectionFromDto(data)));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data, 'habitsSections.update.errors'));
    yield put(habitsSectionsActionUnlockElement(initialHabitsSection));
  }
}
