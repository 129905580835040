import React, { useState } from 'react';
import { useCheckIfMounted } from '../hooks/useCheckIfMounted';

const DoubleClickWrapper = (props) => {
  const { onFirstClick = () => {}, onDoubleClick = () => {}, children } = props;
  const [firstClick, setFirstClick] = useState(false);
  const checkIfMounted = useCheckIfMounted();

  const onMouseDown = (e) => {
    if (firstClick) {
      setFirstClick(false);
      onDoubleClick(e);
    } else {
      onFirstClick(e);
      setFirstClick(true);
      setTimeout(() => {
        if (!checkIfMounted()) return;

        setFirstClick(false);
      }, 400);
    }
  };

  return (
    <div className="w-full" onMouseDown={onMouseDown}>
      {children}
    </div>
  );
};

export default DoubleClickWrapper;
