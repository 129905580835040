import React from 'react';
import { generateTagId } from '../../helpers/identifierHelpers';

const FormikFieldSub = (props) => {
  const { component, name, value, ...additionalProps } = props;
  return React.createElement(component, {
    field: { name: name || generateTagId(), value },
    form: { errors: [], touched: [], setFieldTouched: () => {} },
    ...additionalProps,
  });
};

export default FormikFieldSub;
