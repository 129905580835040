import HabitChartData from '../../models/habitChartData';
import { habitChartRequestVariants } from '../../constants/enums/habitChartRequestVariants';
import { DateTime } from 'luxon';
import { backendDateFormat } from '../../constants/dateFormat';
import { inputTypes } from '../../constants/enums/inputTypes';

export const habitChartDataFromDto = (dto, habitChartRequestId, habit, date) => {
  const result = new HabitChartData();
  result.habitChartRequestId = dto.habitChartRequestId;
  let chartRequest;
  habit.habitChartRequests.forEach((request) => {
    if (request.id.toString() === habitChartRequestId.toString()) chartRequest = request;
  });
  const defaultValue = getDefaultValue(habit.inputType, habit.considerOnlyUpdatedValues);
  result.data = chartRequest ? fillInGaps(dto.data, chartRequest.variant, defaultValue, date) : [];
  return result;
};

const getDefaultValue = (inputType, considerOnlyUpdatedValues) => {
  if (considerOnlyUpdatedValues) return undefined;
  if (inputType === inputTypes.checkbox) return 0;
  if (inputType === inputTypes.counter) return 0.0;
  if (inputType === inputTypes.realNumber) return 0.0;
};

const fillInGaps = (data, requestVariant, defaultValue, endDate) => {
  if ([0, 1].includes(data.length)) return data;
  if (requestVariant === habitChartRequestVariants.daily) return fillInDailyGaps(data, defaultValue, endDate);
  if (requestVariant === habitChartRequestVariants.weekly) return fillInWeeklyGaps(data, defaultValue, endDate);
  if (requestVariant === habitChartRequestVariants.monthly) return fillInMonthlyGaps(data, defaultValue, endDate);
  return data;
};

const fillInDailyGaps = (data, defaultValue, endDate) => {
  return fillInGapsCommonLogic(data, defaultValue, endDate, (previousLabel) => {
    const currentDateTime = DateTime.fromFormat(previousLabel, backendDateFormat);
    return currentDateTime.plus({ days: 1 }).toFormat(backendDateFormat);
  });
};

const fillInWeeklyGaps = (data, defaultValue, endDate) => {
  const endDateTime = DateTime.fromFormat(endDate, backendDateFormat);
  return fillInGapsCommonLogic(
    data,
    defaultValue,
    `${endDateTime.get('weekNumber')}/${endDateTime.get('weekYear')}`,
    (previousLabel) => {
      const splitted = previousLabel.split('/');
      const isoWeek = parseInt(splitted[0], 10);
      const isoYear = parseInt(splitted[1], 10);
      const maxIsoWeek = getMaxIsoWeek(isoYear);
      if (isoWeek !== maxIsoWeek) return `${isoWeek + 1}/${isoYear}`;
      else return `1/${isoYear + 1}`;
    },
  );
};

const getMaxIsoWeek = (year) => {
  let day = 31;
  let result = DateTime.utc(year, 12, day).get('weekNumber');
  while (result === 1) {
    day -= 1;
    result = DateTime.utc(year, 12, day).get('weekNumber');
  }
  return result;
};

const fillInMonthlyGaps = (data, defaultValue, endDate) => {
  const endDateTime = DateTime.fromFormat(endDate, backendDateFormat);
  const maxMonth = 12;
  return fillInGapsCommonLogic(
    data,
    defaultValue,
    `${endDateTime.get('month')}/${endDateTime.get('year')}`,
    (previousLabel) => {
      const splitted = previousLabel.split('/');
      const month = parseInt(splitted[0], 10);
      const year = parseInt(splitted[1], 10);
      if (month !== maxMonth) return `${month + 1}/${year}`;
      else return `1/${year + 1}`;
    },
  );
};

const fillInGapsCommonLogic = (data, defaultValue, endLabel, createNextLabel) => {
  const newData = [];
  let currentLabel = data[0].label;
  let currentIterator = 0;
  let safetyCounter = 0;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    if (currentLabel === data[currentIterator].label) {
      safetyCounter = 0;
      newData.push(data[currentIterator]);
      if (currentIterator < data.length - 1) currentIterator += 1;
    } else {
      newData.push({ label: currentLabel, value: defaultValue });
      safetyCounter += 1;
      if (safetyCounter === 1000) {
        currentLabel = currentIterator < data.length ? data[currentIterator].label : endLabel;
        safetyCounter = 0;
        continue;
      }
    }

    if (currentLabel === endLabel) break;

    currentLabel = createNextLabel(currentLabel);
  }
  return newData;
};
