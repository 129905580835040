import React from 'react';
import Loader from '../../../shared/loaders/Loader';
import WholePageMessage from '../../../shared/titles/WholePageMessage';
import { initT } from '../../../helpers/i18nHelpers';
import { learningQueueVariants } from '../../../constants/enums/learningQueueVariants';
import OverviewVariant from './overviewVariant';
import AudioVariant from './audioVariant';
import TypingVariant from './typingVariant';
import PdfVariant from './pdfVariant';
import EndedPage from './EndedPage';
import { isEmpty } from 'lodash';

const t = initT('pages.learning.queueRun');

const QueueRun = (props) => {
  const { queue, queueRunToolkit } = props;
  const { variant } = queue;
  const { loading, empty, ended, currentChunkUuid } = queueRunToolkit;

  let sharedProps = {
    ...queueRunToolkit,
    queue,
  };
  if (variant === learningQueueVariants.pdf) return <PdfVariant {...sharedProps} />;

  if (loading || isEmpty(queueRunToolkit))
    return (
      <WholePageMessage>
        <Loader />
      </WholePageMessage>
    );
  if (empty)
    return (
      <div className="select-none">
        <WholePageMessage>{t('empty')}</WholePageMessage>
      </div>
    );
  if (ended) return <EndedPage {...props} />;

  sharedProps = {
    ...sharedProps,
    key: currentChunkUuid || 'no-node',
  };
  if (variant === learningQueueVariants.overview) return <OverviewVariant {...sharedProps} />;
  if (variant === learningQueueVariants.typing) return <TypingVariant {...sharedProps} />;
  if (variant === learningQueueVariants.audio) return <AudioVariant {...sharedProps} />;
};

export default QueueRun;
