import frenchkiss from 'frenchkiss';

export const initT =
  (baseKey) =>
  (key, props = {}) =>
    frenchkiss.t(`${baseKey}.${key}`, props);

export const confirmationTKey = (key) => `confirmations.${key}`;

export const confirmationT = (key, props = {}) => frenchkiss.t(confirmationTKey(key), props);

export const notificationTKey = (key) => `notifications.${key}`;

export const notificationT = (key, props = {}) => frenchkiss.t(notificationTKey(key), props);

export const frontEndErrorTKey = (key) => `frontEndErrors.${key}`;

export const frontEndErrorT = (key, props = {}) => frenchkiss.t(frontEndErrorTKey(key), props);

export const enumTKey = (key) => `enums.${key}`;

export const enumT = (key, props = {}) => frenchkiss.t(enumTKey(key), props);

export const weekdayT = (weekdayKey) => {
  const dictionary = {
    1: 'monday',
    2: 'tuesday',
    3: 'wednesday',
    4: 'thursday',
    5: 'friday',
    6: 'saturday',
    7: 'sunday',
  };
  return frenchkiss.t(enumTKey(`weekdayTypes.${dictionary[weekdayKey]}`));
};
