import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { snackbarMessagesActionHandleBackendErrors } from '../../actions/snackbarMessagesActions';
import { languageWorkspaceFromDto, languageWorkspaceToDto } from '../../../helpers/model/languageWorkspacesHelpers';
import {
  languageWorkspacesActionSetElement,
  languageWorkspacesActionUnlockElement,
} from '../../actions/languageWorkspacesActions';

export default function* markPrivateElementLanguageWorkspacesSaga(action) {
  const initialWorkspace = action.payload;
  const { ok, data } = yield call(api.languageWorkspaces.markPrivate, languageWorkspaceToDto(initialWorkspace));
  if (ok) {
    yield put(languageWorkspacesActionSetElement(languageWorkspaceFromDto(data)));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(languageWorkspacesActionUnlockElement(initialWorkspace));
  }
}
