import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import {
  onClickOutsideCallbacksActionRegisterElement,
  onClickOutsideCallbacksActionUnregisterElement,
} from '../../stateManagement/actions/onClickOutsideCallbacksActions';
import { generateTagId } from '../../helpers/identifierHelpers';

const OnClickOutsideWrapper = (props) => {
  const {
    children,
    onClickOutside,
    sizeClass = 'w-full h-full',
    className = '',
    active = true,
    disableWhenModalOpen = true,
    disableWhenPopupOpen = true,
    additionalExceptionClasses = [],
    baseExceptionClasses = [
      'link-popover',
      'checklist-bullet',
      'selectable-menu-wrapper',
      'fixed-menu-overlay',
      'hide-fixed-menu-toggle',
    ],
    exceptionClasses = [...baseExceptionClasses, ...additionalExceptionClasses],
    baseExceptionIds = ['DatePickerPortal'],
    additionalExceptionIds = [],
    exceptionIds = [...baseExceptionIds, ...additionalExceptionIds],
    exceptionModals = [],
    onClickOutsideCallbacksActionRegisterElement: registerCallback,
    onClickOutsideCallbacksActionUnregisterElement: unregisterCallback,
    // redux related, should not be passed down to children
    // eslint-disable-next-line no-unused-vars
    dispatch,
    ...otherProps
  } = props;

  const id = useMemo(generateTagId, []);

  useEffect(
    () => () => {
      unregisterCallback(id);
    },
    [],
  );

  useEffect(() => {
    if (!active) {
      unregisterCallback(id);
      return;
    }

    registerCallback(id, onClickOutside, {
      disableWhenModalOpen,
      disableWhenPopupOpen,
      exceptionClasses,
      exceptionIds,
      exceptionModals,
    });
  }, [
    onClickOutside,
    active,
    disableWhenModalOpen,
    disableWhenPopupOpen,
    exceptionClasses,
    exceptionIds,
    exceptionModals,
  ]);

  return (
    <div id={id} className={`${sizeClass} ${className}`} {...otherProps}>
      {children}
    </div>
  );
};

export default connect(null, {
  onClickOutsideCallbacksActionRegisterElement,
  onClickOutsideCallbacksActionUnregisterElement,
})(OnClickOutsideWrapper);
