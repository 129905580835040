import React, { useCallback, useEffect, useRef, useState } from 'react';
import AudioControllerBase from '../../../shared/features/audioController/base';

const Inner = (props) => {
  const { audioControllerProps, enabled } = props;
  const { waveFormId, isPlaying, audioLocked, playPause, wavesurfer } = audioControllerProps;

  const firstRun = useRef(true);

  useEffect(() => {
    if (firstRun.current) return;

    if (!enabled && isPlaying) playPause();
    else if (enabled && !isPlaying && !audioLocked) playPause();
  }, [enabled]);

  useEffect(() => {
    if (!firstRun.current || !wavesurfer) return;

    firstRun.current = false;
    if (enabled && !audioLocked) playPause();
  }, [wavesurfer]);

  return <div id={waveFormId} className="hidden" />;
};

const QueueRunAudioController = (props) => {
  const { src, mask, audioPaused, onAudioProcess = () => {}, onFinish = () => {} } = props;

  const [enabled, setEnabled] = useState(!audioPaused);
  useEffect(() => {
    setEnabled(!audioPaused);
  }, [audioPaused]);

  const _onFinish = useCallback((_props) => {
    setEnabled(false);
    onFinish(_props);
  }, []);

  if (!src) return <></>;

  return (
    <AudioControllerBase key={src} src={src} mask={mask} onAudioProcess={onAudioProcess} onFinish={_onFinish}>
      {(innerProps) => <Inner queueRunProps={props} audioControllerProps={innerProps} enabled={enabled} />}
    </AudioControllerBase>
  );
};

export default QueueRunAudioController;
