import React from 'react';
import ThemeCrumb from '../../../../ThemeCrumb';
import Clickable from '../../../../Clickable';
import ArrowDownIcon from '../../../../../icons/arrowDownIcon';
import { getThemeTypeDefinition } from '../../../../../helpers/themeHelpers';
import { prepareState } from '../../../../../helpers/stateManagementHelpers';
import { connect } from 'react-redux';

const Trigger = (props) => {
  const {
    theme,
    disabled,
    themeType = disabled ? 'inputDisabled' : 'input',
    textAlignClass = 'text-center',
    toggleMenu,
    menuOpenOnTop,
    menuOpenOnBottom,
    children,
    showArrow = true,
    leftPadding = showArrow,
  } = props;

  const roundedClass = (menuOpenOnBottom && 'rounded-t') || (menuOpenOnTop && 'rounded-b') || 'rounded';

  const arrowColor = getThemeTypeDefinition(theme, themeType).color;
  const arrowBorderColor = getThemeTypeDefinition(theme, themeType).borderColor;

  const arrow = (
    <div className="p-2" style={{ color: arrowColor, borderColor: arrowBorderColor }}>
      <ArrowDownIcon
        className={`w-3 h-3 transform transition-transform
                                       ${menuOpenOnTop ? 'rotate-180' : ''}`}
      />
    </div>
  );

  return (
    <ThemeCrumb type={themeType}>
      <Clickable
        className={`w-full flex items-center border ${roundedClass}`}
        onMouseDown={toggleMenu}
        disabled={disabled}
        opaqueWhenDisabled={false}
      >
        <div className="invisible">{leftPadding ? arrow : <></>}</div>
        <div
          className={`focus:outline-none p-1 w-full text-clip overflow-hidden
                                 ${showArrow ? 'border-r' : ''}
                                 ${textAlignClass}`}
        >
          {children}
        </div>
        {showArrow ? arrow : <></>}
      </Clickable>
    </ThemeCrumb>
  );
};

export default connect(prepareState('theme'))(Trigger);
