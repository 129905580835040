import React from 'react';
import ThemeCrumb from '../ThemeCrumb';
import Clickable from '../Clickable';
import Hoverable from '../Hoverable';
import { connect } from 'react-redux';
import { activeElementsActionDeactivateAllOfType } from '../../stateManagement/actions/activeElementsActions';
import { activeElementTypes } from '../../constants/activeElementTypes';

const SidebarButton = (props) => {
  const {
    children,
    selected,
    selectionThemeType = 'sizeableThingSelected',
    to,
    onMouseDown,
    customLinkOnClick,
    onSelectedMouseDown,
    afterMouseDown = () => window.scrollTo(0, 0),
    faded = false,
    disabled = false,
    closeFixedMenuAfterClick = true,
    activeElementsActionDeactivateAllOfType: deactivateAll,
    bullet = <span className="pr-2">•</span>,
    appendix = <></>,
    paddingClass = 'py-2 px-3',
    centered = false,
  } = props;

  let _onMouseDown = onMouseDown;
  let _onSelectedMouseDown = onSelectedMouseDown;

  if (closeFixedMenuAfterClick) {
    const closeFixedMenu = () => deactivateAll(activeElementTypes.fixedMenu);
    if (onMouseDown)
      _onMouseDown = (props) => {
        closeFixedMenu();
        onMouseDown(props);
      };
    if (onSelectedMouseDown)
      _onSelectedMouseDown = (props) => {
        closeFixedMenu();
        onSelectedMouseDown(props);
      };
  }

  if (afterMouseDown) {
    if (onMouseDown) {
      const withoutCallback = _onMouseDown;
      _onMouseDown = (props) => {
        withoutCallback(props);
        setTimeout(afterMouseDown);
      };
    }
    if (onSelectedMouseDown) {
      const withoutCallback = _onSelectedMouseDown;
      _onSelectedMouseDown = (props) => {
        withoutCallback(props);
        setTimeout(afterMouseDown);
      };
    }
  }

  const childrenWrapperClass = centered ? '' : 'truncate w-full text-left';

  if (disabled)
    return (
      <div className="flex justify-start">
        <div
          className={`w-full flex justify-center items-center my-1 rounded-xl
                                 ${paddingClass}`}
        >
          {bullet}
          <span className={childrenWrapperClass}>{children}</span>
          {appendix}
        </div>
      </div>
    );

  if (selected)
    return (
      <div className="flex justify-start w-full">
        <ThemeCrumb type={selectionThemeType}>
          {_onSelectedMouseDown || to ? (
            <Hoverable className="my-1 rounded-xl" hoveredThemeType="sizeableThingSelectedHovered">
              <Clickable
                to={to}
                onMouseDown={_onSelectedMouseDown}
                customLinkOnClick={customLinkOnClick}
                className={`w-full flex justify-center items-center
                                                   ${paddingClass}`}
                preventContextMenu
              >
                {bullet}
                <b className={childrenWrapperClass}>{children}</b>
                {appendix}
              </Clickable>
            </Hoverable>
          ) : (
            <div
              className={`w-full flex justify-center items-center my-1 rounded-xl
                                        ${paddingClass}`}
            >
              {bullet}
              <b className={childrenWrapperClass}>{children}</b>
              {appendix}
            </div>
          )}
        </ThemeCrumb>
      </div>
    );
  return (
    <ThemeCrumb type={faded ? 'buttonFaded' : 'button'}>
      <div className={'w-full flex justify-start'}>
        <Hoverable
          className={'my-1 rounded-xl'}
          hoveredThemeType="sizeableThingHovered"
          hoveredAdditionalStyles={{ opacity: 1 }}
        >
          <Clickable
            to={to}
            onMouseDown={_onMouseDown}
            customLinkOnClick={customLinkOnClick}
            className={`w-full flex justify-center items-center ${paddingClass}`}
            preventContextMenu
          >
            {bullet}
            <span className={childrenWrapperClass}>{children}</span>
            {appendix}
          </Clickable>
        </Hoverable>
      </div>
    </ThemeCrumb>
  );
};

export default connect(null, { activeElementsActionDeactivateAllOfType })(SidebarButton);
