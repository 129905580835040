import { produceModelFromDto, produceModelToDto } from './modelHelpers';
import LearningQueue from '../../models/learningQueue';
import { learningQueueSourceVariants as sourceVariants } from '../../constants/enums/learningQueueSourceVariant';

const mappings = [
  ['id', 'id'],
  ['name', 'name'],
  ['variant', 'variant', 'enum'],
  ['consider_nested_notes', 'considerNestedNotes'],
  ['with_audio', 'withAudio'],
  ['with_repetition_delay', 'withRepetitionDelay'],
  ['feed_order_variant', 'feedOrderVariant', 'enum'],
  ['learning_workspace_id', 'learningWorkspaceId'],
  ['source_notes_workspace_id', 'sourceNotesWorkspaceId'],
  ['source_note_id', 'sourceNoteId'],
  ['source_label_id', 'sourceLabelId'],
  ['last_learned_node_id', 'lastLearnedNodeId'],
  ['default_order', 'defaultOrder'],
  ['private', 'private'],
];

export const learningQueueFromDto = produceModelFromDto(LearningQueue, mappings);

export const learningQueueToDto = produceModelToDto(mappings);

export const getSourceVariant = (queue) => {
  const { sourceLabelId, sourceNotesWorkspaceId, sourceNoteId } = queue;

  if (sourceLabelId) return sourceVariants.label;
  else if (sourceNotesWorkspaceId) return sourceVariants.notesWorkspace;
  else if (sourceNoteId) return sourceVariants.note;
};
