import React, { useCallback, useEffect, useState } from 'react';
import LabelledButton from '../../../buttons/LabelledButton';
import { initT } from '../../../../helpers/i18nHelpers';
import { useCheckIfMounted } from '../../../../hooks/useCheckIfMounted';
import fixWebmDuration from 'fix-webm-duration';

const t = initT('modals.audioSelectionModal');

let audioChunks = [];
let startTime = null;

const getFilename = (data, recordingsCount) => {
  const dataType = data.type;
  let extension = 'webm';
  if (dataType.includes('audio/ogg')) extension = 'ogg';
  return `${t('recordingFileName')} ${recordingsCount + 1}.${extension}`;
};

const clearStream = (stream) => {
  if (stream) stream.getTracks().forEach((track) => track.stop());
};

const RecordingTrigger = (props) => {
  const { setRecording, onSuccess, recordingsCount, setStopRecording } = props;

  const checkIfMounted = useCheckIfMounted();
  const [stream, setStream] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);

  const listener = useCallback(
    (event) => {
      const duration = Date.now() - startTime;
      audioChunks.push(event.data);
      if (mediaRecorder && mediaRecorder.state === 'inactive') {
        const filename = getFilename(event.data, recordingsCount);
        const initialBlob = new Blob(audioChunks);
        fixWebmDuration(initialBlob, duration, (blobWithDuration) => {
          if (!checkIfMounted()) return;

          const newFile = new File([blobWithDuration], filename);
          onSuccess(newFile);
          audioChunks = [];
          clearStream(stream);
          setStream(null);
          setMediaRecorder(null);
          setRecording(false);
        });
      }
    },
    [stream, mediaRecorder, onSuccess, setRecording, recordingsCount],
  );

  useEffect(() => {
    return () => clearStream(stream);
  }, [stream]);

  useEffect(() => {
    if (mediaRecorder) mediaRecorder.addEventListener('dataavailable', listener);
    return () => {
      if (mediaRecorder) mediaRecorder.removeEventListener('dataavailable', listener);
    };
  }, [mediaRecorder, listener]);

  const startRecording = () => {
    if (!mediaRecorder || mediaRecorder.state === 'inactive') {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        setStream(stream);
        const newMediaRecorder = new MediaRecorder(stream);
        setMediaRecorder(newMediaRecorder);
        audioChunks = [];
        newMediaRecorder.start();
        startTime = Date.now();
        setStopRecording(() => () => {
          if (newMediaRecorder && newMediaRecorder.state === 'recording') newMediaRecorder.stop();
        });
        setRecording(true);
      });
    }
  };

  return (
    <LabelledButton variant="microphone" onMouseDown={startRecording}>
      {t('startRecording')}
    </LabelledButton>
  );
};

export default RecordingTrigger;
