import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { Editable, ReactEditor, Slate, withReact } from 'slate-react';
import { createEditor, Editor, Transforms } from 'slate';
import ThemeCrumb from '../../../../ThemeCrumb';
import Toolbar from './Toolbar';
import isEqual from 'lodash/isEqual';
import { produceRenderElement } from './renderMethods/produceRenderElement';
import { produceRenderLeaf } from './renderMethods/renderLeaf';
import { produceOnKeyDown } from './renderMethods/produceOnKeyDown';
import { produceApply, produceDeleteBackward, produceInsertBreak, produceIsInline } from './helpers/overrides';
import { getUuid } from '../../../../../helpers/identifierHelpers';
import { withHistory } from 'slate-history';
import { alignmentTypes } from './constants/alignmentTypes';
import { recalculateBulletsOrdinals } from './helpers/blockNestingHelpers';
import { isArray } from 'chart.js/helpers';
import { runIfDraggingNotEnabled } from '../../../../../helpers/draggingHelpers';
import { scrollToCaretIfTooLow } from '../../../../../helpers/inputsHelpers';
import OnClickOutsideWrapper from '../../../../onClickOutside/OnClickOutsideWrapper';
import compact from 'lodash/compact';
import { prepareValueToHandleHighlight } from './helpers/highlightHelpers';
import { useCheckIfMounted } from '../../../../../hooks/useCheckIfMounted';

const useSelection = (editor) => {
  const [selection, setSelection] = useState(editor.selection);
  const setSelectionOptimized = useCallback(
    (newSelection) => {
      if (!isEqual(selection, newSelection)) {
        setSelection(newSelection);
        editor.lastValidSelection = newSelection;
      }
    },
    [setSelection, selection],
  );

  return [selection, setSelectionOptimized];
};

const InnerTextEditor = (props) => {
  const {
    readOnly = false,
    autoFocus = false,
    clickable = false,
    onMouseDown = () => {},
    onBlur = () => {},
    checklistItemClickable = true,
    onChecklistItemClicked = () => {},
    themeType = 'input',
    reset,
    roundedClass = 'rounded-md',
    onModal,
    additionalContentMarginClass = '',
    highlightedTextProps,
  } = props;

  let { initialValue } = props;
  if (!isArray(initialValue))
    initialValue = [
      {
        type: 'block',
        alignmentType: alignmentTypes.left,
        children: [{ text: '' }],
      },
    ];

  const [toolbarRefreshKey, setToolbarRefreshKey] = useState(getUuid());
  const { width, ref } = useResizeDetector();

  const checkIfMounted = useCheckIfMounted();
  const highlightedCaretRef = useRef(null);
  useEffect(() => {
    if (readOnly && highlightedTextProps) {
      setTimeout(() => {
        if (checkIfMounted() && highlightedCaretRef.current) {
          highlightedCaretRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      });
    }
  }, [readOnly, highlightedTextProps]);
  const prepareInitialValue = useCallback(
    (value) => {
      if (readOnly && highlightedTextProps) return prepareValueToHandleHighlight(initialValue, highlightedTextProps);
      return value;
    },
    [readOnly, highlightedTextProps],
  );
  const [value, setValue] = useState(prepareInitialValue(initialValue));
  const [key, setKey] = useState(getUuid());
  useEffect(() => {
    setValue(prepareInitialValue(initialValue));
    setKey(getUuid());
  }, [prepareInitialValue]);
  const [editor] = useState(() => {
    const result = withReact(withHistory(createEditor()));

    result.lastValidSelection = result.selection;
    result.forceToolbarReload = () => setToolbarRefreshKey(getUuid());
    result.tempToggledLeavesVariants = [];
    result.enableNormalization = true;

    const { normalizeNode } = result;
    result.normalizeNode = (entry) => {
      if (result.enableNormalization) normalizeNode(entry);
    };
    result.apply = produceApply(result);
    result.isInline = produceIsInline(result);
    result.insertBreak = produceInsertBreak(result);
    result.deleteBackward = produceDeleteBackward(result);
    result.reset = reset;

    return result;
  });
  const [selection, setSelection] = useSelection(editor);

  useEffect(() => {
    editor.tempToggledLeavesVariants = [];
  }, [selection]);
  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        ReactEditor.focus(editor);
        Transforms.select(editor, {
          anchor: Editor.end(editor, []),
          focus: Editor.end(editor, []),
        });
      });
    }
  }, [autoFocus]);
  useEffect(() => {
    Editor.normalize(editor, { force: true });
    recalculateBulletsOrdinals(editor);
  }, []);

  const onKeyDown = useCallback(produceOnKeyDown(editor), []);
  const renderElement = useCallback(
    produceRenderElement(readOnly, clickable, checklistItemClickable, () => {
      onChecklistItemClicked(editor.children);
    }),
    [readOnly, checklistItemClickable, onChecklistItemClicked],
  );

  const onChange = useCallback((newValue) => {
    setValue(newValue);
    setSelection(editor.selection);
    // without setTimeout dynamically changing selection can impact calculations in
    // an unexpected manner
    if (!readOnly) setTimeout(scrollToCaretIfTooLow);
  }, []);

  return (
    <OnClickOutsideWrapper
      active={!readOnly}
      onClickOutside={(e) => {
        Transforms.deselect(editor);
        onBlur(e, value);
      }}
      key={editor.key}
      exceptionModals={compact([onModal])}
    >
      {/*Use div instead of Clickable component to prevent problems with autoFocus*/}
      <div
        key={key}
        className={clickable ? 'opacity-50-on-devices-supporting-hover cursor-pointer' : ''}
        onMouseDown={clickable ? () => runIfDraggingNotEnabled(onMouseDown) : () => {}}
      >
        <Slate editor={editor} value={value} onChange={onChange}>
          <ThemeCrumb type={themeType}>
            <div className={`${roundedClass} border`} ref={ref}>
              {!readOnly && <Toolbar selection={selection} editorWidth={width} refreshKey={toolbarRefreshKey} />}
              <div className="px-4 pb-4 overflow-y-auto overscroll-contain">
                <div className={additionalContentMarginClass}>
                  <Editable
                    renderElement={renderElement}
                    renderLeaf={produceRenderLeaf(highlightedCaretRef)}
                    onKeyDown={onKeyDown}
                    readOnly={readOnly}
                  />
                </div>
              </div>
            </div>
          </ThemeCrumb>
        </Slate>
      </div>
    </OnClickOutsideWrapper>
  );
};

const TextEditor = (props) => {
  const [key, setKey] = useState(getUuid());
  const reset = () => setKey(getUuid());
  return <InnerTextEditor {...props} key={key} reset={reset} />;
};

export default TextEditor;
