/* eslint-disable max-len */
import React from 'react';

export const PlayIcon = ({ className = '', style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className={`fill-current ${className}`}
    style={style}
  >
    <path d="M3 22v-20l18 10-18 10z" />
  </svg>
);
export default PlayIcon;
