import moment from 'moment';
import {
  getCookieLastActivityDate,
  removeCookieLastActivityDate,
  setExpiredCookieLastActivityDate,
} from '../../helpers/lastActivityDateHelpers';
import quickPass from '../../constants/quickPass';

const initialState = () => ({ active: false, hash: null });

const idleLimitExceeded = () => {
  const lastActivityDate = getCookieLastActivityDate();
  if (lastActivityDate) {
    const activationDate = lastActivityDate.clone().add(quickPass.idleTimeToActivate, 'ms');
    return moment() > activationDate;
  }
  return false;
};

export default function (state = initialState(), action) {
  switch (action.type) {
    case 'QUICK_PASS_ACTION_ACTIVATE':
      // hack to simplify things significantly - set the oldest possible moment
      setExpiredCookieLastActivityDate();
      // use idleLimitExceeded to ensure cross-tab sync
      return { ...state, active: !!state.hash && idleLimitExceeded() };
    case 'QUICK_PASS_ACTION_AUTO_ACTIVATE':
      // use idleLimitExceeded to ensure cross-tab sync
      return { ...state, active: !!state.hash && idleLimitExceeded() };
    case 'QUICK_PASS_ACTION_DEACTIVATE':
      return { ...state, active: false };
    case 'QUICK_PASS_ACTION_REMOVE_HASH':
      removeCookieLastActivityDate();
      return { ...state, active: false, hash: action.payload };
    case 'QUICK_PASS_ACTION_SET_HASH': {
      const hash = action.payload;
      const hashPresent = !!hash;
      const active = hashPresent && idleLimitExceeded();
      return { ...state, active, hash };
    }
    case 'GLOBAL_ACTION_RESET': {
      if (action.payload?.skipSessionConfig) return state;
      else return initialState();
    }
    default:
      return state;
  }
}
