import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import StandardContentTemplate from '../../../shared/contentTemplates/StandardContentTemplate';
import Left from './Left';
import Main from './Main';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { wastesActionFetch } from '../../../stateManagement/actions/wastesActions';
import { modelNames } from './helpers';
import { getAllWastes } from '../../../helpers/wasteHelpers';

const WastesIndexPage = (props) => {
  const { incognitoMode, wastes, wastesActionFetch } = props;
  const { modelName } = useParams();
  const history = useHistory();

  const fetched = wastes.fetched(modelName);
  useEffect(() => {
    if (modelName && !fetched) wastesActionFetch(modelName);
  }, [modelName, fetched]);

  const relevantWastes = useMemo(
    () => getAllWastes(wastes, modelName, incognitoMode),
    [wastes, modelName, incognitoMode],
  );

  if (incognitoMode) {
    setTimeout(() => {
      history.replace(`/`);
    });
    return <></>;
  }
  if (!modelName) {
    setTimeout(() => {
      history.replace(`/waste-bin/${modelNames[0]}`);
    });
    return <></>;
  }

  return <StandardContentTemplate left={<Left />} mainReady={fetched} main={<Main wastes={relevantWastes} />} />;
};

export default connect(prepareState(['incognitoMode', 'wastes']), {
  wastesActionFetch,
})(WastesIndexPage);
