import Collection from '../../models/auxiliary/collection';

const initialState = () => new Collection();

export default function (state = initialState(), action) {
  switch (action.type) {
    case 'LABELS_ACTION_FETCH':
      return state.toNotFetched();
    case 'LABELS_ACTION_SET':
      return new Collection().toFetched(false).withReplacedElements(action.payload, { createClone: false });
    case 'LABELS_ACTION_TOGGLE_ASSIGNMENT':
      return state.toLocked();
    case 'LABELS_ACTION_MARK_PRIVATE_ELEMENT':
    case 'LABELS_ACTION_UNMARK_PRIVATE_ELEMENT':
    case 'LABELS_ACTION_LOCK_ELEMENT':
      return state.withActualizedElements(action.payload.toLocked(), ['locked']);
    case 'LABELS_ACTION_UNLOCK_ELEMENT':
      return state.withActualizedElements(action.payload.toUnlocked(), ['locked']);
    case 'LABELS_ACTION_SET_ELEMENT':
      return state.withReplacedElements(action.payload.toUnlocked());
    case 'GLOBAL_ACTION_RESET':
      return initialState();
    default:
      return state;
  }
}
