import { Editor, Range } from 'slate';
import { leafVariants, leafVariantsValues } from '../constants/leafVariants';
import { ReactEditor } from 'slate-react';

export const getValidLeafVariant = (leafVariant) => (leafVariantsValues.includes(leafVariant) ? leafVariant : null);

export const leafVariantToIconButtonVariant = (leafVariant) => {
  leafVariant = getValidLeafVariant(leafVariant);
  switch (leafVariant) {
    case leafVariants.bold:
      return 'bold';
    case leafVariants.italic:
      return 'italic';
    case leafVariants.underline:
      return 'underline';
    case leafVariants.strikethrough:
      return 'strikethrough';
    case leafVariants.code:
      return 'code';
  }
};

export const getActiveLeavesVariants = (editor) => {
  let result;
  for (let [node] of Editor.nodes(editor, { at: editor.selection, mode: 'lowest' })) {
    const leafVariants = Object.keys(node).filter((k) => leafVariantsValues.includes(k));
    if (!result) result = new Set(leafVariants);
    else result = new Set([...result].filter((key) => leafVariants.includes(key)));
  }

  if (!result) result = new Set();
  return result;
};

export const getToolbarActiveLeavesVariants = (editor) => {
  const { tempToggledLeavesVariants } = editor;
  const activeLeavesVariants = getActiveLeavesVariants(editor);
  return new Set(
    leafVariantsValues.filter((leafVariant) => {
      let active = activeLeavesVariants.has(leafVariant);
      if (tempToggledLeavesVariants.includes(leafVariant)) active = !active;
      return active;
    }),
  );
};

export const produceToggleLeafVariant = (editor, leafVariant) => (event) => {
  if (event) event.preventDefault();
  if (!leafVariantsValues.includes(leafVariant)) return;

  const { tempToggledLeavesVariants } = editor;
  const setTempToggledLeavesVariants = (newValue) => {
    editor.tempToggledLeavesVariants = newValue;
    editor.forceToolbarReload();
  };

  const activeLeavesVariants = getActiveLeavesVariants(editor);
  const selectionCollapsed = editor.selection ? Range.isCollapsed(editor.selection) : false;

  if (selectionCollapsed) {
    const leafVariantIndex = tempToggledLeavesVariants.indexOf(leafVariant);
    if (leafVariantIndex === -1) setTempToggledLeavesVariants([...tempToggledLeavesVariants, leafVariant]);
    else {
      setTempToggledLeavesVariants(tempToggledLeavesVariants.filter((lv) => lv !== leafVariant));
      editor.removeMark(leafVariant);

      ReactEditor.focus(editor);
      return;
    }
  }

  if (activeLeavesVariants.has(leafVariant)) {
    editor.removeMark(leafVariant);
  } else {
    editor.addMark(leafVariant, true);
  }

  ReactEditor.focus(editor);
};
