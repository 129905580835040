import frenchkiss from 'frenchkiss';

frenchkiss.extend('en', {
  features: {
    taskPanel: {
      noDetails: 'NO DETAILS',
      description: 'Name',
      categoryId: 'Category',
      todoFrom: 'TODO from',
      details: 'Additional details',
      todoFromDetail: 'TODO from',
    },
  },
});
