import React from 'react';
import CustomColorCrumb from '../../shared/CustomColorCrumb';

const EventPresenter = (props) => {
  const { event } = props;
  return (
    <CustomColorCrumb
      styleKeys={['borderColor', 'backgroundColor']}
      color={event.color}
      mixinThemeType="container"
      mixedStyleKeysMultipliers={{ borderColor: 0.8, backgroundColor: 0.8 }}
    >
      <div className="rounded overflow-hidden min-w-16">{event.name}</div>
    </CustomColorCrumb>
  );
};

export default EventPresenter;
