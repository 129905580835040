import React, { useEffect } from 'react';
import ThemeCrumb from '../../ThemeCrumb';

const notHideableBottomWindowHeightThresholdPx = 500;

let lastScrollTop = 0;
let ongoingTouchmove = false;

const toNotFixedPosition = (bottomHeightPx, windowHeightPx) => {
  const bottom = document.getElementById('standard-content-template-bottom');
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const scrollHeightPx = document.body.scrollHeight;
  const translateYPx = Math.max(Math.min(bottomHeightPx, scrollHeightPx - scrollTop - windowHeightPx), 0);
  bottom.style.transform = `translateY(${translateYPx}px)`;
};

const toFixedPosition = () => {
  const bottom = document.getElementById('standard-content-template-bottom');
  bottom.style.transform = 'translateY(0)';
};

const Bottom = (props) => {
  const { children, leftOffset = '0px', rightOffset = '0px', windowHeightPx, bottomHeightPx, hideable = false } = props;

  useEffect(() => {
    if (!hideable) {
      toFixedPosition();
      return;
    }
    if (windowHeightPx < notHideableBottomWindowHeightThresholdPx) toNotFixedPosition(bottomHeightPx, windowHeightPx);
    else toFixedPosition();
  }, [windowHeightPx < notHideableBottomWindowHeightThresholdPx, hideable]);

  useEffect(() => {
    lastScrollTop = 0;
    if (!hideable) return;

    const listener = () => {
      if (windowHeightPx < notHideableBottomWindowHeightThresholdPx) {
        toNotFixedPosition(bottomHeightPx, windowHeightPx);
        return;
      }

      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > lastScrollTop) toNotFixedPosition(bottomHeightPx, windowHeightPx);
      else if (!ongoingTouchmove) toFixedPosition();
      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    };
    const onTouchmove = () => (ongoingTouchmove = true);
    const onTouchend = () => (ongoingTouchmove = false);
    window.addEventListener('scroll', listener);
    window.addEventListener('touchmove', onTouchmove);
    window.addEventListener('touchend', onTouchend);
    window.addEventListener('touchcancel', onTouchend);
    return () => {
      window.removeEventListener('scroll', listener);
      window.removeEventListener('touchmove', onTouchmove);
      window.removeEventListener('touchend', onTouchend);
      window.removeEventListener('touchcancel', onTouchend);
    };
  }, [windowHeightPx, bottomHeightPx, hideable]);

  return (
    <ThemeCrumb type="main">
      <div
        id="standard-content-template-bottom"
        className={'flex justify-center fixed'}
        style={{
          zIndex: 5,
          bottom: 0,
          left: `calc(${leftOffset})`,
          right: `calc(${rightOffset})`,
          width: `calc(100% - ${rightOffset} - ${leftOffset})`,
        }}
      >
        <ThemeCrumb type="standardTemplateBottom">
          <div
            className="w-full flex justify-center items-center
                                border-t border-l border-r rounded-t-xl"
          >
            {children}
          </div>
        </ThemeCrumb>
      </div>
    </ThemeCrumb>
  );
};

export default Bottom;
