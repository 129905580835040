import React from 'react';
import { alignmentTypeToFlexJustifyClass, alignmentTypeToTextClass } from '../helpers/alignmentHelpers';
import { alignmentTypes } from '../constants/alignmentTypes';

const alpha = Array.from(Array(26)).map((e, i) => i + 97);
const alphabet = alpha.map((x) => String.fromCharCode(x));
const alphabetize = (number) => {
  let result = '';
  while (number !== 0) {
    result = alphabet[(number - 1) % 26] + result;
    number = Math.floor((number - 1) / 26);
  }
  return result;
};

const romanKeys = [
  '',
  'C',
  'CC',
  'CCC',
  'CD',
  'D',
  'DC',
  'DCC',
  'DCCC',
  'CM',
  '',
  'X',
  'XX',
  'XXX',
  'XL',
  'L',
  'LX',
  'LXX',
  'LXXX',
  'XC',
  '',
  'I',
  'II',
  'III',
  'IV',
  'V',
  'VI',
  'VII',
  'VIII',
  'IX',
];
const romanize = (number) => {
  let digits = String(+number).split(''),
    roman = '',
    i = 3;
  while (i--) roman = (romanKeys[+digits.pop() + i * 10] || '') + roman;
  return Array(+digits.join('') + 1).join('M') + roman;
};

const OrderedBullet = React.forwardRef((props, ref) => {
  const { children, alignmentType, nestingLevel, ordinal, attributes } = props;

  let bullet;
  let leftMarginClass;
  switch (nestingLevel) {
    case 0:
      bullet = Number.isInteger(ordinal) ? `${ordinal + 1}.` : '';
      leftMarginClass = '';
      break;
    case 1:
      bullet = Number.isInteger(ordinal) ? `${alphabetize(ordinal + 1)}.` : '';
      leftMarginClass = alignmentType === alignmentTypes.center ? 'pl-8' : 'pl-4';
      break;
    case 2:
      bullet = Number.isInteger(ordinal) ? `${romanize(ordinal + 1)}.` : '';
      leftMarginClass = alignmentType === alignmentTypes.center ? 'pl-16' : 'pl-8';
      break;
  }
  const containerClass = `${leftMarginClass} pt-2 first:pt-4 flex ${alignmentTypeToFlexJustifyClass(alignmentType)}`;

  return (
    <div className={containerClass} ref={ref} {...attributes}>
      <div contentEditable={false} className="mr-2 font-mono">
        {bullet}
      </div>
      <div className={alignmentTypeToTextClass(alignmentType)}>{children}</div>
    </div>
  );
});

export default OrderedBullet;
