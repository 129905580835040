import { DateTime } from 'luxon';
import { backendDateFormat } from '../../constants/dateFormat';

export const tasksActionFetch = () => ({
  type: 'TASKS_ACTION_FETCH',
});

export const tasksActionGeneratedLookup = (date) => ({
  type: 'TASKS_ACTION_GENERATED_LOOKUP',
  payload: { date },
});

export const tasksActionSet = (tasks) => ({
  type: 'TASKS_ACTION_SET',
  payload: tasks,
});

export const tasksActionCreateElement = (task) => ({
  type: 'TASKS_ACTION_CREATE_ELEMENT',
  payload: task,
});

export const tasksActionUnlock = () => ({
  type: 'TASKS_ACTION_UNLOCK',
});

export const tasksActionUpdateElement = (task) => ({
  type: 'TASKS_ACTION_UPDATE_ELEMENT',
  payload: task,
});

export const tasksActionCheckElement = (task, checkedDate = DateTime.now().toFormat(backendDateFormat)) => ({
  type: 'TASKS_ACTION_CHECK_ELEMENT',
  payload: task.assignValues({ checked: true, checkedDate }),
});

export const tasksActionMarkTodoElement = (task) => ({
  type: 'TASKS_ACTION_MARK_TODO_ELEMENT',
  payload: task.assignValues({ todo: true }),
});

export const tasksActionUnmarkTodoElement = (task) => ({
  type: 'TASKS_ACTION_UNMARK_TODO_ELEMENT',
  payload: task.assignValues({ todo: false }),
});

export const tasksActionMarkPrivateElement = (task) => ({
  type: 'TASKS_ACTION_MARK_PRIVATE_ELEMENT',
  payload: task.assignValues({ private: true }),
});

export const tasksActionUnmarkPrivateElement = (task) => ({
  type: 'TASKS_ACTION_UNMARK_PRIVATE_ELEMENT',
  payload: task.assignValues({ private: false }),
});

export const tasksActionMoveElement = (task, direction) => ({
  type: 'TASKS_ACTION_MOVE_ELEMENT',
  payload: { task, direction },
});

export const tasksActionDeleteElement = (task) => ({
  type: 'TASKS_ACTION_DELETE_ELEMENT',
  payload: task,
});

export const tasksActionLockElement = (task) => ({
  type: 'TASKS_ACTION_LOCK_ELEMENT',
  payload: task,
});

export const tasksActionUnlockElement = (task) => ({
  type: 'TASKS_ACTION_UNLOCK_ELEMENT',
  payload: task,
});

export const tasksActionSetCreatedElement = (task) => ({
  type: 'TASKS_ACTION_SET_CREATED_ELEMENT',
  payload: task,
});

export const tasksActionSetElement = (task) => ({
  type: 'TASKS_ACTION_SET_ELEMENT',
  payload: task,
});

export const tasksActionUnset = (oneOrManyElements) => ({
  type: 'TASKS_ACTION_UNSET',
  payload: oneOrManyElements,
});
