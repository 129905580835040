import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { notificationT } from '../../../helpers/i18nHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { push } from 'connected-react-router';
import {
  learningWorkspacesActionUnset,
  learningWorkspacesActionUnlockElement,
} from '../../actions/learningWorkspacesActions';

export default function* deleteElementLearningWorkspacesSaga(action) {
  const initialWorkspace = action.payload;
  const { ok, data } = yield call(api.learningWorkspaces.delete, initialWorkspace.id);
  if (ok) {
    yield put(push('/learning/workspaces'));
    yield put(snackbarMessagesActionAdd(notificationT('learningWorkspaces.delete.success')));
    yield put(learningWorkspacesActionUnset(initialWorkspace));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(learningWorkspacesActionUnlockElement(initialWorkspace));
  }
}
