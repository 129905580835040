import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { notificationT } from '../../../helpers/i18nHelpers';
import { activeElementsActionActivateIfOtherInactive } from '../../actions/activeElementsActions';
import { activeElementTypes } from '../../../constants/activeElementTypes';
import { habitFromDto, habitToDto } from '../../../helpers/model/habitsHelpers';
import { habitsActionSetCreatedElement, habitsActionUnlock } from '../../actions/habitActions';
import { habitAnswersActionReset } from '../../actions/habitAnswersActions';
import { push } from 'connected-react-router';

export default function* createElementHabitsSaga(action) {
  const { ok, data } = yield call(api.habits.create, habitToDto(action.payload));
  if (ok) {
    const newHabit = habitFromDto(data);
    yield put(push(`/habits/${newHabit.habitsSectionId}`));
    yield put(snackbarMessagesActionAdd(notificationT('habits.create.success')));
    yield put(habitAnswersActionReset());
    yield put(habitsActionSetCreatedElement(newHabit));
    yield put(activeElementsActionActivateIfOtherInactive(activeElementTypes.habit, newHabit.id));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data, 'habits.create.errors'));
    yield put(habitsActionUnlock());
  }
}
