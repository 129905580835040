import Lockable from './parents/lockable';

export default class Task extends Lockable {
  constructor() {
    super();
    this.id = null;
    this.categoryId = null;
    this.nodesGroupId = null;
    this.description = null;
    this.todo = false;
    this.defaultOrder = 0;
    this.todoOrder = 0;
    this.todoFrom = null;
    this.private = false;
    this.checked = false;
    this.checkedDate = null;
  }

  visible(incognitoMode = false) {
    return incognitoMode ? !this.private : true;
  }
}
