import React, { useState } from 'react';
import { initT } from '../../../helpers/i18nHelpers';
import LockIcon from '../../../icons/lockIcon';
import SunglassesIcon from '../../../icons/sunglassesIcon';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { quickPassActionActivate } from '../../../stateManagement/actions/quickPassActions';
import { sessionActionSignOut } from '../../../stateManagement/actions/sessionActions';
import {
  incognitoModeActionDisable,
  incognitoModeActionEnable,
} from '../../../stateManagement/actions/incognitoModeActions';
import SignOutIcon from '../../../icons/signOutIcon';
import NavbarBigScreenContent from './NavbarBigScreenContent';
import useResizeEffect from '../../../hooks/useResizeEffect';
import NavbarSmallScreenContent from './NavbarSmallScreenContent';
import ThemeCrumb from '../../../shared/ThemeCrumb';
import { currentDateInBackendFormat } from '../../../helpers/dateTimeHelpers';

const t = initT('navbar.links');

const Navbar = (props) => {
  const {
    session,
    incognitoMode,
    quickPassActionActivate,
    sessionActionSignOut,
    incognitoModeActionEnable,
    incognitoModeActionDisable,
  } = props;

  const renderBigThresholdPx = 1024;
  const [windowWidthPx, setWindowWidthPx] = useState(window.innerWidth);
  useResizeEffect(
    () => {
      setWindowWidthPx(window.innerWidth);
    },
    [],
    true,
  );
  const renderBig = windowWidthPx >= renderBigThresholdPx;

  const incognitoModeIconOnClick = incognitoMode ? incognitoModeActionDisable : incognitoModeActionEnable;
  const incognitoModeIconTooltip = incognitoMode ? t('disableIncognitoMode') : t('enableIncognitoMode');

  const leftLinksConfig = [
    {
      key: 'lock',
      onMouseDown: quickPassActionActivate,
      tooltip: t('lock'),
      children: <LockIcon className="w-6 h-6" />,
    },
    {
      key: 'incognito',
      onMouseDown: incognitoModeIconOnClick,
      tooltip: incognitoModeIconTooltip,
      confirmationI18nKey: incognitoMode ? 'incognitoMode.exit' : null,
      children: <SunglassesIcon className={`${incognitoMode ? '' : 'opacity-20'} w-6 h-6`} />,
    },
  ];

  const mainLinksConfig = [
    { key: 'notes', to: '/notes', children: t('notes'), smallScreenOrder: 1 },
    { key: 'learning', to: '/learning', children: t('learning'), smallScreenOrder: 2 },
    { key: 'language', to: '/language', children: t('language'), smallScreenOrder: 3 },
    {
      key: 'journal',
      to: `/journal?date=${currentDateInBackendFormat()}`,
      children: t('journal'),
      smallScreenOrder: 0,
    },
    { key: 'habits', to: '/habits', children: t('habits'), smallScreenOrder: 4 },
    { key: 'tasks', to: '/tasks', children: t('tasks'), smallScreenOrder: 5 },
    { key: 'other', to: '/other', children: t('other'), smallScreenOrder: 6 },
  ];

  const rightLinksConfig = [
    {
      key: 'profile',
      to: '/profile',
      tooltip: t('profile'),
      children: (
        <img alt="Google User Profile Picture" src={session.picture} className="text-0" style={{ width: '24px' }} />
      ),
      smallScreenOrder: 7,
    },
    {
      key: 'signOut',
      onMouseDown: sessionActionSignOut,
      tooltip: t('signOut'),
      children: <SignOutIcon className="w-6 h-6" />,
      smallScreenOrder: 8,
    },
  ];

  const propsToPass = {
    leftLinksConfig,
    mainLinksConfig,
    rightLinksConfig,
  };

  return (
    <div id="navbar" className="sticky z-10 top-0">
      <div className="flex w-full justify-center items-center">
        <ThemeCrumb type="navbar">
          <div
            className="w-full max-w-ultra border-b ultra:border-l ultra:border-r
                                    h-56px flex"
          >
            {renderBig ? <NavbarBigScreenContent {...propsToPass} /> : <NavbarSmallScreenContent {...propsToPass} />}
          </div>
        </ThemeCrumb>
      </div>
    </div>
  );
};

export default connect(prepareState(['session', 'incognitoMode']), {
  quickPassActionActivate,
  sessionActionSignOut,
  incognitoModeActionEnable,
  incognitoModeActionDisable,
})(Navbar);
