import React, { useRef } from 'react';
import { draggedElements } from '../../constants/draggedElements';
import TaskPanel from '../../shared/features/taskPanel';
import List from '../../shared/lists/List';
import { activeElementTypes } from '../../constants/activeElementTypes';
import TaskCreator from '../../shared/features/taskCreator';
import useNarrowMainWidth from '../../hooks/useNarrowMainWidth';

const TodoList = (props) => {
  const { todoTasks, disabled, additionCategory, todoCreationActive } = props;
  const draggableListRef = useRef(null);

  const width = useNarrowMainWidth();
  const activeWidth = useNarrowMainWidth(false);

  const draggableTasks = [];
  const notDraggableTasks = [];
  todoTasks.forEach((tt) => {
    if (tt.task.todoFrom) notDraggableTasks.push(tt);
    else draggableTasks.push(tt);
  });

  return (
    <>
      {todoCreationActive ? (
        <div className="w-full flex justify-center mb-2">
          <div style={{ width }}>
            <TaskCreator category={additionCategory} alwaysTodo cancellable />
          </div>
        </div>
      ) : (
        <></>
      )}
      <List
        collection={draggableTasks}
        draggable={{
          ref: draggableListRef,
          draggedElementType: draggedElements.todoTask,
        }}
        noDataCondition={draggableTasks.length === 0 && !todoCreationActive}
      >
        {({ task, color, checkedDate }) => (
          <div key={task.id} className="w-full flex justify-center mt-2 first:mt-0">
            <TaskPanel
              task={task}
              color={color}
              checkedDate={checkedDate}
              activeElementType={activeElementTypes.todoTask}
              draggableListRef={draggableListRef}
              managementMode={false}
              disabled={disabled}
              width={width}
              activeWidth={activeWidth}
            />
          </div>
        )}
      </List>
      {notDraggableTasks.map(({ task, color, checkedDate }) => (
        <div key={task.id} className="w-full flex justify-center mt-2 first:mt-0">
          <TaskPanel
            task={task}
            color={color}
            checkedDate={checkedDate}
            activeElementType={activeElementTypes.todoTask}
            managementMode={false}
            disabled={disabled}
            width={width}
            activeWidth={activeWidth}
          />
        </div>
      ))}
    </>
  );
};

export default TodoList;
