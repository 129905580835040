import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../helpers/stateManagementHelpers';
import { useCheckIfMounted } from '../../hooks/useCheckIfMounted';
import { onClickOutsideCallbacksActionUnregisterElement } from '../../stateManagement/actions/onClickOutsideCallbacksActions';
import { activeElementModalTypes } from '../../constants/activeElementTypes';
import { generateTagId } from '../../helpers/identifierHelpers';
import difference from 'lodash/difference';

let onMouseDownListener;

const checkIfBlockedByParentClass = (exceptionClasses, target) => {
  let result = false;
  exceptionClasses.forEach((exceptionClass) => {
    for (let exceptionElement of document.getElementsByClassName(exceptionClass))
      if (exceptionElement.contains(target)) result = true;
  });
  return result;
};

const checkIfBlockedByParentId = (exceptionIds, target) => {
  let result = false;
  exceptionIds.forEach((exceptionId) => {
    const element = document.getElementById(exceptionId);
    if (element && element.contains(target)) result = true;
  });
  return result;
};

const OnClickOutsideController = (props) => {
  const { onClickOutsideCallbacks, activeElements, onClickOutsideCallbacksActionUnregisterElement: unregister } = props;

  const id = useMemo(generateTagId, []);
  const checkIfMounted = useCheckIfMounted();

  const openModals = useMemo(
    () =>
      activeElements
        .filter((activeElement) => activeElementModalTypes.includes((activeElement || {}).type))
        .map((activeElement) => (activeElement || {}).type),
    [activeElements],
  );
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const safeOnMouseDownListener = (e) => {
      if (onMouseDownListener) onMouseDownListener(e);
    };

    window.addEventListener('mousedown', safeOnMouseDownListener);
    window.addWindowPopupOpenListener(id, () => setPopupOpen(true));
    window.addWindowPopupCloseListener(id, () => setPopupOpen(false));
    return () => {
      window.removeEventListener('mousedown', safeOnMouseDownListener);
      window.removeWindowPopupOpenListener(id);
      window.removeWindowPopupCloseListener(id);
    };
  });

  useEffect(() => {
    if (!checkIfMounted()) return;
    if (onClickOutsideCallbacks.length === 0) {
      setTimeout(() => {
        onMouseDownListener = undefined;
      });
      return;
    }

    const newListener = (e) => {
      let callbackIndex = 0;

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const {
          htmlElementId,
          disableWhenModalOpen,
          disableWhenPopupOpen,
          exceptionClasses,
          exceptionIds,
          exceptionModals,
        } = onClickOutsideCallbacks[callbackIndex];

        const element = document.getElementById(htmlElementId);

        const notPresent = !element;
        const clickedOutside = !notPresent && !element.contains(e.target);
        const blockedByModal = disableWhenModalOpen && difference(openModals, exceptionModals).length > 0;
        const blockedByPopup = disableWhenPopupOpen && popupOpen;
        const blockedByParentClass = checkIfBlockedByParentClass(exceptionClasses, e.target);
        const blockedByParentId = checkIfBlockedByParentId(exceptionIds, e.target);

        if (notPresent) unregister(onClickOutsideCallbacks[callbackIndex].htmlElementId);

        if (clickedOutside && !blockedByModal && !blockedByPopup && !blockedByParentClass && !blockedByParentId) break;

        callbackIndex += 1;
        if (callbackIndex === onClickOutsideCallbacks.length) return;
      }

      const { callback } = onClickOutsideCallbacks[callbackIndex];

      callback(e);
    };
    setTimeout(() => {
      onMouseDownListener = newListener;
    });
  }, [onClickOutsideCallbacks, openModals, popupOpen]);

  return <></>;
};

export default connect(prepareState(['onClickOutsideCallbacks', 'activeElements']), {
  onClickOutsideCallbacksActionUnregisterElement,
})(OnClickOutsideController);
