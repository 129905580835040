import React, { useState } from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import { categoriesActionUpdateElement } from '../../../stateManagement/actions/categoriesActions';
import LockedEntityLoader from '../../../shared/loaders/LockedEntityLoader';
import { TitleEditor } from '../../../shared/features/titleEditor';
import CategoryHeader from './CategoryHeader';

const Top = (props) => {
  const { category, categoriesActionUpdateElement: update } = props;

  const [editionMode, setEditionMode] = useState(false);

  const handleSubmit = ({ title }) => {
    const updatedCategory = category.shallowClone().assignValues({ ...category, name: title });
    if (title !== category.name) update(updatedCategory);
    setEditionMode(false);
  };

  return (
    <div className="w-full h-40px">
      <LockedEntityLoader visible={category.locked}>
        {editionMode ? (
          <TitleEditor title={category.name} handleSubmit={handleSubmit} />
        ) : (
          <CategoryHeader category={category} enableEditionMode={() => setEditionMode(true)} />
        )}
      </LockedEntityLoader>
    </div>
  );
};

export default connect(prepareState(['incognitoMode', 'categories']), {
  categoriesActionUpdateElement,
})(Top);
