import React, { useState } from 'react';
import RecurringTaskAdditionPanel from './RecurringTaskAdditionPanel';
import RecurringTasksList from './RecurringTasksList';

const RecurringTasks = () => {
  const [additionMode, setAdditionMode] = useState(false);

  return (
    <div className="w-full pb-25px">
      <RecurringTaskAdditionPanel additionMode={additionMode} setAdditionMode={setAdditionMode} />
      <div className="px-25px">
        <RecurringTasksList additionMode={additionMode} />
      </div>
    </div>
  );
};

export default RecurringTasks;
