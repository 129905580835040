import React from 'react';
import ThemeCrumb from '../../../../ThemeCrumb';
import { initT } from '../../../../../helpers/i18nHelpers';

const t = initT('selector');

const Menu = (props) => {
  const { themeType = 'selectorMenu', options, menuOpenOnBottom, fullyBordered = false } = props;

  const roundedClass = menuOpenOnBottom ? 'rounded-b-md' : 'rounded-t-md';
  const verticalBorderClass = fullyBordered ? 'border-b border-t' : menuOpenOnBottom ? 'border-b' : 'border-t';

  const content =
    options.length > 0 ? (
      options.map((option) => React.cloneElement(option, { key: option.props.value, ...props }))
    ) : (
      <div className="opacity-40 p-1">{t('noOptions')}</div>
    );

  return (
    <ThemeCrumb type={themeType}>
      <div className={`border-l border-r ${verticalBorderClass} ${roundedClass}`}>
        <div className="max-h-32 overflow-y-auto">{content}</div>
      </div>
    </ThemeCrumb>
  );
};

export default Menu;
