export const draggedElements = {
  task: 'TASK',
  todoTask: 'TODO_TASK',
  recurringTask: 'RECURRING_TASK',
  category: 'CATEGORY',
  habitsSection: 'HABITS_SECTION',
  habit: 'HABIT',
  node: 'NODE',
  notesWorkspace: 'NOTES_WORKSPACE',
  note: 'NOTE',
  event: 'EVENT',
  recurringEvent: 'RECURRING_EVENT',
  learningWorkspace: 'LEARNING_WORKSPACE',
  learningQueue: 'LEARNING_QUEUE',
  languageWorkspace: 'LANGUAGE_WORKSPACE',
};
