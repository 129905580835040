export const notesWorkspacesActionFetch = () => ({
  type: 'NOTES_WORKSPACES_ACTION_FETCH',
});

export const notesWorkspacesActionSet = (notesWorkspaces) => ({
  type: 'NOTES_WORKSPACES_ACTION_SET',
  payload: notesWorkspaces,
});

export const notesWorkspacesActionCreateElement = (notesWorkspace) => ({
  type: 'NOTES_WORKSPACES_ACTION_CREATE_ELEMENT',
  payload: notesWorkspace,
});

export const notesWorkspacesActionUnlock = () => ({
  type: 'NOTES_WORKSPACES_ACTION_UNLOCK',
});

export const notesWorkspacesActionUpdateElement = (notesWorkspace) => ({
  type: 'NOTES_WORKSPACES_ACTION_UPDATE_ELEMENT',
  payload: notesWorkspace,
});

export const notesWorkspacesActionMarkPrivateElement = (notesWorkspace) => ({
  type: 'NOTES_WORKSPACES_ACTION_MARK_PRIVATE_ELEMENT',
  payload: notesWorkspace.assignValues({ private: true }),
});

export const notesWorkspacesActionUnmarkPrivateElement = (notesWorkspace) => ({
  type: 'NOTES_WORKSPACES_ACTION_UNMARK_PRIVATE_ELEMENT',
  payload: notesWorkspace.assignValues({ private: false }),
});

export const notesWorkspacesActionLockElement = (notesWorkspace) => ({
  type: 'NOTES_WORKSPACES_ACTION_LOCK_ELEMENT',
  payload: notesWorkspace,
});

export const notesWorkspacesActionUnlockElement = (notesWorkspace) => ({
  type: 'NOTES_WORKSPACES_ACTION_UNLOCK_ELEMENT',
  payload: notesWorkspace,
});

export const notesWorkspacesActionDeleteElement = (notesWorkspace) => ({
  type: 'NOTES_WORKSPACES_ACTION_DELETE_ELEMENT',
  payload: notesWorkspace,
});

export const notesWorkspacesActionSetCreatedElement = (notesWorkspace) => ({
  type: 'NOTES_WORKSPACES_ACTION_SET_CREATED_ELEMENT',
  payload: notesWorkspace,
});

export const notesWorkspacesActionSetElement = (notesWorkspace) => ({
  type: 'NOTES_WORKSPACES_ACTION_SET_ELEMENT',
  payload: notesWorkspace,
});

export const notesWorkspacesActionUnset = (oneOrManyElements) => ({
  type: 'NOTES_WORKSPACES_ACTION_UNSET',
  payload: oneOrManyElements,
});
