import React, { useRef } from 'react';
import useResizeEffect from '../../hooks/useResizeEffect';

const CenteredContent = (props) => {
  const { left, main, right, heightPx, minMainWidthPx, className = '' } = props;

  const wrapperRef = useRef();
  const leftRef = useRef();
  const mainRef = useRef();
  const rightRef = useRef();

  useResizeEffect(
    ({ initialRun }) => {
      const wrapper = wrapperRef.current;
      const _left = leftRef.current;
      const _right = rightRef.current;

      const effect = () => {
        if (!wrapper || !_left || !_right) return;

        const wrapperWidthPx = wrapper.clientWidth;
        const leftWidthPx = _left.clientWidth;
        const rightWidthPx = _right.clientWidth;

        let sideWidthPx = Math.max(leftWidthPx, rightWidthPx);
        if (minMainWidthPx) {
          sideWidthPx = Math.min(sideWidthPx, Math.round((wrapperWidthPx - minMainWidthPx) / 2));
        }

        _left.style.minWidth = `${sideWidthPx}px`;
        _right.style.minWidth = `${sideWidthPx}px`;
      };

      if (initialRun) {
        effect();
      } else {
        _left.style.minWidth = '';
        _right.style.minWidth = '';
        setTimeout(effect);
      }
    },
    [left, main, right, heightPx],
    true,
  );

  const wrapperClass = `w-full flex items-center ${className}`;
  const leftClass = 'flex justify-start items-center';
  const rightClass = 'flex justify-end items-center';
  const mainClass = 'flex-grow flex justify-center items-center truncate';

  return (
    <div ref={wrapperRef} className={wrapperClass} style={{ height: heightPx }}>
      <div ref={leftRef} className={leftClass}>
        {left}
      </div>
      <div ref={mainRef} className={mainClass} style={{ minWidth: minMainWidthPx }}>
        {main}
      </div>
      <div ref={rightRef} className={rightClass}>
        {right}
      </div>
    </div>
  );
};

export default CenteredContent;
