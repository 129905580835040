import React, { useMemo, useState, useCallback } from 'react';
import RecordingTrigger from './RecordingTrigger';
import { getUuid } from '../../../../helpers/identifierHelpers';
import ExchangeableContents from '../../../ExchangeableContents';
import RecordingPlaceholder from './RecordingPlaceholder';
import { frontEndErrorT, initT } from '../../../../helpers/i18nHelpers';
import FileChooser from './FileChooser';
import { maxFileSizeInMb } from '../../../../constants/files';
import { snackbarMessagesActionAdd } from '../../../../stateManagement/actions/snackbarMessagesActions';
import snackbarMessages from '../../../../constants/snackbarMessages';
import { connect } from 'react-redux';
import ModalHeader from '../../ModalHeader';
import { activeElementTypes } from '../../../../constants/activeElementTypes';
import ModalBody from '../../ModalBody';
import ModalFooter from '../../ModalFooter';
import LabelledButton from '../../../buttons/LabelledButton';
import find from 'lodash/find';
import last from 'lodash/last';
import { nodesActionUpdateAudioUrlElement } from '../../../../stateManagement/actions/nodesActions';
import { activeElementsActionDeactivate } from '../../../../stateManagement/actions/activeElementsActions';
import NoAudioPanel from './NoAudioPanel';
import AuxiliaryText from '../../../AuxiliaryText';
import NodePanel from '../../../features/nodesGroupPanel/nodePanel';
import SubSectionTitle from '../../../titles/SubSectionTitle';
import AudioPanel from './AudioPanel';

const t = initT('modals.audioSelectionModal');

const AudioSelectionModalContent = (props) => {
  const {
    identifier,
    node,
    node: { audioUrl, audioMask },
    snackbarMessagesActionAdd,
    activeElementsActionDeactivate: deactivate,
    nodesActionUpdateAudioUrlElement: updateAudioUrl,
  } = props;

  const candidates = useMemo(() => [], []);
  const initialAudioUuid = useMemo(getUuid, []);
  const [selectedCandidateUuid, setSelectedCandidateUuid] = useState(audioUrl ? initialAudioUuid : null);
  const [recording, setRecording] = useState(false);
  const [stopRecording, setStopRecording] = useState(() => () => {});
  const [recordingsCount, setRecordingsCount] = useState(0);
  const [masks, setMasks] = useState({ [initialAudioUuid]: audioMask });

  const hideModal = useCallback(
    () => deactivate(activeElementTypes.audioSelectionModal, identifier),
    [deactivate, identifier],
  );

  const addFile = (file) => {
    if (file.size > maxFileSizeInMb * 1024 * 1024) {
      snackbarMessagesActionAdd(
        frontEndErrorT('shared.file.tooBig', { maxFileSizeInMb }),
        snackbarMessages.types.error,
      );
      return;
    }

    setRecordingsCount(recordingsCount + 1);
    const uuid = getUuid();
    candidates.unshift({ uuid, file });
    setSelectedCandidateUuid(uuid);
  };
  const confirm = () => {
    let newMask = masks[selectedCandidateUuid];
    if (initialAudioUuid === selectedCandidateUuid) {
      if (JSON.stringify(audioMask) === JSON.stringify(newMask)) {
        hideModal();
        return;
      } else {
        hideModal();
        updateAudioUrl(node, { mask: newMask });
        return;
      }
    }

    let newFile = null;
    if (selectedCandidateUuid) {
      const selectedCandidate = find(candidates, { uuid: selectedCandidateUuid });
      const { file } = selectedCandidate;
      const extension = last(file.name.split('.'));
      Object.defineProperty(file, 'name', {
        writable: true,
        value: `${t('currentAudioName')}.${extension}`,
      });
      newFile = file;
    }
    if (!newFile) newMask = null;

    hideModal();
    updateAudioUrl(node, { file: newFile, mask: newMask });
  };

  return (
    <div className="w-148 max-w-almost-screen">
      <ExchangeableContents
        leaderVisible={!recording}
        follower={<RecordingPlaceholder stopRecording={stopRecording} node={node} />}
      >
        <ModalHeader type={activeElementTypes.audioSelectionModal} identifier={identifier}>
          {t('title')}
        </ModalHeader>
        <ModalBody>
          <div className="w-full flex">
            <RecordingTrigger
              setRecording={setRecording}
              onSuccess={addFile}
              recordingsCount={recordingsCount}
              setStopRecording={setStopRecording}
            />
            <FileChooser onFileChosen={addFile} />
          </div>
          <div className="py-25px">
            <NodePanel node={node} editionAllowed={false} interactive={false} />
          </div>
          <div>
            <SubSectionTitle marginClass="mb-25px">{t('selection')}</SubSectionTitle>
            {audioUrl ? (
              <AudioPanel
                src={audioUrl}
                mask={masks[initialAudioUuid]}
                selected={selectedCandidateUuid === initialAudioUuid}
                onSelect={() => {
                  setSelectedCandidateUuid(initialAudioUuid);
                }}
                onMaskUpdated={(newMask) => {
                  setMasks({ ...masks, [initialAudioUuid]: newMask });
                }}
                label={t('currentAudioName')}
              />
            ) : (
              <></>
            )}
            {candidates.length > 0 ? (
              candidates.map(({ uuid, file }) => (
                <div key={uuid} className="first:mt-0 mt-25px">
                  <AudioPanel
                    file={file}
                    mask={masks[uuid]}
                    selected={selectedCandidateUuid === uuid}
                    label={file.name}
                    onSelect={() => {
                      setSelectedCandidateUuid(uuid);
                    }}
                    onMaskUpdated={(newMask) => {
                      setMasks({ ...masks, [uuid]: newMask });
                    }}
                  />
                </div>
              ))
            ) : (
              <></>
            )}
            <div className="first:mt-0 mt-25px">
              <NoAudioPanel
                selectedCandidateUuid={selectedCandidateUuid}
                setSelectedCandidateUuid={setSelectedCandidateUuid}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div>
            <AuxiliaryText className="mr-2">{t('notSelectedLossWarning')}</AuxiliaryText>
            <div className="flex w-full justify-end mt-2">
              <LabelledButton variant="cancel" onMouseDown={hideModal} />
              <LabelledButton variant="confirm" onMouseDown={confirm} />
            </div>
          </div>
        </ModalFooter>
      </ExchangeableContents>
    </div>
  );
};

export default connect(null, {
  activeElementsActionDeactivate,
  snackbarMessagesActionAdd,
  nodesActionUpdateAudioUrlElement,
})(AudioSelectionModalContent);
