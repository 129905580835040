import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { prepareState } from '../../../helpers/stateManagementHelpers';
import sortBy from 'lodash/sortBy';
import { draggedElements } from '../../../constants/draggedElements';
import List from '../../../shared/lists/List';
import TaskPanel from '../../../shared/features/taskPanel';
import TaskCreator from '../../../shared/features/taskCreator';
import MainTopSpace from '../../../shared/organizers/MainTopSpace';
import FullPageNoData from '../../../shared/placeholders/FullPageNoData';
import useNarrowMainWidth from '../../../hooks/useNarrowMainWidth';

const Tasks = (props) => {
  const { category, tasks, incognitoMode } = props;

  const draggableListRef = useRef(null);

  const width = useNarrowMainWidth();
  const activeWidth = useNarrowMainWidth(false);

  if (!category) return <FullPageNoData />;

  const { id: categoryId, color } = category;

  const visibleTasks = sortBy(tasks.visible(incognitoMode, { checked: false, categoryId }), 'defaultOrder');

  return (
    <div className="w-full px-25px pb-25px">
      <MainTopSpace />
      <div className="w-full flex justify-center mb-4">
        <div style={{ width }}>
          <TaskCreator fixedCategory={category} />
        </div>
      </div>
      <List
        collection={visibleTasks}
        draggable={{ ref: draggableListRef, draggedElementType: draggedElements.task }}
        className="mb-25px"
        noDataPlaceholder={null}
      >
        {(task) => (
          <div key={task.id} className="mb-4 last:mb-0 flex w-full justify-center">
            <TaskPanel
              task={task}
              color={color}
              width={width}
              activeWidth={activeWidth}
              draggableListRef={draggableListRef}
            />
          </div>
        )}
      </List>
    </div>
  );
};

export default connect(prepareState(['tasks', 'incognitoMode']))(Tasks);
