export const eventsActionFetch = (dates) => ({
  type: 'EVENTS_ACTION_FETCH',
  payload: { dates },
});

export const eventsActionSet = (dates, newElements) => ({
  type: 'EVENTS_ACTION_SET',
  payload: { dates, newElements },
});

export const eventsActionCreateElement = (element) => ({
  type: 'EVENTS_ACTION_CREATE_ELEMENT',
  payload: { element },
});

export const eventsActionUnlock = () => ({
  type: 'EVENTS_ACTION_UNLOCK',
});

export const eventsActionUpdateElement = (element) => ({
  type: 'EVENTS_ACTION_UPDATE_ELEMENT',
  payload: { element },
});

export const eventsActionMarkPrivateElement = (element) => ({
  type: 'EVENTS_ACTION_MARK_PRIVATE_ELEMENT',
  payload: { element: element.assignValues({ private: true }) },
});

export const eventsActionUnmarkPrivateElement = (element) => ({
  type: 'EVENTS_ACTION_UNMARK_PRIVATE_ELEMENT',
  payload: { element: element.assignValues({ private: false }) },
});

export const eventsActionLockElement = (element) => ({
  type: 'EVENTS_ACTION_LOCK_ELEMENT',
  payload: { element },
});

export const eventsActionUnlockElement = (element) => ({
  type: 'EVENTS_ACTION_UNLOCK_ELEMENT',
  payload: { element },
});

export const eventsActionDeleteElement = (element) => ({
  type: 'EVENTS_ACTION_DELETE_ELEMENT',
  payload: { element },
});

export const eventsActionSetCreatedElement = (element) => ({
  type: 'EVENTS_ACTION_SET_CREATED_ELEMENT',
  payload: { element },
});

export const eventsActionSetElement = (element) => ({
  type: 'EVENTS_ACTION_SET_ELEMENT',
  payload: { element },
});

export const eventsActionUnset = (oneOrManyElements) => ({
  type: 'EVENTS_ACTION_UNSET',
  payload: { oneOrManyElements },
});
