import { useMemo, useEffect, useRef, useCallback } from 'react';
import { getUuid } from '../helpers/identifierHelpers';
import { activeElementTypes } from '../constants/activeElementTypes';

export const useConfirmation = (props) => {
  const {
    key,
    onConfirmed,
    title,
    message,
    textConfirmation,
    textConfirmationPrompt,
    activeElementsActionActivate,
    activeElementsActionDeactivate,
  } = props;

  const uuid = useMemo(getUuid, []);
  const opened = useRef(false);

  useEffect(() => {
    return () => {
      if (opened.current) activeElementsActionDeactivate(activeElementTypes.confirmationModal, uuid);
    };
  }, [key]);

  const confirm = useCallback(() => {
    activeElementsActionActivate(activeElementTypes.confirmationModal, uuid, {
      onConfirmed,
      identifier: uuid,
      title,
      message,
      textConfirmation,
      textConfirmationPrompt,
    });
    opened.current = true;
  }, [onConfirmed, title, message]);

  return { confirm };
};
