import React from 'react';
import MenuTitle from '../../shared/titles/MenuTitle';
import { initT } from '../../helpers/i18nHelpers';
import MenuContent from '../../shared/organizers/MenuContent';

const t = initT('pages.language');

const Left = (props) => {
  const { workspace } = props;
  return (
    <MenuContent
      top={
        <>
          <MenuTitle marginClass="mx-25px py-25px" to="/language/workspaces" tooltip={t('changeWorkspace')}>
            {workspace.name}
          </MenuTitle>
        </>
      }
      main={<div>main</div>}
    />
  );
};

export default Left;
