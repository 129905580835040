export const wastesActionFetch = (modelName) => ({
  type: 'WASTES_ACTION_FETCH',
  payload: { modelName },
});

export const wastesActionSet = (modelName, elements) => ({
  type: 'WASTES_ACTION_SET',
  payload: { modelName, elements },
});

export const wastesActionRestoreElement = (element) => ({
  type: 'WASTES_ACTION_RESTORE_ELEMENT',
  payload: { element },
});

export const wastesActionDeleteElement = (element) => ({
  type: 'WASTES_ACTION_DELETE_ELEMENT',
  payload: { element },
});

export const wastesActionLockElement = (element) => ({
  type: 'WASTES_ACTION_LOCK_ELEMENT',
  payload: { element },
});

export const wastesActionUnlockElement = (element) => ({
  type: 'WASTES_ACTION_UNLOCK_ELEMENT',
  payload: { element },
});

export const wastesActionUnset = (oneOrManyElements) => ({
  type: 'WASTES_ACTION_UNSET',
  payload: { oneOrManyElements },
});

export const wastesActionResetAllExcept = (modelName) => ({
  type: 'WASTES_ACTION_RESET_ALL_EXCEPT',
  payload: { modelName },
});
