import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import sessionReducer from '../reducer/sessionReducer';
import themeReducer from '../reducer/themeReducer';
import quickPassReducer from '../reducer/quickPassReducer';
import categoriesReducer from '../reducer/categoriesReducer';
import tasksReducer from '../reducer/tasksReducer';
import snackbarMessagesReducer from '../reducer/snackbarMessagesReducer';
import activeElementsReducer from '../reducer/activeElementsReducer';
import elementToFocusOnReducer from '../reducer/elementToFocusOnReducer';
import incognitoModeReducer from '../reducer/incognitoModeReducer';
import checkedTasksReducer from '../reducer/checkedTasksReducer';
import dayEvaluationsReducer from '../reducer/dayEvaluationsReducer';
import habitsSectionsReducer from '../reducer/habitsSectionsReducer';
import habitsReducer from '../reducer/habitsReducer';
import habitAnswersReducer from '../reducer/habitAnswersReducer';
import habitChartsDataReducer from '../reducer/habitChartsDataReducer';
import dayEvaluationsChartsDataReducer from '../reducer/dayEvaluationsChartsDataReducer';
import nodesReducer from '../reducer/nodesReducer';
import notesWorkspacesReducer from '../reducer/notesWorkspacesReducer';
import configReducer from '../reducer/configReducer';
import notesReducer from '../reducer/notesReducer';
import recurringTasksReducer from '../reducer/recurringTasksReducer';
import eventsReducer from '../reducer/eventsReducer';
import recurringEventsReducer from '../reducer/recurringEventsReducer';
import filesReducer from '../reducer/filesReducer';
import labelsReducer from '../reducer/labelsReducer';
import onClickOutsideCallbacksReducer from '../reducer/onClickOutsideCallbacksReducer';
import audioConfigReducer from '../reducer/audioConfigReducer';
import learningWorkspacesReducer from '../reducer/learningWorkspacesReducer';
import languageWorkspacesReducer from '../reducer/languageWorkspacesReducer';
import learningQueuesReducer from '../reducer/learningQueuesReducer';
import wastesReducer from '../reducer/wastesReducer';

const initializeReducer = (browserHistory) =>
  combineReducers({
    router: connectRouter(browserHistory),
    activeElements: activeElementsReducer,
    onClickOutsideCallbacks: onClickOutsideCallbacksReducer,
    elementToFocusOn: elementToFocusOnReducer,
    quickPass: quickPassReducer,
    incognitoMode: incognitoModeReducer,
    session: sessionReducer,
    config: configReducer,
    snackbarMessages: snackbarMessagesReducer,
    theme: themeReducer,
    audioConfig: audioConfigReducer,
    categories: categoriesReducer,
    tasks: tasksReducer,
    checkedTasks: checkedTasksReducer,
    recurringTasks: recurringTasksReducer,
    dayEvaluations: dayEvaluationsReducer,
    dayEvaluationsChartsData: dayEvaluationsChartsDataReducer,
    habitsSections: habitsSectionsReducer,
    habits: habitsReducer,
    habitAnswers: habitAnswersReducer,
    habitChartsData: habitChartsDataReducer,
    nodes: nodesReducer,
    notesWorkspaces: notesWorkspacesReducer,
    notes: notesReducer,
    events: eventsReducer,
    recurringEvents: recurringEventsReducer,
    files: filesReducer,
    labels: labelsReducer,
    learningWorkspaces: learningWorkspacesReducer,
    learningQueues: learningQueuesReducer,
    languageWorkspaces: languageWorkspacesReducer,
    wastes: wastesReducer,
  });

export default initializeReducer;
