import React, { useRef } from 'react';
import { imageFileExtensions } from '../../../../constants/fileExtensions';
import last from 'lodash/last';
import ThemeCrumb from '../../../ThemeCrumb';
import AttachmentIcon from '../../../../icons/attachmentIcon';
import AuxiliaryText from '../../../AuxiliaryText';
import Clickable from '../../../Clickable';
import TooltipWrapper from '../../../TooltipWrapper';
import { initT } from '../../../../helpers/i18nHelpers';
import useAttachmentsGridColsClass from './useAttachmentsGridColsClass';
import { create } from 'apisauce';

const t = initT('features.nodesGroupPanel');

const generateOpenPdf = (url) => async () => {
  const response = await create({ headers: {}, responseType: 'blob' }).get(url);
  const file = new Blob([response.data], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL, '_blank');
};

const AttachmentTile = (props) => {
  const { url, name, description, interactive = true } = props;

  const isImage = name && imageFileExtensions.includes(`.${last(name.split('.'))}`);

  let content;
  if (url && isImage) content = <img src={url} alt="Attachment image" className="max-h-16" />;
  else
    content = (
      <ThemeCrumb type="input">
        <AttachmentIcon className="w-full w-6 h-6" />
      </ThemeCrumb>
    );

  const result = (
    <div className="w-full p-4 border rounded">
      <div className="h-16 w-full flex justify-center items-center">{content}</div>
      <div className="mt-2 w-full text-xs h-8 overflow-hidden node-image-name">{name}</div>
      {description ? (
        <div className="mt-2 overflow-hidden w-full node-image-description">
          <AuxiliaryText className="text-center">{description}</AuxiliaryText>
        </div>
      ) : (
        <></>
      )}
    </div>
  );

  if (interactive) {
    const clickableActionParams = (url || '').endsWith('.pdf')
      ? { onMouseDown: generateOpenPdf(url) }
      : { href: url, download: true };
    return (
      <Clickable hoverOpacity {...clickableActionParams}>
        <TooltipWrapper tooltip={`${t('download')} ${name}`} className="h-full">
          <ThemeCrumb type="node">{result}</ThemeCrumb>
        </TooltipWrapper>
      </Clickable>
    );
  }
  return result;
};

const AttachmentsPresenter = (props) => {
  const { content, downloadableMode = true } = props;

  const ref = useRef(null);
  const gridColsClass = useAttachmentsGridColsClass(ref);

  const { attachments = [] } = content || {};

  return (
    <div ref={ref} className={`grid ${gridColsClass} gap-2`}>
      {attachments.map((attachment) => (
        <AttachmentTile key={attachment.url} downloadableMode={downloadableMode} {...attachment} />
      ))}
    </div>
  );
};

export default AttachmentsPresenter;
