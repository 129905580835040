import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { notificationT } from '../../../helpers/i18nHelpers';
import { activeElementsActionActivateIfOtherInactive } from '../../actions/activeElementsActions';
import { activeElementTypes } from '../../../constants/activeElementTypes';
import { recurringTasksActionSetElement, recurringTasksActionUnlockElement } from '../../actions/recurringTasksActions';
import { recurringTaskFromDto, recurringTaskToDto } from '../../../helpers/model/recurringTaskHelpers';

export default function* updateElementRecurringTasksSaga(action) {
  const initialRecurringTask = action.payload;
  const { ok, data } = yield call(api.recurringTasks.update, recurringTaskToDto(initialRecurringTask));
  if (ok) {
    const updatedRecurringTask = recurringTaskFromDto(data);
    yield put(snackbarMessagesActionAdd(notificationT('recurringTasks.update.success')));
    yield put(recurringTasksActionSetElement(updatedRecurringTask));
    yield put(activeElementsActionActivateIfOtherInactive(activeElementTypes.recurringTask, updatedRecurringTask.id));
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(recurringTasksActionUnlockElement(initialRecurringTask));
  }
}
