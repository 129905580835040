import { useState, useCallback } from 'react';

export const useDoubleClickToBlur = ({ enabled = true, onBlur }) => {
  const [alreadyClickedOutside, setAlreadyClickedOutside] = useState(false);
  const onClickOutside = useCallback(() => {
    if (alreadyClickedOutside || !enabled) {
      onBlur();
      return;
    }
    setAlreadyClickedOutside(true);
  }, [enabled, alreadyClickedOutside, onBlur]);
  const onClickInside = useCallback(() => {
    setAlreadyClickedOutside(false);
  }, []);

  return { onClickOutside, onClickInside, aboutToBlur: alreadyClickedOutside };
};
