import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { tasksActionSetElement, tasksActionUnlockElement } from '../../actions/tasksActions';
import { taskFromDto, taskToDto } from '../../../helpers/model/taskHelpers';
import { notificationT } from '../../../helpers/i18nHelpers';
import {
  snackbarMessagesActionAdd,
  snackbarMessagesActionHandleBackendErrors,
} from '../../actions/snackbarMessagesActions';
import { activeElementsActionActivateIfOtherInactive } from '../../actions/activeElementsActions';
import { activeElementTypes } from '../../../constants/activeElementTypes';
import { push } from 'connected-react-router';
import { getCurrentHref } from '../../../helpers/locationHelpers';

export default function* updateElementTasksSaga(action) {
  const initialTask = action.payload;
  const { ok, data } = yield call(api.tasks.update, taskToDto(initialTask));
  if (ok) {
    const updatedTask = taskFromDto(data);
    yield put(snackbarMessagesActionAdd(notificationT('tasks.update.success')));
    yield put(tasksActionSetElement(updatedTask));
    if (getCurrentHref().startsWith('/tasks')) {
      yield put(push(`/tasks/${updatedTask.categoryId}`));
      yield put(activeElementsActionActivateIfOtherInactive(activeElementTypes.task, updatedTask.id));
    }
  } else {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    yield put(tasksActionUnlockElement(initialTask));
  }
}
