import Lockable from './parents/lockable';

export default class HabitAnswer extends Lockable {
  constructor() {
    super();
    this.id = null;
    this.hidden = false;
    this.answer = null;
    this.date = null;
    this.wasUpdated = false;
    this.habitId = null;
    this.habitSummaries = [];
  }

  visible() {
    return true;
  }
}
