import { call, put } from 'redux-saga/effects';
import { api } from '../../../helpers/apiHelpers';
import { draggedElements } from '../../../constants/draggedElements';
import { tasksActionLockElement, tasksActionSetElement, tasksActionUnlockElement } from '../../actions/tasksActions';
import { taskFromDto } from '../../../helpers/model/taskHelpers';
import Task from '../../../models/task';
import { snackbarMessagesActionHandleBackendErrors } from '../../actions/snackbarMessagesActions';
import Category from '../../../models/category';
import {
  categoriesActionLockElement,
  categoriesActionSetElement,
  categoriesActionUnlockElement,
} from '../../actions/categoriesActions';
import { categoryFromDto } from '../../../helpers/model/categoryHelpers';
import HabitsSection from '../../../models/habitsSection';
import {
  habitsSectionsActionLockElement,
  habitsSectionsActionSetElement,
  habitsSectionsActionUnlockElement,
} from '../../actions/habitsSectionsActions';
import { habitsSectionFromDto } from '../../../helpers/model/habitsSectionHelpers';
import Habit from '../../../models/habit';
import Node from '../../../models/node';
import { habitsActionLockElement, habitsActionSetElement, habitsActionUnlockElement } from '../../actions/habitActions';
import { habitFromDto } from '../../../helpers/model/habitsHelpers';
import { nodesActionLockElement, nodesActionSetElement, nodesActionUnlockElement } from '../../actions/nodesActions';
import { nodeFromDto } from '../../../helpers/model/nodeHelpers';
import NotesWorkspace from '../../../models/notesWorkspace';
import {
  notesWorkspacesActionLockElement,
  notesWorkspacesActionSetElement,
  notesWorkspacesActionUnlockElement,
} from '../../actions/notesWorkspacesActions';
import { notesWorkspaceFromDto } from '../../../helpers/model/notesWorkspacesHelpers';
import Note from '../../../models/note';
import { notesActionLockElement, notesActionSetElement, notesActionUnlockElement } from '../../actions/notesActions';
import { noteFromDto } from '../../../helpers/model/noteHelpers';
import RecurringTask from '../../../models/recurringTask';
import {
  recurringTasksActionLockElement,
  recurringTasksActionSetElement,
  recurringTasksActionUnlockElement,
} from '../../actions/recurringTasksActions';
import { recurringTaskFromDto } from '../../../helpers/model/recurringTaskHelpers';
import EventModel from '../../../models/eventModel';
import {
  eventsActionLockElement,
  eventsActionSetElement,
  eventsActionUnlockElement,
} from '../../actions/eventsActions';
import { eventFromDto } from '../../../helpers/model/eventHelpers';
import RecurringEvent from '../../../models/recurringEvent';
import {
  recurringEventsActionLockElement,
  recurringEventsActionSetElement,
  recurringEventsActionUnlockElement,
} from '../../actions/recurringEventsActions';
import { recurringEventFromDto } from '../../../helpers/model/recurringEventHelpers';
import LearningWorkspace from '../../../models/learningWorkspace';
import {
  learningWorkspacesActionLockElement,
  learningWorkspacesActionSetElement,
  learningWorkspacesActionUnlockElement,
} from '../../actions/learningWorkspacesActions';
import { learningWorkspaceFromDto } from '../../../helpers/model/learningWorkspacesHelpers';
import LanguageWorkspace from '../../../models/languageWorkspace';
import {
  languageWorkspacesActionLockElement,
  languageWorkspacesActionSetElement,
  languageWorkspacesActionUnlockElement,
} from '../../actions/languageWorkspacesActions';
import { languageWorkspaceFromDto } from '../../../helpers/model/languageWorkspacesHelpers';
import {
  learningQueuesActionLockElement,
  learningQueuesActionSetElement,
  learningQueuesActionUnlockElement,
} from '../../actions/learningQueuesActions';
import LearningQueue from '../../../models/learningQueue';
import { learningQueueFromDto } from '../../../helpers/model/learningQueueHelpers';

const beforeActions = {
  [draggedElements.task]: function* (draggedElement) {
    const task = new Task();
    task.id = parseInt(draggedElement.identifier, 10);
    yield put(tasksActionLockElement(task));
  },
  [draggedElements.todoTask]: function* (draggedElement) {
    const task = new Task();
    task.id = parseInt(draggedElement.identifier, 10);
    yield put(tasksActionLockElement(task));
  },
  [draggedElements.recurringTask]: function* (draggedElement) {
    const recurringTask = new RecurringTask();
    recurringTask.id = parseInt(draggedElement.identifier, 10);
    yield put(recurringTasksActionLockElement(recurringTask));
  },
  [draggedElements.category]: function* (draggedElement) {
    const category = new Category();
    category.id = parseInt(draggedElement.identifier, 10);
    yield put(categoriesActionLockElement(category));
  },
  [draggedElements.habitsSection]: function* (draggedElement) {
    const habitsSection = new HabitsSection();
    habitsSection.id = parseInt(draggedElement.identifier, 10);
    yield put(habitsSectionsActionLockElement(habitsSection));
  },
  [draggedElements.habit]: function* (draggedElement) {
    const habit = new Habit();
    habit.id = parseInt(draggedElement.identifier, 10);
    yield put(habitsActionLockElement(habit));
  },
  [draggedElements.node]: function* (draggedElement) {
    const node = new Node();
    node.id = parseInt(draggedElement.identifier, 10);
    yield put(nodesActionLockElement(node));
  },
  [draggedElements.notesWorkspace]: function* (draggedElement) {
    const workspace = new NotesWorkspace();
    workspace.id = parseInt(draggedElement.identifier, 10);
    yield put(notesWorkspacesActionLockElement(workspace));
  },
  [draggedElements.note]: function* (draggedElement) {
    const note = new Note();
    note.id = parseInt(draggedElement.identifier, 10);
    yield put(notesActionLockElement(note));
  },
  [draggedElements.event]: function* (draggedElement) {
    const event = new EventModel();
    event.id = parseInt(draggedElement.identifier, 10);
    yield put(eventsActionLockElement(event));
  },
  [draggedElements.recurringEvent]: function* (draggedElement) {
    const recurringEvent = new RecurringEvent();
    recurringEvent.id = parseInt(draggedElement.identifier, 10);
    yield put(recurringEventsActionLockElement(recurringEvent));
  },
  [draggedElements.learningWorkspace]: function* (draggedElement) {
    const workspace = new LearningWorkspace();
    workspace.id = parseInt(draggedElement.identifier, 10);
    yield put(learningWorkspacesActionLockElement(workspace));
  },
  [draggedElements.learningQueue]: function* (draggedElement) {
    const queue = new LearningQueue();
    queue.id = parseInt(draggedElement.identifier, 10);
    yield put(learningQueuesActionLockElement(queue));
  },
  [draggedElements.languageWorkspace]: function* (draggedElement) {
    const workspace = new LanguageWorkspace();
    workspace.id = parseInt(draggedElement.identifier, 10);
    yield put(languageWorkspacesActionLockElement(workspace));
  },
};

const apiActions = {
  [draggedElements.task]: api.tasks.move,
  [draggedElements.todoTask]: api.tasks.moveTodo,
  [draggedElements.recurringTask]: api.recurringTasks.move,
  [draggedElements.category]: api.categories.move,
  [draggedElements.habitsSection]: api.habitsSections.move,
  [draggedElements.habit]: api.habits.move,
  [draggedElements.node]: api.nodes.move,
  [draggedElements.notesWorkspace]: api.notesWorkspaces.move,
  [draggedElements.note]: api.notes.move,
  [draggedElements.event]: api.events.move,
  [draggedElements.recurringEvent]: api.recurringEvents.move,
  [draggedElements.learningWorkspace]: api.learningWorkspaces.move,
  [draggedElements.learningQueue]: api.learningQueues.move,
  [draggedElements.languageWorkspace]: api.languageWorkspaces.move,
};

const defaultGetApiActionDataFunc = (draggedElement, successorElement) => ({
  id: draggedElement.identifier,
  successor_id: successorElement.identifier,
});

const getApiActionDataFunctions = {
  [draggedElements.task]: defaultGetApiActionDataFunc,
  [draggedElements.todoTask]: defaultGetApiActionDataFunc,
  [draggedElements.recurringTask]: defaultGetApiActionDataFunc,
  [draggedElements.category]: defaultGetApiActionDataFunc,
  [draggedElements.habitsSection]: defaultGetApiActionDataFunc,
  [draggedElements.habit]: defaultGetApiActionDataFunc,
  [draggedElements.node]: defaultGetApiActionDataFunc,
  [draggedElements.notesWorkspace]: defaultGetApiActionDataFunc,
  [draggedElements.note]: (draggedElement, successorElement, additionalData) => ({
    id: draggedElement.identifier,
    successor_id: successorElement.identifier,
    parent_id: additionalData.parentId,
  }),
  [draggedElements.event]: defaultGetApiActionDataFunc,
  [draggedElements.recurringEvent]: defaultGetApiActionDataFunc,
  [draggedElements.learningWorkspace]: defaultGetApiActionDataFunc,
  [draggedElements.learningQueue]: defaultGetApiActionDataFunc,
  [draggedElements.languageWorkspace]: defaultGetApiActionDataFunc,
};

const afterActions = {
  [draggedElements.task]: function* (data) {
    yield put(tasksActionSetElement(taskFromDto(data)));
  },
  [draggedElements.todoTask]: function* (data) {
    yield put(tasksActionSetElement(taskFromDto(data)));
  },
  [draggedElements.recurringTask]: function* (data) {
    yield put(recurringTasksActionSetElement(recurringTaskFromDto(data)));
  },
  [draggedElements.category]: function* (data) {
    yield put(categoriesActionSetElement(categoryFromDto(data)));
  },
  [draggedElements.habitsSection]: function* (data) {
    yield put(habitsSectionsActionSetElement(habitsSectionFromDto(data)));
  },
  [draggedElements.habit]: function* (data) {
    yield put(habitsActionSetElement(habitFromDto(data)));
  },
  [draggedElements.node]: function* (data) {
    yield put(nodesActionSetElement(nodeFromDto(data)));
  },
  [draggedElements.notesWorkspace]: function* (data) {
    yield put(notesWorkspacesActionSetElement(notesWorkspaceFromDto(data)));
  },
  [draggedElements.note]: function* (data) {
    yield put(notesActionSetElement(noteFromDto(data)));
  },
  [draggedElements.event]: function* (data) {
    yield put(eventsActionSetElement(eventFromDto(data)));
  },
  [draggedElements.recurringEvent]: function* (data) {
    yield put(recurringEventsActionSetElement(recurringEventFromDto(data)));
  },
  [draggedElements.learningWorkspace]: function* (data) {
    yield put(learningWorkspacesActionSetElement(learningWorkspaceFromDto(data)));
  },
  [draggedElements.learningQueue]: function* (data) {
    yield put(learningQueuesActionSetElement(learningQueueFromDto(data)));
  },
  [draggedElements.languageWorkspace]: function* (data) {
    yield put(languageWorkspacesActionSetElement(languageWorkspaceFromDto(data)));
  },
};

const afterFailedActions = {
  [draggedElements.task]: function* (draggedElement, data) {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    const task = new Task();
    task.id = parseInt(draggedElement.identifier, 10);
    yield put(tasksActionUnlockElement(task));
  },
  [draggedElements.todoTask]: function* (draggedElement, data) {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    const task = new Task();
    task.id = parseInt(draggedElement.identifier, 10);
    yield put(tasksActionUnlockElement(task));
  },
  [draggedElements.recurringTask]: function* (draggedElement, data) {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    const recurringTask = new RecurringTask();
    recurringTask.id = parseInt(draggedElement.identifier, 10);
    yield put(recurringTasksActionUnlockElement(recurringTask));
  },
  [draggedElements.category]: function* (draggedElement, data) {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    const category = new Category();
    category.id = parseInt(draggedElement.identifier, 10);
    yield put(categoriesActionUnlockElement(category));
  },
  [draggedElements.habitsSection]: function* (draggedElement, data) {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    const habitsSection = new HabitsSection();
    habitsSection.id = parseInt(draggedElement.identifier, 10);
    yield put(habitsSectionsActionUnlockElement(habitsSection));
  },
  [draggedElements.habit]: function* (draggedElement, data) {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    const habit = new Habit();
    habit.id = parseInt(draggedElement.identifier, 10);
    yield put(habitsActionUnlockElement(habit));
  },
  [draggedElements.node]: function* (draggedElement, data) {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    const node = new Node();
    node.id = parseInt(draggedElement.identifier, 10);
    yield put(nodesActionUnlockElement(node));
  },
  [draggedElements.notesWorkspace]: function* (draggedElement, data) {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    const workspace = new NotesWorkspace();
    workspace.id = parseInt(draggedElement.identifier, 10);
    yield put(notesWorkspacesActionUnlockElement(workspace));
  },
  [draggedElements.note]: function* (draggedElement, data) {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    const note = new Note();
    note.id = parseInt(draggedElement.identifier, 10);
    yield put(notesActionUnlockElement(note));
  },
  [draggedElements.event]: function* (draggedElement, data) {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    const event = new EventModel();
    event.id = parseInt(draggedElement.identifier, 10);
    yield put(eventsActionUnlockElement(event));
  },
  [draggedElements.recurringEvent]: function* (draggedElement, data) {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    const recurringEvent = new RecurringEvent();
    recurringEvent.id = parseInt(draggedElement.identifier, 10);
    yield put(recurringEventsActionUnlockElement(recurringEvent));
  },
  [draggedElements.learningWorkspace]: function* (draggedElement, data) {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    const workspace = new LearningWorkspace();
    workspace.id = parseInt(draggedElement.identifier, 10);
    yield put(learningWorkspacesActionUnlockElement(workspace));
  },
  [draggedElements.learningQueue]: function* (draggedElement, data) {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    const queue = new LearningQueue();
    queue.id = parseInt(draggedElement.identifier, 10);
    yield put(learningQueuesActionUnlockElement(queue));
  },
  [draggedElements.languageWorkspace]: function* (draggedElement, data) {
    yield put(snackbarMessagesActionHandleBackendErrors(data));
    const workspace = new LanguageWorkspace();
    workspace.id = parseInt(draggedElement.identifier, 10);
    yield put(languageWorkspacesActionUnlockElement(workspace));
  },
};

export default function* draggedElementDropSaga(action) {
  const { draggedElement, successorElement, additionalData } = action.payload;
  const draggedType = draggedElement.type;
  yield beforeActions[draggedType](draggedElement, additionalData);
  const apiActionData = getApiActionDataFunctions[draggedType](draggedElement, successorElement, additionalData);
  const { ok, data } = yield call(apiActions[draggedType], apiActionData);
  if (ok) {
    yield afterActions[draggedType](data);
  } else {
    yield afterFailedActions[draggedType](draggedElement, data);
  }
}
