import React from 'react';
import ThemeCrumb from '../../../../ThemeCrumb';
import Hoverable from '../../../../Hoverable';
import Clickable from '../../../../Clickable';

const Option = (props) => {
  const {
    label,
    value,
    selectedValue,
    onSelect = (_, { closeMenu }) => closeMenu(),
    checkIfSelected = (optionValue, selectedValue) => optionValue === selectedValue,
    ...otherProps
  } = props;

  const isSelected = checkIfSelected(value, selectedValue);

  return (
    <ThemeCrumb type={isSelected ? 'sizeableThingSelected' : 'selectorOption'}>
      <Hoverable className="" hoveredThemeType="sizeableThingHovered">
        <Clickable
          className="w-full "
          onMouseDown={() => onSelect(value, { label, previousValue: selectedValue, ...otherProps })}
          disabled={isSelected}
          opaqueWhenDisabled={false}
        >
          <div
            className={`w-full p-1 flex justify-center text-clip overflow-hidden
                                    ${isSelected ? 'font-bold' : ''}`}
          >
            {label}
          </div>
        </Clickable>
      </Hoverable>
    </ThemeCrumb>
  );
};

export default Option;
