import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const useWorkspace = (props) => {
  const { notesWorkspaces, incognitoMode, config, notes, notesActionFetch, notesWorkspacesActionFetch } = props;

  const history = useHistory();

  useEffect(() => {
    if (!notesWorkspaces.fetched) notesWorkspacesActionFetch();
  }, []);

  let workspace = {};
  const workspaceCandidates = notesWorkspaces.visible(incognitoMode, { id: config.selectedNotesWorkspaceId });
  if (notesWorkspaces.fetched) {
    if (workspaceCandidates.length === 1) workspace = workspaceCandidates[0];
    // a bit hacky without setTimeout, but it makes the UX better
    else history.replace('/notes/workspaces');
  }
  const workspaceSelected = workspace.id !== undefined;

  useEffect(() => {
    if (workspaceSelected && !notes.fetched(workspace.id)) notesActionFetch(workspace.id);
  }, [workspace, workspace && notes.fetched(workspace.id)]);

  return [workspaceSelected, workspace];
};
