import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import MainTopSpace from '../../shared/organizers/MainTopSpace';
import FormikFieldSub from '../../shared/form/FormikFieldSub';
import TextField from '../../shared/form/fieldTypes/TextField';
import RadioField from '../../shared/form/fieldTypes/RadioField';
import { initT } from '../../helpers/i18nHelpers';
import { getUuid } from '../../helpers/identifierHelpers';
import { api } from '../../helpers/apiHelpers';
import debounce from 'lodash/debounce';
import NoData from '../../shared/placeholders/NoData';
import LastStickyElement from '../../shared/LastStickyElement';
import useSearchParamsObject from '../../hooks/useSearchParamsObject';
import { useHistory } from 'react-router-dom';
import { updateSearchParams } from '../../helpers/locationHelpers';
import FoundEvents from './FoundEvents';
import { useCheckIfMounted } from '../../hooks/useCheckIfMounted';
import FoundCheckedTask from './FoundCheckedTasks';
import Container from '../../shared/organizers/containers/Container';

const t = initT('pages.finder');

const collectionNames = {
  events: 'events',
  checkedTasks: 'checkedTasks',
};

const SearchingSpace = () => {
  const history = useHistory();
  const searchParams = useSearchParamsObject();
  const { needle = '', collection: collectionName } = searchParams;

  const checkIfMounted = useCheckIfMounted();
  // eslint-disable-next-line no-unused-vars
  const [_, reload] = useState(getUuid());
  const inputRef = useRef(null);

  const eventsResultDates = useMemo(() => ({}), []);
  const checkedTasksResultDates = useMemo(() => ({}), []);
  const datesDict = useMemo(() => {
    if (collectionName === collectionNames.checkedTasks) return checkedTasksResultDates;
    return eventsResultDates;
  }, [collectionName]);
  const apiMethod = useMemo(() => {
    if (collectionName === collectionNames.checkedTasks) return api.checkedTasks.find;
    return api.events.find;
  }, [collectionName]);
  const FoundEntitiesComponent = useMemo(() => {
    if (collectionName === collectionNames.checkedTasks) return FoundCheckedTask;
    return FoundEvents;
  }, [collectionName]);

  const fetchDates = useCallback(
    debounce(async (_needle) => {
      if (_needle.length < 3) return;

      const { ok, data } = await apiMethod(_needle);
      if (!checkIfMounted()) return;

      datesDict[_needle] = ok ? data.dates : 'error';
      reload(getUuid());
    }, 300),
    [apiMethod, datesDict],
  );

  const onNeedleChange = useCallback(
    ({ event }) => {
      const newNeedle = event.target.value;
      datesDict[newNeedle] = 'loading';
      fetchDates(newNeedle);
      updateSearchParams(history, { needle: newNeedle });
    },
    [datesDict, fetchDates],
  );

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
    if (needle.length >= 3) onNeedleChange({ event: { target: { value: needle } } });
  }, []);

  const updateCollection = useCallback(
    (newValue) => {
      updateSearchParams(history, { needle: '', collection: newValue });
      if (inputRef.current) inputRef.current.focus();
    },
    [history],
  );

  if (!Object.values(collectionNames).includes(collectionName)) {
    // a bit hacky without setTimeout, but it makes the UX better
    updateCollection(collectionNames.events);
  }

  return (
    <>
      <MainTopSpace />
      <LastStickyElement>
        <Container themeType="main" borderClass="">
          <div className="flex justify-center">
            <div className="p-25px">
              <FormikFieldSub
                component={RadioField}
                value={collectionName}
                radioValue={collectionNames.events}
                onChange={() => updateCollection(collectionNames.events)}
                label={t('collectionNames.events')}
              />
            </div>
            <div className="p-25px">
              <FormikFieldSub
                component={RadioField}
                value={collectionName}
                radioValue={collectionNames.checkedTasks}
                onChange={() => updateCollection(collectionNames.checkedTasks)}
                label={t('collectionNames.checkedTasks')}
              />
            </div>
          </div>
          <FormikFieldSub
            innerRef={inputRef}
            value={needle}
            onChange={onNeedleChange}
            component={TextField}
            paddingClass="p-3"
            placeholder={t('searchPlaceholder')}
          />
        </Container>
      </LastStickyElement>
      <div className="w-full py-25px">
        {datesDict[needle] && needle.length >= 3 ? (
          React.createElement(FoundEntitiesComponent, {
            key: needle,
            dates: datesDict[needle],
            needle,
          })
        ) : (
          <NoData placeholder={needle.length < 3 ? t('atLeast3ToSearch') : undefined} />
        )}
      </div>
    </>
  );
};

export default SearchingSpace;
